import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import SingleToggle from './SingleToggle';

class ChartIchimokuCloud extends React.Component {
  localize(i18nKey, otherParams) {
    return this.props.intl.formatMessage({ id: i18nKey }, otherParams);
  }

  getPeriods(type) {
    let sma =
      _.find(this.props.indicators, indicator => indicator.type === type) || {};
    return sma.params || [];
  }

  toggleCloud = event => {
    let value = event.target.checked;

    if (value) {
      this.props.addIndicator(this.props.type);
    } else {
      this.props.deleteIndicator(this.props.type);
    }
  };

  render() {
    return (
      <div className="settings-card-contents">
        <SingleToggle
          title={this.localize(
            'settings.chart.indicators.' + this.props.type + '.title'
          )}
          checked={this.getPeriods(this.props.type).length > 0}
          onChange={this.toggleCloud}
          skipList={true}
          toggleFavorite={this.props.toggleFavorite}
          type={this.props.type}
          isFavorite={this.props.isFavorite}
        />
      </div>
    );
  }
}

export default injectIntl(ChartIchimokuCloud);
