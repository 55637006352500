import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { getBoardType, isPositionBoard } from '../../utils/marketboards';
import { marketboardFontFamily } from '../../utils/html';
import { useDesktopWidth } from '../../utils/hooks/useDesktopWidth';
import {StandardWidths, useWidth} from "../../utils/hooks/useWidth";

const useStylesGlobal = makeStyles({
  quoteTableHeaderSymbolNameCell: {
    textAlign: 'left',
    marginLeft: '5px'
  },
  quoteTableHeaderGraphCell: {
    textAlign: 'center'
  },
  quoteTableHeaderPriceCell: {
    textAlign: 'right',
    marginRight: '12px'
  }
});

const useStylesSmall = makeStyles({
  quoteTableHeader: {
    display: 'grid',
    gridTemplateColumns: '7px minmax(0,1fr) minmax(0,96px) 137px',
    gridTemplateRows: '28px',
    height: '28px',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.05)',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#666666',
    borderBottom: '1px solid #00000026'
  },
  quoteTableHeaderCell: {
    marginTop: 'auto',
    marginBottom: 'auto'
  }
});

const useStylesMedium = useStylesSmall;
const useStylesLarge = useStylesSmall;

export const MobileMarketboardHeader = ({ marketboardId }) => {
  // Intl
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });

  // Width Calculation
  const calculatedWidth = useWidth();
  const displayGraphHeader = calculatedWidth !== StandardWidths.xs;

  // Styles
  const smallStyle = useStylesSmall;
  const mediumStyle = useStylesMedium;
  const largeStyle = useStylesLarge;

  let activeStyle = smallStyle;

  if (
      calculatedWidth === StandardWidths.lg ||
      calculatedWidth === StandardWidths.xl
  ) {
    activeStyle = largeStyle;
  }

  if (calculatedWidth === StandardWidths.md) {
    activeStyle = mediumStyle;
  }

  const styles = { ...useStylesGlobal(), ...activeStyle() };

  const boardName = marketboardId || '';
  const boardType = getBoardType(boardName);
  const isCash = boardType === 'cash';
  const isCashOrMargin = isPositionBoard(boardName);

  const headerNameLabel = localize('marketboard.header.symbol.name');
  const headerGraphLabel = localize('marketboard.header.graph');
  let headerPriceLabel = localize('marketboard.header.userboard.price');

  if (isCashOrMargin) {
    headerPriceLabel = isCash
      ? localize('marketboard.header.cashboard.price')
      : localize('marketboard.header.marginboard.price');
  }

  return (
    <div className={styles.quoteTableHeader}>
      <div />
      <div
        className={[
          styles.quoteTableHeaderCell,
          styles.quoteTableHeaderSymbolNameCell
        ].join(' ')}
      >
        {headerNameLabel}
      </div>
      {
        <div
          className={[
            styles.quoteTableHeaderCell,
            styles.quoteTableHeaderGraphCell
          ].join(' ')}
        >
          {displayGraphHeader && headerGraphLabel}
        </div> 
      }
      <div
        className={[
          styles.quoteTableHeaderCell,
          styles.quoteTableHeaderPriceCell
        ].join(' ')}
      >
        {headerPriceLabel}
      </div>
    </div>
  );
};
