import React from 'react';
import { injectIntl } from 'react-intl';
import Number from '../common/Number';
import { getFundamentalValue } from '../../utils/fundamentals';

const i18nKeyPrefix = 'symbol.details.indicators.analystConsensus.';

class AnalystConsensusSection extends React.PureComponent {
  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  getValue(field) {
    return getFundamentalValue(this.props.fundamentals, field, 'value');
  }

  render() {
    const rating = this.getValue('rating');
    const targetPrice = this.getValue('targetPrice');
    const targetPriceChange = this.getValue('targetPriceChange');

    return (
      <div className="symbol-details-indicators-fundamental-analysis-section">
        <div className="section-header symbol-indicators-title">
          {this.localize(i18nKeyPrefix + 'title')}
        </div>
        <div className="symbol-indicator-fundamental-analysis-details">
          <div className="analyst-consensus-table">
            <div className={'analyst-consensus-table-row border-first'}>
              <div className={'analyst-consensus-table-row-data'}>
                <span className="analyst-consensus-table-row-column-header">
                  {this.localize(i18nKeyPrefix + 'rating')}
                </span>
                <span className="analyst-consensus-table-row-value">
                  <Number value={rating} precision="1" signed={true} />
                </span>
              </div>
            </div>
            <div className={'analyst-consensus-table-row border-bottom'}>
              <div className={'analyst-consensus-table-row-data'}>
                <span className="analyst-consensus-table-row-column-header">
                  {this.localize(i18nKeyPrefix + 'targetPrice')}
                </span>
                <span className="analyst-consensus-table-row-value">
                  <Number value={targetPrice} precision="2" />
                </span>
              </div>
            </div>
            <div className={'analyst-consensus-table-row border-bottom'}>
              <div className={'analyst-consensus-table-row-data'}>
                <span className="analyst-consensus-table-row-column-header">
                  {this.localize(i18nKeyPrefix + 'targetPriceChange')}
                </span>
                <span className="analyst-consensus-table-row-value">
                  <Number value={targetPriceChange} precision="2" suffix="%" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AnalystConsensusSection = injectIntl(AnalystConsensusSection);

export default AnalystConsensusSection;
