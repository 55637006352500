import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';
import { TextField } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import { injectIntl, FormattedMessage } from 'react-intl';
import { fetchSymbolPatternIfNeeded } from '../../actions/symbols';
import Exchange from '../common/Exchange';
import withTracker from '../../utils/analytics';
import { DEFAULT_MAX_SYMBOL_SEARCH_RESULTS } from '../../utils/index';
import { isSupplemental, isIndustry } from '../../utils/symbol';
import * as lsUtil from '../../utils/localStorage';

function ExchangeWithRoot(Component) {
  return class ExchangeChild extends React.PureComponent {
    render() {
      return (
        <span className={'formatted-exchange'}>
          <span className="short-name">{this.props.jpShortName || ''}</span>
          {(!isSupplemental(this.props.assetClass) &&
            !isIndustry(this.props.assetClass)) && this.props.root + ' '}
          <Component {...this.props} {...this.state} />
        </span>
      );
    }
  };
}

const FormattedExchange = ExchangeWithRoot(Exchange);

function SearchItem(props) {
  if (props.symbolData && props.symbolData.data) {
    let exchange = (
      <FormattedExchange
        name={props.symbolData.data.exchangeName}
        section={props.symbolData.data.jpSection}
        root={props.symbolData.data.root}
        jpShortName={props.symbolData.data.jpShortName}
        assetClass={props.symbolData.data.assetClass}
      />
    );
    const iconStyle = { width: '28px', height: '28px' };

    return (
      <ListItem
        button
        key={props.symbol}
        value={props.symbol}
        className="search-list-item add-symbol"
        onClick={() => props.onSelect(props.symbol, props.symbolData)}
      >
        <ListItemText
          primary={
            <div className={'primary-text'}>{props.symbolData.data.name}</div>
          }
          secondary={exchange}
        />
        <ListItemIcon>
          <AddIcon
            key={props.symbol + '-add-icon'}
            className="add-button"
            style={iconStyle}
          />
        </ListItemIcon>
      </ListItem>
    );
  }
  return <FormattedMessage id="message.status.loading" />;
}

function SearchText(props) {
  const icon = <CheckIcon />;
  const criteriaTiles = (
    <div className={'button-selection-group'}>
      <Chip
        key="JPEQ"
        label={props.localize(`symbol.search.criteria.jpeq`)}
        onClick={() => props.toggleJpeq()}
        icon={icon}
        className={props.includeJpeq ? 'selected' : 'un-selected'}
      />
      <Chip
        key="USEQ"
        label={props.localize(`symbol.search.criteria.useq`)}
        onClick={() => props.toggleUseq()}
        icon={icon}
        className={props.includeUseq ? 'selected' : 'un-selected'}
      />
      <Chip
        key="SUPPLEMENTAL"
        label={props.localize(`symbol.search.criteria.supplemental`)}
        onClick={() => props.toggleSupplemental()}
        icon={icon}
        className={props.includeSupplemental ? 'selected' : 'un-selected'}
      />
      <Chip
        key="INDUSTRY"
        label={props.localize(`symbol.search.criteria.industry`)}
        onClick={() => props.toggleIndustry()}
        icon={icon}
        className={props.includeIndustry ? 'selected' : 'un-selected'}
      />
    </div>
  );

  return (
    <div>
      <div className="search-box">
        <SearchIcon className="search-icon" />
        <TextField
          key={'symbol-search-text'}
          fullWidth={true}
          autoFocus={true}
          onChange={props.onChange}
          placeholder={props.placeholder}
          className={'search-text'}
          variant="standard"
          value={props.search}
          disabled={props.isDisabled}
        />
      </div>
      {criteriaTiles}
    </div>
  );
}

class SearchItemListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      includeJpeq: lsUtil.getIncludeJpeq(),
      includeUseq: lsUtil.getIncludeUseq(),
      includeSupplemental: lsUtil.getIncludeSupplementals(),
      includeIndustry: lsUtil.getIncludeIndustry()
    };
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  onChange(e) {
    this.props.actions.getSymbol(
      e.target.value,
      DEFAULT_MAX_SYMBOL_SEARCH_RESULTS,
      this.state.includeJpeq,
      this.state.includeUseq,
      this.state.includeSupplemental,
      this.state.includeIndustry,
    );
    this.setState({
      search: e.target.value || ''
    });
  }

  handleRowSelection(selectedSymbol, selectedSymbolData) {
    this.setState({
      search: ''
    });

    if (selectedSymbol) {
      this.props.onSelectedSymbol(selectedSymbol, selectedSymbolData);
    }
  }

  getCurrentSearch() {
    return this.props.symbols.searches[this.state.search] || { symbols: [] };
  }

  toggleJpeq() {
    if (this.state.search && this.state.search.length > 0) {
      this.props.actions.getSymbol(
        this.state.search,
        DEFAULT_MAX_SYMBOL_SEARCH_RESULTS,
        !this.state.includeJpeq,
        this.state.includeUseq,
        this.state.includeSupplemental,
        this.state.includeIndustry,
      );
    }
    lsUtil.setIncludeJpeq(!this.state.includeJpeq);
    this.setState({
      includeJpeq: !this.state.includeJpeq
    });
  }

  toggleUseq() {
    if (this.state.search && this.state.search.length > 0) {
      this.props.actions.getSymbol(
        this.state.search,
        DEFAULT_MAX_SYMBOL_SEARCH_RESULTS,
        this.state.includeJpeq,
        !this.state.includeUseq,
        this.state.includeSupplemental,
        this.state.includeIndustry
      );
    }
    lsUtil.setIncludeUseq(!this.state.includeUseq);
    this.setState({
      includeUseq: !this.state.includeUseq
    });
  }

  toggleSupplemental() {
    if (this.state.search && this.state.search.length > 0) {
      this.props.actions.getSymbol(
        this.state.search,
        DEFAULT_MAX_SYMBOL_SEARCH_RESULTS,
        this.state.includeJpeq,
        this.state.includeUseq,
        !this.state.includeSupplemental,
        this.state.includeIndustry
      );
    }
    lsUtil.setIncludeSupplementals(!this.state.includeSupplemental);
    this.setState({
      includeSupplemental: !this.state.includeSupplemental
    });
  }

  toggleIndustry() {
    if (this.state.search && this.state.search.length > 0) {
      this.props.actions.getSymbol(
        this.state.search,
        DEFAULT_MAX_SYMBOL_SEARCH_RESULTS,
        this.state.includeJpeq,
        this.state.includeUseq,
        this.state.includeSupplemental,
        !this.state.includeIndustry
      );
    }
    lsUtil.setIncludeIndustry(!this.state.includeIndustry);
    this.setState({
      includeIndustry: !this.state.includeIndustry
    });
  }

  render() {
    const currentSearch = this.getCurrentSearch();
    const symbols = currentSearch.symbols;
    const placeholder = this.props.intl.formatMessage({
      id: 'label.symbol.code.or.name.search'
    });
    const className = 'symbol-search-container';

    if (symbols && symbols.length) {
      return (
        <div className={className}>
          <SearchText
            includeJpeq={this.state.includeJpeq}
            includeUseq={this.state.includeUseq}
            includeSupplemental={this.state.includeSupplemental}
            includeIndustry={this.state.includeIndustry}
            localize={(id) => this.localize(id)}
            onChange={this.onChange.bind(this)}
            toggleJpeq={() => this.toggleJpeq()}
            toggleUseq={() => this.toggleUseq()}
            toggleSupplemental={() => this.toggleSupplemental()}
            toggleIndustry={() => this.toggleIndustry()}
            search={this.state.search}
          />
          <List className={this.props.className || 'search-list'}>
            {currentSearch.symbols.map((symbol, index) => (
              <div key={'search-item' + index}>
                <SearchItem
                  symbol={symbol}
                  symbolData={this.props.symbols.data[symbol] || {}}
                  onSelect={this.handleRowSelection.bind(this)}
                />
              </div>
            ))}
          </List>
        </div>
      );
    }
    var messageId;

    switch (true) {
      case !this.state.search:
        messageId = 'message.none';
        break;
      case !!currentSearch.isLoading:
        messageId = 'message.status.searching';
        break;
      case !!currentSearch.isError:
        messageId = 'message.status.error';
        break;
      case this.state.includeJpeq +
        this.state.includeUseq +
        this.state.includeSupplemental ===
        0:
        messageId = 'message.no.market.selected';
        break;
      case symbols.length === 0:
        messageId = 'message.no.symbols.found';
        break;
      default:
        messageId = 'message.none';
    }

    return (
      <div className="symbol-search-fixed">
        <SearchText
          includeJpeq={this.state.includeJpeq}
          includeUseq={this.state.includeUseq}
          includeSupplemental={this.state.includeSupplemental}
          includeIndustry={this.state.includeIndustry}
          localize={(id) => this.localize(id)}
          onChange={this.onChange.bind(this)}
          placeholder={placeholder}
          toggleJpeq={() => this.toggleJpeq()}
          toggleUseq={() => this.toggleUseq()}
          toggleSupplemental={() => this.toggleSupplemental()}
          toggleIndustry={() => this.toggleIndustry()}
          search={this.state.search}
          isDisabled={this.props.isDisabled}
        />
        <span className="search-result">
          <FormattedMessage id={messageId} />
        </span>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    symbols: state.symbols
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: {
      getSymbol: bindActionCreators(fetchSymbolPatternIfNeeded, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTracker(injectIntl(SearchItemListContainer)));
