import React from 'react';

const divStyle = {
  margin: '0 auto',
  maxWidth: '50em',
  lineHeight: '1.5',
  padding: '.5em .5em'
};
const pStyle = {
  textAlign: 'center'
};

const footer = {
  clear: 'both',
  position: 'relative',
  marginBottom: '0',
  border: '1px solid #f0f0f0',
  borderRadius: '2px',
  background: '#fafafa'
};

const footerText = {
  fontSize: '75%',
  textAlign: 'center'
};

const linkContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: '25px',
  marginBottom: '30px'
};

const linkButton = {
  backgroundColor: '#f5f5f5',
  padding: '12px 16px',
  borderSadius: '6px',
  boxShadow: '3px 4px 3px 0px rgba(0, 0, 0, 0.2)',
  transitionProperty: 'box-shadow',
  transitionDuration: '0.025s'
};

const MaintenanceMessage = () => (
  <div style={divStyle}>
    <div style={pStyle}>
      <p>
        システムメンテナンスのため、投資情報サービスを一時停止させていただきます。
      </p>
      <p>
        お客様には、ご迷惑をおかけいたしますがご了承いただきますようお願い申し上げます。
      </p>
      <p>
        なお、投資情報につきましては、お取引画面または各種ツールにてご確認をお願いいたします。
      </p>
      <div style={linkContainer}>
        <a href="https://mst.monex.co.jp/mst/servlet/ITS/ucu/MaintenanceAnnounceGST" style={linkButton}>
          メンテナンス時間を確認する
        </a>
      </div>
    </div>
    <div style={footer}>
      <div style={footerText}>
        <p>金融商品取引業者　関東財務局長（金商）第165号</p>
        <p>
          加入協会：日本証券業協会、一般社団法人
          第二種金融商品取引業協会、一般社団法人 金融先物取引業協会、一般社団法人 日本暗号資産取引業協会、一般社団法人 日本投資顧問業協会
        </p>
        <p>
          <a href="https://info.monex.co.jp/policy/risk/index.html">
            リスク・手数料などの重要事項.
          </a>
        </p>
      </div>
    </div>
  </div>
);

const SystemMaintenanceContainer = () => {
  return <MaintenanceMessage />;
};

export default SystemMaintenanceContainer;
