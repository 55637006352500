import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import leftArrowCold from '../../icons/Icon-Left_Arrow_Cold.svg';
import leftArrowHot from '../../icons/Icon-Left_Arrow_Hot.svg';
import rightArrowCold from '../../icons/Icon-Right_Arrow_Cold.svg';
import rightArrowHot from '../../icons/Icon-Right_Arrow_Hot.svg';

const useStyles = makeStyles({
  statusFlag: {
    display: 'inline-block',
    width: '16px',
    height: '16px',
    lineHeight: '15px',
    color: 'white',
    fontSize: '10px'
  },
  leftArrowHot: {
    background: `url('${leftArrowHot}') no-repeat`
  },
  leftArrowCold: {
    background: `url('${leftArrowCold}') no-repeat`
  },
  rightArrowHot: {
    background: `url('${rightArrowHot}') no-repeat`
  },
  rightArrowCold: {
    background: `url('${rightArrowCold}') no-repeat`
  },
  right: {
    marginRight: '2px',
    marginLeft: '3px',
    textAlign: 'center'
  },
  left: {
    marginRight: '3px',
    marginLeft: '4px',
    textAlign: 'center'
  }
});

export const StatusFlag = ({ direction, hot, flag }) => {
  const styles = useStyles();

  if (!flag) return '';

  let background = '';
  let side = '';
  if (direction === 'left') {
    side = styles.right;
    background = hot ? styles.leftArrowHot : styles.leftArrowCold;
  } else {
    side = styles.left;
    background = hot ? styles.rightArrowHot : styles.rightArrowCold;
  }

  return (
    <span className={[styles.statusFlag, side, background].join(' ')}>
      {flag}
    </span>
  );
};
