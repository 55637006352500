import { scanCategoryData } from '../views/scan/ScannerCategory';

const typeMap = {
  0: 'range',
  2: 'select',
  3: 'multiselect'
};

export function getRegexFromCategory(category, options, scanCategoryData) {
  return options.map(option => {
    return new RegExp(scanCategoryData[category].Options[option].Pattern);
  });
}

export function filterSelects(
  symbolData,
  criteria,
  scanCategoryData,
  previousResults = null
) {
  // This gets a list of regex expressions matching the possible options
  // (such as ETF, REIT, Equity) for this selectable scan category
  let regexToTest = getRegexFromCategory(
    criteria.id,
    criteria.values,
    scanCategoryData
  );

  return symbolData.filter(function(s) {
    // If there are previous results and this symbol is not
    // included, don't even bother
    if (previousResults && !previousResults[s.symbol]) {
      return false;
    }

    // If there are no selected values for this criteria, all symbols
    // are considered to "match"
    return (
      criteria.values.length === 0 ||
      regexToTest.some(regex => regex.test(s.data.filterValue))
    );
  });
}

export function filterRange(
  symbolData,
  criteria,
  scanCategoryData,
  previousResults = null
) {
  return symbolData.filter(s => {
    if (s.data.filterValue == null) {
      return false;
    }

    // If there are previous results and this symbol is not
    // included, don't even bother
    if (previousResults && !previousResults[s.symbol]) {
      return false;
    }

    let isGreaterThan =
      // userScanSetting is expected to have 'values', where [0]
      // is the minimum value, and [1] is the maximum value.
      typeof criteria.values[0] == 'number'
        ? criteria.values[0] <= s.data.filterValue
        : true;
    let isLessThan =
      typeof criteria.values[1] == 'number'
        ? s.data.filterValue <= criteria.values[1]
        : true;

    return isGreaterThan && isLessThan;
  });
}

export function getCriteriaOptions(field) {
  switch (field.type) {
    case 'select':
      return field.values[0];
    case 'multiselect':
      return field.values;
    case 'range':
      return {
        lower: field.values[0],
        upper: field.values[1]
      };
    default:
      return null;
  }
}

export function getCriteriaFromScan(scan) {
  let criteria = [],
    criteriaOptions = {};

  if (scan) {
    scan.fields.forEach(field => {
      criteria.push(field.id);
      criteriaOptions[field.id] = getCriteriaOptions(field);
    });
  }
  return [criteria, criteriaOptions];
}

export function buildScan(criteria, selections, scanName, scanId) {
  let scan = {
    name: scanName,
    fields: []
  };

  if (scanId) {
    scan.id = scanId;
  }

  if (!criteria || !selections || !scanName) {
    let message = `[buildScan] Insufficient parameter`;
    console.log(message);
    throw new Error(message);
  }

  for (let i = 0; i < criteria.length; i++) {
    let criterion = criteria[i];
    let criterionData = scanCategoryData[criterion];
    let type = typeMap[criterionData.Input];

    let values = [];

    let selectedValues = selections[criterion];

    switch (type) {
      case 'select':
        // This value is null when no option is selected,
        // or is the string value of the option when selected
        if (selectedValues) {
          values.push(selectedValues);
        }
        break;
      case 'multiselect':
        // This value is null when no option has ever been selected,
        // otherwise it is an array (maybe empty) of all currently
        // selected options
        if (selectedValues) {
          values = selectedValues;
        }
        break;
      case 'range':
        // This value is null when no values have ever been entered,
        // otherwise it is an object with two potential members of
        // lower and upper.  When the value for either is empty, the
        // value is set to an empty string, otherwise it is a number
        // in a string representing the correct value
        if (selectedValues) {
          let lowerValue =
            !selectedValues.lower || selectedValues.lower === ''
              ? null
              : parseFloat(selectedValues.lower);
          let upperValue =
            !selectedValues.upper || selectedValues.upper === ''
              ? null
              : parseFloat(selectedValues.upper);

          values = [lowerValue, upperValue];
        }
        break;
      default:
        let message = `[buildScan] Invalid type specified: ${type}`;
        console.log(message);
        throw new Error(message);
    }

    scan.fields.push({
      id: criterion,
      type: type,
      values: values
    });
  }

  return scan;
}
