import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import makeStyles from '@mui/styles/makeStyles';

import Time from '../common/Time';
import Number from '../common/Number';
import Exchange from '../common/Exchange';
import RadiantChart from '../common/RadiantChart';
import { MobileNote } from '../common/MobileNote';
import { StatusFlag } from '../common/StatusFlag';
import {
  formatNumberOrDefault,
  getQuoteFlag
} from '../common/tables/formatters';

import { calcNetChgPct } from '../../utils/maths';
import { trackNavigation } from '../../utils/analytics';
import { postUserTrade } from '../../utils/userMetrics';
import { fullQuoteSelector, getTradeStatus } from '../../utils/quotes';
import {
  getSymbolClose,
  getSymbolRoot,
  isIndustry,
  isJpeq,
  isSupplemental,
  isUseq
} from '../../utils/symbol';

import useActions from '../../utils/hooks/useActions';
import navigationTypes from '../../actions/_navigationTypes';
import { fetchSymbolIfNeeded } from '../../actions/symbol';
import { start, stop } from '../../actions/stream';

import { getSymbolBuySellUrl, getNewMarginBuyUrl } from '../../utils/formatter';

import MemoIcon from '../../icons/Icon-memo-new.svg';
import GainIcon from '../../icons/Icon-Arrow_Gain.svg';
import LossIcon from '../../icons/Icon-Arrow_Loss.svg';
import NeutralIcon from '../../icons/Icon-Arrow_Neutral.svg';
import CssFlash from '../common/CssFlash';
import { MobileQuoteCardBody } from './MobileQuoteCardBody';
import {
  pnlNetChange,
  pnlNetChangePct,
  pnlValuation
} from '../../utils/marketboards';
import { marketboardFontFamily } from '../../utils/html';
import { StandardWidths, useWidth } from '../../utils/hooks/useWidth';
import { useDesktopWidth } from '../../utils/hooks/useDesktopWidth';

const GAIN_COLOR = '#A64E4A';
const LOSS_COLOR = '#4B5CAD';
const NEUTRAL_COLOR = '#666666';
const GRAPH_STYLE = 'smooth';
const GRAPH_BAR_SIZE = '1hour';
const GRAPH_BAR_QUANTITY = 40;
const GRAPH_SMA_LENGTH = 1;
const GRAPH_STROKE_SIZE = 2;
const GRAPH_WIDTH_SMALL = 72;
const GRAPH_HEIGHT_SMALL = 24;
const GRAPH_WIDTH_STANDARD = 96;
const GRAPH_HEIGHT_STANDARD = 36;
const GRAPH_MARK_HIGH_LOW = false;
const GRAPH_USE_STROKE_COLORS = true;
const GRAPH_USE_SHADOW = true;

const useStylesGlobal = makeStyles({
  symbolDetailsArea: {
    margin: 'auto 0px'
  },
  symbolPriceArea: {
    textAlign: 'right',
    marginRight: '8px'
  },
  symbolName: {
    fontFamily: marketboardFontFamily,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#333333',
    marginRight: '1px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: '4px',
    position: 'relative'
  },
  symbolNameContainer: {
    display: 'flex'
  },
  symbolDetails: {
    height: '16px',
    display: 'block',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#666666'
  },
  symbolDetailItem: {
    marginRight: '5px'
  },
  tradeStatus: {
    marginRight: '5px',
    color: '#A64E4A'
  },
  chart: {
    height: '36px',
    width: '96px',
    textAlign: 'center'
  },
  currentPrice: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#333333',
    '& span': {
      fontFamily: marketboardFontFamily
    }
  },
  netChange: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    '& span': {
      fontFamily: marketboardFontFamily
    },
    textAlign: 'right',
    marginRight: '8px',
    float: 'right'
  },
  netChangePct: {
    paddingLeft: '2px'
  },
  heatPipe: {
    width: '3px',
    height: '100%',
    minHeight: '48px',
    marginLeft: '1px',
    marginRight: '5px',
    borderRadius: '2px',
    background: 'rgba(0, 0, 255, 0.3)'
  },
  memoIcon: {
    position: 'absolute',
    margin: '0px 2px',
    right: '-3px'
  },
  memoContainer: {
    width: '18px',
    position: 'relative'
  },
  indicatorIcon: {
    position: 'relative',
    top: '4px'
  },
  button: {
    width: '64px',
    height: '32px',
    marginRight: '12px',
    borderRadius: '4px',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    border: 0,
    display: 'inline-block',
    '& span': {
      position: 'relative',
      top: '9px'
    }
  },
  buttonDisabled: {
    background: 'rgba(0, 0, 0, 0.1)',
    color: '#999999',
    cursor: 'default'
  },
  buy: {
    background: '#D84E4E',
    color: '#FFFFFF',
    cursor: 'pointer'
  },
  sell: {
    background: '#3D9AD3',
    color: '#FFFFFF',
    cursor: 'pointer'
  },
  margin: {
    background: '#E1C561',
    color: '#FFFFFF',
    cursor: 'pointer'
  },
  details: {
    background: '#FFFFFF',
    color: '#927A22',
    border: '2px solid #927A22',
    position: 'relative',
    top: '2px',
    '& span': {
      position: 'relative',
      top: '7px !important'
    },
    cursor: 'pointer'
  },
  extendedDetailsArea: {
    background: '#EEEEEE',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '2px'
  },
  tradeStatusFont: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px'
  },
  tradeStatusAsk: {
    color: '#4B5CAD',
    float: 'left'
  },
  tradeStatusBid: {
    color: '#A64E4A',
    float: 'right'
  },
  gain5: { background: '#ff3333' },
  gain4: { background: '#ff5959' },
  gain3: { background: '#ff7f7f' },
  gain2: { background: '#ffa6a6' },
  gain1: { background: '#ffcccc' },
  unch: { background: '#f1f1f1' },
  loss1: { background: '#cce1ff' },
  loss2: { background: '#a6cbff' },
  loss3: { background: '#7fb5ff' },
  loss4: { background: '#599eff' },
  loss5: { background: '#3388ff' }
});

const useStylesSmall = (withGraph) => {
  const styles = useMemo(
    () =>
      makeStyles({
        gridQuoteCard: {
          display: 'grid',
          gridTemplateColumns: '7px minmax(0,1fr) minmax(0,72px) 135px',
          gridTemplateRows: '48px',
          padding: '2px',
          width: '100%'
        },
        collapsed: {
          height: '0px',
          display: 'none'
        },
        expanded: {
          minHeight: '150px'
        },
        gridHeatPipe: {
          gridColumn: '1',
          gridRow: '1 / 7'
        },
        gridSimpleDetailsLeft: {
          gridColumn: '2 / span ' + (withGraph ? '1' : '2'),
          gridRow: '1'
        },
        gridSimpleDetailsChart: {
          height: '36px',
          width: '100px',
          gridColumn: '3',
          gridRow: '1',
          alignSelf: 'center'
        },
        gridSimpleDetailsLast: {
          gridColumn: '4',
          gridRow: '1',
          margin: 'auto 0'
        },
        gridSimpleDetailsEnd: {
          gridColumn: '2 / span 4',
          textAlign: 'center',
          borderBottom: '1px solid #00000026',
          height: '3px'
        },
        gridExtendedDetailsRow: {
          gridColumn: '2 / span 4',
          textAlign: 'center',
          paddingTop: '10px'
        },
        extendedDetailsGrid: {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr'
        },
        gridQuoteCardEnd: {
          gridColumn: '1 / span 4',
          textAlign: 'center',
          borderBottom: '1px solid #00000026'
        }
      }),
    [withGraph] // Dependency array
  );

  return styles;
};

async function navigateTo(navigationType, redirectUrl, user, symbolId) {
  trackNavigation(navigationType);

  let data = {
    navigationType: navigationType,
    origin: 'MarketBoard',
    symbol: symbolId,
    redirectUrl: redirectUrl,
    encryptedId: user.encryptedId
  };

  await Promise.race([
    postUserTrade(data),
    new Promise((resolve) => setTimeout(resolve, 400))
  ]);
  window.location.href = redirectUrl;
}

const useStylesMedium = useStylesSmall;
const useStylesLarge = useStylesSmall;

const MobileQuoteRightHeader = ({
  styles,
  boardType,
  close,
  quoteData,
  positionData
}) => {
  let value;
  let netChange;
  let netChangePct;
  let adjustedPrecision = quoteData.precision;

  switch (boardType) {
    case 'cash':
    case 'margin':
      adjustedPrecision = 0;
      value = formatNumberOrDefault(
        pnlValuation(boardType, positionData, close),
        adjustedPrecision,
        0
      );
      netChange = formatNumberOrDefault(
        pnlNetChange(boardType, positionData, close),
        adjustedPrecision,
        0
      );
      netChangePct = formatNumberOrDefault(
        pnlNetChangePct(boardType, positionData, close),
        2,
        0
      );
      break;
    default:
      value = close;
      netChange = close - quoteData.prevClose;
      netChangePct = calcNetChgPct(close, quoteData.prevClose);
      break;
  }

  const isNeutral = netChangePct === 0;
  const gainLossArrowIcon = netChange > 0 ? GainIcon : LossIcon;
  const arrowIndicator = isNeutral ? NeutralIcon : gainLossArrowIcon;
  const netTemperatureGainLoss = netChange > 0 ? GAIN_COLOR : LOSS_COLOR;
  const netTemperature = isNeutral ? NEUTRAL_COLOR : netTemperatureGainLoss;
  return (
    <>
      <div
        id={'quote-last'}
        className={[styles.symbolPriceArea, styles.currentPrice].join(' ')}
      >
        <CssFlash value={value}>
          <Number value={value} precision={adjustedPrecision} />
        </CssFlash>
      </div>
      <div className={styles.netChange} style={{ color: netTemperature }}>
        <span className={styles.indicatorIcon}>
          <img src={arrowIndicator} />
        </span>
        <Number value={netChange} precision={adjustedPrecision} />
        <Number
          value={netChangePct}
          precision={2}
          signed={true}
          suffix={'%)'}
          prefix={'('}
          className={styles.netChangePct}
        />
      </div>
    </>
  );
};

export const MobileQuoteCard = ({
  symbol,
  isVisible,
  boardType,
  positionData
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);

  // Actions
  const [startStream] = useActions([start], []);
  const [stopStream] = useActions([stop], []);
  const [getSymbol] = useActions([fetchSymbolIfNeeded], []);

  // Redux Store
  const channels = useSelector((state) => (state.stream || {}).channels || {});
  const quoteData = fullQuoteSelector(channels, symbol);
  const metadata = useSelector(
    (state) => (state.symbols.data || { data: {} } || {})[symbol] || {}
  );
  const symbolData = metadata.data || {};
  const user = useSelector((state) => state.user || {});
  const memo = useSelector((state) => (state.memos || {})[symbol] || {});
  const marginEnabled = user.isMarginEnabled || false;

  // Intl
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });

  // Width Calculation
  const calculatedWidth = useWidth();
  const displayGraph = calculatedWidth !== StandardWidths.xs;
  const isSmallGraph = calculatedWidth === StandardWidths.sm;

  // Styles
  const smallStyle = useStylesSmall(displayGraph);
  const mediumStyle = useStylesMedium(displayGraph);
  const largeStyle = useStylesLarge(displayGraph);

  let activeStyle = smallStyle;

  if (
    calculatedWidth === StandardWidths.lg ||
    calculatedWidth === StandardWidths.xl
  ) {
    activeStyle = largeStyle;
  }

  if (calculatedWidth === StandardWidths.md) {
    activeStyle = mediumStyle;
  }

  const styles = { ...useStylesGlobal(), ...activeStyle() };

  const symbolChannel = `${symbol}/quote`;

  useEffect(() => {
    if (isVisible && !isStreaming) {
      startStream(symbolChannel);
      setIsStreaming(true);
    }
    if (!isVisible && isStreaming) {
      stopStream(symbolChannel);
      setIsStreaming(false);
    }

    return () => {
      if (isStreaming) {
        stopStream(symbolChannel);
      }
    };
  }, [isStreaming, startStream, stopStream, symbolChannel, isVisible]);

  useEffect(() => {
    getSymbol(symbol);
  }, [symbol, getSymbol]);

  const close = getSymbolClose(symbolData.assetClass, quoteData);

  const netChangePct = calcNetChgPct(close, quoteData.prevClose);
  const isNeutral = netChangePct === 0;

  const tempPrefix = netChangePct > 0 ? 'gain' : 'loss';
  const tempSuffix = Math.min(5, Math.ceil(Math.abs(netChangePct)));
  const temperature = isNeutral
    ? styles.unch
    : styles[`${tempPrefix}${tempSuffix}`];

  let symbolName = symbolData.jpShortName || symbolData.name || '';
  if (metadata.error || symbolData.errorCode) {
    symbolName = localize('message.status.load-failed');
  }
  let symbolRoot = symbolData.root;
  if (metadata.error || symbolData.errorCode) {
    symbolRoot = getSymbolRoot(symbol);
  }

  const assetClass = symbolData.assetClass;
  const isJpeqSymbol = isJpeq(assetClass);
  const isUseqSymbol = isUseq(assetClass);
  const isPurchasable = isJpeqSymbol || isUseqSymbol;
  const isDesktopWidth = useDesktopWidth();

  let leftColumn = (
    <div
      id={'symbol-name'}
      className={[styles.gridSimpleDetailsLeft, styles.symbolDetailsArea].join(
        ' '
      )}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className={styles.symbolNameContainer}>
        <span className={styles.symbolName}>{symbolName}</span>
        <div className={styles.memoContainer}>
          {memo.memo && (
            <div id={'memo-icon'} className={styles.memoIcon}>
              <img src={MemoIcon} alt={memo.memo} />
            </div>
          )}
        </div>
      </div>
      <div id={'symbol-code'} className={styles.symbolDetails}>
        {isSupplemental(symbolData.assetClass) ||
        isIndustry(symbolData.assetClass) ? (
          ' '
        ) : (
          <span className={styles.symbolDetailItem}>{symbolRoot}</span>
        )}
        <span className={styles.symbolDetailItem}>
          <Exchange
            name={symbolData.exchangeName}
            section={symbolData.jpSection}
            assetClass={symbolData.assetClass}
          />
        </span>
        <span className={styles.symbolDetailItem}>
          <Time value={quoteData.lastTime} />
        </span>
        <span className={styles.tradeStatus}>{getTradeStatus(quoteData)}</span>
      </div>
    </div>
  );

  let centerColumn = (
    <div className={'chart'}>
      <RadiantChart
        style={GRAPH_STYLE}
        symbol={symbol}
        width={isSmallGraph ? GRAPH_WIDTH_SMALL : GRAPH_WIDTH_STANDARD}
        height={isSmallGraph ? GRAPH_HEIGHT_SMALL : GRAPH_HEIGHT_STANDARD}
        barSize={GRAPH_BAR_SIZE}
        quantity={GRAPH_BAR_QUANTITY}
        markHighLow={GRAPH_MARK_HIGH_LOW}
        smaLength={GRAPH_SMA_LENGTH}
        useStrokeColors={GRAPH_USE_STROKE_COLORS}
        strokeSize={GRAPH_STROKE_SIZE}
        useShadow={GRAPH_USE_SHADOW}
      />
    </div>
  );

  let extendedContent =
    isExpanded &&
    (() => {
      const precision = quoteData.precision;
      const ask = quoteData.ask;
      const askSize = quoteData.askSize;
      const bid = quoteData.bid;
      const bidSize = quoteData.bidSize;
      const askRow = (
        <>
          <div>
            <span className={styles.tradeStatusAsk}>
              {askSize ? (
                <>
                  <StatusFlag
                    flag={getQuoteFlag(quoteData.askType)}
                    isHot={false}
                  />
                  <Number
                    value={askSize}
                    precision={precision}
                    hideIfZero={true}
                    className={styles.tradeStatusFont}
                  />
                </>
              ) : (
                ''
              )}
            </span>
          </div>
          <div>
            <Number
              value={ask}
              precision={precision}
              hideIfZero={true}
              className={styles.tradeStatusFont}
            />
          </div>
          {/* The below div is necessary as the 3rd column */}
          <div />
        </>
      );

      const bidRow = (
        <>
          {/* The below div is necessary as the 1st column */}
          <div />
          <div>
            <Number
              value={bid}
              precision={precision}
              hideIfZero={true}
              className={styles.tradeStatusFont}
            />
          </div>
          <div>
            <span className={styles.tradeStatusBid}>
              {bidSize ? (
                <>
                  <Number
                    value={bidSize}
                    precision={precision}
                    className={styles.tradeStatusFont}
                  />
                  <StatusFlag
                    flag={getQuoteFlag(quoteData.bidType)}
                    direction={'left'}
                    hot={true}
                  />
                </>
              ) : (
                ''
              )}
            </span>
          </div>
        </>
      );

      const cashBuyButton = (
        <div
          id={'buy-button'}
          className={[
            styles.button,
            isPurchasable ? styles.buy : styles.buttonDisabled
          ].join(' ')}
          onClick={() => {
            isPurchasable &&
              navigateTo(
                navigationTypes.MB_CASH_BUY_CLICK,
                getSymbolBuySellUrl(
                  assetClass,
                  symbol,
                  user,
                  'buy',
                  isDesktopWidth
                ),
                user,
                symbol
              );
          }}
        >
          <span>{localize('button.label.cash.buy')}</span>
        </div>
      );

      const cashSellButton = (
        <div
          id={'sell-button'}
          type={'button'}
          className={[
            styles.button,
            isPurchasable ? styles.sell : styles.buttonDisabled
          ].join(' ')}
          onClick={() => {
            isPurchasable &&
              navigateTo(
                navigationTypes.MB_CASH_SELL_CLICK,
                getSymbolBuySellUrl(
                  assetClass,
                  symbol,
                  user,
                  'sell',
                  isDesktopWidth
                ),
                user,
                symbol
              );
          }}
        >
          <span>{localize('button.label.cash.sell')}</span>
        </div>
      );

      const marginBuyButton = (
        <div
          id={'margin-button'}
          className={[styles.button, styles.margin].join(' ')}
          onClick={() => {
            isPurchasable &&
              navigateTo(
                navigationTypes.MB_MARGIN_ORDER_CLICK,
                getNewMarginBuyUrl(user.dm, user.attrSrcKey, symbol),
                user,
                symbol
              );
          }}
        >
          <span>{localize('button.label.margin.new-order')}</span>
        </div>
      );

      const detailsButton = (
        <div
          id={'details-button'}
          className={[styles.button, styles.details].join(' ')}
          onClick={() => {
            navigateTo(
              navigationTypes.MB_OPEN_SYMBOL_DETAILS,
              `#/symbol/${symbol}`,
              user,
              symbol
            );
          }}
        >
          <span>{localize('button.label.symbol.details')}</span>
        </div>
      );

      return (
        <>
          <div className={styles.gridSimpleDetailsEnd} />
          <div className={styles.gridExtendedDetailsRow}>
            <MobileQuoteCardBody
              boardType={boardType}
              quoteData={quoteData}
              close={close}
              positionData={positionData}
            />
          </div>

          {isJpeqSymbol && (
            <div className={styles.gridExtendedDetailsRow}>
              <div className={styles.extendedDetailsArea}>
                <div className={styles.extendedDetailsGrid}>{askRow}</div>
              </div>
              <div className={styles.extendedDetailsArea}>
                <div className={styles.extendedDetailsGrid}>{bidRow}</div>
              </div>
            </div>
          )}
          <div className={styles.gridExtendedDetailsRow}>
            {cashBuyButton}
            {cashSellButton}
            {marginEnabled && isJpeqSymbol && marginBuyButton}
            {detailsButton}
          </div>
          <div className={styles.gridExtendedDetailsRow}>
            <MobileNote {...memo} symbol={symbol} />
          </div>
        </>
      );
    })();

  return (
    <div id={'quote-card'}>
      <div className={styles.gridQuoteCard}>
        <div
          className={`${styles.gridHeatPipe} ${styles.heatPipe} ${temperature}`}
        />
        {leftColumn}
        <div
          className={styles.gridSimpleDetailsChart}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {displayGraph && centerColumn}
        </div>
        <div
          className={styles.gridSimpleDetailsLast}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <MobileQuoteRightHeader
            styles={styles}
            boardType={boardType}
            close={close}
            quoteData={quoteData}
            positionData={positionData}
          />
        </div>
        {extendedContent}
      </div>
      <div className={styles.gridQuoteCardEnd} />
    </div>
  );
};
