import React from 'react';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis
} from '@dnd-kit/modifiers';

const WithDraggableContext = (children, items, onSortEnd) => {
  const getIndex = (id) => items.indexOf(id);
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over.id) {
          onSortEnd({
            oldIndex: getIndex(active.id),
            newIndex: getIndex(over.id),
          });
        }
      }}
    >
      <SortableContext items={items}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default WithDraggableContext;