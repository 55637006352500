import React from 'react';
import { injectIntl } from 'react-intl';
import { isIndustry, isSupplemental } from '../../utils/symbol';
import { getExchangeResource } from '../../utils/exchange';

class Exchange extends React.PureComponent {
  render() {
    if (
      isSupplemental(this.props.assetClass) ||
      isIndustry(this.props.assetClass)
    ) {
      return (
        <span>
          {this.props.intl.formatMessage({
            id: `symbol.assetType.${this.props.assetClass}`
          })}
        </span>
      );
    }
    if (!this.props.name) {
      return null;
    }

    const resourceID = getExchangeResource(
      this.props.name,
      this.props.section
    );
    return <span>{this.props.intl.formatMessage({ id: resourceID })}</span>;
  }
}

export default injectIntl(Exchange);
