import React from 'react';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  searchResultsEmpty: {
    color: '#9fa6af',
    'font-weight': 'bold',
    padding: '7px'
  }
});

const EmptySearchResult = ({
  includeJpeq,
  includeUseq,
  includeSupplementals
}) => {
  let messageId = 'message.no.market.selected';
  if (includeJpeq || includeUseq || includeSupplementals) {
    messageId = 'message.no.symbols.found';
  }
  const classes = useStyles();
  return (
    <tr>
      <td className={classes.searchResultsEmpty} colSpan="4">
        <FormattedMessage id={messageId} />
      </td>
    </tr>
  );
};

export default EmptySearchResult;
