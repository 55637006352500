import Number from '../Number';
import DateTime from '../DateTime';
import React from 'react';
import {
  get52WeekHighFlag,
  get52WeekLowFlag,
  getAskTypeFlag,
  getBidTypeFlag
} from './formatters';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import Time from '../Time';
import { tableFont } from './fonts';

const useStyles = makeStyles({
  symbolCodeAndExchange: {
    cursor: 'text'
  },
  symbolStatus: {
    color: 'red',
    fontWeight: 'bold'
  },
  hyperlink: {
    '& a': {
      color: '#1155CC'
    },
    '& a:link': {
      textDecoration: 'none'
    },
    '& a:hover': {
      textDecoration: 'underline'
    }
  },
  high: {
    color: '#e01515'
  },
  low: {
    color: '#146dc8'
  },
  bid: {
    color: '#e01515'
  },
  ask: {
    color: '#146dc8'
  },
  long: {
    color: '#e01515'
  },
  short: {
    color: '#146dc8'
  },
  date: {
    color: 'gray',
    fontSize: '10px'
  },
  signed: {
    '& .positive': {
      color: '#e01515'
    },
    '& .negative': {
      color: 'blue'
    },
    '& .zero': {
      color: 'black'
    }
  },
  font: tableFont
});

export const FlashCell = (props) => {
  const classes = useStyles();
  const precision = (props.data || {}).precision ?? 0;

  return (
    <Number
      value={props.value}
      precision={precision}
      className={classes.font}
    />
  );
};
export const FlashCellSigned = (props) => {
  const classes = useStyles();
  const precision = (props.data || {}).precision ?? 0;

  return (
    <span className={classes.signed}>
      <Number
        value={props.value}
        precision={precision}
        signed={true}
        className={classes.font}
      />
    </span>
  );
};
export const FlashCellPct = (props) => {
  const classes = useStyles();

  return (
    <Number
      value={props.value}
      precision="2"
      suffix="%"
      className={classes.font}
    />
  );
};
export const FlashCellPctSigned = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.signed}>
      <Number
        value={props.value}
        precision="2"
        suffix="%"
        signed={true}
        className={classes.font}
      />
    </span>
  );
};
export const FlashCellStock = (props) => {
  const classes = useStyles();

  return (
    <Number
      value={props.value}
      precision="0"
      suffix="株"
      className={classes.font}
    />
  );
};
export const WholeNumberCellFormatter = (props) => {
  const classes = useStyles();

  return <Number value={props.value} precision="0" className={classes.font} />;
};
export const WholeNumberCellFormatterSigned = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.signed}>
      <Number
        value={props.value}
        precision="0"
        signed={true}
        className={classes.font}
      />
    </span>
  );
};
export const DateTimeCellFormatter = (props) => {
  const classes = useStyles();
  return (
    <span>
      <DateTime
        value={props.value}
        format={'YYYY/MM/DD'}
        hasZeroPad={true}
        className={classes.font}
      />
    </span>
  );
};

export const SymbolCodeCellRenderer = (props) => {
  const classes = useStyles();

  return (
    <div>
      <span className={[classes.symbolCodeAndExchange, classes.font].join(' ')}>
        {props.data.symbolCodeAndExchange}
      </span>
      &nbsp;
      <span className={[classes.symbolStatus, classes.font].join(' ')}>
        {props.data.symbolStatus}
      </span>
    </div>
  );
};

export const SymbolNameCellRenderer = (props) => {
  const classes = useStyles();

  return (
    <span className={[classes.hyperlink, classes.font].join(' ')}>
      <a href={`#/symbol/${props.data.symbol}`}>{props.data.symbolName}</a>
    </span>
  );
};

export const SymbolHighCellRenderer = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });
  const precision = (props.data || {}).precision ?? 0;

  let highFlag = get52WeekHighFlag(props.data, localize);

  return (
    <div>
      {highFlag ? <span className={classes.high}>{highFlag}&nbsp;</span> : null}
      <Number
        value={props.data.high}
        precision={precision}
        className={classes.font}
      />
    </div>
  );
};

export const SymbolLowCellRenderer = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });
  const precision = (props.data || {}).precision ?? 0;

  let lowFlag = get52WeekLowFlag(props.data, localize);

  return (
    <div>
      {lowFlag ? <span className={classes.low}>{lowFlag}&nbsp;</span> : null}
      <Number
        value={props.data.low}
        precision={precision}
        className={classes.font}
      />
    </div>
  );
};

export const SymbolBidCellRenderer = (props) => {
  const classes = useStyles();
  const precision = (props.data || {}).precision ?? 0;

  return (
    <div>
      <span className={classes.bid}>{getBidTypeFlag(props.data)}</span>&nbsp;
      <Number
        value={props.data.bid}
        precision={precision}
        className={classes.font}
      />
    </div>
  );
};

export const SymbolAskCellRenderer = (props) => {
  const classes = useStyles();
  const precision = (props.data || {}).precision ?? 0;

  return (
    <div>
      <span className={classes.ask}>{getAskTypeFlag(props.data)}</span>&nbsp;
      <Number
        value={props.data.ask}
        precision={precision}
        className={classes.font}
      />
    </div>
  );
};

export const SymbolHigh52WeekCellRenderer = (props) => {
  const classes = useStyles();
  const precision = (props.data || {}).precision ?? 0;

  return (
    <div>
      <Number
        value={props.data.high52Week}
        precision={precision}
        className={classes.font}
      />
      &nbsp;
      <span className={classes.date}>
        <DateTime
          value={props.data.high52WeekTime}
          format={'M/D'}
          hasZeroPad={true}
        />
      </span>
    </div>
  );
};

export const SymbolLow52WeekCellRenderer = (props) => {
  const classes = useStyles();
  const precision = (props.data || {}).precision ?? 0;

  return (
    <div>
      <Number
        value={props.data.low52Week}
        precision={precision}
        className={classes.font}
      />
      &nbsp;
      <span className={classes.date}>
        <DateTime
          value={props.data.low52WeekTime}
          format={'M/D'}
          hasZeroPad={true}
        />
      </span>
    </div>
  );
};

export const MarginSideCellRenderer = (props) => {
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });
  const classes = useStyles();
  const side = props.value.toLowerCase();
  const sideClass = side === 'short' ? classes.short : classes.long;

  return (
    <div>
      <span>
        {localize('marketboard.row.tag.margin.seido')}
        {localize('common.separator.period')}
      </span>
      <span className={sideClass}>
        {localize(`marketboard.row.tag.margin.${side}`)}
      </span>
    </div>
  );
};

export const LastTimeCellRenderer = (props) => {
  return <Time value={props.data.lastTime} />;
};

export const MemoCellRenderer = (props) => {
  const classes = useStyles();
  return <span className={classes.font}>{props.value}</span>;
};
