import React from 'react';
import { injectIntl } from 'react-intl';
import FundamentalAnalysisRow from './FundamentalAnalysisRow';
import DateTime from '../common/DateTime';

const i18nKeyPrefix = 'symbol.details.indicators.fundamentalAnalysis.header.';

class FundamentalAnalysisSection extends React.PureComponent {
  localize(i18nKey, value) {
    if (value) {
      value = {
        '0': value
      };
    }
    return this.props.intl.formatMessage(
      {
        id: i18nKey
      },
      value
    );
  }

  getPeriodDateTitle(field, checkAnnouncementDate) {
    if (checkAnnouncementDate && !this.props[field].announcementDate) {
      return null;
    }

    let dateText = this.props[field].periodDate;
    if (!dateText) {
      return null;
    }

    return (
      <DateTime
        format="YYYY/MM"
        value={dateText}
        suffix={this.localize(i18nKeyPrefix + 'period.date')}
      />
    );
  }

  getAnnouncementDateTitle(field) {
    let dateText = this.props[field].announcementDate;
    if (!dateText) {
      return null;
    }

    return (
      <DateTime
        format="MM/DD"
        value={dateText}
        suffix={this.localize(i18nKeyPrefix + 'announcement.date')}
      />
    );
  }

  getConsolidatedTitle(field, attributeToCheck) {
    if (!this.props[field][attributeToCheck]) {
      return null;
    }

    let consolidated = this.props[field].consolidated;
    let key = consolidated ? 'consolidated' : 'nonConsolidated';
    return this.localize(i18nKeyPrefix + key);
  }

  render() {
    // 2019/03期 実績 05/15発表 連結
    const titlePrevious = (
      <div>
        <div>{this.getPeriodDateTitle('companyResults', true)}</div>
        <div>{this.localize(i18nKeyPrefix + 'results')}</div>
        <div>{this.getAnnouncementDateTitle('companyResults')}</div>
        <div>
          {this.getConsolidatedTitle('companyResults', 'announcementDate')}
        </div>
      </div>
    );

    // 2019/03期 会社予想 07/31発表 連結
    const titleForecast = (
      <div>
        <div>{this.getPeriodDateTitle('companyEstimates', true)}</div>
        <div>{this.localize(i18nKeyPrefix + 'estimates')}</div>
        <div>{this.getAnnouncementDateTitle('companyEstimates')}</div>
        <div>
          {this.getConsolidatedTitle('companyEstimates', 'announcementDate')}
        </div>
      </div>
    );

    // 2020/03期 アナリスト 予想 連結
    const titleAnalyst = (
      <div>
        <div>{this.getPeriodDateTitle('analyst', false)}</div>
        <div>{this.localize(i18nKeyPrefix + 'analyst')}</div>
        <div>{this.localize(i18nKeyPrefix + 'prediction')}</div>
        <div>{this.getConsolidatedTitle('analyst', 'periodDate')}</div>
      </div>
    );

    return (
      <div className="symbol-details-indicators-fundamental-analysis-section">
        <div className="section-header symbol-indicators-title">
          {this.localize('symbol.details.indicators.fundamentalAnalysis.title')}
        </div>
        <div className="symbol-indicator-fundamental-analysis-details">
          <div className="exchange-summary-detail-table">
            <div className="exchange-summary-detail-table-heading">
              <div className="exchange-summary-detail-table-row">
                <div className="exchange-summary-detail-table-head"></div>
                <div className="exchange-summary-detail-table-head">
                  {titlePrevious}
                </div>
                <div className="exchange-summary-detail-table-head symbol-indicators-fundamental-anaylis-title">
                  {titleForecast}
                </div>
                <div className="exchange-summary-detail-table-head symbol-indicators-fundamental-anaylis-title">
                  {titleAnalyst}
                </div>
              </div>
            </div>
            <div className="exchange-summary-detail-table-body">
              <FundamentalAnalysisRow
                title="netSales"
                value1={{
                  value: this.props.companyResults.netSales
                }}
                value2={{
                  value: this.props.companyEstimates.netSales
                }}
                value3={{
                  value: this.props.analyst.netSales
                }}
              />
              <FundamentalAnalysisRow
                title="netSalesChange"
                value1={{
                  value: this.props.companyResults.netSalesChange,
                  precision: 2,
                  suffix: '%'
                }}
                value2={{
                  value: this.props.companyEstimates.netSalesChange,
                  precision: 2,
                  suffix: '%'
                }}
                // Analyst value is relative to last week's value; per Monex do not display
                value3={{
                  value: 0,
                  hideIfZero: true
                }}
              />
              <FundamentalAnalysisRow
                title="ordinaryProfit"
                value1={{
                  value: this.props.companyResults.ordinaryProfit
                }}
                value2={{
                  value: this.props.companyEstimates.ordinaryProfit
                }}
                value3={{
                  value: this.props.analyst.ordinaryProfit
                }}
              />
              <FundamentalAnalysisRow
                title="ordinaryProfitChange"
                value1={{
                  value: this.props.companyResults.ordinaryProfitChange,
                  precision: 2,
                  suffix: '%'
                }}
                value2={{
                  value: this.props.companyEstimates.ordinaryProfitChange,
                  precision: 2,
                  suffix: '%'
                }}
                // Analyst value is relative to last week's value; per Monex do not display
                value3={{
                  value: 0,
                  hideIfZero: true
                }}
              />
              <FundamentalAnalysisRow
                title="netProfit"
                value1={{
                  value: this.props.companyResults.netProfit
                }}
                value2={{
                  value: this.props.companyEstimates.netProfit
                }}
                value3={{
                  value: this.props.analyst.netProfit
                }}
              />
              <FundamentalAnalysisRow
                title="netProfitChange"
                value1={{
                  value: this.props.companyResults.netProfitChange,
                  precision: 2,
                  suffix: '%'
                }}
                value2={{
                  value: this.props.companyEstimates.netProfitChange,
                  precision: 2,
                  suffix: '%'
                }}
                // Analyst value is relative to last week's value; per Monex do not display
                value3={{
                  value: 0,
                  hideIfZero: true
                }}
              />
              <FundamentalAnalysisRow
                title="earningsPerShare"
                value1={{
                  value: this.props.companyResults.earningsPerShare,
                  precision: 2
                }}
                value2={{
                  value: this.props.companyEstimates.earningsPerShare,
                  precision: 2
                }}
                value3={{
                  value: 0,
                  hideIfZero: true
                }}
              />
              <FundamentalAnalysisRow
                title="priceEarningsRatio"
                value1={{
                  value: this.props.companyResults.priceEarningsRatio,
                  precision: 2,
                  suffix: this.localize(
                    'symbol.details.indicators.fundamentalAnalysis.times'
                  )
                }}
                value2={{
                  value: this.props.companyEstimates.priceEarningsRatio,
                  precision: 2,
                  suffix: this.localize(
                    'symbol.details.indicators.fundamentalAnalysis.times'
                  )
                }}
                value3={{
                  value: this.props.analyst.priceEarningsRatio,
                  precision: 2,
                  suffix: this.localize(
                    'symbol.details.indicators.fundamentalAnalysis.times'
                  )
                }}
              />
              <FundamentalAnalysisRow
                title="priceBookRatio"
                value1={{
                  value: this.props.companyResults.priceBookRatio,
                  precision: 2,
                  suffix: this.localize(
                    'symbol.details.indicators.fundamentalAnalysis.times'
                  )
                }}
                value2={{
                  hide: true
                }}
                value3={{
                  hide: true
                }}
              />
              <FundamentalAnalysisRow
                title="returnOnAverageEquity"
                value1={{
                  value: this.props.companyResults.returnOnEquity,
                  precision: 2,
                  suffix: '%'
                }}
                value2={{
                  hide: true
                }}
                value3={{
                  hide: true
                }}
              />
              <FundamentalAnalysisRow
                title="returnOnAverageAssets"
                value1={{
                  value: this.props.companyResults.returnOnAssets,
                  precision: 2,
                  suffix: '%'
                }}
                value2={{
                  hide: true
                }}
                value3={{
                  hide: true
                }}
              />
              <FundamentalAnalysisRow
                title="bookValuePerShare"
                value1={{
                  value: this.props.companyResults.bookValuePerShare,
                  precision: 2
                }}
                value2={{
                  hide: true
                }}
                value3={{
                  hide: true
                }}
              />
              <FundamentalAnalysisRow
                title="capitalAdequacyRatio"
                value1={{
                  value: this.props.companyResults.capitalAdequacyRatio,
                  precision: 2,
                  suffix: '%'
                }}
                value2={{
                  hide: true
                }}
                value3={{
                  hide: true
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
FundamentalAnalysisSection = injectIntl(FundamentalAnalysisSection);

export default FundamentalAnalysisSection;
