import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import HelpIcon from '../../icons/Icon-help.svg';
import { getSometaroLink, getSymbolDetailsLink } from '../../utils/formatter';

function getSometaroLinkUrl(route, user, symbolId) {
  switch(route) {
    case 'symbol':
      return getSymbolDetailsLink(
        user.encryptedId,
        user.hash,
        symbolId,
        user.rf,
        user.dm,
        user.attrSrcKey,
        user.rz
      );
    default:
      return getSometaroLink(
        user.encryptedId,
        user.hash,
        user.rf,
        user.dm,
        user.attrSrcKey,
        user.rz,
        route
      );
  }
}

function getHelpLinkUrl(route) {
  // TODO: need a better way to manage the desktop help urls once business is ready to release the others,
  // but for go-live we will only have the marketboard pro help page

  // NOTE: we will only display the help link on the page if the url exists here
  switch(route) {
    case 'marketboard':
      return 'https://info.monex.co.jp/help/market/marketboard01.html';
    default:
      return ''
  }
}

// Since we are using React Router v4, in order to pass props to the component,
// we need to use the render function:
// https://ui.dev/react-router-pass-props-to-components
export const DesktopHelpBar = ({ route, symbolId }) => {
  const user = useSelector(state => state.user);
  const sometaroLinkUrl = getSometaroLinkUrl(route, user, symbolId);
  const helpLinkUrl = getHelpLinkUrl(route);

  return (
      <div
        id="gnavi-mobile-help-bar"
        className={'gnavi-mobile gnavi-mobile-help-append desktop'}
      >
        {helpLinkUrl && (
          <span>
            <a
              className={'gnavi-mobile-help-link desktop'}
              href={helpLinkUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={'gnavi-mobile-help-icon'}
                src={HelpIcon}
                alt={'Help'}
              />
              <FormattedMessage id="helpBar.help" />
            </a>
          </span>
        )}
        <span style={{ marginRight: '10px' }}>
          <a
            className={'gnavi-mobile-sometaro-link desktop'}
            href={sometaroLinkUrl}
            rel="noopener noreferrer"
          >
            {<FormattedMessage id="helpBar.sometaro" />}
          </a>
        </span>
      </div>
  );
};
