import React from 'react';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageTitleBar } from '../common/PageTitleBar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MobileSymbolSearchContainer from './MobileSymbolSearchContainer';
import {
  postMarketboards,
  updateMarketboardSymbol
} from '../../actions/marketboards';
import withTracker from '../../utils/analytics';
import { isMonexApp } from '../../utils/common';

class MarketBoardSymbolSearchContainer extends React.Component {
  constructor(props) {
    super(props);
    this.getMarketboardEditUrl = this.getMarketboardEditUrl.bind(this);
    this.handleSelectedSymbol = this.handleSelectedSymbol.bind(this);
  }

  componentDidMount() {
    document.body.setAttribute('data-page-name', 'marketboard-edit-symbol');
  }

  onBackClick() {
    window.history.back();
  }

  getMarketboardEditUrl() {
    return '/marketboard/edit/' + this.props.match.params.id;
  }

  handleSelectedSymbol(symbol) {
    this.props.actions.updateSymbol(
      this.props.match.params.id,
      this.props.match.params.symbolposition,
      symbol
    );
    this.props.actions.saveMarketboard();
    window.history.back();
  }

  render() {
    const marketboardId = this.props.match.params.id;
    const backButton = !isMonexApp() && (
      <IconButton onClick={this.onBackClick} size="large">
        <ArrowBackIcon />
      </IconButton>
    );
    return (
      <div>
        {!isMonexApp() && (
          <PageTitleBar
            title={this.props.intl.formatMessage({
              id: 'marketboard.title.add.symbol'
            })}
            leftButton={backButton}
          />
        )}
        <MobileSymbolSearchContainer
          marketboardId={marketboardId}
          onSelectedSymbol={this.handleSelectedSymbol}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: {
      updateSymbol: bindActionCreators(updateMarketboardSymbol, dispatch),
      saveMarketboard: bindActionCreators(postMarketboards, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTracker(injectIntl(MarketBoardSymbolSearchContainer)));
