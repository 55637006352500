import React, { useCallback, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../icons/Icon-List-Delete.svg';
import { ReactComponent as YellowAlertIcon } from '../../icons/Icon-Yellow-Alert.svg';
import { ReactComponent as RedAlertIcon } from '../../icons/Icon-Red-Alert.svg';
import { FormattedMessage } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { marketboardFontFamily } from '../../utils/html';

const ERR_ALERT = 1;
const INFO_ALERT = 2;

const useStyles = makeStyles({
  globalAlertBar: {
    minHeight: '48px',
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#333333',
    display: 'flex',
    cursor: 'pointer'
  },
  globalAlertMessage: {
    width: 'calc(100% - 96px)',
    overflow: 'hidden',
    paddingTop: '16px',
    paddingBottom: '13px'
  },
  globalAlertDismiss: {
    float: 'right',
    position: 'relative',
    top: '2px',
    left: '9px'
  },
  expandedMessage: {
    whiteSpace: 'unset',
    overflow: 'unset',
    textOverflow: 'unset'
  },
  alertIcon: {
    padding: '12px 6px 0px 12px'
  },
  alert: {
    background: '#F6DCDC'
  },
  info: {
    background: '#F2ECDA'
  }
});

const MobileMessageBar = ({ message, onDismissMessage }) => {
  const [expanded, setExpanded] = useState(false);
  const [dismissed, setDismissed] = useState(false);

  const toggleExpansion = useCallback(() => {
    setExpanded(!expanded);
  }, [setExpanded, expanded]);

  const handleDismissMessage = useCallback(() => {
    setDismissed(true);
    onDismissMessage();
  }, [setDismissed, onDismissMessage]);

  const styles = useStyles();

  if (dismissed) {
    return <div />;
  }

  const noMessage = !message || !message.data || !message.data.message;
  const messageBody = noMessage ? (
    <span
      className={[
        styles.globalAlertMessage,
        expanded ? styles.expandedMessage : ''
      ].join(' ')}
    >
      <FormattedMessage id={message.TranslationId} values={message.Values} />
    </span>
  ) : (
    <span
      className={[
        styles.globalAlertMessage,
        expanded ? styles.expandedMessage : ''
      ].join(' ')}
      dangerouslySetInnerHTML={{
        __html: message.data.message
      }}
    />
  );

  const dismissible = noMessage || message.data.dismissable !== false;
  const alertType = message.typeId;
  const alertIcon = (
    <span className={styles.alertIcon}>
      {alertType === INFO_ALERT ? <YellowAlertIcon /> : <RedAlertIcon />}
    </span>
  );

  const dismissButton = dismissible ? (
    <span className={styles.globalAlertDismiss}>
      <IconButton
        onClick={() => handleDismissMessage(message.Key)}
        size="large"
      >
        <DeleteIcon />
      </IconButton>
    </span>
  ) : (
    <div />
  );

  return (
    <div
      onClick={toggleExpansion}
      className={[
        styles.globalAlertBar,
        alertType === ERR_ALERT ? styles.alert : styles.info
      ].join(' ')}
    >
      {alertIcon}
      {messageBody}
      {dismissButton}
    </div>
  );
};

export default MobileMessageBar;
