import React, { useState, useLayoutEffect } from 'react';
import Ticker from './Ticker';

import 'react-multi-carousel/lib/styles.css';

import { makeStyles } from '@mui/styles';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

const DEFAULT_TICKERS = [
  'JP:$MNK',
  '@OSENK',
  'JP:$0000-TS',
  'USDJPY',
  'JP:$8802-TS',
  '$DJI',
  '$COMPX',
  'JP:$M000001'
];

const TICKER_MAX_WIDTH = 200;
const TICKER_SYMBOLS_KEY = 'tickerSymbols';

const useStyles = makeStyles({
  tickerContainer: {
    marginTop: 2,
    position: 'relative',
    textAlign: 'center',
    margin: '0 auto',
    zIndex: 2,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    height: '50px'
  },
  tickerBar: {
    height: '50px',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    backgroundColor: 'white'
  },
  tickerBarFixed: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    backgroundColor: 'white',
    position: 'fixed',
    top: 0,
    '-moz-box-shadow': '0px 0px 5px 2px rgba(0, 0, 0, 0.2)',
    '-webkit-box-shadow': '0px 0px 5px 2px rgba(0, 0, 0, 0.2)',
    '-ms-box-shadow': '0px 0px 5px 2px rgba(0, 0, 0, 0.2)',
    'box-shadow': '0px 0px 5px 2px rgba(0, 0, 0, 0.2)',
    borderRadius: '2px'
  }
});

function getTickerSymbols(defaultValue) {
  let value = localStorage.getItem(TICKER_SYMBOLS_KEY);
  if (!value) {
    return defaultValue;
  }

  return JSON.parse(value);
}

export const TickerContainer = () => {
  // Hooks
  const classes = useStyles();

  const [isScrolling, setIsScrolling] = useState(false);

  useLayoutEffect(() => {
    const onScroll = () => {
      if (174 < window.scrollY) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const { width } = useWindowDimensions();

  // Local State
  const getSymbols = () => {
    let tickers = getTickerSymbols(DEFAULT_TICKERS);
    let maxAllowedAmount = Math.floor(width / TICKER_MAX_WIDTH);
    if (maxAllowedAmount >= tickers.length) {
      return tickers;
    }

    return tickers.slice(0, maxAllowedAmount);
  };

  const symbolTickers = getSymbols().map((symbol) => (
    <Ticker symbol={symbol} key={symbol} />
  ));

  return (
    <div className={classes.tickerContainer}>
      <div className={isScrolling ? classes.tickerBarFixed : classes.tickerBar}>
        {symbolTickers}
      </div>
    </div>
  );
};
