import React from 'react';
import { injectIntl } from 'react-intl';
import Number from '../common/Number';
import { getFundamentalValue } from '../../utils/fundamentals';

const i18nKeyPrefix = 'symbol.details.indicators.scoreAnalysis.';

class ScoreAnalysisSection extends React.PureComponent {
  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  getFieldValue(field) {
    let value = getFundamentalValue(this.props.fundamentals, field, 'value');
    if (typeof value !== 'number') {
      return null;
    }

    return value * 10;
  }

  getRow(valueObj) {
    return (
      <div className="score-analysis-row">
        <div className="label">
          {this.localize(i18nKeyPrefix + valueObj.title)}
        </div>
        <div className="value">
          <Number value={valueObj.company} />
        </div>
        <div className="thermometer">
          <div
            className="company"
            style={{
              width: (valueObj.company || 0) + '%'
            }}
          ></div>
          <div className="line"></div>
          <div
            className="sector"
            style={{
              width: (valueObj.sector || 0) + '%'
            }}
          ></div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="symbol-details-indicators-fundamental-analysis-section">
        <div className="section-header symbol-indicators-title">
          {this.localize(i18nKeyPrefix + 'title')}
        </div>
        <div className="symbol-indicator-score-analysis-header">
          <span className="title title1">
            {this.localize(i18nKeyPrefix + 'ifisScore')}
          </span>
          <span className="value value1">
            <Number value={this.getFieldValue('averageScore')} />
          </span>
          <span className="title">
            {this.localize(i18nKeyPrefix + 'sectorAverageScore')}
          </span>
          <span className="value value2">
            <Number value={this.getFieldValue('sectorAverageScore')} />
          </span>
        </div>
        {this.getRow({
          title: 'growth',
          company: this.getFieldValue('growthScore'),
          sector: this.getFieldValue('sectorGrowthScore')
        })}
        {this.getRow({
          title: 'value',
          company: this.getFieldValue('valueScore'),
          sector: this.getFieldValue('sectorValueScore')
        })}
        {this.getRow({
          title: 'size',
          company: this.getFieldValue('sizeScore'),
          sector: this.getFieldValue('sectorSizeScore')
        })}
        {this.getRow({
          title: 'technical',
          company: this.getFieldValue('technicalScore'),
          sector: this.getFieldValue('sectorTechnicalScore')
        })}
        {this.getRow({
          title: 'fiscalHealth',
          company: this.getFieldValue('fiscalHealthScore'),
          sector: this.getFieldValue('sectorFiscalHealthScore')
        })}
        {this.getRow({
          title: 'marketTrend',
          company: this.getFieldValue('marketTrendScore'),
          sector: this.getFieldValue('sectorMarketTrendScore')
        })}
      </div>
    );
  }
}
ScoreAnalysisSection = injectIntl(ScoreAnalysisSection);

export default ScoreAnalysisSection;
