import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { initWebSocket, start, stop } from '../../actions/stream';
import { calcNetChgPct } from '../../utils/maths';
import { fetchSymbolIfNeeded } from '../../actions/symbols';
import Number from '../common/Number';
import DateTime from '../common/DateTime';
import RadiantChart from '../common/RadiantChart';
import CssFlash from '../common/CssFlash';
import { getSymbolClose } from '../../utils/symbol';

const loadingTimeStamp = '00/00 00:00';
const loadingDate = '0/0';
const loadingString = '-';
const defaultPrecision = 1;

const omitSymbols = ['@OSENK', '@SGXNK', 'JP:$A820-TS'];

class SymbolSummaryRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      streaming: false,
      initialized: false
    };
  }

  componentDidMount() {
    this.props.actions.getSymbol(this.props.symbolData.symbolCode);
    if (!this.state.streaming) {
      this.props.actions.start(`${this.props.symbolData.symbolCode}/quote`);
      this.setState({ streaming: true });
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this.props.actions.stop(`${this.props.symbolData.symbolCode}/quote`);
  }

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  render() {
    const symbolQuote = this.props;
    const symbolInfo = symbolQuote.symbolData || {};
    const symbolNameData = symbolQuote.symbolsName;
    const assetClass = symbolNameData.assetClass;

    const symbol = symbolInfo.symbolCode;
    const quoteObj = this.props.stream[`${symbol}/quote`];

    let symbolName;
    let isLoading = true;
    let isNotLink = false;
    if (quoteObj) {
      isLoading = false;

      // Get last price based on asset class and set it to lastPrice field
      quoteObj.lastPrice = getSymbolClose(assetClass, quoteObj);
    }

    if (omitSymbols.includes(symbol)) {
      symbolName = this.localize(`market.summary.symbol.${symbol}`);
      isNotLink = true;
    } else if (symbolNameData) {
      symbolName = symbolNameData.jpShortName || symbolNameData.name;
    }

    return (
      <div>
        {isNotLink ? (
          this.createSymbolItem(
            isLoading,
            quoteObj,
            symbol,
            symbolName,
            isNotLink
          )
        ) : (
          <Link to={`/symbol/${symbol}`}>
            {this.createSymbolItem(isLoading, quoteObj, symbol, symbolName)}
          </Link>
        )}
      </div>
    );
  }

  createSymbolItem(isLoading, quoteObj, symbol, symbolName, isNotLink) {
    return (
      <div className={`market_summary_symbol${isNotLink ? ' notLink' : ''}`}>
        <div class="container">
          <div class="market_summary_symbol-quote-card symbol-name">
            <div className="market_summary_symbol__row one">
              <span
                className={`flag-icon mi_flag_${this.props.symbolData.flagCode}`}
              />
              <span
                className={`hotlist_value__symbol-name${
                  isNotLink ? ' notLink' : ''
                }`}
              >
                {symbolName ? symbolName : loadingString}
              </span>
            </div>
            <div className="market_summary_symbol__row two">
              <span className="market_summary_symbol__quote-time">
                {isLoading ? (
                  loadingTimeStamp
                ) : (
                  <DateTime value={quoteObj.tradeTime} hasZeroPad={true} />
                )}
                {this.localize('market.summary.symbol.time.updated')}
              </span>
            </div>
          </div>
          <div class="symbolFields">
            <div class="market_summary_symbol-quote-card sparkline">
              <div className="market_summary_symbol__row one">
                <div class="market_summary_symbol__quote sparkline">
                  {!isNotLink && <RadiantChart symbol={symbol} />}
                </div>
              </div>
            </div>
            <div class="market_summary_symbol-quote-card price-low">
              <div className="market_summary_symbol__row one">
                {isLoading ? (
                  <span className="market_summary_symbol__quote price-low">
                    {loadingString}
                  </span>
                ) : (
                  <CssFlash value={quoteObj.low} isChange={false}>
                    <Number
                      className="market_summary_symbol__quote price-low"
                      value={quoteObj.low}
                      precision={quoteObj.precision}
                      hideIfZero={true}
                    />
                  </CssFlash>
                )}
              </div>
            </div>
            <div class="market_summary_symbol-quote-card price-high">
              <div className="market_summary_symbol__row one">
                {isLoading ? (
                  <span className="market_summary_symbol__quote price-high">
                    {loadingString}
                  </span>
                ) : (
                  <CssFlash value={quoteObj.high} isChange={false}>
                    <Number
                      className="market_summary_symbol__quote price-high"
                      value={quoteObj.high}
                      precision={quoteObj.precision}
                      hideIfZero={true}
                    />
                  </CssFlash>
                )}
              </div>
            </div>
            <div class="market_summary_symbol-quote-card price-open">
              <div className="market_summary_symbol__row one">
                {isLoading ? (
                  <span className="market_summary_symbol__quote price-open">
                    {loadingString}
                  </span>
                ) : (
                  <CssFlash value={quoteObj.open} isChange={false}>
                    <Number
                      className="market_summary_symbol__quote price-open"
                      value={quoteObj.open}
                      precision={quoteObj.precision}
                      hideIfZero={true}
                    />
                  </CssFlash>
                )}
              </div>
            </div>
            <div class="market_summary_symbol-quote-card price-volume">
              <div className="market_summary_symbol__row one">
                {isLoading ? (
                  <span className="market_summary_symbol__quote price-volume">
                    {loadingString}
                  </span>
                ) : (
                  <CssFlash value={quoteObj.volume} isChange={false}>
                    <Number
                      className="market_summary_symbol__quote price-volume"
                      value={quoteObj.volume}
                      hideIfZero={true}
                    />
                  </CssFlash>
                )}
              </div>
            </div>
            <div class="market_summary_symbol-quote-card price-prevclose">
              <div className="market_summary_symbol__row one">
                {isLoading ? (
                  <span className="market_summary_symbol__quote price-prevclose">
                    {loadingString}
                  </span>
                ) : (
                  <CssFlash value={quoteObj.prevClose} isChange={false}>
                    <Number
                      className="market_summary_symbol__quote price-prevclose"
                      value={quoteObj.prevClose}
                      precision={quoteObj.precision}
                      hideIfZero={true}
                    />
                  </CssFlash>
                )}
              </div>
            </div>
            <div class="market_summary_symbol-quote-card price-52low">
              <div className="market_summary_symbol__row one">
                {isLoading ? (
                  <span className="market_summary_symbol__quote price-52low">
                    {loadingString}
                  </span>
                ) : (
                  <CssFlash value={quoteObj.low52Week} isChange={false}>
                    <Number
                      className="market_summary_symbol__quote price-52low"
                      value={quoteObj.low52Week}
                      precision={quoteObj.precision}
                      hideIfZero={true}
                    />
                  </CssFlash>
                )}
              </div>

              <div className="market_summary_symbol__row two">
                <span className="market_summary_symbol__quote-time">
                  {isLoading ? (
                    loadingDate
                  ) : (
                    <DateTime
                      value={quoteObj.low52WeekTime}
                      format={'M/D'}
                      hasZeroPad={true}
                    />
                  )}
                </span>
              </div>
            </div>
            <div class="market_summary_symbol-quote-card price-52high">
              <div className="market_summary_symbol__row one">
                {isLoading ? (
                  <span className="market_summary_symbol__quote price-52high">
                    {loadingString}
                  </span>
                ) : (
                  <CssFlash value={quoteObj.high52Week} isChange={false}>
                    <Number
                      className="market_summary_symbol__quote price-52high"
                      value={quoteObj.high52Week}
                      precision={quoteObj.precision}
                      hideIfZero={true}
                    />
                  </CssFlash>
                )}
              </div>

              <div className="market_summary_symbol__row two">
                <span className="market_summary_symbol__quote-time">
                  {isLoading ? (
                    loadingDate
                  ) : (
                    <DateTime
                      value={quoteObj.high52WeekTime}
                      format={'M/D'}
                      hasZeroPad={true}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
          <div class="market_summary_symbol-quote-card price-last">
            <div className="market_summary_symbol__row one">
              {isLoading ? (
                <span className="market_summary_symbol__quote-last">
                  {loadingString}
                </span>
              ) : (
                <CssFlash value={quoteObj.lastPrice} isChange={false}>
                  <Number
                    className="market_summary_symbol__quote-last"
                    value={quoteObj.lastPrice}
                    precision={quoteObj.precision}
                    hideIfZero={true}
                  />
                </CssFlash>
              )}
            </div>
            <div className="market_summary_symbol__row two">
              <span className={'market_summary_symbol__net-change'}>
                <CssFlash
                  value={isLoading ? 0 : quoteObj.lastPrice}
                  isChange={false}
                >
                  <Number
                    className={'market_summary_symbol__net-change-value'}
                    value={
                      isLoading ? 0 : quoteObj.lastPrice - quoteObj.prevClose
                    }
                    precision={
                      isLoading ? defaultPrecision : quoteObj.precision
                    }
                    hideIfZero={true}
                    signed={true}
                  />
                </CssFlash>{' '}
                <CssFlash
                  value={isLoading ? 0 : quoteObj.lastPrice}
                  isChange={false}
                >
                  <Number
                    className={'market_summary_symbol__net-change-value'}
                    value={
                      isLoading
                        ? 0
                        : calcNetChgPct(quoteObj.lastPrice, quoteObj.prevClose)
                    }
                    hideIfZero={true}
                    precision="2"
                    prefix="("
                    suffix="%)"
                    signed={true}
                  />
                </CssFlash>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SymbolSummaryRow = injectIntl(SymbolSummaryRow);

function mapStateToProps(state, ownProps) {
  if (!state.user) {
    return {};
  }
  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };
  props.symbolsName =
    (state.symbols.data[ownProps.symbolData.symbolCode] || { data: {} }).data ||
    {};

  if (!state.stream || !state.stream.channels) {
    return {};
  }
  props.stream = { ...(state.stream.channels || {}) };

  props.user = state.user;

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      init: bindActionCreators(initWebSocket, dispatch),
      start: bindActionCreators(start, dispatch),
      stop: bindActionCreators(stop, dispatch),
      getSymbol: bindActionCreators(fetchSymbolIfNeeded, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SymbolSummaryRow);
