import React from 'react';
import Number from '../../common/Number';
import * as moment from 'moment-timezone';

const mdash = '\u2014';

class SymbolHistoricalListItem extends React.PureComponent {
  // Convert timestamp day to Monday (beginning of the week)
  // for the weekly bar date display
  convertWeekDate(timestamp) {
    let date = new Date(timestamp);
    let dayOfWeek = date.getDay();
    // If Sunday (0), we need to go back 6 days
    let offset = dayOfWeek === 0 ? -6 : dayOfWeek - 1;
    let newDay = date.getDate() - offset;
    return new Date(date.setDate(newDay));
  }

  // Subtract a few days from the monthly timestamp to
  // guarantee that the timestamp is in the correct month
  convertMonthDate(timestamp) {
    return moment(timestamp).subtract(4, 'd');
  }

  getHighLowClasses(value) {
    if (this.props.bar[value] === this.props.lows[value]) {
      return ' lowest';
    } else if (this.props.bar[value] === this.props.highs[value]) {
      return ' highest';
    }
    return '';
  }

  // We return the volume parameter rather than the volume directly
  // so that we can use this same function to get the volume from the
  // bar, as well as the correct parameter for the high/low classes
  getVolumeParameter() {
    if (
      this.props.barSize === '1month' ||
      this.props.barSize === '1week' ||
      this.props.barSize === '1quarter'
    ) {
      return 'AverageDailyVolume';
    } else {
      return 'Volume';
    }
  }

  getFormattedDate() {
    if (this.props.barSize === '1month' || this.props.barSize === '1quarter') {
      return moment(this.convertMonthDate(this.props.bar.Timestamp)).format(
        'YYYY/\u200bMM'
      );
    } else if (this.props.barSize === '1week') {
      // 2020-04-22T20:59:00Z
      // This is done to match Sometaro, which ignores timezones.
      let timestamp = this.convertWeekDate(this.props.bar.Timestamp);
      let timestampBits = timestamp.toISOString().split('T');
      let date = timestampBits[0];
      return moment(date).format('YYYY/\u200bMM/DD');
    } else if (
      this.props.barSize === '5min' ||
      this.props.barSize === '1hour'
    ) {
      return moment(this.props.bar.Timestamp)
        .tz('Asia/Tokyo')
        .format('MM/DD HH:mm');
    } else {
      // 1 Day
      // 2020-04-22T20:59:00Z
      // This is done to match Sometaro, which ignores timezones.
      let timestampBits = this.props.bar.Timestamp.split('T');
      let date = timestampBits[0];
      return moment(date).format('YYYY/\u200bMM/DD');
    }
  }

  render() {
    return (
      <div className={'row'} key={this.props.index}>
        <div className={'cell'}>
          <div className={'multirow-cell'}>
            <div className={'text'}>{this.getFormattedDate()}</div>
          </div>
        </div>
        <div className={'cell'}>
          <div className={'subcell' + this.getHighLowClasses('HighPrice')}>
            <Number
              value={this.props.bar.HighPrice}
              precision={this.props.precision}
            />
          </div>
          <div className={'subcell' + this.getHighLowClasses('LowPrice')}>
            <Number
              value={this.props.bar.LowPrice}
              precision={this.props.precision}
            />
          </div>
        </div>
        <div className={'cell'}>
          <div className={'subcell' + this.getHighLowClasses('OpenPrice')}>
            <Number
              value={this.props.bar.OpenPrice}
              precision={this.props.precision}
            />
          </div>
          <div className={'subcell' + this.getHighLowClasses('ClosePrice')}>
            <Number
              value={this.props.bar.ClosePrice}
              precision={this.props.precision}
            />
          </div>
        </div>
        <div className={'cell'}>
          <div className={'subcell' + this.getHighLowClasses('changeAmount')}>
            {this.props.bar.changeAmount !== 0 ? (
              <Number
                className={'colorful'}
                value={this.props.bar.changeAmount}
                precision={this.props.precision}
                signed={true}
              />
            ) : (
              mdash
            )}
          </div>
          {this.props.displayVolume && (
            <div
              className={
                'subcell' + this.getHighLowClasses(this.getVolumeParameter())
              }
            >
              <Number
                value={this.props.bar[this.getVolumeParameter()]}
                precision={0}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default SymbolHistoricalListItem;
