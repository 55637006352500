import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Number from '../common/Number';
import { calcNetChgPct } from '../../utils/maths';
import { getSymbolClose } from '../../utils/symbol';

import CssFlash from '../../views/common/CssFlash';
import useActions from '../../utils/hooks/useActions';
import useLocalize from '../../utils/hooks/useLocalize';
import { start, stop } from '../../actions/stream';
import SymbolRestrictions from '../common/SymbolRestrictions';
import { selectChannel } from '../../selectors/channelSelectors';
import { getTradeStatus } from '../../utils/quotes';

const useStyles = makeStyles({
  symbolSearch: {
    margin: '10px 0'
  },
  quoteData: {
    whiteSpace: 'nowrap'
  },
  quoteName: {
    fontSize: '28px',
    marginRight: '15px',
    fontFamily: 'Hiragino Sans',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'clip',
    maxWidth: '100%',
    display: 'inline-block'
  },
  quoteLast: {
    color: '#222',
    display: 'inline-block',
    fontFamily: 'Arial, Bold',
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: '0.25rem',
    marginRight: '10px',
    textAlign: 'left'
  },
  quoteNetChange: {
    marginBottom: '15px',
    lineHeight: '1.6rem',
    textAlign: 'left',
    fontFamily: 'Arial, Regular',
    fontSize: '16px'
  },
  number: {
    position: 'relative',
    top: '-2px',
    fontSize: '16px',
    '& .positive': {
      color: '#e01515'
    },
    '& .negative': {
      color: '#146dc8'
    },
    '& .zero': {
      color: 'black'
    }
  },
  tradeStatus: {
    position: 'relative',
    top: '-2px',
    marginLeft: '5px',
    color: '#A64E4A',
    fontFamily: 'Arial, Regular',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  symbolRestrictions: {
    display: 'inline-block',
    position: 'absolute',
    marginTop: '-21px',
    marginLeft: '6px'
  }
});

const SymbolDetailHeader = ({ symbol, displayTradeStatus = false }) => {
  // Redux
  const channel = useMemo(() => `${symbol}/quote`, [symbol]);
  const quote = useSelector((state) => selectChannel(state, channel));
  const [startStream] = useActions([start], []);
  const [stopStream] = useActions([stop], []);
  const metadata = useSelector(
    (state) => (state.symbols.data || { data: {} } || {})[symbol] || {}
  );

  // Intl
  const localize = useLocalize();
  const classes = useStyles();

  const getSymbolName = useCallback(
    (metadata) => {
      if (!metadata || !metadata.data || metadata.isLoading) {
        return localize('message.status.loading');
      }

      if (metadata.error || metadata.data.errorCode) {
        return localize('message.status.load-failed');
      }

      return metadata.data.jpShortName || metadata.data.symbolName;
    },
    [localize]
  );

  useEffect(() => {
    if (!symbol) {
      return;
    }

    startStream(`${symbol}/quote`);

    return () => {
      stopStream(`${symbol}/quote`);
    };
  }, [symbol, startStream, stopStream]);

  if (!symbol) {
    return null;
  }

  const symbolName = getSymbolName(metadata);
  const symbolMetadata = (metadata.data || {});
  const close = getSymbolClose(symbolMetadata.assetClass, quote);

  return (
    <div className={classes.quoteData}>
      <span className={classes.quoteName}>{symbolName}</span>
      <span className={classes.quoteLast}>
        <CssFlash value={close}>
          <Number value={close} precision={quote.precision} hideIfZero={true} />
        </CssFlash>
      </span>
      <span className={classes.number}>
        <Number
          value={close - quote.prevClose}
          precision={quote.precision}
          signed={true}
        />{' '}
        <Number
          value={calcNetChgPct(close, quote.prevClose)}
          precision="2"
          prefix="("
          suffix="%)"
          signed={true}
        />

      </span>
      {displayTradeStatus && (
        <span className={classes.tradeStatus}>{getTradeStatus(quote)}</span>
      )}
      <SymbolRestrictions
        className={classes.symbolRestrictions}
        symbol={symbol}
      />
    </div>
  );
};

export default SymbolDetailHeader;
