export function getResultsForCurrentPage(resultArray, currPage, itemsPerPage) {
  let startIndex = currPage === 1 ? 0 : (currPage - 1) * itemsPerPage;
  return resultArray.slice(startIndex, startIndex + itemsPerPage);
}

export function calculateFinalIndexForCurrentPage(
  currPage,
  dataSize,
  itemsPerPage,
  indexOffset = 0
) {
  let pageNumber = currPage + indexOffset;

  if (pageNumber * itemsPerPage > dataSize) {
    return dataSize;
  } else {
    return pageNumber * itemsPerPage;
  }
}
