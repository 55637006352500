export const fullQuoteSelector = (channels, symbol) => {
  return channels[`${symbol}/quote`] || {};
}

export const miniQuoteSelector = (channels, symbol) => {
  return channels[`${symbol}/miniquote`] || {};
}

export const getTradeStatus = (quote) => {
  if (!quote || !quote.tradeStatus) {
    return null;
  }

  switch(quote.tradeStatus) {
    case 'CA':
      return 'C';
    case 'PR':
      return 'Pr';
    default:
      return quote.tradeStatus;
  }
}