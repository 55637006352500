import React from 'react';
import { Link } from 'react-router-dom';
import Search from '@mui/icons-material/Search';

const LastScanButton = props => {
  return (
    <Link
      to={{
        pathname: '/scanresult/last-scan',
        state: { userScanIndex: -1 }
      }}
    >
      <div className="actions">
        <div className="margin button btn-cmn scans" onClick={props.onClick}>
          <div className="icon">
            <Search fontSize="small" />
          </div>
          <div className="label">{props.text}</div>
        </div>
      </div>
    </Link>
  );
};

export default LastScanButton;
