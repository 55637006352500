const { getFilteredBarValuesWithSameTimestamps } = require('./utils');

// color palette for comparison chart plots (based on Sometaro)
const colors = [
  '#0055cc', // color #2 on monex color chart (blue)
  '#bf0000', // #13 (red)
  '#008c46', // #3 (green)
  '#9732a6', // #5 (purple)
  '#eba315', // #4 (yellow)
  '#32afed' // #6 (cyan)
];

function getComparisonSymbolData(bars, fullBarData) {
  const filteredBars = getFilteredBarValuesWithSameTimestamps(
    bars,
    fullBarData,
    fullBarData
  );
  return getNetPercentChanges(filteredBars);
}

function getNetPercentChanges(bars) {
  if (!bars || !bars.length) {
    return bars;
  }

  // ClosePrice of first bar
  var firstClosePrice = bars[0][1][4];
  if (isNaN(firstClosePrice)) {
    return bars;
  }

  return bars.map((bar) => {
    const timestamp = bar[0];
    const currentClosePrice = bar[1][4];
    if (isNaN(currentClosePrice)) {
      // return 0 if Close Price not available
      return [timestamp, 0];
    }

    // return net change
    const changePercent =
      (100 * (currentClosePrice - firstClosePrice)) / firstClosePrice;
    return [timestamp, changePercent];
  });
}

function getComparisonSymbolsSeries(
  periods,
  bars,
  fullBarData,
  localize,
  fullVolume,
  additionalSymbolsData,
  comparisonSymbols
) {
  if (!fullBarData || fullBarData.length === 0) {
    return [];
  }

  if (
    !additionalSymbolsData ||
    Object.keys(additionalSymbolsData).length === 0
  ) {
    return [];
  }

  const symbols = Object.keys(additionalSymbolsData);

  // we already have main symbol's price series
  // so comparison symbols will start from 1;
  const zIndexOffset = 1;

  const result = symbols.map((symbol, index) => {
    const root = ((comparisonSymbols[symbol] || {}).data || {}).root || symbol;
    return {
      name: root,
      type: 'line',
      color: colors[index % colors.length],
      visible: true,
      yAxis: 0,
      data: getComparisonSymbolData(bars, additionalSymbolsData[symbol]),
      tooltip: {
        yDecimals: 2
      },
      zIndex: index + zIndexOffset
    };
  });

  return result;
}

module.exports = {
  getSeries: getComparisonSymbolsSeries
};
