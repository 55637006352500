import React from 'react';
import { injectIntl } from 'react-intl';
import HotlistItem from './HotlistItem';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const _ = require('lodash');
const DEFAULT_SHOW = 25;
class Hotlist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sort: 'rank',
      reverse: false,
      show: DEFAULT_SHOW
    };
  }

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  handleExpandClick(e) {
    this.setState({
      show: this.state.show + DEFAULT_SHOW
    });
  }

  handleSortClick(e) {
    this.setState({
      reverse: !this.state.reverse
    });
  }

  getSortColName(hotlistName, colToSort) {
    return (
      this.localize(
        `hotlist.column.${
          this.hotlistValueMap[hotlistName][colToSort || 'primary']
        }`
      ) || ''
    );
  }

  // TODO: complete the hotlistValueMap for New52WeekHigh onwards.
  //       currently we display only primary column so it is fine until then.
  hotlistValueMap = {
    RateRise: {
      primary: 'NetChangePct',
      secondary: 'NetChange',
      tertiary: 'Close',
      quaternary: 'Volume',
      quinary: 'Turnover',
      senary: ''
    },
    RateFall: {
      primary: 'NetChangePct',
      secondary: 'NetChange',
      tertiary: 'Close',
      quaternary: 'Volume',
      quinary: 'Turnover',
      senary: ''
    },
    BidAskPrevCloseOneDayGainers: {
      primary: 'BidAskRatio'
    },
    BidAskOpenGainers: {
      primary: 'OpenPriceRatio'
    },
    PercentChangeFiveDayGainers: {
      primary: '5DaysRiseRate'
    },
    PercentChangeThirtyDayGainers: {
      primary: '30DaysRiseRate'
    },
    PercentVWAPDeviationRateHighest: {
      primary: 'VwapDeviationRate'
    },
    BidAskPrevCloseOneDayLosers: {
      primary: 'BidAskRatio'
    },
    BidAskOpenLosers: {
      primary: 'OpenPriceRatio'
    },
    PercentChangeFiveDayLosers: {
      primary: '5DaysFallRate'
    },
    PercentChangeThirtyDayLosers: {
      primary: '30DaysFallRate'
    },
    PercentVWAPDeviationRateLowest: {
      primary: 'VwapDeviationRate'
    },
    New52WeekHigh: {
      primary: 'NetChangePct'
    },
    New52WeekLow: {
      primary: 'NetChangePct'
    },
    StopHigh: {
      primary: 'NetChangePct'
    },
    StopLow: {
      primary: 'NetChangePct'
    },
    Volume: {
      primary: 'Volume'
    },
    Turnover: {
      primary: 'Turnover'
    },
    VolumeSurge: {
      primary: 'VolumeChangePct'
    },
    RiseInSharesSold: {
      primary: 'ChangeInSharesSoldOnMargin'
    },
    FallInSharesSold: {
      primary: 'ChangeInSharesSoldOnMargin'
    },
    RiseInSharesBought: {
      primary: 'ChangeInSharesBoughtOnMargin'
    },
    FallInSharesBought: {
      primary: 'ChangeInSharesBoughtOnMargin'
    }
  };

  render() {
    if (this.props.hotlist && this.props.hotlist.results) {
      let itemsToShow = _.chain(this.props.hotlist.results)
        // The first part of orderBy is the column. Default to rank,
        // but tis can be changed to any available value. But will
        // not sort if that value is not present.
        .orderBy(
          !!this.state.sort ? this.state.sort : 'rank',
          // The second is the order, and must be in an array
          [!this.state.reverse ? 'asc' : 'desc']
        )
        .take(!!this.state.show ? this.state.show : DEFAULT_SHOW)
        .value();

      let showMore = itemsToShow.length < this.props.hotlist.results.length;

      return [
        <div className="hotlist_header" key="header">
          <div className="hotlist_header__title" key="title">
            {/* <div className="hotlist_header__results">
              {this.localize('hotlist.search.results').replace(
                '{0}',
                this.props.hotlist.results.length
              )}
            </div> */}
            {this.localize('scan.label.symbol')}
          </div>
          <div
            className="hotlist_header__sort"
            onClick={() => this.handleSortClick()}
            key="sort"
          >
            <IconButton size="large">
              <span
                className="hotlist_value__sort"
                isdescending={this.state.reverse ? 'false' : 'true'}
              />
            </IconButton>
            {/* TODO: change null below to sort colum param, depending on settings(to be implemented) */}
            <span className="hotlist_header__sortName">
              {this.getSortColName(this.props.hotlist.hotlist, null)}
            </span>
          </div>
        </div>,
        <div component="nav" key="nav">
          {itemsToShow.length === 0 && (
            <div className={'no-content empty smaller-text-label'}>
              {this.localize('message.no.symbols.found')}
            </div>
          )}
          {itemsToShow.map(symbol => {
            return (
              <HotlistItem
                key={symbol.symbol}
                value={symbol}
                columns={this.props.hotlist.columns}
                hotlistName={this.props.hotlist.hotlist}
                hotlistValueMap={this.hotlistValueMap}
                ranked={this.props.hotlist.ranked}
              />
            );
          })}
        </div>,
        <div key="show-more">
          {showMore && (
            <div className="expand-button">
              <IconButton
                className="expand"
                onClick={() => this.handleExpandClick()}
                aria-expanded={this.state.expanded}
                size="large">
                <ExpandMoreIcon />
              </IconButton>
            </div>
          )}
        </div>
      ];
    } else {
      return <div />;
    }
  }
}

export default injectIntl(Hotlist);
