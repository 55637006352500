import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { start, stop } from '../../actions/stream';
import { fetchSymbolIfNeeded } from '../../actions/symbols';
import { fetchUpDownRatios } from '../../actions/market';
import Number from '../common/Number';
import DateTime from '../common/DateTime';
import { useSelector } from 'react-redux';
import useActions from '../../utils/hooks/useActions';
import upIcon from '../../icons/Icon-Arrow_Red.svg';
import downIcon from '../../icons/Icon-Arrow_Blue.svg';
import unchangedIcon from '../../icons/Icon-Arrow_Gray.svg';

export const ExchangeSummary = ({
  symbol,
  advance,
  decline,
  unchanged,
  overrideName,
  linkedSymbol,
  notLink
}) => {
  // Actions
  const [startStream] = useActions([start]);
  const [stopStream] = useActions([stop]);
  const [getSymbolMetadata] = useActions([fetchSymbolIfNeeded]);
  const [getRatios] = useActions([fetchUpDownRatios]);

  // Intl
  const intl = useIntl();
  const localize = (i18nKey) => {
    return intl.formatMessage({
      id: i18nKey
    });
  };

  // Load the ratios, open the symbols, get the metadata
  useEffect(() => {
    startStream(symbol + '/quote');
    startStream(advance + '/miniquote');
    startStream(decline + '/miniquote');
    startStream(unchanged + '/miniquote');
    getRatios();
    getSymbolMetadata(symbol);

    // When we unmount, stop the streams
    return () => {
      stopStream(symbol + '/quote');
      stopStream(advance + '/miniquote');
      stopStream(decline + '/miniquote');
      stopStream(unchanged + '/miniquote');
    };
  }, [symbol]);

  const advanceData = useSelector(
    (state) => state.stream.channels[advance + '/miniquote'] || {}
  );

  const declineData = useSelector(
    (state) => state.stream.channels[decline + '/miniquote'] || {}
  );

  const unchangedData = useSelector(
    (state) => state.stream.channels[unchanged + '/miniquote'] || {}
  );

  const exchangeQuote = useSelector(
    (state) => state.stream.channels[symbol + '/quote'] || {}
  );

  const tradeTime = exchangeQuote.tradeTime;
  const volume = exchangeQuote.volume / 1000;
  const turnOver = exchangeQuote.turnover / 1000000;

  const symbolData = useSelector((state) => state.symbols.data[symbol]);

  const upDownRatios = useSelector(
    (state) =>
      state.market.upDownRatios.data.find((x) => x.symbol === symbol) || {}
  );

  const isLoading =
    !symbolData ||
    symbolData.isLoading ||
    !advanceData.close ||
    !declineData.close ||
    !unchangedData.close ||
    !upDownRatios ||
    upDownRatios.isLoading;

  const calcWidth = (numerator) => {
    return `${
      isLoading
        ? 33
        : (numerator /
            (advanceData.close + declineData.close + unchangedData.close)) *
          100
    }%`;
  };

  let name = '';
  if (overrideName) {
    // If we have a local override for the name, don't bother showing
    // an emdash if we're still loading - we already have it.
    name = localize(overrideName);
  } else {
    name = isLoading ? '-' : symbolData.name;
  }

  const nameLink = <Link to={'/symbol/' + linkedSymbol}>{name}</Link>;

  // The `close` values of these miniquotes represent the number of symbols
  // on the exchange which are either advancing (up), declining (down), or
  // unchanged for the day.
  let advancingSymbols = advanceData.close || 0;
  let decliningSymbols = declineData.close || 0;
  let unchangedSymbols = unchangedData.close || 0;

  return (
    <div className="exchange-summary">
      <div className="exchange-summary-header">
        <div
          className={
            'exchange-summary-header-title' + (notLink ? ' notLink' : '')
          }
        >
          {notLink ? name : nameLink}
        </div>
        <div className="exchange-summary-header-timestamp">
          {isLoading ? '-' : <DateTime value={tradeTime} hasZeroPad={true} />}
        </div>
      </div>
      <div className="exchange-summary-ratios">
        <div className="exchange-summary-ratios-color-bar">
          <div
            className="exchange-summary-ratios-color-bar--up"
            style={{
              width: calcWidth(advancingSymbols)
            }}
          >
            {/* This is a load-bearing space */}
            &nbsp;
          </div>
          <div
            className="exchange-summary-ratios-color-bar--down"
            style={{
              width: calcWidth(decliningSymbols)
            }}
          />
          <div
            className="exchange-summary-ratios-color-bar--unchanged"
            style={{
              width: calcWidth(unchangedSymbols)
            }}
          />
        </div>
        <div className="exchange-summary-ratios-data">
          <div className="exchange-summary-ratio exchange-summary-ratios-up">
            <img src={upIcon} className="exchange-summary-ratio-icon" alt="" />
            <Number value={advancingSymbols} hideIfZero={true} />
          </div>
          <div className="exchange-summary-ratio  exchange-summary-ratios-down">
            <img
              src={downIcon}
              className="exchange-summary-ratio-icon"
              alt=""
            />
            <Number value={decliningSymbols} hideIfZero={true} />
          </div>
          <div className="exchange-summary-ratio exchange-summary-ratios-unchanged">
            <img
              src={unchangedIcon}
              className="exchange-summary-ratio-icon"
              alt=""
            />
            <Number value={unchangedSymbols} hideIfZero={true} />
          </div>
          <div className="exchange-summary-ratio exchange-summary-ratios-total">
            <span className="total">{localize('market.summary.total')}</span>
            <Number
              value={advancingSymbols + decliningSymbols + unchangedSymbols}
              hideIfZero={true}
            />
          </div>
        </div>
      </div>
      <div className="exchange-summary-details">
        <div className="exchange-summary-detail-table">
          <div className="exchange-summary-detail-table-heading">
            <div className="exchange-summary-detail-table-row">
              <div className="exchange-summary-detail-table-head">
                {localize('market.summary.rise.fall.ratio')}
              </div>
              <div className="exchange-summary-detail-table-head">
                {localize('market.summary.volume.thousands')}
              </div>
              <div className="exchange-summary-detail-table-head last-column">
                {localize('market.summary.trading.value.millions')}
              </div>
            </div>
          </div>
          <div className="exchange-summary-detail-table-body">
            <div className="exchange-summary-detail-table-row">
              <div className="exchange-summary-detail-table-cell">
                <Number
                  value={upDownRatios.ratio || 0}
                  hideIfZero={true}
                  precision={2}
                />
                (
                <Number
                  value={upDownRatios.change || 0}
                  hideIfZero={true}
                  precision={2}
                  signed={true}
                  className="color"
                />
                )
              </div>
              <div className="exchange-summary-detail-table-cell">
                <Number value={volume || 0} hideIfZero={true} />
              </div>
              <div className="exchange-summary-detail-table-cell last-column">
                <Number value={turnOver || 0} hideIfZero={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
