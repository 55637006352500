import { FeatureToggle } from "../common/FeatureToggle";
import { TickSection } from "../symbolOverview/TickSection";
import SymbolMatrix from "./SymbolMatrix";
import React from 'react';

export const SymbolMatrixContainer = ({symbol, enabled}) => {
  return (
    <div className="matrix section">
      <div className="matrix-container">
        <SymbolMatrix symbol={symbol} enabled={enabled} />
        <FeatureToggle featureName="tickEnabled">
          <TickSection />
        </FeatureToggle>
      </div>
    </div>
  );
}
