import React from 'react';

// Time displays a time of day. The time is expected to be given as a string in
// the `value` prop in the format `HH:MM:SS`. By default, the seconds portion
// of the time is *not* displayed unless the `displaySeconds` prop is `true`.
//
// If the given value is `00:00:00` or if the value isn't in the expected
// format, an em dash is displayed.
export default class Time extends React.PureComponent {
  render() {
    const value = '' + this.props.value;
    // Checks for UTC
    if (!/^\d\d:\d\d:\d\d/.test(value) || value === '00:00:00') {
      return <span className="time empty">&mdash;</span>;
    }
    return <span className="time">{value.substr(0, 5)}</span>;
  }
}
