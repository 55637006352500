import React from 'react';
import { Route } from 'react-router-dom';
import ErrorPage from '../views/errors/ErrorPage';
import Logger from '../utils/logger';
import { useIntl } from 'react-intl';

function ProtectedRoute({ component: Component, authed, ...rest }) {
  const intl = useIntl();

  if (!authed) {
    Logger.error({
      errorCode: 'E0009',
      message: 'Current user is not Authorized',
      component: Component,
      info: rest
    });
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (props.match && props.match.params) {
          Object.keys(props.match.params).forEach(key => {
            props.match.params[key] = decodeURIComponent(
              props.match.params[key]
            );
          });
        }

        if (authed === true) {
          return <Component {...props} />;
        } else {
          return (
            <ErrorPage
              {...props}
              errorTitle={intl.formatMessage({
                id: 'message.status.error'
              })}
              errorMessage={intl.formatMessage({
                id: 'message.invalid.token'
              })}
            />
          );
        }
      }}
    />
  );
}

export default ProtectedRoute;
