import types from './_actionTypes';
import { fetchRetryWithAuth } from '../utils/fetchRetry';

const API_URL = process.env.REACT_APP_INSIGHT_API_DOMAIN;

function getScanUrl(scanName) {
  // prettier-ignore
  return `https://${API_URL}/market/scans/${scanName}`;
}

function getUserScanUrl(userId, scanId = '') {
  return `https://${API_URL}/user/${userId}/scans/${scanId}`;
}

function getLastScanUrl(userId) {
  return `https://${API_URL}/user/${userId}/scans/last-scan`;
}

function receiveScanResponse(scanName, response) {
  return {
    type: types.RECEIVE_SCAN,
    name: scanName,
    data: response
  };
}

function receiveUserScansResponse(response) {
  return {
    type: types.RECEIVE_USER_SCANS,
    data: response
  };
}

function receiveLastScanResponse(response) {
  return {
    type: types.RECEIVE_LAST_SCAN,
    data: response
  };
}

function saveLastScanResponse(scan) {
  return {
    type: types.SAVE_LAST_SCAN,
    data: scan
  };
}

function saveScanResponse(scan) {
  return {
    type: types.SAVE_SCAN,
    data: scan
  };
}

function overwriteScanResponse(scan) {
  return {
    type: types.OVERWRITE_SCAN,
    data: scan
  };
}

function deleteScanResponse(response, scanId) {
  return {
    type: types.DELETE_SCAN,
    id: scanId,
    data: response
  };
}

export function errorRequestingScan(scanName, error) {
  return {
    type: types.ERROR_REQUESTING_SCAN,
    name: scanName,
    error: error,
    errorCode: 'E0018'
  };
}

export function errorRequestingUserScans(error) {
  return {
    type: types.ERROR_REQUESTING_USER_SCANS,
    error: error,
    errorCode: 'E0032'
  };
}

export function errorRequestingLastScan(error) {
  return {
    type: types.ERROR_REQUESTING_LAST_SCAN,
    error: error,
    errorCode: 'E0033'
  };
}

export function errorSavingLastScan(lastScan, error) {
  return {
    type: types.ERROR_SAVING_LAST_SCAN,
    lastScan: lastScan,
    error: error,
    errorCode: 'E0034'
  };
}

export function errorSavingScan(scan, error) {
  return {
    type: types.ERROR_SAVING_SCAN,
    scan: scan,
    error: error,
    errorCode: 'E0035'
  };
}

export function errorOverwritingScan(scan, error) {
  return {
    type: types.ERROR_OVERWRITING_SCAN,
    scan: scan,
    error: error,
    errorCode: 'E0036'
  };
}

export function errorDeletingScan(scanId, error) {
  return {
    type: types.ERROR_DELETING_SCAN,
    scanId: scanId,
    error: error,
    errorCode: 'E0037'
  };
}

export function fetchScan(scanName) {
  let req = {
    method: 'get'
  };

  return fetchRetryWithAuth(
    req,
    () => {
      return getScanUrl(scanName);
    },
    (response) => {
      return receiveScanResponse(scanName, response);
    },
    (error) => {
      return errorRequestingScan(scanName, error);
    }
  );
}

export function fetchUserScans() {
  let req = {
    method: 'get'
  };

  return fetchRetryWithAuth(
    req,
    getUserScanUrl,
    receiveUserScansResponse,
    errorRequestingUserScans
  );
}

export function fetchLastScan() {
  let req = {
    method: 'get'
  };

  return fetchRetryWithAuth(
    req,
    getLastScanUrl,
    receiveLastScanResponse,
    errorRequestingLastScan
  );
}

export function saveLastScan(lastScan) {
  let req = {
    method: 'put',
    body: JSON.stringify(lastScan)
  };

  return fetchRetryWithAuth(
    req,
    getLastScanUrl,
    saveLastScanResponse,
    (error) => {
      return errorSavingLastScan(lastScan, error);
    }
  );
}

export function saveScan(newScan, handleSuccess, handleFail) {
  let req = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newScan)
  };

  return fetchRetryWithAuth(
    req,
    getUserScanUrl,
    (scan) => {
      // This is done this way so that the scan container
      // can get the ID (which is generated server side)
      // for the newly created scan so it can be loaded.
      handleSuccess(scan.data.scan.id);
      return saveScanResponse(scan);
    },
    (error) => {
      return errorSavingScan(newScan, error);
    },
    '',
    null,
    handleFail
  );
}

export function overwriteScan(existingScan, handleSuccess, handleFail) {
  let req = {
    method: 'put',
    headers: {},
    body: JSON.stringify(existingScan)
  };

  return fetchRetryWithAuth(
    req,
    (userId) => {
      return getUserScanUrl(userId, existingScan.id);
    },
    overwriteScanResponse,
    (error) => {
      return errorOverwritingScan(existingScan, error);
    },
    '',
    handleSuccess,
    handleFail
  );
}

export function deleteScan(scanId, handleSuccess, handleFail) {
  let req = {
    method: 'delete',
    headers: {}
  };

  return fetchRetryWithAuth(
    req,
    (userId) => {
      return getUserScanUrl(userId, scanId);
    },
    (response) => {
      return deleteScanResponse(response, scanId);
    },
    (error) => {
      return errorDeletingScan(scanId, error);
    },
    '',
    handleSuccess,
    handleFail
  );
}
