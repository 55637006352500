import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorPage extends Component {
  static defaultProps = {
    errorTitle: 'Error',
    errorMessage: 'An unknown error has occurred'
  };
  render() {
    return (
      <div>
        <h1>{this.props.errorTitle}</h1>
        <p>{this.props.errorMessage}</p>
      </div>
    );
  }
}

ErrorPage.propTypes = {
  errorTitle: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired
};

export default ErrorPage;
