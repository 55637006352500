import useWindowDimensions from './useWindowDimensions';

export const SMALL_WIDTH = 320;
export const MEDIUM_WIDTH = 375;
export const LARGE_WIDTH = 768;
export const XLARGE_WIDTH = 1024;

export const StandardWidths = {
    xs: 'smallest',
    sm: 'small',
    md: 'medium',
    lg: 'large',
    xl: 'extra-large'
};

export function useWidth() {

    const { width } = useWindowDimensions();
    
    // Smallest Width (smallest)
    if (width < SMALL_WIDTH) {
        return StandardWidths.xs;
    }
    
    // Small Width (small)
    if (width < MEDIUM_WIDTH) {
        return StandardWidths.sm;
    }
    
    // Medium Width (mobile)
    if (width < LARGE_WIDTH) {
        return StandardWidths.md;
    }
    
    // Large Width (tablet)
    if (width < XLARGE_WIDTH) {
        return StandardWidths.lg;
    }
    
    // Extra Large (desktop)
    return StandardWidths.xl;
}
