export const filterByPage = (message, currentPage) => {
  const page = message?.data?.page ?? 'all';
  return page === 'all' || currentPage === page;
};

export const filterBySymbols = (message, currentPage) => {
  // First check if we are on the symbol details page.
  // If not, no need to filter by symbols.
  if (currentPage !== 'symbol_details') {
    return true;
  }

  // Then check if the message contains symbols.
  const symbols = message?.data?.symbols ?? [];
  if (symbols.length === 0) {
    return true;
  };

  // Finally check if the symbol of the page is in the list of symbols.
  // 'window.location.hash' is in the format of '#/symbol/JP:8698-TS'.
  const tokens = window.location.hash.split('/');
  const currentSymbol = tokens.length >= 3 ? tokens[2] : null;
  return symbols.includes(currentSymbol);
};

export const filterBySizes = (message, width) => {
  const sizes = message?.data?.sizes;
  if (!sizes) {
    return true;
  }

  if (sizes.indexOf('small') !== -1 && width < 768) {
    return true;
  }

  if (sizes.indexOf('medium') !== -1 && width >= 768 && width <= 1023) {
    return true;
  }

  if (sizes.indexOf('large') !== -1 && width >= 1024) {
    return true;
  }

  return false;
};