import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Chip, Divider } from '@mui/material';
import useLuckySymbol from '../../utils/hooks/useLuckySymbol';
import useLocalize from '../../utils/hooks/useLocalize';

const useStyles = makeStyles({
  root: {
    paddingTop: '10px'
  },
  divider: {},
  header: {
    fontSize: '0.8rem',
    marginTop: '5px',
    marginBottom: '5px'
  },
  body: {
    paddingBottom: '5px'
  },
  chip: {
    marginRight: '0.2rem',
    marginBottom: '0.2rem'
  }
});

const RelatedSymbols = ({ relatedSymbols }) => {
  const classes = useStyles();
  const localize = useLocalize();

  const [selectedSymbol, setSelectedSymbol] = useState(null);

  // Lucky Symbol - this is the 1st symbol found based on the symbol
  // pattern we passed to Syft.
  // eg: pattern: 7203
  //     luckySymbol: JP:7203-TS
  const luckySymbol = useLuckySymbol(selectedSymbol);

  useEffect(() => {
    if (luckySymbol) {
      // Once we have the lucky symbol based on the selected symbol,
      // we redirect user to the first symbol in the search result list
      window.location.hash = `/symbol/${luckySymbol}`;
      window.scrollTo(0, 0);
    }
  }, [luckySymbol]);

  return (
    relatedSymbols &&
    relatedSymbols.length > 0 && (
      <div className={classes.root}>
        <Divider />
        <p className={classes.header}>{localize('news.related.symbols')}</p>
        <div className={classes.body}>
          {relatedSymbols.map((symbol) => {
            return (
              <Chip
                className={classes.chip}
                key={symbol}
                label={symbol}
                variant="outlined"
                onClick={() => setSelectedSymbol(symbol)}
              />
            );
          })}
        </div>
      </div>
    )
  );
};

export default RelatedSymbols;
