const {
  simpleMovingAverage,
  getFilteredBarValuesWithSameTimestamps
} = require('./utils');

function getVolumeAverage(fullVolume, bars, period) {
  // We extract the volume values
  let volumes = fullVolume.map(a => a[1]);

  // Calculate the SMA for the volume values
  let sma = simpleMovingAverage(volumes, period);
  return getFilteredBarValuesWithSameTimestamps(bars, fullVolume, sma);
}

function getSeries(periods, bars, fullBarData, localize, fullVolume) {
  if (!fullVolume) {
    return [];
  }

  let result = [];
  let period = periods[0];

  // Insert the orange line for the SMA
  result.push({
    name: localize({ id: 'settings.chart.indicators.volAvg.title' }),
    type: 'line',
    color: '#eba315',
    yAxis: 1,
    data: getVolumeAverage(fullVolume, bars, period),
    tooltip: { yDecimals: 2 },
    zIndex: 1
  });

  return result;
}

module.exports = {
  getSeries: getSeries
};
