import React from 'react';

class SymbolHistoricalListLegend extends React.PureComponent {
  render() {
    return (
      <div className={'symbol-historical-legend-container'}>
        <div className={'legend'}>
          <div className={'highest'}>&nbsp;</div>
          <div className={'label'}>
            {this.props.localize('symbol.details.historical.label.highest')}
          </div>
          <div className={'lowest'}>&nbsp;</div>
          <div className={'label'}>
            {this.props.localize('symbol.details.historical.label.lowest')}
          </div>
        </div>
      </div>
    );
  }
}
export default SymbolHistoricalListLegend;
