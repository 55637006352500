import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import {
  getAvailableBoardPosition,
  getBoardType,
  getMaxSymbolsPerBoard,
  isPositionBoard
} from '../../utils/marketboards';
import { MobileQuoteCard } from './MobileQuoteCard';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ReactComponent as ListAddIcon } from '../../icons/Icon-List-Add-Gold.svg';
import { FormattedMessage } from 'react-intl';

// When displaying the error for the message memo, we have
// the issue that we are sending multiple times the DELETE
// ajax requests. This happens when one symbol is added multiple
// times in a marketboard, so there will be multiple QuoteCard elements
// and each of them will do the DELETE request separately. Since
// QuoteCard components can't communite with each other, we have
// created this variable here to keep track which symbols have
// displayed the error message when parsing a memo and at the same
// time which have sent a DELETE request to avoid redundancy.
let errorMessageDisplayed = {};

const getPositionData = (marketboardId, symbol) => {
  switch (getBoardType(marketboardId)) {
    case 'cash':
      return {
        averagePrice: symbol.AveragePrice,
        cost: symbol.Cost,
        quantity: symbol.Quantity,
        sellable: symbol.Sellable,
        selling: symbol.Selling,
        even: symbol.even
      };
    case 'margin':
      return {
        commission: symbol.Commission,
        cost: symbol.Cost,
        marginType: symbol.MarginType,
        openDate: symbol.OpenDate,
        openPrice: symbol.OpenPrice,
        quantity: symbol.Quantity,
        side: symbol.Side
      };
    default:
      return null;
  }
};

export default class QuoteTableBody extends Component {
  shouldDisplayErrorMessage(symbol) {
    if (errorMessageDisplayed[symbol]) {
      return false;
    }

    errorMessageDisplayed[symbol] = true;
    return true;
  }

  getQuoteCard(symbol) {
    return (
      <VisibilitySensor
        offset={{ top: -150, bottom: -150 }}
        scrollCheck
        scrollThrottle={100}
        delayedCall
        partialVisibility
      >
        {({ isVisible }) => {
          return (
            <MobileQuoteCard
              symbol={symbol.Symbol}
              isVisible={isVisible}
              onDeleteClick={this.props.onDeleteClick}
              shouldDisplayErrorMessage={this.shouldDisplayErrorMessage}
              boardType={getBoardType(this.props.marketboardId)}
              positionData={getPositionData(this.props.marketboardId, symbol)}
            />
          );
        }}
      </VisibilitySensor>
    );
  }

  navigate(url) {
    this.props.history.push(url);
  }

  render() {
    const marketboardId = (this.props || {}).marketboardId || '';
    const isUserboard = !isPositionBoard(marketboardId);
    const boardPosition = isUserboard
      ? getAvailableBoardPosition(this.props.symbols)
      : '-1';
    const editUrlHash = `/marketboard/edit/${marketboardId}/${boardPosition}`;
    const symbols = this.props.symbols || [];
    const canAdd = isUserboard && symbols.length < getMaxSymbolsPerBoard();
    const items = symbols.map((symbol) => (
        this.getQuoteCard(symbol)
    ));
    return (
      <ul className="quote-cards">
        {items}
        <ListItem
            button
            className={'marketboard-list-item-add-board'}
            onClick={canAdd ? () => this.navigate(editUrlHash) : null}
            key="add-button-list-item"
          >
            <div
              className={['add-board-icon', !canAdd ? 'disabled' : ''].join(
                ' '
              )}
            >
              <ListItemIcon>
                <ListAddIcon key="add-button-icon" />
              </ListItemIcon>
              <span>
                <FormattedMessage id="marketboard.title.add.symbol" />
              </span>
            </div>
          </ListItem>
      </ul>
    );
  }
}

/* This is a hack to trick material-ui into render a wrapped TableBody component, see:
    https://github.com/callemall/material-ui/issues/5966 */
QuoteTableBody.muiName = 'TableBody';
