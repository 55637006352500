import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useActions from '../../utils/hooks/useActions';
import { fetchSymbolPatternIfNeeded } from '../../actions/symbolSearch';
import { DEFAULT_MAX_SYMBOL_SEARCH_RESULTS, getBoardById } from '../../utils';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import MobileSymbolSearchCard from './MobileSymbolSearchCard';
import { MobileMarketboardSearchBox } from './MobileMarketboardSearchBox';
import { getMarketboards } from '../../selectors/marketboardSelectors';
import CheckIconGold from './../../icons/Icon-Check_Gold.svg';
import CheckIconGray from './../../icons/Icon-Check_Gray.svg';
import { useSymbolSearchFlags } from '../../utils/hooks/useSymbolSearchFlags';
import navigationTypes from '../../actions/_navigationTypes';
import { getSymbolBuySellUrl } from '../../utils/formatter';
import { trackNavigation } from '../../utils/analytics';
import { postUserTrade } from '../../utils/userMetrics';
import { getMaxSymbolsPerBoard, isPositionBoard } from '../../utils/marketboards';
import { marketboardFontFamily } from '../../utils/html';
import { isJpeq, isUseq } from '../../utils/symbol';

const SEARCH_DEBOUNCE = 200;
const MAX_SYMBOLS = getMaxSymbolsPerBoard();

const SYMBOL_SEARCH_PATTERN_KEY = 'symbolSearchPattern';
const SYMBOL_SEARCH_HISTORY_KEY = 'symbolSearchHistory';
const SYMBOL_SEARCH_HISTORY_MAX_RESULTS = 10;

const useStyles = makeStyles({
  chipBar: {
    display: 'flex',
    width: '100%',
    height: '40px',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.05)',
    justifyContent: 'center',
    padding: '5px 0px'
  },
  divider: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.15)'
  },
  assetChip: {
    margin: 'auto 3px',
    padding: '1px 7px 1px 3px',
    borderRadius: '14px',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '13px',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    '& img': {
      position: 'relative',
      top: '3px'
    },
    cursor: 'pointer'
  },
  chipEnabled: {
    color: '#927A22',
    border: '1px solid #927A22'
  },
  chipDisabled: {
    color: '#666666',
    border: '1px solid #666666',
    opacity: '0.5'
  },
  maxSymbolsAlertArea: {
    background: 'rgba(0, 0, 0, 0.05)',
    paddingTop: '0px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  maxSymbolsAlertBox: {
    width: '100%',
    height: '32px',
    background: '#F2ECDA',
    borderRadius: '8px',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#333333',
    textAlign: 'center',
    '& span': {
      position: 'relative',
      top: '10px'
    }
  }
});

const getSearchedSymbols = () => {
  let value = localStorage.getItem(SYMBOL_SEARCH_HISTORY_KEY);
  if (!value) {
    return [];
  }

  return JSON.parse(value);
}

const addSearchedSymbol = (symbolId) => {
  let symbols = getSearchedSymbols();
  // Add at the beginning
  symbols.unshift(symbolId);

  // Leave only unique symbols
  symbols = [...new Set(symbols)];

  // Limit to max amount of results
  symbols.splice(SYMBOL_SEARCH_HISTORY_MAX_RESULTS);

  // Save to localStorage
  localStorage.setItem(SYMBOL_SEARCH_HISTORY_KEY, JSON.stringify(symbols));
}

const MobileSearchItemListContainer = ({
  onSelectedSymbol,
  marketboardId = '',
  initialSearchPattern = ''
}) => {
  // Actions
  const [getSymbol] = useActions([fetchSymbolPatternIfNeeded], []);

  // Local State
  const [searchPattern, setSearchPattern] = useState(initialSearchPattern);
  const [searchResult, setSearchResult] = useState([]);
  const [
    includeJpeq,
    includeUseq,
    includeSupplementals,
    includeIndustry,
    setIncludeJpeq,
    setIncludeUseq,
    setIncludeSupplementals,
    setIncludeIndustry
  ] = useSymbolSearchFlags();

  // Intl
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });

  // Store
  const symbols = useSelector((state) => state.symbols || {});
  const boards = useSelector(
    (state) => getMarketboards(state) || { boards: [] }
  ).boards;
  const board = getBoardById(boards, marketboardId);
  const isCashOrMargin = isPositionBoard(marketboardId);
  const symbolCountExceeded =
    board && board.Symbols && board.Symbols.length >= MAX_SYMBOLS;
  const metadata = useSelector((state) => state.symbols.data || { data: {} } || {});

  // Callbacks
  // initialize debounce on mount
  const search = useCallback(
    (searchPattern) => {
      if (!searchPattern) {
        return;
      }

      getSymbol(
        searchPattern,
        DEFAULT_MAX_SYMBOL_SEARCH_RESULTS,
        includeJpeq,
        includeUseq,
        includeSupplementals,
        includeIndustry
      );
    },
    [getSymbol, includeIndustry, includeJpeq, includeSupplementals, includeUseq]
  );

  const debouncedSearch = useMemo(
    () => debounce(search, SEARCH_DEBOUNCE),
    [search]
  );

  // Effects
  // the first time the page loads, check if there is a search pattern saved in local storage
  useEffect(() => {
    let value = localStorage.getItem(SYMBOL_SEARCH_PATTERN_KEY);
    if (value) {
      setSearchPattern(value);
      localStorage.setItem(SYMBOL_SEARCH_PATTERN_KEY, '');
    }
  }, []);

  // execute search when pattern has changed
  useEffect(
    () => debouncedSearch(searchPattern),
    [debouncedSearch, searchPattern]
  );

  // update search result when we got search results
  useEffect(() => {
    const result = symbols.searches[searchPattern] || {
      symbols: [],
      isLoading: true
    };

    // save search result
    setSearchResult(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbols]);

  async function navigateTo(redirectUrl) {
    window.location.href = redirectUrl;
  }

  async function navigateAndTrack(navigationType, redirectUrl, user, symbolId) {
    trackNavigation(navigationType);

    let data = {
      navigationType: navigationType,
      origin: 'SymbolSearch',
      symbol: symbolId,
      redirectUrl: redirectUrl,
      encryptedId: user.encryptedId
    };

    await Promise.race([
      postUserTrade(data),
      new Promise((resolve) => setTimeout(resolve, 400))
    ]);
    window.location.href = redirectUrl;
  }

  const toggleJpeq = () => {
    setIncludeJpeq(!includeJpeq);
  };

  const toggleUseq = () => {
    setIncludeUseq(!includeUseq);
  };

  const toggleSupplemental = () => {
    setIncludeSupplementals(!includeSupplementals);
  };

  const toggleIndustry = () => {
    setIncludeIndustry(!includeIndustry);
  };

  const onChange = useCallback((e) => {
    setSearchResult({ symbols: [] });
    setSearchPattern(e.target.value);
  }, []);

  // Styles
  const styles = useStyles();

  const searchInput = useMemo(
    () => (
      <MobileMarketboardSearchBox
        searchPattern={searchPattern}
        onChange={onChange}
      />
    ),
    [searchPattern, onChange]
  );

  const jpeqChip = (
    <span
      id={`toggle-jpeq-${includeJpeq ? 'enabled' : 'disabled'}`}
      className={[
        styles.assetChip,
        includeJpeq ? styles.chipEnabled : styles.chipDisabled
      ].join(' ')}
      onClick={toggleJpeq}
    >
      <img
        src={includeJpeq ? CheckIconGold : CheckIconGray}
        alt={`jpeq ${includeJpeq ? 'included' : 'excluded'}`}
      />
      {localize('local.menu.search.jpeq')}
    </span>
  );
  const useqChip = (
    <span
      id={`toggle-useq-${includeUseq ? 'enabled' : 'disabled'}`}
      className={[
        styles.assetChip,
        includeUseq ? styles.chipEnabled : styles.chipDisabled
      ].join(' ')}
      onClick={toggleUseq}
    >
      <img
        src={includeUseq ? CheckIconGold : CheckIconGray}
        alt={`useq ${includeUseq ? 'included' : 'excluded'}`}
      />
      {localize('local.menu.search.useq')}
    </span>
  );
  const supplementalChip = (
    <span
      id={`toggle-supplemental-${includeSupplementals ? 'enabled' : 'disabled'
        }`}
      className={[
        styles.assetChip,
        includeSupplementals ? styles.chipEnabled : styles.chipDisabled
      ].join(' ')}
      onClick={toggleSupplemental}
    >
      <img
        src={includeSupplementals ? CheckIconGold : CheckIconGray}
        alt={`supplemental ${includeSupplementals ? 'included' : 'excluded'}`}
      />
      {localize('local.menu.search.supplementals')}
    </span>
  );
  const industryChip = (
    <span
      id={`toggle-industry-${includeIndustry ? 'enabled' : 'disabled'}`}
      className={[
        styles.assetChip,
        includeIndustry ? styles.chipEnabled : styles.chipDisabled
      ].join(' ')}
      onClick={toggleIndustry}
    >
      <img
        src={includeIndustry ? CheckIconGold : CheckIconGray}
        alt={`industry ${includeIndustry ? 'included' : 'excluded'}`}
      />
      {localize('local.menu.search.industry')}
    </span>
  );
  const assetBar = (
    <div className={styles.chipBar}>
      {jpeqChip}
      {useqChip}
      {supplementalChip}
      {industryChip}
    </div>
  );

  const user = useSelector((state) => state.user || {});
  const searchResultListItem = (symbol, isFromHistory) => {
    const symbolData = (metadata[symbol] || {}).data;
    const assetClass = (symbolData || {}).assetClass;
    const isJpeqSymbol = isJpeq(assetClass);
    const isUseqSymbol = isUseq(assetClass);
    const isPurchasable = isJpeqSymbol || isUseqSymbol;
    return (
      <MobileSymbolSearchCard
        symbol={symbol}
        isFromHistory={isFromHistory}
        onAddClick={() => {
          addSearchedSymbol(symbol);
          onSelectedSymbol(symbol);
        }}
        onOrderClick={async () => {
          if (isPurchasable) {
            localStorage.setItem(SYMBOL_SEARCH_PATTERN_KEY, searchPattern);
            addSearchedSymbol(symbol);
            await navigateAndTrack(
              navigationTypes.SS_CASH_BUY_CLICK,
              getSymbolBuySellUrl(assetClass, symbol, user, 'buy', false),
              user,
              symbol
            );
          }
        }}
        onDetailsClick={async () => {
          localStorage.setItem(SYMBOL_SEARCH_PATTERN_KEY, searchPattern);
          addSearchedSymbol(symbol);
          await navigateTo(`#/symbol/${symbol}`);
        }}
        isRecent={false}
        isAddDisabled={isCashOrMargin || symbolCountExceeded}
        isBuyDisabled={!isPurchasable}
        isDetailsDisabled={false}
      />
    );
  };

  const searchResultList = (symbols) => {
    let isFromHistory = false;
    if (symbols.length === 0 && !searchPattern) {
      symbols = getSearchedSymbols();
      isFromHistory = true;
    }

    return symbols.map((symbol) => searchResultListItem(symbol, isFromHistory));
  };

  const alertBox = (message) => {
    return (
      <div className={styles.maxSymbolsAlertArea}>
        <div className={styles.maxSymbolsAlertBox}>
          <span>{message}</span>
        </div>
      </div>
    );
  };

  const searchResultData = searchResult.symbols || [];
  const noSearchResults = !!searchPattern && searchResultData.length === 0;

  return (
    <div className={'symbol-search-fixed'}>
      {searchInput}
      {assetBar}
      {!isCashOrMargin && symbolCountExceeded &&
        alertBox(localize('marketboard.symbol.count.exceeded.mobile'))}
      {noSearchResults && alertBox(localize('message.no.symbols.found'))}
      <div className={styles.divider} />
      {searchResultList(searchResultData)}
    </div>
  );
};

export default MobileSearchItemListContainer;
