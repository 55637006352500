import React from 'react';

class SymbolHistoricalListHeader extends React.PureComponent {
  getVolumeHeaderString() {
    if (this.props.barSize === '1day' || this.props.barSize === '5min') {
      return this.props.localize('symbol.details.historical.header.volume');
    } else {
      return this.props.localize(
        'symbol.details.historical.header.averagevolume'
      );
    }
  }

  render() {
    let headerTime;
    switch (this.props.barSize) {
      case '5min':
      case '1hour':
        headerTime = 'time';
        break;
      case '1quarter':
        headerTime = 'month';
        break;
      default:
        headerTime = 'date';
        break;
    }

    return (
      <div className={'header row'}>
        <div className={'cell'}>
          <div className={'multirow-cell'}>
            {this.props.localize(
              'symbol.details.historical.header.' + headerTime
            )}
          </div>
        </div>
        <div className={'cell'}>
          <div className={'subcell'}>
            {this.props.localize('symbol.details.historical.header.high')}
          </div>
          <div className={'subcell'}>
            {this.props.localize('symbol.details.historical.header.low')}
          </div>
        </div>
        <div className={'cell'}>
          <div className={'subcell'}>
            {this.props.localize('symbol.details.historical.header.open')}
          </div>
          <div className={'subcell'}>
            {this.props.localize('symbol.details.historical.header.close')}
          </div>
        </div>
        <div className={'cell'}>
          <div className={'subcell'}>
            {this.props.localize(
              `symbol.details.historical.header.change.${this.props.barSize}`
            )}
          </div>
          {this.props.displayVolume && (
            <div className={'subcell'}>{this.getVolumeHeaderString()}</div>
          )}
        </div>
      </div>
    );
  }
}
export default SymbolHistoricalListHeader;
