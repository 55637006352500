import _ from 'lodash';

const marketboardLastViewedBoard = 'marketboard.last.board';

export function isPositionBoard(boardId) {
  if (!boardId) {
    return false;
  }

  return boardId.startsWith('cash') || boardId.startsWith('margin');
}

export function getBoardType(boardId) {
  if (!boardId) {
    return null;
  }

  switch (true) {
    case boardId.startsWith('cash'):
      return 'cash';
    case boardId.startsWith('margin'):
      return 'margin';
    default:
      return 'user';
  }
}

export function vwap(turnover, volume) {
  if (!!turnover && !!volume) {
    return turnover / volume;
  }

  return 0;
}

export function pnlValuation(type, position, quote) {
  if (!isPositionBoard(type) || !position || !quote) {
    return null;
  }
  if (type === 'cash') {
    return quote * position.quantity;
  }
  return position.openPrice * position.quantity;
}

export function positionPrice(type, position) {
  if (!isPositionBoard(type) || !position) {
    return null;
  }
  if (type === 'cash') {
    return position.averagePrice;
  }
  return position.openPrice;
}

export function pnlNetChange(type, position, quote) {
  if (!isPositionBoard(type) || !position || !quote) {
    return null;
  }
  if (type === 'margin') {
    if (position.side && position.side.toUpperCase() === 'SHORT') {
      return (
        (position.openPrice - quote) * position.quantity - position.commission
      );
    }
    return (
      (quote - position.openPrice) * position.quantity - position.commission
    );
  }
  return (quote - position.averagePrice) * position.quantity;
}

export function pnlNetChangePct(type, position, quote) {
  if (!isPositionBoard(type) || !position || !quote) {
    return null;
  }
  return 100 * (pnlNetChange(type, position, quote) / position.cost);
}

function findFirstIndexOfLastNullChunk(array) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i] !== null || array[i - 1] === null) {
      continue;
    }
    return i;
  }
  return -1;
}

export function getAvailableBoardPosition(symbols) {
  let availablePosition = Array(50).fill(null);
  (symbols || []).forEach((symbol) => {
    availablePosition[symbol.Position] = symbol.Symbol;
  });
  return findFirstIndexOfLastNullChunk(availablePosition);
}

export const getMaxSymbolsPerBoard = () => 50;

export const updateMarketboardProVisibleRowIndex = (
  minHeight,
  maxHeight,
  gridApi,
  currentGridContainerElement,
  currentVisibleRowIndex,
  updateCallback
) => {
  if (
    minHeight < 0 ||
    maxHeight <= minHeight ||
    !currentGridContainerElement ||
    !currentVisibleRowIndex
  ) {
    // required parameters are not valid
    return;
  }

  if (!gridApi) {
    // this might happen during the first few ProMarketBoard rendering, and the gridApi is not ready yet
    return;
  }

  const sizes = gridApi.getSizesForCurrentTheme();
  const headerHeight = sizes?.headerHeight || 0;
  const rowHeight = sizes?.rowHeight || 0;

  const containerRect = currentGridContainerElement.getBoundingClientRect();
  const containerTop = containerRect?.top || 0;

  const firstRowTop = containerTop + headerHeight;

  const rowCount = gridApi.getDisplayedRowCount();

  // when rowCount is 0
  // set both the firstIndex and the lastIndex to -1 so adding a new row will trigger a render
  if (rowCount <= 0) {
    if (updateCallback) {
      updateCallback(-1, -1, 0);
    }
    return;
  }

  // if we could not get info for containerRect, the headerHeight, the rowHeight
  // we might want to be safe and set the firstIndex to 0 and the lastIndex to rowCount - 1 to render all rows
  if (!containerRect || headerHeight <= 0 || rowHeight <= 0) {
    const lastIndex = Math.max(0, rowCount - 1);
    if (updateCallback) {
      updateCallback(0, lastIndex, rowCount);
    }
    return;
  }

  // we also update rows that are partially visible
  let newFirstIndex = rowCount;
  let newLastIndex = -1;

  for (let index = 0; index < rowCount; index++) {
    const rowTop = firstRowTop + rowHeight * index;
    const rowBottom = rowTop + rowHeight;

    if (rowBottom >= minHeight && index < newFirstIndex) {
      newFirstIndex = index;
    }

    if (rowTop <= maxHeight && index > newLastIndex) {
      newLastIndex = index;
    }
  }

  if (
    newFirstIndex !== currentVisibleRowIndex.first ||
    newLastIndex !== currentVisibleRowIndex.last
  ) {
    if (updateCallback) {
      updateCallback(newFirstIndex, newLastIndex, rowCount);
    }
  }
};

export const getPreviousSortingValue = (sortingColumns, data) => {
  const previousSortingValue = {};
  Object.keys(sortingColumns).forEach((key) => {
    previousSortingValue[key] = data[key];
  });

  return previousSortingValue;
};

export const rowIsVisible = (
  rowNode,
  visibleRowIndex,
  onlyRenderVisibleRows,
  maxNumSymbols
) => {
  const firstIndex = onlyRenderVisibleRows ? visibleRowIndex.first : -1;

  const lastIndex = onlyRenderVisibleRows
    ? visibleRowIndex.last
    : maxNumSymbols;

  return rowNode.rowIndex >= firstIndex && rowNode.rowIndex <= lastIndex;
};

export const valueChangedInSortingColumn = (
  rowNode,
  previousSortingValue,
  sortingColumns
) => {
  const data = rowNode.data;

  return Object.keys(sortingColumns).some((key) => {
    return !_.isEqual(data[key], previousSortingValue[key]);
  });
};

export const addRowToUpdateItems = (
  rowNode,
  itemsToBeUpdated,
  visibilityPredicate,
  sortingChangedPredicate
) => {
  if (visibilityPredicate(rowNode)) {
    itemsToBeUpdated.push(rowNode.data);
  } else {
    if (sortingChangedPredicate(rowNode)) {
      itemsToBeUpdated.push(rowNode.data);
    }
  }

  return itemsToBeUpdated;
};

export const rowHasNoUpdate = (rowNode, updateBook) => {
  const data = rowNode.data;

  if (
    data.quoteTimestamp === updateBook[data.id]?.quoteTimestamp &&
    data.memoTimestamp === updateBook[data.id]?.memoTimestamp &&
    data.metadataTimestamp === updateBook[data.id]?.metadataTimestamp
  ) {
    return true;
  }

  return false;
};

export const saveLastMarketboard = (marketboardId) => {
  // eg: mi.monexinsight-dev.net
  let domain = window.location.hostname;
  if (domain !== 'localhost') {
    // This removes the first part of the domain, eg: mi.monexinsight-dev.net -> monexinsight-dev.net
    let tokens = domain.split('.');
    tokens.shift();
    domain = tokens.join('.');
  }

  var expires = (new Date(Date.now()+ 86400*1000*365)).toUTCString();
  document.cookie = marketboardLastViewedBoard + '=' + marketboardId + '; domain=' + domain + '; expires=' + expires;
};

export const getLastMarketboard = () => {
  return document.cookie.split('; ')
  .find((row) => row.startsWith(marketboardLastViewedBoard + '='))?.split('=')[1] || null;
};
