import _ from 'lodash';
import React from 'react';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import ChartIndicator from './ChartIndicator';
import { connect } from 'react-redux';
import ChartStyle from './ChartStyle';
import {
  setChartStyle,
  setDisplayTooltip,
  setIndicators,
  setIndicatorParameters,
  setDisabledIndicators
} from '../../actions/user';

const DEFAULT_PARAMS = {
  sma: [5],
  avgDeviation: [5, 25],
  ichimoku: [0],
  bb: [10],
  volAvg: [10],
  volByPrice: [20],
  macd: [6, 12, 9],
  parabolicSar: [0],
  stochasticSlow: [14],
  stochasticFast: [14],
  rsi: [14],
  movAvgEnvelopes: [10],
  percentageR: [14],
  advDeclineRatios: [25],
  avgTrueRange: [14],
  mfi: [14],
  rci: [10, 3],
  cci: [14, 9]
};

const INDICATORS = [
  'sma',
  'avgDeviation',
  'ichimoku',
  'bb',
  'volAvg',
  'volByPrice',
  'macd',
  'parabolicSar',
  'stochasticSlow',
  'stochasticFast',
  'rsi',
  'movAvgEnvelopes',
  'percentageR',
  'advDeclineRatios',
  'avgTrueRange',
  'mfi',
  'rci',
  'cci'
];

function isMarkedAsFavorite(indicator, indicators, disabledIndicators) {
  // First try to find the indicator in the indicators array
  let index = indicators.findIndex((ind) => ind.type === indicator);
  if (index !== -1) {
    return indicators[index].isFavorite || false;
  }

  // If not, then try to find the indicator in the disabledIndicators array
  index = disabledIndicators.findIndex((ind) => ind.type === indicator);
  if (index !== -1) {
    return disabledIndicators[index].isFavorite || false;
  }

  // If not, then the indicator is not marked as favorite
  return false;
}

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartStyle: props.settings.chartStyle || 'candlestick',
      displayTooltip: props.settings.displayTooltip,
      indicators: props.indicators,
      disabledIndicators: props.disabledIndicators,
      favorites: {}
    };
  }

  localize(i18nKey, otherParams) {
    return this.props.intl.formatMessage({ id: i18nKey }, otherParams);
  }

  setChartStyle = (event) => {
    let value = event.target.value;
    this.props.actions.setChartStyle(value);
    this.setState({
      chartStyle: value
    });
  };

  setDisplayTooltip = (event) => {
    let value = event.target.checked;
    this.props.actions.setDisplayTooltip(value);
    this.setState({
      displayTooltip: value
    });
  };

  addIndicator = (indicator) => {
    let indicators = [...this.props.indicators];
    let disabledIndicators = [...this.props.disabledIndicators];
    let oldIndicator = disabledIndicators.filter(
      (ind) => ind.type === indicator
    );
    if (oldIndicator.length > 0) {
      indicators.push(oldIndicator[0]);
      disabledIndicators = disabledIndicators.filter(
        (ind) => ind.type !== indicator
      );
      this.updateDisabledIndicators(disabledIndicators);
    } else {
      indicators.push({
        type: indicator,
        isFavorite: false,
        params: DEFAULT_PARAMS[indicator]
      });
    }
    this.updateIndicators(indicators);
  };

  deleteIndicator = (indicator) => {
    let indicators = [...this.props.indicators];
    let disabledIndicators = [...this.props.disabledIndicators];
    disabledIndicators.push(this.getIndicator(indicator));
    indicators = indicators.filter((ind) => ind.type !== indicator);
    this.updateIndicators(indicators);
    this.updateDisabledIndicators(disabledIndicators);
  };

  updateIndicators = (indicators) => {
    this.setState({
      indicators: indicators
    });
    this.props.actions.setIndicators(indicators);
  };

  updateDisabledIndicators = (indicators) => {
    this.setState({
      disabledIndicators: indicators
    });
    this.props.actions.setDisabledIndicators(indicators);
  };

  getIndicator = (indicatorName) => {
    return (
      _.find(
        this.state.indicators,
        (indicator) => indicator.type === indicatorName
      ) || {}
    );
  };

  getPeriods = (type) => {
    let indicator = this.getIndicator(type);
    return [...indicator.params] || [];
  };

  updatePeriods = (periods, type) => {
    let indicators = [...this.state.indicators];
    let indicator =
      _.find(indicators, (indicator) => indicator.type === type) || {};
    this.props.actions.setIndicatorParameters(indicator.type, periods);
    indicator.params = periods;
    this.setState({
      indicators: indicators
    });
    this.props.actions.setIndicatorParameters(indicator.type, periods);
  };

  addParameter = (type) => {
    let periods = this.getPeriods(type);
    // We will only add the first member of the default.
    this.updatePeriods(periods.concat(DEFAULT_PARAMS[type][0]), type);
  };

  setPeriod = (i, value, type) => {
    let periods = this.getPeriods(type);
    periods[i] = value;
    this.updatePeriods(periods, type);
  };

  deletePeriod = (i, type) => {
    let periods = this.getPeriods(type);
    if (i === -1) {
      return;
    }

    periods.splice(i, 1);
    if (periods.length > 0) {
      this.updatePeriods(periods, type);
    } else {
      this.deleteIndicator(type);
    }
  };

  isIndicatorActive = (type) => {
    return !!this.props.enabledIndicators[type];
  };

  toggleFavorite = (indicator) => {
    // First try to find the indicator in the indicators array
    const indicators = [...this.props.indicators];
    const disabledIndicators = [...this.props.disabledIndicators];
    let isFavorite = isMarkedAsFavorite(
      indicator,
      indicators,
      disabledIndicators
    );
    let index = indicators.findIndex((ind) => ind.type === indicator);
    if (index !== -1) {
      indicators[index].isFavorite = !isFavorite;
      this.updateIndicators(indicators);
      return;
    }

    // If not, then try to find the indicator in the disabledIndicators array
    index = disabledIndicators.findIndex((ind) => ind.type === indicator);
    if (index !== -1) {
      disabledIndicators[index].isFavorite = !isFavorite;
      this.updateDisabledIndicators(disabledIndicators);
      return;
    }

    // If not, add the indicator to the disabledIndicators and mark it as favorite
    disabledIndicators.push({
      type: indicator,
      isFavorite: true,
      params: DEFAULT_PARAMS[indicator]
    });
    this.updateDisabledIndicators(disabledIndicators);
  };

  getIndicatorsOrderedByFavorites() {
    let favorites = [];
    let nonFavorites = [];
    for (let i = 0; i < INDICATORS.length; i++) {
      let indicator = INDICATORS[i];
      let isFavorite = this.state.favorites[indicator] || false;
      if (isFavorite) {
        favorites.push(indicator);
      } else {
        nonFavorites.push(indicator);
      }
    }

    return favorites.concat(nonFavorites);
  }

  initFavoritesIfRequired() {
    if (Object.keys(this.state.favorites).length === 0) {
      let favorites = {};
      for (let i = 0; i < INDICATORS.length; i++) {
        let indicator = INDICATORS[i];
        let isFavorite = isMarkedAsFavorite(
          INDICATORS[i],
          this.props.indicators,
          this.props.disabledIndicators
        );
        favorites[indicator] = isFavorite;
      }

      this.setState(() => {
        return {
          favorites: favorites
        };
      });
    }
  }

  render() {
    // Since we only want the indicators to be ordered by favorites only when the dialog is closed, we will initialize
    // them only once here.
    this.initFavoritesIfRequired();

    const indicatorsList = this.getIndicatorsOrderedByFavorites().map(
      (indicator, index) => {
        let isFavorite = isMarkedAsFavorite(
          indicator,
          this.props.indicators,
          this.props.disabledIndicators
        );
        return (
          <li className="settings-card" key={index}>
            <ChartIndicator
              key={index}
              type={indicator}
              isFavorite={isFavorite || false}
              indicators={this.props.indicators}
              addPeriod={this.addParameter}
              setPeriod={this.setPeriod}
              deletePeriod={this.deletePeriod}
              deleteIndicator={this.deleteIndicator}
              addIndicator={this.addIndicator}
              isIndicatorActive={this.isIndicatorActive}
              toggleFavorite={this.toggleFavorite}
            />
          </li>
        );
      }
    );
    return (
      <div>
        <div
          className={
            'chart-style-section ' + (this.props.chartSectionClassName || '')
          }
        >
          {this.localize('settings.chart.title')}
        </div>
        <ul>
          <ChartStyle
            value={this.state.chartStyle}
            onChange={this.setChartStyle}
          />
        </ul>
        <div
          className={
            'chart-style-section ' + (this.props.chartSectionClassName || '')
          }
        >
          {this.localize('settings.chart.indicators.title')}
        </div>
        <ul>{indicatorsList}</ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let settings = state.user.settings.chart || {};
  let indicators = settings.indicators || [];
  let disabledIndicators = settings.disabledIndicators || [];
  let enabledIndicators = {};
  for (let i = 0; i < INDICATORS.length; i++) {
    let indicatorName = INDICATORS[i];
    enabledIndicators[indicatorName] = indicators.some(
      (i) => i.type === indicatorName
    );
  }

  return {
    settings: settings,
    indicators: indicators,
    enabledIndicators: enabledIndicators,
    disabledIndicators: disabledIndicators
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setChartStyle: bindActionCreators(setChartStyle, dispatch),
      setDisplayTooltip: bindActionCreators(setDisplayTooltip, dispatch),
      setIndicators: bindActionCreators(setIndicators, dispatch),
      setIndicatorParameters: bindActionCreators(
        setIndicatorParameters,
        dispatch
      ),
      setDisabledIndicators: bindActionCreators(setDisabledIndicators, dispatch)
    }
  };
}

Chart = injectIntl(Chart);

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
