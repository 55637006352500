import React from 'react';
import { injectIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import {
  showCodeAndExchange,
  getSymbolAssetTypeResourceId
} from '../../utils/symbol';

import Exchange from '../../views/common/Exchange';
import AddSearchIcon from '../../icons/add_search.png';
import MoreSearchIcon from '../../icons/more_search.png';

const useStyles = makeStyles({
  result: {
    'border-bottom': '1px solid #ebebed',
    cursor: 'pointer',

    '&.selected td': {
      'background-color': '#e7edf0'
    },

    '&:hover td': {
      'background-color': '#dce1e8'
    },

    '& td': {
      'line-height': '32px',
      'white-space': 'nowrap',
      padding: '0 4px'
    },

    '&:last-child': {
      'border-bottom': 'none'
    }
  },

  description: {
    'font-weight': 'bold'
  },

  exchange: {
    color: '#9fa6af'
  },

  multiListed: {
    '-webkit-border-radius': '4px',
    '-moz-border-radius': '4px',
    'border-radius': '4px',
    background: '#95a3b5',
    color: '#fff',
    display: 'inline-block',
    'font-size': '12px',
    'line-height': '11px',
    'margin-left': '4px',
    opacity: '1',
    padding: '4px',
    position: 'relative',
    'vertical-align': 'baseline'
  },

  actions: {
    'text-align': 'right',

    '& .action': {
      margin: '0 2px',
      cursor: 'pointer',
      display: 'inline-block',
      opacity: '.8',

      '&.add': {
        background: `transparent url(${AddSearchIcon}) no-repeat 0 3px`,
        width: '16px',
        height: '17px'
      },

      '&.detail': {
        background: `transparent url(${MoreSearchIcon}) no-repeat 0 3px`,
        width: '12px',
        height: '17px'
      }
    }
  }
});

const SymbolSearchResultItem = ({
  intl,
  symbolName,
  metadata,
  selected,
  onClick,
  showActionButtons,
}) => {
  const localize = (id) => intl.formatMessage({ id: id });
  const renderSymbolRoot = () =>
    showCodeAndExchange(metadata) ? <td>{metadata.root}</td> : null;

  const renderAssetClass = () =>
    !showCodeAndExchange(metadata) ? (
      <td>{localize(getSymbolAssetTypeResourceId(metadata.assetClass))}</td>
    ) : null;

  const renderSymbolName = () => (
    <td className={classes.description}>{metadata.name}</td>
  );

  const renderExchange = () =>
    showCodeAndExchange(metadata) ? (
      <td className={classes.exchange}>
        <Exchange
          name={metadata.exchangeName}
          section={metadata.jpSection}
          root={metadata.root}
          jpShortName={metadata.jpShortName}
          assetClass={metadata.assetClass}
        />
        {metadata.variants > 0 ? (
          <span className={classes.multiListed}>
            {localize('exchange.multiple.listing')}
          </span>
        ) : (
          <span></span>
        )}
      </td>
    ) : null;

  const renderPadding = () =>
    !showCodeAndExchange(metadata) ? (
      <td className={classes.exchange}>&nbsp;</td>
    ) : null;

  const renderActionButtons = () => (
    <td className={classes.actions}>
      
      <span><a href={`#/symbol/${symbolName}`}><span className="action detail" /></a></span>
    </td>
  );

  const classes = useStyles();
  return (
    <tr
      className={classnames(classes.result, selected ? 'selected' : '')}
      key={symbolName}
      onClick={() => {
        console.log(`>> Clicked: ${symbolName}`);
        onClick(symbolName);
      }}
    >
      {/* Symbol Root */}
      {renderSymbolRoot()}
      {/* Asset Class */}
      {renderAssetClass()}
      {/* Symbol Name */}
      {renderSymbolName()}
      {/* Exchange */}
      {renderExchange()}
      {renderPadding()}
      {/* Action buttons (Add to marketboard, Show Symbol Details) */}
      {showActionButtons && renderActionButtons()}
    </tr>
  );
};

export default injectIntl(SymbolSearchResultItem);
