import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { getConsensusRisingRankingsLink } from '../../utils/formatter';
import navigationTypes from '../../actions/_navigationTypes';
import { trackNavigation } from '../../utils/analytics';
import OpenIcon from '@mui/icons-material/OpenInNew';
import PriceRankingsTopFive from './PriceRankingsTopFive';
import { Tabs } from '../common/Tabs';

const exchanges = ['allexchanges', 'tse_p', 'tse_s', 'tse_g'];

const hotlists = ['raterise', 'ratefall', 'volume', 'turnover'];

class PriceRankings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExchange: 0
    };
    this.handleExchangeChange = this.handleExchangeChange.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  handleExchangeChange(event, value) {
    let index = _.findIndex(exchanges, function (exchange) {
      return exchange === value;
    });
    this.setState({
      selectedExchange: index
    });
  }

  render() {
    const HotlistsButton = () => {
      return (
        <Link to={'/hotlists'}>
          <div className="actions">
            <span className="margin button btn-cmn global-news">
              {this.props.intl.formatMessage({
                id: 'market.summary.price.rankings.hotlists.link'
              })}
            </span>
          </div>
        </Link>
      );
    };

    const consensusRisingRankingsLink = getConsensusRisingRankingsLink(
      this.props.user.ifisHash,
      this.props.user.encryptedId
    );

    const ConsensusLink = () => {
      return (
        <div className={'market_summary-link-row'}>
          <a
            href={consensusRisingRankingsLink}
            onClick={() => {
              trackNavigation(
                navigationTypes.MS_CONSENSUS_RISING_RANKINGS_CLICK
              );
            }}
          >
            <div className={'market_summary-consensus-container'}>
              <span className="market_summary-consensus-link">
                {this.props.intl.formatMessage({
                  id: 'market.summary.price.rankings.consensus.link'
                })}
              </span>
              {
                <OpenIcon
                  style={{
                    padding: 0,
                    height: 20,
                    width: 20,
                    verticalAlign: 'middle',
                    color: '#555'
                  }}
                />
              }
            </div>
          </a>
        </div>
      );
    };

    return (
      <div className="marketboard-list-view">
        <div className="section-header">
          {this.localize('market.summary.price.rankings.title')}
        </div>
        <Tabs
          tabValues={exchanges}
          selectedTabIndex={this.state.selectedExchange}
          handleTabChange={this.handleExchangeChange}
          labelKeyPrefix={'market.summary.price.rankings.type.'}
        />
        {hotlists.map((hotlist, index) => {
          return (
            <PriceRankingsTopFive
              key={`${hotlist} tab`}
              className={`${hotlist} tab`}
              label={this.props.intl.formatMessage({
                id: `market.summary.price.rankings.type.${hotlist}`
              })}
              exchange={exchanges[this.state.selectedExchange]}
              hotlistName={hotlists[index]}
            />
          );
        })}
        <HotlistsButton />
        <ConsensusLink />
      </div>
    );
  }
}

PriceRankings = injectIntl(PriceRankings);

function mapStateToProps(state, ownProps) {
  if (!state.user) {
    return {};
  }

  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };

  // Grab user
  props.user = state.user;
  return props;
}

export default connect(mapStateToProps)(PriceRankings);
