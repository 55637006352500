import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '../common/Button';
import { TextField, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  input: {
    marginTop: '16px'
  },
  deleteContent: {
    marginTop: '14px',
    marginLeft: '13px',
    fontWeight: '900'
  },
  deleteDialogCancel: {
    color: '#000000',
    backgroundColor: 'white !important'
  },
  deleteDialogConfirm: {
    backgroundColor: '#d84e4e',
    color: 'white'
  },
  buttonDisabled: {
    color: '#bdbdbd',
    backgroundColor: '#eeeeee'
  },
  checkBox: {
    '& .MuiSvgIcon-root': {
      fontSize: 22,
      color: '#616161'
    },
    'padding-bottom': '12px'
  },
  deleteDialog: {}
});

const DeleteDialog = ({
  titleText,
  contentText,
  cancelText,
  confirmText,
  openDialog,
  onCancel = 'キャンセル',
  onDelete,
  keyword,
  confirmViaCheckbox,
  inputPlaceholder,
  deleteContent,
  classes,
  swapButtons = false
}) => {
  const defaultStyles = useStyles();
  const styles = classes ?? defaultStyles;

  const [isDisabled, setIsDisabled] = useState(true);
  const [text, setText] = useState('');

  const inputRef = useRef(null);
  const confirmationRequired = keyword || confirmViaCheckbox;

  useEffect(() => {
    if (confirmationRequired && !openDialog) {
      setText('');
      setIsDisabled(true);
    }
  }, [confirmationRequired, openDialog]);

  const handleTextChange = (event) => {
    setIsDisabled(event.target.value !== keyword);
    setText(event.target.value.length > 0 ? event.target.value : undefined);
  };
  
  const deleteButtonDisabled = confirmationRequired && isDisabled;

  const deleteButton = (
    <Button
      id={'delete-dialog-confirm'}
      disabled={deleteButtonDisabled}
      className={[
        styles.deleteDialogConfirm,
        deleteButtonDisabled ? styles.buttonDisabled : '',
      ].join(' ')}
      onClick={() => {
        if (confirmationRequired) {
          isDisabled && inputRef.current?.focus();
          !isDisabled && onDelete();
        } else {
          onDelete();
        }
      }}
    >
      {confirmText}
    </Button>
  );

  const cancelButton = (
    <Button
      id="delete-dialog-cancel"
      className={styles.deleteDialogCancel}
      onClick={onCancel}
    >
      {cancelText}
    </Button>
  );

  return (
    <Dialog
      id="delete-dialog"
      className={styles.deleteDialog}
      open={!!openDialog}
    >
      <DialogTitle className={styles.titleText}>{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText className={styles.contentText}>
          {contentText}
        </DialogContentText>
        {deleteContent && (
          <DialogContentText className={styles.deleteContent}>
            {deleteContent}
            {confirmViaCheckbox && (
              <Checkbox
                className={styles.checkBox}
                checked={!isDisabled}
                onChange={() => setIsDisabled(!isDisabled)}
              />
            )}
          </DialogContentText>
        )}
        {keyword && (
          <TextField
            placeholder={inputPlaceholder}
            fullWidth
            value={text}
            onChange={handleTextChange}
            className={styles.input}
            inputRef={inputRef}
          />
        )}
      </DialogContent>
      <DialogActions>
        {swapButtons ? deleteButton : cancelButton}
        {swapButtons ? cancelButton : deleteButton}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
