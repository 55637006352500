import types from './_actionTypes';
import fetchRetry from '../utils/fetchRetry';
import { handleErrors } from '../utils/common';

const API_URL = process.env.REACT_APP_INSIGHT_API_DOMAIN;

function getUrl() {
  // prettier-ignore
  return `https://${API_URL}/system/status`;
}

function receiveSystemStatusResponse(response) {
  return {
    type: types.RECEIVE_SYSTEM_STATUS,
    data: response
  };
}

export function errorRequestingSystemStatus(error) {
  return {
    type: types.ERROR_REQUESTING_SYSTEM_STATUS,
    error: error,
    errorCode: 'E0015'
  };
}

export function fetchSystemStatus() {
  return (dispatch) => {
    return fetchRetry(getUrl())
      .then(handleErrors)
      .then((response) => response.json())
      .then((response) => dispatch(receiveSystemStatusResponse(response)))
      .catch((ex) => {
        dispatch(errorRequestingSystemStatus(ex));
      });
  };
}
