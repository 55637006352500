import 'react-app-polyfill/ie11';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { unregister } from './registerServiceWorker';
import Root from './containers/Root';
import { version } from './version';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root tab="root" />);

unregister();

console.log(`version: ${version}`);
