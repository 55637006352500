import React from 'react';
import {DesktopWindowsOutlined} from "@mui/icons-material";
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import ChartIcon from '@mui/icons-material/InsertChartOutlined';
import SymbolDetailLinkRow from './SymbolDetailLinkRow';
import { trackNavigation } from '../../utils/analytics';
import navigationTypes from '../../actions/_navigationTypes';
import { FormattedMessage } from 'react-intl';
import {
  getShareholdersIncentivesUrl,
  getFinancialReportUrl,
  getUseqFinancialReportUrl,
  getAnalystReportUrl,
  getSymbolDetailsLink,
  getShikihoQuarterlyReportUrl,
} from '../../utils/formatter';

export default class Footer extends React.PureComponent {
  render() {
    let containerClass = this.props.containerClass || 'symbol-stats-table';

    // Build all our links here for readability
    let symbolDetailsLink = getSymbolDetailsLink(
      this.props.user.encryptedId,
      this.props.user.hash,
      this.props.symbol,
      this.props.user.rf,
      this.props.user.dm,
      this.props.user.attrSrcKey,
      this.props.user.rz
    );
    let shikihoLink = getShikihoQuarterlyReportUrl(
      this.props.symbol,
      this.props.user.hash,
      this.props.user.encryptedId
    );
    let financialReportLink = getFinancialReportUrl(
      this.props.symbol,
      this.props.user.ifisHash,
      this.props.user.encryptedId
    );
    let useqFinancialReportLink = getUseqFinancialReportUrl(
      this.props.user.dm,
      this.props.symbol
    );
    let analystReportLink = getAnalystReportUrl(
      this.props.user.dm,
      this.props.symbol
    );
    let shareholdersIncentivesLink = getShareholdersIncentivesUrl(
      this.props.user.dm,
      this.props.user.attrSrcKey,
      this.props.symbol
    );

    return (
      <div
        className={
          this.props.mainClassName
            ? this.props.mainClassName
            : 'symbol-detail-section'
        }
      >
        {/* <div className={'symbol-detail-section-title'}>{this.props.title}</div> */}
        <div className={containerClass}>
          <SymbolDetailLinkRow
            icon={<AssignmentIcon className={'symbol-detail-link-icon'} />}
            label={
              <FormattedMessage
                id={'symbol.details.button.shareholders.incentives'}
              />
            }
            link={shareholdersIncentivesLink}
            onClick={() => {
              trackNavigation(navigationTypes.SD_SHAREHOLDERS_INCENTIVES_CLICK);
            }}
            show={this.props.isSymbolEquity}
          />
          <SymbolDetailLinkRow
            icon={<AssignmentIcon className={'symbol-detail-link-icon'} />}
            label={
              <FormattedMessage id={'symbol.details.button.analyst.report'} />
            }
            link={analystReportLink}
            onClick={() => {
              trackNavigation(navigationTypes.SD_ANALYST_REPORT_CLICK);
            }}
            show={this.props.isSymbolEquity}
          />
          <SymbolDetailLinkRow
            icon={<ChartIcon className={'symbol-detail-link-icon'} />}
            label={
              <FormattedMessage
                id={'symbol.details.sidebar.financialReportLink'}
              />
            }
            link={useqFinancialReportLink}
            onClick={() => {
              trackNavigation(navigationTypes.SD_USEQ_FINANCIAL_REPORT_CLICK);
            }}
            show={this.props.isSymbolUseq}
          />
          <SymbolDetailLinkRow
            icon={<ChartIcon className={'symbol-detail-link-icon'} />}
            label={
              <FormattedMessage
                id={'symbol.details.sidebar.financialReportLink'}
              />
            }
            link={financialReportLink}
            onClick={() => {
              trackNavigation(navigationTypes.SD_FINANCIAL_REPORT_CLICK);
            }}
            show={this.props.isSymbolEquity}
          />
          <SymbolDetailLinkRow
            icon={<AssignmentIcon className={'symbol-detail-link-icon'} />}
            label={
              <FormattedMessage
                id={'symbol.details.sidebar.shikihoQuarterlyReportLink'}
              />
            }
            link={shikihoLink}
            onClick={() => {
              trackNavigation(navigationTypes.SD_SHIKIHO_REPORT_CLICK);
            }}
            show={this.props.isSymbolEquity}
          />
          <SymbolDetailLinkRow
            icon={<DesktopWindowsOutlined className={'symbol-detail-link-icon'} />}
            label={<FormattedMessage id={'symbol.details.pc.details.link'} />}
            link={symbolDetailsLink}
            onClick={() => {
              trackNavigation(navigationTypes.SD_OPEN_PC_SYMBOL_DETAILS);
            }}
            show={true}
          />
        </div>
      </div>
    );
  }
}
