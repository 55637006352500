import { combineReducers } from 'redux';
import streamReducer from './streamReducer';
import marketboardReducer from './marketboardReducer';
import marketReducer from './marketReducer';
import userReducer from './userReducer';
import symbolReducer from './symbolReducer';
import newsReducer from './newsReducer';
import systemReducer from './systemReducer';
import hotlistReducer from './hotlistReducer';
import barsReducer from './barsReducer';
import scanReducer from './scanReducer';
import memosReducer from './memosReducer';
import featureFlagsReducer from './featureFlagsReducer';
import { filterActions, ignoreActions } from 'redux-ignore';

// To save a few if statements, we can
// ignore, or filter, actions based on
// what the require.
const rootReducer = combineReducers({
  //nullReducer,
  stream: filterActions(streamReducer, [
    'CONNECTING',
    'RECEIVE_MESSAGE',
    'RECEIVE_STATUS'
  ]),
  marketboard: ignoreActions(marketboardReducer, [
    'CONNECTING',
    'RECEIVE_MESSAGE',
    'RECEIVE_STATUS'
  ]),
  market: ignoreActions(marketReducer, [
    'CONNECTING',
    'RECEIVE_MESSAGE',
    'RECEIVE_STATUS'
  ]),
  user: ignoreActions(userReducer, [
    'CONNECTING',
    'RECEIVE_MESSAGE',
    'RECEIVE_STATUS'
  ]),
  symbols: filterActions(symbolReducer, action => action.type.match(/SYMBOL/)),
  news: filterActions(newsReducer, action => action.type.match(/NEWS/)),
  system: filterActions(systemReducer, action => action.type.match(/SYSTEM/)),
  bars: filterActions(barsReducer, action => action.type.match(/BARS/)),
  hotlists: filterActions(hotlistReducer, action =>
    action.type.match(/HOTLIST/)
  ),
  scans: filterActions(scanReducer, action => action.type.match(/SCAN/)),
  memos: filterActions(memosReducer, action => action.type.match(/MEMO/)),
  featureFlags: filterActions(featureFlagsReducer, action => action.type.match(/FEATURE_FLAGS/)),
});

export default rootReducer;
