const initialState = {
  user: {
    id: '',
    globalMessages: [],
    localMessages: [],
    token: '',
    tokenIsValid: true,
    settings: {
      chart: {
        chartStyle: 'candlestick',
        displayTooltip: true,
        period: {
          type: 'month',
          count: 3
        },
        indicators: []
      }
    }
  },
  market: {
    upDownRatios: {
      isLoading: false,
      isError: false,
      data: [
        {
          symbol: 'JP:$0000-TS',
          ratio: 0,
          change: 0
        },
        {
          symbol: 'JP:$5001-TS',
          ratio: 0,
          change: 0
        },
        {
          symbol: 'JP:$5002-TS',
          ratio: 0,
          change: 0
        }
      ]
    },
    fundamentals: {}
  },
  marketboard: {
    isLoading: true,
    canAdd: false,
    cash: [],
    user: [],
    margin: [],
    isSaveForbidden: false,
    selectedView: 1,
    selectedTab: 0
  },
  memos: {},
  streams: {
    messages: [],
    channels: {},
    status: {
      connected: false,
      connecting: false,
      hasError: false
    }
  },
  symbols: {
    data: {},
    searches: {}
  },
  news: {
    articles: {},
    headlines: {},
    tse: { isLoading: true }
  },
  bars: {},
  hotlists: {},
  scans: {
    scanData: {},
    userScans: []
  },
  featureFlags: {}
};

export default initialState;
