import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import SymbolSummaryRow from './SymbolSummaryRow';

export default class SymbolSummary extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  render() {
    let symbolsData = this.props.categorySymbolData.symbols;
    let categoryNameKey = this.props.categorySymbolData.categoryNameKey;
    const sparklineHeader = (
      <div class="price-low market_summary_symbol-quote-card-header sparkline">
        <div class="market_summary_symbol__row">
          <span class="market_summary_symbol__quote">
            {this.localize('market.summary.quote.price.52variation')}
          </span>
        </div>
      </div>
    );

    return (
      <div>
        <div className={'market_summary_symbol--title_bar'}>
          <div class="container">
            <div class="symbol-name market_summary_symbol-quote-card-header">
              {this.localize(categoryNameKey)}
            </div>
            <div class="symbolFields">
              {sparklineHeader}
              <div class="price-low market_summary_symbol-quote-card-header">
                <div class="market_summary_symbol__row">
                  <span class="market_summary_symbol__quote">
                    {this.localize('market.summary.quote.price.low')}
                  </span>
                </div>
              </div>
              <div class="price-high market_summary_symbol-quote-card-header">
                <div class="market_summary_symbol__row">
                  <span class="market_summary_symbol__quote">
                    {this.localize('market.summary.quote.price.high')}
                  </span>
                </div>
              </div>
              <div class="price-open market_summary_symbol-quote-card-header">
                <div class="market_summary_symbol__row">
                  <span class="market_summary_symbol__quote">
                    {this.localize('market.summary.quote.price.open')}
                  </span>
                </div>
              </div>
              <div class="price-volume market_summary_symbol-quote-card-header">
                <div class="market_summary_symbol__row">
                  <span class="market_summary_symbol__quote">
                    {this.localize('market.summary.quote.price.volume')}
                  </span>
                </div>
              </div>
              <div class="price-prevclose market_summary_symbol-quote-card-header">
                <div class="market_summary_symbol__row">
                  <span class="market_summary_symbol__quote">
                    {this.localize('market.summary.quote.price.prevclose')}
                  </span>
                </div>
              </div>
              <div class="price-52low market_summary_symbol-quote-card-header">
                <div class="market_summary_symbol__row">
                  <span class="market_summary_symbol__quote">
                    {this.localize('market.summary.quote.price.52low')}
                  </span>
                </div>
              </div>
              <div class="price-52high market_summary_symbol-quote-card-header">
                <div class="market_summary_symbol__row">
                  <span class="market_summary_symbol__quote">
                    {this.localize('market.summary.quote.price.52high')}
                  </span>
                </div>
              </div>
            </div>
            <div class="price-last market_summary_symbol-quote-card-header">
              <div class="market_summary_symbol__row">
                <span class="market_summary_symbol__quote">
                  {this.localize('market.summary.quote.price.last')}
                </span>
              </div>
            </div>
          </div>
        </div>
        {symbolsData.map((symbolData) => {
          return (
            <SymbolSummaryRow
              symbolData={symbolData}
              key={symbolData.symbolCode}
            />
          );
        })}
      </div>
    );
  }
}

SymbolSummary = injectIntl(SymbolSummary);
