import React from 'react';
import { useIntl } from 'react-intl';

import { ExchangeSummary } from './ExchangeSummary';

export const MarketOverviewContainer = () => {
  // Intl
  const intl = useIntl();
  const localize = (i18nKey) => {
    return intl.formatMessage({
      id: i18nKey
    });
  };

  return (
    <div>
      <div className="section-header">{localize('market.overview')}</div>
      <div className="exchange-summary-list">
        <ExchangeSummary
          symbol="JP:$0000-TS"
          advance="JP:$ADVP-TS"
          decline="JP:$DECLP-TS"
          unchanged="JP:$UNCHP-TS"
          overrideName="scan.criteria.Exchange.TSE_P"
          linkedSymbol="JP:$0500-TS"
        />
        <ExchangeSummary
          symbol="JP:$5001-TS"
          advance="JP:$ADVS-TS"
          decline="JP:$DECLS-TS"
          unchanged="JP:$UNCHS-TS"
          overrideName="scan.criteria.Exchange.TSE_S"
          // JP:$5001-TS is a synthetic symbol created purely
          // for containing the market volume and turnover for
          // the TSE Standard exchange.  As there is no other
          // data (such as OHLC data) available for this symbol,
          // we instead link to the TSE Standard Top 20 Index.
          linkedSymbol="JP:$0501-TS"
        />
        <ExchangeSummary
          symbol="JP:$5002-TS"
          advance="JP:$ADVG-TS"
          decline="JP:$DECLG-TS"
          unchanged="JP:$UNCHG-TS"
          overrideName="scan.criteria.Exchange.TSE_G"
          // JP:$5002-TS is a synthetic symbol created purely
          // for containing the market volume and turnover for
          // the TSE Growth exchange.  As there is no other
          // data (such as OHLC data) available for this symbol,
          // we instead link to the TSE Growth Core Index.
          linkedSymbol="JP:$0502-TS"
        />
      </div>
    </div>
  );
};
