import types from '../actions/_actionTypes';
import initialState from './initialState';
import { getOrderedUserboards } from '../utils';
import _ from 'lodash';
import { getLastMarketboard, isPositionBoard, saveLastMarketboard } from '../utils/marketboards';

function checkBoardCount(marketboard) {
  return (
    marketboard && marketboard.length < process.env.REACT_APP_MAX_MARKETBOARDS
  );
}

// It was noticed that if we return '0' as the default marketboard when the id provided is not
// found, it will cause an issue with the positions boards. For that reason, it was noticed that it's
// better to return the `undefined` value (null or empty string are also ok). When the `selected`
// property of the state is `undefined` or doesn't contain a proper value, the current logic will pick
// the first marketboard of the user boards by default anyways. For that reason the default value
// of '0' was removed from this function.
const getLastMarketboardId = (marketboardId, boards) => {
  const resolvedMarketboard =
  _(boards)
    .filter((b) => b.Id === marketboardId)
    .first() || {};
  return resolvedMarketboard.Id;
};

const getLastMarketboardPositionId = (savedMarketboardId, cashBoards, marginBoards) => {
  let positionBoards = cashBoards.concat(marginBoards);
  return getLastMarketboardId(savedMarketboardId, positionBoards);
}

// If the current cookie contains a value for the last marketboard that doesn't exist in the current
// user or position marketboards, it would be a good idea to clear the value of the cookie. For that
// reason, we are setting the value of the cookie to an empty string. When the cookie contains an empty
// string, the current logic will pick the first marketboard of the user boards by default.
const clearLastMarketboardIfRequired = (marketboardId) => {
  if (!marketboardId) {
    saveLastMarketboard('');
  }
};

export default function marketboard(state = initialState.marketboard, action) {
  let marketboardIndex, mb, updated;
  switch (action.type) {
    case types.UPDATE_POSITION_BOARDS:
      let lastMarketboard = getLastMarketboard();
      let result = {
        cash: action.cashBoards || [],
        margin: action.marginBoards || []
      };

      if (isPositionBoard(lastMarketboard)) {
        result.selected = getLastMarketboardPositionId(
          lastMarketboard, result.cash, result.margin
        );
        clearLastMarketboardIfRequired(result.selected);
      }
      
      return Object.assign({}, state, result);
    case types.RECEIVE_MARKETBOARDS:
      let userBoards = getOrderedUserboards(action.marketboards);
      userBoards = userBoards.map((x) => {
        return Object.assign({}, x, {
          Symbols: _.orderBy(x.Symbols, ['Position'])
        });
      });

      let userBoardsResult = {
        isLoading: false,
        user: userBoards,
        total: action.total,
        marketboardLastUpdate: action.marketboardLastUpdate,
        canAdd: checkBoardCount(userBoards)
      }

      const savedMarketboardId = getLastMarketboard();
      if (!isPositionBoard(savedMarketboardId)) {
        userBoardsResult.selected = getLastMarketboardId(savedMarketboardId, userBoards);
        clearLastMarketboardIfRequired(userBoardsResult.selected);
      }
      
      return Object.assign({}, state, userBoardsResult);
    case types.FILTER_MARKETBOARDS:
      return Object.assign({}, state, {
        filter: action.filter
      });
    case types.SET_SELECTED:
      return Object.assign({}, state, {
        selected: action.selected
      });
    case types.SET_SELECTED_TAB:
      return Object.assign({}, state, {
        selectedTab: action.selectedTab
      });
    case types.SELECT_MARKETBOARD_VIEW:
      return Object.assign({}, state, {
        selectedView: action.selectedView
      });
    case types.ERROR_FETCHING_MARKETBOARD:
      return Object.assign({}, state, {
        isError: true,
        isSaveForbidden: true,
        error: action.error
      });
    case types.ERROR_POSTING_MARKETBOARD:
      return Object.assign({}, state, {
        isError: true,
        error: action.error
      });
    case types.TOGGLE_EDIT:
      return Object.assign({}, state, {
        edit: state.edit === false ? true : false
      });
    case types.DELETE_SYMBOL:
      marketboardIndex = _.findIndex(state.user, function (board) {
        return board.Id === action.marketboardId;
      });
      if (marketboardIndex === -1 || !state.user[marketboardIndex].Symbols)
        return state;

      mb = Object.assign({}, state.user[marketboardIndex]);

      if (!mb.Symbols) return state;
      mb.Symbols = mb.Symbols.filter(
        (e) => e.Position !== action.symbolPosition
      );
      updated = Object.assign({}, state, {
        user: [
          ...state.user.slice(0, marketboardIndex),
          mb,
          ...state.user.slice(marketboardIndex + 1)
        ]
      });
      return updated;
    case types.REPLACE_MARKETBOARD_SYMBOLS:
      marketboardIndex = _.findIndex(state.user, function (board) {
        return board.Id === action.marketboardId;
      });
      if (marketboardIndex === -1 || !state.user[marketboardIndex])
        return state;

      if (!action.symbols) return state;

      mb = Object.assign({}, state.user[marketboardIndex]);
      mb.Symbols = action.symbols;

      updated = Object.assign({}, state, {
        user: [
          ...state.user.slice(0, marketboardIndex),
          mb,
          ...state.user.slice(marketboardIndex + 1)
        ]
      });

      return updated;
    case types.UPDATE_MARKETBOARD_NAME:
      marketboardIndex = _.findIndex(state.user, function (board) {
        return board.Id === action.marketboardId;
      });
      if (marketboardIndex === -1 || !state.user[marketboardIndex])
        return state;

      if (!action.name) return state;
      mb = Object.assign({}, state.user[marketboardIndex]);
      mb.Name = action.name;

      updated = Object.assign({}, state, {
        user: [
          ...state.user.slice(0, marketboardIndex),
          mb,
          ...state.user.slice(marketboardIndex + 1)
        ]
      });
      return updated;
    case types.UPDATE_MARKETBOARD_SYMBOL:
      marketboardIndex = _.findIndex(state.user, function (board) {
        return board.Id === action.marketboardId;
      });
      if (marketboardIndex === -1 || !state.user[marketboardIndex])
        return state;

      mb = Object.assign({}, state.user[marketboardIndex]);
      if (!mb.Symbols) mb.Symbols = [];
      mb.Symbols = mb.Symbols.filter(
        (e) => e.Position !== action.symbolPosition
      );
      mb.Symbols.push({
        Symbol: action.symbol,
        Position: action.symbolPosition
      });
      mb.Symbols.sort((a, b) => {
        return a.Position < b.Position ? -1 : 1;
      });

      updated = Object.assign({}, state, {
        user: [
          ...state.user.slice(0, marketboardIndex),
          mb,
          ...state.user.slice(marketboardIndex + 1)
        ]
      });
      return updated;
    case types.MARKETBOARD_ADD:
      //require name and ID
      if (
        !action.marketboard ||
        !action.marketboard.Name ||
        !action.marketboard.Id
      ) {
        return state;
      }

      // can't have more than 100
      if (!state.canAdd) {
        return state;
      }

      updated = Object.assign({}, state, {
        user: state.user.concat(action.marketboard)
      });
      updated.canAdd = checkBoardCount(updated.user);
      return updated;
    case types.MARKETBOARD_DELETE:
      //do NOT delete the last marketboard
      if (state.user.length === 1) return state;

      marketboardIndex = _.findIndex(state.user, function (board) {
        return board.Id === action.marketboardId;
      });

      if (marketboardIndex === -1 || !state.user[marketboardIndex])
        return state;

      updated = Object.assign({}, state, {
        user: [
          ...state.user.slice(0, marketboardIndex),
          ...state.user.slice(marketboardIndex + 1)
        ]
      });
      updated.canAdd = checkBoardCount(updated.user);
      var defaultMarketboardId = _.findIndex(state.user, () => {
        return '0';
      });
      updated.selected = defaultMarketboardId;
      return updated;
    case types.MOVE_MARKETBOARD:
      const boards = state.user.slice(0);
      const removed = boards.splice(action.fromIndex, 1);
      boards.splice(action.toIndex, 0, removed[0]);
      return Object.assign({}, state, { user: boards });
    default:
      return state;
  }
}
