import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

import SymbolSearchResultItem from './SymbolSearchResultItem';
import EmptySearchResult from './EmptySearchResult';
import { useSymbolSearchFlags } from '../../utils/hooks/useSymbolSearchFlags';
import { getMenuPlacement } from '../../utils/menuPlacement';

const RowHeight = 33;
const HeaderHeight = 36;

const useStyles = makeStyles({
  searchResults: {
    border: '1px solid #b5bec4',
    background: '#fff',
    'box-shadow': '0 4px 12px rgba(0, 0, 0, 0.25) 1px 3px rgba(1, 1, 1, 0.5)',
    '-moz-box-shadow':
      '0 4px 12px rgba(0, 0, 0, 0.25) 1px 3px rgba(1, 1, 1, 0.5)',
    '-webkit-box-shadow':
      '0 4px 12px rgba(0, 0, 0, 0.25) 1px 3px rgba(1, 1, 1, 0.5)',
    '-webkit-box-shadow': '0 4px 12px rgb(0 0 0 / 25%)',
    '-moz-box-shadow': '0 4px 12px rgba(0, 0, 0, 0.25)',
    'box-shadow': '0 4px 12px rgb(0 0 0 / 25%)',
    'font-size': '14px',
    'min-width': '400px'
  },

  searchResultsTable: {
    'border-collapse': 'collapse',
    width: '100%'
  },

  searchResultsTitle: {
    '& td': {
      background: '#e6e7e9',
      background: '-moz-linear-gradient(top, #f5f7f8 0, #e6e7e9 100%)',
      background: '-webkit-linear-gradient(top, #f5f7f8 0, #e6e7e9 100%)',
      background: 'linear-gradient(top, #f5f7f8 0, #e6e7e9 100%)',
      'border-bottom': '1px solid #b5bec4',
      '-webkit-border-radius': '5px 5px 0 0',
      '-moz-border-radius': '5px 5px 0 0',
      'border-radius': '5px 5px 0 0',
      color: '#9fa6af',
      padding: '7px',
      'white-space': 'pre'
    }
  },

  searchResultsScopes: {
    float: 'right',
    'margin-right': '4px',
    'font-size': '12px'
  }
});

const getHeaderText = (
  intl,
  includeJpeq,
  includeUseq,
  includeSupplementals,
  includeIndustry
) => {
  const localize = (id) => intl.formatMessage({ id: id });

  var searchCriteria = [];
  // If/when adding additional scopes in the future,
  // they should be added here in the order which
  // they should be displayed.
  if (includeJpeq) {
    searchCriteria.push(localize('symbol.search.criteria.jpeq'));
  }
  if (includeUseq) {
    searchCriteria.push(localize('symbol.search.criteria.useq'));
  }
  if (includeSupplementals) {
    searchCriteria.push(localize('symbol.search.criteria.others'));
  }
  if (includeIndustry) {
    searchCriteria.push(localize('symbol.search.criteria.industry'));
  }

  if (searchCriteria.length > 0) {
    return searchCriteria.join('／');
  } else {
    // If there are no selected scopes, return
    // a message saying as much
    return localize('message.none.selected');
  }
};

export const GetMenuPlacement = ({ element, resultItemCount, offsetTop }) => {
  // required height of the result items when rendered
  const totalRequiredHeight =
    resultItemCount * RowHeight + HeaderHeight + (offsetTop ?? 0);

  return getMenuPlacement(element, totalRequiredHeight);
};

const SymbolSearchResults = ({
  intl,
  selectedIndex,
  results,
  symbolsMetadata,
  onResultItemClick,
  showActionButtons
}) => {
  const [includeJpeq, includeUseq, includeSupplementals, includeIndustry] =
    useSymbolSearchFlags();

  results = results || [];
  let items = (
    <EmptySearchResult
      includeJpeq={includeJpeq}
      includeUseq={includeUseq}
      includeSupplementals={includeSupplementals}
    />
  );
  if (results.length > 0) {
    items = results.map((symbol, index) => (
      <SymbolSearchResultItem
        key={symbol}
        symbolName={symbol}
        metadata={(symbolsMetadata[symbol] || { data: {} }).data}
        selected={index === selectedIndex}
        onClick={onResultItemClick}
        showActionButtons={showActionButtons}
      />
    ));
  }

  const classes = useStyles();

  return (
    <div className={classes.searchResults}>
      <table className={classes.searchResultsTable}>
        <thead>
          <tr className={classes.searchResultsTitle}>
            <td colSpan="1">
              <FormattedMessage id="scan.result.title" />
            </td>
            <td colSpan="3">
              <div className={classes.searchResultsScopes}>
                {getHeaderText(
                  intl,
                  includeJpeq,
                  includeUseq,
                  includeSupplementals,
                  includeIndustry
                )}
              </div>
            </td>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </table>
    </div>
  );
};

export default injectIntl(SymbolSearchResults);
