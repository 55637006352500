import { MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export const BarSizes = {
  '5min': { size: '5min', type: 'min', count: 5, label: '5min' },
  '1hour': { size: '1hour', type: 'hour', count: 1, label: '1hour' },
  '1day': { size: '1day', type: 'day', count: 1, label: '1day' },
  '1week': { size: '1week', type: 'week', count: 1, label: '1week' },
  '1month': { size: '1month', type: 'month', count: 1, label: '1month' },
  '1quarter': { size: '1quarter', type: 'quarter', count: 1, label: '1quarter' }
};

export const BarSizeSelector = ({ barSize, onSelectionChanged }) => {
  // State
  const [selectedBarSize, setSelectedBarSize] = useState(barSize);

  // Effects
  useEffect(() => {
    setSelectedBarSize(barSize);
  }, [barSize]);

  // Intl
  const intl = useIntl();
  const localize = (id, otherParams) =>
    intl.formatMessage({ id: id }, otherParams);

  const handleSelectionChanged = (event) => {
    if (onSelectionChanged) {
      onSelectionChanged(event.target.value);
    }
  };

  return (
    <Select
      labelId="settings-chart-style-select"
      id="settings-chart-style-select"
      value={selectedBarSize}
      onChange={handleSelectionChanged}
      className="symbol-historial-period-select"
      variant="standard"
    >
      {Object.keys(BarSizes).map((size) => {
        return (
          <MenuItem value={size} key={size}>
            {localize(`symbol.details.chart.buttons.${BarSizes[size].label}`)}
          </MenuItem>
        );
      })}
    </Select>
  );
};
