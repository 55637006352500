import types from './_actionTypes';
import fetchRetry, { fetchRetryWithAuth } from '../utils/fetchRetry';
import * as time from '../utils/time';
import { isJapaneseEquity } from '../utils/symbol';
import { handleErrors } from '../utils/common';

const API_URL = process.env.REACT_APP_INSIGHT_API_DOMAIN;
const SYPHON_BASE_URL = `https://${API_URL}/market`;

function getUpDownRatiosUrl() {
  // prettier-ignore
  return `${SYPHON_BASE_URL}/upDownRatios`;
}

function getFundamentalsUrl(symbolId) {
  // prettier-ignore

  return `${SYPHON_BASE_URL}/fundamentals/${symbolId}`;
}

function requestUpDownRatios() {
  return {
    type: types.REQUESTING_UP_DOWN_RATIOS
  };
}

function receiveUpDownRatios(response) {
  return {
    type: types.RECEIVE_UP_DOWN_RATIOS,
    data: response
  };
}

export function errorRequestingUpDownRatios(error) {
  return {
    type: types.ERROR_REQUESTING_UP_DOWN_RATIOS,
    error: error,
    errorCode: 'E0019'
  };
}

function requestFundamentals(symbol) {
  return {
    type: types.REQUESTING_FUNDAMENTALS,
    symbol: symbol
  };
}

function receiveFundamentals(symbol, response) {
  return {
    type: types.RECEIVE_FUNDAMENTALS,
    symbol: symbol,
    data: response
  };
}

export function errorRequestingFundamentals(symbol, error) {
  return {
    type: types.ERROR_REQUESTING_FUNDAMENTALS,
    symbol: symbol,
    error: error,
    errorCode: 'E0021'
  };
}

function shouldFetchFundamental(state, symbol) {
  // only request for Japanese Equity Symbols
  if (!isJapaneseEquity(symbol)) {
    return false;
  }

  const search = state.market.fundamentals[symbol];
  if (!search) {
    return true;
  }

  if (search.isError) {
    return true;
  }

  if (search.isLoading) {
    return false;
  }

  if (search.when && search.when < time.timestamp() - 300) {
    return true;
  }

  return search.didInvalidate || false;
}

export function fetchFundamentals(pattern, top) {
  return (dispatch, getState) => {
    if (shouldFetchFundamental(getState(), pattern)) {
      dispatch(requestFundamentals(pattern));
      return dispatch(_fetchFundamentals(pattern));
    }
  };
}

export function fetchUpDownRatios() {
  return (dispatch, getState) => {
    let user = getState().user;
    if (!user.id || user.tokenIsValid !== true || !user.token) {
      return false;
    }

    let req = {
      method: 'get',
      headers: {
        authorization: user.token
      }
    };

    return fetchRetry(getUpDownRatiosUrl(), req)
      .then(dispatch(requestUpDownRatios()))
      .then(handleErrors)
      .then((response) => response.json())
      .then((response) => dispatch(receiveUpDownRatios(response)))
      .catch((ex) => {
        dispatch(errorRequestingUpDownRatios(ex));
      });
  };
}

function _fetchFundamentals(symbol) {
  let req = {
    method: 'get'
  };

  return fetchRetryWithAuth(
    req,
    () => {
      return getFundamentalsUrl(symbol);
    },
    (response) => {
      return receiveFundamentals(symbol, response);
    },
    (error) => {
      return errorRequestingFundamentals(symbol, error);
    }
  );
}
