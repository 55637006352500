import types from '../actions/_actionTypes';
import initialState from './initialState';
import Logger from './../utils/logger';

function getMemo(memo) {
  let memoText = memo.memo;
  if (
    !memoText ||
    typeof memoText !== 'string' ||
    memoText.trim().length === 0
  ) {
    memo.memo = null;
    return memo;
  }

  try {
    memo.memo = decodeURIComponent(memoText.trim());
    return memo;
  } catch (e) {
    Logger.error(
      `E0024: Unable to parse memo '${memoText}' for symbol ${memo.symbol}: ${e.message}`
    );
    memo.memo = null;
    memo.error = true;
    return memo;
  }
}

const memo = (state = initialState.memos, action) => {
  var newState;

  switch (action.type) {
    case types.REQUESTING_USER_MEMOS:
      newState = Object.assign({}, state);
      action.symbols.forEach((symbol) => {
        newState[symbol] = {
          isLoading: true
        };
      });
      return newState;
    case types.RECEIVE_USER_MEMOS:
      newState = Object.assign({}, state);
      action.data.forEach((memo) => {
        newState[memo.symbol] = getMemo(memo);
      });
      return newState;
    case types.SAVE_USER_MEMO:
      newState = Object.assign({}, state);
      newState[action.symbolId] = getMemo(action.data);
      return newState;
    case types.DELETE_USER_MEMO:
      newState = Object.assign({}, state);
      delete newState[action.symbolId];
      return newState;
    case types.ERROR_REQUESTING_USER_MEMOS:
      newState = Object.assign({}, state);
      return newState;
    case types.ERROR_SAVING_USER_MEMO:
      newState = Object.assign({}, state);
      return newState;
    case types.ERROR_DELETING_USER_MEMO:
      newState = Object.assign({}, state);
      return newState;
    default:
      return state;
  }
};

export default memo;
