function merge(bars, volumeBars) {
  return bars.map(bar => {
    let volBar = volumeBars.find(vbar => vbar[0] === bar[0]);
    bar.push(volBar ? volBar[1] || 0 : 0);
    return bar;
  });
}

const barMap = {
  timestamp: 0,
  open: 1,
  high: 2,
  low: 3,
  close: 4,
  display: 5,
  volume: 6
};

function getHighestClosePrice(bars) {
  return bars
    .map(bar => {
      return bar[barMap.close];
    })
    .reduce(function(prev, current) {
      return prev > current ? prev : current;
    });
}

function getLowestClosePrice(bars) {
  return bars
    .map(bar => {
      return bar[barMap.close];
    })
    .reduce(function(prev, current) {
      return prev < current ? prev : current;
    });
}

function getVolumeByPriceData(intervalCount, bars, volumeBars) {
  if (!bars || !Array.isArray(bars) || bars.length === 0) {
    return null;
  }

  var highestClosePrice = getHighestClosePrice(bars);

  var lowestClosePrice = getLowestClosePrice(bars);

  if (highestClosePrice === lowestClosePrice) {
    intervalCount = 1;
    highestClosePrice += 1;
    lowestClosePrice -= 1;
  }

  let priceVolumeMap = merge(bars, volumeBars);

  let closePriceRange = highestClosePrice - lowestClosePrice;

  let vbp = [];
  for (var i = 0; i < intervalCount; i++) {
    let lowerBoundofRange =
      lowestClosePrice + (i / intervalCount) * closePriceRange;
    let upperBoundofRange =
      lowestClosePrice + ((i + 1) / intervalCount) * closePriceRange;

    let totalVolume = priceVolumeMap
      .filter(bar => bar[barMap.close] >= lowerBoundofRange)
      .filter(
        bar => i === intervalCount - 1 || bar[barMap.close] < upperBoundofRange
      )
      .map(bar => bar[barMap.volume])
      .reduce((a, b) => a + b, 0);
    vbp.push([lowerBoundofRange, upperBoundofRange, totalVolume]);
  }

  return vbp;
}

// this will give us the number of total bars with volume and
// with spaces. Where there are multiple spacers, we will only
// count a single space.
function calculateMin(volbars) {
  let minDivs = 1;

  // Discard any contiguous bars with no volume
  for (let i = 1; i < volbars.length; i++) {
    if (volbars[i][2] > 0 || (volbars[i][2] === 0 && volbars[i - 1][2] !== 0))
      minDivs++;
  }
  return minDivs;
}

function getSmaSeries(periods, bars, fullBarData, localize, fullVolume) {
  // don't render until the volume and bars are present
  if (!fullVolume || !fullVolume.length > 0 || !bars || !bars.length > 0) {
    return;
  }

  // In highcharts, when the number of values is low, and the number of
  // periods is high, there is an issue where it shows the wrong number
  // of vol-by-price bars. Manually lowering it to a number closer to
  // what Sometaro shows fixes the issue. So, in order to find that
  // number, we do the following calculation taken from Sometaro and
  // figure out how many bars with volume that Sometaro will show. Any
  // contiguous bars with no volume will be discarded.
  let volbars = getVolumeByPriceData(periods[0], bars, fullVolume);

  let minDivs = calculateMin(volbars);

  let divs = Math.min(periods[0], minDivs);

  return [
    {
      id: 'vbp',
      type: 'vbp',
      linkedTo: 'bars',
      color: '#DBE8E0',
      pointPadding: 2,
      enableMouseTracking: false,
      volumeDivision: {
        enabled: false
      },
      params: {
        volumeSeriesID: 'volume',
        ranges: divs
      },
      dataLabels: {
        enabled: false
      },
      zoneLines: {
        enabled: false
      }
    }
  ];
}

module.exports = {
  getSeries: getSmaSeries,
  getVolumeByPriceData: getVolumeByPriceData,
  getLowestClosePrice: getLowestClosePrice,
  getHighestClosePrice: getHighestClosePrice,
  merge: merge,
  calculateMin: calculateMin
};
