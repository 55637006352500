import React from 'react';
import { injectIntl } from 'react-intl';
import SymbolHistoricalListItem from './SymbolHistoricalListItem';
import SymbolHistoricalListHeader from './SymbolHistoricalListHeader';
import Loading from '../../common/Loading';
import Error from '../../common/Error';
import NoData from '../../common/NoData';
import * as barHelpers from '../../../utils/bars';

const BARS_BY_SIZE = {
  '5min': 132,
  '1hour': 73,
  '1day': 73,
  '1week': 73,
  '1month': 73
};

class SymbolHistoricalListContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.localize = this.localize.bind(this);
  }

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  render() {
    if (this.props.noData) {
      return <NoData />;
    } else if (!this.props.bars || this.props.isLoading) {
      return <Loading />;
    } else if (this.props.isError) {
      return <Error />;
    }

    let bars = this.props.bars.slice(0, BARS_BY_SIZE[this.props.barSize]);

    // Add the bar-by-bar change to each bar in advance
    bars = barHelpers.calculateChange(bars);

    // Calculate the highest and lowest values for each
    // bar parameter so we can highlight them in the list
    let lows = barHelpers.calculateLows(bars);
    let highs = barHelpers.calculateHighs(bars);

    // Bars should only display volume in the list if they are an equity symbol,
    // or the volume for the entire previous trade day is greater than zero
    let shouldDisplayVolume =
      this.props.isSymbolEquity || this.props.previousVolume > 0;

    return (
      <div className={'symbol-historical-data'}>
        <SymbolHistoricalListHeader
          localize={this.localize}
          barSize={this.props.barSize}
          displayVolume={shouldDisplayVolume}
        />
        {bars.map((bar, i, arr) => (
          <SymbolHistoricalListItem
            key={i}
            bar={bar}
            index={i}
            barSize={this.props.barSize}
            lows={lows}
            highs={highs}
            precision={this.props.precision}
            isSymbolEquity={this.props.isSymbolEquity}
            previousVolume={this.props.previousVolume}
            displayVolume={shouldDisplayVolume}
          />
        ))}
      </div>
    );
  }
}
SymbolHistoricalListContainer = injectIntl(SymbolHistoricalListContainer);

export default SymbolHistoricalListContainer;
