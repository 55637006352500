import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash';
import { setChartPeriod as setChartPeriodAction } from '../../actions/user';
import {
  getBarSizeForChartPeriod,
  reduceBarsToTimePeriod,
  mapSyphonBarsToHighchartBars,
  hasLoadedWithoutBarsData,
  getBarInfo,
  getSymbolFullBarsData
} from '../../utils/chart';
import { getAdvDeclineRatioSymbols } from '../../utils/indicators/advDeclineRatios';
import { fetchAllBarsIfNeeded as fetchAllBarsIfNeededAction } from '../../actions/bars';
import { fetchSymbolIfNeeded as fetchSymbolIfNeededAction } from '../../actions/symbol';
import SymbolChart from './SymbolChart';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import NoData from '../common/NoData';
import SymbolChartRealTime from '../symbolChartRealTime/SymbolChartRealTime';
import {
  BarSizes,
  BarSizeSelector
} from '../symbolChartRealTime/BarSizeSelector';
import useActions from '../../utils/hooks/useActions';
import { useSelector } from 'react-redux';
import useLocalize from '../../utils/hooks/useLocalize';
import { useFeatureToggle } from '../../utils/hooks/useFeatureToggle';
import MemoContainer from './MemoContainer';
import { barsSelector } from '../../selectors/barsSelector';
import { chartSettingsSelector } from '../../selectors/userSelectors';

const periodData = [
  { type: 'day', count: 1, label: '1day' },
  { type: 'day', count: 2, label: '2day' },
  { type: 'day', count: 5, label: '5day' },
  { type: 'month', count: 1, label: '1month' },
  { type: 'month', count: 3, label: '3month' },
  { type: 'month', count: 6, label: '6month' },
  { type: 'year', count: 1, label: '1year' },
  { type: 'year', count: 5, label: '5year' },
  { type: 'year', count: 10, label: '10year' },
  { type: 'year', count: 20, label: '20year' },
  { type: 'year', count: 30, label: '30year' }
];

const SymbolChartContainer = ({ symbol }) => {
  const localize = useLocalize();

  const bars = useSelector(barsSelector);
  const realTimeChartEnabled = useFeatureToggle('realTimeChartEnabled');

  const setChartPeriod = useActions(setChartPeriodAction, []);
  const fetchAllBarsIfNeeded = useActions(fetchAllBarsIfNeededAction, []);
  const fetchSymbolIfNeeded = useActions(fetchSymbolIfNeededAction, []);

  const settings = useSelector(chartSettingsSelector);

  useEffect(() => {
    // Initialize the chart
    chartPeriodChange(
      null,
      JSON.stringify({
        type: interval || 'month',
        count: count || 3,
        barSize: barSize || '1day'
      })
    );
  }, [barSize, chartPeriodChange, count, interval]);

  const [chartLoading, setChartLoading] = useState(false);
  const [barSize, setBarSize] = useState(settings.period.size);
  const [timePeriod, setTimePeriod] = useState(BarSizes[barSize]);

  const displayTooltip = settings.displayTooltip;
  const interval = settings.period.type;
  const count = settings.period.count;
  const enabledIndicators = JSON.parse(JSON.stringify(settings.indicators));
  const chartMode = settings.chartMode;

  const symbolsForComparison = Object.keys(settings.comparisonSymbols ?? {});
  const comparisonSymbols = useMemo(
    () => symbolsForComparison ?? [],
    [symbolsForComparison]
  );

  const hasAdvancedDeclineRatiosIndicator = useMemo(
    () => enabledIndicators.some((i) => i.type === 'advDeclineRatios'),
    [enabledIndicators]
  );

  useEffect(() => {
    // Fetch metadata and bars for comparison symbols
    comparisonSymbols.forEach((symbol) => {
      fetchAllDataForSymbol(symbol);
    });

    // Fetch metadata and bars for for adv/dec ratio symbols
    hasAdvancedDeclineRatiosIndicator &&
      getAdvDeclineRatioSymbols().forEach((symbol) => {
        fetchAllDataForSymbol(symbol);
      });
  }, [
    comparisonSymbols,
    hasAdvancedDeclineRatiosIndicator,
    fetchAllDataForSymbol
  ]);

  // map of symbols and their full bars
  const symbolsWithFullBars = useMemo(() => {
    // update this map every time bars are updated
    const symbols = {
      [symbol]: getSymbolFullBarsData(bars, barSize, symbol)
    };
    comparisonSymbols.forEach((s) => {
      symbols[s] = getSymbolFullBarsData(bars, barSize, s);
    });

    hasAdvancedDeclineRatiosIndicator &&
      getAdvDeclineRatioSymbols().forEach((s) => {
        symbols[s] = getSymbolFullBarsData(bars, barSize, s);
      });

    return symbols;
  }, [
    bars,
    barSize,
    comparisonSymbols,
    symbol,
    hasAdvancedDeclineRatiosIndicator
  ]);

  const isComparisonMode = useMemo(
    () => chartMode === 'comparison',
    [chartMode]
  );

  const fetchAllDataForSymbol = useCallback(
    (symbol) => {
      // bars
      fetchAllBarsIfNeeded(symbol);
      // metadata
      fetchSymbolIfNeeded(symbol);
    },
    [fetchAllBarsIfNeeded, fetchSymbolIfNeeded]
  );

  useEffect(() => {
    fetchAllDataForSymbol(symbol);
  }, [symbol, fetchAllDataForSymbol]);

  useEffect(() => {
    if (!isComparisonMode) {
      return;
    }

    // reset symbols with full bars
    Object.keys(symbolsWithFullBars).forEach((s) => {
      if (s === symbol) {
        return;
      }

      delete symbolsWithFullBars[s];
    });

    // fetch data (metadata, bars) for current symbol
    // and comparison symbols
    const symbols = comparisonSymbols.concat([symbol]);
    symbols.forEach((symbol) => {
      fetchAllDataForSymbol(symbol);
    });
  }, [
    symbol,
    symbolsWithFullBars,
    comparisonSymbols,
    fetchAllDataForSymbol,
    isComparisonMode
  ]);

  const chartPeriodChange = useCallback(
    (event, initialData) => {
      let data = !event || !event.target ? initialData : event.target.value;
      // The data is passed in as a string, because it is taken from a tab value
      data = JSON.parse(data);

      // adjust chart period/bar size data
      data = adjustChartPeriod(data);
      data.size = getBarSizeForChartPeriod(
        data.type,
        data.count,
        realTimeChartEnabled
      );

      setChartLoading(true);

      setBarSize(data.size);
      setTimePeriod(data);
      setChartPeriod(data);

      return true;
    },
    [adjustChartPeriod, realTimeChartEnabled, setChartPeriod]
  );

  const adjustChartPeriod = useCallback(
    (data) => {
      // This is to adjust chart period based on chart time (realtime or non-realtime)
      // For non-realtime, type/count are Period (e.g. 3months)
      // For realtime, type/count are basically bar sizes (e.g. 1 month, 1 day, 5 mins)

      if (!realTimeChartEnabled) {
        // if for some reason, the period data passed in the argument
        // does not match the non-realtime format,
        // we reset to 1month period
        const period = periodData.find(
          (x) => x.type === data.type && x.count === data.count
        );
        if (!period) {
          data.type = 'month';
          data.count = 1;
        }
        return data;
      }

      // if for some reason, the data passed in the argument
      // does not match the realtime bar size format,
      // we reset to 1 day bar size
      if (!BarSizes[`${data.count}${data.type}`]) {
        data.type = 'day';
        data.count = 1;
      }
      return data;
    },
    [realTimeChartEnabled]
  );

  // Renders Chart Period Selector (for non-realtime chart)
  const periodSelector = useMemo(() => {
    return (
      <Select
        labelId="settings-chart-style-select"
        id="settings-chart-style-select"
        value={JSON.stringify({
          type: interval || 'month', // Default to month
          count: count || 3 // Default to 3
        })}
        onChange={chartPeriodChange}
        className="symbol-historial-period-select"
        variant="standard"
      >
        {periodData.map((period) => {
          const periodType = period.type;
          const periodCount = period.count;
          const periodLabel = period.label;
          return (
            <MenuItem
              value={JSON.stringify({
                type: periodType,
                count: periodCount
              })}
              key={periodLabel}
            >
              {localize(`symbol.details.chart.buttons.${periodLabel}`)}
            </MenuItem>
          );
        })}
      </Select>
    );
  }, [chartPeriodChange, count, interval, localize]);

  // Renders Bar Size Selector (for realtime chart)
  const barSizeSelector = useMemo(() => {
    // Try with user's latest setting.
    // Then try with what's saved in store
    // Otherwise, default to 1day
    const size = barSize || '1day';

    return (
      <BarSizeSelector
        onSelectionChanged={onBarSizeSelectionChanged}
        barSize={size}
      />
    );
  }, [barSize, onBarSizeSelectionChanged]);

  const onBarSizeSelectionChanged = useCallback(
    (barSize) => {
      setChartLoading(true);
      setBarSize(barSize);
      setTimePeriod(BarSizes[barSize]);
      setChartPeriod(BarSizes[barSize]);
    },
    [setChartPeriod]
  );

  const barInfo = getBarInfo(bars, symbol, barSize);
  const dailyBars = getBarInfo(bars, symbol, '1day');

  let fullHighchartsData = {};
  let reducedHighchartsData = {};

  let loadingComplete = barInfo && dailyBars;

  if (isComparisonMode) {
    // if we have comparison symbols,
    // check if bars for those symbols are still being loaded
    const symbols = comparisonSymbols;
    const barsForBarSizeAvailable = symbols.every((symbol) =>
      getBarInfo(bars, symbol, barSize)
    );
    const barsFor1DayAvailable = symbols.every((symbol) =>
      getBarInfo(bars, symbol, '1day')
    );
    loadingComplete =
      loadingComplete && barsForBarSizeAvailable && barsFor1DayAvailable;
  }

  if (loadingComplete && !realTimeChartEnabled) {
    // Execute the following only if we are not showing
    // realtime chart

    fullHighchartsData = mapSyphonBarsToHighchartBars(barInfo.bars, barSize);
    reducedHighchartsData = reduceBarsToTimePeriod(
      _.cloneDeep(fullHighchartsData),
      timePeriod,
      dailyBars
    );
  }

  useEffect(() => {
    if (loadingComplete && !realTimeChartEnabled && chartLoading) {
      setChartLoading(false);
    }
  }, [loadingComplete, realTimeChartEnabled, chartLoading]);

  const chartStyle = isComparisonMode ? 'line' : settings.chartStyle;
  const indicators = useMemo(
    () =>
      isComparisonMode
        ? [
            {
              type: 'comparisonSymbols'
            }
          ]
        : enabledIndicators,
    [isComparisonMode, enabledIndicators]
  );

  const symbolChart = useMemo(
    () =>
      !realTimeChartEnabled ? (
        <SymbolChart
          name={symbol}
          bars={reducedHighchartsData.bars}
          volume={reducedHighchartsData.volume}
          fullBars={fullHighchartsData.bars}
          fullVolume={fullHighchartsData.volume}
          additionalSymbolsData={symbolsWithFullBars}
          chartStyle={chartStyle}
          displayTooltip={displayTooltip}
          barSize={barSize}
          indicators={indicators}
          isLoading={chartLoading}
        />
      ) : (
        <SymbolChartRealTime
          symbol={symbol}
          barSize={barSize}
          indicators={indicators}
          additionalSymbolsData={symbolsWithFullBars}
        />
      ),
    [
      reducedHighchartsData.volume,
      reducedHighchartsData.bars,
      fullHighchartsData.volume,
      fullHighchartsData.bars,
      symbolsWithFullBars,
      symbol,
      barSize,
      chartStyle,
      displayTooltip,
      indicators,
      realTimeChartEnabled,
      chartLoading
    ]
  );

  let symbolChartToDisplay = hasLoadedWithoutBarsData(bars, symbol, barSize) ? (
    <NoData />
  ) : (
    <div>{symbolChart}</div>
  );

  const symbolMemo = <MemoContainer symbol={symbol} />;

  return (
    <div className={'symbol-details-chart-container'}>
      <div className={'symbol-details-chart-inner-container'}>
        <div
          className={
            'symbol-historial-selectors' +
            (realTimeChartEnabled ? ' realtime' : '')
          }
        >
          <span>{localize('symbol.details.historical.time.select')}</span>
          <span className="symbol-historial-period-span">
            {realTimeChartEnabled ? barSizeSelector : periodSelector}
          </span>
        </div>

        {symbolChartToDisplay}
        {symbolMemo}
      </div>
    </div>
  );
};
export default SymbolChartContainer;
