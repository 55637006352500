import React from 'react';
import RaisedButton from '@mui/material/Button';

export class Button extends React.Component {
  render() {
    const { ...otherProps } = this.props;

    return <RaisedButton variant="contained" {...otherProps} />;
  }
}

export default Button;
