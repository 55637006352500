import Logger from './logger';

const defaultTimeoutMilliseconds = 3000;
const defaultRetries = 3;
const defaultDelay = 500;

async function promiseTimeout(promise, timeout) {
  let tx = setTimeout(() => {
    return 'error';
  }, timeout);

  let result = await Promise.race([
    promise,
    new Promise(function (resolve) {
      setTimeout(resolve.bind(null, 'timeout'), timeout);
    }),
  ]);

  clearTimeout(tx);
  if (result !== 'timeout') {
    return result;
  }

  const msg = 'E0026: Time out occurred while waiting for promise to complete';
  Logger.error(msg);
  throw new Error(msg);
}

export function promiseRetry(
  fn,
  delay = defaultDelay,
  retries = defaultRetries,
  timeout = defaultTimeoutMilliseconds
) {
  const maxRetries = retries;

  return new Promise((resolve, reject) => {
    function success(response) {
      resolve(response);
    }

    function failure(error) {
      retries--;
      if (retries) {
        setTimeout(executeWithTimeout, delay);
      } else {
        Logger.error(
          `E0027: Promise has failed after retrying for ${maxRetries} times`
        );
        reject(error);
      }
    }

    function executeWithTimeout() {
      return promiseTimeout(fn(), timeout).then(success).catch(failure);
    }
    executeWithTimeout();
  });
}
