import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { scanCategoryData, scanCategoryMapping } from './ScannerCategory';
import ListItem from '@mui/material/ListItem';
import ScanCriteriaSelector from './ScanCriteriaSelector';
import {
  fetchScan,
  saveLastScan,
  saveScan,
  overwriteScan
} from '../../actions/scan';
import {
  filterSelects,
  filterRange,
  getCriteriaFromScan,
  buildScan
} from '../../utils/scan';
import MyScansButton from './MyScansButton';
import NewScansAddCriteriaButton from './NewScansAddCriteriaButton';
import NewScansSaveCriteriaContainer from './NewScansSaveCriteriaContainer';
import NewScansResultsContainer from './NewScansResultsContainer';
import MessageBar from '../common/MessageBar';
import MinifiedCriteria from './MinifiedCriteria';
import EditableScanTitle from './EditableScanTitle';
import CheckIcon from '@mui/icons-material/Check';
import SettingsDialog from '../common/SettingsDialog';
import { withDesktopWidth } from '../../utils/hocs/withDesktopWidth';

const _ = require('lodash');
const MAX_CRITERIA_QUANTITY = 5;
const typeMap = {
  0: 'range',
  2: 'select',
  3: 'multiselect'
};
const LAST_SCAN_DEBOUNCE_TIME = 5000;
const LAST_SCAN_ID = 'last-scan';

class ScansLandingContainer extends Component {
  constructor(props) {
    super(props);

    let [criteria, selectedOptions] = getCriteriaFromScan(props.scan);
    this.state = {
      open: false,
      criteria: criteria,
      dialogOpenCategory: null,
      selectedOptions: selectedOptions,
      editEnabled: !this.props.scanId,
      displaySaved: false,
      displayWarnSaving: false,
      overwrittenCriteria: null,
      overwrittenOptions: null,
      scanName:
        props.scan && props.scan.id !== 'last-scan'
          ? props.scan.name
          : this.localize('scan.my.scan.default.name')
    };
    this.setOpen = this.setOpen.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.addSubcategory = this.addSubcategory.bind(this);
    this.removeSubcategory = this.removeSubcategory.bind(this);
    this.toggleMultiselectCriteria = this.toggleMultiselectCriteria.bind(this);
    this.toggleSelectCriteria = this.toggleSelectCriteria.bind(this);
    this.rangeCriteriaHandler = this.rangeCriteriaHandler.bind(this);
    this.debouncedSaveLastScan = _.debounce(
      this.saveLastScan,
      LAST_SCAN_DEBOUNCE_TIME
    );
    this.timer = null;
    this.saveNewCriteria = this.saveNewCriteria.bind(this);
    this.overwriteCriteria = this.overwriteCriteria.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.updateScanName = this.updateScanName.bind(this);
    this.getSanifiedScanName = this.getSanifiedScanName.bind(this);
  }

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  setOpen(openness) {
    this.setState({ open: openness });
  }

  handleClickOpen() {
    this.setOpen(true);
  }

  handleClose() {
    this.toggleOpenCategory(null);
    this.setOpen(false);
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      displaySaved: false,
      displayWarnSaving: false
    });
  };

  addSubcategory(criteria) {
    if (
      this.state.criteria.indexOf(criteria) > -1 ||
      this.state.criteria.length > MAX_CRITERIA_QUANTITY
    ) {
      // do something if we want to someday
    } else {
      let newCriteria = this.state.criteria;
      newCriteria.push(criteria);
      this.setState({
        criteria: newCriteria
      });
    }
    this.props.actions.getScan(criteria);

    // Close the open category in the dialog
    this.toggleOpenCategory(null);
    // Close the dialog
    this.handleClose();
  }

  removeSubcategory(criteria) {
    let newCriteria = this.state.criteria;
    let selectedOptions = Object.assign({}, this.state.selectedOptions);

    newCriteria = newCriteria.filter(c => c !== criteria);

    delete selectedOptions[criteria];

    this.setState({
      criteria: newCriteria,
      selectedOptions: selectedOptions
    });
  }

  toggleMultiselectCriteria(option, criteria) {
    let selectedOptions = Object.assign({}, this.state.selectedOptions);
    if (!selectedOptions[option]) {
      selectedOptions[option] = [];
    }

    if (selectedOptions[option].indexOf(criteria) > -1) {
      selectedOptions[option] = selectedOptions[option].filter(
        o => o !== criteria
      );
    } else {
      // using concat rather than push because
      // if we just push, React won't re-render
      // because it sees the array as the same,
      // whereas concat will create a new object
      // and trigger a re-render
      selectedOptions[option] = selectedOptions[option].concat(criteria);
    }

    this.setState({
      selectedOptions: selectedOptions
    });
  }

  toggleSelectCriteria(option, criteria) {
    let selectedOptions = Object.assign({}, this.state.selectedOptions);
    if (!selectedOptions[option]) {
      selectedOptions[option] = '';
    }

    if (selectedOptions[option] !== criteria) {
      selectedOptions[option] = criteria;
    } else {
      selectedOptions[option] = null;
    }

    this.setState({
      selectedOptions: selectedOptions
    });
  }

  rangeCriteriaHandler(option, field, value) {
    let selectedOptions = Object.assign({}, this.state.selectedOptions);
    if (!selectedOptions[option]) {
      selectedOptions[option] = {};
    }

    selectedOptions[option][field] = value;

    this.setState({
      selectedOptions: selectedOptions
    });
  }

  gatValuesOrDefault(selectedOptions, type) {
    switch (type) {
      case 'range':
        return [
          selectedOptions ? Number(selectedOptions.lower) || null : null,
          selectedOptions ? Number(selectedOptions.upper) || null : null
        ];
      case 'select':
        return selectedOptions ? [selectedOptions] : [];
      default:
        return selectedOptions ? selectedOptions : [];
    }
  }

  componentDidMount() {
    this.state.criteria.forEach(criterion => {
      this.props.actions.getScan(criterion);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.scan !== prevProps.scan) {
      let [criteria, criteriaOptions] = getCriteriaFromScan(this.props.scan);
      this.setState({
        criteria: criteria,
        selectedOptions: criteriaOptions,
        // Only change the scan id if the provided scan is not
        // 'last-scan', which is the id of the last scan which is unnamed
        scanName:
          this.props.scan && this.props.scan.id !== LAST_SCAN_ID
            ? this.props.scan.name
            : this.state.scanName
      });

      criteria.forEach(criterion => {
        this.props.actions.getScan(criterion);
      });
    }

    if (
      this.props !== prevProps ||
      this.state.criteria !== prevState.criteria ||
      this.state.selectedOptions !== prevState.selectedOptions
    ) {
      this.getFilteredResults();
    }
  }

  allDataLoaded() {
    if (_.isEmpty(this.props.scanData)) {
      return false;
    }

    let relevantScanCategories = this.state.criteria.map(s => s.toLowerCase());

    for (var i = 0; i < relevantScanCategories.length; i++) {
      let category = relevantScanCategories[i];
      if (!this.props.scanData[category]) {
        return false;
      }
    }

    return true;
  }

  getFilteredResults() {
    // We are just going to shortcut out if we have determined that we don't
    // have all the data needed to make an accurate count
    if (!this.allDataLoaded()) return;

    let filterResults = [];
    let resultCountByCriteria = [];

    const scanResultMapper = {
      multiselect: filterSelects,
      select: filterSelects,
      range: filterRange
    };
    let currentScanFields = this.state.criteria.map(cx => {
      let cxType = typeMap[scanCategoryData[cx].Input];
      return {
        id: cx,
        type: cxType,
        values: this.gatValuesOrDefault(this.state.selectedOptions[cx], cxType)
      };
    });

    let rawScanData = currentScanFields.map(field => {
      return this.props.scanData[field.id.toLowerCase()];
    });

    // This is a placeholder that the symbols matching the previous scan
    // criteria will be placed into.
    let previousResult = null;
    for (var i = 0; i < currentScanFields.length; i++) {
      let field = currentScanFields[i];
      let categorySymbolData = rawScanData[i].symbols;
      let processedCategoryData = Object.keys(rawScanData[i].symbols).map(k => {
        return {
          symbol: k,
          data: categorySymbolData[k]
        };
      });
      filterResults = scanResultMapper[field.type](
        processedCategoryData,
        field,
        scanCategoryData,
        previousResult
      );
      if (previousResult) {
        // If there were results from a previous run, merge the display values from before
        // so we can keep an object with all the data we will need to display.
        // ESLint doesn't like having a map inside this loop, but moving it causes side
        // effects and makes the code much harder to read.
        // eslint-disable-next-line
        filterResults = filterResults.map(item => {
          // Clone the item as we will be modifying it, don't want any side effects
          let newItem = _.cloneDeep(item);
          newItem.data.displayValues = _.merge(
            newItem.data.displayValues,
            previousResult[item.symbol].data.displayValues
          );
          delete newItem.data.filterValue;
          return newItem;
        });
      }
      previousResult = _.keyBy(filterResults, scan => scan.symbol);
      resultCountByCriteria.push(filterResults.length);
    }

    this.debouncedSaveLastScan();

    this.setState({
      resultCountByCriteria: resultCountByCriteria,
      scanResults: filterResults
    });
  }

  saveLastScan() {
    if (this.state.criteria.length > 0) {
      let lastScan = buildScan(
        this.state.criteria,
        this.state.selectedOptions,
        'previous',
        'last-scan'
      );

      this.props.actions.saveLastScan(lastScan);
    }
  }

  buildHeaderListItem(
    mainLabel,
    subLabel,
    onClickHandler,
    subContainer,
    subContainerOpen,
    key
  ) {
    let style = {};
    style.padding = 0;
    return (
      <div key={key}>
        <ListItem button style={style}>
          <div className={'hotlist_settings_category'} onClick={onClickHandler}>
            <div className="hotlist_settings__row one">
              <span className="hotlist_settings_category--name">
                {mainLabel}
              </span>
            </div>
            <div className="hotlist_settings__row two">
              <span className="hotlist_settings_category--selected">
                {subLabel}
              </span>
            </div>
          </div>
        </ListItem>
        {subContainerOpen && subContainer}
      </div>
    );
  }

  buildListItem(onClick, label, isLast, selected, key) {
    return (
      <ListItem button style={{ padding: '0px' }} disabled={selected} key={key}>
        {selected && <CheckIcon className={'hotlist_selected_item_check'} />}
        <div className={`hotlist_settings_item--container`}>
          <div
            className={`hotlist_settings_item${isLast ? ' last' : ''}`}
            onClick={onClick}
          >
            <span className={`hotlist_settings_item--name`}>{label}</span>
          </div>
        </div>
      </ListItem>
    );
  }

  toggleOpenCategory(category) {
    if (this.state.dialogOpenCategory === category) {
      category = null;
    }

    this.setState({
      dialogOpenCategory: category
    });
  }

  getSanifiedScanName() {
    let sanifiedScanName = this.state.scanName.trim();
    if (sanifiedScanName === '') {
      this.setState({ scanName: this.localize('scan.my.scan.default.name') });
      sanifiedScanName = this.localize('scan.my.scan.default.name');
    } else if (sanifiedScanName.length !== this.state.scanName.length) {
      this.setState({ scanName: sanifiedScanName });
    }
    return sanifiedScanName;
  }

  componentWillUnmount() {
    this.debouncedSaveLastScan.cancel();
    this.saveLastScan();
  }

  saveNewCriteria() {
    let scan = buildScan(
      this.state.criteria,
      this.state.selectedOptions,
      this.getSanifiedScanName()
    );

    this.toggleEditFlag();
    this.props.actions.saveScan(
      scan,
      scanId => {
        this.setState({
          displaySaved: true
        });

        // This is the callback with the newly
        // created scan id - signal back to the
        // parent that we have a new scan we want to show
        this.props.loadNewScan(scanId);
      },
      () => this.setState({ displayWarnSaving: true })
    );
  }

  overwriteCriteria() {
    let lastScan = buildScan(
      this.state.criteria,
      this.state.selectedOptions,
      this.getSanifiedScanName(),
      this.props.scan.id
    );

    this.toggleEditFlag();
    this.props.actions.overwriteScan(
      lastScan,
      () => {
        // If we don't use cloneDeep here, the "overwrittenX"
        // pieces of the state will reference the same memory
        // as the original values, which we don't want.
        this.setState({
          displaySaved: true,
          overwrittenCriteria: _.cloneDeep(this.state.criteria),
          overwrittenOptions: _.cloneDeep(this.state.selectedOptions)
        });
      },
      () => this.setState({ displayWarnSaving: true })
    );
  }

  toggleEditFlag() {
    this.setState({
      editEnabled: !this.state.editEnabled
    });
  }

  updateScanName(scanName) {
    const onlyWebSafeStringsRegex = /^((?![&\/\\#$%<>{}]+).)*$/;
    let newScanName = scanName;
    if (!onlyWebSafeStringsRegex.test(newScanName) || newScanName.length > 30) {
      return null;
    }
    this.setState({ scanName: newScanName });
  }

  render() {
    const CriteriaSelector = (
      <SettingsDialog
        isSmall={this.props.isDesktopWidth}
        handleClose={this.handleClose}
        open={this.state.open}
        dialogContentClassName={'dialog-modal-content-nopadding'}
        title={this.localize('symbol.details.settings.title')}
      >
        <div className={'settings-inner-container'}>
          {scanCategoryMapping.map((item, index) => {
            return this.buildHeaderListItem(
              this.localize(`scan.category.${item.Name}`),
              '',
              () => this.toggleOpenCategory(item.Name),
              item.Fields.map((field, i) =>
                this.buildListItem(
                  () => {
                    this.addSubcategory(field);
                  },
                  this.localize(`scan.criteria.${field}`),
                  i === item.Fields.length - 1,
                  this.state.criteria.indexOf(field) > -1,
                  'list-item-' + field
                )
              ),
              this.state.dialogOpenCategory === item.Name,
              item.Name
            );
          })}
        </div>
      </SettingsDialog>
    );

    let [originalCriteria, originalCriteriaOptions] = getCriteriaFromScan(
      this.props.scan
    );

    // This will make the "Overwrite" button inoperable when
    // there are no changes to the loaded scan.
    let isDirty = false;

    // The return value from the API when overwriting a scan does not
    // include the values overwritten, so there is no clean way of getting
    // the updated scan values into the Redux state.  Instead, we centralize
    // that messiness here by saving the new scan criteria to the local state
    // when overwriting a scan.  If this state is not null, that means we should
    // use our local state version of the scan to compare against when determining
    // the isDirty flag, otherwise we can use the props.
    if (this.state.overwrittenCriteria && this.state.overwrittenOptions) {
      isDirty =
        !_.isEqual(this.state.overwrittenOptions, this.state.selectedOptions) ||
        !_.isEqual(this.state.overwrittenCriteria, this.state.criteria) ||
        (this.props.scan && this.state.scanName !== this.props.scan.name);
    } else {
      isDirty =
        !_.isEqual(originalCriteriaOptions, this.state.selectedOptions) ||
        !_.isEqual(originalCriteria, this.state.criteria) ||
        (this.props.scan && this.state.scanName !== this.props.scan.name);
    }

    const EditCriteria = (
      <div>
        <div className={'scan-subcategory-container'}>
          {this.state.criteria.map(o => (
            <ScanCriteriaSelector
              key={'scan-subcategory-container_' + o}
              subcategoryName={o}
              subcategoryData={scanCategoryData[o]}
              selectedOptions={this.state.selectedOptions[o]}
              removeItemHandler={this.removeSubcategory}
              multiselectCriteriaHandler={this.toggleMultiselectCriteria}
              selectCriteriaHandler={this.toggleSelectCriteria}
              rangeCriteriaHandler={this.rangeCriteriaHandler}
            />
          ))}
        </div>
        {this.state.criteria.length < MAX_CRITERIA_QUANTITY && (
          <NewScansAddCriteriaButton
            handleClickOpen={this.handleClickOpen}
            labelText={this.localize('scan.criteria.addnew')}
          />
        )}
        {this.state.criteria.length > 0 && (
          <NewScansSaveCriteriaContainer
            saveNewCriteriaText={this.localize('scan.criteria.savenew')}
            handleSaveNewCriteria={() => this.saveNewCriteria()}
            allowSaveNewCriteria={true}
            overwriteCriteriaText={this.localize('scan.criteria.overwrite')}
            handleOverwriteCriteria={() => this.overwriteCriteria()}
            allowOverwriteCriteria={this.props.isUserScan && isDirty}
          />
        )}
        {CriteriaSelector}
      </div>
    );

    return (
      <div className="scan-section">
        <div className="scan-header">
          <div className="scan-title">
            {this.localize('scan.title.enter.conditions')}
          </div>
          <div className="my-scans-button">
            <MyScansButton
              buttonText={this.props.intl.formatMessage({
                id: 'scan.result.my.scan.list'
              })}
            />
          </div>
          <div className="scan-subheading">
            {this.localize('scan.subheading.enter.conditions')}
          </div>
        </div>
        <div className="scan-name">
          <EditableScanTitle
            scanName={this.state.scanName}
            handleInputChange={this.updateScanName}
            isEditable={this.state.editEnabled}
          />
        </div>
        {this.state.editEnabled ? (
          EditCriteria
        ) : (
          <MinifiedCriteria
            key={this.props.scanId}
            allowEditCriteria={true}
            criteria={this.state.criteria}
            selectedOptions={this.state.selectedOptions}
            handleClickEdit={() => {
              this.toggleEditFlag();
            }}
          />
        )}
        <NewScansResultsContainer
          resultCountByCriteria={this.state.resultCountByCriteria}
          scanResults={this.state.scanResults}
          titleText={this.localize('scan.result.count')}
          units={this.localize('scan.result.count.unit')}
        />
        <MessageBar
          open={this.state.displaySaved}
          onClose={this.handleSnackbarClose}
          message={this.localize('scan.message.success')}
          messageType={'success'}
        />
        <MessageBar
          open={this.state.displayWarnSaving}
          onClose={this.handleSnackbarClose}
          message={this.localize('scan.message.fail')}
          messageType={'warning'}
        />
      </div>
    );
  }
}

ScansLandingContainer = injectIntl(ScansLandingContainer);

function mapStateToProps(state) {
  // Use spread (...) to guarantee we have a new object.
  var props = {
    ...{}
  };

  props.scanData = state.scans.scanData;

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getScan: bindActionCreators(fetchScan, dispatch),
      saveLastScan: bindActionCreators(saveLastScan, dispatch),
      saveScan: bindActionCreators(saveScan, dispatch),
      overwriteScan: bindActionCreators(overwriteScan, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDesktopWidth(ScansLandingContainer));
