import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import configureStore from '../configureStore';
import App from './App';
import I18nContainer from './I18nContainer';
// eslint-disable-next-line
import Polyfills from '../utils/polyfills';

const store = configureStore();

const theme = createTheme({
  palette: {
    primary1Color: '#ffdc55',
    primary2Color: '#ffc736',
    primary3Color: '#ff0',
    accent1Color: '#fcc800'
  },
  fontFamily:
    '"ヒラギノ角ゴシック ProN","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif',
  appBar: {
    textColor: '#333'
  },

  tabs: {
    textColor: '#7a7878',
    selectedTextColor: '#595757'
  },

  typography: {
    useNextVariants: true,
    fontFamily:
      '"ヒラギノ角ゴシック ProN","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif'
  },

  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderTop: 'none rgb(224, 224, 224)',
          borderLeft: 'none rgb(224, 224, 224)',
          borderRight: 'none rgb(224, 224, 224)',
          borderBottom: '1px solid rgb(224, 224, 224)'
        },
        '&&&&:hover:before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        },
        '&:after': {
          borderBottom: '2px solid rgb(252, 200, 0)'
        }
      }
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: 'rgb(252, 200, 0)'
        }
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: 'rgb(252, 200, 0)'
      }
    }
  },

  zIndex: {
    appBar: 1029
  }
});

export default class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <I18nContainer>
              <div>
                <App />
              </div>
            </I18nContainer>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    );
  }
}
