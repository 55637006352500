import types from './_actionTypes';
import * as time from '../utils/time';

function getRedirectUrl() {
  return (
    process.env.REACT_APP_MONEX_REDIRECT_URL ||
    'https://mst.monex.co.jp/pc/ITS/login/LoginIDPassword.jsp'
  );
}

function receiveMessage(data) {
  return {
    type: types.RECEIVE_MESSAGE,
    when: time.timestamp(),
    ...data
  };
}

function receivedStatus(data, lastState) {
  return {
    type: types.RECEIVE_STATUS,
    text:
      'STATUS (ping: ' +
      data.ping +
      ' req: ' +
      data.channels.requested +
      ' active: ' +
      data.channels.active +
      ')',
    data: data,
    when: time.timestamp()
  };
}

function connecting() {
  return {
    type: types.CONNECTING,
    when: time.timestamp()
  };
}

function error(data) {
  return {
    type: types.RECEIVE_MESSAGE,
    when: time.timestamp(),
    ...data
  };
}

export function start(channel) {
  return (dispatch, getState, faucet) => {
    faucet.start(channel);
  };
}

export function stop(channel) {
  return (dispatch, getState, faucet) => {
    faucet.stop(channel);
  };
}

export function initWebSocket(token) {
  return (dispatch, getState, faucet) => {
    faucet.setToken(token);
    dispatch(connecting());

    faucet.on('data', e => {
      dispatch(receiveMessage(e));
    });

    faucet.on('status', e => {
      dispatch(receivedStatus(e, getState()));
    });

    faucet.on('error', e => {
      dispatch(error());
      if (e.code === 'TOKEN_EXPIRED' || e.code === 'TOKEN_INVALID') {
        // The location.replace function was used in order to unit test it
        window.location.replace(getRedirectUrl());
      }
    });
  };
}
