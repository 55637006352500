import React from 'react';

const NewScansSaveCriteriaButton = props => {
  let buttonClassName = `margin button btn-cmn scan result save-criteria${
    props.disabled ? ' disabled' : ''
  }`;
  return (
    <div className="actions save-scan-criteria">
      <div
        onClick={() => {
          if (!props.disabled) {
            props.handleClickOpen();
          }
        }}
        disabled={props.disabled}
      >
        <div className={buttonClassName}>
          <div className={'save-criteria-label'}>
            <div className={'save-criteria-icon'}>{props.icon}</div>
            <div className={'save-criteria-svg-label'}>{props.labelText}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewScansSaveCriteriaButton;
