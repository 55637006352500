import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

// A common loading message block to be used when
// data is still being loaded from an API
class Loading extends React.PureComponent {
  render() {
    return (
      <div className="is-loading">
        <div className="loading-message">
          <FormattedMessage id={'message.status.loading'} />
        </div>
      </div>
    );
  }
}

export default injectIntl(Loading);
