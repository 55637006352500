const pushToLoggly = require('./logglyService').default;
const version = require('../../package.json').version;
const environment = JSON.stringify(process.env.NODE_ENV).replace(/['"]+/g, '');
const token = process.env.REACT_APP_LOGGLY_TOKEN;
const enableLoggly = process.env.REACT_APP_ENABLE_LOGGLY;
const tags = ['popsicle', version, environment];
const pushInterval = 5000;
const maxLogLength = 1000;
const maxNodeLength = 100;
const defaultMessage = 'No message provided';
var queue = [];
var user = {};

class Logger {
  constructor() {
    setInterval(function () {
      if (enableLoggly === 'true' && queue.length > 0) {
        pushToLoggly(queue, token, tags).catch((err) => {
          console.log('Error publishing logs:', err);
        });
      }
      queue = [];
    }, pushInterval);
  }

  // Allow the component to force a send so that events
  // do not get lost in the unload step
  sendLogs() {
    if (enableLoggly === 'true' && queue.length > 0) {
      pushToLoggly(queue, token, tags).catch((err) => {
        console.log('Error publishing logs:', err);
      });
    }
    this.flushQueue();
  }

  get queue() {
    return queue;
  }

  _enqueue(level, data) {
    this.outputToConsole(level, data);

    let event = {};
    let message = defaultMessage;
    let stringData = JSON.stringify(data);

    // We will attempt to count the number of nodes in
    // the object and check the total length of the
    // data in the object. If either of these seems
    // high, just submit as a string
    if (
      typeof data === 'object' &&
      stringData.match(/[^\\]":/g).length < maxNodeLength &&
      stringData.length < maxLogLength
    ) {
      // if a message is provided in the data object
      // move it to the root data level
      // and remove it from the data level
      // to keep the messaging shape consistent.
      // Also, to avoid breaking our logging
      // system, we will only send the data for
      // objects under a certain size
      if (data.message) {
        message = data.message;
        delete data.message;
      }

      // If this data contains an error code, we will prepend
      // that to the message.
      if (data.errorCode) {
        message = `${data.errorCode}: ${message}`;
        delete data.errorCode;
      }

      // If this data contains an error object, we will attempt
      // to extract the error message from the object.
      if (data.error) {
        if (typeof data.error === 'string') { 
          event.error = `${data.error}`;
        } else if (typeof data.error === 'object') {
          if (data.error.message) {
            event.error = `${data.error.message}`;
          } else if (data.error.code) {
            event.error = `${data.error.code}: ${message}`;
          }
        }
        delete data.error;
      }

      // Optionally, when we have a user, we will use this as the
      // user for all logs going forward, and remove it from
      // the data object.
      if (data.user) {
        user = data.user;
        delete data.user;
      }

      // If the object has additional data, we will add it to the
      // event. 
      if (Object.keys(data).length > 0) {
        event.data = data;
      }
    } else if (typeof data === 'string') {
      message = data;
    } else {
      message = stringData.substring(0, maxLogLength);
    }

    // override any properties already set
    event.level = level;
    event.timestamp = Date.now();
    event.message = message;
    event.user = user;
    queue.push(event);
  }

  warn(logData) {
    let level = 'WARN';
    this._enqueue(level, logData);
  }

  info(logData) {
    let level = 'INFO';
    this._enqueue(level, logData);
  }

  error(logData) {
    let level = 'ERROR';
    this._enqueue(level, logData);
  }

  debug(logData) {
    let level = 'DEBUG';
    this.outputToConsole(level, logData);
  }

  outputToConsole(level, data) {
    if (enableLoggly !== 'true') {
      console.log(level, { data: JSON.stringify(data) });
    }
  }

  sendMetric(data) {
    data.level = 'INFO';
    queue.push(data);
    pushToLoggly(queue, token, tags)
    .then(() => {
      flushQueue();
    })
    .catch((err) => {
      console.log('Error publishing logs:', err);
    });
  }

  postMetric(data) {
    data.level = 'INFO';
    queue.push(data);
  }

  flushQueue() {
    queue = [];
  }
}

export default new Logger();
