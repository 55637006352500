import React, { useState, useRef, useEffect } from 'react';

// PageTitleBar creates a full-width area intended to display a page's title
// and up to two optional buttons (one on either side). The actual positioning
// of these elements is left up to CSS; this component merely defines their
// relative locations in the DOM.
//
// The left and right buttons are specified through the `leftButton` and
// `rightButton` props. (A full React component is expected here.) Although
// these props are named `Buttons`, technically speaking any component could be
// passed in. Typically, however, you will provide an IconButton or something
// of a similar size.
//
// When a component is provided for `leftButton`, the root element of this
// component is given the `has-left-button` CSS class so that the position of
// the title text can be adjusted to accomodate the space taken by the button
// (if needed). Similarly, for the `rightButton` prop, there exists a
// `has-right-button` class.
//
// The primary feature of this component is that it subscribes to the
// window.scroll event to determine whether the root's element's top bound has
// passed above a certain threshold. This threshold is determined by the
// presence of the `hybrid_monex_co_jp` class on the body element. When this
// class is not present, we expect the fixed Monex navigation bar to be visible
// and to take up exactly 46 pixels. When this class is present, we expect
// there to be no such navigation bar, so the threshold is effectively zero.
// So long as the top bound is above this threshold, the `fixed` CSS class is
// added to the root element. This allows for the title bar element to be made
// "sticky" via CSS rules.
//
// This `fixed` class remains attached to the component's element until the top
// bound of the component's *parent* element goes *below* the same threshold.
// Therefore, for this component to work as expected, it should be the first
// child of a block element that remains in the document flow at all times.
//
// To help accomodate the content shift that occurs when an element is
// transitioned from inside the document flow to outside and back again, for as
// long as the root element has the `fixed` class applied, the parent element
// is given the `page-title-bar-fixed` class. (This class is removed at the
// same time the `fixed` class is removed.) This allows for a CSS rule
// targeting the parent element to apply whatever padding is necessary to make
// up for the document flow space lost to a fixed-position element.
export function PageTitleBar({title, leftButton, rightButton, isNoHelpBar}) {
    // Local State
  const [fixed, setFixed] = useState(false);

  const ref = useRef();
  const threshold = document.body.classList.contains('hybrid_monex_co_jp') ? 0 : (isNoHelpBar ? 46 : 20);

  const toggleFixed = () => {
    const offset = ref.current.parentElement.getBoundingClientRect().top;
    if (offset >= threshold) {
      setFixed(false);
      ref.current.parentElement.classList.remove('page-title-bar-fixed');
    } else if (!fixed && offset < threshold) {
      setFixed(true);
      ref.current.parentElement.classList.add('page-title-bar-fixed');
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleFixed);
    return () => window.removeEventListener("scroll", toggleFixed);
  }, []);

  const className =
    'page-title-bar' +
    (fixed ? ' fixed' : '') +
    (leftButton ? ' has-left-button' : '') +
    (rightButton ? ' has-right-button' : '');
  return (
    <>
      <div className={className} ref={ref}>
        <div className="left-button">{leftButton}</div>
        <div className="title">{title}</div>
        <div className="right-button">{rightButton}</div>
      </div>
      { fixed && !isNoHelpBar && <div className={"page-title-bar-empty"}/> }
    </>
  );
}
