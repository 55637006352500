const {
  simpleMovingAverage,
  getFilteredBarValuesWithSameTimestamps
} = require('./utils');

// Returns an array of maximum values, where each maximum is calculated
// over a sliding window of length across neighboring elements
export function movingMaximum(values, length) {
  // The maximums will hold all the determined max values for the range
  // This will be the array that is returned
  let maximums = [];

  // The queue will hold all the current items of length to check for
  // the current maximum
  let queue = [];

  // Max is the current max value for the given range. We are assuming that, since this is
  // being used for stock prices, they will never go negative.
  let max = 0;
  if (length <= 0) {
    throw new Error('Argument out of range: length');
  }
  values.forEach(value => {
    //each item gets added to the queue
    queue.push(value);

    // if the current value is larger than the max, set it is the max
    if (value > max) {
      max = value;
    }
    // For the moving max value, we need the entire length
    // before setting the return values. A null value represents
    // that we do not have enough information to determine a moving
    // maximum
    if (queue.length < length) {
      maximums.push(null);
    } else {
      // If we do have enough items in the queue, we can add the
      // most recent maximum to the return array
      maximums.push(max);

      // remove the first value off the queue to leave it at the
      // required length.
      let removed = queue.shift();

      // if the current item is not null, and is potentially the
      // maximum of the range, set the current max to the max
      // value in the queue
      if (removed && max === removed) {
        max = Math.max(...queue);
      }
    }
  });

  return maximums;
}

// Returns an array of minimum values, where each minimum is calculated
// over a sliding window of length across neighboring elements
export function movingMinimum(values, length) {
  // This will hold the array of minimum values to be
  // returned
  let minimums = [];

  // The queue will hold all the current items of length to check for
  // the current minimum
  let queue = [];

  // Max is the current min value for the given range.
  let min = Math.pow(2, 53);
  if (length <= 0) {
    throw new Error('Argument out of range: length');
  }
  values.forEach(value => {
    //each item gets added to the queue
    queue.push(value);

    // For the moving min value, we need the entire length
    // before setting the return values.
    if (value < min) {
      min = value;
    }
    // A null value represents
    // that we do not have enough information to determine a moving
    // minimum
    if (queue.length < length) {
      minimums.push(null);
    } else {
      // If we do have enough items in the queue, we can add the
      // most recent value to the return array
      minimums.push(min);

      // remove the first item in the queue
      queue.shift();

      // set the min to equal the minimum value from the
      // remaining items
      min = Math.min(...queue);
    }
  });
  return minimums;
}

export function getStochasticOscillatorD(
  bars,
  fullBarData,
  length,
  keepNonChartBars
) {
  let closeBars = fullBarData.map(bar => bar[4]);

  let highBars = fullBarData.map(bar => bar[2]);
  let lowBars = fullBarData.map(bar => bar[3]);

  let highs = movingMaximum(highBars, length);
  let lows = movingMinimum(lowBars, length);

  let numerators = closeBars.map((close, index) => {
    let low = lows[index];
    return low == null ? null : close - low;
  });

  let denominators = highs.map((high, index) => {
    let low = lows[index];
    return high == null || low == null ? null : high - low;
  });

  let smaNumerators = simpleMovingAverage(numerators, 3);
  let smaDenominators = simpleMovingAverage(denominators, 3);

  let values = smaNumerators.map((n, index) => {
    let d = smaDenominators[index];

    if (n == null || d == null) {
      return null;
    } else if (d === 0) {
      return 0;
    } else {
      return (n / d) * 100;
    }
  });

  return getFilteredBarValuesWithSameTimestamps(
    bars,
    fullBarData,
    values,
    keepNonChartBars
  );
}

// Stochastic slow uses the Fast D line for the slow K line, and
// the fast D line with a Simple Moving Average for the
// slow D line.
export function D(bars, fullBarData, length) {
  // start with the fast K line, but since we are
  // going to additionally apply a simple moving average,
  // we get a few more bars than we need. We will slice them
  // off after the SMA
  let k = getStochasticOscillatorD(
    fullBarData.slice(-2 * bars.length),
    fullBarData,
    length,
    true
  );

  // Apply a simple moving average to the K.
  // The 3 is a magic number in Sometaro. TBH, I
  // have no idea why 3 was chosen.
  let kClose = k.map(bar => bar[1]);
  let values = simpleMovingAverage(kClose, 3);
  return getFilteredBarValuesWithSameTimestamps(bars, k, values);
}

export function getStochasticOscillatorK(bars, fullBarData, length) {
  let closeBars = fullBarData.map(bar => bar[4]);

  let highBars = fullBarData.map(bar => bar[2]);
  let lowBars = fullBarData.map(bar => bar[3]);

  let highs = movingMaximum(highBars, length);
  let lows = movingMinimum(lowBars, length);

  let values = closeBars.map((close, index) => {
    return K(close, highs[index], lows[index]);
  });

  return getFilteredBarValuesWithSameTimestamps(bars, fullBarData, values);
}

function K(close, high, low) {
  if (high == null || low == null) {
    return null;
  }

  var numerator = close - low;
  var denominator = high - low;
  if (denominator === 0) {
    return 0;
  }

  return (numerator / denominator) * 100;
}
