import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

// A common loading message block to be used when
// data is still being loaded from an API
class NoData extends React.PureComponent {
  render() {
    return (
      <div className="symbol-details-chart-no-data">
        <FormattedMessage id={'symbol.details.chart.noData1'} />
        <br />
        <FormattedMessage id={'symbol.details.chart.noData2'} />
      </div>
    );
  }
}

export default injectIntl(NoData);
