import { getSma } from './sma';

export const getSeries = (periods, bars, fullBarData, localize) => {
  let result = [];
  for (var i = 0; i < periods.length; i++) {
    let period = periods[i];
    let lineId = `avgDeviation_${i}_${period}`;
    result.push({
      type: 'candlestick',
      id: lineId,
      visible: false,
      data: fullBarData.slice(-1 * (bars.length + period - 1))
    });
    result.push({
      type: 'avgDeviation',
      name: localize({
        id: 'symbol.details.chart.tooltip.avgDeviation'
      }).replace('XX', period),
      yAxis: 'avgDeviation',
      linkedTo: lineId,
      visible: true,
      params: {
        index: 3,
        period: period
      },
      enableMouseTracking: false
    });
  }
  return result;
}

export const getAvgDeviation = (bars, period) => {
	const result = [];
  const sma = getSma(bars, period);

  // Convert sma to dictionary so that way we can retrieve the same timestamps from
  // both the bars and the sma results.
  const smaDictionary = {};
  sma.forEach(i => {
    if (i !== null) {
      smaDictionary[i[0]] = i[1]
    }
  });

  for (let i = 0; i < bars.length; i++) {
    const timestamp = bars[i][0];
    const average = smaDictionary[timestamp];

    // If there is no average, skip further logic
    if (!average) {
      result.push([timestamp, null]);
      continue;
    }

    const close = bars[i][4];
    const difference = ((close - average) / average) * 100;
    result.push([timestamp, difference]);
  }

  return result;
};