import { createSelector } from 'reselect';

const globalMessagesSelector = (state) => state.user.globalMessages || [];
const localMessagesSelector = (state) => state.user.localMessages || [];
const stateUserSelector = (state) => state.user;
const stateChartSettingsSelector = (state) => state.user.settings.chart;

export const allMessagesSelector = createSelector(
  [globalMessagesSelector, localMessagesSelector],
  (globalMessages, localMessages) => {
    let all = [];
    all.push(...globalMessages);
    all.push(...localMessages);
    return all;
  }
);

export const userSelector = createSelector([stateUserSelector], (user) => user);

export const chartSettingsSelector = createSelector(
  [stateChartSettingsSelector],
  (chartSettings) => chartSettings
);
