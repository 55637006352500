import types from './actions/_actionTypes';
import Logger from './utils/logger';

function handleAction(user, state, action) {
  let context;
  let logCode = 'I9001';
  switch (action.type) {
    case types.MARKETBOARD_ADD:
      // Grab the Name and ID of the new marketboard
      context = {
        name: action.marketboard.Name,
        id: action.marketboard.Id
      };
      break;
    case types.MARKETBOARD_DELETE:
      // We only have the ID of the marketboard to delete
      context = {
        id: action.marketboardId
      };
      break;
    case types.DELETE_SYMBOL:
      context = {
        position: action.symbolPosition,
        id: action.marketboardId
      };
      break;
    case types.REPLACE_MARKETBOARD_SYMBOLS:
      context = {
        id: action.marketboardId,
        symbols: action.symbols
      };
      break;
    case types.UPDATE_MARKETBOARD_NAME:
      context = {
        id: action.marketboardId,
        name: action.name
      };
      break;
    case types.UPDATE_MARKETBOARD_SYMBOL:
      context = {
        id: action.marketboardId,
        position: action.symbolPosition,
        symbol: action.symbol
      };
      break;
    case types.MOVE_MARKETBOARD:
      context = {
        fromIndex: action.fromIndex,
        toIndex: action.toIndex
      };
      break;
    case types.ERROR_REQUESTING_BARS:
      logCode = action.errorCode;
      context = {
        symbol: action.symbol,
        period: action.period,
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_FETCHING_MARKETBOARD:
      logCode = action.errorCode;
      context = {
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_POSTING_MARKETBOARD:
      logCode = action.errorCode;
      context = {
        message: (action.error || {}).message
      };
      break;
    case types.USER_ERROR_VALIDATING_TOKEN:
      logCode = action.errorCode;
      context = {
        token: state.user.token,
        monexSubdomain: state.user.dm,
        message: (action.error || {}).message
      };
      break;
    case types.SYMBOL_ERROR_REQUESTING_METADATA:
      logCode = action.errorCode;
      context = {
        symbol: action.symbol,
        message: (action.error || {}).message
      };
      break;
    case types.SYMBOL_ERROR_REQUESTING_PATTERN:
      logCode = action.errorCode;
      context = {
        pattern: action.pattern,
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_REQUESTING_UP_DOWN_RATIOS:
      logCode = action.errorCode;
      context = {
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_REQUESTING_FUNDAMENTALS:
      logCode = action.errorCode;
      context = {
        symbol: action.symbol,
        message: (action.error || {}).message
      };
      break;
    case types.NEWS_ERROR_REQUESTING_HEADLINES:
      logCode = action.errorCode;
      context = {
        symbol: action.symbol,
        message: (action.error || {}).message
      };
      break;
    case types.NEWS_ERROR_REQUESTING_ARTICLE:
      logCode = action.errorCode;
      context = {
        articleId: action.articleId,
        message: (action.error || {}).message
      };
      break;
    case types.NEWS_ERROR_REQUESTING_TSE:
      logCode = action.errorCode;
      context = {
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_REQUESTING_SYSTEM_STATUS:
      logCode = action.errorCode;
      context = {
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_REQUESTING_HOTLIST:
      logCode = action.errorCode;
      context = {
        name: action.hotlistName,
        exchange: action.exchange,
        sector: action.sector,
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_REQUESTING_SCAN:
      logCode = action.errorCode;
      context = {
        name: action.name,
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_REQUESTING_USER_SCANS:
      logCode = action.errorCode;
      context = {
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_REQUESTING_LAST_SCAN:
      logCode = action.errorCode;
      context = {
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_SAVING_LAST_SCAN:
      logCode = action.errorCode;
      context = {
        scan: action.lastScan,
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_SAVING_SCAN:
      logCode = action.errorCode;
      context = {
        scan: action.scan,
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_OVERWRITING_SCAN:
      logCode = action.errorCode;
      context = {
        scan: action.scan,
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_DELETING_SCAN:
      logCode = action.errorCode;
      context = {
        scanId: action.scanId,
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_REQUESTING_USER_MEMOS:
      logCode = action.errorCode;
      context = {
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_SAVING_USER_MEMO:
      logCode = action.errorCode;
      context = {
        symbol: action.symbol,
        memo: action.memo,
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_DELETING_USER_MEMO:
      logCode = action.errorCode;
      context = {
        symbol: action.symbol,
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_POSTING_FEEDBACK:
      logCode = action.errorCode;
      context = {
        id: action.id,
        value: action.value,
        message: (action.error || {}).message
      };
      break;
    case types.ERROR_REQUESTING_FEATURE_FLAGS:
      logCode = action.errorCode;
      context = {
        message: (action.error || {}).message
      };
      break;
    default:
    // Don't log anything
  }

  // Only log if we have a context object
  if (context) {
    let log = {
      code: logCode,
      userId: state.user.id,
      action: action.type,
      context: context,
      location: window.location.hash
    };

    if (logCode.indexOf('E') > -1) {
      Logger.error(log);
    } else {
      Logger.info(log);
    }
  }
}

export const actionLoggerMiddleware = (store) => (next) => (action) => {
  var state = store.getState();

  if (state.user && state.user.id) {
    handleAction(state.user.id, state, action);
  }

  next(action);
};
