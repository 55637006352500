import { createSelector } from 'reselect';
import { getOrderedUserboards } from '../utils';
import _ from 'lodash';

const BOARD_PREFIX_DEFAULT = '登録';
const BOARD_LIMIT = process.env.REACT_APP_MAX_MARKETBOARDS;

export const userBoardSelector = state => state.marketboard.user || [];
export const cashBoardSelector = state => state.marketboard.cash || [];
export const marginBoardSelector = state => state.marketboard.margin || [];
export const searchSelector = state => state.marketboard.filter || '';
export const marketboardSelectedSelector = (state, urlBoardId) => {
  // If the url contains an id of the marketboard to display,
  // use that one instead of the one saved in the state
  if (urlBoardId) {
    return urlBoardId;
  }

  return state.marketboard.selected;
};
export const totalUserBoardsSelector = state => state.marketboard.total;
export const canAddSelector = state => state.marketboard.canAdd;

export const getMarketboards = createSelector(
  [userBoardSelector],
  marketboardSelector => {
    const mb = Object.assign({}, { boards: marketboardSelector });
    return mb;
  }
);

export const getOrderedMarketboards = createSelector(
  [getMarketboards],
  marketboards => {
    const boards = Object.assign({}, marketboards);
    boards.boards = getOrderedUserboards(marketboards.boards);
    return boards;
  }
);

export const getFilteredMarketboards = createSelector(
  [getOrderedMarketboards, searchSelector],
  (marketboards, searchFilter) => {
    var mb = Object.assign({}, marketboards);
    mb.boards = _(mb.boards)
      .filter(
        b => b.Name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1
      )
      .value();
    return mb;
  }
);

export const selectedSelector = createSelector(
  [marketboardSelectedSelector, getOrderedMarketboards],
  (selected, user) => {
    if (!selected) {
      return user.boards && user.boards.length > 0 ? user.boards[0].Id : '1';
    }
    return selected;
  }
);

export const marketboardEditableSelector = createSelector(
  [selectedSelector, totalUserBoardsSelector],
  (selected, user) => {
    // you can edit if the total boards is 100, but you cannot add
    return _.isFinite(_.parseInt(selected, 10)) && user < BOARD_LIMIT + 1;
  }
);

export const marketboardAddableSelector = createSelector(
  [getMarketboards],
  user => {
    return user.boards.length < BOARD_LIMIT;
  }
);

export const marketboardDeletableSelector = createSelector(
  [getMarketboards],
  user => {
    // you can delete if the total boards is more than 1
    return user.boards.length > 1;
  }
);

export const getSelectedBoard = createSelector(
  [getMarketboards, cashBoardSelector, marginBoardSelector, selectedSelector],
  (user, cash, margin, selected) => {
    const board = _(user.boards)
      .concat(cash)
      .concat(margin)
      .filter(b => b.Id === selected)
      .take(1)
      .value();
    return board && board.length > 0 ? board[0] : {};
  }
);

export const isLoadingSelector = state => state.marketboard?.isLoading ?? false;

export const getSelectedBoardOrDefault = createSelector(
  [isLoadingSelector, getMarketboards, cashBoardSelector, marginBoardSelector, selectedSelector],
  (isLoading, user, cash, margin, selected) => {
    const allBoards = _(user.boards).concat(cash).concat(margin);

    const selectedBoardArray = allBoards
      .filter((b) => b.Id === selected)
      .take(1)
      .value();

    // If we find a match return the selected board
    // Otherwise while cash and margin boards are loading return an empty object
    // Once they are loaded if we still have no match, return the default board
    // This avoids flickering the default board while loading is in progress
    if (selectedBoardArray && selectedBoardArray.length > 0) {
      return selectedBoardArray[0];
    } else {
      return isLoading
        ? {}
        : allBoards.take(1).value()[0];
    }
  }
);

export const getNextMarketboardId = createSelector(
  [getOrderedMarketboards],
  user => {
    const max = _.maxBy(user.boards, function(o) {
      return _.parseInt(o.Id, 10);
    });
    return !!max ? String(_.parseInt(max.Id, 10) + 1) : '1';
  }
);

export const getNextMarketboardName = createSelector(
  [getOrderedMarketboards],
  user => {
    if (user && user.boards) {
      const topName = _(user.boards)
        .filter(b => b.Name.startsWith(BOARD_PREFIX_DEFAULT))
        .sortBy(b => _.parseInt(b.Name.slice(BOARD_PREFIX_DEFAULT.length)))
        .slice(-1)
        .take(1)
        .value();

      if (topName && topName.length) {
        //get the current number from the name
        const current = topName[0].Name.slice(
          BOARD_PREFIX_DEFAULT.length,
          topName[0].Name.length
        );
        let x = (_.parseInt(current) || 0) + 1;
        return BOARD_PREFIX_DEFAULT + x;
      }
    }
    return BOARD_PREFIX_DEFAULT + '1';
  }
);
