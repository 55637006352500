import { useSelector } from 'react-redux';

const useFundamentals = (symbol) => {
  const symbolFundamentals = useSelector(
    (state) => (state.market.fundamentals || {})[symbol] || {}
  );

  return (symbolFundamentals.data || {}).fundamentals || {};
}

export default useFundamentals;