import types from '../actions/_actionTypes';
import initialState from './initialState';

const scan = (state = initialState.scans, action) => {
  var newState;

  switch (action.type) {
    case types.RECEIVE_SCAN:
      newState = Object.assign({}, state);
      newState.scanData = Object.assign({}, state.scanData);
      newState.scanData[action.name.toLowerCase()] = action.data.data;
      return newState;
    case types.RECEIVE_USER_SCANS:
      return Object.assign({}, state, {
        userScans: action.data.data.scans,
        staticScans: action.data.data.staticScans || []
      });
    case types.RECEIVE_LAST_SCAN:
      return Object.assign({}, state, {
        lastScan: action.data.data.scan
      });
    case types.SAVE_SCAN:
      let newScans = Object.assign([], state.userScans);
      newScans.push(action.data.data.scan);
      return Object.assign({}, state, {
        userScans: newScans
      });
    case types.DELETE_SCAN:
      return Object.assign({}, state, {
        userScans: state.userScans.filter(scan => scan.id !== action.id)
      });
    case types.ERROR_REQUESTING_SCAN:
      newState = Object.assign({}, state);
      return newState;
    case types.ERROR_REQUESTING_USER_SCANS:
      newState = Object.assign({}, state);
      return newState;
    case types.ERROR_REQUESTING_LAST_SCAN:
      newState = Object.assign({}, state);
      return newState;
    case types.ERROR_DELETING_SCAN:
      newState = Object.assign({}, state);
      return newState;
    default:
      return state;
  }
};

export default scan;
