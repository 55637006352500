function getBbSeries(periods, bars, fullBarData, localize) {
  let result = [];
  let period = periods[0];

  const colorMapping = {
    0: '#9432a6',
    1: '#009900',
    2: '#FC300D',
    3: '#0C89E8'
  };
  result.push({
    type: 'candlestick',
    id: 'bb' + period,
    visible: false,
    data: fullBarData.slice(-1 * (bars.length + period - 1)),
    yAxis: 0
  });
  for (var sd = 3; sd >= 0; sd--) {
    result.push({
      type: 'bb',
      name:
        sd === 0
          ? localize({ id: 'symbol.details.chart.tooltip.bb.mid' })
          : localize({ id: 'symbol.details.chart.tooltip.bb' }).replace(
              'XX',
              sd
            ),
      yAxis: 0,
      linkedTo: 'bb' + period,
      visible: true,
      color: colorMapping[sd],
      params: {
        index: 3,
        period: period,
        standardDeviation: sd
      },
      enableMouseTracking: false
    });
  }

  return result;
}

module.exports = {
  getSeries: getBbSeries
};
