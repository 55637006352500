export const scanCategoryData = {
  Close: {
    Name: 'Close',
    ResourceKey: 'Quote_Label_CurrentPrice',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: 'Close',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  NetChange: {
    Name: 'NetChange',
    ResourceKey: 'Quote_Label_NetChange',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: 'Close SUB PrevClose',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: true,
    Magnitude: 1,
    Precision: 0
  },
  NetChangePct: {
    Name: 'NetChangePct',
    ResourceKey: 'Quote_Label_NetChangePct',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: '((Close SUB PrevClose) DIV PrevClose) MUL 100',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 2
  },
  Volume: {
    Name: 'Volume',
    ResourceKey: 'Quote_Label_Volume',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: 'TotalVol',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 6,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  Turnover: {
    Name: 'Turnover',
    ResourceKey: 'Quote_Label_TradingValue',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: 'TurnOver',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1000,
    Precision: 0
  },
  '52WeekHigh': {
    Name: '52WeekHigh',
    ResourceKey: 'Quote_Label_52WeekHigh',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: 'TwelveMonthHigh',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  '52WeekHighChangePct': {
    Name: '52WeekHighChangePct',
    ResourceKey: 'Scan_Field_52WeekHighChangePct',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: '((High SUB TwelveMonthHigh) DIV TwelveMonthHigh) MUL 100',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 0
  },
  High: {
    Name: 'High',
    ResourceKey: 'Quote_Label_High',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: 'High',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  '52WeekLow': {
    Name: '52WeekLow',
    ResourceKey: 'Quote_Label_52WeekLow',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: 'TwelveMonthLow',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  '52WeekLowChangePct': {
    Name: '52WeekLowChangePct',
    ResourceKey: 'Scan_Field_52WeekLowChangePct',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: '((Low SUB TwelveMonthLow) DIV TwelveMonthLow) MUL 100',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 0
  },
  Low: {
    Name: 'Low',
    ResourceKey: 'Quote_Label_Low',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: 'Low',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  '10DayVolumeAverage': {
    Name: '10DayVolumeAverage',
    ResourceKey: 'Quote_Label_10DayVolumeAverage',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: '(TotalVol AVERAGE 10)',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 6,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  VolumeChangePct: {
    Name: 'VolumeChangePct',
    ResourceKey: 'Quote_Label_VolumeChangePct',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField:
      '((Prev_TotalVol SUB (TotalVol AVERAGE 10)) DIV (TotalVol AVERAGE 10)) MUL 100',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 2
  },
  PreviousVolume: {
    Name: 'PreviousVolume',
    ResourceKey: 'Quote_Label_PreviousVolume',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: false,
    SourceField: 'Prev_TotalVol',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 6,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  SMACrossover_5_25_Date: {
    Name: 'SMACrossover_5_25_Date',
    ResourceKey: 'Scan_Technical_5Day25DayBullishCrossoverDate',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: true,
    ReservoirFieldName: 'SMACrossover_5_25',
    SourceField: 'CrossoverDate',
    ValueIndex: null,
    Source: 1,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 2,
    Units: 0,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  SMACrossover_5_25_Close: {
    Name: 'SMACrossover_5_25_Close',
    ResourceKey: 'Scan_Technical_5Day25DayBullishCrossoverClose',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: true,
    ReservoirFieldName: 'SMACrossover_5_25',
    SourceField: 'CrossoverClose',
    ValueIndex: null,
    Source: 1,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  SMACrossover_25_5_Date: {
    Name: 'SMACrossover_25_5_Date',
    ResourceKey: 'Scan_Technical_5Day25DayBearishCrossoverDate',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: true,
    ReservoirFieldName: 'SMACrossover_25_5',
    SourceField: 'CrossoverDate',
    ValueIndex: null,
    Source: 1,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 2,
    Units: 0,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  SMACrossover_25_5_Close: {
    Name: 'SMACrossover_25_5_Close',
    ResourceKey: 'Scan_Technical_5Day25DayBearishCrossoverClose',
    AlternateResourceKey: null,
    IsPublic: false,
    IsColumn: true,
    ReservoirFieldName: 'SMACrossover_25_5',
    SourceField: 'CrossoverClose',
    ValueIndex: null,
    Source: 1,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  SecurityType: {
    Name: 'SecurityType',
    ResourceKey: 'Symbol_Label_Type',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'SectionClassCode',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 3,
    Options: {
      ETF: {
        Name: 'ETF',
        ResourceKey: 'Symbol_Label_Type_ETF',
        Expression:
          "(SectionClassCode eq '0115' OR SectionClassCode eq '0116')",
        Pattern: '^011[56]$'
      },
      REIT: {
        Name: 'REIT',
        ResourceKey: 'Symbol_Label_Type_REIT',
        Expression: "(SectionClassCode eq '0119')",
        Pattern: '^0119$'
      },
      Equity: {
        Name: 'Equity',
        ResourceKey: 'Symbol_Label_Type_Equity',
        Expression:
          "(SectionClassCode ne '0115' AND SectionClassCode ne '0116' AND SectionClassCode ne '0119')",
        Pattern: '^(?!0115|0116|0119).*$'
      }
    },
    Output: 0,
    Units: 0,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  Exchange: {
    Name: 'Exchange',
    ResourceKey: 'Quote_Label_Exchange',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'Listed_Exchange,SectionClassCode',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 2,
    Options: {
      TSE: {
        Name: 'TSE',
        ResourceKey: 'Exchange_TS',
        Expression: '(Listed_Exchange eq 166)',
        Pattern: '^166,.*$'
      },
      TSE_P: {
        Name: 'TSE_P',
        ResourceKey: 'Exchange_TS_0111',
        Expression:
          "(Listed_Exchange eq 166 AND (SectionClassCode eq '0111' OR SectionClassCode eq '0113'))",
        Pattern: '^166,011[13]$'
      },
      TSE_S: {
        Name: 'TSE_S',
        ResourceKey: 'Exchange_TS_0112',
        Expression:
          "(Listed_Exchange eq 166 AND (SectionClassCode eq '0112' OR SectionClassCode eq '0121'))",
        Pattern: '^166,01(12|21)$'
      },
      TSE_G: {
        Name: 'TSE_G',
        ResourceKey: 'Exchange_TS_0114',
        Expression:
          "(Listed_Exchange eq 166 AND (SectionClassCode eq '0114' OR SectionClassCode eq '0118'))",
        Pattern: '^166,011[48]$'
      },
      NSE: {
        Name: 'NSE',
        ResourceKey: 'Exchange_NG',
        Expression: '(Listed_Exchange eq 11)',
        Pattern: '^11,.*$'
      },
      FSE: {
        Name: 'FSE',
        ResourceKey: 'Exchange_FK',
        Expression: '(Listed_Exchange eq 7)',
        Pattern: '^7,.*$'
      },
      SSE: {
        Name: 'SSE',
        ResourceKey: 'Exchange_SP',
        Expression: '(Listed_Exchange eq 14)',
        Pattern: '^14,.*$'
      }
    },
    Output: 0,
    Units: 0,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  Sector: {
    Name: 'Sector',
    ResourceKey: 'Quote_Label_Sector',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'IndustryCode',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 3,
    Options: {
      1050: {
        Name: '1050',
        ResourceKey: 'Scan_Sector_1050',
        Expression: "(IndustryCode eq '1050')",
        Pattern: '^1050$'
      },
      2050: {
        Name: '2050',
        ResourceKey: 'Scan_Sector_2050',
        Expression: "(IndustryCode eq '2050')",
        Pattern: '^2050$'
      },
      3050: {
        Name: '3050',
        ResourceKey: 'Scan_Sector_3050',
        Expression: "(IndustryCode eq '3050')",
        Pattern: '^3050$'
      },
      3100: {
        Name: '3100',
        ResourceKey: 'Scan_Sector_3100',
        Expression: "(IndustryCode eq '3100')",
        Pattern: '^3100$'
      },
      3150: {
        Name: '3150',
        ResourceKey: 'Scan_Sector_3150',
        Expression: "(IndustryCode eq '3150')",
        Pattern: '^3150$'
      },
      3200: {
        Name: '3200',
        ResourceKey: 'Scan_Sector_3200',
        Expression: "(IndustryCode eq '3200')",
        Pattern: '^3200$'
      },
      3250: {
        Name: '3250',
        ResourceKey: 'Scan_Sector_3250',
        Expression: "(IndustryCode eq '3250')",
        Pattern: '^3250$'
      },
      3300: {
        Name: '3300',
        ResourceKey: 'Scan_Sector_3300',
        Expression: "(IndustryCode eq '3300')",
        Pattern: '^3300$'
      },
      3350: {
        Name: '3350',
        ResourceKey: 'Scan_Sector_3350',
        Expression: "(IndustryCode eq '3350')",
        Pattern: '^3350$'
      },
      3400: {
        Name: '3400',
        ResourceKey: 'Scan_Sector_3400',
        Expression: "(IndustryCode eq '3400')",
        Pattern: '^3400$'
      },
      3450: {
        Name: '3450',
        ResourceKey: 'Scan_Sector_3450',
        Expression: "(IndustryCode eq '3450')",
        Pattern: '^3450$'
      },
      3500: {
        Name: '3500',
        ResourceKey: 'Scan_Sector_3500',
        Expression: "(IndustryCode eq '3500')",
        Pattern: '^3500$'
      },
      3550: {
        Name: '3550',
        ResourceKey: 'Scan_Sector_3550',
        Expression: "(IndustryCode eq '3550')",
        Pattern: '^3550$'
      },
      3600: {
        Name: '3600',
        ResourceKey: 'Scan_Sector_3600',
        Expression: "(IndustryCode eq '3600')",
        Pattern: '^3600$'
      },
      3650: {
        Name: '3650',
        ResourceKey: 'Scan_Sector_3650',
        Expression: "(IndustryCode eq '3650')",
        Pattern: '^3650$'
      },
      3700: {
        Name: '3700',
        ResourceKey: 'Scan_Sector_3700',
        Expression: "(IndustryCode eq '3700')",
        Pattern: '^3700$'
      },
      3750: {
        Name: '3750',
        ResourceKey: 'Scan_Sector_3750',
        Expression: "(IndustryCode eq '3750')",
        Pattern: '^3750$'
      },
      3800: {
        Name: '3800',
        ResourceKey: 'Scan_Sector_3800',
        Expression: "(IndustryCode eq '3800')",
        Pattern: '^3800$'
      },
      4050: {
        Name: '4050',
        ResourceKey: 'Scan_Sector_4050',
        Expression: "(IndustryCode eq '4050')",
        Pattern: '^4050$'
      },
      5050: {
        Name: '5050',
        ResourceKey: 'Scan_Sector_5050',
        Expression: "(IndustryCode eq '5050')",
        Pattern: '^5050$'
      },
      5100: {
        Name: '5100',
        ResourceKey: 'Scan_Sector_5100',
        Expression: "(IndustryCode eq '5100')",
        Pattern: '^5100$'
      },
      5150: {
        Name: '5150',
        ResourceKey: 'Scan_Sector_5150',
        Expression: "(IndustryCode eq '5150')",
        Pattern: '^5150$'
      },
      5200: {
        Name: '5200',
        ResourceKey: 'Scan_Sector_5200',
        Expression: "(IndustryCode eq '5200')",
        Pattern: '^5200$'
      },
      5250: {
        Name: '5250',
        ResourceKey: 'Scan_Sector_5250',
        Expression: "(IndustryCode eq '5250')",
        Pattern: '^5250$'
      },
      6050: {
        Name: '6050',
        ResourceKey: 'Scan_Sector_6050',
        Expression: "(IndustryCode eq '6050')",
        Pattern: '^6050$'
      },
      6100: {
        Name: '6100',
        ResourceKey: 'Scan_Sector_6100',
        Expression: "(IndustryCode eq '6100')",
        Pattern: '^6100$'
      },
      7050: {
        Name: '7050',
        ResourceKey: 'Scan_Sector_7050',
        Expression: "(IndustryCode eq '7050')",
        Pattern: '^7050$'
      },
      7100: {
        Name: '7100',
        ResourceKey: 'Scan_Sector_7100',
        Expression: "(IndustryCode eq '7100')",
        Pattern: '^7100$'
      },
      7150: {
        Name: '7150',
        ResourceKey: 'Scan_Sector_7150',
        Expression: "(IndustryCode eq '7150')",
        Pattern: '^7150$'
      },
      7200: {
        Name: '7200',
        ResourceKey: 'Scan_Sector_7200',
        Expression: "(IndustryCode eq '7200')",
        Pattern: '^7200$'
      },
      8050: {
        Name: '8050',
        ResourceKey: 'Scan_Sector_8050',
        Expression: "(IndustryCode eq '8050')",
        Pattern: '^8050$'
      },
      9050: {
        Name: '9050',
        ResourceKey: 'Scan_Sector_9050',
        Expression: "(IndustryCode eq '9050')",
        Pattern: '^9050$'
      },
      '0050': {
        Name: '0050',
        ResourceKey: 'Scan_Sector_0050',
        Expression: "(IndustryCode eq '0050')",
        Pattern: '^0050$'
      }
    },
    Output: 0,
    Units: 0,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  InvestmentAmount: {
    Name: 'InvestmentAmount',
    ResourceKey: 'Scan_Field_InvestmentAmount',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'UnitOfTrade MUL Close',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  LotSize: {
    Name: 'LotSize',
    ResourceKey: 'Fund_Label_LotSize',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'UnitOfTrade',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 6,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  MarketCap: {
    Name: 'MarketCap',
    ResourceKey: 'Fund_Label_MarketCap',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: '(CAC_OUTS2 mul Close) div 1000000',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 100000000,
    Precision: 1
  },
  NetSales: {
    Name: 'NetSales',
    ResourceKey: 'Fund_Label_NetSales',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Sales',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 100000000,
    Precision: 0
  },
  NetSalesChange: {
    Name: 'NetSalesChange',
    ResourceKey: 'Fund_Label_NetSalesChange',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Sales_YoY',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 2
  },
  OrdinaryProfit: {
    Name: 'OrdinaryProfit',
    ResourceKey: 'Fund_Label_OrdinaryProfit',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_RP',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 100000000,
    Precision: 0
  },
  OrdinaryProfitChange: {
    Name: 'OrdinaryProfitChange',
    ResourceKey: 'Fund_Label_OrdinaryProfitChange',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_RP_YoY',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 2
  },
  OrdinaryProfitToNetSalesRatio: {
    Name: 'OrdinaryProfitToNetSalesRatio',
    ResourceKey: 'Fund_Label_OrdinaryProfitToNetSalesRatio',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_RP_Sales_Ratio',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  OrdinaryProfitVsPredictedProgress: {
    Name: 'OrdinaryProfitVsPredictedProgress',
    ResourceKey: 'Fund_Label_OrdinaryProfitVsPredictedProgress',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_RP_EstProg',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  NetProfit: {
    Name: 'NetProfit',
    ResourceKey: 'Fund_Label_NetProfit',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_NP',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 100000000,
    Precision: 0
  },
  NetProfitChange: {
    Name: 'NetProfitChange',
    ResourceKey: 'Fund_Label_NetProfitChange',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_NP_YoY',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 2
  },
  ReturnOnEquity: {
    Name: 'ReturnOnEquity',
    ResourceKey: 'Fund_Label_ROE',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_ROE',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  ReturnOnAssets: {
    Name: 'ReturnOnAssets',
    ResourceKey: 'Fund_Label_ROA',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_ROA',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  EarningsPerShare: {
    Name: 'EarningsPerShare',
    ResourceKey: 'Fund_Label_EPS',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_EPS',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  PriceEarningsRatio: {
    Name: 'PriceEarningsRatio',
    ResourceKey: 'Fund_Label_PER',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_PER',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 4,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  PriceSalesRatio: {
    Name: 'PriceSalesRatio',
    ResourceKey: 'Fund_Label_PSR',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_PSR',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 4,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  PriceCashFlowRatio: {
    Name: 'PriceCashFlowRatio',
    ResourceKey: 'Fund_Label_PCFR',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_PCFR',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 4,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  PredictedNetSales: {
    Name: 'PredictedNetSales',
    ResourceKey: 'Fund_Label_PredictedNetSales',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CE_Sales',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 100000000,
    Precision: 0
  },
  PredictedNetSalesChange: {
    Name: 'PredictedNetSalesChange',
    ResourceKey: 'Fund_Label_PredictedNetSalesChange',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CE_Sales_YoY',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 2
  },
  PredictedOrdinaryProfit: {
    Name: 'PredictedOrdinaryProfit',
    ResourceKey: 'Fund_Label_PredictedOrdinaryProfit',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CE_RP',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 100000000,
    Precision: 0
  },
  PredictedOrdinaryProfitChange: {
    Name: 'PredictedOrdinaryProfitChange',
    ResourceKey: 'Fund_Label_PredictedOrdinaryProfitChange',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CE_RP_YoY',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 2
  },
  PredictedNetProfit: {
    Name: 'PredictedNetProfit',
    ResourceKey: 'Fund_Label_PredictedNetProfit',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CE_NP',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 100000000,
    Precision: 0
  },
  PredictedNetProfitChange: {
    Name: 'PredictedNetProfitChange',
    ResourceKey: 'Fund_Label_PredictedNetProfitChange',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CE_NP_YoY',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 2
  },
  PredictedEarningsPerShare: {
    Name: 'PredictedEarningsPerShare',
    ResourceKey: 'Fund_Label_PredictedEPS',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CE_EPS',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  PredictedPriceEarningsRatio: {
    Name: 'PredictedPriceEarningsRatio',
    ResourceKey: 'Fund_Label_PredictedPER',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CE_PER',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 4,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  PredictedPriceSalesRatio: {
    Name: 'PredictedPriceSalesRatio',
    ResourceKey: 'Fund_Label_PredictedPSR',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CE_PSR',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 4,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  TotalAssets: {
    Name: 'TotalAssets',
    ResourceKey: 'Fund_Label_TotalAssets',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Total_Asset',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 100000000,
    Precision: 0
  },
  NetAssets: {
    Name: 'NetAssets',
    ResourceKey: 'Fund_Label_NetAssets',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Net_Asset',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 100000000,
    Precision: 0
  },
  CapitalAdequacyRatio: {
    Name: 'CapitalAdequacyRatio',
    ResourceKey: 'Fund_Label_CapitalAdequacyRatio',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Capital_Ratio',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  BPS: {
    Name: 'BPS',
    ResourceKey: 'Fund_Label_BPS',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_BPS',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  PBR: {
    Name: 'PBR',
    ResourceKey: 'Fund_Label_PBR',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_PBR',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 4,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  DebtEquityRatio: {
    Name: 'DebtEquityRatio',
    ResourceKey: 'Fund_Label_DebtEquityRatio',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Debt_Equity_Ratio',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  CurrentRatio: {
    Name: 'CurrentRatio',
    ResourceKey: 'Fund_Label_CurrentRatio',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Current_Ratio',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  QuickRatio: {
    Name: 'QuickRatio',
    ResourceKey: 'Fund_Label_QuickRatio',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Acid_Ratio',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  ReceivablesTurnoverRate: {
    Name: 'ReceivablesTurnoverRate',
    ResourceKey: 'Fund_Label_ReceivablesTurnoverRate',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Receivable_Turnover_Rate',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 4,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  InventoryTurnoverRate: {
    Name: 'InventoryTurnoverRate',
    ResourceKey: 'Fund_Label_InventoryTurnoverRate',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Inventory_Turnover_Rate',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 4,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  DividendPerShare: {
    Name: 'DividendPerShare',
    ResourceKey: 'Fund_Label_DPS',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_DPS',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  DividendPerShareForecast: {
    Name: 'DividendPerShareForecast',
    ResourceKey: 'Fund_Label_PredictedDPS',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CE_DPS',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  DividendYield: {
    Name: 'DividendYield',
    ResourceKey: 'Fund_Label_DividendYield',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Dividend_yield',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  DividendYieldForecast: {
    Name: 'DividendYieldForecast',
    ResourceKey: 'Fund_Label_PredictedYield',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CE_Dividend_yield',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  DividendPayoutRatio: {
    Name: 'DividendPayoutRatio',
    ResourceKey: 'Fund_Label_DividendPayoutRatio',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Dividend_payout_ratio',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  DividendOnEquity: {
    Name: 'DividendOnEquity',
    ResourceKey: 'Fund_Label_DividendOnEquity',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CR_Dividend_on_equity',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  MarginType: {
    Name: 'MarginType',
    ResourceKey: 'Fund_Label_MarginType',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CB_LBTR',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 3,
    Options: {
      1: {
        Name: '1',
        ResourceKey: 'Scan_MarginTradingType_1',
        Expression: '(CB_LBTR eq 1)',
        Pattern: '^1$'
      },
      2: {
        Name: '2',
        ResourceKey: 'Scan_MarginTradingType_2',
        Expression: '(CB_LBTR eq 2)',
        Pattern: '^2$'
      },
      3: {
        Name: '3',
        ResourceKey: 'Scan_MarginTradingType_3',
        Expression: '(CB_LBTR eq 3)',
        Pattern: '^3$'
      }
    },
    Output: 0,
    Units: 0,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  SharesSoldOnMargin: {
    Name: 'SharesSoldOnMargin',
    ResourceKey: 'Fund_Label_MarginBalance_Sold',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CB_MTBCSS',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 6,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  ChangeInSharesSoldOnMargin: {
    Name: 'ChangeInSharesSoldOnMargin',
    ResourceKey: 'Fund_Label_MarginBalance_Sold_Change',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CB_MTBCSS1WD',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 6,
    IsChange: true,
    Magnitude: 1,
    Precision: 0
  },
  SharesBoughtOnMargin: {
    Name: 'SharesBoughtOnMargin',
    ResourceKey: 'Fund_Label_MarginBalance_Bought',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CB_MTBCSB',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 6,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  ChangeInSharesBoughtOnMargin: {
    Name: 'ChangeInSharesBoughtOnMargin',
    ResourceKey: 'Fund_Label_MarginBalance_Bought_Change',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CB_MTBCSB1WD',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 6,
    IsChange: true,
    Magnitude: 1,
    Precision: 0
  },
  MarginBuySellRatio: {
    Name: 'MarginBuySellRatio',
    ResourceKey: 'Fund_Label_MarginBuySellRatio',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CB_MTBCSB div CB_MTBCSS',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 4,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  MarginLendingFee: {
    Name: 'MarginLendingFee',
    ResourceKey: 'Fund_Label_LendingFee',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'OLF_Lending_fee',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 1,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  MarginTurnoverPeriod: {
    Name: 'MarginTurnoverPeriod',
    ResourceKey: 'Fund_Label_MarginTurnoverPeriod',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'OLE_Total_turnover_period',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 2,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  TotalScore: {
    Name: 'TotalScore',
    ResourceKey: 'Fund_Label_TotalScore',
    AlternateResourceKey: 'Fund_Label_TotalScore_Short',
    IsPublic: true,
    SourceField: 'SC_Total_Score MUL 10',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 5,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  GrowthScore: {
    Name: 'GrowthScore',
    ResourceKey: 'Fund_Label_GrowthScore',
    AlternateResourceKey: 'Fund_Label_GrowthScore_Short',
    IsPublic: true,
    SourceField: 'SC_Growth_Score MUL 10',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 5,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  ValueScore: {
    Name: 'ValueScore',
    ResourceKey: 'Fund_Label_ValueScore',
    AlternateResourceKey: 'Fund_Label_ValueScore_Short',
    IsPublic: true,
    SourceField: 'SC_UnderValued_Score MUL 10',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 5,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  SizeScore: {
    Name: 'SizeScore',
    ResourceKey: 'Fund_Label_SizeScore',
    AlternateResourceKey: 'Fund_Label_SizeScore_Short',
    IsPublic: true,
    SourceField: 'SC_CompanyScale_Score MUL 10',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 5,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  TechnicalScore: {
    Name: 'TechnicalScore',
    ResourceKey: 'Fund_Label_TechnicalScore',
    AlternateResourceKey: 'Fund_Label_TechnicalScore_Short',
    IsPublic: true,
    SourceField: 'SC_Technical_Score MUL 10',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 5,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  FiscalHealthScore: {
    Name: 'FiscalHealthScore',
    ResourceKey: 'Fund_Label_FiscalHealthScore',
    AlternateResourceKey: 'Fund_Label_FiscalHealthScore_Short',
    IsPublic: true,
    SourceField: 'SC_FinancialHealth_Score MUL 10',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 5,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  MarketTrendScore: {
    Name: 'MarketTrendScore',
    ResourceKey: 'Fund_Label_MarketTrendScore',
    AlternateResourceKey: 'Fund_Label_MarketTrendScore_Short',
    IsPublic: true,
    SourceField: 'SC_Revision_Score MUL 10',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 5,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  Rating: {
    Name: 'Rating',
    ResourceKey: 'Fund_Label_Rating_Short',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CDR_cons_rating',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 0,
    IsChange: false,
    Magnitude: 1,
    Precision: 2
  },
  AnalystCount: {
    Name: 'AnalystCount',
    ResourceKey: 'Fund_Label_AnalystCount',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField:
      '(CDR_n_S_sell add CDR_n_sell add CDR_n_hold add CDR_n_buy add CDR_n_S_buy)',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 0,
    IsChange: false,
    Magnitude: 1,
    Precision: 0
  },
  TargetPriceChange: {
    Name: 'TargetPriceChange',
    ResourceKey: 'Fund_Label_TargetPriceChange',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: 'CDR_cons_targetprice_1wchange',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 0
  },
  TargetPriceVsCurrentPrice: {
    Name: 'TargetPriceVsCurrentPrice',
    ResourceKey: 'Fund_Label_TargetPriceVsCurrentPrice',
    AlternateResourceKey: null,
    IsPublic: true,
    SourceField: '((CDR_cons_targetprice sub Close) div Close) mul 100',
    ValueIndex: null,
    Source: 0,
    ReservoirEntity: null,
    Input: 0,
    Options: [],
    Output: 1,
    Units: 3,
    IsChange: true,
    Magnitude: 1,
    Precision: 0
  },
  SMADeviation_5: {
    Name: 'SMADeviation_5',
    ResourceKey: 'Scan_Technical_5DaySMADeviation',
    Source: 1,
    SourceField: 'Deviation',
    Input: 0,
    Units: 3,
    IsChange: true,
    Precision: 2
  },
  SMADeviation_25: {
    Name: 'SMADeviation_25',
    ResourceKey: 'Scan_Technical_25DaySMADeviation',
    Source: 1,
    SourceField: 'Deviation',
    Input: 0,
    Units: 3,
    IsChange: true,
    Precision: 2
  },
  SMADeviation_65: {
    Name: 'SMADeviation_65',
    ResourceKey: 'Scan_Technical_13WeekSMADeviation',
    Source: 1,
    SourceField: 'Deviation',
    Input: 0,
    Units: 3,
    IsChange: true,
    Precision: 2
  },
  SMADeviation_130: {
    Name: 'SMADeviation_130',
    ResourceKey: 'Scan_Technical_26WeekSMADeviation',
    Source: 1,
    SourceField: 'Deviation',
    Input: 0,
    Units: 3,
    IsChange: true,
    Precision: 2
  },
  RSI_14: {
    Name: 'RSI_14',
    ResourceKey: 'Scan_Technical_RSI',
    Source: 1,
    SourceField: 'Index',
    Input: 0,
    Units: 3,
    IsChange: false,
    Precision: 2
  },
  StochasticFast_9: {
    Name: 'StochasticFast_9',
    ResourceKey: 'Scan_Technical_StochasticsFastNineD',
    ColumnResourceKey: 'Scan_Technical_StochasticsFastNineD_ColumnHeader',
    Source: 1,
    SourceField: 'D',
    Input: 0,
    Units: 3,
    IsChange: false,
    Precision: 2
  },
  StochasticSlow_9: {
    Name: 'StochasticSlow_9',
    ResourceKey: 'Scan_Technical_StochasticsSlowNineD',
    ColumnResourceKey: 'Scan_Technical_StochasticsSlowNineD_ColumnHeader',
    Source: 1,
    SourceField: 'D',
    Input: 0,
    Units: 3,
    IsChange: false,
    Precision: 2
  },
  IchimokuCloud: {
    Name: 'IchimokuCloud',
    ResourceKey: 'Scan_Technical_Ichimoku',
    ColumnResourceKey: 'Scan_Technical_Ichimoku_ColumnHeader',
    Source: 1,
    SourceField: 'LeadingA,LeadingB,Position',
    ValueIndex: 2,
    Input: 2,
    Output: 4,
    Options: {
      Above: {
        Name: 'Above',
        ResourceKey: 'Scan_Technical_Ichimoku_Above',
        Pattern: '^Above$'
      },
      Within: {
        Name: 'Within',
        ResourceKey: 'Scan_Technical_Ichimoku_Within',
        Pattern: '^Within$'
      },
      Below: {
        Name: 'Below',
        ResourceKey: 'Scan_Technical_Ichimoku_Below',
        Pattern: '^Below$'
      }
    },
    Units: 1
  },
  SMACrossover_5_25: {
    Name: 'SMACrossover_5_25',
    ResourceKey: 'Scan_Technical_5Day25DayBullishCrossover',
    ColumnResourceKey: 'Scan_Technical_5Day25DayBullishCrossover_ColumnHeader',
    Source: 1,
    SourceField: 'CrossoverDate,CrossoverClose,CrossoverDaysAgo',
    ValueIndex: 2,
    Input: 0,
    Output: 3,
    Units: 1
  },
  SMACrossover_25_5: {
    Name: 'SMACrossover_25_5',
    ResourceKey: 'Scan_Technical_5Day25DayBearishCrossover',
    ColumnResourceKey: 'Scan_Technical_5Day25DayBearishCrossover_ColumnHeader',
    Source: 1,
    SourceField: 'CrossoverDate,CrossoverClose,CrossoverDaysAgo',
    ValueIndex: 2,
    Input: 0,
    Output: 3,
    Units: 1
  },
  ATR_14: {
    Name: 'ATR_14',
    ResourceKey: 'Scan_Technical_ATR',
    Source: 1,
    SourceField: 'Atr',
    Input: 0,
    Units: 3,
    IsChange: false,
    Precision: 2
  },
  MFI_14: {
    Name: 'MFI_14',
    ResourceKey: 'Scan_Technical_MFI',
    Source: 1,
    SourceField: 'Mfi',
    Input: 0,
    Units: 3,
    IsChange: false,
    Precision: 2
  },
  RCI_10_3: {
    Name: 'RCI_10_3',
    ResourceKey: 'Scan_Technical_RCI',
    Source: 1,
    SourceField: 'Rci',
    Input: 0,
    Units: 3,
    IsChange: false,
    Precision: 2
  },
  CCI_14_9: {
    Name: 'CCI_14_9',
    ResourceKey: 'Scan_Technical_CCI',
    Source: 1,
    SourceField: 'Cci',
    Input: 0,
    Units: 3,
    IsChange: false,
    Precision: 2
  }
};

export const scanCategoryMapping = [
  {
    Name: 'Basic',
    ResourceKey: 'Scan_Category_Basic',
    Fields: [
      // "Close",
      // "NetChange",
      // "NetChangePct",
      // "Volume",
      // "Turnover",
      // "52WeekHigh",
      // "52WeekHighChangePct",
      // "High",
      // "52WeekLow",
      // "52WeekLowChangePct",
      // "Low",
      // "10DayVolumeAverage",
      // "VolumeChangePct",
      // "PreviousVolume",
      // "SMACrossover_5_25_Date",
      // "SMACrossover_5_25_Close",
      // "SMACrossover_25_5_Date",
      // "SMACrossover_25_5_Close",
      'SecurityType',
      'Exchange',
      'Sector',
      'InvestmentAmount',
      'LotSize',
      'MarketCap'
    ]
  },
  {
    Name: 'Performance',
    ResourceKey: 'Scan_Category_Performance',
    Fields: [
      'NetSales',
      'NetSalesChange',
      'OrdinaryProfit',
      'OrdinaryProfitChange',
      'OrdinaryProfitToNetSalesRatio',
      'OrdinaryProfitVsPredictedProgress',
      'NetProfit',
      'NetProfitChange',
      'ReturnOnEquity',
      'ReturnOnAssets',
      'EarningsPerShare',
      'PriceEarningsRatio',
      'PriceSalesRatio',
      'PriceCashFlowRatio'
    ]
  },
  {
    Name: 'Predictions',
    ResourceKey: 'Scan_Category_Predictions',
    Fields: [
      'PredictedNetSales',
      'PredictedNetSalesChange',
      'PredictedOrdinaryProfit',
      'PredictedOrdinaryProfitChange',
      'PredictedNetProfit',
      'PredictedNetProfitChange',
      'PredictedEarningsPerShare',
      'PredictedPriceEarningsRatio',
      'PredictedPriceSalesRatio'
    ]
  },
  {
    Name: 'AssetsLiabilities',
    ResourceKey: 'Scan_Category_AssetsLiabilities',
    Fields: [
      'TotalAssets',
      'NetAssets',
      'CapitalAdequacyRatio',
      'BPS',
      'PBR',
      'DebtEquityRatio',
      'CurrentRatio',
      'QuickRatio',
      'ReceivablesTurnoverRate',
      'InventoryTurnoverRate'
    ]
  },
  {
    Name: 'Dividends',
    ResourceKey: 'Scan_Category_Dividends',
    Fields: [
      'DividendPerShare',
      'DividendPerShareForecast',
      'DividendYield',
      'DividendYieldForecast',
      'DividendPayoutRatio',
      'DividendOnEquity'
    ]
  },
  {
    Name: 'Margin',
    ResourceKey: 'Scan_Category_Margin',
    Fields: [
      'MarginType',
      'SharesSoldOnMargin',
      'ChangeInSharesSoldOnMargin',
      'SharesBoughtOnMargin',
      'ChangeInSharesBoughtOnMargin',
      'MarginBuySellRatio',
      'MarginLendingFee',
      'MarginTurnoverPeriod'
    ]
  },
  {
    Name: 'Score',
    ResourceKey: 'Scan_Category_Scores',
    Fields: [
      'TotalScore',
      'GrowthScore',
      'ValueScore',
      'SizeScore',
      'TechnicalScore',
      'FiscalHealthScore',
      'MarketTrendScore'
    ]
  },
  {
    Name: 'AnalystConsensus',
    ResourceKey: 'Scan_Category_Ratings',
    Fields: [
      'Rating',
      'AnalystCount',
      'TargetPriceChange',
      'TargetPriceVsCurrentPrice'
    ]
  },
  {
    Name: 'Technical',
    ResourceKey: 'Scan_Category_Technical',
    Fields: [
      'SMADeviation_5',
      'SMADeviation_25',
      'SMADeviation_65',
      'SMADeviation_130',
      'RSI_14',
      'StochasticFast_9',
      'StochasticSlow_9',
      'IchimokuCloud',
      'SMACrossover_5_25',
      'SMACrossover_25_5',
      'ATR_14',
      'MFI_14',
      'RCI_10_3',
      'CCI_14_9'
    ]
  }
];
