import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { initWebSocket, start, stop } from '../../actions/stream';
import { fetchSymbolIfNeeded } from '../../actions/symbols';
import { injectIntl } from 'react-intl';
import { calcNetChgPct } from '../../utils/maths';
import Number from '../common/Number';
import Exchange from '../common/Exchange';
import { getSymbolClose } from '../../utils/symbol';

class ScanResultSymbolItem extends React.PureComponent {
  componentDidMount() {
    this.props.actions.getSymbol(this.props.result.symbol);
    this.props.actions.start(this.props.result.symbol + '/quote');
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this.props.actions.stop(this.props.result.symbol + '/quote');
  }

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  render() {
    const streamData =
      this.props.stream[`${this.props.result.symbol}/quote`] || {};
    const metaData = this.props.metadata || {};
    const assetClass = metaData.assetClass;
    const close = getSymbolClose(assetClass, streamData);

    /*let categories = Object.keys(this.props.result.data.displayValues);
    let lowerCaseKeyedCategoryData = _.transform(
      scanCategoryData,
      (r, v, k) => (r[k.toLowerCase()] = v)
    );
    // This will be used in the future (phase 2) so keep it around
    // eslint-disable-next-line
    let categoryLocaleKeys = categories.map(
      c => `scan.criteria.${lowerCaseKeyedCategoryData[c.toLowerCase()].Name}`
    );*/

    return (
      <Link to={'/symbol/' + this.props.result.symbol}>
        <div className="scan-result-symbol-container">
          <div className="scan-result-symbol-row-one">
            <span className="scan-result-symbol-name">
              {this.props.metadata.jpShortName
                ? this.props.metadata.jpShortName
                : this.props.result.symbol}
            </span>
            <Number
              className="scan-result-symbol-last"
              value={close || 0}
              precision={streamData ? streamData.precision : 2}
              hideIfZero={true}
            />
          </div>
          <div className="scan-result-symbol-row-two">
            <span className="scan-result-symbol-detail">
              {this.props.result.symbol.substring(3, 7) + ' '}
              {this.props.metadata.jpIndustry ? (
                <Exchange
                  name={this.props.metadata.exchangeName}
                  section={this.props.metadata.jpSection}
                />
              ) : (
                ' -'
              )}
              {this.props.metadata.jpIndustry
                ? ' ' +
                  this.props.intl.formatMessage({
                    id: `symbol.industry.${this.props.metadata.jpIndustry}`
                  })
                : ''}
            </span>
            <Number
              className="scan-result-symbol-net-change"
              value={calcNetChgPct(
                close || 0,
                streamData.prevClose || 0
              )}
              precision="2"
              suffix="%"
              signed={true}
            />
          </div>
        </div>
      </Link>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.user) {
    return {};
  }
  // Use spread (...) to guarantee we have a new object.
  var props = {
    ...{}
  };

  props.metadata =
    (state.symbols.data[ownProps.result.symbol] || { data: {} }).data || {};

  if (!state.stream || !state.stream.channels) {
    return {};
  }
  props.stream = { ...(state.stream.channels || {}) };

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      init: bindActionCreators(initWebSocket, dispatch),
      start: bindActionCreators(start, dispatch),
      stop: bindActionCreators(stop, dispatch),
      getSymbol: bindActionCreators(fetchSymbolIfNeeded, dispatch)
    }
  };
}

ScanResultSymbolItem = injectIntl(ScanResultSymbolItem);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanResultSymbolItem);
