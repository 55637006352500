import React from 'react';
import moment from 'moment-timezone';

// Time displays a date/time of day. The date/time is expected to be given as a string in
// the `value` prop, as well as the following boolean options: {hasZeroPad, hasYear, displayDashIfInvalid, format, prefix, suffix}.
// 'M/D HH:mm' will be applied. (refer to format in the moment.js doc)
// Returned time will always be JST.
export default class DateTime extends React.PureComponent {
  render() {
    const value = '' + this.props.value;
    let format = 'M/D HH:mm';

    if (this.props.hasZeroPad) {
      format = format.replace('M', 'MM');
      format = format.replace('D', 'DD');
    }
    if (this.props.hasYear) {
      format = `YYYY/${format}`;
    }
    if (this.props.format) {
      format = this.props.format;
    }

    if (!moment(value).isValid()) {
      // https://momentjs.com/docs/#/parsing/is-valid/
      const mdash = '\u2014';
      const invalidDisplayText = this.props.displayDashIfInvalid ? mdash : '';

      return <span className="dateTime">{invalidDisplayText}</span>;
    }

    let text = moment(value).tz('Asia/Tokyo').format(format);

    if (this.props.prefix) {
      text = this.props.prefix + text;
    }

    if (this.props.suffix) {
      text += this.props.suffix;
    }
    return <span className="dateTime">{text}</span>;
  }
}
