const {
  getStochasticOscillatorD,
  getStochasticOscillatorK
} = require('./stochasticOscillator');
const { getFixedLinesData } = require('./utils');

function getSeries(periods, bars, fullBarData, localize) {
  let result = [];
  let period = periods[0] || 14;

  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.overbought' }),
    type: 'line',
    color: '#ff2800',
    yAxis: 'stochasticFast',
    data: getFixedLinesData(bars, 80),
    tooltip: { yDecimals: 2 },
    zIndex: 4
  });

  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.oversold' }),
    type: 'line',
    color: '#0055cc',
    yAxis: 'stochasticFast',
    data: getFixedLinesData(bars, 20),
    tooltip: { yDecimals: 2 },
    zIndex: 3
  });

  result.push({
    id: 'StochasticFastK',
    name: localize({ id: 'symbol.details.chart.tooltip.fastK' }),
    type: 'line',
    color: '#008c56',
    yAxis: 'stochasticFast',
    data: getStochasticOscillatorK(bars, fullBarData, period),
    tooltip: { yDecimals: 2 },
    zIndex: 1
  });

  result.push({
    id: 'StochasticFastD',
    name: localize({ id: 'symbol.details.chart.tooltip.fastD' }),
    type: 'line',
    color: '#EFB84D',
    yAxis: 'stochasticFast',
    data: getStochasticOscillatorD(bars, fullBarData, period),
    tooltip: { yDecimals: 2 },
    zIndex: 2
  });

  return result;
}

module.exports = {
  getSeries: getSeries
};
