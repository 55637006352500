import { getSymbolRoot, getSymbolExchange, isJapaneseEquity, isJpeq, isUseq } from './symbol';
import Logger from './logger';
import {
  getSometaroNksolCashOrderUrl,
  getSometaroNksolDefaultMarginOrderUrl,
  getSometaroNksolDefaultMarginRefundUrl,
  getSometaroNksolSingleShareOrderUrl
} from './builders/nksolUrlBuilder';
import { isMonexUser } from './common';
import {
  getSometaroMonex11CashOrderUrl,
  getSometaroMonex11DefaultMarginOrderUrl,
  getSometaroMonex11DefaultMarginRefundUrl,
  getSometaroMonex11SingleShareOrderUrl
} from './builders/monex11UrlBuilder';

const API_URL =
  process.env.REACT_APP_INSIGHT_API_DOMAIN || 'api.monexinsight.net';

const USEQ_TRADING_URL_DESKTOP =
  process.env.REACT_APP_USEQ_TRADING_DOMAIN_DESKTOP || 'ik.monexinsight-dev.net';

  const USEQ_TRADING_URL_MOBILE =
  process.env.REACT_APP_USEQ_TRADING_DOMAIN_MOBILE || 'ikm.monexinsight-dev.net';

const exchangeMap = {
  TS: 1,
  NG: 3,
  FK: 6,
  SP: 8
};

function addParameter(url, param, value) {
  if (!!value) {
    // eslint-disable-next-line
    url += (url.match(/[\?]/g) ? '&' : '?') + `${param}=${value}`;
  }
  return url;
}

export function symbolExchange(symbolId) {
  return exchangeMap[getSymbolExchange(symbolId)];
}

export function symbolRoot(symbolId) {
  return String(getSymbolRoot(symbolId) + '0').slice(0, 5);
}

export function getCashBuyUrl(dm, attrSrcKey, symbolId) {
  if (!isJapaneseEquity(symbolId)) {
    return;
  }

  let ex = symbolExchange(symbolId);
  if (!ex) {
    Logger.error(
      `Cannot generate CASH BUY URL as exchange for symbol [${symbolId}] not found`
    );
    return null;
  }
  let symbol = symbolRoot(symbolId);
  return (
    'https://' +
    dm +
    '.monex.co.jp/pc/servlet/ITS/stock/StkBuyOrder?site=insight&attrSrcKey=' +
    attrSrcKey +
    '&dscrCD=' +
    symbol +
    '&marketCDOrder=' +
    ex +
    '&orderNari=1'
  );
}

export function getCashSellUrl(dm, attrSrcKey, symbolId) {
  let symbol = symbolRoot(symbolId);
  return (
    'https://' +
    dm +
    '.monex.co.jp/pc/servlet/ITS/stock/StkHavingList?site=insight&attrSrcKey=' +
    attrSrcKey +
    '&dscrCD=' +
    symbol
  );
}

// https://{subdomain}.monex.co.jp/pc/servlet/ITS/stock/MrgNewOrder?site=insight&attrSrcKey={attrSrcKey}&dscrCD={symbol}&marketCDOrder={exchange}

export function getNewMarginBuyUrl(dm, attrSrcKey, symbolId) {
  if (!isJapaneseEquity(symbolId)) {
    return;
  }

  let ex = symbolExchange(symbolId);
  if (!ex) {
    Logger.error(
      `Cannot generate MARGIN BUY URL as exchange for symbol [${symbolId}] not found`
    );
    return null;
  }
  let symbol = symbolRoot(symbolId);
  return (
    'https://' +
    dm +
    '.monex.co.jp/pc/servlet/ITS/stock/MrgNewOrder?site=insight&attrSrcKey=' +
    attrSrcKey +
    '&dscrCD=' +
    symbol +
    '&marketCDOrder=' +
    ex
  );
}

export function getMarginRefundUrl(dm, attrSrcKey, symbolId) {
  let symbol = symbolRoot(symbolId);
  return (
    'https://' +
    dm +
    '.monex.co.jp/pc/servlet/ITS/stock/MrgRefundList?site=insight&attrSrcKey=' +
    attrSrcKey +
    '&dscrCD=' +
    symbol
  );
}

// https://{subdomain}.monex.co.jp/pc/servlet/ITS/login/TransitionSsoForInternal?page=integrate&path=/servlet/ITS/stkloan/HospitalityImageDetailMember?dscrCd={symbol}&attrSrcKey={attrSrcKey}&site=insight

export function getShareholdersIncentivesUrl(dm, attrSrcKey, symbolId) {
  let symbol = symbolRoot(symbolId);
  return (
    'https://' +
    dm +
    '.monex.co.jp/pc/servlet/ITS/login/TransitionSsoForInternal?page=integrate&path=/servlet/ITS/stkloan/HospitalityImageDetailMember?dscrCd=' +
    symbol +
    '&attrSrcKey=' +
    attrSrcKey +
    '&site=insight'
  );
}

export function getFinancialReportUrl(symbolId, ifisHash, encryptedId) {
  let symbol = getSymbolRoot(symbolId).substr(0, 4);
  return `https://monex.ifis.co.jp/index.php?action=tp2&sa=report_zaimu&bcode=${symbol}&hc=${ifisHash}&id=${encryptedId}`;
}

export function getUseqFinancialReportUrl(dm, symbolId) {
  return (
    'https://' +
    dm +
    '.monex.co.jp/pc/ITS/login/LoginIDPassword.jsp?transKbn=2&url1=/servlet/ITS/common/ForeignStkSiteConnect?serviceType=3&scouterType=4&tickerName=' +
    symbolId
  );
}

export function getShikihoQuarterlyReportUrl(symbolId, dailyHash, encryptedId) {
  let symbol = getSymbolRoot(symbolId).substr(0, 4);
  return `https://monex-shikiho.kabumap.com/services/Query?SRC=monex_shikiho/shikiho1&hash=${dailyHash}&id=${encryptedId}&QCODE=${symbol}`;
}

// https://{subdomain}.monex.co.jp/pc/servlet/ITS/report/AnalystBackNumber?searchDscrCode1={symbol}&searchFlg=true

export function getAnalystReportUrl(dm, symbolId) {
  let symbol = symbolRoot(symbolId);
  return (
    'https://' +
    dm +
    '.monex.co.jp/pc/servlet/ITS/report/AnalystBackNumber?searchDscrCode1=' +
    symbol +
    '&searchFlg=true'
  );
}

// https://mxp3.monex.co.jp/pc/servlet/ITS/stock/MrgRefundList?site=insight&attrSrcKey=1677c7750b48&dscrCD=79740

export function getMarginRefundUrlSymbolDetails(dm, attrSrcKey, symbolId) {
  let symbol = symbolRoot(symbolId);
  return (
    'https://' +
    dm +
    '.monex.co.jp/pc/servlet/ITS/stock/MrgRefundList?site=insight&attrSrcKey=' +
    attrSrcKey +
    '&dscrCD=' +
    symbol
  );
}

// https://mxp3.monex.co.jp/pc/servlet/ITS/stock/StkHakabuBuyOrder?site=insight&attrSrcKey=1677c7750b48&dscrCD=79740

export function getSmallLotOrderUrl(dm, attrSrcKey, symbolId) {
  let symbol = symbolRoot(symbolId);
  return (
    'https://' +
    dm +
    '.monex.co.jp/pc/servlet/ITS/stock/StkHakabuBuyOrder?site=insight&attrSrcKey=' +
    attrSrcKey +
    '&dscrCD=' +
    symbol
  );
}

// Example
// https://mxp3.monex.co.jp/pc/servlet/ITS/home/Announce?attrSrcKey=16c880ba8f68
export function getHomeLink(dm, attrSrcKey) {
  let url = `https://${!!dm ? dm : 'www'
    }.monex.co.jp/pc/servlet/ITS/home/Announce`;

  url = addParameter(url, 'attrSrcKey', attrSrcKey);

  return url;
}

function getRedirectLink(encryptedId, hash) {
  if (!!encryptedId && !!hash) {
    return `https://${API_URL}/auth/redirect/${encryptedId}/${hash}`;
  }

  return 'https://insight.monex.co.jp';
}

function addTokenParameters(url, rf, dm, attrSrcKey, rz) {
  url = addParameter(url, 'rf', rf);
  url = addParameter(url, 'dm', dm);
  url = addParameter(url, 'attrSrcKey', attrSrcKey);
  url = addParameter(url, 'rz', rz);
  return url;
}

export function getSometaroLink(
  encryptedId,
  hash,
  rf,
  dm,
  attrSrcKey,
  rz,
  page = ''
) {
  let url = getRedirectLink(encryptedId, hash);
  url += !!page ? `/${page}` : '';
  return addTokenParameters(url, rf, dm, attrSrcKey, rz);
}

export function getSymbolDetailsLink(
  encryptedId,
  hash,
  symbolId,
  rf,
  dm,
  attrSrcKey,
  rz
) {
  let section = isJapaneseEquity(symbolId) ? 'overview' : 'chart';
  let page = 'symbol/' + section + '?symbol=' + symbolId;
  return getSometaroLink(encryptedId, hash, rf, dm, attrSrcKey, rz, page);
}

export function getScansLink(encryptedId, hash, rf, dm, attrSrcKey, rz) {
  return getSometaroLink(encryptedId, hash, rf, dm, attrSrcKey, rz, 'scan');
}

export function getConsensusRisingRankingsLink(ifisHash, encryptedId) {
  //http://monex.ifis.co.jp/index.php?action=tp2&sa=screenRank&hc=IFIS_HASH&id=ENCRYPTED_USER_ID
  return (
    'http://monex.ifis.co.jp/index.php?action=tp2&sa=screenRank&hc=' +
    ifisHash +
    '&id=' +
    encryptedId
  );
}

export function getToolUrl(dm, attrSrcKey) {
  return (
    'https://' +
    dm +
    '.monex.co.jp/pc/servlet/ITS/info/InfoToolList?attrSrcKey=' +
    attrSrcKey
  );
}

export function getVersionNumber(version) {
  //if version is empty or not a string, return null
  if (!version || typeof version !== 'string') {
    return null;
  }

  let versionNumber = version;

  // if versionNumber starts with the character 'v', remove it
  if (versionNumber.charAt(0) === 'v') {
    versionNumber = versionNumber.substring(1);
  }

  // if the versionNumber contains the trailing hash, starting with a dash, 
  // it is removed
  let dashIndex = versionNumber.indexOf('-');
  if (dashIndex > -1) {
    versionNumber = versionNumber.substring(0, dashIndex);
  }

  return versionNumber
}

function getMissingUserAttribute(user) {
  if (!user.dm) {
    return 'dm';
  }

  if (isMonexUser(user) && !user.attrSrcKey) {
    return 'attrSrcKey';
  }

  if (!isMonexUser(user) && !user.encryptedId) {
    return 'encryptedId';
  }

  return null;
}

export function getDesktopCashOrderUrl(user,
  symbol,
  action,
  validity = null,
  quantity = null,
  price = null) {
  if (!user) {
    Logger.error(`getDesktopCashOrderUrl: No user object`);
    return null;
  }

  let missingAttribute = getMissingUserAttribute(user);
  if (missingAttribute) {
    Logger.error(
      `getDesktopCashOrderUrl: ${missingAttribute} missing from user object`
    );
    return null;
  }

  return isMonexUser(user)
    ? getSometaroMonex11CashOrderUrl(user.dm, user.attrSrcKey, symbol, action, validity, quantity, price)
    : getSometaroNksolCashOrderUrl(user.dm, user.encryptedId, symbol, action, validity, quantity, price);
}

export function getDesktopMarginOrderUrl(user, symbol) {
  if (!user) {
    Logger.error(`getDesktopMarginOrderUrl: No user object`);
    return null;
  }

  let missingAttribute = getMissingUserAttribute(user);
  if (missingAttribute) {
    Logger.error(
      `getDesktopMarginOrderUrl: ${missingAttribute} missing from user object`
    );
    return null;
  }

  return isMonexUser(user)
    ? getSometaroMonex11DefaultMarginOrderUrl(user.dm, user.attrSrcKey, symbol)
    : getSometaroNksolDefaultMarginOrderUrl(user.dm, user.encryptedId, symbol);
}

export function getDesktopMarginRefundUrl(user, symbol) {
  if (!user) {
    Logger.error(`getDesktopMarginRefundUrl: No user object`);
    return null;
  }

  let missingAttribute = getMissingUserAttribute(user);
  if (missingAttribute) {
    Logger.error(
      `getDesktopMarginRefundUrl: ${missingAttribute} missing from user object`
    );
    return null;
  }

  return isMonexUser(user)
    ? getSometaroMonex11DefaultMarginRefundUrl(user.dm, user.attrSrcKey, symbol)
    : getSometaroNksolDefaultMarginRefundUrl(user.dm, user.encryptedId, symbol);
}

export function getDesktopSmallLotOrderUrl(user, symbol) {
  if (!user) {
    Logger.error(`getDesktopSmallLotOrderUrl: No user object`);
    return null;
  }

  let missingAttribute = getMissingUserAttribute(user);
  if (missingAttribute) {
    Logger.error(
      `getDesktopSmallLotOrderUrl: ${missingAttribute} missing from user object`
    );
    return null;
  }

  return isMonexUser(user)
    ? getSometaroMonex11SingleShareOrderUrl(user.dm, user.attrSrcKey, symbol)
    : getSometaroNksolSingleShareOrderUrl(user.dm, user.encryptedId, symbol);
}

export function getUseqTradingUrl(symbol, type, isDesktopWidth) {
  if (type !== 'buy' && type !== 'sell') {
    Logger.error(`getUseqTradingUrl: Invalid type [${type}]`);
    return null;
  }

  if (isDesktopWidth) {
    const page = type === 'buy' ? 'buy' : 'position';
    return `https://${USEQ_TRADING_URL_DESKTOP}/#/${page}/${symbol}`;
  }

  const page = type === 'buy' ? 'order' : 'positions';
  return `https://${USEQ_TRADING_URL_MOBILE}/${page}?symbol=${symbol}`;
}

export function getSymbolBuySellUrl(assetClass, symbol, user, type, isDesktopWidth) {
  if (isJpeq(assetClass)) {
    return type === 'buy'
      ? getCashBuyUrl(user.dm, user.attrSrcKey, symbol)
      : getCashSellUrl(user.dm, user.attrSrcKey, symbol);
  }

  if (isUseq(assetClass)) {
    return getUseqTradingUrl(symbol, type, isDesktopWidth);
  }

  return null;
}