const {
  getFixedLinesData,
  getRelativeStrengthIndexes,
  getFilteredBarValuesWithSameTimestamps
} = require('./utils');

function getRsiData(bars, fullBarData, period) {
  // When calculating the RSI, we need to have at least the amount of
  // bars of the period (e.g. 14) in order for the bars to have values
  // on the chart. For that reason, we first need to insert those bars
  // artificially using the close values only.
  let periodCloseBars = fullBarData.map(a => a[4]);

  // We then calculate the RSI values and leave the same amount of bars
  // to display on the chart.
  let values = getRelativeStrengthIndexes(periodCloseBars, period);
  return getFilteredBarValuesWithSameTimestamps(bars, fullBarData, values);
}

function getRsiSeries(periods, bars, fullBarData, localize) {
  if (!fullBarData) {
    return [];
  }

  let result = [];
  let period = periods[0];

  // First insert the red line for the overbought (70)
  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.overbought' }),
    type: 'line',
    color: '#ff2800',
    yAxis: 'rsi',
    data: getFixedLinesData(bars, 70),
    tooltip: { yDecimals: 2 },
    zIndex: 3
  });

  // Second insert the blue line for the oversold (30)
  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.oversold' }),
    type: 'line',
    color: '#0055cc',
    yAxis: 'rsi',
    data: getFixedLinesData(bars, 30),
    tooltip: { yDecimals: 2 },
    zIndex: 2
  });

  // Finally, calculate the RSI for the given period
  result.push({
    id: 'RSI',
    name: localize({ id: 'symbol.details.chart.tooltip.rsi' }),
    type: 'line',
    color: '#008c46',
    yAxis: 'rsi',
    data: getRsiData(bars, fullBarData, period),
    tooltip: { yDecimals: 2 },
    zIndex: 1
  });

  return result;
}

module.exports = {
  getSeries: getRsiSeries
};
