import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Button from './NewScansEditCriteriaButton';
import Grid from '@mui/material/Grid';
import ScanItemRangeDetail from './ScanItemRangeDetail';

class MinifiedCriteria extends Component {
  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  buildScanField(name, options) {
    let values = [];
    if (Array.isArray(options)) {
      values = options;
    } else if (typeof options === 'string') {
      values = [options];
    } else if (!!options && typeof options === 'object') {
      values.push(
        !options.lower || options.lower === ''
          ? null
          : parseFloat(options.lower)
      );
      values.push(
        !options.upper || options.upper === ''
          ? null
          : parseFloat(options.upper)
      );
    }
    return (
      <div key={name}>
        <span className="scan-search-criteria-type">
          {this.props.intl.formatMessage({
            id: `scan.criteria.${name}`
          })}
        </span>
        <div className="scan-search-criteria-detail">
          <ScanItemRangeDetail id={name} values={values} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="scan-search-criteria-container">
          <div className="scan-search-criteria-title">
            {this.props.intl.formatMessage({
              id: `scan.result.search.criteria`
            })}
          </div>
          {this.props.criteria &&
            this.props.selectedOptions &&
            this.props.criteria.map(o => {
              return this.buildScanField(o, this.props.selectedOptions[o]);
            })}
        </div>
        <div className="scan-search-criteria-edit-container">
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button
                handleClickOpen={this.props.handleClickEdit}
                labelText={this.localize('scan.criteria.edit')}
                disabled={!this.props.allowEditCriteria}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default injectIntl(MinifiedCriteria);
