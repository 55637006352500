import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const MonexTabs = withStyles({
  root: {
    width: '100%',
    marginTop: 10,
    minHeight: 40,
    borderBottom: '2px solid #E1C561'
  },
  flexContainer: {
    marginLeft: 5,
    marginRight: 5,
    maxHeight: 30
  },
  indicator: {
    display: 'none'
  }
})((props) => <Tabs {...props} />);

export const MonexTab = withStyles({
  root: {
    backgroundColor: '#E0E0E0',
    minHeight: 40,
    maxWidth: 120,
    '@media (min-width: 960px)': {
      minWidth: 120
    }
  },
  selected: {
    backgroundColor: '#E1C561',
    fontWeight: 'bold',
    color: '#000000 !important'
  },
  fullWidth: {
    marginLeft: 5,
    marginRight: 5
  }
})((props) => <Tab {...props} />);
