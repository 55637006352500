const { getStochasticOscillatorK } = require('./stochasticOscillator');
const { getFixedLinesData } = require('./utils');

function getPercentageRData(bars, fullBarData, period) {
  let values = getStochasticOscillatorK(bars, fullBarData, period);
  for (let i = 0; i < values.length; i++) {
    values[i][1] = values[i][1] - 100;
  }

  return values;
}

function getRsiSeries(periods, bars, fullBarData, localize) {
  if (!fullBarData) {
    return [];
  }

  let result = [];
  let period = periods[0];

  // First insert the red line for the overbought (-20)
  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.overbought' }),
    type: 'line',
    color: '#ff2800',
    yAxis: 'percentageR',
    data: getFixedLinesData(bars, -20),
    tooltip: { yDecimals: 2 },
    zIndex: 3
  });

  // Second insert the blue line for the oversold (-80)
  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.oversold' }),
    type: 'line',
    color: '#0055cc',
    yAxis: 'percentageR',
    data: getFixedLinesData(bars, -80),
    tooltip: { yDecimals: 2 },
    zIndex: 2
  });

  // Finally, calculate the Percentage R for the given period
  result.push({
    id: '%R',
    name: localize({ id: 'symbol.details.chart.tooltip.percentageR' }),
    type: 'line',
    color: '#008c46',
    yAxis: 'percentageR',
    data: getPercentageRData(bars, fullBarData, period),
    tooltip: { yDecimals: 2 },
    zIndex: 1
  });

  return result;
}

module.exports = {
  getSeries: getRsiSeries
};
