import React from 'react';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { injectIntl, FormattedMessage } from 'react-intl';
import Exchange from '../common/Exchange';
import withTracker from '../../utils/analytics';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';

function ExchangeWithRoot(Component) {
  return class ExchangeChild extends React.PureComponent {
    render() {
      return (
        <span className={'formatted-exchange'}>
          <span className="short-name">{this.props.jpShortName || ''}</span>
          {this.props.root} <Component {...this.props} {...this.state} />
        </span>
      );
    }
  };
}
const FormattedExchange = ExchangeWithRoot(Exchange);

function SearchItem(props) {
  if (props.symbolData && props.symbolData.data) {
    let exchange = (
      <FormattedExchange
        name={props.symbolData.data.exchangeName}
        section={props.symbolData.data.jpSection}
        root={props.symbolData.data.root}
      />
    );

    return (
      <ListItem
        button
        key={props.symbol}
        value={props.symbol}
        className="search-list-item add-symbol"
        // https://github.com/mui-org/material-ui/issues/9106
        component={(p) => <Link {...p} to={`/symbol/${props.symbol}`} />}
      >
        <ListItemText
          primary={
            <div className={'primary-text'}>{props.symbolData.data.name}</div>
          }
          secondary={exchange}
        />
        <ListItemIcon />
      </ListItem>
    );
  }
  return <FormattedMessage id="message.status.loading" />;
}

class SearchItemListContainer extends React.PureComponent {
  render() {
    const currentSearch = this.props.searchResult;
    const symbols = currentSearch.symbols;
    const className = 'symbol-search-container';

    if (symbols && symbols.length) {
      if (symbols.length === 1) {
        return <Redirect to={`/symbol/${symbols[0]}`} />;
      }

      return (
        <div className={className}>
          <List className={this.props.className || 'search-list'}>
            {currentSearch.symbols.map((symbol, index) => (
              <div key={'search-item' + index}>
                <SearchItem
                  symbol={symbol}
                  symbolData={this.props.symbolData[symbol] || {}}
                />
              </div>
            ))}
          </List>
        </div>
      );
    }
    var messageId;

    switch (true) {
      case !!currentSearch.isLoading:
        messageId = 'message.status.searching';
        break;
      case !!currentSearch.isError:
        messageId = 'message.status.error';
        break;
      case symbols.length === 0:
        messageId = 'message.no.symbols.found';
        break;
      default:
        messageId = 'message.none';
    }

    return (
      <div className="symbol-search-fixed">
        <span className="search-result">
          <FormattedMessage id={messageId} />
        </span>
      </div>
    );
  }
}

export default connect()(withTracker(injectIntl(SearchItemListContainer)));
