var actionTypes = [
  // Faucet
  'CONNECTING',
  'RECEIVE_MESSAGE',
  'RECEIVE_STATUS',

  // Bars
  'REQUESTING_BARS',
  'RECEIVE_BARS',
  'INVALIDATE_BARS',
  'ERROR_REQUESTING_BARS',

  // Marketboard
  'RECEIVE_MARKETBOARDS',
  'FILTER_MARKETBOARDS',
  'SET_SELECTED',
  'SET_SELECTED_TAB',
  'SELECT_MARKETBOARD_VIEW',
  'ERROR_FETCHING_MARKETBOARD',
  'ERROR_POSTING_MARKETBOARD',
  'TOGGLE_EDIT',
  'DELETE_SYMBOL',
  'REPLACE_MARKETBOARD_SYMBOLS',
  'UPDATE_MARKETBOARD_NAME',
  'UPDATE_MARKETBOARD_SYMBOL',
  'MARKETBOARD_ADD',
  'MARKETBOARD_DELETE',
  'MOVE_MARKETBOARD',
  'UPDATE_POSITION_BOARDS',

  // User
  'SET_TOKEN',
  'SET_USER',
  'USER_VALIDATE_TOKEN',
  'USER_ERROR_VALIDATING_TOKEN',
  'SET_DM_PARAMETER',
  'SET_RZ_PARAMETER',
  'SET_USER_SRC_KEY',
  'ADD_LOCAL_MESSAGE',
  'DISMISS_LOCAL_MESSAGE',
  'DISMISS_GLOBAL_MESSAGE',
  'RECEIVE_GLOBAL_MESSAGES',

  // Symbol
  'REQUESTING_SYMBOL',
  'REQUESTING_SYMBOL_PATTERN',
  'RECEIVE_SYMBOL_PATTERN_RESULT',
  'RECEIVE_SYMBOL_RESULT',
  'RECEIVE_SYMBOL_METADATA',
  'INVALIDATE_SYMBOL_PATTERN',
  'SYMBOL_ERROR_REQUESTING_METADATA',
  'SYMBOL_ERROR_REQUESTING_PATTERN',
  'RECEIVE_SYMBOL_NEWS',

  // Market
  'REQUESTING_UP_DOWN_RATIOS',
  'RECEIVE_UP_DOWN_RATIOS',
  'ERROR_REQUESTING_UP_DOWN_RATIOS',
  'REQUESTING_FUNDAMENTALS',
  'RECEIVE_FUNDAMENTALS',
  'ERROR_REQUESTING_FUNDAMENTALS',

  // News
  'RECEIVE_NEWS_ARTICLE',
  'RECEIVE_NEWS_TSE',
  'NEWS_ERROR_REQUESTING_HEADLINES',
  'NEWS_ERROR_REQUESTING_ARTICLE',
  'NEWS_ERROR_REQUESTING_TSE',
  'RECEIVE_GLOBAL_NEWS',
  'WILL_RECEIVE_NEWS',

  // Settings
  'SET_CHART_STYLE',
  'SET_CHART_MODE',
  'SET_DISPLAY_TOOLTIP',
  'SET_CHART_PERIOD',
  'SET_INDICATOR_PARAMETERS',
  'SET_INDICATORS',
  'SET_DISABLED_INDICATORS',
  'SET_COMPARISON_SYMBOLS',
  'SET_INCLUDE_JPEQ',
  'SET_INCLUDE_USEQ',
  'SET_INCLUDE_SUPPLEMENTALS',
  'SET_INCLUDE_INDUSTRY',

  // System
  'RECEIVE_SYSTEM_STATUS',
  'ERROR_REQUESTING_SYSTEM_STATUS',

  // Hotlist
  'REQUESTING_HOTLIST',
  'RECEIVE_HOTLIST',
  'INVALIDATE_HOTLIST',
  'ERROR_REQUESTING_HOTLIST',

  // Scan
  'RECEIVE_SCAN',
  'ERROR_REQUESTING_SCAN',
  'RECEIVE_USER_SCANS',
  'ERROR_REQUESTING_USER_SCANS',
  'RECEIVE_LAST_SCAN',
  'ERROR_REQUESTING_LAST_SCAN',
  'SAVE_LAST_SCAN',
  'ERROR_SAVING_LAST_SCAN',
  'SAVE_SCAN',
  'OVERWRITE_SCAN',
  'ERROR_SAVING_SCAN',
  'ERROR_OVERWRITING_SCAN',
  'DELETE_SCAN',
  'ERROR_DELETING_SCAN',

  // Memos
  'REQUESTING_USER_MEMOS',
  'RECEIVE_USER_MEMOS',
  'ERROR_REQUESTING_USER_MEMOS',
  'SAVE_USER_MEMO',
  'ERROR_SAVING_USER_MEMO',
  'DELETE_USER_MEMO',
  'ERROR_DELETING_USER_MEMO',

  // Feedback
  'ERROR_POSTING_FEEDBACK',

  // Feature Flags
  'REQUESTING_FEATURE_FLAGS',
  'RECEIVE_FEATURE_FLAGS',
  'ERROR_REQUESTING_FEATURE_FLAGS'
];

/*
  Return a frozen object mapping all of the above action types
  to a property that has the same value. e.g.,
  
  'CONNECTING' => { CONNECTING: 'CONNECTING' }

  The purpose of this function is to avoid affectively entering the same
  string twice simply to export a constant.
*/
function constants(actionTypes) {
  return Object.freeze(
    actionTypes.reduce((obj, action) => {
      return {
        ...obj,
        [action]: action
      };
    }, {})
  );
}

export default constants(actionTypes);
