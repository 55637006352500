import React from 'react';
import { Stack, TextField, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  searchContainer: {
    width: '200px',
    padding: '5px'
  }
});

const desktopChip =  {
  '& .MuiChip-label': {
    width: '65px',
    marginLeft: '5px',
    display: 'flex',
    justifyContent: 'center'
  }
}

export default function NewsSearch({
  isCompact = false,
  onTextChange = null,
  onToggleProvider = null,
  validProviders = [],
  selectedProviders = []
}) {
  if (
    onTextChange === null ||
    onToggleProvider === null ||
    validProviders === [] ||
    selectedProviders === []
  )
    return null;

  const intl = useIntl();
  const classes = useStyles();
  const localize = (i18nKey) => {
    return intl.formatMessage({
      id: i18nKey
    });
  };
  const isWideView = useMediaQuery('(min-width: 767.98px)');
  const newsSearchContainerClasses = `news-search-container ${
    isWideView ? classes.searchContainer : null
  }`;

  return (
    <div className={`news-search`}>
      <div className={newsSearchContainerClasses}>
        <div className={`news-search--title${isWideView ? ' desktop': ''}`}>
          {localize('global.news.search')}
        </div>
        <div className="search-box news-search-container-item">
          <TextField
            key={'symbol-search-text'}
            autoFocus={false}
            onChange={onTextChange}
            fullWidth={true}
            placeholder={localize('global.news.search.placeholder')}
            className={`news-search--input${isWideView ? ' desktop': ''}`}
            variant="standard"
            size="small"
          />
        </div>
        <div className={isCompact ? "" : "news-search--provider-container"}>
          <div className="news-provider--label">
            {localize('news.provider')}
          </div>
          <div className="news-search--providers">
            <Stack direction={isCompact ? 'column' : 'row'} flexWrap={'wrap'}>
              {validProviders.map((providerId) => {
                return (
                  <Chip
                    sx={isCompact ? desktopChip : {}}
                    key={providerId}
                    label={localize(`news.provider.${providerId}`)}
                    onClick={() => onToggleProvider(providerId)}
                    icon={<CheckIcon />}
                    className={
                      !selectedProviders.includes(providerId)
                        ? `un-selected${isCompact ? ' desktop' : ''}`
                        : `selected${isCompact ? ' desktop' : ''}`
                    }
                  />
                );
              })}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}
