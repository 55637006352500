import types from './_actionTypes';
import { fetchRetryWithAuth } from '../utils/fetchRetry';
import * as time from '../utils/time';

const API_URL = process.env.REACT_APP_INSIGHT_API_DOMAIN;

function getHotlistUrl(hotlistName, exchange, sector) {
  // prettier-ignore
  let url = `https://${API_URL}/market/hotlist/${hotlistName}`;

  if (!!exchange || !!sector) {
    url += '?';
  }

  if (!!exchange) {
    // Adding an additional ampersand immediately after the ? of the URL
    // has no ill effects, and was therefore done for simplicity
    url += `&exchange=${exchange}`;
  }

  if (!!sector) {
    url += `&sector=${sector}`;
  }

  return url;
}

export function requestingHotlist(hotlistName, exchange, sector) {
  return {
    type: types.REQUESTING_HOTLIST,
    hotlistName: hotlistName,
    exchange: exchange,
    sector: sector
  };
}

export function receiveHotlistResponse(
  response,
  hotlistName,
  exchange,
  sector
) {
  return {
    type: types.RECEIVE_HOTLIST,
    hotlistName: hotlistName,
    exchange: exchange,
    sector: sector,
    data: response
  };
}

export function invalidateHotlist(hotlistName, exchange, sector) {
  return {
    type: types.INVALIDATE_HOTLIST,
    hotlistName: hotlistName,
    exchange: exchange,
    sector: sector
  };
}

export function errorRequestingHotlist(hotlistName, exchange, sector, error) {
  return {
    type: types.ERROR_REQUESTING_HOTLIST,
    hotlistName: hotlistName,
    exchange: exchange,
    sector: sector,
    error: error,
    errorCode: 'E0017'
  };
}

function fetchHotlist(hotlistName, exchange, sector) {
  let req = {
    method: 'get'
  };

  return fetchRetryWithAuth(
    req,
    () => {
      return getHotlistUrl(hotlistName, exchange, sector);
    },
    (response) => {
      return receiveHotlistResponse(response, hotlistName, exchange, sector);
    },
    (error) => {
      return errorRequestingHotlist(hotlistName, exchange, sector, error);
    }
  );
}

export function getHotlistStateId(hotlistName, exchange, sector) {
  let id = hotlistName;
  if (exchange) {
    id += '/' + exchange;
  }

  if (sector) {
    id += '/' + sector;
  }

  return id;
}

function shouldFetchHotlist(state, hotlistName, exchange, sector) {
  const hotlistStateId = getHotlistStateId(hotlistName, exchange, sector);
  const search = state.hotlists[hotlistStateId];
  if (!search) {
    return true;
  }

  if (search.isError) {
    return true;
  }

  if (search.isLoading) {
    return false;
  }

  if (search.when && search.when < time.timestamp() - 60) {
    return true;
  }

  return search.didInvalidate || false;
}

export function fetchHotlistIfNeeded(hotlistName, exchange, sector) {
  return (dispatch, getState) => {
    if (shouldFetchHotlist(getState(), hotlistName, exchange, sector)) {
      dispatch(requestingHotlist(hotlistName, exchange, sector));
      return dispatch(fetchHotlist(hotlistName, exchange, sector));
    }
  };
}
