import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS as cssDndKit } from '@dnd-kit/utilities';
import MobileDragHandle from './MobileDragHandle';
import { ReactComponent as ListDeleteIcon } from '../../icons/Icon-List-Delete.svg';
import { isPositionBoard } from '../../utils/marketboards';
import { ListItem, ListItemButton } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import AppsIcon from '@mui/icons-material/Apps';

const SortableBoardItem = (props) => {
  const { setNodeRef, transform, transition, listeners, isDragging } = useSortable({ id: props.id });
  const style = {
    transform: cssDndKit.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto'
  };

  // TODO: the onClick handler below is a temporary hack to work around the
  // issue with the selected marketboard ID in the Redux state object not being
  // updated when navigating to a new URL. When the routing issue is fixed
  // (either by dispatching a Redux action when the route changes or by reading
  // the selected board ID from the route match params instead of Redux), it
  // should be possible to remove this hack.
  const handle =
    props.editable && !props.searchTerm ? (
      <MobileDragHandle style={{ right: '10px !important' }} handleListeners={listeners} />
    ) : (
      <AppsIcon className="apps-icon" />
    );
  const isCashOrMargin = isPositionBoard(props.board.Id);
  const canDelete = props.canDelete && !isCashOrMargin;
  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      className="marketboard-list-item"
      key={props.board.Id}
    >
      <ListItemButton
        className={'marketboard-list-item-delete-icon'}
        onClick={() => {
          if (canDelete) {
            props.onDelete(props.board);
          }
        }}
      >
        <div>
          <ListDeleteIcon className={canDelete ? '' : 'disabled'} />
        </div>
      </ListItemButton>
      <div
        className={'marketboard-list-item-text'}
        onClick={() => {
          props.onSelect(props.board.Id);
          window.location.hash = '/marketboard/' + props.board.Id;
        }}
      >
        <ListItemText primary={props.board.Name} />
      </div>

      <ListItemSecondaryAction style={{ right: '10px' }}>
        {handle}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

SortableBoardItem.muiName = 'ListItem';

export default SortableBoardItem;