import React from 'react';
import { injectIntl } from 'react-intl';

export class QuoteCardExRightsFlag extends React.PureComponent {
  render() {
    if (!this.props.status || !this.props.status.length) {
      return null;
    }

    const label = this.props.intl.formatMessage({
      id: 'marketboard.row.flag.exRightsStatus'
    });
    return <span className={this.props.className}>{label}</span>;
  }
}
QuoteCardExRightsFlag = injectIntl(QuoteCardExRightsFlag);

export default QuoteCardExRightsFlag;
