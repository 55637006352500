const {
  getFixedLinesData,
  getFilteredBarValuesWithSameTimestamps,
  simpleMovingAverage
} = require('./utils');

function getCciData(bars, fullBarData, period, avgPeriod) {
  // Typical Prices should be divided by 3 but for precision
  // of CCI calculation we keep it this way
  let typicalPriceBars = fullBarData.map(a => a[2] + a[3] + a[4]);
  let values = [];
  let priceWindow = [];

  for (let i = 0; i < typicalPriceBars.length; i++) {
    priceWindow.push(typicalPriceBars[i]);

    if (priceWindow.length < period) {
      values.push(null);
      continue;
    }

    let preMean = priceWindow.reduce((pv, cv) => pv + cv);
    let preMeanDeviation = priceWindow
      .map(a => Math.abs(a * period - preMean))
      .reduce((pv, cv) => pv + cv)
      .toFixed(5);
    let cci = 0;
    if (preMeanDeviation > 0) {
      cci =
        (period * (period * typicalPriceBars[i] - preMean)) /
        (0.015 * preMeanDeviation);
    }
    values.push(cci);
    priceWindow.shift();
  }

  let valuesAvg = simpleMovingAverage(values, avgPeriod);
  return {
    cci: getFilteredBarValuesWithSameTimestamps(bars, fullBarData, values),
    cciAvg: getFilteredBarValuesWithSameTimestamps(bars, fullBarData, valuesAvg)
  };
}

function getCciSeries(periods, bars, fullBarData, localize, fullVolume) {
  if (!fullBarData) {
    return [];
  }

  let result = [];
  const period = periods[0];
  const avgPeriod = periods[1];
  let cciData = getCciData(bars, fullBarData, period, avgPeriod);

  // First insert the red line for the overbought (100)
  result.push({
    id: 'cciOverbought',
    name: localize({ id: 'symbol.details.chart.tooltip.overbought' }),
    type: 'line',
    color: '#ff2800',
    yAxis: 'cci',
    data: getFixedLinesData(bars, 100),
    tooltip: { yDecimals: 2 },
    zIndex: 3
  });

  // Second insert the blue line for the oversold (-100)
  result.push({
    id: 'cciOversold',
    name: localize({ id: 'symbol.details.chart.tooltip.oversold' }),
    type: 'line',
    color: '#0055cc',
    yAxis: 'cci',
    data: getFixedLinesData(bars, -100),
    tooltip: { yDecimals: 2 },
    zIndex: 2
  });

  // Calculate the CCI for the given period
  result.push({
    id: 'cci',
    name: localize({ id: 'symbol.details.chart.tooltip.cci' }).replace(
      'XX',
      period
    ),
    type: 'line',
    color: '#19901b',
    yAxis: 'cci',
    data: cciData['cci'],
    tooltip: { yDecimals: 2 },
    zIndex: 1
  });

  // Finally, calculate the CCI Average for the given avgPeriod
  result.push({
    id: 'cciAverage',
    name: localize({ id: 'symbol.details.chart.tooltip.cci.average' }).replace(
      'XX',
      avgPeriod
    ),
    type: 'line',
    color: '#98ab20',
    yAxis: 'cci',
    data: cciData['cciAvg'],
    tooltip: { yDecimals: 2 },
    zIndex: 1
  });

  return result;
}

module.exports = {
  getSeries: getCciSeries,
  // Added for testing
  getCciData: getCciData
};
