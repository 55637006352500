import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useActions from './useActions';
import { fetchSymbolPatternIfNeeded } from '../../actions/symbolSearch';

const useLuckySymbol = (pattern) => {
  const symbols = useSelector((state) => state.symbols || {});
  const [searchSymbol] = useActions([fetchSymbolPatternIfNeeded], []);
  const [luckySymbol, setLuckySymbol] = useState(null);

  useEffect(() => {
    if (pattern) {
      // Trigger search for symbol when selectedSymbol changes
      searchSymbol(pattern);
    }
  }, [searchSymbol, pattern]);

  useEffect(() => {
    if (
      !pattern ||
      !symbols.searches[pattern] ||
      symbols.searches[pattern].symbols.length === 0
    ) {
      return;
    }

    setLuckySymbol(symbols.searches[pattern].symbols[0]);
  }, [pattern, symbols]);

  return luckySymbol;
};

export default useLuckySymbol;
