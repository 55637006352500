import sma from './indicators/sma';
import * as avgDeviation from './indicators/avgDeviation';
import ichimoku from './indicators/ichimoku';
import bb from './indicators/bb';
import volAvg from './indicators/volAvg';
import volByPrice from './indicators/volByPrice';
import macd from './indicators/macd';
import parabolicSar from './indicators/parabolicStopAndReverse';
import stochasticSlow from './indicators/stochasticSlow';
import stochasticFast from './indicators/stochasticFast';
import rsi from './indicators/rsi';
import movAvgEnvelopes from './indicators/movAvgEnvelopes';
import percentageR from './indicators/percentageR';
import advDeclineRatios from './indicators/advDeclineRatios';
import avgTrueRange from './indicators/avgTrueRange';
import mfi from './indicators/mfi';
import rci from './indicators/rci';
import cci from './indicators/cci';
import comparisonSymbols from './indicators/comparisonSymbols';

const indicatorFunctions = {
  sma: {
    function: sma.getSeries,
    yAxis: null
  },
  avgDeviation: {
    function: avgDeviation.getSeries,
    yAxis: {
      id: 'avgDeviation',
      height: 250,
      title: {
        text: null
      },
      labels: {
        enabled: false
      }
    }
  },
  ichimoku: {
    function: ichimoku.getSeries,
    yAxis: null
  },
  bb: {
    function: bb.getSeries,
    yAxis: null
  },
  volAvg: {
    function: volAvg.getSeries,
    yAxis: null
  },
  volByPrice: {
    function: volByPrice.getSeries,
    yAxis: null
  },
  macd: {
    function: macd.getSeries,
    yAxis: {
      id: 'macd',
      height: 150,
      title: {
        text: null
      }
    }
  },
  parabolicSar: {
    function: parabolicSar.getSeries,
    yAxis: null
  },
  stochasticSlow: {
    function: stochasticSlow.getSeries,
    yAxis: {
      id: 'stochasticSlow',
      opposite: true,
      height: 150,
      title: {
        text: null
      }
    }
  },
  stochasticFast: {
    function: stochasticFast.getSeries,
    yAxis: {
      id: 'stochasticFast',
      opposite: true,
      height: 150,
      title: {
        text: null
      }
    }
  },
  rsi: {
    function: rsi.getSeries,
    yAxis: {
      id: 'rsi',
      height: 150,
      title: {
        text: null
      }
    }
  },
  movAvgEnvelopes: {
    function: movAvgEnvelopes.getSeries,
    yAxis: null
  },
  percentageR: {
    function: percentageR.getSeries,
    yAxis: {
      id: 'percentageR',
      height: 150,
      min: -120,
      max: 20,
      title: {
        text: null
      }
    }
  },
  advDeclineRatios: {
    function: advDeclineRatios.getSeries,
    yAxis: {
      id: 'advDeclineRatios',
      height: 150,
      title: {
        text: null
      }
    }
  },
  avgTrueRange: {
    function: avgTrueRange.getSeries,
    yAxis: {
      id: 'avgTrueRange',
      height: 150,
      title: {
        text: null
      }
    }
  },
  mfi: {
    function: mfi.getSeries,
    yAxis: {
      id: 'mfi',
      height: 150,
      title: {
        text: null
      }
    }
  },
  rci: {
    function: rci.getSeries,
    yAxis: {
      id: 'rci',
      height: 150,
      title: {
        text: null
      }
    }
  },
  cci: {
    function: cci.getSeries,
    yAxis: {
      id: 'cci',
      height: 150,
      title: {
        text: null
      }
    }
  },
  comparisonSymbols: {
    function: comparisonSymbols.getSeries,
    yAxis: null
  }
};

export function getIndicatorsSeries(
  indicators,
  bars,
  fullBarData,
  localize,
  fullVolume,
  additionalSymbolsData,
  comparisonSymbols
) {
  return indicators.reduce(function (result, indicator) {
    if (indicator.type && indicator.type in indicatorFunctions) {
      return result.concat(
        indicatorFunctions[indicator.type]['function'](
          indicator.params,
          bars,
          fullBarData,
          localize,
          fullVolume,
          additionalSymbolsData,
          comparisonSymbols
        )
      );
    }
    return null;
  }, []);
}

export function getAdditionalYAxis(indicators) {
  return indicators
    .reduce(function (result, indicator) {
      if (indicator.type && indicator.type in indicatorFunctions) {
        let yAxis = result.concat(indicatorFunctions[indicator.type]['yAxis']);
        if (yAxis) {
          return yAxis;
        }
      }
      return null;
    }, [])
    .filter((e) => e !== null);
}
