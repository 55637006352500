import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  HighchartsStockChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Debug,
  Series
} from 'react-jsx-highstock';
import { tooltipFormatter } from '../../utils/chartTooltip';

import AvgDeviation from './AvgDeviation';
import { getSeries as macd } from '../../utils/indicators/macd';
import { getSeries as stochasticSlow } from '../../utils/indicators/stochasticSlow';
import { getSeries as stochasticFast } from '../../utils/indicators/stochasticFast';
import { getSeries as rsi } from '../../utils/indicators/rsi';
import { getSeries as percentageR } from '../../utils/indicators/percentageR';
import { getSeries as advDeclineRatios } from '../../utils/indicators/advDeclineRatios';
import { getSeries as avgTrueRange } from '../../utils/indicators/avgTrueRange';
import { getSeries as mfi } from '../../utils/indicators/mfi';
import { getSeries as rci } from '../../utils/indicators/rci';
import { getSeries as cci } from '../../utils/indicators/cci';

const Indicator = ({ indicatorName, getSeries, symbol, barSize, bars, setting, volumeBars, additionalSymbolsData }) => {
  const intl = useIntl();
  const localize = (id, otherParams) =>
    intl.formatMessage({ id: id }, otherParams);

  const series = useMemo(() => {
    const indicatorLocalize = (data, params) =>
      intl.formatMessage(data, params);

    return getSeries(
      setting.params,
      bars,
      bars,
      indicatorLocalize,
      volumeBars,
      additionalSymbolsData
    );
  }, [getSeries, setting.params, bars, intl, volumeBars]);

  return (
    <HighchartsStockChart key={`${symbol}/${barSize}/${indicatorName}/insight`}>
      {/* Passing in the debug component will instruct the wrapper
    to place the highcharts object on `window.chart` for easy debugging. 
    https://github.com/whawker/react-jsx-highcharts/wiki/Debugging */}
      {process.env.NODE_ENV === 'development' && <Debug varName={indicatorName}/>}

      <Chart
        height={200}/>

      <Tooltip
        useHTML={true}
        formatter={tooltipFormatter}
        outside={true}
        split={false}
        variables={{
          currentBarSize: barSize,
          realtime: true
        }}
        externalFunctions={{
          localize: localize
        }}
      />

      <XAxis />

      <YAxis>
        {series.map((props) => (
          <Series
            key={props.id || indicatorName}
            showInNavigator={false}
            animation={false}
            {...props}
          />
        ))}
      </YAxis>
    </HighchartsStockChart>
  );
};

const RealtimeChartIndicator = (props) => {
  const components = {
    avgDeviation: [AvgDeviation, null],
    macd: [Indicator, macd],
    stochasticSlow: [Indicator, stochasticSlow],
    stochasticFast: [Indicator, stochasticFast],
    rsi: [Indicator, rsi],
    percentageR: [Indicator, percentageR],
    advDeclineRatios: [Indicator, advDeclineRatios],
    avgTrueRange: [Indicator, avgTrueRange],
    mfi: [Indicator, mfi],
    rci: [Indicator, rci],
    cci: [Indicator, cci]
  };

  const indicatorData = components[props.type];
  if (!indicatorData) {
    return null;
  }

  const Component = indicatorData[0];
  return <Component indicatorName={props.type} getSeries={indicatorData[1]} {...props} />;
};

export default RealtimeChartIndicator;
