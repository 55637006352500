import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { getResultsForCurrentPage } from '../common/customMaterial/Pagination/PaginateCommon';
import { addToArrayReplaceOldestIfExceeds } from '../../utils/common';
import List from '@mui/material/List';
import Headline from './Headline';
import PaginationBoxView from '../common/customMaterial/Pagination/PaginationBoxView';

const PREFIX = 'HeadlineList';

const classes = {
  headlineContainer: `${PREFIX}-headlineContainer`
};

const StyledList = styled(List)({
  [`& .${classes.headlineContainer}`]: {
    padding: '1px',
    border: '1px solid #d3d9e0',
    borderCollapse: 'separate',
    borderRadius: '5px'
  }
});

export default function HeadlineList(props) {
  // States
  const [amountNewsToShow, setAmountNewsToShow] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [displayedHeadlines, setDisplayedHeadlines] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [articlesRead, setArticlesRead] = useState([]);

  const READ_NEWS_LIMIT = 10000;

  useEffect(() => {
    let articles = JSON.parse(localStorage.getItem('news.read'));
    if (articles) {
      setArticlesRead(articles);
    }
  }, []);

  useEffect(() => {
    if (articlesRead.length > 0) {
      localStorage.setItem('news.read', JSON.stringify(articlesRead));
    }
  }, [articlesRead]);

  const onRelatedSymbolPress = (relatedSymbol, getSymbol) => {
    setSelectedSymbol(relatedSymbol);
    getSymbol(relatedSymbol);
  };

  const addArticlesToLocalStorage = (articleId) => {
    if (articleId && !articlesRead.includes(articleId)) {
      let updatedArticles = addToArrayReplaceOldestIfExceeds(
        articleId,
        articlesRead,
        READ_NEWS_LIMIT
      );
      setArticlesRead(updatedArticles);
    }
  };

  // Pagination is base 0 ~
  // Get Results for Current Page is base 1 ~

  const handlePageClick = (data) => {
    // data.selected is 0-indexed as its from pagination
    let currentPage = data.selected;

    setCurrPage(currentPage);
    setDisplayedHeadlines(
      getResultsForCurrentPage(
        props.headlines,
        currentPage + 1,
        props.amountNewsToShow
      )
    );
  };

  const propsHeadlines = props.headlines || [];

  const noNewsMessage = (id) => {
    return (
      <div className="is-loading">
        <div className="loading-message">
          <FormattedMessage id={id} />
        </div>
      </div>
    );
  };

  // In charge of resetting the current display back to page 1 when the headlines change
  // Set current page back to 0 because we are updating the search
  useEffect(() => {
    let currentPage = 0;

    setCurrPage(currentPage);
    setAmountNewsToShow(props.amountNewsToShow);
    setTotalResults(propsHeadlines.length);
    setDisplayedHeadlines(
      getResultsForCurrentPage(
        propsHeadlines,
        currentPage + 1,
        props.amountNewsToShow
      )
    );
  }, [propsHeadlines]);

  if (props.isLoading) {
    return noNewsMessage('message.status.loading');
  } else if (props.isError) {
    return noNewsMessage('message.status.error');
  } else if (propsHeadlines.length === 0) {
    return noNewsMessage('symbol.details.news.no.news');
  }

  const headlineContainerClass = props.isWide
    ? classes.headlineContainer
    : null;

  return (
    <StyledList component="nav">
      {/* This iterates over each headline in the results to display,
            and renders a newArticlesSymbolItem for each.
            displayedHeadlines contains the subsection of results
            appropriate for the currently displayed page, the full results
            are stored in displayedHeadlines. */}
      <div className={`scan-card-symbols ${headlineContainerClass}`}>
        {displayedHeadlines &&
          displayedHeadlines.map((article, index) => {
            return (
              <Headline
                article={article}
                key={index}
                {...props}
                isWide={props.isWide}
                index={index}
                displayRelatedSymbols={true}
                onRelatedSymbolPress={onRelatedSymbolPress}
                addArticlesToLocalStorage={addArticlesToLocalStorage}
                isRead={articlesRead.includes(article.articleId)}
                selectedSymbol={selectedSymbol}
                setSelectedSymbol={setSelectedSymbol}
                isDisableRelatedSymbols={props.isDisableRelatedSymbols}
              />
            );
          })}
      </div>
      {displayedHeadlines && (
        <div className="pagination-container">
          <PaginationBoxView
            itemsPerPage={amountNewsToShow}
            totalDataSize={totalResults}
            onPageChange={handlePageClick}
            previousLabel={'<'}
            nextLabel={'>'}
            breakClassName={'break-me'}
            marginPagesDisplayed={1}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={currPage}
          />
        </div>
      )}
    </StyledList>
  );
}
