import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import IconSearch from '../../icons/Icon-Search.svg';
import { marketboardFontFamily } from '../../utils/html';

const useStyles = makeStyles({
  searchBox: {
    display: 'flex',
    height: '32px',
    left: '12px',
    top: '12px',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    padding: '0px 0px 0px 10px',
    margin: '10px',
    clear: 'both',
    '& input:focus': { color: '#333333' },
    '& input:focus::placeholder': { color: 'transparent' }
  },
  searchIcon: {
    position: 'relative',
    top: '5px',
    marginRight: '10px'
  },
  searchText: {
    width: '100%',
    border: 'none',
    background: 'none',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '16px',
    outline: 'none',
    margin: '0px',
    padding: '0px',
    color: '#9E9E9E'
  }
});
 
export const MobileMarketboardSearchBox = ({
  searchPattern,
  onChange,
  onClick,
  isDisabled = false
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });

  return (
    <div id={'symbol-search-box'} className={styles.searchBox}>
      <span className={styles.searchIcon}>
        <img src={IconSearch} alt={'Search'} />
      </span>
      <input
        className={styles.searchText}
        id={'searchText'}
        value={searchPattern}
        onChange={onChange}
        placeholder={localize('marketboard.search.text')}
        disabled={isDisabled}
      />
      {isDisabled && (
        <div
          style={{
            position: 'absolute',
            left: '0px',
            height: '32px',
            width: '100%',
            cursor: 'pointer'
          }}
          onClick={onClick}
        />
      )}
    </div>
  );
};
