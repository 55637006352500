import React from 'react';
import ChartSma from './ChartSma';
import ChartAvgDeviation from './ChartAvgDeviation';
import ChartIchimokuCloud from './ChartIchimokuCloud';
import ChartMacd from './ChartMacd';
import ChartPeriodSelector from './ChartPeriodSelector';
import ChartRci from './ChartRci';
import ChartCci from './ChartCci';

class ChartIndicator extends React.Component {
  components = {
    sma: ChartSma,
    avgDeviation: ChartAvgDeviation,
    ichimoku: ChartIchimokuCloud,
    bb: ChartPeriodSelector,
    volAvg: ChartPeriodSelector,
    volByPrice: ChartPeriodSelector,
    macd: ChartMacd,
    parabolicSar: ChartIchimokuCloud,
    stochasticSlow: ChartPeriodSelector,
    stochasticFast: ChartPeriodSelector,
    rsi: ChartPeriodSelector,
    movAvgEnvelopes: ChartPeriodSelector,
    percentageR: ChartPeriodSelector,
    advDeclineRatios: ChartPeriodSelector,
    avgTrueRange: ChartPeriodSelector,
    mfi: ChartPeriodSelector,
    rci: ChartRci,
    cci: ChartCci
  };
  render() {
    const Indicator = this.components[this.props.type];
    return <Indicator {...this.props} />;
  }
}
export default ChartIndicator;
