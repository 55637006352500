import React from 'react';
import Button from './NewScansSaveCriteriaButton';
import Grid from '@mui/material/Grid';
import PostAdd from '@mui/icons-material/PostAdd';
import Save from '@mui/icons-material/Save';

const NewScansSaveCriteriaContainer = props => {
  return (
    <div className="actions edit-scan-criteria">
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button
            icon={<PostAdd fontSize={'small'} />}
            handleClickOpen={props.handleSaveNewCriteria}
            labelText={props.saveNewCriteriaText}
            disabled={!props.allowSaveNewCriteria}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            icon={<Save fontSize={'small'} />}
            handleClickOpen={props.handleOverwriteCriteria}
            labelText={props.overwriteCriteriaText}
            disabled={!props.allowOverwriteCriteria}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default NewScansSaveCriteriaContainer;
