import React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useActions from '../../utils/hooks/useActions';
import { useSelector } from 'react-redux';
import {
  saveUserMemo as saveUserMemoAction,
  deleteUserMemo as deleteUserMemoAction,
  fetchUserMemosIfNeeded
} from '../../actions/memos';
import SymbolNote from '../common/Note';
import { debounce } from 'lodash';
import { createMemoSelector } from '../../selectors/memoSelectors';

const SAVE_MEMO_DEBOUNCE_TIME = 1000;

const MemoContainer = ({ symbol }) => {
  const fetchUserMemos = useActions(fetchUserMemosIfNeeded, []);
  const saveUserMemo = useActions(saveUserMemoAction, []);
  const deleteUserMemo = useActions(deleteUserMemoAction, []);

  useEffect(() => {
    // Fetch user memos
    fetchUserMemos([symbol]);
  }, [fetchUserMemos, symbol]);

  const symbolMemo = useSelector(createMemoSelector(symbol));

  const [memoOpen, setMemoOpen] = useState(false);
  const [memoText, setMemoText] = useState();

  useEffect(() => {
    setMemoText(symbolMemo.memo);
  }, [symbolMemo.memo]);

  const onNoteBlur = useCallback(() => {
    setMemoOpen(false);
  }, []);

  const onNoteClick = useCallback(
    (e) => {
      if (!memoOpen) {
        setMemoOpen(true);
      }

      e.stopPropagation();
    },
    [memoOpen]
  );

  const saveMemo = useCallback(
    (text) => {
      if (!text) {
        deleteUserMemo(symbol);
        return;
      }

      saveUserMemo(symbol, encodeURIComponent(text));
    },
    [deleteUserMemo, saveUserMemo, symbol]
  );

  // memoize debounce to avoid re-creating debounce every time
  // this component is rendered
  const debouncedSaveMemo = useMemo(
    () => debounce(saveMemo, SAVE_MEMO_DEBOUNCE_TIME),
    [saveMemo]
  );
  const onNoteChange = useCallback(
    (e) => {
      const newValue = e.target.value;

      // Value hasn't changed
      if (newValue === memoText) {
        return;
      }

      setMemoText(newValue);
      debouncedSaveMemo(newValue);
    },
    [debouncedSaveMemo, memoText]
  );

  const memoTimestamp = symbolMemo.timestamp;
  return (
    <div className="symbol-details-note">
      <SymbolNote
        onClick={onNoteClick}
        onChange={onNoteChange}
        onBlur={onNoteBlur}
        open={memoOpen}
        memo={memoText}
        timestamp={memoTimestamp}
      />
    </div>
  );
};

export default MemoContainer;
