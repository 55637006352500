import React from 'react';
import { injectIntl } from 'react-intl';
import Number from '../common/Number';
import { connect } from 'react-redux';

const i18nKeyPrefix = 'symbol.details.indicators.technicalAnalysis.';
class TechnicalAnalysisSection extends React.PureComponent {
  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  getRow(params) {
    return (
      <div className={'analyst-consensus-table-row border-bottom'}>
        <div className={'analyst-consensus-table-row-data'}>
          <span className="analyst-consensus-table-row-column-header">
            {this.localize(i18nKeyPrefix + params.title)}
          </span>
          <span className="analyst-consensus-table-row-value">
            <Number
              value={params.value}
              precision="2"
              suffix={params.suffix || null}
            />
          </span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="symbol-details-indicators-fundamental-analysis-section">
        <div className="section-header symbol-indicators-title">
          {this.localize(i18nKeyPrefix + 'title')}
        </div>
        <div className="symbol-indicator-fundamental-analysis-details">
          <div className="analyst-consensus-table title">
            <div className={'analyst-consensus-table-row border-first title'}>
              <div className={'analyst-consensus-table-row-data'}>
                <span className="analyst-consensus-table-row-column-header">
                  {this.localize(
                    i18nKeyPrefix + 'movingAverageDeviations.title'
                  )}
                </span>
              </div>
            </div>
            {this.getRow({
              title: 'movingAverageDeviations.sma5Day',
              value: this.props.sma5Day,
              suffix: '%'
            })}
            {this.getRow({
              title: 'movingAverageDeviations.sma25Day',
              value: this.props.sma25Day,
              suffix: '%'
            })}
            {this.getRow({
              title: 'movingAverageDeviations.sma13Week',
              value: this.props.sma13Week,
              suffix: '%'
            })}
            {this.getRow({
              title: 'movingAverageDeviations.sma26Week',
              value: this.props.sma26Week,
              suffix: '%'
            })}
          </div>
          <div className="analyst-consensus-table title">
            <div className={'analyst-consensus-table-row border-first title'}>
              <div className={'analyst-consensus-table-row-data'}>
                <span className="analyst-consensus-table-row-column-header">
                  {this.localize(i18nKeyPrefix + 'otherIndicators.title')}
                </span>
              </div>
            </div>
            {this.getRow({
              title: 'otherIndicators.rsi14Day',
              value: this.props.rsi14Day
            })}
          </div>
        </div>
      </div>
    );
  }
}

TechnicalAnalysisSection = injectIntl(TechnicalAnalysisSection);

function hasFundamentalsForSymbol(state, symbolId) {
  try {
    return state.market.fundamentals[symbolId].data.fundamentals;
  } catch (error) {
   return false;
  }
}

function getFundamentalValue(state, symbolId, propName) {
  let data = state.market.fundamentals[
    symbolId
  ].data.fundamentals[propName];

  if (!data) {
    return null;
  }

  return data.value;
}

function mapStateToProps(state, ownProps) {
  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };

  let symbolId = ownProps.symbolId;
  if (hasFundamentalsForSymbol(state, symbolId)) {
    props.sma5Day = getFundamentalValue(state, symbolId, 'sma5Day');
    props.sma25Day = getFundamentalValue(state, symbolId, 'sma25Day');
    props.sma13Week = getFundamentalValue(state, symbolId, 'sma13Week');
    props.sma26Week = getFundamentalValue(state, symbolId, 'sma26Week');
    props.rsi14Day = getFundamentalValue(state, symbolId, 'rsi14Day');
  }

  return props;
}

export default connect(mapStateToProps)(TechnicalAnalysisSection);
