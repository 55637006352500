import React from 'react';
import TradeButtons from './TradeButtons';
import SymbolDetailHeader from '../common/SymbolDetailHeader';
import { MarketBoardDropdownSelect } from './MarketBoardDropdownSelect';
import { DesktopHelpBar } from '../common/DesktopHelpbar';
import PageSubtitle from '../symbolOverview/PageSubtitle';
import { isJpeq, isUseq } from '../../utils/symbol';
import makeStyles from '@mui/styles/makeStyles';
import useSymbolMetadata from '../../utils/hooks/useSymbolMetadata';
import { saveLastMarketboard } from '../../utils/marketboards';
import { setSelected } from '../../actions/marketboards';
import useActions from '../../utils/hooks/useActions';

const useStyles = makeStyles({
  marketBoardHeader: {
    marginLeft: '25px',
    marginRight: '25px',
    marginTop: '20px',
    height: '115px'
  },
  ticker: {
    height: '50px',
    width: 'calc(100% - 300px)'
  },
  tradeBar: {
    width: '500px',
    height: '50px',
    float: 'left'
  },
  dropdown: {
    float: 'right',
    marginTop: '18px'
  }
});

const MarketBoardHeader = ({
  symbol,
  board,
  boards,
  handleAddBoardDialogOpen,
  handleDeleteBoardDialogOpen,
  handleRenameBoardDialogOpen
}) => {
  const classes = useStyles();
  const symbolData = useSymbolMetadata(symbol);
  const isUseqSymbol = isUseq(symbolData.assetClass);
  const isJpeqSymbol = isJpeq(symbolData.assetClass);
  const [setSelectedMarketboard] = useActions([setSelected]);
  return (
    <>
      <DesktopHelpBar route={'marketboard'} />
      <div className={classes.marketBoardHeader}>
        <div id={'ticker'} className={classes.ticker}>
          <SymbolDetailHeader symbol={symbol} displayTradeStatus={true} />
        </div>
        <div>
          <div id={'tradeBar'} className={classes.tradeBar}>
            <TradeButtons symbol={symbol} isUseq={isUseqSymbol} />
            <PageSubtitle show={isJpeqSymbol} symbolData={symbolData} />
          </div>
          <div id={'dropdown'} className={classes.dropdown}>
            <MarketBoardDropdownSelect
              boards={boards}
              activeBoard={board}
              onAddClick={() => handleAddBoardDialogOpen(true)}
              onDeleteClick={() => handleDeleteBoardDialogOpen(true)}
              onRenameClick={() => handleRenameBoardDialogOpen(true)}
              onBoardClick={(boardId) => {
                setSelectedMarketboard(boardId);
                // Save Board Id and Navigate to the marketboard with the given id.
                saveLastMarketboard(boardId);
                window.location.hash = '#/marketboard/' + boardId;
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const ProMarketBoardHeader = React.memo(MarketBoardHeader);
