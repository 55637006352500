const {
  simpleMovingAverage,
  getFilteredBarValuesWithSameTimestamps
} = require('./utils');

function getAvgTrueRangeData(bars, fullBarData, period) {
  let closeBars = fullBarData.map(bar => bar[4]);
  let highBars = fullBarData.map(bar => bar[2]);
  let lowBars = fullBarData.map(bar => bar[3]);
  let prevClose = null;
  let trueRanges = [];
  for (let i = 0; i < highBars.length; i++) {
    if (!prevClose) {
      trueRanges.push(null);
    } else {
      trueRanges.push(
        Math.max(highBars[i], prevClose) - Math.min(lowBars[i], prevClose)
      );
    }
    prevClose = closeBars[i];
  }
  let values = simpleMovingAverage(trueRanges, period);
  return getFilteredBarValuesWithSameTimestamps(
    bars,
    fullBarData,
    values,
    true
  );
}

function getAvgTrueRangeSeries(periods, bars, fullBarData, localize) {
  if (!fullBarData) {
    return [];
  }

  let result = [];
  let period = periods[0];

  result.push({
    id: 'ATR',
    name: localize({ id: 'symbol.details.chart.tooltip.avgTrueRange' }).replace(
      'XX',
      period
    ),
    type: 'line',
    color: '#0055cc',
    yAxis: 'avgTrueRange',
    data: getAvgTrueRangeData(bars, fullBarData, period),
    tooltip: { yDecimals: 2 },
    zIndex: 1
  });

  return result;
}

module.exports = {
  getSeries: getAvgTrueRangeSeries,
  // Added for testing
  getAvgTrueRangeData: getAvgTrueRangeData
};
