import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as HelpIcon } from '../../icons/Icon-help.svg';
import { ReactComponent as EditIcon } from '../../icons/Icon-Edit.svg';

const useStyles = makeStyles({
  helpIcons: {
    float: 'right'
  },
  icon: {
    width: '18px',
    height: '18px',
    paddingRight: '10px',
    cursor: 'pointer'
  }
});

export const MobileHelpBar = ({ disableEdit, editUrlHash }) => {
  const styles = useStyles();

  return (
    <div id={'marketboard-help-bar'} className={styles.helpIcons}>
      {!disableEdit && (
        <span
          id={'mobile-help-bar-help-button'}
          className={styles.icon}
          onClick={() => (window.location.hash = editUrlHash)}
        >
          <EditIcon />
        </span>
      )}
      <span
        id={'mobile-help-bar-edit-button'}
        className={styles.icon}
        onClick={() =>
          (window.location =
            'https://info.monex.co.jp/sp/help/insight/marketboard.html')
        }
      >
        <HelpIcon />
      </span>
    </div>
  );
};
