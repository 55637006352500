import { useState, useEffect } from 'react';

export function useCurrPage() {
  const [currPage, setCurrPage] = useState('market');

  useEffect(() => {
    let url = window.location.href;
    if (url.includes('marketboard')) {
      setCurrPage('marketboard');
    } else if (url.includes('symbol')) {
      setCurrPage('symbol_details');
    } else if (url.includes('market')) {
      setCurrPage('market_summary');
    } else if (url.includes('news')) {
      setCurrPage('news');
    } else if (url.includes('hotlists')) {
      setCurrPage('hotlists');
    } else if (url.includes('scan')) {
      setCurrPage('scans');
    } else if (url.includes('tool')) {
      setCurrPage('tool');
    } else if (url.includes('mail')) {
      setCurrPage('mail');
    } else {
      setCurrPage('marketboard');
    }
  }, [window.location.href]);

  return currPage;
}
