import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import ScanResultSymbolItem from './ScanResultSymbolItem';
import PaginationBoxView from '../common/customMaterial/Pagination';
import { getResultsForCurrentPage } from '../common/customMaterial/Pagination/PaginateCommon.js';

const _ = require('lodash');
const ITEMS_PER_PAGE = 10;

// props
//   scanResults

class NewScansResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      displayedSymbols: getResultsForCurrentPage(
        this.props.scanResults,
        1,
        ITEMS_PER_PAGE
      )
    };
  }

  handlePageClick = data => {
    // data.selected is 0-indexed
    let currPage = data.selected + 1;

    this.setState({
      currPage: currPage,
      displayedSymbols: getResultsForCurrentPage(
        this.props.scanResults,
        currPage,
        ITEMS_PER_PAGE
      )
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (_.isEqual(this.props, prevProps) && _.isEqual(this.state, prevState)) {
      return;
    }

    this.setState({
      displayedSymbols: getResultsForCurrentPage(
        this.props.scanResults,
        this.state.currPage,
        ITEMS_PER_PAGE
      )
    });
  }

  render() {
    let totalResults = this.props.scanResults
      ? this.props.scanResults.length
      : 0;

    return (
      <div>
        {/* This iterates over each symbol in the results to display,
            and renders a ScanResultSymbolItem for each.
            this.state.displayedSymbols contains the subsection of results
            appropriate for the currently displayed page, the full results
            are stored in this.state.scanResult. */}
        <div className="scan-card-symbols">
          {this.state.displayedSymbols &&
            this.state.displayedSymbols.map(result => {
              return (
                <ScanResultSymbolItem result={result} key={result.symbol} />
              );
            })}
        </div>
        {this.props.scanResults && (
          <div className="pagination-container">
            <PaginationBoxView
              itemsPerPage={ITEMS_PER_PAGE}
              totalDataSize={totalResults}
              onPageChange={this.handlePageClick}
              previousLabel={'<'}
              nextLabel={'>'}
              breakClassName={'break-me'}
              marginPagesDisplayed={1}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(NewScansResults);
