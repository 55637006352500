import { useEffect } from 'react';

const accountId = process.env.REACT_APP_NEWRELIC_ACCOUNT_ID;
const applicationId = process.env.REACT_APP_NEWRELIC_APP_ID;
const trustKey = process.env.REACT_APP_NEWRELIC_TRUST_KEY;
const apiKey = process.env.REACT_APP_NEWRELIC_API_KEY;
const environment = process.env.REACT_APP_ENVIRONMENT;
const eventUrl = process.env.REACT_APP_NEWRELIC_EVENT_ENDPOINT;

function replaceAll(str, search, replacement) {
  let regex = new RegExp(search, 'g');
  return str.replace(regex, replacement);
}

export const useNewRelic = () => {
  useEffect(() => {
    async function fetchAndInjectScript() {
      try {
        const response = await fetch('/newRelic.txt');

        if (!response.ok) {
          // Its fine if this fails. Sending this data should not impact the system
          console.error('Error loading script from text');
          return;
        }

        const text = await response.text();

        if (text) {
          let scriptText = text;
          //The application ID shows up multiple times, so the replace all was added
          // Currently Account ID and Trust Key are the same, but I wasn't sure if
          // this was always the case, so I split them out. Additional variables
          // can easily be added if needed.
          scriptText = replaceAll(
            scriptText,
            '__applicationId__',
            applicationId
          );
          scriptText = replaceAll(scriptText, '__accountId__', accountId);
          scriptText = replaceAll(scriptText, '__trustKey__', trustKey);

          let script = document.createElement('script');
          script.text = scriptText;
          document.head.appendChild(script);

          return () => {
            document.head.removeChild(script);
          };
        }
      } catch (error) {
        console.error('There was a problem:', error.message);
      }
    }

    fetchAndInjectScript();
  }, []);

  return window.newRelic;
};

export const setApplicationVersion = (version) => {
  // These are both used for slightly different things, so we will use both
  if (
    window.newrelic &&
    typeof window.newrelic.setApplicationVersion === 'function'
  ) {
    window.newrelic.setApplicationVersion(version);
  } else {
    console.error(
      'New Relic is not initialized or setApplicationVersion is not a function'
    );
  }

  if (window.newrelic && typeof window.newrelic.addRelease === 'function') {
    window.newrelic.addRelease('Popsicle', version);
  } else {
    console.error(
      'New Relic is not initialized or addRelease is not a function'
    );
  }
};

export const setUserId = (userId) => {
  if (window.newrelic && typeof window.newrelic.setUserId === 'function') {
    window.newrelic.setUserId(userId);
  } else {
    console.error(
      'New Relic is not initialized or setUserId is not a function'
    );
  }
};

export const eventLogger = () => {
  let lastLoggedData = null;
  const additionalEventData = {
    applicationId: applicationId,
    service: 'Popsicle',
    environment: environment ?? 'unknown'
  };

  const send = async (eventDataJson) => {
    console.log('eventDataJson', eventDataJson);

    if (eventDataJson === lastLoggedData) {
      return;
    }

    lastLoggedData = eventDataJson;

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (apiKey) {
      headers.append('Api-Key', apiKey);
    }

    try {
      console.log('about to parse eventDataJson', typeof eventDataJson);
      // Parse to object and assign additional log data
      const eventDataObject = JSON.parse(JSON.stringify(eventDataJson));
      console.log('eventDataObject', eventDataObject);
      const body = JSON.stringify({
        ...eventDataObject,
        ...additionalEventData
      });

      console.log('body', body);

      const response = await fetch(eventUrl ?? 'error', {
        method: 'POST',
        headers: headers,
        body: body
      });

      if (!response.ok) {
        console.error('New Relic event API responded with an error');
        console.error(JSON.stringify(response));
      }
    } catch (err) {
      let errorMessage = 'New Relic event API responded with an unknown error';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      console.error(errorMessage);
    }
  };

  return {
    sendEvent: (eventDataJson) => send(eventDataJson)
  };
};
