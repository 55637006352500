import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import HelpIcon from '../../icons/Icon-help.svg';

// Since we are using React Router v4, in order to pass props to the component,
// we need to use the render function:
// https://ui.dev/react-router-pass-props-to-components
export const HelpBar = () => (
    <div>
        <Switch>
            <Route
                path="/marketboard/edit"
                render={() => <PopsicleMobileHelpbar />}
            />
            <Route
                path="/marketboard/list"
                render={() => <PopsicleMobileHelpbar />}
            />
        </Switch>
    </div>
);

const PopsicleMobileHelpbar = () => {
    return (
        <>
            <div
                id="gnavi-mobile-help-bar"
                className={'gnavi-mobile gnavi-mobile-help-append'}
            >
                <a
                    href="https://info.monex.co.jp/sp/help/insight/marketboard.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img className={'gnavi-mobile-help-icon'} src={HelpIcon} alt={'Help'} />
                    <span className={'gnavi-mobile-help-link'}>
          {<FormattedMessage id="helpBar.help" />}
        </span>
                </a>
            </div>
        </>
    );
};
