import React, { useEffect, useMemo } from 'react';
import useActions from '../../utils/hooks/useActions';
import { fetchSymbolIfNeeded } from '../../actions/symbol';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

import ListAddIcon from '../../icons/Icon-Board-Add.svg';
import ListDeleteIcon from '../../icons/Icon-List-Delete.svg';
import UndoIcon from '../../icons/Icon-Undo.svg';
import { marketboardFontFamily } from '../../utils/html';
import useLocalize from '../../utils/hooks/useLocalize';
import { useSortable } from '@dnd-kit/sortable';
import { CSS as cssDndKit } from '@dnd-kit/utilities';
import MobileDragHandle from '../common/MobileDragHandle';
 
const useStyles = makeStyles({
  marketboardTitleClass: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#333333'
  },
  deleteHyperlinkClass: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#927A22'
  },
  listItem: {
    background: 'white',
    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
    height: '52px',
    padding: 0
  },
  listItemText: {
    '& span': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      userSelect: 'none'
    }
  },
  listItemTextPrimary: {
    width: 'calc(100% - 112px)',
    '& span': {
      paddingLeft: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      userSelect: 'none'
    }
  },
  addButton: {},
  undoButtonMiddle: {
    display: 'inline-block',
    textAlign: 'center',
    position: 'relative',
    top: '-5px',
    flexGrow: 100
  },
  restoreButton: {
    position: 'relative',
    top: 4,
    margin: 'auto'
  }
});

const SymbolAddEditRow = ({
  id,
  data,
  marketboardId,
  onDeleteClick,
  onUndoClick,
  showUndo
}) => {
  const localize = useLocalize();
  const styles = useStyles();

  // actions
  const [getSymbol] = useActions([fetchSymbolIfNeeded]);

  // Redux Store
  const symbolData = useSelector(
    (state) =>
      ((state.symbols.data || { data: {} } || {})[data.name] || {}).data || null
  );
  const isLoading = !symbolData || symbolData.isLoading;
  const containsSymbol = !!data.containsSymbol;

  useEffect(() => {
    getSymbol(data.name);
  }, [containsSymbol, getSymbol, data]);

  const addButton = useMemo(
    () => (
      <IconButton
        id="add-button"
        className={styles.addButton}
        href={'#/marketboard/edit/' + marketboardId + '/' + data.position}
        size="large"
      >
        <img src={ListAddIcon} alt={'add'} />
      </IconButton>
    ),
    [data.position, marketboardId, styles, ListAddIcon]
  );

  const deleteButton = useMemo(
    () => (
      <IconButton
        id="delete-button"
        onClick={() => onDeleteClick(data.position)}
        style={{ paddingRight: 5 }}
        size="large"
      >
        <img src={ListDeleteIcon} alt={'delete'} />
      </IconButton>
    ),
    [data.position, onDeleteClick, ListDeleteIcon]
  );

  const undoButton = useMemo(
    () => (
      <IconButton
        id="undo-button"
        className={styles.restoreButton}
        onClick={() => onUndoClick(data.position)}
        size="large"
      >
        <img src={UndoIcon} alt={'undo'} />
      </IconButton>
    ),
    [data.position, styles, onUndoClick, UndoIcon]
  );

  return data.containsSymbol ? (
    <ListItemWithSymbol
      id={id}
      styles={styles}
      localize={localize}
      isLoading={isLoading}
      symbolData={symbolData}
      deleteButton={deleteButton}
    />
  ) : (
    <ListItemWithoutSymbol
      id={id}
      styles={styles}
      addButton={addButton}
      undoButton={undoButton}
      showUndo={showUndo}
    />
  );
};

export default SymbolAddEditRow;

const ListItemWithSymbol = ({
  id,
  styles,
  deleteButton,
  localize,
  isLoading,
  symbolData
}) => {
  const { setNodeRef, transform, transition, isDragging, listeners } =
    useSortable({ id });

  const transformString = cssDndKit.Transform.toString(transform);

  const primary = useMemo(() => {
    if (!isLoading) {
      if (symbolData && symbolData.errorCode) {
        return localize('message.status.load-failed');
      } else {
        return symbolData.name || symbolData.jpShortName;
      }
    }

    return localize('message.status.loading');
  }, [localize, isLoading, symbolData]);

  const dragHandle = useMemo(
    () => (
      <ListItemIcon>
        <MobileDragHandle handleListeners={listeners} />
      </ListItemIcon>
    ),
    [listeners]
  );

  const deleteIconItem = useMemo(
    () => <ListItemIcon>{deleteButton}</ListItemIcon>,
    [deleteButton]
  );

  const listItemText = useMemo(
    () => (
      <ListItemText primary={primary} className={styles.listItemTextPrimary} />
    ),
    [primary, styles]
  );

  const listItem = useMemo(() => {
    const style = {
      transform: transformString,
      transition,
      zIndex: isDragging ? '100' : 'auto',
      boxShadow: isDragging ? '0 4px 12px rgba(0,0,0,0.25)' : 'none'
    };

    return (
      <ListItem
        ref={setNodeRef}
        style={style}
        className={styles.listItem}
        id="marketboard-edit-symbol-row"
      >
        {deleteIconItem}
        {listItemText}
        {dragHandle}
      </ListItem>
    );
  }, [
    styles,
    setNodeRef,
    transformString,
    transition,
    isDragging,
    deleteIconItem,
    listItemText,
    dragHandle
  ]);

  return listItem;
};

const ListItemWithoutSymbol = ({
  id,
  styles,
  addButton,
  undoButton,
  showUndo
}) => {
  const { setNodeRef, transform, transition, isDragging, listeners } =
    useSortable({ id });

  const transformString = cssDndKit.Transform.toString(transform);

  const dragHandle = useMemo(
    () => (
      <ListItemIcon>
        <MobileDragHandle handleListeners={listeners} />
      </ListItemIcon>
    ),
    [listeners]
  );

  const addUndoButton = useMemo(
    () => (
      <>
        {addButton}
        <span className={styles.undoButtonMiddle}>{undoButton}</span>
      </>
    ),
    [styles, addButton, undoButton]
  );

  const showUndoButton = useMemo(
    () => (showUndo ? addUndoButton : addButton),
    [addButton, addUndoButton, showUndo]
  );

  const listItemText = useMemo(
    () => (
      <ListItemText
        className={styles.listItemText}
        style={{ display: 'flex' }}
        primary={showUndoButton}
      />
    ),
    [styles, showUndoButton]
  );

  const listItem = useMemo(() => {
    const style = {
      transform: transformString,
      transition,
      zIndex: isDragging ? '100' : 'auto',
      boxShadow: isDragging ? '0 4px 12px rgba(0,0,0,0.25)' : 'none'
    };

    return (
      <ListItem
        ref={setNodeRef}
        style={style}
        className={styles.listItem}
        id="marketboard-edit-symbol-row"
      >
        {listItemText}
        {dragHandle}
      </ListItem>
    );
  }, [
    styles,
    setNodeRef,
    transformString,
    transition,
    isDragging,
    listItemText,
    dragHandle
  ]);

  return listItem;
};
