import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const MessageBar = ({ open, onClose, message, messageType }) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClose={onClose}
      autoHideDuration={3000}
    >
      <Alert severity={messageType}>{message}</Alert>
    </Snackbar>
  );
};

export default MessageBar;
