import logger from './logger';

function getRedirectUrl() {
  return (
    process.env.REACT_APP_MONEX_REDIRECT_URL ||
    'https://mst.monex.co.jp/pc/ITS/login/LoginIDPassword.jsp'
  );
}

// At this point we received an error from one of the endpoints.
// So, it could be an invalid/expired token or another type of error.
// If it's a token related error, redirect to the login page.
// Otherwise, throw an error with the message passed.
export function redirectToLoginOrThrowError(response, message) {
  return response.json().then((res) => {
    // If the token is expired, redirect to login page
    if (
      res.errorCode === 'TOKEN_EXPIRED' ||
      res.errorCode === 'TOKEN_INVALID' ||
      res.errorCode === 'TOKEN_MISSING'
    ) {
      // The location.replace function was used in order to unit test it
      window.location.replace(getRedirectUrl());
      return;
    }

    // Else throw error
    logger.error({
      errorCode: 'E0040',
      error: response,
      message: 'Error requesting from endpoint',
      url: response.url,
      status: response.status
    });
    throw Error(message, response);
  });
}

export function redirectToLoginIfRequired(res) {
  if (
    res.code === 'TOKEN_EXPIRED' ||
    res.code === 'TOKEN_INVALID' ||
    res.code === 'TOKEN_MISSING'
  ) {
    // The location.replace function was used in order to unit test it
    window.location.replace(getRedirectUrl());
  }
}

export function handleErrors(response) {
  if (!response.ok) {
    return redirectToLoginOrThrowError(response, response.statusText);
  }

  return response;
}

export function sanitizeString(word, limit = 30) {
  let sanitizedString = word;
  sanitizedString = sanitizedString.replace(/[&\/\\#$%<>{}]/g, '');
  if (sanitizedString.length > limit) {
    sanitizedString = sanitizedString.slice(0, limit);
  }
  return sanitizedString;
}

export function isMonexApp() {
  return window.navigator.userAgent.includes('hybrid.monex.co.jp');
}

export function getSometaroUrl() {
  let hostname =
    process.env.REACT_APP_SOMETARO_HOSTNAME || 'insight.monex.co.jp';
  return `https://${hostname}`;
}

export function isMonexUser(user) {
  const subDomain = (user || {}).dm || '';
  return subDomain.startsWith('mxp');
}

// Example: https://mxp3.monex.co.jp/
export function isRedirectedFromMonex() {
  const referrer = document.referrer;
  if (!referrer) {
    return false;
  }

  return new URL(referrer).hostname.match(/mxp\d\.monex\.co\.jp/i);
}

export function addToArrayReplaceOldestIfExceeds(item, array, limit) {
  let arrayCopy = [...array]

  if(arrayCopy.length >= limit) {
    arrayCopy.splice(0, arrayCopy.length - limit + 1)
  }

  arrayCopy.push(item)
  return arrayCopy
}

export function removeJpFromSymbols(symbols) {
  return symbols.map(symbol => symbol.replace('JP:', ''))
}