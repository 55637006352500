import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getHotlistStateId, fetchHotlistIfNeeded } from '../../actions/hotlist';
import PriceRankingsTopFiveRow from './PriceRankingsTopFiveRow';
import { FormattedMessage } from 'react-intl';

const AMOUNT_SYMBOLS_TO_SHOW = 5;

function getExchange(exchange) {
  if (!exchange || exchange === 'allexchanges') {
    return null;
  }

  return exchange.toUpperCase();
}

class PriceRankingsTopFive extends Component {
  componentDidMount() {
    this.props.actions.getHotlist(
      this.props.hotlistName,
      getExchange(this.props.exchange)
    );
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.exchange === this.props.exchange) {
      return;
    }

    this.props.actions.getHotlist(
      this.props.hotlistName,
      getExchange(this.props.exchange)
    );
    this.setState({
      exchange: this.props.exchange
    });
  };

  componentWillUnmount() {}

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  getContent() {
    // Show error message
    if (this.props.hotlist && this.props.hotlist.isError) {
      return (
        <div className="no-content loading">
          <FormattedMessage id="message.status.error" />
        </div>
      );
    }

    // Show loading message
    if (!this.props.hotlist || this.props.hotlist.isLoading) {
      return (
        <div className="no-content loading">
          <FormattedMessage id="message.status.loading" />
        </div>
      );
    }

    // Show price rankings
    let hotlistData = (this.props.hotlist || {}).data || {};

    // If there was a response from the server but the number of results
    // is zero, without this message we show a blank box with no information
    // informing the user.  This way we at least have a message on the screen.
    if (hotlistData.results.length <= 0) {
      return (
        <div className="no-content loading">
          <FormattedMessage id="message.no.symbols.found" />
        </div>
      );
    }

    return hotlistData.results
      .slice(0, AMOUNT_SYMBOLS_TO_SHOW)
      .map((i, index) => {
        // Index is 0-indexed
        return (
          <PriceRankingsTopFiveRow
            symbol={i.symbol}
            key={i.symbol}
            columns={hotlistData.columns}
            data={i.values}
            hotlistName={this.props.hotlistName}
            rank={index + 1}
          />
        );
      });
  }

  render() {
    return (
      <div className="market_summary_symbol--ranking">
        <div className={'market_summary_symbol--title_price_ranking'}>
          {this.localize(
            `market.summary.price.rankings.type.${this.props.hotlistName}`
          )}
        </div>
        {this.getContent()}
      </div>
    );
  }
}
PriceRankingsTopFive = injectIntl(PriceRankingsTopFive);

function mapStateToProps(state, ownProps) {
  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };

  let stateId = getHotlistStateId(
    ownProps.hotlistName,
    getExchange(ownProps.exchange)
  );
  props.hotlist = state.hotlists[stateId];
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getHotlist: bindActionCreators(fetchHotlistIfNeeded, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceRankingsTopFive);
