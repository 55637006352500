import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Number from '../common/Number';
import Time from '../common/Time';
import { useSelector } from 'react-redux';
import { start, stop } from '../../actions/stream';
import useActions from '../../utils/hooks/useActions';
import { selectChannel } from '../../selectors/channelSelectors';

const i18nKeyPrefix = 'symbol.details.session.details.';
const rowTypes = ['open', 'high', 'low', 'last', 'volume'];

const getSymbolSession = (symbol, letter) => {
  return symbol.replace('-', `.${letter}-`);
}

const SessionDetailsSection = ({ symbol }) => {
  // Actions
  const [startStream] = useActions([start], []);
  const [stopStream] = useActions([stop], []);

  // Redux
  const metadata = useSelector(
    (state) => state.symbols.data || { data: {} } || {}
  );

  // Intl
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: i18nKeyPrefix + id });

  const startAllStreams = useCallback(() => {
    startStream(`${symbol}/quote`);
    startStream(`${getSymbolSession(symbol, 'M')}/quote`);
    startStream(`${getSymbolSession(symbol, 'A')}/quote`);
  }, [startStream, symbol]);

  const stopAllStreams = useCallback(() => {
    stopStream(`${symbol}/quote`);
    stopStream(`${getSymbolSession(symbol, 'M')}/quote`);
    stopStream(`${getSymbolSession(symbol, 'A')}/quote`);
  }, [stopStream, symbol]);

  const channel = useMemo(() => `${symbol}/quote`, [symbol]);
  const channelMorning = useMemo(() => `${getSymbolSession(symbol, 'M')}/quote`, [symbol]);
  const channelAfternoon = useMemo(() => `${getSymbolSession(symbol, 'A')}/quote`, [symbol]);

  const quote = useSelector((state) => selectChannel(state, channel));
  const morning = useSelector((state) => selectChannel(state, channelMorning));
  const afternoon = useSelector((state) => selectChannel(state, channelAfternoon));

  const symbolMetadata = (metadata[symbol] || {}).data || {};
  const symbolPrecision = symbolMetadata.precision || 0; 

  useEffect(() => {
    startAllStreams();

    return () => {
      stopAllStreams();
    };
  }, [symbol, startAllStreams, stopAllStreams]);
  
  const generateRowValueArray = (type) => {
    let timeVariableName = type + 'Time';
    let precision = symbolPrecision;
    if (type === 'volume') {
      timeVariableName = 'lastTime';
      precision = null;
    }

    let returnArray = [
      {
        value: quote[type] === 0 ? '' : quote[type],
        precision: precision
      },
      {
        value:
          quote[timeVariableName] === 0 ? '' : quote[timeVariableName]
      },
      {
        value: morning[type] === 0 ? '' : morning[type],
        precision: precision
      },
      {
        value:
          morning[timeVariableName] === 0
            ? ''
            : morning[timeVariableName]
      },
      {
        value:
          afternoon[type] === 0 ? '' : afternoon[type],
        precision: precision
      },
      {
        value:
          afternoon[timeVariableName] === 0
            ? ''
            : afternoon[timeVariableName]
      }
    ];

    if (type === 'last') {
      returnArray[0].value =
        quote['lastTime'] === '00:00:00' ? '' : quote['last'];
      returnArray[2].value =
        morning['lastTime'] === '00:00:00'
          ? ''
          : morning['last'];
      returnArray[4].value =
        afternoon['lastTime'] === '00:00:00'
          ? ''
          : afternoon['last'];
    }

    return returnArray;
  }

  const getCellValue = (valueObj, index, isTimeColumn, isLastColumn) => {
    if (isTimeColumn) {
      return (
        <div
          className={`symbol-margin-table-cell-time${
            isLastColumn ? ' last-column' : ''
          }`}
          key={index}
        >
          {valueObj.value === undefined ||
          valueObj.value === '00:00:00' ? null : (
            <Time value={valueObj.value} />
          )}
        </div>
      );
    }

    let className = valueObj.signed
      ? 'market_summary_symbol__net-change-value'
      : null;
    return (
      <div
        className={`symbol-margin-table-cell symbol-margin-information-table-cell-value session-detail 
                    ${isLastColumn ? ' last-column' : ''}`}
        key={index}
      >
        <Number
          className={className}
          value={valueObj.value}
          precision={valueObj.precision}
        />
      </div>
    );
  }

  const getRow = (valueObj) => {
    const title = valueObj.title ? (
      <div className='symbol-margin-information-table-cell-title'>
        {localize(valueObj.title)}
      </div>
    ) : null;

    let lastColumnIndex = valueObj.values.length - 1;

    return (
      <div className='exchange-summary-detail-table-row'>
        {title}
        {valueObj.values.map((e, i) =>
          getCellValue(e, i, i % 2, i === lastColumnIndex)
        )}
      </div>
    );
  }

  return (
    <div className='symbol-margin-information-section'>
      <div className='section-header symbol-indicators-title'>
        {localize('title')}
      </div>
      <div className='symbol-indicator-fundamental-analysis-details'></div>
      <div className='symbol-indicator-fundamental-analysis-details'>
        <div className='symbol-margin-information-title'></div>
        <div className='exchange-summary-detail-table'>
          <div className='exchange-summary-detail-table-heading'>
            <div className='exchange-summary-detail-table-row'>
              <div className='exchange-summary-detail-table-head'>&nbsp;</div>
              <div className='exchange-summary-detail-table-head session-detail'>
                {localize('total')}
              </div>
              <div className='exchange-summary-detail-table-head empty'>
                &nbsp;
              </div>
              <div className='exchange-summary-detail-table-head session-detail'>
                {localize('morning')}
              </div>
              <div className='exchange-summary-detail-table-head empty'>
                &nbsp;
              </div>
              <div className='exchange-summary-detail-table-head session-detail'>
                {localize('afternoon')}
              </div>
              <div className='exchange-summary-detail-table-head empty last-column'>
                &nbsp;
              </div>
            </div>
          </div>
          <div className='exchange-summary-detail-table-body'>
            {rowTypes.map(rowType =>
              getRow({
                title: rowType,
                values: generateRowValueArray(rowType)
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionDetailsSection;