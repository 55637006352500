import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';
import useActions from '../../utils/hooks/useActions';
import { deleteUserMemo, saveUserMemo } from '../../actions/memos';
import { marketboardFontFamily } from '../../utils/html';

const MEMO_MAX_LENGTH = 200;
const SAVE_MEMO_DEBOUNCE_TIME = 1000;

const useStyles = makeStyles({
  note: {
    minHeight: '50px',
    border: '1px solid #DDDDDD',
    padding: '0px 8px 17px 8px',
    margin: '3px',
    borderRadius: '4px',
    background: 'rgba(0, 0, 0, 0.05)',
    color: '#999999',
    decoration: 'none'
  },
  charCounter: {
    float: 'right',
    position: 'relative',
    top: '-8px',
    left: '-3px'
  },
  detailsHeading: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#666666',
    paddingRight: '5px',
    paddingLeft: '3px',
    paddingBottom: '5px',
    textAlign: 'left'
  },
  memoDateTime: {
    float: 'right'
  }
});

const textarea = {
  width: '100%',
  height: '100%',
  border: 'none',
  resize: 'none',
  background: 'transparent',
  fontFamily: marketboardFontFamily,
  fontStyle: 'normal',
  fontWeight: '300',
  fontSize: '14px',
  lineHeight: '16px !important'
};

export const MobileNote = ({ memo, timestamp, symbol }) => {
  const styles = useStyles();
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });
  const [memoText, setMemoText] = useState(memo || '');
  const [memoTextPrevious, setMemoTextPrevious] = useState(memo || '');
  const [memoOpen, setMemoOpen] = useState(false);
  const [saveMemo, deleteMemo] = useActions([saveUserMemo, deleteUserMemo], []);

  const updateMemo = useCallback(
    (memo) => {
      if (!memo) {
        deleteMemo(symbol);
        return;
      }

      saveMemo(symbol, encodeURIComponent(memo));
    },
    [symbol, deleteMemo, saveMemo]
  );
  
  const debouncedSaveMemo = useMemo(
    () => debounce(updateMemo, SAVE_MEMO_DEBOUNCE_TIME),
    [updateMemo]
  );

  const onNoteChange = (e) => {
    const newValue = e.target.value;
    // Value hasn't changed
    if (newValue === memoText) {
      return;
    }
    setMemoTextPrevious(memoText);
    setMemoText(newValue);
  };

  useEffect(() => {
    if (memoText === memoTextPrevious) {
      return;
    }

    debouncedSaveMemo(memoText);
  }, [memoText, memoTextPrevious, debouncedSaveMemo]);

  const headingLabel = <span>{localize('symbol.note.header.note')}</span>;

  // https://momentjs.com/docs/#/parsing/is-valid/
  const formattedDate = !moment(timestamp).isValid()
    ? ''
    : moment(timestamp).tz('Asia/Tokyo').format('YYYY/M/D HH:mm');
  const memoTimestamp = memoText ? formattedDate : '';

  const dateTimeLabel = (
    <span className={styles.memoDateTime}>{memoTimestamp}</span>
  );
  const heading = (
    <div className={styles.detailsHeading}>
      {headingLabel}
      {dateTimeLabel}
    </div>
  );

  const memoBody = (
    <div id={'note-body'} className={styles.note}>
      <TextField
        placeholder={localize('symbol.note.body.placeholder')}
        fullWidth={true}
        multiline={true}
        rows={memoOpen ? 7 : null}
        maxRows={7}
        value={memoText}
        inputProps={{ maxLength: MEMO_MAX_LENGTH, style: textarea }}
        InputProps={{ disableUnderline: true }}
        variant="standard"
        onFocus={() => setMemoOpen(true)}
        onBlur={() => setMemoOpen(false)}
        onChange={onNoteChange}
      />
      <div className={styles.charCounter}>
        {`${memoText.length}/${MEMO_MAX_LENGTH}`}
      </div>
    </div>
  );

  return (
    <>
      {heading}
      {memoBody}
    </>
  );
};
