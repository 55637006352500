import React from 'react';
import OpenIcon from '@mui/icons-material/OpenInNew';

class SymbolDetailLinkRow extends React.PureComponent {
  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div className={'symbol-stats-row'}>
        <a href={this.props.link} onClick={this.props.onClick}>
          <div className={'symbol-stats-label'}>
            {this.props.icon}
            {<span className="symbol-detail-link">{this.props.label}</span>}
          </div>
          <div className={'symbol-stats-value'}>
            {
              <OpenIcon
                style={{
                  padding: 0,
                  height: 20,
                  width: 20,
                  verticalAlign: 'middle',
                  color: '#555'
                }}
              />
            }
          </div>
        </a>
      </div>
    );
  }
}

export default SymbolDetailLinkRow;
