import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import SymbolSummary from './SymbolSummary';
import TSENews from './TSENews';
import { MarketOverviewContainer } from './MarketOverviewContainer';
import PriceRankings from './PriceRankings';
import { DesktopHelpBar } from '../common/DesktopHelpbar';
import _ from 'lodash';
import {
  domesticSymbolData,
  internationalSymbolData,
  forexSymbolData
} from './ViewTemplate';
import IndustryRankings from './IndustryRankings';
import withTracker from '../../utils/analytics';
import { Tabs } from '../common/Tabs';
import NewsContainer from '../news/NewsContainer';
import { withDesktopWidth } from '../../utils/hocs/withDesktopWidth';
import { setDocumentTitle } from '../../utils/html';

const RECENT_NEWS_AMOUNT = 4;

const tabValues = ['domestic', 'international', 'forex'];

class MarketSummaryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleTabChange = this.handleTabChange.bind(this);
    this.setTitle = this.setTitle.bind(this);
  }

  componentWillMount() {}

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  handleTabChange(event, value) {
    let index = _.findIndex(tabValues, function (tab) {
      return tab === value;
    });

    // It was noticed that when trying to view any news from the domestic tab and using the `?tab=domestic`
    // search parameter of the history props, the news dialog won't be displayed as expected. For that reason,
    // when viewing the domestic tab, we won't be setting any search parameter in order for the news dialog
    // to work as expected.
    if (index === 0) {
      this.props.history.push({
        pathname: '/market',
        search: ''
      });
      // Since we are not showing any news links on the other two tabs, we can safely set the search parameter.
    } else if (index > 0) {
      this.props.history.push({
        pathname: '/market',
        search: '?tab=' + value
      });
    }

    this.setState({
      selectedTab: index
    });
  }

  buildSymbolSummaryFromTemplate(viewTemplate) {
    return viewTemplate.map((data, i) => {
      let isLast = viewTemplate.length - 1 <= i;
      return (
        <div
          className={`market_summary_symbol__container${isLast && ' last'}`}
          key={i}
        >
          <SymbolSummary
            user={this.props.user}
            symbolsName={this.props.symbolsName}
            categorySymbolData={data}
          />
        </div>
      );
    });
  }

  setTitle(tabIndex) {
    // handle cases where tab is not set in query params
    tabIndex = Math.max(0, tabIndex);

    const selectedTab = tabValues[tabIndex];
    setDocumentTitle(this.localize(`document.title.market.summary.${selectedTab}`));
  }

  render() {
    this.setTitle(this.state.selectedTab);

    const GlobalNewsButton = () => {
      return (
        <Link to={'/news'}>
          <div className="actions">
            <span className="margin button btn-cmn global-news">
              {this.props.intl.formatMessage({
                id: 'market.summary.global.news.link'
              })}
            </span>
          </div>
        </Link>
      );
    };

    return (
      <>
      {this.props.isDesktopWidth && (
        <div className="section-desktop-help-bar">
          <DesktopHelpBar
            route={'market'}
          />
        </div>
      )}

      <div className="market-summary-container">
        <Tabs
          tabValues={tabValues}
          selectedTabIndex={this.state.selectedTab}
          handleTabChange={this.handleTabChange}
          labelKeyPrefix={'market.summary.type.'}
        />
        {!this.state.selectedTab && (
          <div>
            {this.buildSymbolSummaryFromTemplate(domesticSymbolData)}
            <MarketOverviewContainer />
            <TSENews />
            <PriceRankings />
            <IndustryRankings />
            <NewsContainer
              newsAmount={RECENT_NEWS_AMOUNT}
              hideShowMore={true}
              displaySearch={false}
              isWidget={true}
              isDisableRelatedSymbols={true}
            />
            <GlobalNewsButton />
          </div>
        )}
        {this.state.selectedTab === 1 &&
          this.buildSymbolSummaryFromTemplate(internationalSymbolData)}
        {this.state.selectedTab === 2 &&
          this.buildSymbolSummaryFromTemplate(forexSymbolData)}
      </div>
      </>
    );
  }
}

MarketSummaryContainer = injectIntl(MarketSummaryContainer);

function mapStateToProps(state, ownProps) {
  if (!state.user) {
    return {};
  }

  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };
  props.hotlists = state.hotlists;
  props.user = state.user;
  return props;
}

export default connect(mapStateToProps)(withTracker(withDesktopWidth(MarketSummaryContainer)));
