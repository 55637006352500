import React from 'react';
import Time from '../common/Time';
import DateComponent from '../common/DateComponent';

// This component provides a panel to be used on the Symbol Details
// page for showing data.  Mandatory props are `label`,
// which is the smaller text on the left-hand side, and `value`,
// which is the number or text to be displayed on the right-hand
// side.  Often, the `value` prop will be a Number component
// passed in by the parent, to give as much flexibility as
// possible. There are also optional parameters, if the prop `date`
// is passed in, a DateComponent will be added in the header.
// This `date` prop should be a string in ISO 8601 format as
// needed by the DateComponent.  If the prop `timestamp` is passed
// in, a Time component will be added in the header instead.  This
// `timestamp` prop should be a string in the format `HH:MM:SS`
// as required by the Time component.  Only the `timestamp` or the
// `date` component should be passed in, as they are displayed in the
// same place on the card.
// If a `flag` prop is passed in, the flag will be displayed to the
// left of the value for maximum consistency and ease of
// readability.
class DataPanel extends React.PureComponent {
  render() {
    if (!this.props.show) {
      return null;
    }

    let timestamp,
      date = null;
    if (this.props.timestamp) {
      timestamp = (
        <div className={'symbol-stats-timestamp'}>
          <Time value={this.props.timestamp} />
        </div>
      );
    }

    if (this.props.date) {
      date = (
        <div className={'symbol-stats-date'}>
          <DateComponent value={this.props.date} />
        </div>
      );
    }

    return (
      <div className={'data-panel'}>
        <div className={'data-panel-header'}>
          <div className={'data-panel-header-label'}>{this.props.label}</div>
          <div className={'data-panel-header-timestamp'}>
            {timestamp}
            {date}
          </div>
        </div>
        <div className={'data-panel-value'}>
          {this.props.flag}
          {this.props.value}
        </div>
      </div>
    );
  }
}

export default DataPanel;
