export const domesticSymbolData = [
  {
    categoryNameKey: 'market.summary.symbollist.domestic',
    symbols: [
      {
        symbolCode: 'JP:$MNK',
        flagCode: 'jp'
      },
      {
        symbolCode: '@OSENK',
        flagCode: 'jp'
      },
      {
        symbolCode: '@NIY',
        flagCode: 'jp'
      },
      {
        symbolCode: '@SGXNK',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$0000-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$0500-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$0501-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$0502-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$8507-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$0503-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$A820-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$8802-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$8801-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$0070-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$0075-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$0002-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$0003-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: 'JP:$0004-TS',
        flagCode: 'jp'
      },
      {
        symbolCode: '$DJI',
        flagCode: 'us'
      },
      {
        symbolCode: '$COMPX',
        flagCode: 'us'
      },
      {
        symbolCode: '$INX',
        flagCode: 'us'
      },
      {
        symbolCode: 'USDJPY',
        flagCode: 'us'
      },
      {
        symbolCode: 'JP:$M000001',
        flagCode: 'cn'
      },
      {
        symbolCode: 'JP:$MHSI',
        flagCode: 'hk'
      }
    ]
  }
];

export const internationalSymbolData = [
  {
    categoryNameKey: 'market.summary.symbollist.international',
    symbols: [
      {
        symbolCode: '$DJI',
        flagCode: 'us'
      },
      {
        symbolCode: '$COMPX',
        flagCode: 'us'
      },
      {
        symbolCode: '$NDX.X',
        flagCode: 'us'
      },
      {
        symbolCode: '$INX',
        flagCode: 'us'
      },
      {
        symbolCode: '@YM',
        flagCode: 'us'
      },
      {
        symbolCode: '@ES',
        flagCode: 'us'
      },
      {
        symbolCode: '$VIX.X',
        flagCode: 'us'
      },
      {
        symbolCode: '$BRTI',
        flagCode: 'us'
      },
      {
        symbolCode: 'JP:$M000001',
        flagCode: 'cn'
      },
      {
        symbolCode: 'JP:$M000003',
        flagCode: 'cn'
      },
      {
        symbolCode: 'JP:$MHSI',
        flagCode: 'hk'
      },
      {
        symbolCode: 'JP:$MHSCCI',
        flagCode: 'hk'
      },
      {
        symbolCode: 'JP:$MKOSPI',
        flagCode: 'kr'
      },
      {
        symbolCode: 'JP:$MWTIND',
        flagCode: 'tw'
      },
      {
        symbolCode: 'JP:$MVNX',
        flagCode: 'vn'
      },
      /*{
        symbolCode: 'JP:$MCOMPOSITE',
        flagCode: 'id'
      },*/
      {
        symbolCode: 'JP:$MSENSEX30',
        flagCode: 'in'
      },
      {
        symbolCode: 'JP:$MXAO',
        flagCode: 'au'
      },
      {
        symbolCode: 'JP:$MUKX',
        flagCode: 'gb'
      },
      {
        symbolCode: 'DE:$DAX',
        flagCode: 'de'
      },
      {
        symbolCode: 'JP:$MCAC40',
        flagCode: 'fr'
      },
      {
        symbolCode: 'JP:$MSSIP',
        flagCode: 'ch'
      },
      {
        symbolCode: 'JP:$MRTSI',
        flagCode: 'ru'
      },
      {
        symbolCode: 'JP:$MXU100',
        flagCode: 'tr'
      },
      {
        symbolCode: 'JP:$MBVSP',
        flagCode: 'br'
      },
      {
        symbolCode: 'JP:$MERVAL',
        flagCode: 'ar'
      }
    ]
  },
  {
    categoryNameKey: 'market.summary.symbollist.commodities',
    symbols: [
      {
        symbolCode: '@CL',
        flagCode: 'oil'
      },
      {
        symbolCode: '@GC',
        flagCode: 'gold'
      }
      /*{
        symbolCode: '$TRCCRB',
        flagCode: 'wheat'
      }*/
    ]
  },
  {
    categoryNameKey: 'market.summary.symbollist.exchange',
    symbols: [
      {
        symbolCode: 'USDJPY',
        flagCode: 'us'
      },
      {
        symbolCode: 'EURJPY',
        flagCode: 'eu'
      },
      {
        symbolCode: 'AUDJPY',
        flagCode: 'au'
      },
      {
        symbolCode: 'CNYJPY',
        flagCode: 'cn'
      }
    ]
  }
];

export const forexSymbolData = [
  {
    categoryNameKey: 'market.summary.symbollist.exchange',
    symbols: [
      {
        symbolCode: 'USDJPY',
        flagCode: 'us'
      },
      {
        symbolCode: 'EURJPY',
        flagCode: 'eu'
      },
      {
        symbolCode: 'EURUSD',
        flagCode: 'eu'
      },
      {
        symbolCode: 'AUDJPY',
        flagCode: 'au'
      },
      {
        symbolCode: 'NZDJPY',
        flagCode: 'nz'
      },
      {
        symbolCode: 'GBPJPY',
        flagCode: 'gb'
      },
      {
        symbolCode: 'CHFJPY',
        flagCode: 'ch'
      },
      {
        symbolCode: 'CADJPY',
        flagCode: 'ca'
      },
      {
        symbolCode: 'CNYJPY',
        flagCode: 'cn'
      },
      {
        symbolCode: 'USDCNH',
        flagCode: 'us'
      },
      {
        symbolCode: 'HKDJPY',
        flagCode: 'hk'
      },
      {
        symbolCode: 'KRWJPY',
        flagCode: 'kr'
      },
      {
        symbolCode: 'SGDJPY',
        flagCode: 'sg'
      },
      {
        symbolCode: 'INRJPY',
        flagCode: 'in'
      },
      {
        symbolCode: 'RUBJPY',
        flagCode: 'ru'
      },
      {
        symbolCode: 'TRYJPY',
        flagCode: 'tr'
      },
      {
        symbolCode: 'BRLJPY',
        flagCode: 'br'
      },
      {
        symbolCode: 'MXNJPY',
        flagCode: 'mx'
      },
      {
        symbolCode: 'ZARJPY',
        flagCode: 'za'
      }
    ]
  },
  {
    categoryNameKey: 'market.summary.symbollist.bond',
    symbols: [
      {
        symbolCode: 'TMBMKJP.03Y.YIELD',
        flagCode: 'jp'
      },
      {
        symbolCode: 'TMBMKJP.05Y.YIELD',
        flagCode: 'jp'
      },
      {
        symbolCode: 'TMBMKJP.10Y.YIELD',
        flagCode: 'jp'
      },
      {
        symbolCode: 'BMUS.2Y.YIELD',
        flagCode: 'us'
      },
      {
        symbolCode: 'BMUS.5Y.YIELD',
        flagCode: 'us'
      },
      {
        symbolCode: 'BMUS.10Y.YIELD',
        flagCode: 'us'
      },
      {
        symbolCode: 'BMDE.10Y.YIELD',
        flagCode: 'de'
      },
      {
        symbolCode: 'BMGB.10Y.YIELD',
        flagCode: 'gb'
      },
      {
        symbolCode: 'BMAU.10Y.YIELD',
        flagCode: 'au'
      }
    ]
  }
];
