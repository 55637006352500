import React from 'react';
import { injectIntl } from 'react-intl';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import RadioIcon from '@mui/icons-material/Brightness1Outlined';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const UNIT_MAP = {
  0: 'none',
  1: 'yen',
  2: 'days',
  3: 'percent',
  4: 'multiple',
  5: 'points',
  6: 'shares'
};

const RANGE = 0;
const SELECT = 2;
const MULTISELECT = 3;

class ScanCriteriaSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lowerError: false,
      upperError: false
    };
    this.errorChecker = this.errorChecker.bind(this);
  }

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  optionHandler(optionName, field, value) {
    switch (this.props.subcategoryData.Input) {
      case RANGE:
        this.props.rangeCriteriaHandler(
          this.props.subcategoryName,
          field,
          value
        );
        break;
      case SELECT:
        this.props.selectCriteriaHandler(
          this.props.subcategoryName,
          optionName
        );
        break;
      case MULTISELECT:
      default:
        this.props.multiselectCriteriaHandler(
          this.props.subcategoryName,
          optionName
        );
        break;
    }
  }

  criteriaIsNotSelected(criteria) {
    if (!this.props.selectedOptions) {
      return true;
    }

    switch (this.props.subcategoryData.Input) {
      case RANGE:
        break;
      case SELECT:
        return this.props.selectedOptions !== criteria;
      case MULTISELECT:
      default:
        return this.props.selectedOptions.indexOf(criteria) === -1;
    }
  }

  buildRangeLabel(unit, field, magnitude) {
    // Oku Yen
    if (magnitude === 100000000) {
      let prefix = this.localize('scan.criteria.range.billion');
      return (
        prefix +
        this.props.intl
          .formatMessage(
            {
              id: `scan.criteria.range.label.${field}`
            },
            this.localize(`scan.criteria.range.${UNIT_MAP[unit]}`)
          )
          .trim()
      );
    } else {
      return this.props.intl
        .formatMessage(
          {
            id: `scan.criteria.range.label.${field}`
          },
          this.localize(`scan.criteria.range.${UNIT_MAP[unit]}`)
        )
        .trim();
    }
  }

  errorChecker(field, target) {
    let value = target.value;
    // https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
    let badInput = target.validity.badInput;
    let isNumber = !isNaN(Number(value));

    if (field === 'lower') {
      this.setState({
        lowerError: badInput || !isNumber
      });
    } else {
      this.setState({
        upperError: badInput || !isNumber
      });
    }
  }

  render() {
    let optionsDisplay = null;

    // There's probably a better way this can be done,
    // this should be revisted after it's a bit more final.
    switch (this.props.subcategoryData.Input) {
      case RANGE:
        let lower = this.props.selectedOptions
            ? this.props.selectedOptions.lower
            : null,
          upper = this.props.selectedOptions
            ? this.props.selectedOptions.upper
            : null;
        if (
          this.props.subcategoryName === 'SMACrossover_5_25' ||
          this.props.subcategoryName === 'SMACrossover_25_5'
        ) {
          optionsDisplay = (
            <div className={'number-input-wrapper wide'}>
              <TextField
                className={'number-input'}
                type="number"
                onChange={event => {
                  this.errorChecker('upper', event.target);
                }}
                onBlur={event => {
                  this.errorChecker('upper', event.target);
                  this.optionHandler(null, 'upper', event.target.value);
                }}
                error={this.state.upperError}
                helperText={
                  this.state.upperError
                    ? this.localize('scan.label.invalidinput')
                    : null
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {this.localize('scan.criteria.range.smacrossover.prefix')}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {this.localize('scan.criteria.range.smacrossover.suffix')}
                    </InputAdornment>
                  )
                }}
                defaultValue={upper}
                variant="standard"
              />
            </div>
          );
        } else {
          optionsDisplay = (
            <div>
              <div className={'number-input-wrapper'}>
                <TextField
                  className={'number-input'}
                  type="number"
                  error={this.state.lowerError}
                  onChange={event => {
                    this.errorChecker('lower', event.target);
                  }}
                  onBlur={event => {
                    this.errorChecker('lower', event.target);
                    this.optionHandler(null, 'lower', event.target.value);
                  }}
                  helperText={
                    this.state.lowerError
                      ? this.localize('scan.label.invalidinput')
                      : this.buildRangeLabel(
                          this.props.subcategoryData.Units,
                          'lower',
                          this.props.subcategoryData.Magnitude
                        )
                  }
                  defaultValue={lower}
                  variant="standard"
                />
              </div>
              <div className={'number-input-wrapper'}>
                <TextField
                  className={'number-input'}
                  type="number"
                  error={this.state.upperError}
                  onChange={event => {
                    this.errorChecker('upper', event.target);
                  }}
                  onBlur={event => {
                    this.errorChecker('upper', event.target);
                    this.optionHandler(null, 'upper', event.target.value);
                  }}
                  helperText={
                    this.state.upperError
                      ? this.localize('scan.label.invalidinput')
                      : this.buildRangeLabel(
                          this.props.subcategoryData.Units,
                          'upper',
                          this.props.subcategoryData.Magnitude
                        )
                  }
                  defaultValue={upper}
                  variant="standard"
                />
              </div>
            </div>
          );
        }
        break;
      case SELECT:
      case MULTISELECT:
      default:
        let options = Object.keys(this.props.subcategoryData.Options);
        optionsDisplay = options.map(option => {
          let optionData = this.props.subcategoryData.Options[option];
          let unselected = this.criteriaIsNotSelected(optionData.Name);
          let icon = <CheckIcon />;
          // Single select
          if (this.props.subcategoryData.Input === 2) {
            icon = <RadioIcon />;
          }
          return (
            <Chip
              key={optionData.Name}
              label={this.localize(
                `scan.criteria.${this.props.subcategoryName}.${optionData.Name}`
              )}
              onClick={() => {
                this.optionHandler(optionData.Name);
              }}
              icon={icon}
              className={unselected ? 'un-selected' : 'selected'}
            />
          );
        });
        break;
    }

    return (
      <div className={'scan-subcategory-selector'}>
        <div className={'title-bar'}>
          <div className={'title'}>
            {this.localize(`scan.criteria.${this.props.subcategoryData.Name}`)}
          </div>
          <div className={'delete-button'}>
            <ClearIcon
              onClick={() =>
                this.props.removeItemHandler(this.props.subcategoryName)
              }
            />
          </div>
        </div>

        <div className={'button-selection-group'}>{optionsDisplay}</div>
      </div>
    );
  }
}

ScanCriteriaSelector = injectIntl(ScanCriteriaSelector);

export default ScanCriteriaSelector;
