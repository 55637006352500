const { getFilteredBarValuesWithSameTimestamps } = require('./utils');

const barMap = {
  timestamp: 0,
  high: 2,
  low: 3,
  close: 4
};

// Calculates a parabolic stop-and-reversal indicator, which is plotted
// as a series of dots that either provides a line of support (below the
// asset price) or resistance (above the asset price). A change in the
// placement of the points relative to the asset price indicates a buy
// signal (if transitioning from resistance to support) or a sell signal
// (if transitioning from support to resistance).
// This is ported from theTradeStation SDK which is ported from the
// TradeStation platform's Easy Language definition, which results in the
// high cyclic complexity rating.
function getParabolicStopAndReverse(bars) {
  var longPosition = false;
  var highestHigh = 0;
  var lowestLow = 0;
  var highestHighs = [];
  var lowestLows = [];
  var closingStop = 0;
  var openingStop = 0;
  var accelerationFactor = 0;
  var i = 0;
  var lastBar = null;
  var returnValues = [];

  // The amount by which the acceleration factor is increased during a
  // continuing trend. Must be greater than zero. Default: 0.02.
  var accelerationFactorStep = 0.02;

  // The maximum allowable value for the acceleration factor. Must be
  // greater than the acceleration factor step amount. Default: 0.2
  var maxAccelerationFactor = 0.2;

  bars.forEach(bar => {
    let high = bar[barMap.high];
    let low = bar[barMap.low];
    if (i === 0) {
      highestHigh = high;
      lowestLow = low;

      // Previously we we're adding the timestamp
      // and the null, and the timestamp was wrongly
      // being used as the first value in the list.
      // Since the calculation originally included
      // a null value as the first value, we will
      // leave that in. 
      returnValues.push(null);
    } else {
      if (i === 1) {
        openingStop = high;
      }

      if (high > highestHigh) {
        highestHigh = high;
      }

      if (low < lowestLow) {
        lowestLow = low;
      }

      if (longPosition) {
        if (low <= openingStop) {
          longPosition = false;
          closingStop = highestHigh;
          highestHigh = high;
          lowestLow = low;
          accelerationFactor = accelerationFactorStep;
          openingStop =
            closingStop + accelerationFactor * (lowestLow - closingStop);
          if (openingStop < high) {
            openingStop = high;
          }

          if (i > 0 && openingStop < lastBar[barMap.high]) {
            openingStop = lastBar[barMap.high];
          }
        } else {
          closingStop = openingStop;
          if (
            highestHigh > (i === 0 ? 0 : highestHighs[i - 1]) &&
            accelerationFactor < maxAccelerationFactor
          ) {
            accelerationFactor = Math.min(
              accelerationFactor + accelerationFactorStep,
              maxAccelerationFactor
            );
          }

          openingStop =
            closingStop + accelerationFactor * (highestHigh - closingStop);
          if (openingStop > low) {
            openingStop = low;
          }

          if (i > 0 && openingStop > lastBar[barMap.low]) {
            openingStop = lastBar[barMap.low];
          }
        }
      } else {
        if (high >= openingStop) {
          longPosition = true;
          closingStop = lowestLow;
          highestHigh = high;
          lowestLow = low;
          accelerationFactor = accelerationFactorStep;
          openingStop =
            closingStop + accelerationFactor * (highestHigh - closingStop);
          if (openingStop > low) {
            openingStop = low;
          }

          if (i > 0 && openingStop > lastBar[barMap.low]) {
            openingStop = lastBar[barMap.low];
          }
        } else {
          closingStop = openingStop;
          if (
            lowestLow < (i === 0 ? Number.MAX_VALUE : lowestLows[i - 1]) &&
            accelerationFactor < maxAccelerationFactor
          ) {
            accelerationFactor = Math.min(
              accelerationFactor + accelerationFactorStep,
              maxAccelerationFactor
            );
          }

          openingStop =
            closingStop + accelerationFactor * (lowestLow - closingStop);
          if (openingStop < high) {
            openingStop = high;
          }

          if (i > 0 && openingStop < lastBar[barMap.high]) {
            openingStop = lastBar[barMap.high];
          }
        }
      }
      returnValues.push(closingStop);
    }

    highestHighs.push(highestHigh);
    lowestLows.push(lowestLow);
    lastBar = bar;
    i++;
  });

  return returnValues;
}

function getPSARSeries(params, bars, fullBarData, localize) {
  if (!fullBarData) {
    return [];
  }

  let result = [];
  let data = getParabolicStopAndReverse(fullBarData);
  let filteredData = getFilteredBarValuesWithSameTimestamps(
    bars,
    fullBarData,
    data
  );

  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.parabolicSar' }),
    type: 'scatter',
    color: '#d9027c',
    data: filteredData,
    enableMouseTracking: false,
    lineWidth: 0,
    marker: {
      enabled: true,
      radius: 2.5,
      symbol: 'circle'
    },
    yAxis: 0,
    zIndex: 0
  });

  return result;
}

module.exports = {
  getSeries: getPSARSeries
};
