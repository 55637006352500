import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  fetchScan,
  fetchUserScans,
  fetchLastScan,
  deleteScan
} from '../../actions/scan';
import DateTime from '../common/DateTime';
import { Link } from 'react-router-dom';
import ScanItemRangeDetail from './ScanItemRangeDetail';
import withTracker from '../../utils/analytics';
import { getScansLink } from '../../utils/formatter';
import LastScanButton from './LastScanButton';
import MessageBar from '../common/MessageBar';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Add from '@mui/icons-material/Add';
import Search from '@mui/icons-material/Search';
import DeleteDialog from '../common/DeleteDialog';
import { DesktopHelpBar } from '../common/DesktopHelpbar';
import { withDesktopWidth } from '../../utils/hocs/withDesktopWidth';
import { setDocumentTitle } from '../../utils/html';

const defaultScan = 'SecurityType';

class ScansContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySuccessBar: false,
      displayFailureBar: false,
      scanIdToBeDelted: null
    };

    this.handleScanDeleteSuccess = this.handleScanDeleteSuccess.bind(this);
    this.handleScanDeleteFailure = this.handleScanDeleteFailure.bind(this);
    this.onMarketboardDeleteCancel = this.onMarketboardDeleteCancel.bind(this);
    this.setTitle = this.setTitle.bind(this);
  }

  componentWillMount() {}

  getCurrentScan() {
    return (
      new URLSearchParams(this.props.location.search).get('scan') || defaultScan
    );
  }

  componentDidMount() {
    // TODO: The behavior of the scan override (if implemented) and the default scan
    // is expected to change once the settings page gets implemented and needs to control
    // what scan is loaded.  As-is, this code is temporary.
    this.props.actions.getUserScans();
    this.props.actions.getLastScan();
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  deleteScan(scanId) {
    this.props.actions.deleteUserScan(
      scanId,
      this.handleScanDeleteSuccess,
      this.handleScanDeleteFailure
    );
  }

  onScanDeleteOpen(scanId) {
    this.setState({ scanIdToBeDelted: scanId });
  }

  onMarketboardDeleteCancel() {
    this.setState({ scanIdToBeDelted: null });
  }

  handleScanDeleteSuccess() {
    this.setState({ displaySuccessBar: true });
  }

  handleScanDeleteFailure() {
    this.setState({ displayFailureBar: true });
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      displaySuccessBar: false,
      displayFailureBar: false
    });
  };

  buildScan(scan, index, isStatic = false) {
    const ScanResultButton = () => {
      return (
        <Link
          to={{
            pathname: `/scanresult/${scan.id}`,
            state: { userScanIndex: index }
          }}
        >
          <div className="actions">
            <div
              className="margin button btn-cmn scans"
              onClick={this.activateScan}
            >
              <div className="icon">
                <Search fontSize="small" />
              </div>
              <div className="label">
                {this.localize('scan.my.scan.apply.button')}
              </div>
            </div>
          </div>
        </Link>
      );
    };

    return (
      <ul className="scan-cards" key={scan.id}>
        <li className="scan-card">
          <div className={`scan-card-header${isStatic ? ' static' : ''}`}>
            <div className="symbol-name">{scan.name}</div>
            {!isStatic && (
              <IconButton
                className={'delete-scan-button'}
                onClick={() => {
                  this.onScanDeleteOpen(scan.id);
                }}
                size="large">
                <DeleteForeverIcon className={'delete-scan-icon'} />
              </IconButton>
            )}
          </div>
          <div className={`scan-card-column${isStatic ? ' static' : ''}`}>
            {!isStatic && (
              <div className="scan-card-column-register-date">
                {this.props.intl.formatMessage({
                  id: 'scan.my.scan.date'
                })}
                <DateTime value={scan.created} hasYear={true} />
              </div>
            )}
            {scan.fields.map(field => {
              return this.buildScanField(field);
            })}
            <ScanResultButton />
          </div>
        </li>
      </ul>
    );
  }

  buildScanField(field) {
    return (
      <div key={field.id}>
        <div className="scan-card-column-item-title">
          {this.props.intl.formatMessage({
            id: `scan.criteria.${field.id}`
          })}
        </div>
        <div className="scan-card-column-item-detail">
          <ScanItemRangeDetail id={field.id} values={field.values} />
        </div>
      </div>
    );
  }

  buildPCLink() {
    return getScansLink(
      this.props.user.encryptedId,
      this.props.user.hash,
      this.props.user.rf,
      this.props.user.dm,
      this.props.user.attrSrcKey,
      this.props.user.rz
    );
  }

  setTitle() {
    setDocumentTitle(this.localize('document.title.scans'));
  }

  render() {
    this.setTitle();

    let staticScansDisplay = null;
    const newSearchButton = (
      <Link
        to={{
          pathname: `/scanresult`
        }}
      >
        <div className="actions">
          <div
            className="margin button btn-cmn scans"
            onClick={this.activateScan}
          >
            <div className="icon">
              <Add fontSize="small" />
            </div>
            <div className="label">
              {this.localize('scan.my.scan.new.scan')}
            </div>
          </div>
        </div>
      </Link>
    );

    // Only display static scans if they are loaded
    if (this.props.staticScans && this.props.staticScans.length > 0) {
      staticScansDisplay = (
        <div>
          <div className="section-header">
            {this.localize('scan.recommended.scan.title')}
          </div>
          {this.props.staticScans.map((scan, index) => {
            return this.buildScan(scan, index, true);
          })}
        </div>
      );
    }

    const noUserScans = (
      <div className="no-content empty">
        <div>{this.localize('scan.my.scan.no.scans')}</div>
        <div>
          <a href={this.buildPCLink()}>
            {this.localize('scan.my.scan.no.scans.pc.link.1')}
          </a>
          {this.localize('scan.my.scan.no.scans.pc.link.2')}
        </div>
      </div>
    );

    return (
      <>
        {this.props.isDesktopWidth && (
          <div className="section-desktop-help-bar">
            <DesktopHelpBar
              route={'scan'}
            />
          </div>
      )}
      <div className="scan-container">
        <div className="scan-section">
          <div className="scan-header">
            <div className="scan-title">
              {this.localize('scan.title.scanning')}
            </div>
          </div>
          <div className="top-buttons">
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <LastScanButton
                  onClick={this.activateScan}
                  text={this.localize('scan.my.scan.last-scan.label')}
                />
              </Grid>
              <Grid item xs={6}>
                {newSearchButton}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="section-header">
          {this.localize('scan.my.scan.title')}
        </div>
        {this.props.userScans.length > 0
          ? this.props.userScans.map((scan, index) => {
              if (scan) {
                return this.buildScan(scan, index);
              }

              return null;
            })
          : noUserScans}
        {staticScansDisplay}
        <MessageBar
          open={this.state.displaySuccessBar}
          onClose={this.handleSnackbarClose}
          message={this.localize('scan.delete.success')}
          messageType={'success'}
        />
        <MessageBar
          open={this.state.displayFailureBar}
          onClose={this.handleSnackbarClose}
          message={this.localize('scan.delete.failure')}
          messageType={'warning'}
        />
        <DeleteDialog
          titleText={this.props.intl.formatMessage({
            id: 'scan.dialog.delete.title'
          })}
          contentText={this.props.intl.formatMessage({
            id: 'scan.dialog.delete.message'
          })}
          cancelText={this.props.intl.formatMessage({
            id: 'button.label.cancel'
          })}
          confirmText={this.props.intl.formatMessage({
            id: 'button.label.delete'
          })}
          openDialog={this.state.scanIdToBeDelted}
          onCancel={this.onMarketboardDeleteCancel}
          onDelete={() => {
            this.deleteScan(this.state.scanIdToBeDelted);
            this.onMarketboardDeleteCancel();
          }}
        />
      </div>
      </>
    );
  }
}

ScansContainer = injectIntl(ScansContainer);

function mapStateToProps(state) {
  if (!state.user) {
    return {};
  }

  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };

  // Grab user
  props.user = state.user;
  props.userScans = state.scans.userScans || [];
  props.staticScans = state.scans.staticScans || [];

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getScan: bindActionCreators(fetchScan, dispatch),
      getUserScans: bindActionCreators(fetchUserScans, dispatch),
      getLastScan: bindActionCreators(fetchLastScan, dispatch),
      deleteUserScan: bindActionCreators(deleteScan, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTracker(withDesktopWidth(ScansContainer)));
