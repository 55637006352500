import { useSelector } from 'react-redux';

import {
  setIncludeJpeq,
  setIncludeUseq,
  setIncludeSupplementals,
  setIncludeIndustry
} from '../../actions/user';
import useActions from './useActions';

export function useSymbolSearchFlags() {
  // Actions
  const [
    setIncludeJpeqAction,
    setIncludeUseqAction,
    setIncludeSupplementalsAction,
    setIncludeIndustryAction
  ] = useActions([
    setIncludeJpeq,
    setIncludeUseq,
    setIncludeSupplementals,
    setIncludeIndustry
  ]);

  const includeJpeq = useSelector((state) => {
    return (((state.user) || {}).settings || {}).includeJpeq;
  });

  const includeUseq = useSelector((state) => {
    return (((state.user) || {}).settings || {}).includeUseq;
  });

  const includeSupplementals = useSelector((state) => {
    return (((state.user) || {}).settings || {}).includeSupplementals;
  });

  const includeIndustry = useSelector((state) => {
    return (((state.user) || {}).settings || {}).includeIndustry;
  });

  return [
    includeJpeq,
    includeUseq,
    includeSupplementals,
    includeIndustry,
    setIncludeJpeqAction,
    setIncludeUseqAction,
    setIncludeSupplementalsAction,
    setIncludeIndustryAction
  ];
}