function getJapaneseKanjiForDayOfWeek(dayOfWeek, localize) {
  return localize(`symbol.details.chart.tooltip.${dayOfWeek}`);
}

function headerFormatter(barSize, date, localize) {
  return localize(`symbol.details.chart.tooltip.header.${barSize}`, {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1,
    date: date.getUTCDate(),
    dayKanji: getJapaneseKanjiForDayOfWeek(date.getUTCDay(), localize),
    hours: ('0' + date.getUTCHours()).slice(-2),
    minutes: ('0' + date.getUTCMinutes()).slice(-2)
  });
}

function formatRow(label, value, color) {
  // Round to a maximum of five decimal places without padding the end
  value = Math.round(value * 100000) / 100000.0;

  color = color || 'inherit';
  return (
    '<tr><td class="point-label" style="color: ' +
    color +
    ';">' +
    label +
    '</td><td class="point-value">' +
    value +
    '</td></tr>'
  );
}

function candlestickFormatter(rows, point, series, localize) {
  // For candlesticks and OHLC
  rows[rows.length] = formatRow(
    localize('symbol.details.chart.tooltip.open'),
    point.open
  );
  rows[rows.length] = formatRow(
    localize('symbol.details.chart.tooltip.high'),
    point.high
  );
  rows[rows.length] = formatRow(
    localize('symbol.details.chart.tooltip.low'),
    point.low
  );
  rows[rows.length] = formatRow(
    localize('symbol.details.chart.tooltip.close'),
    point.close
  );
}

function ichimokuFormatter(rows, point, series, localize) {
  // Sadly it doesn't appear very easy to get the associated
  // color data out of what is sent to this tooltip, because
  // it's a custom coloring.  In addition, the naming scheme
  // for the objects that contain the data are NOT the same
  // as the names of the lines themselves, thus this mapping.
  let colorMapping = {
    tenkanSen: 'graphtenkanLine',
    kijunSen: 'graphkijunLine',
    chikouSpan: 'graphchikouLine',
    senkouSpanA: 'graphsenkouSpanA',
    senkouSpanB: 'graphsenkouSpanB'
  };
  for (var p = 0; p < point.series.pointArrayMap.length; p++) {
    let pointName = point.series.pointArrayMap[p];
    if (point[pointName] != null) {
      rows[rows.length] = formatRow(
        localize('symbol.details.chart.ichimoku.' + pointName),
        point[pointName],
        point.series[colorMapping[pointName]].stroke
      );
    }
  }
}

function bollingerFormatter(rows, point, series, localize) {
  for (var pp = 0; pp < point.series.pointArrayMap.length; pp += 2) {
    let pointName = point.series.pointArrayMap[pp];
    let midBbName = localize('symbol.details.chart.tooltip.bb.mid');

    if (point[pointName] !== null && series.name !== midBbName) {
      rows.splice(
        rows.length + point.series.userOptions.params.standardDeviation - 3,
        0,
        formatRow(
          localize(
            'symbol.details.chart.tooltip.' + pointName // either '.top' -> '+' or '.bottom' -> '-'
          ) + series.name,
          point[pointName],
          series.color
        )
      );
    } else if (
      point[pointName] !== null &&
      series.name === midBbName &&
      pp !== 2
    ) {
      rows.splice(
        rows.length - 3,
        0,
        formatRow(series.name, point[pointName], series.color)
      );
    }
  }
}

function getVolumeSeries(params) {
  let localize = params.options.externalFunctions.localize;
  return {
    color: '#008c46',
    name: localize('symbol.details.volume'),
    type: 'column',
    visible: true
  };
}

export function tooltipFormatter(params) {
  let pointIndex = this.series.xData.indexOf(this.key);
  let points = this.series.xAxis.series
    .filter((seriesData) => {
      return seriesData.visible && seriesData.points[pointIndex] != null;
    })
    .map((seriesData) => {
      return {
        point: seriesData.points[pointIndex],
        series: seriesData
      };
    });

  if (params.options.variables.realtime) {
    // For range-based charts, data are in processedXData
    pointIndex = this.series.processedXData.indexOf(this.key);

    points = this.series.xAxis.series
    .filter((seriesData) => {
      // Since each series might have different processedXData
      // we have to find the point based on the matching timestamp
      const seriesPointIndex = seriesData.processedXData.indexOf(this.key);
      return seriesData.visible &&  seriesData.points[seriesPointIndex] != null;
    })
    .map((seriesData) => {
      // Since each series might have different processedXData
      // we have to find the point based on the matching timestamp
      const seriesPointIndex = seriesData.processedXData.indexOf(this.key);
      const point = seriesData.points[seriesPointIndex];
      return {
        point: point,
        series: seriesData
      };
    });

    // Since we want to merge the tooltip on the real-time charts,
    // we need to manually add the series for the volume here.
    if (params.options.variables.volume) {
      // First get the index for the volume of the same timestamp we are hovering over
      pointIndex = params.options.variables.volume.map(v => v[0]).indexOf(this.key);
  
      // In case we have volume data for this timestamp, add the point and the volume series.
      if (pointIndex >= 0) {
        points.push({
          point: {
            y: params.options.variables.volume[pointIndex][1]
          },
          series: getVolumeSeries(params)
        });
      }
    }
  }  

  // Because the "this" context here has been completely hijacked by Highcharts,
  // we pass in our localization function through these params.
  let localize = params.options.externalFunctions.localize;

  // Add in a bit of padding to the timestamp, to make sure we've forwarded to
  // the correct 5-minute bar.  This has no impact on daily bars or larger.
  var timestamp = new Date(this.key + 30000);
  var header = headerFormatter(
    params.options.variables.currentBarSize,
    timestamp,
    localize
  );

  var rows = [];
  for (var i = 0; i < points.length; i++) {
    var point = points[i].point;
    var series = points[i].series;
    if (series.type === 'candlestick' || series.type === 'ohlc') {
      candlestickFormatter(rows, point, series, localize);
    } else if (series.type === 'ikh') {
      ichimokuFormatter(rows, point, series, localize);
    } else if (series.type === 'bb') {
      bollingerFormatter(rows, point, series, localize);
    } else if (series.type === 'vbp') {
      // explicitly do not format the vbp
    } else {
      rows[rows.length] = formatRow(series.name, point.y, series.color);
    }
  }

  var markup =
    `<div class="point-timestamp">${header}</div>` +
    '<table>' +
    rows.join('') +
    '</table>';

  return markup;
}
