import React from 'react';
import { useIntl } from 'react-intl';

import Number from '../common/Number';
import { StatusFlag } from '../common/StatusFlag';
import {
  get52WeekHighFlag,
  get52WeekLowFlag
} from '../common/tables/formatters';

import { vwap } from '../../utils/marketboards';
import { makeStyles } from '@mui/styles';
import { calcNetChgPct } from '../../utils/maths';

import {
  DateTimeCellFormatter,
  MarginSideCellRenderer
} from '../common/tables/renderers';

import { ReactComponent as GainIcon } from '../../icons/Icon-Arrow_Gain.svg';
import { ReactComponent as LossIcon } from '../../icons/Icon-Arrow_Loss.svg';
import { ReactComponent as NeutralIcon } from '../../icons/Icon-Arrow_Neutral.svg';
import { marketboardFontFamily } from '../../utils/html';

const gain = '#A64E4A';
const loss = '#4B5CAD';
const neutral = '#666666';

const globalStyles = makeStyles({
  detailsHeading: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#616161',
    paddingRight: '5px',
    paddingLeft: '3px',
    paddingBottom: '5px',
    textAlign: 'left'
  },
  detailsValue: {
    textAlign: 'right',
    float: 'right',
    fontWeight: '400'
  },
  detailsValueFont: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333'
  },
  currentPrice: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#333333',
    float: 'right'
  },
  detailsCell: {
    textAlign: 'left',
    float: 'left',
    width: '50%',
    paddingRight: '3px'
  },
  extendedDetailsBody: {
    marginLeft: '0px',
    marginRight: '0px',
    marginBottom: '5px'
  },
  indicatorIcon: {
    position: 'relative',
    top: '4px'
  },
  netChange: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    '& span': {
      fontFamily: marketboardFontFamily
    }
  },
  netChangePct: {
    paddingLeft: '2px'
  }
});

const CurrentPriceCell = ({ quoteData, close }) => {
  const styles = globalStyles();
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });
  return (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.header.current.price')}
      </span>
      <span className={styles.detailsValue}>
        <Number
          value={close}
          precision={quoteData.precision}
          hideIfZero={true}
          className={styles.currentPrice}
        />
      </span>
    </div>
  );
};

const NetCellChange = ({ quoteData, close }) => {
  const styles = globalStyles();
  const netChange = close - quoteData.prevClose;
  const netChangePct = calcNetChgPct(close, quoteData.prevClose);

  const isNeutral = netChangePct === 0;
  const gainLossArrowIcon = netChange > 0 ? <GainIcon /> : <LossIcon />;
  const arrowIndicator = isNeutral ? <NeutralIcon /> : gainLossArrowIcon;

  const netTemperatureGainLoss = netChange > 0 ? gain : loss;
  const netTemperature = isNeutral ? neutral : netTemperatureGainLoss;

  return (
    <div className={styles.detailsCell}>
      <span className={styles.detailsValue} style={{ color: netTemperature }}>
        <span className={styles.indicatorIcon}>
          {arrowIndicator}
        </span>
        <Number value={netChange} precision={quoteData.precision} />
        <Number
          value={netChangePct}
          precision={2}
          signed={true}
          suffix={'%)'}
          prefix={'('}
          className={styles.netChangePct}
        />
      </span>
    </div>
  );
};

const MobileQuoteCardBodyCash  = ({ quoteData, close, positionData }) => {
  const styles = globalStyles();
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });

  const averagePriceCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.header.cash.average')}
      </span>
      <span className={styles.detailsValue}>
        <Number
          value={positionData.averagePrice}
          precision={0}
          hideIfZero={true}
          className={styles.detailsValueFont}
        />
      </span>
    </div>
  );

  const quantityCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.header.cash.cost.quantity')}
      </span>
      <span className={styles.detailsValue}>
        <Number
          value={positionData.quantity}
          precision={0}
          hideIfZero={true}
          className={styles.detailsValueFont}
          suffix={localize('marketboard.row.tag.size')}
        />
      </span>
    </div>
  );

  const ordersCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.header.cash.orders')}
      </span>
      <span className={styles.detailsValue}>
        <Number
          value={positionData.selling}
          precision={0}
          hideIfZero={true}
          className={styles.detailsValueFont}
          suffix={localize('marketboard.row.tag.size')}
        />
      </span>
    </div>
  );

  return (
    <div className={styles.extendedDetailsBody}>
      {averagePriceCell}
      <CurrentPriceCell quoteData={quoteData} close={close} />
      {quantityCell}
      <NetCellChange quoteData={quoteData} close={close} />
      {ordersCell}
    </div>
  );
};

const MobileQuoteCardBodyMargin  = ({ quoteData, close, positionData }) => {
  const styles = globalStyles();
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });

  const orderPriceCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.header.margin.order')}
      </span>
      <span className={styles.detailsValue}>
        <Number
          value={positionData.openPrice}
          precision={0}
          hideIfZero={true}
          className={styles.detailsValueFont}
        />
      </span>
    </div>
  );

  const quantityCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.header.margin.order.qty')}
      </span>
      <span className={styles.detailsValue}>
        <Number
          value={positionData.quantity}
          precision={0}
          hideIfZero={true}
          className={styles.detailsValueFont}
          suffix={localize('marketboard.row.tag.size')}
        />
      </span>
    </div>
  );

  const sideCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.header.margin.info')}
      </span>
      <span className={styles.detailsValue}>
        <MarginSideCellRenderer value={positionData.side} />
      </span>
    </div>
  );

  const emptyCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}></span>
      <span className={styles.detailsValue}>&nbsp;</span>
    </div>
  );

  const openDateCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.header.open.date')}
      </span>
      <span className={styles.detailsValue}>
        <DateTimeCellFormatter value={positionData.openDate} />
      </span>
    </div>
  );

  return (
    <div className={styles.extendedDetailsBody}>
      {orderPriceCell}
      <CurrentPriceCell quoteData={quoteData} close={close} />
      {quantityCell}
      <NetCellChange quoteData={quoteData} close={close} />
      {sideCell}
      {emptyCell}
      {openDateCell}
    </div>
  );
};

const MobileQuoteCardBodyUser  = ({ quoteData, close }) => {
  const styles = globalStyles();
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });

  const volume = quoteData.volume;
  const volumeWeightedAveragePrice = vwap(quoteData.turnover, quoteData.volume);
  const high = quoteData.high;
  const highFlag = get52WeekHighFlag({ quoteData: quoteData }, localize);
  const low = quoteData.low;
  const lowFlag = get52WeekLowFlag({ quoteData: quoteData }, localize);
  const open = quoteData.open;
  const volumeCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.header.volume')}
      </span>
      <span className={styles.detailsValue}>
        <Number
          value={volume}
          precision={0}
          hideIfZero={true}
          className={styles.detailsValueFont}
        />
      </span>
    </div>
  );

  const vwapCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.header.vwap')}
      </span>
      <span className={styles.detailsValue}>
        <Number
          value={volumeWeightedAveragePrice}
          precision={quoteData.precision}
          hideIfZero={true}
          className={styles.detailsValueFont}
        />
      </span>
    </div>
  );

  const highCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.row.tag.high')}
      </span>
      <span className={styles.detailsValue}>
        {highFlag && (
          <StatusFlag flag={highFlag} direction={'right'} hot={true} />
        )}
        <Number
          value={high}
          precision={quoteData.precision}
          hideIfZero={true}
          className={styles.detailsValueFont}
        />
      </span>
    </div>
  );

  const openCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.row.tag.open')}
      </span>
      <span className={styles.detailsValue}>
        <Number
          value={open}
          precision={quoteData.precision}
          hideIfZero={true}
          className={styles.detailsValueFont}
        />
      </span>
    </div>
  );

  const lowCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.row.tag.low')}
      </span>
      <span className={styles.detailsValue}>
        {lowFlag && (
          <StatusFlag flag={lowFlag} direction={'right'} hot={false} />
        )}
        <Number
          value={low}
          precision={quoteData.precision}
          hideIfZero={true}
          className={styles.detailsValueFont}
        />
      </span>
    </div>
  );

  const closeCell = (
    <div className={styles.detailsCell}>
      <span className={styles.detailsHeading}>
        {localize('marketboard.row.tag.close')}
      </span>
      <span className={styles.detailsValue}>
        <Number
          value={close}
          precision={quoteData.precision}
          hideIfZero={true}
          className={styles.detailsValueFont}
        />
      </span>
    </div>
  );

  return (
    <div className={styles.extendedDetailsBody}>
      {volumeCell}
      {vwapCell}
      {highCell}
      {openCell}
      {lowCell}
      {closeCell}
    </div>
  );
};

export const MobileQuoteCardBody = (props) => {
  switch (props.boardType) {
    case 'cash':
      return <MobileQuoteCardBodyCash {...props}/>;
    case 'margin':
      return <MobileQuoteCardBodyMargin {...props}/>;
    default:
      return <MobileQuoteCardBodyUser {...props}/>;
  }
};
