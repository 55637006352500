import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  HighchartsStockChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Debug,
  Series,
  ColumnSeries
} from 'react-jsx-highstock';
import { tooltipFormatter } from '../../utils/chartTooltip';

import { getSeries as volAvg } from '../../utils/indicators/volAvg';
import { useCallback } from 'react';

const Indicator = ({ getSeries, bars, volume, setting }) => {
  const intl = useIntl();
  const series = useMemo(() => {
    const indicatorLocalize = (data, params) =>
      intl.formatMessage(data, params);

    return getSeries(
      setting.params,
      bars,
      bars,
      indicatorLocalize,
      volume
    );
  }, [getSeries, setting.params, bars, volume, intl]);

  return (
    <>
      {series.map((props) => (
        <Series
          key={props.id}
          showInNavigator={false}
          animation={false}
          {...props}
        />
      ))}
    </>
  );
};

const RealtimeChartVolumeIndicator = (props) => {
  const components = {
    volAvg: [Indicator, volAvg],
  };

  const indicatorData = components[props.type];
  if (!indicatorData) {
    return null;
  }

  const Component = indicatorData[0];
  return <Component getSeries={indicatorData[1]} {...props} />;
};

const RealtimeVolumeChart = ({
  symbol,
  barSize,
  onAfterSetExtremes,
  mappedBars,
  mappedVolumes,
  indicators
}) => {
  // Intl
  const intl = useIntl();
  const localize = useCallback(
    (id, otherParams) => intl.formatMessage({ id: id }, otherParams),
    [intl]
  );

  // These are the indicators that will be rendered
  // as part of the Volume Chart
  const throttledVolumeChartIndicators = useMemo(
    () => (
      <>
        {indicators.map((setting) => (
          <RealtimeChartVolumeIndicator
            key={setting.type}
            bars={mappedBars}
            volume={mappedVolumes}
            type={setting.type}
            setting={setting}
          />
        ))}
      </>
    ),
    [indicators, mappedBars, mappedVolumes]
  );

  return (
    <HighchartsStockChart key={`${symbol}/${barSize}/volume`}>
      {process.env.NODE_ENV === 'development' && <Debug varName="volume"/>}
      <Chart height={100} />
      <Tooltip
        useHTML={true}
        formatter={tooltipFormatter}
        outside={true}
        split={false}
        variables={{
          currentBarSize: barSize,
          realtime: true
        }}
        externalFunctions={{
          localize: localize
        }}
      />

      <XAxis onAfterSetExtremes={onAfterSetExtremes} />
      <YAxis>
        <ColumnSeries
          id='volume'
          name={localize('symbol.details.chart.volume')}
          data={mappedVolumes.map((bar) => {
            return [bar[0], bar[1]];
          })}
          color={'#008c46'}
          groupPadding={0}
        />
        {throttledVolumeChartIndicators}
      </YAxis>
    </HighchartsStockChart>
  );
};

export default RealtimeVolumeChart;
