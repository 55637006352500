import types from './_actionTypes';
import { fetchRetryWithAuth } from '../utils/fetchRetry';

function getFeatureFlagsUrl() {
  // prettier-ignore
  return `https://${process.env.REACT_APP_INSIGHT_API_DOMAIN}/system/features/popsicle`;
}

export function requestingFeatureFlags() {
  return {
    type: types.REQUESTING_FEATURE_FLAGS
  };
}

export function receiveFeaturesFlagResponse(response) {
  return {
    type: types.RECEIVE_FEATURE_FLAGS,
    data: response
  };
}

export function errorRequestingFeatureFlags(error) {
  return {
    type: types.ERROR_REQUESTING_FEATURE_FLAGS,
    error: error,
    errorCode: 'E0041'
  };
}

function isUserInternal(state) {
  const user = state.user || {};
  return user.internal === true;
}

function fetchFeatureFlags(state) {
  let req = {
    method: 'get'
  };

  if ((isUserInternal(state))) {
    req.headers = {
      'X-FEATURE-INTERNAL-ENABLED': true
    };
  }

  return fetchRetryWithAuth(
    req,
    () => {
      return getFeatureFlagsUrl();
    },
    (response) => {
      return receiveFeaturesFlagResponse(response);
    },
    (error) => {
      return errorRequestingFeatureFlags(error);
    }
  );
}

function shouldFetchFeatureFlags(state) {
  const featureFlags = state.featureFlags || {};
  // If it's the first time and there is no data, `featureFlags` will contain an empty object
  if (Object.keys(featureFlags).length === 0) {
    return true;
  }

  if (featureFlags.isError) {
    return true;
  }

  if (featureFlags.isLoading) {
    return false;
  }

  return featureFlags.didInvalidate || false;
}

export function fetchFeatureFlagsIfNeeded() {
  return (dispatch, getState) => {
    let state = getState();
    if (shouldFetchFeatureFlags(state)) {
      dispatch(requestingFeatureFlags());
      return dispatch(fetchFeatureFlags(state));
    }
  };
}