import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SymbolSearchContainer from '../marketBoard/SymbolSearchContainer';
import List from '@mui/material/List';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { FormattedMessage } from 'react-intl';
import Exchange from '../common/Exchange';
import { useSelector } from 'react-redux';
import useActions from '../../utils/hooks/useActions';
import { setComparisonSymbols } from '../../actions/user';
import { isIndustry, isSupplemental } from '../../utils/symbol';

const MAX_SYMBOLS_FOR_COMPARISON = 5;

const useStyles = makeStyles({
  container: {
    width: '100%',
    textAlign: 'center'
  },
  searchResultListItem: {
    paddingLeft: '10px',
    paddingTop: '4px',
    paddingBottom: '4px'
  },
  searchResultListItemPrimary: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  shortName: {
    marginRight: '0.5em'
  },
  errorNotice: {
    color: 'red',
    paddingBottom: '10px'
  }
});

const SymbolChartComparisonSettings = () => {
  const classes = useStyles();

  // Actions
  const [setSymbols] = useActions([setComparisonSymbols]);

  // Redux Store
  const chartSettings = useSelector((state) => state.user.settings.chart || {});
  const comparisonSymbols = chartSettings.comparisonSymbols || {};
  const comparisonSymbolsCount = Object.keys(comparisonSymbols).length;
  const symbolsExceeded = comparisonSymbolsCount >= MAX_SYMBOLS_FOR_COMPARISON;
  const noSymbols = comparisonSymbolsCount === 0;

  const handleSymbolSelect = (symbol, symbolData) => {
    if (!comparisonSymbols[symbol]) {
      comparisonSymbols[symbol] = symbolData;
      setSymbols(comparisonSymbols);
    }
  };
  const handleDelete = (id) => {
    delete comparisonSymbols[id];
    setSymbols(comparisonSymbols);
  };
  const renderPrimary = (symbolData) => {
    return (
      <div className={classes.searchResultListItemPrimary}>
        {symbolData.data.name}
      </div>
    );
  };
  const renderSecondary = (symbolData) => {
    return (
      <span>
        <span className={classes.shortName}>{symbolData.data.name}</span>
        <span className={classes.shortName}>
          {(!isSupplemental(symbolData.data.assetClass) && !isIndustry(symbolData.data.assetClass)) && symbolData.data.root}
        </span>
        <Exchange
          className={classes.shortName}
          name={symbolData.data.exchangeName}
          section={symbolData.data.jpSection}
          assetClass={symbolData.data.assetClass}
        />
      </span>
    );
  };
  const drawSymbolListItem = (symbolData) => {
    return (
      <ListItem
        button
        key={symbolData.symbol}
        value={symbolData.symbol}
        className={classes.searchResultListItem}
      >
        <ListItemText
          primary={renderPrimary(symbolData)}
          secondary={renderSecondary(symbolData)}
        />
        <ListItemIcon
          onClick={() => handleDelete(symbolData.symbol)}
          size="medium"
        >
          <DeleteForeverIcon />
        </ListItemIcon>
      </ListItem>
    );
  };
  const noSymbolsMessage = (
    <FormattedMessage
      id={'symbol.details.settings.comparison.list.nosymbols'}
    />
  );
  const tooManySymbolsMessage = (
    <div className={classes.errorNotice}>
      <FormattedMessage
        id={'symbol.details.settings.comparison.list.toomanysymbols'}
      />
    </div>
  );

  return (
    <div className={classes.container}>
      <div
        className={'chart-style-section chart-style-section-highlight'}
        style={{ textAlign: 'left' }}
      >
        <FormattedMessage id={'settings.chart.comparison.selection.title'} />
      </div>
      <SymbolSearchContainer
        onSelectedSymbol={handleSymbolSelect}
        isDisabled={symbolsExceeded}
      />
      {symbolsExceeded ? tooManySymbolsMessage : null}
      <div
        className={'chart-style-section chart-style-section-highlight'}
        style={{ textAlign: 'left' }}
      >
        <FormattedMessage id={'symbol.details.settings.comparison.list'} />
      </div>
      {noSymbols ? noSymbolsMessage : null}
      <div>
        <List>
          {Object.keys(comparisonSymbols).map((symbol) =>
            drawSymbolListItem(comparisonSymbols[symbol])
          )}
        </List>
      </div>
    </div>
  );
};

export default SymbolChartComparisonSettings;
