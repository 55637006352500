function getExponentialMovingAverage(bars, length) {
  let multiplier = 2 / (length + 1);
  let sum = 0;
  let count = 0;
  let avg = 0;

  let result = [];
  for (let i = 0; i < bars.length; i++) {
    let value = bars[i];
    if (value == null) {
      result.push(null);
      continue;
    }

    count++;
    if (count <= length) {
      sum += value;
    }

    if (count < length) {
      result.push(null);
      continue;
    }

    if (count === length) {
      avg = sum / length;
      result.push(avg);
      continue;
    }

    avg = (value - avg) * multiplier + avg;
    result.push(avg);
  }

  return result;
}

function getMacdValues(
  bars,
  fullBarData,
  fastLength,
  slowLength,
  signalLength
) {
  let periodCloseBars = fullBarData.map(a => a[4]);

  // First, we calculate fast, slow and macd
  let fast = getExponentialMovingAverage(periodCloseBars, fastLength);
  let slow = getExponentialMovingAverage(periodCloseBars, slowLength);
  let macd = [];
  for (let i = 0; i < fast.length; i++) {
    macd.push(fast[i] - slow[i]);
  }

  // Then we locate the index of the first timestamp of the source bar
  // in the fullBars array. This is to know where we can start to trim.
  let sliceIndex = 0;
  for (let i = 0; i < fullBarData.length; i++) {
    if (fullBarData[i][0] === bars[0][0]) {
      sliceIndex = i;
      break;
    }
  }

  // Then, we calculate the signal and time the unnecessary bars to improve
  // response times
  let filteredBars = fullBarData.slice(sliceIndex);
  fast = fast.slice(sliceIndex);
  slow = slow.slice(sliceIndex);
  let signal = getExponentialMovingAverage(macd, signalLength).slice(
    sliceIndex
  );
  macd = macd.slice(sliceIndex);
  let result = {
    macd: [],
    average: [],
    difference: []
  };

  // Since we now have the bars that begin with the same timestamp as the source bars
  // we just need to filter out the EOD elements in case there's any
  for (let i = 0; i < filteredBars.length; i++) {
    // If the last element is not true, then it's an EOD element
    // Bar array: [timestamp, open, high, low, close, isChartBar]
    if (!filteredBars[i][5]) {
      continue;
    }

    let timestamp = filteredBars[i][0];
    result.macd.push([timestamp, macd[i]]);
    result.average.push([timestamp, signal[i]]);
    result.difference.push([timestamp, macd[i] - signal[i]]);
  }

  return result;
}

function getMacdSeries(periods, bars, fullBarData, localize) {
  if (!fullBarData || !fullBarData.length) {
    return [];
  }

  let values = getMacdValues(
    bars,
    fullBarData,
    periods[0],
    periods[1],
    periods[2]
  );
  let result = [];
  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.macd' }),
    type: 'line',
    color: '#5f29cc',
    yAxis: 'macd',
    data: values.macd,
    tooltip: { yDecimals: 2 },
    zIndex: 2
  });

  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.macd.average' }),
    type: 'line',
    color: '#04b0bf',
    yAxis: 'macd',
    data: values.average,
    tooltip: { yDecimals: 2 },
    zIndex: 1
  });

  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.macd.difference' }),
    type: 'column',
    color: '#9daaae',
    yAxis: 'macd',
    data: values.difference,
    tooltip: { yDecimals: 2 },
    zIndex: 0
  });

  return result;
}

module.exports = {
  getSeries: getMacdSeries
};
