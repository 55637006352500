import * as marketSdk from '@monex-securities/insight-market-sdk';
import * as userSdk from '@monex-securities/insight-user-sdk';

const API_URL = process.env.REACT_APP_INSIGHT_API_DOMAIN;

let marketSdkInstance = null;
let userSdkInstance = null;

export function getMarketSdk(token) {
  if (!marketSdkInstance) {
    marketSdkInstance = new marketSdk.Insight({
      token: token,
      apiUrlOverride: API_URL
    });
  }

  return marketSdkInstance;
}

export function getUserSdk(token) {
  if (!userSdkInstance) {
    userSdkInstance = new userSdk.Insight({
      token: token,
      apiUrlOverride: API_URL
    });
  }

  return userSdkInstance;
}
