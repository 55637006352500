import React from 'react';
import { useIntl } from 'react-intl';
import DialogModal from '../common/DialogModal';
import DialogNavigationPosition from '../common/DialogNavigationPosition';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';
import Chart from '../settings/Chart';
import SymbolChartComparisonSettings from './SymbolChartComparisonSettings';
import { useSelector } from 'react-redux';
import useActions from '../../utils/hooks/useActions';
import {
  setDisplayTooltip as setDisplayTooltipAction,
  setChartMode as setChartModeAction
} from '../../actions/user';
import SingleToggle from '../settings/SingleToggle';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import { isMonexApp } from '../../utils/common';
import { useDesktopWidth } from '../../utils/hooks/useDesktopWidth';

const StyledIconButton = withStyles({
  root: {
    position: 'absolute'
  }
})(IconButton);

const ChartMode = {
  Indicators: 'indicators',
  Comparison: 'comparison'
};

export const SymbolSettings = ({
  handleClose,
  pageTitleBar,
  open,
}) => {
  // Hooks
  const intl = useIntl();
  const [setTooltip] = useActions([setDisplayTooltipAction]);
  const [setMode] = useActions([setChartModeAction]);
  const isDesktopWidth = useDesktopWidth();

  // Selectors
  const userSettings = useSelector((state) => {
    return state.user.settings.chart || {};
  });
  const displayTooltip = userSettings.displayTooltip;
  const chartMode = userSettings.chartMode || ChartMode.Indicators;

  // Local Functions
  const handleTabClick = (event, value) => {
    setMode(value);
  };

  const localize = (i18nKey) => {
    return intl.formatMessage({
      id: i18nKey
    });
  };

  const setDisplayTooltip = (event) => {
    let value = event.target.checked;
    setTooltip(value);
  };

  const backButton = (isMonexApp() || isDesktopWidth) ? (
    <CloseIcon style={{ paddingRight: 8, marginTop: 5 }} />
  ) : (
    <ArrowBackIcon style={{ paddingRight: 8, marginTop: 5 }} />
  );

  return (
    <DialogModal
      fullScreen={!isDesktopWidth}
      navigationPosition={DialogNavigationPosition.Top}
      navigation={
        <div>
          <StyledIconButton
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={'symbol-settings-close-button'}
          >
            {backButton}
          </StyledIconButton>
          <div>
            <div className={'symbol-settings-title-bar'}>{pageTitleBar}</div>
          </div>
        </div>
      }
      handleClose={handleClose}
      open={open}
      dialogContentClassName={'dialog-modal-content-nopadding'}
    >
      {/* Tooltip Toggle */}
      <div className="settings-card-contents">
        <SingleToggle
          title={localize('settings.chart.displayTooltip')}
          checked={displayTooltip}
          onChange={setDisplayTooltip}
        />
      </div>
      {/* Chart Mode Toggle (Indicators/Comparison) */}
      <div className="settings-card-contents">
        <span className="settings-label">
          {localize('settings.chart.chartMode.title')}
        </span>
        <div className="settings-option">
          <ToggleButtonGroup
            value={chartMode}
            exclusive
            onChange={handleTabClick}
            size="small"
            sx={{
              '& .Mui-selected': {
                backgroundColor: '#E1C561 !important',
                fontWeight: 'bold'
              }
            }}
          >
            <ToggleButton value={ChartMode.Indicators}>
              {localize('settings.chart.chartMode.indicators')}
            </ToggleButton>
            <ToggleButton value={ChartMode.Comparison}>
              {localize('settings.chart.chartMode.comparison')}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <Divider />
      {/* Render the settings for the current chart mode */}
      <div
        className={'settings-inner-container symbol-setting'}
        hidden={ChartMode.Indicators !== chartMode}
        value={ChartMode.Indicators}
      >
        <Chart chartSectionClassName="chart-style-section-highlight" />
      </div>
      <div
        className={'settings-inner-container symbol-setting'}
        hidden={ChartMode.Comparison !== chartMode}
        value={ChartMode.Comparison}
      >
        <SymbolChartComparisonSettings />
      </div>
    </DialogModal>
  );
};
