import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { postMetric } from '../../utils/userMetrics';

const MAX_AMOUNT_BOARDS = 100;

export const MarketBoardDropdownSelect = ({
  boards,
  activeBoard,
  onAddClick,
  onDeleteClick,
  onRenameClick,
  onBoardClick
}) => {
  // State
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(undefined);

  // Redux Store
  const cashBoards = useSelector(
    (state) => (state.marketboard || {}).cash || []
  );
  const marginBoards = useSelector(
    (state) => (state.marketboard || {}).margin || []
  );

  // Intl
  const intl = useIntl();

  // This is to disable search text box & delete button for Cash & Margin
  const boardId = activeBoard.Id || '';
  const restrictedMode =
    boardId.startsWith('cash') || boardId.startsWith('margin');

  const handleClose = () => {
    setOpen(false);
    setFilter(undefined);
  };

  const handleOpenClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
    setFilter(undefined);
  };

  const handleBoardClick = (boardId) => {
    handleClose();
    if (onBoardClick !== undefined && onBoardClick instanceof Function) {
      onBoardClick(boardId);
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value.length > 0 ? event.target.value : undefined);
    postMetric({
      metricType: 'MarketboardPro',
      metricName: 'SearchMarketboard'
    });
  };

  const getSearchMenuItem = () => (
    <MenuItem
      onKeyDown={(event) => {
        // This is necessary to allow input without triggering
        // MUI's default behavior of navigating to the first
        // element in the menu that starts with the typed letter
        event.stopPropagation();
      }}
      disableTouchRipple
      disableRipple
      sx={{
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '&:focus': {
          backgroundColor: 'transparent'
        },
        '&:focus-within': {
          backgroundColor: 'transparent'
        }
      }}
      className="marketboard-dropdown-filter-item"
    >
      <TextField
        id="standard-basic"
        placeholder={intl.formatMessage({
          id: 'marketboard.title.search'
        })}
        variant="standard"
        size="small"
        onChange={handleFilterChange}
      />
    </MenuItem>
  );

  const getAddMenuItem = () => (
    <MenuItem
      className="marketboard-dropdown-add"
      onClick={() => {
        onAddClick();
        setOpen(false);
      }}
      disabled={boards.length >= MAX_AMOUNT_BOARDS}
    >
      <ListItemText>
        {intl.formatMessage({ id: 'marketboard.menu.board.add' })}
      </ListItemText>
    </MenuItem>
  );

  const getRenameMenuItem = () => (
    <MenuItem
      className="marketboard-dropdown-rename"
      onClick={() => {
        if (!restrictedMode) {
          onRenameClick();
          setOpen(false);
        }
      }}
      disabled={restrictedMode}
    >
      <ListItemText>
        {intl.formatMessage({ id: 'marketboard.menu.board.rename' })}
      </ListItemText>
    </MenuItem>
  );

  const getDeleteMenuItem = () => (
    <MenuItem
      className="marketboard-dropdown-delete"
      onClick={() => {
        if (!restrictedMode) {
          onDeleteClick();
          setOpen(false);
        }
      }}
      disabled={restrictedMode || boards.length <= 1}
    >
      <ListItemText>
        {intl.formatMessage({ id: 'marketboard.menu.board.delete' })}
      </ListItemText>
    </MenuItem>
  );

  const getMarketBoards = () => {
    return boards
      .filter((board) => {
        if (filter) {
          // Filter by name, this does not take into account
          // hiragana/katakana/kanji differences
          return board.Name.toUpperCase().indexOf(filter.toUpperCase()) > -1;
        }

        // If there is no filter, show all boards
        return true;
      })
      .map((board, i) => {
        return (
          <MenuItem
            key={board.Id}
            onClick={() => {
              handleBoardClick(board.Id);
            }}
            className="marketboard-dropdown-user-board"
          >
            {board.Id === activeBoard.Id && (
              // If this is the active board,
              // show a checkmark next to it.
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            {board.Name}
          </MenuItem>
        );
      });
  };

  const getCashBoards = () => {
    return cashBoards
      .filter((board) => {
        if (filter) {
          // Filter by name, this does not take into account
          // hiragana/katakana/kanji differences
          return board.Name.toUpperCase().indexOf(filter.toUpperCase()) > -1;
        }

        // If there is no filter, show all boards
        return true;
      })
      .map((board, i) => {
        return (
          <MenuItem
            key={board.Id}
            onClick={() => {
              handleBoardClick(board.Id);
            }}
            className="marketboard-dropdown-user-board"
          >
            {board.Id === activeBoard.Id && (
              // If this is the active board,
              // show a checkmark next to it.
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            {board.Name}
          </MenuItem>
        );
      });
  };

  const getMarginBoards = () => {
    return marginBoards
      .filter((board) => {
        if (filter) {
          // Filter by name, this does not take into account
          // hiragana/katakana/kanji differences
          return board.Name.toUpperCase().indexOf(filter.toUpperCase()) > -1;
        }

        // If there is no filter, show all boards
        return true;
      })
      .map((board, i) => {
        return (
          <MenuItem
            key={board.Id}
            onClick={() => {
              handleBoardClick(board.Id);
            }}
            className="marketboard-dropdown-user-board"
          >
            {board.Id === activeBoard.Id && (
              // If this is the active board,
              // show a checkmark next to it.
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            {board.Name}
          </MenuItem>
        );
      });
  };

  return (
    <>
      <Select
        defaultValue={0}
        inputProps={{ readOnly: true }}
        onClick={handleOpenClick}
        variant="standard"
        size="small"
        style={{
          padding: '2px 5px 0px 10px',
          marginBottom: '10px',
          width: 295,
          fontSize: '16px',
          border: '1px solid #9E9E9E'
        }}
        className="marketboard-dropdown-primary-select"
        disableUnderline
      >
        <MenuItem value={0} selected>
          {activeBoard.Name}
        </MenuItem>
      </Select>

      <Menu
        id="basic-menu"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          style: {
            maxHeight: 450,
            width: '27ch'
          }
        }}
        className="marketboard-dropdown-menu"
      >
        <MenuList dense>
          {getSearchMenuItem()}
          {getAddMenuItem()}
          {getRenameMenuItem()}
          {getDeleteMenuItem()}
          <Divider />

          {getMarketBoards()}

          {cashBoards.length > 0 ? (
            <div>
              <Divider />
              {getCashBoards()}
            </div>
          ) : null}
          {marginBoards.length > 0 ? (
            <div>
              <Divider />
              {getMarginBoards()}
            </div>
          ) : null}
        </MenuList>
      </Menu>
    </>
  );
};
