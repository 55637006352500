import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getHomeLink } from '../../utils/formatter';
import { withRouter } from 'react-router';

const BreadCrumbFunction = () => {
  const user = useSelector((state) => {
    return ((state.user) || {});
  });
  const homeLink = getHomeLink(user.dm, user.attrSrcKey);
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <BreadCrumbTrail
            pageLabelKey={'marketboard'}
            homeLink={homeLink}
          />
        )}
      />
      <Route
        path="/marketboard"
        render={() => (
          <BreadCrumbTrail
            pageLabelKey={'marketboard'}
            homeLink={homeLink}
          />
        )}
      />
      <Route
        path="/symbol/search"
        render={() => (
          <BreadCrumbTrail
            pageLabelKey={'symbolsearch'}
            homeLink={homeLink}
          />
        )}
      />
      <Route
        path="/symbol/"
        render={() => (
          <BreadCrumbTrail
            pageLabelKey={'symboldetail'}
            homeLink={homeLink}
          />
        )}
      />
      <Route
        path="/news/"
        render={() => (
          <BreadCrumbTrail
            pageLabelKey={'globalnews'}
            homeLink={homeLink}
          />
        )}
      />
      <Route
        path="/settings/"
        render={() => (
          <BreadCrumbTrail
            pageLabelKey={'symboldetail'}
            homeLink={homeLink}
          />
        )}
      />
      <Route
        path="/hotlists"
        render={() => (
          <BreadCrumbTrail
            pageLabelKey={'hotlist'}
            homeLink={homeLink}
          />
        )}
      />
      <Route
        path="/scan"
        render={() => (
          <BreadCrumbTrail
            pageLabelKey={'scan'}
            homeLink={homeLink}
          />
        )}
      />
      <Route
        path="/scanresult"
        render={() => (
          <BreadCrumbTrail
            pageLabelKey={'scan'}
            homeLink={homeLink}
          />
        )}
      />
      <Route
        path="/market"
        render={() => (
          <BreadCrumbTrail
            pageLabelKey={'marketSummary'}
            homeLink={homeLink}
          />
        )}
      />
    </Switch>
  );
}

export const BreadCrumb = withRouter(
  connect()(BreadCrumbFunction)
);

const BreadCrumbTrail = ({
  pageLabelKey,
  homeLink
}) => {
  const intl = useIntl();
  const home = intl.formatMessage({ id: 'breadcrumb.mypage' });
  const info = intl.formatMessage({ id: 'breadcrumb.info' });
  const pageLabel = intl.formatMessage({ id: `breadcrumb.${pageLabelKey}` });
  return (
    <div id="gnavi-desktop-bread-crumb">
      <div className="gnavi-desktop">
        <ul className="breadcrumb">
          <li>
            <a clickcount="pc-cmnhead-home" href={homeLink}>
              {home}
            </a>
            {'>'}
          </li>
          <li>
            <a clickcount="pc-cmnhead-home" href={'#/market'}>
              {info}
            </a>
            {'>'}
          </li>
          <li>{pageLabel}</li>
        </ul>
      </div>
    </div>
  );
}