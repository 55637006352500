import types from '../actions/_actionTypes';
import initialState from './initialState';
import * as time from '../utils/time';

const featureFlags = (state = initialState.featureFlags, action) => {
  let newState;
  switch (action.type) {
    case types.REQUESTING_FEATURE_FLAGS:
      newState = Object.assign({}, state);
      newState = {
        data: null,
        isLoading: true,
        isError: false,
        when: time.timestamp()
      };
      return newState;
    case types.RECEIVE_FEATURE_FLAGS:
      newState = Object.assign({}, state);
      newState = {
        data: action.data.data.system.features,
        isLoading: false,
        isError: false,
        when: time.timestamp()
      };
      return newState;
    case types.ERROR_REQUESTING_FEATURE_FLAGS:
      newState = Object.assign({}, state);
      newState = {
        data: null,
        isLoading: false,
        isError: true,
        when: time.timestamp()
      };
      return newState;
    default:
      return state;
  }
};

export default featureFlags;