import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import PlusIcon from '../../icons/plus-symbol-button.png';
import SelectorArrow from '../../icons/ico-form-select_01.png';

const defaultComponentWidth = 200;
const selectorArrowWidth = 20;
const inputSidePadding = 10;
const totalSidePadding = inputSidePadding * 2; // left + right
const getComponentWidth = (props) =>
  ((props || {}).width || defaultComponentWidth) + 'px';
// For some reason, FF and Safari have different behavior from Chrome in terms
// of rendering HTML input element. Explicitly setting width would make the
// behavior consistent across these browsers
const getInputWidth = (props) =>
  ((props || {}).width || defaultComponentWidth) -
  selectorArrowWidth -
  totalSidePadding +
  'px';

const useStyles = makeStyles({
  searchInput: {
    '& input:focus::placeholder': { color: 'transparent' },
    zIndex: 1200,
    position: 'relative',
    margin: 'auto',
    '& div': {
      width: getComponentWidth,
      'text-align': 'left',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',

      padding: '0 2px 0 10px',
      'box-shadow': 'inset 0 0 4px rgb(1 1 1 / 10%)',
      border: '1px solid #e0e0e0',
      'vertical-align': 'middle',
      background: '#fff',

      height: '26px',
      'line-height': '26px',
      '&.placeholder': {
        'line-height': '26px'
      },

      '& input': {
        border: '0px',
        width: getInputWidth,
        height: '20px',
        fontSize: '13px'
      }
    },
    '& .placeholder': {
      color: '#9fa6af',
      left: '12px',
      'line-height': '27px',
      position: 'absolute',
      'font-size': '13px',

      '&.hidden': {
        display: 'none'
      }
    },
    '& .selector-arrow': {
      width: selectorArrowWidth + 'px',
      float: 'right',
      height: '17px',
      'margin-top': '4px',
      border: 'none',
      background: `#ffffff url(${SelectorArrow}) right center no-repeat`
    }
  }
});

const useStylesPlusIcon = makeStyles({
  searchInput: {
    '& input:focus::placeholder': { color: 'transparent' },
    margin: 'auto',
    '& div': {
      width: getComponentWidth,
      'text-align': 'left',
      display: 'inline-block',
      position: 'relative',

      padding: '0 2px 0 10px',
      borderRight: '1px solid #e0e0e0',
      'vertical-align': 'middle',

      height: '27px',
      'line-height': '27px',
      '&.placeholder': {
        'line-height': '26px'
      },

      '& input': {
        border: '0px',
        width: 'calc(100% - 44px)',
        marginLeft: '17px'
      }
    },
    '& .selector-arrow': {
      width: selectorArrowWidth + 'px',
      position: 'absolute',
      marginLeft: '6px',
      height: '17px',
      'margin-top': '4px',
      border: 'none',
      background: `#ffffff url(${SelectorArrow}) right center no-repeat`
    }
  },
  actions: {
    '-khtml-user-select': 'none',
    '-o-user-select': 'none',
    '-moz-user-select': 'none',
    '-webkit-user-select': 'none',
    'user-select': 'none',

    position: 'absolute',
    '& .action': {
      margin: '2px 2px 2px -5px',
      display: 'inline-block',
      '&.add': {
        background: `transparent url(${PlusIcon}) no-repeat 0 3px`,
        width: '22px',
        height: '22px',
        opacity: '0.6'
      }
    }
  },
  enabled: {
    cursor: 'pointer'
  },
  disabled: {
    cursor: 'default',
    background: '#ddd'
  }
});

const SearchInput = ({
  searchPattern,
  searchInputRef,
  focused,
  onFocus,
  onBlur,
  onKeyDown,
  onChange,
  showSearchSelector,
  disabled,
  searchText,
  isPlusIcon,
  ...props
}) => {
  const plusIconStyles = useStylesPlusIcon(props);
  const styles = useStyles(props);
  const classes = isPlusIcon ? plusIconStyles : styles;

  const plusIcon = useMemo(() => {
    return (
      isPlusIcon &&
      !focused && (
        <td
          className={[classes.actions].join(' ')}
          onClick={() => !disabled && searchInputRef.current.focus()}
        >
          <span className="action add" />
        </td>
      )
    );
  }, [isPlusIcon, focused, classes.actions, disabled, searchInputRef]);

  return (
    <div
      className={[
        classes.searchInput,
        disabled ? classes.disabled : classes.enabled
      ].join(' ')}
    >
      <div>
        {plusIcon}
        <input
          id={'searchInput'}
          ref={searchInputRef}
          className={classes.input}
          type="text"
          value={searchPattern}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={onChange}
          disabled={disabled}
          placeholder={searchText}
        />
        <span
          className="selector-arrow"
          onClick={(e) => !disabled && showSearchSelector(e)}
          disabled={disabled}
        ></span>
      </div>
    </div>
  );
};

export default injectIntl(SearchInput);
