import React, { useState, useEffect, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import useActions from '../../utils/hooks/useActions';
import { fetchArticle } from '../../actions/news';
import { Link } from 'react-router-dom';
import DateTime from '../common/DateTime';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DialogModal from '../common/DialogModal';
import IconButton from '@mui/material/IconButton';
import WrapTextIcon from '@mui/icons-material/WrapText';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import DialogNavigationPosition from '../common/DialogNavigationPosition';
import { fetchSymbolPatternIfNeeded } from '../../actions/symbolSearch';
import { removeJpFromSymbols } from '../../utils/common';
import { useDesktopWidth } from '../../utils/hooks/useDesktopWidth';
import RelatedSymbols from './RelatedSymbols';
import { postMetric } from '../../utils/userMetrics';

const newsProvider = (providerId) => {
  return `news.provider.${providerId}`;
};

const formattedDateOnly = (timestamp) => {
  let date = new Date(timestamp);
  return `${date.toLocaleDateString('ja-JP')}`;
};

const useRelatedSymbols = (relatedSymbols) => {
  return removeJpFromSymbols(JSON.parse(relatedSymbols || '[]'));
};

const useStyles = makeStyles({
  headline: {
    background: '#ffffff',
    borderBottom: '1px solid #e2e2e2',
    borderRadius: '2px',
    paddingLeft: '15px',
    paddingRight: '15px',
    overflow: 'hidden',
    position: 'relative',
    paddingTop: '15px',
    paddingBottom: '10px',
    transition: 'max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94)'
  },
  thinHeadline: {
    background: '#ffffff',
    borderBottom: '1px solid #e2e2e2',
    borderRadius: '2px',
    paddingLeft: '15px',
    paddingRight: '15px',
    overflow: 'hidden',
    position: 'relative',
    paddingTop: '15px',
    paddingBottom: '10px',
    transition: 'max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    maxHeight: 'calc(1rem + 15px)'
  },
  iconStyle: {
    color: '#BCBCBC',
    display: 'inlineBlock',
    marginRight: '0',
    fontSize: '9px',
    fontWeight: 'bold',
    height: '100'
  },
  headlineItem: {
    padding: '5px',
    border: '0px solid #d3d9e0',
    borderTop: '1px solid #d3d9e0'
  },
  headLineItemFirst: {
    border: '0px',
    padding: '5px'
  },
  headlineColumn: {
    width: '100px',
    whiteSpace: 'nowrap'
  },
  relatedSymbolColumn: {
    display: 'flex',
    justifyContent: 'center',
    width: '65px',
    whiteSpace: 'nowrap'
  },
  headlineHeaderColumn: {
    width: '100%'
  },
  articleBody: {
    fontFamily: 'monospace',
    fontSize: '14px'
  },
  articleNavigationCloseButton: {
    float: 'right'
  },
  articleHeader: {
    height: '32px'
  },
  articleTitle: {
    fontWeight: 'bold !important',
    fontSize: '1rem !important',
    borderBottom: '1px solid #bbb'
  },
  articleTimestamp: {
    display: 'inline-block',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  articleProvider: {
    float: 'right',
    backgroundColor: '#9e9e9e',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '7px',
    paddingRight: '7px',
    fontSize: '0.675rem',
    color: 'white'
  },
  wrap: {
    whiteSpace: 'pre-wrap'
  },
  nowrap: {
    paddingTop: '15px',
    overflowX: 'scroll',
    whiteSpace: 'pre'
  },
  relatedSymbolPlusIcon: {
    lineHeight: '15px',
    position: 'absolute',
    right: '-9px'
  },
  headlineLink: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: 'inherit',
    position: 'relative'
  },
  headlineLinkLeft: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  headlineLinkLeftTitle: {
    flexGrow: 1,
    lineHeight: '1.8em'
  },
  headlineLinkLeftProvider: {
    alignSelf: 'flex-end',
    WebkitTextSizeAdjust: '100%',
    msTextSizeAdjust: '90%',
    fontSize: '12px',
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: '1.2rem',
    marginLeft: '-8px',
    padding: '4px 4px 4px 12px'
  },
  headlineLinkRight: {
    display: 'flex',
    alignItems: 'center'
  },
  viewed: {
    color: '#800080 !important'
  },
  odd: {
    background: '#f7f8fa'
  },
  even: {
    background: 'white'
  },
  dialog: {
    width: '100%',
    maxWidth: '1500px',
    height: '80vh'
  },
  desktopDialog: {
    width: '100%',
    maxWidth: '1500px'
  },
  relatedSymbols: {
    fontSize: '0.9rem',
    wordWrap: 'break-word'
  }
});

export default function Headline(props) {
  const intl = useIntl();
  const classes = useStyles();
  const localize = (i18nKey) => intl.formatMessage({ id: i18nKey });
  const index = props.index || 0;
  const isFirst = index === 0;
  const isOdd = index % 2;
  const articleId = props.article.articleId;
  const isDesktopWidth = useDesktopWidth();

  // Redux Store
  const fullArticle = useSelector(
    (state) => (state.news.articles || {})[articleId] || null
  );

  // States
  const [open, setOpen] = useState(false);
  const [wrap, setWrap] = useState(true);
  const user = useSelector((state) => {
    return ((state.user) || {});
  });

  // Actions
  const [getArticle] = useActions([fetchArticle]);
  const [getSymbol] = useActions([fetchSymbolPatternIfNeeded], []);
  const selectedSymbol = props.selectedSymbol || '';
  const symbols = useSelector((state) => state.symbols || {});
  const searchResults = symbols.searches || {};
  const searchResultSymbols =
    (searchResults[selectedSymbol] || {}).symbols || [];

  useEffect(() => {
    if (selectedSymbol && searchResultSymbols.length > 0) {
      window.location.hash = `/symbol/${searchResultSymbols[0]}`;
      props.setSelectedSymbol(null);
    }
  }, [selectedSymbol, searchResultSymbols]);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
    getArticle(props.article.articleId);
    props.addArticlesToLocalStorage(props.article.articleId);
    postMetric({
      metricType: 'News',
      metricName: 'FetchNewsArticle',
      customData: {
        articleId: props.article.articleId,
        userId: user.encryptedId,
        provider: localize(newsProvider(props.article.provider)),
      }
    });
  }, [props, user, getArticle]);

  const handleClose = () => {
    setOpen(false);
  };

  const toggleWrap = () => {
    setWrap(!wrap);
  };

  const isDialogFullscreen = !props.isWide;
  const relatedSymbols = useRelatedSymbols(props.article.relatedSymbols);

  const dialog = (
    <DialogModal
      fullScreen={false}
      navigationPosition={
        isDialogFullscreen
          ? DialogNavigationPosition.Bottom
          : DialogNavigationPosition.Top
      }
      dialogClassName={isDesktopWidth ? classes.desktopDialog : classes.dialog}
      navigation={
        <div>
          <IconButton
            id={'article-navigation-wrap-button'}
            edge="start"
            color="inherit"
            onClick={toggleWrap}
            aria-label="close"
            size="large"
          >
            <WrapTextIcon
              style={{ paddingLeft: 8 }}
              className={wrap ? '' : 'grey'}
            />
          </IconButton>
          <IconButton
            id={'article-navigation-close-button'}
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={classes.articleNavigationCloseButton}
            size="large"
          >
            <CloseIcon style={{ paddingRight: 8 }} />
          </IconButton>
        </div>
      }
      handleClose={handleClose}
      open={open}
    >
      <Typography
        gutterBottom
        id={'article-header'}
        className={classes.articleHeader}
      >
        <div id={'article-timestamp'} className={classes.articleTimestamp}>
          {formattedDateOnly(props.article.timestamp)}
        </div>
        <div id={'article-provider'} className={classes.articleProvider}>
          {localize(newsProvider(props.article.provider))}
        </div>
      </Typography>
      <Typography
        gutterBottom={true}
        id={'article-title'}
        className={classes.articleTitle}
      >
        {props.article.title}
      </Typography>
      <Typography gutterBottom>
        {fullArticle ? (
          <pre
            id={'article-body-pre'}
            className={[
              classes.articleBody,
              wrap ? classes.wrap : classes.nowrap
            ].join(' ')}
          >
            {fullArticle.body}
          </pre>
        ) : (
          localize('news.article.loading')
        )}
      </Typography>
      {fullArticle && <RelatedSymbols relatedSymbols={relatedSymbols} />}
    </DialogModal>
  );

  const headLineClass = isFirst
    ? classes.headLineItemFirst
    : classes.headlineItem;
  const shadedClass = isOdd ? classes.odd : classes.even;
  const headlineClasses = `${headLineClass} ${shadedClass}`;
  const hover = relatedSymbols.length > 1 ? relatedSymbols.join(', ') : '';

  const thinHeadline = (
    <div className={headlineClasses}>
      <Stack
        direction={'row'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={2}
      >
        {!props.isDisableRelatedSymbols && (
          <div
            className={classes.relatedSymbolColumn}
            onClick={() => {
              props.onRelatedSymbolPress(relatedSymbols[0], getSymbol);
            }}
          >
            <Link
              to={'#'}
              id={'headline-link'}
              className={classes.headlineLink}
              title={hover}
            >
              {relatedSymbols.length > 0 && relatedSymbols[0]}
              {relatedSymbols.length >= 2 && (
                <div
                  id={'related-symbol-plus-icon'}
                  className={classes.relatedSymbolPlusIcon}
                >
                  +
                </div>
              )}
            </Link>
          </div>
        )}
        <div className={classes.headlineColumn}>
          <DateTime value={props.article.timestamp} hasZeroPad={true} />
        </div>
        <div className={classes.headlineColumn}>
          {localize(newsProvider(props.article.provider))}
        </div>
        <div className={classes.headlineHeaderColumn}>
          <Link
            id={'headline-link'}
            to={'#'}
            onClick={handleClickOpen}
            className={[
              classes.headlineLink,
              props.isRead ? classes.viewed : ''
            ].join(' ')}
          >
            {props.article.title}
          </Link>
        </div>
      </Stack>
      {dialog}
    </div>
  );

  const headline = (
    <div className={classes.headline} id={props.article.articleId}>
      <Link
        id={'headline-link'}
        to={'#'}
        onClick={handleClickOpen}
        className={[
          classes.headlineLink,
          props.isRead ? classes.viewed : ''
        ].join(' ')}
      >
        <div id={'headline-link-left'} className={classes.headlineLinkLeft}>
          <div
            id={'headline-link-left-title'}
            className={classes.headlineLinkLeftTitle}
          >
            {props.article.title}
          </div>
          <span
            id={'headline-link-left-provider'}
            className={classes.headlineLinkLeftProvider}
          >
            <DateTime value={props.article.timestamp} hasZeroPad={true} />
            &nbsp;
            {localize(newsProvider(props.article.provider))}
          </span>
        </div>
        <div id={'headline-link-right'} className={classes.headlineLinkRight}>
          <ArrowForwardIosIcon
            id={'headline-link-right-icon'}
            className={classes.iconStyle}
            aria-label="view"
          />
        </div>
      </Link>
      {dialog}
    </div>
  );

  return props.isWide ? thinHeadline : headline;
}
