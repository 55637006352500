import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import { FormGroup } from '@mui/material';
import SearchSelectorCheckbox from './SearchSelectorCheckbox';
import { useSymbolSearchFlags } from '../../utils/hooks/useSymbolSearchFlags';
import { getMenuPlacement } from '../../utils/menuPlacement';

const defaultComponentWidth = 200;
const getComponentWidth = (props) => ((props || {}).width || defaultComponentWidth) + 'px';

const useStyles = makeStyles({
  outerDiv: {
    position: 'relative'
  },
  searchSelector: {
    border: '1px solid #b5bec4',
    background: '#fff',
    'box-shadow': '0 4px 12px rgba(0,0,0,0.25)',
    fontSize: 14,
    width: getComponentWidth,
    height: '163px',
    '& .title': {
      'font-weight': 'bold',
      fontSize: 15,
      'padding-top': '13px',
      'padding-left': '25px',
      'margin-bottom': '2px'
    },
    '& div': {
      'padding-left': '24px'
    }
  },
  label: {
    '& label': {
      height: 28
    }
  },
  disabled: {
    cursor: 'default'
  }
});

export const GetMenuPlacement = ({ element, offsetTop }) => {
  // required height of the result items when rendered
  const selectorHeight = 170;

  return getMenuPlacement(element, selectorHeight + (offsetTop ?? 0));
};

const SearchSelector = ({
  displaySearchSelector,
  setSearchSelector,
  ...props
}) => {
  const [
    includeJpeq,
    includeUseq,
    includeSupplementals,
    includeIndustry,
    setIncludeJpeq,
    setIncludeUseq,
    setIncludeSupplementals,
    setIncludeIndustry,
    disabled
  ] = useSymbolSearchFlags();

  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });
  const classes = useStyles(props);

  if (!displaySearchSelector) {
    return null;
  }

  return (
    <div
      className={classes.outerDiv}
      onMouseLeave={() => setSearchSelector(false)}
    >
      <div className={[classes.searchSelector, disabled ? classes.disabled : ''].join(' ')}>
        <div className="title">{localize('local.menu.search.title')}</div>
        <FormGroup className={classes.label}>
          <SearchSelectorCheckbox
            label={localize('local.menu.search.jpeq')}
            isChecked={includeJpeq}
            toggleChecked={setIncludeJpeq}
          />
          <SearchSelectorCheckbox
            label={localize('local.menu.search.useq')}
            isChecked={includeUseq}
            toggleChecked={setIncludeUseq}
          />
          <SearchSelectorCheckbox
            label={localize('local.menu.search.supplementals')}
            isChecked={includeSupplementals}
            toggleChecked={setIncludeSupplementals}
          />
          <SearchSelectorCheckbox
            label={localize('local.menu.search.industry')}
            isChecked={includeIndustry}
            toggleChecked={setIncludeIndustry}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default SearchSelector;
