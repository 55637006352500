import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import DateTime from '../common/DateTime';
import { fetchTSENews } from '../../actions/news';

const EIGHT_LINES_HEIGHT = 215;

class TSENews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMore: true,
      showToggleTSE: false
    };
    this.toggleTSE = this.toggleTSE.bind(this);
  }

  componentDidMount() {
    this.props.actions.getTSENews();
  }

  componentDidUpdate() {
    if (!this.state.showToggleTSE && document.getElementById('tse-content')) {
      var clientHeight = document.getElementById('tse-content').offsetHeight;

      if (clientHeight >= EIGHT_LINES_HEIGHT) {
        this.setState({ showToggleTSE: true });
      }
    }
  }

  componentWillUnmount() {}

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  toggleTSE(e) {
    this.setState({ isShowMore: !this.state.isShowMore });
  }

  createToggleButton() {
    let toggleKeyName = this.state.isShowMore ? 'more' : 'close';
    return (
      <div className="market_summary_tse--toggle">
        <div className={'market_summary_tse--show'} onClick={this.toggleTSE}>
          {this.props.intl.formatMessage({
            id: `button.label.${toggleKeyName}`
          })}
        </div>
        <div className={`triangle${this.state.isShowMore ? '' : '-down'}`} />
      </div>
    );
  }

  render() {
    const TitleBarTSE = () => (
      <div className={'market_summary_symbol--title_bar'}>
        {this.localize('market.summary.tse.news.title')}
      </div>
    );

    if (this.props.tse.isError) {
      return (
        <div>
          <TitleBarTSE />
          <div className="no-content empty">
            <FormattedMessage id="message.status.error" />
          </div>
        </div>
      );
    }

    return (
      <div>
        <TitleBarTSE />
        {this.props.tse.isLoading ? (
          <div className="no-content loading">
            <FormattedMessage id="message.status.loading" />
          </div>
        ) : (
          <div className="market_summary_tse">
            <div className={'market_summary_tse--headline'}>
              {this.props.tse.headline}
            </div>
            <div className={'market_summary_tse--timestamp'}>
              <DateTime value={this.props.tse.timestamp} hasZeroPad={true} />
              {` ${this.props.intl.formatMessage({
                id: 'market.summary.tse.news.overview'
              })}`}
            </div>
            <div
              className={`market_summary_tse--content${
                this.state.showToggleTSE && this.state.isShowMore
                  ? ' collapse'
                  : ''
              }`}
              id={'tse-content'}
            >
              {this.props.tse.body}
            </div>
            {this.state.showToggleTSE && this.createToggleButton()}
          </div>
        )}
      </div>
    );
  }
}

TSENews = injectIntl(TSENews);

function mapStateToProps(state, ownProps) {
  if (!state.user) {
    return {};
  }
  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };

  props.tse = state.news.tse || {};

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getTSENews: bindActionCreators(fetchTSENews, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TSENews);
