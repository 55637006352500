import React from 'react';
import { injectIntl } from 'react-intl';
import Number from '../common/Number';
import DateTime from '../common/DateTime';

const i18nKeyPrefix = 'symbol.details.indicators.dividendInformation.';

class DividendInformationSection extends React.PureComponent {
  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  getPeriodDateTitle(field, checkAnnouncementDate) {
    if (checkAnnouncementDate && !this.props[field].announcementDate) {
      return null;
    }

    let dateText = this.props[field].periodDate;
    if (!dateText) {
      return null;
    }

    return (
      <DateTime
        format="YYYY/MM"
        value={dateText}
        suffix={this.localize(
          i18nKeyPrefix + 'header.' + field + '.periodDate'
        )}
      />
    );
  }

  getAnnouncementDateTitle(field) {
    let dateText = this.props[field].announcementDate;
    if (!dateText) {
      return null;
    }

    return (
      <DateTime
        format="MM/DD"
        value={dateText}
        suffix={this.localize(i18nKeyPrefix + 'header.announcement.date')}
      />
    );
  }

  getRow(params) {
    return (
      <div className="exchange-summary-detail-table-row">
        <div className="symbol-indicator-table-cell-title">
          {this.localize(i18nKeyPrefix + params.title)}
        </div>
        <div className="exchange-summary-detail-table-cell symbol-indicator-table-cell-value">
          <Number
            value={params.value1}
            precision={2}
            suffix={params.suffix || null}
          />
        </div>
        <div className="exchange-summary-detail-table-cell symbol-indicator-table-cell-value">
          <Number
            value={params.value2}
            precision={2}
            suffix={params.suffix || null}
          />
        </div>
      </div>
    );
  }

  render() {
    // 2019/03期 実績
    // 05/15に発表
    const titlePrevious = (
      <div className="exchange-summary-detail-table-head">
        <div>
          <span>{this.getPeriodDateTitle('companyResults', true)}</span>
          <span>{this.localize(i18nKeyPrefix + 'header.results')}</span>
        </div>
        <div>{this.getAnnouncementDateTitle('companyResults')}</div>
      </div>
    );

    // 2020/03期 会社予想
    // 07/31に発表
    const titleForecast = (
      <div className="exchange-summary-detail-table-head">
        <div>
          <span>{this.getPeriodDateTitle('companyEstimates', true)}</span>
          <span>{this.localize(i18nKeyPrefix + 'header.estimates')}</span>
        </div>
        <div>{this.getAnnouncementDateTitle('companyEstimates')}</div>
      </div>
    );

    return (
      <div className="symbol-details-indicators-fundamental-analysis-section">
        <div className="section-header symbol-indicators-title">
          {this.localize(i18nKeyPrefix + 'title')}
        </div>
        <div className="symbol-indicator-fundamental-analysis-details">
          <div className="exchange-summary-detail-table">
            <div className="exchange-summary-detail-table-heading">
              <div className="exchange-summary-detail-table-row">
                <div className="exchange-summary-detail-table-head"></div>
                {titlePrevious}
                {titleForecast}
              </div>
            </div>
            <div className="exchange-summary-detail-table-body">
              {this.getRow({
                title: 'dividend.dps',
                value1: this.props.companyResults.dividendPerShare,
                value2: this.props.companyEstimates.dividendPerShare
              })}
              {this.getRow({
                title: 'dividend.dividendYield',
                value1: this.props.companyResults.dividendYield,
                value2: this.props.companyEstimates.dividendYield,
                suffix: '%'
              })}
              {this.getRow({
                title: 'dividend.dividendPayoutRatio',
                value1: this.props.companyResults.dividendPayoutRatio,
                value2: this.props.companyEstimates.dividendPayoutRatio,
                suffix: '%'
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
DividendInformationSection = injectIntl(DividendInformationSection);

export default DividendInformationSection;
