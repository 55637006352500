import { useFeatureToggle } from '../../utils/hooks/useFeatureToggle';

export const FeatureToggle = ({ featureName, children, fallback }) => {
  const featureIsEnabled = useFeatureToggle(featureName);
  if (!featureIsEnabled) {
    if(fallback) {
      return fallback;
    }

    return <></>;
  }

  return (
    <>
      {children}
    </>
  );
};