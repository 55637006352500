import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchSymbolIfNeeded } from '../../actions/symbols';
import Number from '../common/Number';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

class PriceRankingsTopFiveRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.actions.getSymbol(this.props.symbol);
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  getColumnIndex(columnName) {
    return this.props.columns.findIndex(col => {
      return col.id === columnName;
    });
  }

  getContent() {
    // Price Rise/Fall
    if (this.props.hotlistName.indexOf('rate') === 0) {
      let closeIndex = this.getColumnIndex('Close');
      let close = this.props.data[closeIndex];

      let netChangePctIndex = this.getColumnIndex('NetChangePct');
      let netChangePct = this.props.data[netChangePctIndex];
      return (
        <span className="industry-ranking_value__value industry-ranking_value__value-primary">
          <span>
            <Number
              className={'price-ranking__close-value'}
              value={close}
              hideIfZero={true}
              precision="0"
              suffix=""
            />
          </span>
          <span>
            <Number
              className={'industry-ranking__net-change-value'}
              value={netChangePct}
              hideIfZero={true}
              precision="2"
              suffix="%"
              signed={true}
            />
          </span>
        </span>
      );
    }

    // Volume / Turnover
    let columnName =
      this.props.hotlistName.indexOf('volume') === 0 ? 'Volume' : 'Turnover';
    let index = this.getColumnIndex(columnName);
    let value = this.props.data[index];

    return (
      <span className="price-ranking_value__value industry-ranking_value__value-primary">
        <Number
          className={'price-ranking__close-value'}
          value={value}
          hideIfZero={true}
          precision="0"
          suffix=""
        />
      </span>
    );
  }

  render() {
    const styles = {
      iconStyle: {
        color: '#BCBCBC',
        float: 'right',
        marginRight: '0',
        fontSize: '9px',
        fontWeight: 'bold'
      }
    };
    let symbolName = this.props.symbolData.jpShortName || '-';
    return (
      <Link to={`/symbol/${this.props.symbol}`}>
        <div className={'industry-ranking-row'}>
          <div className={'industry-ranking-row-data'}>
            <span
              className="industry-ranking_value__rank"
              value-rank={this.props.rank}
            >
              {this.props.rank}
            </span>
            <span className="price-ranking_value__symbol-name">
              {symbolName}
            </span>
            {this.getContent()}
          </div>
          <div className={'industry-ranking__arrow'}>
            <ArrowForwardIosIcon style={styles.iconStyle} aria-label="view" />
          </div>
        </div>
      </Link>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.user) {
    return {};
  }
  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };
  props.symbolData =
    (state.symbols.data[ownProps.symbol] || { data: {} }).data || {};

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getSymbol: bindActionCreators(fetchSymbolIfNeeded, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceRankingsTopFiveRow);
