import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { start, stop } from '../../actions/stream';
import { fetchSymbolIfNeeded } from '../../actions/symbols';
import Number from '../common/Number';
import CssFlash from '../common/CssFlash';
import { calcNetChgPct } from '../../utils/maths';
import useActions from '../../utils/hooks/useActions';
import { makeStyles } from '@mui/styles';
import { selectChannel } from '../../selectors/channelSelectors';

const defaultPrecision = 1;
const symbolWithoutLink = '@OSENK';

const getPricingClassForQuote = (quote) => {
  if (quote.close > quote.prevClose) {
    return 'gain';
  }

  if (quote.close < quote.prevClose) {
    return 'loss';
  }

  return 'unch';
};

const useStyles = makeStyles({
  symbolContainer: {
    position: 'relative',
    zIndex: 10,
    paddingLeft: 10,
    textAlign: 'left',
    backgroundColor: 'white'
  },
  tickerContainer: {
    marginTop: '-5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  priceContainer: {
    marginTop: -10
  },
  netChange: {
    fontFamily: 'unset',
    fontSize: '11px',
    fontWeight: 600,
    marginTop: '2px'
  },
  priceMovement: {
    marginTop: '-1px'
  },
  netChangePct: {
    marginTop: '-1px'
  },
  tickerItem: {
    position: 'relative',
    height: 45,
    minWidth: 155,
    display: 'inline-block',
    borderLeft: 'solid 1px #CCCCCC',
    borderRight: 'solid 1px #CCCCCC',
    marginLeft: '-1px'
  }
});

const Ticker = ({ symbol }) => {
  const channel = useMemo(() => `${symbol}/quote`, [symbol]);

  // Hooks
  const classes = useStyles();

  // states
  const [streaming, setStreaming] = useState(false);

  // actions
  const [startStream] = useActions([start], []);
  const [stopStream] = useActions([stop], []);
  const [getSymbol] = useActions([fetchSymbolIfNeeded], []);

  // store
  const quote = useSelector((state) => selectChannel(state, channel));

  const metadata = useSelector(
    (state) => (state.symbols.data[symbol] || { data: {} }).data || {}
  );

  const tryStartStream = useCallback(() => {
    if (!streaming) {
      startStream(channel);
      setStreaming(true);
    }
  }, [startStream, streaming, channel]);

  useEffect(() => {
    getSymbol(symbol);
    // start streaming on mount
    tryStartStream();

    // cleanup - stop streaming on unmount
    return () => {
      if (streaming) {
        stopStream(channel);
      }
    };
  }, [symbol, startStream, stopStream, getSymbol, channel, tryStartStream, streaming]);

  const symbolName = metadata.jpShortName || metadata.name || '\u00A0';
  const symbolLink =
    symbol !== symbolWithoutLink ? (
      <Link to={`/symbol/${symbol}`} className="ttl ticker-desc">
        {symbolName}
      </Link>
    ) : (
      <span className="ttl ticker-desc">{symbolName}</span>
    );
  const isLoading = !quote;

  const priceMovement = getPricingClassForQuote(quote);
  const arrow = priceMovement !== 'unch' ? 'arrow ' : '';

  return (
    <div className={classes.tickerItem}>
      <div className={classes.symbolContainer}>
        <div>{symbolLink}</div>
        <div className={classes.tickerContainer}>
          <span className={`num ${classes.netChange}`}>
            <Number
              className={'ticker-value'}
              value={quote.close}
              precision={quote.precision}
              hideIfZero={true}
            />
            <span>&nbsp;&nbsp;</span>
          </span>
          <span className={classes.priceMovement}>
            <CssFlash value={quote.close} isChange={false}>
              <Number
                className={'ticker-value ' + arrow + priceMovement}
                value={isLoading ? 0 : Math.abs(quote.close - quote.prevClose)}
                precision={isLoading ? defaultPrecision : quote.precision}
                hideIfZero={false}
              />
            </CssFlash>
          </span>
          <span>&nbsp;</span>
          <div className={classes.netChangePct}>
            <CssFlash value={quote.close} isChange={false}>
              <Number
                className={'ticker-value signed ' + priceMovement}
                value={
                  isLoading ? 0 : calcNetChgPct(quote.close, quote.prevClose)
                }
                hideIfZero={false}
                signed={true}
                precision="2"
                suffix="%"
              />
              <span>&nbsp;&nbsp;</span>
            </CssFlash>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticker;
