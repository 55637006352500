import types from './_actionTypes';
import { getUserSdk } from '../utils/sdk';

export function requestingMemos(symbols) {
  return {
    type: types.REQUESTING_USER_MEMOS,
    symbols: symbols
  };
}

function receiveUserMemosResponse(response) {
  return {
    type: types.RECEIVE_USER_MEMOS,
    data: response
  };
}

function saveUserMemoResponse(symbolId, response) {
  return {
    type: types.SAVE_USER_MEMO,
    symbolId: symbolId,
    data: response
  };
}

function deleteUserMemoResponse(symbolId) {
  return {
    type: types.DELETE_USER_MEMO,
    symbolId: symbolId
  };
}

export function errorRequestingUserMemos(error) {
  return {
    type: types.ERROR_REQUESTING_USER_MEMOS,
    error: error,
    errorCode: 'E0037'
  };
}

export function errorSavingUserMemo(symbol, memo, error) {
  return {
    type: types.ERROR_SAVING_USER_MEMO,
    symbol: symbol,
    memo: memo,
    error: error,
    errorCode: 'E0038'
  };
}

export function errorDeletingUserMemo(symbol, error) {
  return {
    type: types.ERROR_DELETING_USER_MEMO,
    symbol: symbol,
    error: error,
    errorCode: 'E0039'
  };
}

function shouldFetchMemo(symbol, state) {
  const memo = state.memos[symbol];
  if (memo && memo.isLoading) {
    return false;
  }

  return true;
}

export function fetchUserMemosIfNeeded(symbols) {
  return (dispatch, getState) => {
    let state = getState();
    let user = state.user;
    if (!user.id || user.tokenIsValid !== true || !user.token) {
      return false;
    }

    // Since we could already be requesting memos, be sure to only
    // fetch memos for symbols that we are not requesting memos yet.
    let targetSymbols = symbols.filter(symbol => shouldFetchMemo(symbol, state));
    if (targetSymbols.length === 0) {
      return true;
    }

    dispatch(requestingMemos(targetSymbols));
    return getUserSdk(user.token)
      .memos.getBySymbols(targetSymbols)
      .then((response) => {
        dispatch(receiveUserMemosResponse(response));
      })
      .catch((ex) => {
        dispatch(errorRequestingUserMemos(ex));
      });
  };
}

export function saveUserMemo(symbolId, memo) {
  return (dispatch, getState) => {
    let user = getState().user;
    if (!user.id || user.tokenIsValid !== true || !user.token) {
      return false;
    }

    return getUserSdk(user.token)
      .memos.addBySymbol(symbolId, memo)
      .then((response) => {
        dispatch(saveUserMemoResponse(symbolId, response));
      })
      .catch((ex) => {
        dispatch(errorSavingUserMemo(symbolId, memo, ex));
      });
  };
}

export function deleteUserMemo(symbolId) {
  return (dispatch, getState) => {
    let user = getState().user;
    if (!user.id || user.tokenIsValid !== true || !user.token) {
      return false;
    }

    return getUserSdk(user.token)
      .memos.deleteBySymbol(symbolId)
      .then(() => {
        dispatch(deleteUserMemoResponse(symbolId));
      })
      .catch((ex) => {
        dispatch(errorDeletingUserMemo(symbolId, ex));
      });
  };
}
