import React from 'react';
import { injectIntl } from 'react-intl';
import Number from '../common/Number';

class FundamentalAnalysisRow extends React.PureComponent {
  getRow(valueObj) {
    if (valueObj.hide) {
      return (
        <div className="exchange-summary-detail-table-cell symbol-indicator-table-cell-value"></div>
      );
    }

    return (
      <div className="exchange-summary-detail-table-cell symbol-indicator-table-cell-value">
        <Number
          value={valueObj.value}
          hideIfZero={valueObj.hideIfZero}
          precision={valueObj.precision}
          suffix={valueObj.suffix}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="exchange-summary-detail-table-row">
        <div className="symbol-indicator-table-cell-title">
          {this.props.intl.formatMessage({
            id:
              'symbol.details.indicators.fundamentalAnalysis.' +
              this.props.title
          })}
        </div>
        {this.getRow(this.props.value1)}
        {this.getRow(this.props.value2)}
        {this.getRow(this.props.value3)}
      </div>
    );
  }
}

FundamentalAnalysisRow = injectIntl(FundamentalAnalysisRow);

export default FundamentalAnalysisRow;
