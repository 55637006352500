import React from 'react';
import { ReactComponent as ListReorderIcon } from '../../icons/Icon-List-Reorder.svg';

const MobileDragHandle = ({ style, handleListeners }) => {
  return (
    <div className="drag-handle" style={{ ...style }} {...handleListeners}>
      <ListReorderIcon />
    </div>
  );
};

export default MobileDragHandle;
