import types from './_actionTypes';
import { fetchRetryWithAuth } from '../utils/fetchRetry';
import * as time from '../utils/time';
import { redirectToLoginIfRequired } from '../utils/common';
import { getMarketSdk } from '../utils/sdk';

const periods = ['5min', '1hour', '1day', '1week', '1month', '1quarter'];
const API_URL = process.env.REACT_APP_INSIGHT_API_DOMAIN;

function getBarsUrl(symbol, period) {
  return `https://${API_URL}/market/bars/${symbol}/${period}`;
}

export function requestingBars(symbol, period) {
  return {
    type: types.REQUESTING_BARS,
    symbol: symbol,
    period: period
  };
}

export function receiveBarsResponse(response, symbol, period) {
  // We cannot be sure that the response has the bars in the
  // correct order, so sort them before we put them in the
  // state.
  if (response.data && response.data.bars) {
    response.data.bars = response.data.bars.sort((a, b) => {
      return new Date(a.Timestamp) - new Date(b.Timestamp);
    });
  }

  return {
    type: types.RECEIVE_BARS,
    symbol: symbol,
    period: period,
    data: response
  };
}

export function invalidateBars(symbol, period) {
  return {
    type: types.INVALIDATE_BARS,
    symbol: symbol,
    period: period
  };
}

export function errorRequestingBars(symbol, period, error) {
  return {
    type: types.ERROR_REQUESTING_BARS,
    symbol: symbol,
    period: period,
    error: error,
    errorCode: 'E0020'
  };
}

function fetchBars(symbol, period) {
  let req = {
    method: 'get'
  };

  return fetchRetryWithAuth(
    req,
    () => {
      return getBarsUrl(symbol, period);
    },
    (response) => {
      return receiveBarsResponse(response, symbol, period);
    },
    (error) => {
      return errorRequestingBars(symbol, period, error);
    }
  );
}

function getBarSizeData(data, symbol, period) {
  let barData = data[symbol + '/' + period];
  if (barData) {
    // Convert the property names from camelCase to PascalCase
    barData.bars = barData.bars.map((bar) => {
      let modifiedBar = {};
      Object.keys(bar).forEach((key) => {
        let modifiedKey = key.substring(0, 1).toUpperCase() + key.substring(1);
        modifiedBar[modifiedKey] = bar[key];
      });
      return modifiedBar;
    });

    return barData;
  }

  return null;
}

function fetchAllBars(symbol) {
  return (dispatch, getState) => {
    let user = getState().user;
    if (!user.id || user.tokenIsValid !== true || !user.token) {
      return false;
    }

    let postData = periods.map((p) => {
      return `${symbol}/${p}`;
    });

    return getMarketSdk(user.token)
      .getHistoricalBars(postData)
      .then((response) => {
        periods.forEach((period) => {
          let barData = { data: getBarSizeData(response, symbol, period) };
          dispatch(receiveBarsResponse(barData, symbol, period));
        });
      })
      .catch((ex) => {
        redirectToLoginIfRequired(ex);
        // There wasn't a specific period that errored, so send 'all'
        dispatch(errorRequestingBars(symbol, 'all', ex));
      });
  };
}

function shouldFetchBars(state, symbol, period) {
  const search = state.bars[symbol + '/' + period];
  if (!search) {
    return true;
  }

  if (search.isError) {
    return true;
  }

  if (search.isLoading) {
    return false;
  }

  if (search.when && search.when < time.timestamp() - 60) {
    return true;
  }

  return search.didInvalidate || false;
}

function shouldFetchAllBars(state, symbol) {
  return periods
    .map((period) => {
      let periodBars = state.bars[symbol + '/' + period];
      return (
        !periodBars ||
        periodBars.isError ||
        (periodBars.when && periodBars.when < time.timestamp() - 60)
      );
    })
    .some((val) => val === true);
}

export function fetchBarsIfNeeded(symbol, period) {
  return (dispatch, getState) => {
    if (shouldFetchBars(getState(), symbol, period)) {
      dispatch(requestingBars(symbol, period));
      return dispatch(fetchBars(symbol, period));
    }
  };
}

export function fetchAllBarsIfNeeded(symbol) {
  return (dispatch, getState) => {
    if (shouldFetchAllBars(getState(), symbol)) {
      periods.map((period) => dispatch(requestingBars(symbol, period)));
      return dispatch(fetchAllBars(symbol));
    }
  };
}
