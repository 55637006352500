import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { FormattedMessage } from 'react-intl';

export default class MessageBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      dismissed: false
    };
    this.handleDismissMessage = this.handleDismissMessage.bind(this);
    this.toggleExpansion = this.toggleExpansion.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.expanded !== nextState.expanded) {
      return true;
    }
    if (this.state.dismissed !== nextState.dismissed) {
      return true;
    }
    return false;
  }

  toggleExpansion(e) {
    this.setState({ expanded: !this.state.expanded });
  }

  handleDismissMessage(e) {
    this.setState({ dismissed: true });
    this.props.onDismissMessage();
  }

  getMessageAlertType(typeId) {
    switch (typeId) {
      case 2:
        return 'info';
      default:
        return 'alert';
    }
  }

  render() {
    if (!this.state.dismissed) {
      let alertType = this.getMessageAlertType(this.props.message.typeId);
      let messageData = this.props.message.data || {};
      let dismissable = messageData.dismissable;
      if (dismissable !== false) {
        dismissable = true;
      }
      let expanded = this.state.expanded ? ' expanded' : '';
      let messageElement =
        !!this.props.message &&
        !!this.props.message.data &&
        !!this.props.message.data.message ? (
          <span
            className={'globalAlertMessage ' + expanded}
            onClick={this.toggleExpansion}
            dangerouslySetInnerHTML={{
              __html: this.props.message.data.message
            }}
          />
        ) : (
          <span
            className={'globalAlertMessage ' + expanded}
            onClick={this.toggleExpansion}
          >
            <FormattedMessage
              id={this.props.message.TranslationId}
              values={this.props.message.Values}
            />
          </span>
        );
      let dismissButton = dismissable ? (
        <span className="globalAlertDismiss">
          <IconButton onClick={this.handleDismissMessage} size="large">
            <Icon>clear_icon</Icon>
          </IconButton>
        </span>
      ) : null;
      return (
        <div className={'globalAlertBar ' + alertType + expanded}>
          <span className={'globalAlertIcon ' + alertType} />
          {messageElement}
          {dismissButton}
        </div>
      );
    } else {
      return <div />;
    }
  }
}
