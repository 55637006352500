import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';

const useStyles = makeStyles({
  checkBox: {
    '& .MuiSvgIcon-root': {
      fontSize: 22,
      color: '#616161'
    }
  },
  label: {
    '& .MuiFormControlLabel-label': {
      fontSize: 15,
      'margin-left': -3,
      'padding-top': 2
    }
  }
});

const SearchSelectorCheckbox = ({ label, isChecked, toggleChecked }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      label={label}
      className={classes.label}
      control={
        <Checkbox
          checked={isChecked}
          className={classes.checkBox}
          onChange={() => toggleChecked(!isChecked)}
        />
      }
    />
  );
};

export default SearchSelectorCheckbox;
