import Faucet from 'faucet-insight';
import Logger from './utils/logger';

Logger.debug('init faucet', process.env.REACT_APP_FAUCET_HOSTNAME);
export const faucet = new Faucet({
  host: process.env.REACT_APP_FAUCET_HOSTNAME,
  secure: true,
  port: 443,
  token: ''
});

window.addEventListener('visibilitychange', () => {
  const channels = faucet.channels;

  const command =
    document.hidden ||
    document.msHidden ||
    document.webkitHidden ||
    document.mozHidden
      ? 'STOP'
      : 'START';

  for (const [k, v] of Object.entries(channels)) {
    if (k && v && v.listeners && v.listeners > 0) {
      const message = `${command} {"channel":"${k}"}`;
      faucet._send(message);
    }
  }
});