import React from 'react';
import { injectIntl } from 'react-intl';
import { MonexTabs, MonexTab } from './MonexTabs';
import { withRouter } from 'react-router';

// If multiple valid tabs are provided in the search params, the first specified tab will be selected
export class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightedTab: this.getSearchParam()
    };
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  componentDidMount() {
    this.props.handleTabChange(null, this.state.highlightedTab);
  }

  componentDidUpdate(prevProps) {
    let search = this.props.location.search;
    // if url did not change, do nothing
    if (prevProps.location.search === search) return;
    let newTab = this.getSearchParam(search);
    this.props.handleTabChange(null, newTab);
    this.setState({ highlightedTab: newTab });
  }

  getSearchParam(searchParam) {
    // Extract the search param
    let search = searchParam ? searchParam : this.props.location.search;
    // Extract search Param by removing the string before '?'
    let urlSearchPram = search.replace(/.*\?/i, '');
    return new URLSearchParams(urlSearchPram).get('tab');
  }

  handleTabClick(event, value) {
    this.setState({
      highlightedTab: value
    });
    this.props.handleTabChange(null, value);
  }

  render() {
    let tabValues = this.props.tabValues;
    let highlightedTab = this.state.highlightedTab;

    if (!tabValues.includes(highlightedTab)) {
      highlightedTab = tabValues[0];
    }

    if (tabValues[this.props.selectedTabIndex] == null) {
      this.props.handleTabChange(null, highlightedTab);
    }

    return (
      <MonexTabs
        className={`monex-list-tabs${
          this.props.className ? ' ' + this.props.className : ''
        }`}
        value={highlightedTab}
        onChange={this.handleTabClick}
        variant="fullWidth"
      >
        {tabValues.map((tab, index) => {
          return (
            <MonexTab
              className={`${tab} tab`}
              label={this.props.intl.formatMessage({
                id: `${this.props.labelKeyPrefix}${tab}`
              })}
              key={`${tab} tab`}
              value={tabValues[index]}
            />
          );
        })}
      </MonexTabs>
    );
  }
}
Tabs = withRouter(injectIntl(Tabs));

export default Tabs;
