import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DialogModal from '../common/DialogModal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const commonTitleCss = {
  right: 0,
  position: 'fixed !important',
  zIndex: 1030,
  background: '#fafafa',
  boxShadow: '0 0px 1px 0 rgba(0, 0, 0, 0.2)',
  clear: 'both',
  height: '40px',
  lineHeight: '49px',
  transition: 'box-shadow 100ms linear',
  textAlign: 'center',
  fontSize: '17px',
};

const useStyles = makeStyles({
  closeButton: {
    float: 'right'
  },
  closeButtonTop: {
    float: 'right',
    marginTop: '-4px'
  },
  contentSmall: {
    padding: `0 !important`,
    width: '436px',
    height: '80vh',
    position: 'relative'
  },
  content: {
    padding: `0 !important`,
  },
  title: {
    ...commonTitleCss,
    left: 0,
    top: '0px',
  },
  titleSmall: {
    ...commonTitleCss,
    left: 'calc(50vw - 218px)',
    width: '436px',
    borderRadius: '5px 5px 0 0',
  },
  titleText: {
    marginLeft: '35px'
  }
});

export default function SettingsDialog({ children, isSmall, handleClose, open, title }) {

  const classes = useStyles();

  return (
    <DialogModal
      fullScreen={!isSmall}
      navigation={
        isSmall ? null :
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={classes.closeButton}
            size="large"
          >
            <CloseIcon style={{ paddingRight: 8 }} />
          </IconButton>
      }
      handleClose={handleClose}
      open={open}
      dialogContentClassName={isSmall ? classes.contentSmall : classes.content}
    >
      <div className={isSmall ? classes.titleSmall : classes.title}>
        <span className={isSmall ? classes.titleText : ""}>
          {title}
        </span>
        {isSmall &&
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={classes.closeButtonTop}
            size="large"
          >
            <CloseIcon style={{ paddingRight: 8 }} />
          </IconButton>
        }
      </div>
      {children}
    </DialogModal>
  )
}