import React from 'react';
import { injectIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InputNumber from '../common/InputNumber';

class ChartPeriod extends React.Component {
  localize(i18nKey, otherParams) {
    return this.props.intl.formatMessage({ id: i18nKey }, otherParams);
  }

  onBlur = value => {
    this.props.setPeriod(this.props.id, value, this.props.type);
  };

  onDelete = type => {
    this.props.deletePeriod(this.props.id, this.props.type);
  };

  render() {
    const deleteIcon =
      this.props.id === -1 || this.props.deletePeriod === null ? null : (
        <div className="settings-chart-indicators-sma-remove">
          <IconButton className="delete-button" onClick={this.onDelete} size="large">
            <DeleteForeverIcon />
          </IconButton>
        </div>
      );

    let localizedText =
      this.props.localizedText || 'settings.chart.indicators.period';
    let localizedTextClass =
      this.props.localizedTextClass || 'settings-chart-indicators-sma-period';
    return (
      <div style={{ display: 'inline-block', width: '100%' }}>
        <div className={localizedTextClass}>{this.localize(localizedText)}</div>
        <div className="settings-chart-indicators-sma-input-container">
          <InputNumber
            className="settings-chart-indicators-sma-input"
            value={this.props.period}
            onBlur={this.onBlur}
            min={1}
            max={300}
            useDecimals={false}
          />
        </div>
        {deleteIcon}
      </div>
    );
  }
}

export default injectIntl(ChartPeriod);
