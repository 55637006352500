import React, { Suspense, useState, lazy } from 'react';

const ProMarketBoard = lazy(() =>
  import('./ProMarketBoard')
);

export const ProMarketBoardContext = React.createContext({});

export const ContextAwareProMarketBoard = ({
  board,
  marketboardId,
  handleDeleteSymbolDialogOpen,
  handleSelectedSymbolChanged
}) => {
  const [selectedSymbol, setSelectedSymbol] = useState(null);

  return (
    <ProMarketBoardContext.Provider
      value={{
        selectedSymbol,
        setSelectedSymbol
      }}
    >
      <Suspense fallback={<></>}>
        <ProMarketBoard
          board={board}
          marketboardId={marketboardId}
          handleDeleteSymbolDialogOpen={handleDeleteSymbolDialogOpen}
          handleSelectedSymbolChanged={handleSelectedSymbolChanged}
        />
      </Suspense>
    </ProMarketBoardContext.Provider>
  );
};
