import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import messagesEn from './strings-en.json';
import messagesJa from './strings-ja.json';
class I18nContainer extends Component {
  render() {
    const messages = this.props.locale === 'ja-JP' ? messagesJa : messagesEn;
    return (
      <IntlProvider textComponent="span" locale={this.props.locale} messages={messages}>
        {this.props.children}
      </IntlProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    locale: state.user.locale
  };
}

export default connect(mapStateToProps)(I18nContainer);
