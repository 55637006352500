// Since these keys have a special way of handling them and
// are shared among many components, we have created methods
// for them below to get and set the localStorage.
const INCLUDE_JPEQ = 'includeJpeq';
const INCLUDE_USEQ = 'includeUseq';
const INCLUDE_SUPPLEMENTAL = 'includeSupplemental';
const INCLUDE_INDUSTRY = 'includeIndustry';

function getBoolFromStorage(name, defaultValue) {
  if (localStorage.getItem(name)) {
    return localStorage.getItem(name) === 'true';
  }
  return defaultValue;
}

export function getIncludeJpeq() {
  return getBoolFromStorage(INCLUDE_JPEQ, true);
}

export function getIncludeUseq() {
  return getBoolFromStorage(INCLUDE_USEQ, true);
}

export function getIncludeSupplementals() {
  return getBoolFromStorage(INCLUDE_SUPPLEMENTAL, true);
}

export function getIncludeIndustry() {
  return getBoolFromStorage(INCLUDE_INDUSTRY, true);
}

export function setIncludeJpeq(value) {
  localStorage.setItem(INCLUDE_JPEQ, value);
}

export function setIncludeUseq(value) {
  localStorage.setItem(INCLUDE_USEQ, value);
}

export function setIncludeSupplementals(value) {
  localStorage.setItem(INCLUDE_SUPPLEMENTAL, value);
}

export function setIncludeIndustry(value) {
  localStorage.setItem(INCLUDE_INDUSTRY, value);
}