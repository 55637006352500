import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { dismissMessage, fetchGlobalMessages } from '../../actions/user';
import MessageBar from './MessageBar';
import { allMessagesSelector } from '../../selectors/userSelectors';
import { isMonexApp } from '../../utils/common';
import MobileMessageBar from './MobileMessageBar';
import { filterBySymbols } from '../../utils/messages';

const GLOBAL_MESSAGE_POLL_TIME_SECONDS = 60;

export class GlobalMessageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleDismissMessage = this.handleDismissMessage.bind(this);
  }

  componentDidMount() {
    this.timeout = setInterval(() => {
      this.props.actions.fetchGlobalMessages();
    }, GLOBAL_MESSAGE_POLL_TIME_SECONDS * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  handleDismissMessage(key) {
    this.props.actions.dismissMessage(key);
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <MessageElementsFunction
                pageLabelKey={'marketboard'}
                messages={this.props.messages}
                handleDismissMessage={this.handleDismissMessage}
              />
            )}
          />
          <Route
            path="/marketboard"
            render={() => (
              <MessageElementsFunction
                pageLabelKey={'marketboard'}
                messages={this.props.messages}
                handleDismissMessage={this.handleDismissMessage}
              />
            )}
          />
          <Route
            path="/market"
            render={() => (
              <MessageElementsFunction
                pageLabelKey={'market_summary'}
                messages={this.props.messages}
                handleDismissMessage={this.handleDismissMessage}
              />
            )}
          />
          <Route
            path="/hotlists"
            render={() => (
              <MessageElementsFunction
                pageLabelKey={'hotlists'}
                messages={this.props.messages}
                handleDismissMessage={this.handleDismissMessage}
              />
            )}
          />
          <Route
            path="/news"
            render={() => (
              <MessageElementsFunction
                pageLabelKey={'news'}
                messages={this.props.messages}
                handleDismissMessage={this.handleDismissMessage}
              />
            )}
          />
          <Route
            path="/scan"
            render={() => (
              <MessageElementsFunction
                pageLabelKey={'scans'}
                messages={this.props.messages}
                handleDismissMessage={this.handleDismissMessage}
              />
            )}
          />
          <Route
            path="/scanresult"
            render={() => (
              <MessageElementsFunction
                pageLabelKey={'scans'}
                messages={this.props.messages}
                handleDismissMessage={this.handleDismissMessage}
              />
            )}
          />
          <Route
            path="/symbol"
            render={() => (
              <MessageElementsFunction
                pageLabelKey={'symbol_details'}
                messages={this.props.messages}
                handleDismissMessage={this.handleDismissMessage}
              />
            )}
          />
        </Switch>
      </HashRouter>
    );
  }
}

const getPageKey = (page) => {
  const knownPages = [
    'marketboard',
    'market_summary',
    'hotlists',
    'news',
    'scans',
    'symbol_details'
  ];
  if (knownPages.includes(page)) return page;
  return 'all';
};

function MessageElementsFunction({
  pageLabelKey,
  messages,
  handleDismissMessage
}) {
  let messageElements = messages
    .filter(
      (msg) =>
        getPageKey((msg.data || {}).page) === 'all' ||
        getPageKey((msg.data || {}).page) === pageLabelKey
    )
    .filter((msg) => msg.typeId !== 3)
    .filter((msg) => filterBySymbols(msg, pageLabelKey))
    .map((message) => {
      let key = message.Key;
      return isMonexApp() ? (
        <MobileMessageBar
          key={key}
          message={message}
          onDismissMessage={() => handleDismissMessage(key)}
        />
      ) : (
        <MessageBar
          key={key}
          message={message}
          onDismissMessage={() => handleDismissMessage(key)}
        />
      );
    });
  return <div>{messageElements}</div>;
}

const mapStateToProps = (state) => {
  return {
    messages: allMessagesSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchGlobalMessages: bindActionCreators(fetchGlobalMessages, dispatch),
      dismissMessage: bindActionCreators(dismissMessage, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalMessageContainer);
