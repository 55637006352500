const {
  getFixedLinesData,
  getFilteredBarValuesWithSameTimestamps
} = require('./utils');

function getMoneyFlowIndexes(fullBarData, fullVolume, period) {
  // Typical Prices should be divided by 3 but for MFI calculation
  // precision we keep it this way
  let typicalPriceBars = fullBarData.map(a => (a[2] + a[3] + a[4]).toFixed(2));
  let volumeBars = fullVolume.map(a => a[1]);
  // For simplicity, we need to keep a record of the last "period" values
  let changes = [];
  // Accumulated sum of Positive Money Flows
  let positiveMoneyFlowSum = 0;
  // Accumulated sum of Negative Money Flows
  let negativeMoneyFlowSum = 0;

  const moneyFlow = i => typicalPriceBars[i] * volumeBars[i];
  let prevPrice = 0;
  let result = [];

  for (let i = 0; i < typicalPriceBars.length; i++) {
    let change = typicalPriceBars[i] - prevPrice;
    if (change > 0) {
      positiveMoneyFlowSum += moneyFlow(i);
    } else {
      negativeMoneyFlowSum += moneyFlow(i);
    }
    changes.push(change);
    prevPrice = typicalPriceBars[i];

    if (i < period - 1) {
      result.push(null);
      continue;
    }

    if (i > period - 1) {
      change = changes.shift();
      if (change > 0) {
        positiveMoneyFlowSum -= moneyFlow(i - period);
      } else {
        negativeMoneyFlowSum -= moneyFlow(i - period);
      }
    }

    let index = 0;
    if (negativeMoneyFlowSum === 0) {
      if (positiveMoneyFlowSum !== 0) {
        index = 100;
      }
    } else {
      index =
        (100 * positiveMoneyFlowSum) /
        (positiveMoneyFlowSum + negativeMoneyFlowSum);
    }
    result.push(index);
  }
  return result;
}

function getMfiData(bars, fullBarData, fullVolume, period) {
  let values = getMoneyFlowIndexes(fullBarData, fullVolume, period);
  return getFilteredBarValuesWithSameTimestamps(bars, fullBarData, values);
}

function getMfiSeries(periods, bars, fullBarData, localize, fullVolume) {
  if (!fullBarData) {
    return [];
  }

  let result = [];
  let period = periods[0];

  // First insert the red line for the overbought (80)
  result.push({
    id: 'mfiOverbought',
    name: localize({ id: 'symbol.details.chart.tooltip.overbought' }),
    type: 'line',
    color: '#ff2800',
    yAxis: 'mfi',
    data: getFixedLinesData(bars, 80),
    tooltip: { yDecimals: 2 },
    zIndex: 3
  });

  // Second insert the blue line for the oversold (20)
  result.push({
    id: 'mfiOversold',
    name: localize({ id: 'symbol.details.chart.tooltip.oversold' }),
    type: 'line',
    color: '#0055cc',
    yAxis: 'mfi',
    data: getFixedLinesData(bars, 20),
    tooltip: { yDecimals: 2 },
    zIndex: 2
  });

  // Finally, calculate the MFI for the given period
  result.push({
    id: 'mfi',
    name: localize({ id: 'symbol.details.chart.tooltip.mfi' }).replace(
      'XX',
      period
    ),
    type: 'line',
    color: '#898088',
    yAxis: 'mfi',
    data: getMfiData(bars, fullBarData, fullVolume, period),
    tooltip: { yDecimals: 2 },
    zIndex: 1
  });

  return result;
}

module.exports = {
  getSeries: getMfiSeries,
  // Added for testing
  getMfiData: getMfiData
};
