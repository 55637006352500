import React from 'react';
import { Link } from 'react-router-dom';

const NewScansEditCriteriaButton = props => {
  let buttonClassName = `margin button btn-cmn scan result edit-criteria${
    props.disabled ? ' disabled' : ''
  }`;
  return (
    <div className="actions edit-scan-criteria">
      <Link
        to="#"
        onClick={() => props.handleClickOpen()}
        disabled={props.disabled}
      >
        <div className={buttonClassName}>
          <div className={'edit-criteria-label'}>
            <div className={'edit-criteria-svg-label'}>{props.labelText}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default NewScansEditCriteriaButton;
