import React from 'react';
import NewScansResults from './NewScansResults';

const NewScansResultsContainer = props => {
  return (
    <div>
      <div className="results">
        <span className="results_title">{props.titleText}</span>
        <span className="results_count">
          {props.resultCountByCriteria && props.resultCountByCriteria.length > 0
            ? props.resultCountByCriteria[
                props.resultCountByCriteria.length - 1
              ]
            : 0}
        </span>
        {props.units}
      </div>
      <div>
        {props.scanResults && props.scanResults.length > 0 && (
          <NewScansResults scanResults={props.scanResults} />
        )}
      </div>
    </div>
  );
};
export default NewScansResultsContainer;
