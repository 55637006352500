import _ from 'lodash';

export const DEFAULT_MAX_SYMBOL_SEARCH_RESULTS = 10;

let idFilter = b => _.parseInt(b.Id, 10) > 0;
let cashFilter = b => b.Id.startsWith('cash');
let marginFilter = b => b.Id.startsWith('margin');

let orderByName = b => b.Name;

let exchangeOrderMapping = {
  TSE: 1,
  NSE: 2,
  FSE: 3,
  SSE: 4
};

export function getBoardById(marketboards, id) {
  var marketboardIndex = _.findIndex(marketboards, function(board) {
    return board.Id === id;
  });
  if (marketboardIndex === -1) return {};
  return marketboards[marketboardIndex];
}

export function getOrderedUserboards(boards) {
  // User boards may be reordered by the user and should maintain whatever
  // order they are originally stored in, thus we pass `null` for the sort
  // function.
  return getOrderedBoards(boards, idFilter, null);
}

export function getOrderedCashboards(boards) {
  return getOrderedBoards(boards, cashFilter, orderByName);
}

export function getOrderedMarginboards(boards) {
  return getOrderedBoards(boards, marginFilter, orderByName);
}

function getOrderedBoards(boards, filter, order) {
  var userBoards = _(boards)
    .filter(filter)
    .orderBy(order)
    .value();
  return userBoards;
}

export function getOrderedSymbolSearch(
  search,
  top = DEFAULT_MAX_SYMBOL_SEARCH_RESULTS
) {
  if (!search) {
    return [];
  }
  if (search.length <= 1) {
    return search;
  }
  let firstRoot = search[0].root;
  let allSameRoot = search.every(symbol => symbol.root === firstRoot);
  let result = _(search)
    .groupBy(allSameRoot ? 'id' : 'root')
    .sortBy(group => search.indexOf(group[0]))
    .map(symbols => {
      symbols = _(symbols)
        .sortBy(symbol => (symbol.jpIsPrimary ? 0 : symbol.exchange))
        .value();
      return symbols[0];
    });
  result = _(result).sortBy(symbol => {
    return allSameRoot
      ? symbol.jpIsPrimary
        ? 0
        : exchangeOrderMapping[symbol.exchangeName]
      : 0;
  });
  if (result.size() > top) {
    result = _(result).dropRight(result.size() - top);
  }
  return result.value();
}
