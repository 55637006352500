/** Gets the Exchange resource ID and appends the exchange section if supported */
export function getExchangeResource(exchangeName, section) {
  const exchangePrefix = 'exchange.' + exchangeName;

  if (
    section &&
    section !== '????' &&
    section.trim().length !== 0 &&
    (exchangeName === 'TSE' ||
      exchangeName === 'JASDAQ' ||
      (exchangeName === 'FSE' && (section === '0122' || section === '0123')))
  ) {
    return exchangePrefix + '.' + section;
  }

  return exchangePrefix;
}
