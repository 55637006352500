import React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import DialogNavigationPosition from './DialogNavigationPosition';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogModal extends React.Component {
  renderTopNavigation() {
    if (this.props.navigationPosition === DialogNavigationPosition.Top) {
      return (
        <div className={'dialog-modal-navigation'}>{this.props.navigation}</div>
      );
    }
    return null;
  }

  renderBottomNavigation() {
    if (
      !this.props.navigationPosition ||
      this.props.navigationPosition === DialogNavigationPosition.Bottom
    ) {
      return (
        <div className={'dialog-modal-navigation'}>{this.props.navigation}</div>
      );
    }
    return null;
  }

  render() {
    const isFullscreen =
      this.props.fullScreen === undefined || this.props.fullScreen === true;

    return (
      <Dialog
        fullScreen={isFullscreen}
        open={this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={this.props.transition || Transition}
        className={'dialog-modal-container'}
        PaperProps={this.props.dialogClassName && {
          className: this.props.dialogClassName
        }}
      >
        {this.renderTopNavigation()}
        <DialogContent
          dividers
          className={
            this.props.dialogContentClassName || ''
          }
        >
          {this.props.children}
        </DialogContent>
        {this.renderBottomNavigation()}
      </Dialog>
    );
  }
}

export default DialogModal;
