import React, { Suspense } from 'react';
import { useDesktopWidth } from '../../utils/hooks/useDesktopWidth';
import MobileHotlistContainer from './MobileHotlistContainer';
import { useFeatureToggle } from '../../utils/hooks/useFeatureToggle';

// lazy load the Desktop version to avoid unnecessary loading
// of AG Grid modules when user is viewing in mobile
const DesktopHotlistContainer = React.lazy(() =>
  import('./DesktopHotlistContainer')
);

const HotlistContainer = (props) => {
  const isDesktopWidth = useDesktopWidth();
  const isDesktopHotlistFeatureEnabled = useFeatureToggle(
    'desktopHotlistEnabled'
  );
  if (isDesktopWidth && isDesktopHotlistFeatureEnabled) {
    return (
      <Suspense fallback={<></>}>
        <DesktopHotlistContainer {...props} />
      </Suspense>
    );
  }

  return <MobileHotlistContainer {...props} />;
};

export default HotlistContainer;
