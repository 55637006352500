import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { getAvgDeviation } from '../../utils/indicators/avgDeviation';
import { tooltipFormatter } from '../../utils/chartTooltip';

import {
  HighchartsStockChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Debug,
  Series
} from 'react-jsx-highstock';

const AvgDeviation = ({ symbol, barSize, bars, setting }) => {
  const intl = useIntl();
  const localize = (id, otherParams) =>
    intl.formatMessage({ id: id }, otherParams);

  const periods = setting.params;
  const series = useMemo(() => periods.map((period, i) => {
    return {
      id: `avgDeviation${i}_${period}`,
      name: localize('symbol.details.chart.tooltip.avgDeviation').replace(
        'XX',
        period
      ),
      data: getAvgDeviation(bars, period)
    }
  }), [periods, bars]);

  return (
    <HighchartsStockChart key={`${symbol}/${barSize}/avgDeviation/insight`}>
      {/* Passing in the debug component will instruct the wrapper
    to place the highcharts object on `window.chart` for easy debugging. 
    https://github.com/whawker/react-jsx-highcharts/wiki/Debugging */}
      {process.env.NODE_ENV === 'development' && <Debug varName="avgDeviation"/>}

      <Chart
        height={200}/>

      <Tooltip
        useHTML={true}
        formatter={tooltipFormatter}
        outside={true}
        split={false}
        variables={{
          currentBarSize: barSize,
          realtime: true
        }}
        externalFunctions={{
          localize: localize
        }}
      />

      <XAxis />

      <YAxis>
        {series.map(avgDeviation => (
          <Series
            key={(avgDeviation || {}).id}
            type={'line'}
            id={(avgDeviation || {}).id}
            name={(avgDeviation || {}).name}
            data={(avgDeviation || {}).data}
            showInNavigator={true}
            animation={false}
          />
        ))}
      </YAxis>
     
    </HighchartsStockChart>
  );
};

export default AvgDeviation;
