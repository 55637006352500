import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import * as smaSeries from '../../utils/indicators/sma';

import { Series } from 'react-jsx-highstock';

const Sma = ({ symbol, barSize, bars, setting }) => {
  const intl = useIntl();
  const localize = (id, otherParams) =>
    intl.formatMessage({ id: id }, otherParams);

  const periods = setting.params;
  const series = useMemo(
    () =>
      periods.map((period, i) => {
        return {
          id: `sma${i}_${period}`,
          name: localize('symbol.details.chart.tooltip.sma').replace(
            'XX',
            period
          ),
          data: smaSeries.getSma(bars, period)
        };
      }),
    [periods, bars]
  );

  return (
    <>
      {series.map((sma) => (
        <Series
          key={(sma || {}).id}
          type={'line'}
          id={(sma || {}).id}
          name={(sma || {}).name}
          data={(sma || {}).data}
          showInNavigator={false}
          animation={false}
        />
      ))}
    </>
  );
};

export default Sma;
