import React, { useEffect, useState } from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import useActions from '../../utils/hooks/useActions';
import { fetchHeadlines } from '../../actions/news';
import { useIntl } from 'react-intl';
import NewsSearch from './NewsSearch';
import HeadlineList from './HeadlineList';
import { DesktopHelpBar } from '../common/DesktopHelpbar';
import { useDesktopWidth } from '../../utils/hooks/useDesktopWidth';
import { setDocumentTitle } from '../../utils/html.js';
import AFHConvert from 'ascii-fullwidth-halfwidth-convert';
const converter = new AFHConvert();

const DEFAULT_GLOBAL = 25;
const DEFAULT_NEWS_AMOUNT = 500;

const useStyles = makeStyles({
  newsContainer: {
    maxWidth: 1500,
    margin: 'auto'
  },
  sidebar: {
    width: '300px'
  },
  newsContainerWideMode: {
    margin: '10px 10px 10px 0px',
    width: '100%',
    fontSize: '12px'
  },
  newsContainerMobileMode: {
    width: '100%'
  },
  newsContainerWidgetMode: {
    width: '100%'
  }
});

const doesArrayContainText = (arrayToCheck, searchText) => {
  const converted = JSON.parse(arrayToCheck);
  if (Array.isArray(converted)) {
    const text = converter.toHalfWidth(converted.join('')).toLowerCase();
    return text.includes(searchText);
  } else {
    return false;
  }
};

const doesHeadlineContainText = (headline, searchTexts) => {
  const title = converter.toHalfWidth(headline.title).toLowerCase();
  return searchTexts.some((searchText) => 
    title.includes(searchText) ||
    doesArrayContainText(headline.relatedSymbols, searchText)
  );
};

// Split by comma (single and double bytes) and remove empty strings
const getFilteredSearchTexts = (searchText) => {
  return searchText.toLowerCase().replace(/[、]/g, ',').split(',').map(s => s.trim()).filter(s => s.length > 0);
};

export const getFilteredHeadlinesBySearch = (headlines, searchText) => {
  const search = converter.toHalfWidth(searchText).toLowerCase();
  const filteredSearchTexts = getFilteredSearchTexts(search);
  return headlines.filter((headline) =>
    doesHeadlineContainText(headline, filteredSearchTexts)
  );
};

export default function NewsContainer(props) {
  const intl = useIntl();
  const classes = useStyles();
  const localize = (i18nKey) => {
    return intl.formatMessage({
      id: i18nKey
    });
  };
  const isWidget = props.isWidget || false;
  const isWideView = useMediaQuery('(min-width: 767.98px)');

  // MarketboardPro feature is enabled or not
  const isDesktopWidth = useDesktopWidth();

  // States
  const [show, setShow] = useState(DEFAULT_GLOBAL);
  const [search, setSearch] = useState('');
  const [validProviders, setValidProviders] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);

  // Actions
  const [getNews] = useActions([fetchHeadlines]);

  // Functions
  const filterProviders = (headlines) => {
    return headlines
      .map((item) => {
        return item.provider;
      })
      .filter((item, index, arr) => {
        return arr.indexOf(item) === index;
      })
      .sort();
  };

  const arraysAreEqual = (inputArray1, inputArray2) => {
    // shortcut out if they are not
    // both arrays, or if the length
    // is not the same
    if (
      !Array.isArray(inputArray1) ||
      !Array.isArray(inputArray2) ||
      inputArray1.length !== inputArray2.length
    ) {
      return false;
    }
    var outputArray1 = inputArray1.concat().sort();
    var outputArray2 = inputArray2.concat().sort();

    for (var i = 0; i < outputArray1.length; i++) {
      if (outputArray1[i] !== outputArray2[i]) return false;
    }

    return true;
  };

  const checkProviders = (headlines) => {
    // Here we only want to update the "valid" providers when
    // a new provider is seen. At that time, we also want to
    // clear any display provider filters that we currently have
    let derivedProviders = filterProviders(headlines);
    if (derivedProviders.some((val) => validProviders.indexOf(val) === -1)) {
      setValidProviders(derivedProviders);
      setSelectedProviders(derivedProviders);
    }
  };

  const filteredHeadlines = (unfilteredHeadlines) => {
    let filteredHeadlines = unfilteredHeadlines;
    //First filter on the providers if:
    // 1) display providers is set
    // 2) they display providers is different from all providers
    // (they contain the same members),
    if (
      !!selectedProviders &&
      !arraysAreEqual(selectedProviders, validProviders)
    ) {
      filteredHeadlines = filteredHeadlines.filter((item) =>
        selectedProviders.includes(item.provider)
      );
    }

    // next we will filter on the search text if:
    // 1) search has content
    if (!!search) {
      filteredHeadlines = getFilteredHeadlinesBySearch(filteredHeadlines, search);
    }

    return filteredHeadlines;
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const toggleProvider = (providerId) => {
    let dsp = selectedProviders;

    if (dsp.includes(providerId)) {
      dsp = dsp.filter((item) => item !== providerId);
    } else {
      // using concat rather than push because
      // if we just push, React won't re-render
      // because it sees the array as the same,
      // whereas concat will create a new object
      // and trigger a re-render
      dsp = dsp.concat(providerId).sort();
    }
    setSelectedProviders(dsp);
  };

  const sideBar = (isWide) => (
    <NewsSearch
      isCompact={!!isWide}
      onTextChange={handleChange}
      onToggleProvider={toggleProvider}
      validProviders={validProviders}
      selectedProviders={selectedProviders}
    />
  );

  const newsContainerFullClass = isWidget
    ? classes.newsContainerWidgetMode
    : isWideView
    ? classes.newsContainerWideMode
    : classes.newsContainerMobileMode;

  const newsContainer = (settings) => (
    <>
      {isDesktopWidth && !isWidget && (
        <div className="section-desktop-help-bar">
            <DesktopHelpBar
              route={'news'}
            />
        </div>
      )}

    <Stack
      direction={settings.isWide ? 'row' : 'column'}
      justifyContent={'center'}
      className={classes.newsContainer}
    >
      {settings.displaySearch === undefined || settings.displaySearch
        ? sideBar(settings.isWide)
        : ''}

      <div className={newsContainerFullClass}>
        <div className="section-header">{localize('news.new')}</div>
        <div>
          <HeadlineList
            amountNewsToShow={settings.amountNewsToShow}
            isLoading={settings.isLoading}
            key={settings.key}
            newsIncrement={settings.newsIncrement}
            hideShowMore={settings.hideShowMore}
            headlines={settings.headlines}
            isWide={settings.isWide}
            isDisableRelatedSymbols={props.isDisableRelatedSymbols}
          />
        </div>
      </div>
    </Stack>
    </>
  );

  // Get news during component initialization
  useEffect(() => {
    getNews(props.symbol || null, props.newsAmount || DEFAULT_NEWS_AMOUNT);
  }, []);

  // Redux Store
  const headlineData = useSelector(
    (state) => (state.news.headlines.data || {}).articles || []
  );
  const isLoading = useSelector((state) => state.isLoading || false);

  checkProviders(headlineData);
  const filteredList = filteredHeadlines(headlineData);

  useEffect(() => {
    if (!isWidget) {
       // Set Document Title only in non-widget mode
       setDocumentTitle(localize('document.title.global.news'));
    }
  });

  return newsContainer({
    amountNewsToShow: props.amountNewsToShow || show,
    isLoading: isLoading,
    newsIncrement: props.newsIncrement || DEFAULT_GLOBAL,
    hideShowMore: false,
    headlines: filteredList,
    isWide: isWideView,
    displaySearch: props.displaySearch,
    key: props.key || 'global-news__headline-list'
  });
}
