import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { isPositionBoard } from '../../utils/marketboards';
import makeStyles from '@mui/styles/makeStyles';
import {createTheme, ThemeProvider} from "@mui/material/styles";

const marketboardFontFamily = '"Arial", "ヒラギノ角ゴシック ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", sans-serif';

const useStyles = makeStyles({
  marketboardTitleEdit: {
    height: "37px",
    left: "0px",
    top: "0px",
    borderRadius: "0px",
    padding: "12px 12px 0px 12px"
  },
  marketboardTitleEditInput: {
    borderBottom: "1px solid #00000026",
    '& div>input': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden' 
    }
  }
});

const textFieldTheme = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input": {
            fontFamily: marketboardFontFamily,
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "16px",
            letterSpacing: "0em",
            textAlign: "left"
          }, 
        }
      },
    },
  },
});


export const MarketBoardTitleEdit = ({ board, onBlur }) => {
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });
  const styles = useStyles();

  // states
  const [marketboardTitle, setMarketboardTitle] = useState(board.Name);

  useEffect(() => {
    setMarketboardTitle(board.Name);
  }, [board.Name]);

  // events
  const onBlurHandler = () => {
    if (marketboardTitle !== board.Name) {
      onBlur(marketboardTitle);
    }
  };

  const onChangeHandler = (e) => {
    setMarketboardTitle(e.currentTarget.value.slice(0, 20));
  };

  const isCashOrMargin = isPositionBoard(board.Name || '');

  return (
      <div id={'marketboard-title-edit'} className={styles.marketboardTitleEdit}>
        <ThemeProvider theme={textFieldTheme}>
          <TextField
              key={board.id}
              value={marketboardTitle || ''}
              className={styles.marketboardTitleEditInput}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              placeholder={localize('marketboard.header.board.name')}
              variant="standard"
              disabled={isCashOrMargin}
          />
        </ThemeProvider>
      </div>
  );
};
