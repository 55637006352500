import React from 'react';
import { injectIntl } from 'react-intl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class ChartStyle extends React.Component {
  localize(i18nKey, otherParams) {
    return this.props.intl.formatMessage({ id: i18nKey }, otherParams);
  }

  render() {
    return (
      <li className="settings-card">
        <div>
          <div className="settings-card-contents">
            <div className="settings-chart-style-title">
              {this.localize('settings.chart.chartStyle.title')}
            </div>
            <div className="settings-chart-style-div-select">
              <Select
                labelId="settings-chart-style-select"
                id="settings-chart-style-select"
                value={this.props.value}
                onChange={this.props.onChange}
                className="settings-chart-style-select"
                variant="standard"
              >
                <MenuItem value={'candlestick'}>
                  {this.localize('settings.chart.chartStyle.candleStick')}
                </MenuItem>
                <MenuItem value={'candlestickBlackWhite'}>
                  {this.localize(
                    'settings.chart.chartStyle.candleStickBlackWhite'
                  )}
                </MenuItem>
                <MenuItem value={'ohlc'}>
                  {this.localize('settings.chart.chartStyle.ohlc')}
                </MenuItem>
                <MenuItem value={'area'}>
                  {this.localize('settings.chart.chartStyle.area')}
                </MenuItem>
                <MenuItem value={'line'}>
                  {this.localize('settings.chart.chartStyle.line')}
                </MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default injectIntl(ChartStyle);
