import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

const duration = 500;

const Fade = ({ cssName, in: inProp, children }) => (
  <CSSTransition
    in={inProp}
    timeout={duration}
    classNames={{
      enter: cssName,
      enterActive: cssName,
      enterDone: cssName
    }}
  >
    {(state) => children}
  </CSSTransition>
);

const CssFlash = ({ value, isChange = true, children }) => {
  const [changed, setChanged] = useState(false);
  const [timer, setTimer] = useState(null);
  const [currValue, setCurrValue] = useState(null);
  const [cssName, setCssName] = useState('flash');

  const triggerFlash = () => {
    setChanged(true);

    if(isChange){
      let changeAmount = value - currValue;

      changeAmount = isNaN(changeAmount) ? 0 : changeAmount;
      if(changeAmount === 0){
        return null
      } 
      setCssName(changeAmount > 0 ? 'flash-up' : 'flash-down')
      setCurrValue(value)
    }

    // reset hasChanged after timeout
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        setChanged(false);
      }, duration)
    );
  };

  useEffect(() => {
    // cleanup - clear timer on unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  useEffect(() => {
    // trigger flash every time value has changed
    triggerFlash();
  }, [value]);

  return (
    <Fade in={changed} value={value} cssName={cssName}>
      {children}
    </Fade>
  );
};

export default CssFlash;
