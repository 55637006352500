const {
  getFixedLinesData,
  getFilteredBarValuesWithSameTimestamps,
  simpleMovingAverage
} = require('./utils');
const { range } = require('lodash');

function getRankChangeSquareSum(priceWindow) {
  let priceBars = range(priceWindow.length).map(i => [priceWindow[i], i]);
  let sortedPrices = priceBars.sort((a, b) => a[0] - b[0]);
  let rankChangeSquareSum = 0;
  for (let i = 0; i < sortedPrices.length; i++) {
    rankChangeSquareSum += (i - sortedPrices[i][1]) * (i - sortedPrices[i][1]);
  }
  return rankChangeSquareSum;
}

function getRciData(bars, fullBarData, period, avgPeriod) {
  let priceBars = fullBarData.map(a => a[4]);
  let values = [];
  let priceWindow = [];

  const denom = period * (period * period - 1);
  for (let i = 0; i < priceBars.length; i++) {
    priceWindow.push(priceBars[i]);
    if (denom !== 0 && i >= period - 1) {
      let corrCoeff = 1 - (6 * getRankChangeSquareSum(priceWindow)) / denom;
      let rci = corrCoeff * 100;
      values.push(rci);
      priceWindow.shift();
    } else {
      values.push(null);
    }
  }

  let valuesAvg = simpleMovingAverage(values, avgPeriod);
  return {
    rci: getFilteredBarValuesWithSameTimestamps(bars, fullBarData, values),
    rciAvg: getFilteredBarValuesWithSameTimestamps(bars, fullBarData, valuesAvg)
  };
}

function getRciSeries(periods, bars, fullBarData, localize) {
  if (!fullBarData) {
    return [];
  }

  let result = [];
  const period = periods[0];
  const avgPeriod = periods[1];
  let rciData = getRciData(bars, fullBarData, period, avgPeriod);

  // First insert the red line for the overbought (80)
  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.overbought' }),
    type: 'line',
    color: '#ff2800',
    yAxis: 'rci',
    data: getFixedLinesData(bars, 80),
    tooltip: { yDecimals: 2 },
    zIndex: 3
  });

  // Second insert the blue line for the oversold (-80)
  result.push({
    name: localize({ id: 'symbol.details.chart.tooltip.oversold' }),
    type: 'line',
    color: '#0055cc',
    yAxis: 'rci',
    data: getFixedLinesData(bars, -80),
    tooltip: { yDecimals: 2 },
    zIndex: 2
  });

  // Calculate the RCI for the given period
  result.push({
    id: 'RCI',
    name: localize({ id: 'symbol.details.chart.tooltip.rci' }).replace(
      'XX',
      period
    ),
    type: 'line',
    color: '#1eb5b5',
    yAxis: 'rci',
    data: rciData['rci'],
    tooltip: { yDecimals: 2 },
    zIndex: 1
  });

  // Finally, calculate the RCI Average for the given avgPeriod
  result.push({
    id: 'RCI',
    name: localize({ id: 'symbol.details.chart.tooltip.rci.average' }).replace(
      'XX',
      avgPeriod
    ),
    type: 'line',
    color: '#898088',
    yAxis: 'rci',
    data: rciData['rciAvg'],
    tooltip: { yDecimals: 2 },
    zIndex: 1
  });

  return result;
}

module.exports = {
  getSeries: getRciSeries,
  // Added for testing
  getRciData: getRciData
};
