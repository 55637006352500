import React, { useEffect, useMemo } from 'react';
import Number from '../common/Number';
import DateTime from '../common/DateTime';
import { getFundamentalValue } from '../../utils/fundamentals';
import useLocalize from '../../utils/hooks/useLocalize';
import useActions from '../../utils/hooks/useActions';
import { start, stop } from '../../actions/stream';
import { useSelector } from 'react-redux';
import useFundamentals from '../../utils/hooks/useFundamentals';
import { selectChannel } from '../../selectors/channelSelectors';

const i18nKeyPrefix = 'symbol.details.margin.information.';
const mdash = '\u2014';

const getFieldValue = (fundamentals, field) => {
  return getFundamentalValue(fundamentals, field, 'value');
}

const getWeeklyMarginRatio = (fundamentals) => {
  let purchases = getFieldValue(fundamentals, 'weeklyMarginPurchases');
  let sales = getFieldValue(fundamentals, 'weeklyMarginSales');
  if ((typeof purchases !== 'number') | (typeof sales !== 'number')) {
    return null;
  }

  let ratio = 0;
  if (sales > 0) {
    ratio = purchases / sales;
  }

  return ratio;
}

const getLendingFeeRatio = (prevClose, lendingFee) => {
  if (typeof lendingFee !== 'number' || typeof prevClose !== 'number') {
    return null;
  }

  let ratio = 0;
  if (prevClose > 0) {
    ratio = (lendingFee / prevClose) * 100;
  }

  return ratio;
}

const getDifference = (value1, value2) => {
  let result = value1 - value2;
  if (isNaN(result)) {
    return null;
  }

  return result;
}

const getMarginValue = (fundamentals, attribute) => {
  return getFundamentalValue(fundamentals, 'margin', attribute);
}

const getCellValue = (valueObj, index, isLastColumn) => {
  if (valueObj.hide) {
    return (
      <div
        className={`symbol-margin-table-cell symbol-margin-information-table-cell-value${
          isLastColumn ? ' last-column' : ''
        }`}
        key={index}
      ></div>
    );
  }

  if (valueObj.value === null && valueObj.showSuffixIfNull) {
    return (
      <div
        className="symbol-margin-table-cell symbol-margin-information-table-cell-value number"
        key={index}
      >
        {mdash + valueObj.suffix}
      </div>
    );
  }

  let className = valueObj.signed
    ? 'market_summary_symbol__net-change-value'
    : null;
  return (
    <div
      className={`symbol-margin-table-cell symbol-margin-information-table-cell-value${
        isLastColumn ? ' last-column' : ''
      }`}
      key={index}
    >
      <Number
        className={className}
        value={valueObj.value}
        precision={valueObj.precision}
        signed={valueObj.signed}
        suffix={valueObj.suffix}
      />
    </div>
  );
}

const WeeklyMarginDate = ({ symbol }) => {
  // Redux
  const fundamentals = useFundamentals(symbol);

  let utcDateText = getFundamentalValue(
    fundamentals,
    'weeklyMarginPurchases',
    'reportingDate'
  );
  if (!utcDateText) {
    return mdash;
  }

  return (
    <DateTime format="MM/DD" value={utcDateText} prefix="(" suffix=")" />
  );
}

const LendingFeeDate = ({ symbol }) => {
  // Redux
  const symbolFundamentals = useSelector(
    (state) => (state.market.fundamentals || {})[symbol] || {}
  );

  const fundamentals = (symbolFundamentals.data || {}).fundamentals || {};
  const localize = useLocalize();

  let utcDateText = getFundamentalValue(
    fundamentals,
    'lendingFee',
    'reportingDate'
  );
  if (!utcDateText) {
    return mdash;
  }

  return (
    <DateTime
      format="MM/DD"
      value={utcDateText}
      prefix="("
      suffix={localize(i18nKeyPrefix + 'finalDateFormat')}
    />
  );
}

const DailyMarginDate = ({ symbol }) => {
  // Redux
  const symbolFundamentals = useSelector(
    (state) => (state.market.fundamentals || {})[symbol] || {}
  );

  const fundamentals = (symbolFundamentals.data || {}).fundamentals || {};
  const localize = useLocalize();

  let utcDateText = getFundamentalValue(
    fundamentals,
    'margin',
    'reportingDate'
  );
  if (!utcDateText) {
    return mdash;
  }

  let finalValue = getFundamentalValue(
    fundamentals,
    'margin',
    'final'
  );
  let i18nKeySuffix = 'estimateDateFormat';
  if (finalValue) {
    i18nKeySuffix = 'finalDateFormat';
  }

  return (
    <DateTime
      format="MM/DD"
      value={utcDateText}
      prefix="("
      suffix={localize(i18nKeyPrefix + i18nKeySuffix)}
    />
  );
}

const SectionRow = ({ data }) => {
  const localize = useLocalize();

  const title = data.title ? (
    <div className="symbol-margin-information-table-cell-title">
      {localize(i18nKeyPrefix + data.title)}
    </div>
  ) : null;

  let lastColumnIndex = data.values.length - 1;

  return (
    <div className="exchange-summary-detail-table-row">
      {title}
      {data.values.map((e, i) =>
        getCellValue(e, i, i === lastColumnIndex)
      )}
    </div>
  );
}

const MarginInformationSection = ({ symbol }) => {
  // Redux
  const [startStream] = useActions([start], []);
  const [stopStream] = useActions([stop], []);
  const symbolFundamentals = useSelector(
    (state) => (state.market.fundamentals || {})[symbol] || {}
  );

  const fundamentals = (symbolFundamentals.data || {}).fundamentals || {};
  useEffect(() => {
    if (!symbol) {
      return;
    }

    startStream(`${symbol}/quote`);

    return () => {
      stopStream(`${symbol}/quote`);
    };
  }, [symbol, startStream, stopStream]);

  const channel = useMemo(() => `${symbol}/quote`, [symbol]);
  const quote = useSelector((state) => selectChannel(state, channel));
  const prevClose = quote.prevClose;

  const localize = useLocalize();

  let dailyOutstandingLoaned = getMarginValue(fundamentals, 'dailyBorrowedSharesOutstanding');
  let dailyNewLoaned = getMarginValue(fundamentals, 'dailyBorrowedSharesNew');
  let dailyRepaidLoaned = getMarginValue(fundamentals, 'dailyBorrowedSharesRepaid');
  let dailyLoanedChange = getDifference(
    dailyNewLoaned,
    dailyRepaidLoaned
  );

  let dailyOutstandingBorrowed = getMarginValue(
    fundamentals,
    'dailyLoanedSharesOutstanding'
  );

  let dailyNewBorrowed = getMarginValue(fundamentals, 'dailyLoanedSharesNew');
  let dailyRepaidBorrowed = getMarginValue(fundamentals, 'dailyLoanedSharesRepaid');
  let dailyBorrowedChange = getDifference(
    dailyNewBorrowed,
    dailyRepaidBorrowed
  );

  let dailyOutstandingSharesDifference = getDifference(
    dailyOutstandingLoaned,
    dailyOutstandingBorrowed
  );

  let dailyNewSharesDifference = getDifference(
    dailyNewLoaned,
    dailyNewBorrowed
  );

  let dailyRepaidSharesDifference = getDifference(
    dailyRepaidLoaned,
    dailyRepaidBorrowed
  );

  let dailySharesChangeDifference = getDifference(
    dailyLoanedChange,
    dailyBorrowedChange
  );

  let lendingFee = getFundamentalValue(
    fundamentals,
    'lendingFee',
    'value'
  );

  if (typeof lendingFee === 'number') {
    lendingFee = lendingFee * 0.01;
  }

  let lendingFeeRatio = getLendingFeeRatio(prevClose, lendingFee);
  return (
    <div className="symbol-margin-information-section">
      <div className="section-header symbol-indicators-title">
        {localize(i18nKeyPrefix + 'title')}
      </div>
      <div className="symbol-indicator-fundamental-analysis-details">
        <div className="symbol-margin-information-title">
          <span className="title">
            {localize(i18nKeyPrefix + 'weeklyMarginBalance')}
          </span>
          <span className="date"><WeeklyMarginDate symbol={symbol} /></span>
        </div>
        <div className="exchange-summary-detail-table">
          <div className="exchange-summary-detail-table-heading">
            <div className="exchange-summary-detail-table-row">
              <div className="exchange-summary-detail-table-head">&nbsp;</div>
              <div className="exchange-summary-detail-table-head">
                {localize(i18nKeyPrefix + 'shareCount')}
              </div>
              <div className="exchange-summary-detail-table-head symbol-indicators-fundamental-anaylis-title  last-column">
                {localize(i18nKeyPrefix + 'changeFromPreviousWeek')}
              </div>
            </div>
          </div>
          <div className="exchange-summary-detail-table-body">
            <SectionRow data={{
              title: 'buyBalance',
              values: [
                {
                  value: getFieldValue(fundamentals, 'weeklyMarginPurchases')
                },
                {
                  value: getFieldValue(fundamentals, 'weeklyMarginPurchasesChange'),
                  signed: true
                }
              ]
            }} />
            <SectionRow data={{
              title: 'sellBalance',
              values: [
                {
                  value: getFieldValue(fundamentals, 'weeklyMarginSales')
                },
                {
                  value: getFieldValue(fundamentals, 'weeklyMarginSalesChange'),
                  signed: true
                }
              ]
            }} />
            <SectionRow data={{
              title: 'marginBuySellRatio',
              values: [
                {
                  value: getWeeklyMarginRatio(fundamentals),
                  precision: 2
                },
                {
                  hide: true
                }
              ]
            }} />
          </div>
        </div>
      </div>
      <div className="symbol-indicator-fundamental-analysis-details">
        <div className="symbol-margin-information-title">
          <span className="title">
            {localize(i18nKeyPrefix + 'dailyMarginBalance')}
          </span>
          <span className="date"><DailyMarginDate symbol={symbol} /></span>
        </div>
        <div className="exchange-summary-detail-table">
          <div className="exchange-summary-detail-table-heading">
            <div className="exchange-summary-detail-table-row">
              <div className="exchange-summary-detail-table-head">&nbsp;</div>
              <div className="exchange-summary-detail-table-head">
                {localize(i18nKeyPrefix + 'shareCount')}
              </div>
              <div className="exchange-summary-detail-table-head symbol-indicators-fundamental-anaylis-title">
                {localize(i18nKeyPrefix + 'marginOpen')}
              </div>
              <div className="exchange-summary-detail-table-head symbol-indicators-fundamental-anaylis-title">
                {localize(i18nKeyPrefix + 'marginClose')}
              </div>
              <div className="exchange-summary-detail-table-head symbol-indicators-fundamental-anaylis-title last-column">
                {localize(i18nKeyPrefix + 'changeFromPreviousDay')}
              </div>
            </div>
          </div>
          <div className="exchange-summary-detail-table-body">
            <SectionRow data={{
              title: 'financeBalance',
              values: [
                {
                  value: dailyOutstandingLoaned
                },
                {
                  value: dailyNewLoaned
                },
                {
                  value: dailyRepaidLoaned
                },
                {
                  value: dailyLoanedChange,
                  signed: true
                }
              ]
            }} />
            <SectionRow data={{
              title: 'stockLoanBalance',
              values: [
                {
                  value: dailyOutstandingBorrowed
                },
                {
                  value: dailyNewBorrowed
                },
                {
                  value: dailyRepaidBorrowed
                },
                {
                  value: dailyBorrowedChange,
                  signed: true
                }
              ]
            }} />
            <SectionRow data={{
              title: 'difference',
              values: [
                {
                  value: dailyOutstandingSharesDifference
                },
                {
                  value: dailyNewSharesDifference
                },
                {
                  value: dailyRepaidSharesDifference
                },
                {
                  value: dailySharesChangeDifference,
                  signed: true
                }
              ]
            }} />
            <SectionRow data={{
              title: 'marginTurnoverPeriod',
              values: [
                {
                  value: getMarginValue(fundamentals, 'dailyTurnoverPeriod'),
                  precision: 1,
                  suffix: localize(i18nKeyPrefix + 'days')
                },
                {
                  hide: true
                },
                {
                  hide: true
                },
                {
                  hide: true
                }
              ]
            }} />
          </div>
        </div>
      </div>
      <div className="symbol-indicator-fundamental-analysis-details">
        <div className="symbol-margin-information-title">
          <span className="title">
            {localize(i18nKeyPrefix + 'lendingFeeInformation')}
          </span>
          <span className="date"><LendingFeeDate symbol={symbol} /></span>
        </div>
        <div className="exchange-summary-detail-table">
          <div className="exchange-summary-detail-table-heading">
            <div className="exchange-summary-detail-table-head">
              {localize(i18nKeyPrefix + 'lendingFee')}
            </div>
            <div className="exchange-summary-detail-table-head symbol-indicators-fundamental-anaylis-title last-column">
              {localize(i18nKeyPrefix + 'lendingFeeVsClose')}
            </div>
          </div>
          <div className="exchange-summary-detail-table-body">
            <SectionRow data={{
              values: [
                {
                  value: lendingFee,
                  precision: 2,
                  suffix: localize(i18nKeyPrefix + 'yen'),
                  showSuffixIfNull: true
                },
                {
                  value: lendingFeeRatio,
                  precision: 3,
                  suffix: '%'
                }
              ]
            }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarginInformationSection;