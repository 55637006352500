function getFundamentalValue(fundamentals, field, attribute) {
  if (
    !fundamentals ||
    !fundamentals[field] ||
    typeof fundamentals[field][attribute] === 'undefined'
  ) {
    return null;
  }

  return fundamentals[field][attribute];
}

function getFundamentalValues(fundamentals, field, attributes, ignoreValues) {
  let result = {};
  for (let i = 0; i < attributes.length; i++) {
    let attribute = attributes[i];
    if (ignoreValues) {
      result[attribute] = null;
    } else {
      result[attribute] = getFundamentalValue(fundamentals, field, attribute);
    }
  }

  return result;
}

function getPeriodDate(fundamentals, field) {
  return getFundamentalValue(fundamentals, field, 'reportingDate');
}

function getAnnouncementDate(fundamentals, field) {
  return getFundamentalValue(fundamentals, field, 'announcementDate');
}

function getConsolidated(fundamentals, field) {
  return getFundamentalValue(fundamentals, field, 'consolidated');
}

function getAnalysisFundamentals(fundamentals) {
  let resultsPeriodDateText = getPeriodDate(fundamentals, 'companyResults');
  let resultsPeriodDate = 0;
  if (resultsPeriodDateText) {
    resultsPeriodDate = new Date(resultsPeriodDateText);
  }

  let estimatesPeriodDateText = getPeriodDate(fundamentals, 'companyEstimates');
  let estimatesPeriodDate = 0;
  if (estimatesPeriodDateText) {
    estimatesPeriodDate = new Date(estimatesPeriodDateText);
  }

  // Estimates
  // It may so happen that the most recent estimates are from a fiscal
  // year prior to the most recent results. In this case it would look
  // weird for Popsicle to display old estimates next to newer results,
  // so we'll only format the estimates if the period date is greater
  // than or equal to the period date for the results.
  let useCompanyEstimateValues = estimatesPeriodDate >= resultsPeriodDate;

  let result = {
    companyResults: getFundamentalValues(fundamentals, 'companyResults', [
      'netSales',
      'netSalesChange',
      'ordinaryProfit',
      'ordinaryProfitChange',
      'netProfit',
      'netProfitChange',
      'earningsPerShare',
      'priceEarningsRatio',
      'priceBookRatio',
      'returnOnEquity',
      'returnOnAssets',
      'bookValuePerShare',
      'capitalAdequacyRatio',
      'dividendPerShare',
      'dividendYield',
      'dividendPayoutRatio'
    ]),
    companyEstimates: getFundamentalValues(
      fundamentals,
      'companyEstimates',
      [
        'netSales',
        'netSalesChange',
        'ordinaryProfit',
        'ordinaryProfitChange',
        'netProfit',
        'netProfitChange',
        'earningsPerShare',
        'priceEarningsRatio',
        'dividendPerShare',
        'dividendYield',
        'dividendPayoutRatio'
      ],
      !useCompanyEstimateValues
    ),
    analyst: getFundamentalValues(fundamentals, 'analyst', [
      'netSales',
      'ordinaryProfit',
      'netProfit',
      'priceEarningsRatio'
    ])
  };

  // General info
  result.companyResults.periodDate = resultsPeriodDateText;
  result.companyResults.announcementDate = getAnnouncementDate(
    fundamentals,
    'companyResults'
  );
  result.companyResults.consolidated = getConsolidated(
    fundamentals,
    'companyResults'
  );
  result.companyEstimates.periodDate = useCompanyEstimateValues
    ? estimatesPeriodDateText
    : null;
  result.companyEstimates.announcementDate = useCompanyEstimateValues
    ? getAnnouncementDate(fundamentals, 'companyEstimates')
    : null;
  result.companyEstimates.consolidated = getConsolidated(
    fundamentals,
    'companyEstimates'
  );
  result.analyst.periodDate = getPeriodDate(fundamentals, 'analyst');
  result.analyst.consolidated = getConsolidated(fundamentals, 'analyst');

  return result;
}

module.exports = {
  getAnalysisFundamentals: getAnalysisFundamentals,
  getFundamentalValue: getFundamentalValue
};
