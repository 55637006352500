var _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault');

Object.defineProperty(exports, '__esModule', {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require('react'));

var _pure = _interopRequireDefault(require('recompose/pure'));

var _SvgIcon = _interopRequireDefault(require('@mui/material/SvgIcon'));

var _ref = _react.default.createElement('path', {
  d: 'M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'
});

/**
 * @ignore - internal component.
 */
var Add = function Add(props) {
  return _react.default.createElement(_SvgIcon.default, props, _ref);
};

Add = (0, _pure.default)(Add);
Add.muiName = 'SvgIcon';
var _default = Add;
exports.default = _default;
