import types from '../actions/_actionTypes';
import initialState from './initialState';
import * as time from '../utils/time';

function getStateId(action) {
  const symbol = action.symbol;
  const period = action.period;
  return symbol + '/' + period;
}

const bars = (state = initialState.bars, action) => {
  let newState;
  let stateId = getStateId(action);

  switch (action.type) {
    case types.REQUESTING_BARS:
      newState = Object.assign({}, state);
      newState[stateId] = {
        data: newState[stateId]?.data,
        isLoading: true,
        didInvalidate: false,
        isError: false,
        when: time.timestamp()
      };
      return newState;
    case types.RECEIVE_BARS:
      newState = Object.assign({}, state);
      newState[stateId] = {
        data: action.data.data,
        isLoading: false,
        didInvalidate: false,
        isError: false,
        when: time.timestamp()
      };
      return newState;
    case types.INVALIDATE_BARS:
      newState = Object.assign({}, state);
      newState[stateId] = {
        data: null,
        isLoading: false,
        didInvalidate: true,
        isError: false,
        when: time.timestamp()
      };
      return newState;
    case types.ERROR_REQUESTING_BARS:
      newState = Object.assign({}, state);
      newState[stateId] = {
        data: null,
        isLoading: false,
        didInvalidate: false,
        isError: true,
        when: time.timestamp()
      };
      return newState;
    default:
      return state;
  }
};

export default bars;
