import navigationTypes from '../actions/_navigationTypes';
const { detect } = require('detect-browser');
const browser = detect();
import { eventLogger } from '../utils/hooks/useNewRelic';

const eventLog = eventLogger();

export async function postMetric(data) {
  let payload = {
    metricType: data.metricType,
    eventType: `Popsicle:${data.metricName}`,
    timestamp: new Date().toISOString(),
    component: 'Popsicle',
    href: window.location.href,
    browserName: browser.name,
    browserVersion: browser.version,
    platform: window.navigator.platform || 'Unknown',
  };

  // Used to pass extra data in an object
  if (data.customData) {
    payload = Object.assign(payload, data.customData);
  }

  await eventLog.sendEvent(payload);
}

export async function postUserTrade(data) {
  switch (data.navigationType) {
    case navigationTypes.MB_CASH_BUY_CLICK:
    case navigationTypes.SD_CASH_BUY_CLICK:
    case navigationTypes.MP_CASH_BUY_CLICK:
    case navigationTypes.MP_MATRIX_CASH_BUY_CLICK:
    case navigationTypes.SD_MATRIX_CASH_BUY_CLICK:
    case navigationTypes.SS_CASH_BUY_CLICK:
      data.metricName = 'CashBuy';
      data.urlType = 'buy';
      break;
    case navigationTypes.MB_CASH_SELL_CLICK:
    case navigationTypes.SD_CASH_SELL_CLICK:
    case navigationTypes.MP_CASH_SELL_CLICK:
    case navigationTypes.MP_MATRIX_CASH_SELL_CLICK:
    case navigationTypes.SD_MATRIX_CASH_SELL_CLICK:
      data.metricName = 'CashSell';
      data.urlType = 'sell';
      break;
    case navigationTypes.MB_MARGIN_ORDER_CLICK:
    case navigationTypes.SD_MARGIN_ORDER_CLICK:
    case navigationTypes.MP_MARGIN_ORDER_CLICK:
      data.metricName = 'MarginBuy';
      data.urlType = 'margin';
      break;
    case navigationTypes.MB_MARGIN_SELL_CLICK:
    case navigationTypes.SD_MARGIN_SELL_CLICK:
    case navigationTypes.MP_MARGIN_SELL_CLICK:
      data.metricName = 'MarginSell';
      data.urlType = 'marginRefund';
      break;
    case navigationTypes.SD_SMALL_LOT_ORDER_CLICK:
    case navigationTypes.MP_SMALL_LOT_ORDER_CLICK:
      data.metricName = 'SmallLotOrder';
      data.urlType = 'buy';
      break;
  }

  let payload = {
    eventType: 'Popsicle:UserTrade',
    orderType: data.metricName,
    side: data.urlType,
    symbol: data.symbol,
    urlType: data.urlType,
    userId: data.encryptedId,
    urlHost: window.location.hostname,
    urlPath: window.location.pathname,
    browserName: browser.name,
    browserVersion: browser.version,
    platform: window.navigator.platform || 'Unknown',
  };

  await eventLog.sendEvent(payload);
}
