import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { MobileMarketBoardDropdownSelect } from './MobileMarketBoardDropdownSelect';
import { MobileHelpBar } from '../common/MobileHelpBar';
import { MobileMarketboardSearchBox } from './MobileMarketboardSearchBox';
import {
  getAvailableBoardPosition, isPositionBoard
} from '../../utils/marketboards';

const useStyles = makeStyles({
  pageTitleBar: {
    padding: '10px 0px 15px 15px',
    height: '20px'
  }
});

export const MobilePageTitleBar = ({ marketboard }) => {
  const styles = useStyles();

  if (!marketboard || !marketboard.Id) return null;

  const isCashOrMargin = isPositionBoard(marketboard.Id);
  const boardPosition = isCashOrMargin ? '-1' : getAvailableBoardPosition(marketboard.Symbols);
  const setPositionUrl = `#/marketboard/edit/${marketboard.Id}/${boardPosition}`;
  const editBoardUrl = `#/marketboard/edit/${marketboard.Id}`;

  return (
    <>
      <MobileMarketboardSearchBox
        isDisabled={true}
        onClick={() => (window.location.hash = setPositionUrl)}
      />
      <div id={'page-title-bar'} className={styles.pageTitleBar}>
        <MobileMarketBoardDropdownSelect marketboard={marketboard} />
        <MobileHelpBar editUrlHash={editBoardUrl} disableEdit={isCashOrMargin} />
      </div>
    </>
  );
};
