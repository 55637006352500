const {
  simpleMovingAverage,
  getFilteredBarValuesWithSameTimestamps
} = require('./utils');

function getMovAvgEnvelopes(bars, fullBarData, period, valueIndex) {
  let targetBars = fullBarData.map(a => a[valueIndex]);
  let values = simpleMovingAverage(targetBars, period);
  return getFilteredBarValuesWithSameTimestamps(bars, fullBarData, values);
}

// Bar values [timestamp, open, high, low, close, isChartBar]
const bar = {
  High: 2,
  Low: 3
};

function getMovAvgEnvelopesSeries(periods, bars, fullBarData, localize) {
  if (!fullBarData) {
    return [];
  }

  let result = [];
  let period = periods[0];

  result.push({
    name: localize({
      id: 'symbol.details.chart.tooltip.movAvgEnvelopes.upperBand'
    }),
    type: 'line',
    color: '#d9027c',
    yAxis: 0,
    data: getMovAvgEnvelopes(bars, fullBarData, period, bar.High),
    tooltip: { yDecimals: 2 },
    zIndex: 3
  });

  result.push({
    name: localize({
      id: 'symbol.details.chart.tooltip.movAvgEnvelopes.lowerBand'
    }),
    type: 'line',
    color: '#04b0bf',
    yAxis: 0,
    data: getMovAvgEnvelopes(bars, fullBarData, period, bar.Low),
    tooltip: { yDecimals: 2 },
    zIndex: 2
  });

  return result;
}

module.exports = {
  getSeries: getMovAvgEnvelopesSeries
};
