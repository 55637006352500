import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { fetchUserScans, fetchLastScan } from '../../actions/scan';
import ScansLandingContainer from './ScansLandingContainer';
import { setDocumentTitle } from '../../utils/html';

const _ = require('lodash');

class SavedScansLandingContainer extends Component {
  constructor(props) {
    super(props);
    this.loadNewScan = this.loadNewScan.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.state = {
      scanId: '',
      scan: {}
    };
  }

  componentWillMount() {}

  componentDidMount() {
    this.props.actions.getUserScans();
    this.props.actions.getLastScan();
    this.updateScan();
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.id !== prevProps.match.params.id ||
      this.props.scanCount !== prevProps.scanCount
    ) {
      this.updateScan();
    }
  }

  loadNewScan(scanId) {
    let currentState = this.props.history.location.pathname;
    let stateBits = currentState.split('/');

    // Replace previous scan ID with new id
    // > 2 means '/newscans/:id'
    // ["", "newscans", "id_here"]
    if (stateBits.length > 2) {
      stateBits[stateBits.length - 1] = scanId;
    } else {
      // <= 2 means '/newscans'
      // ["", "newscans"]
      stateBits.push(scanId);
    }

    let newState = stateBits.join('/');

    // Replace the existing scan with the new scan
    this.props.history.replace(newState);
  }

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  updateScan() {
    let scanId = this.getScanId();
    let scan = this.getScan(scanId);

    this.setState({
      scanId,
      scan
    });
  }

  getScanId() {
    return this.props.match.params.id;
  }

  getScan(id) {
    let result = {
      scan: null,
      isUserScan: false
    };

    if (id === 'last-scan') {
      result.scan = this.props.lastScan;
      return result;
    }

    let i = _.findIndex(this.props.userScans, {
      id: id
    });

    if (i > -1) {
      // Found in user scans
      result.scan = this.props.userScans[i];
      result.isUserScan = true;
    }

    // Not found in user scans, try static scans
    i = _.findIndex(this.props.staticScans, {
      id: id
    });

    if (i > -1) {
      // Found in static scans
      result.scan = this.props.staticScans[i];
    }

    return result;
  }

  setTitle() {
    setDocumentTitle(this.localize('document.title.scans'));
  }

  render() {
    this.setTitle();
    return (
      <ScansLandingContainer
        key={this.state.scanId}
        scanId={this.state.scanId}
        loadNewScan={this.loadNewScan}
        {...this.state.scan}
      />
    );
  }
}

function mapStateToProps(state) {
  var props = {};

  // This is to force a component update when scans are added or removed.
  // React is not smart enough to catch the difference in an array of objects.
  props.scanCount = state.scans.userScans.length;

  props.userScans = state.scans.userScans || [];
  props.staticScans = state.scans.staticScans || [];
  props.lastScan = state.scans.lastScan || null;
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getUserScans: bindActionCreators(fetchUserScans, dispatch),
      getLastScan: bindActionCreators(fetchLastScan, dispatch)
    }
  };
}

const withIntlSavedScansLandingContainer = injectIntl(SavedScansLandingContainer);

// Exported like this for testing
export {
  withIntlSavedScansLandingContainer as SavedScansLandingContainer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(SavedScansLandingContainer)));
