import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

// A common error message block to be used when
// an error occurs when loading data or otherwise
class Error extends React.PureComponent {
  render() {
    return (
      <div className="is-loading">
        <div className="loading-message">
          <FormattedMessage id={'message.status.error'} />
        </div>
      </div>
    );
  }
}

export default injectIntl(Error);
