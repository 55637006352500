import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { Button, Paper, Stack, ClickAwayListener } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { postFeedback as postFeedbackAction } from '../../actions/feedback';
import { dismissMessage } from '../../actions/user';
import useActions from '../../utils/hooks/useActions';
import { useCurrPage } from '../../utils/hooks/useCurrentPage';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { filterByPage, filterBySizes, filterBySymbols } from '../../utils/messages';

const FEED_BACK_PCT = 2;

const feedbackStyles = makeStyles({
  mount: {
    position: 'fixed',
    zIndex: '999',
    width: '100%',
    bottom: '20px',
    display: 'flex',
    justifyContent: 'center'
  },
  dialog: {
    width: '300px',
    padding: '20px'
  },
  dialogIcon: {
    color: '#5BBB59',
    width: '48px',
    height: '48px'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '22px'
  },
  optionIcon: {
    width: '65px',
    height: '65px'
  },
  optionContainer: {
    paddingTop: '10px'
  },
  closeButtonContainer: {
    textAlign: 'center'
  },
  closeButton: {
    border: '2px solid #dbab00',
    color: '#917100',
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF !important',
    boxShadow: '0 1px 2px 1px rgb(0 0 0 / 30%)'
  },
  unsatisfied: { color: '#7B0000' },
  ok: { color: 'black' },
  satisfied: { color: '#00952B' },
  overlayConfirmation: {
    background: '#000',
    filter: 'alpha(opacity=20)',
    bottom: 0,
    left: 0,
    opacity: 0.2,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 1000000,
  },
  mountConfirmation: {
    position: 'fixed',
    zIndex: '999',
    left: '50%',
    transform: 'translate(-50%, 0)',
    display: 'flex',
    justifyContent: 'center',
    height: '265px',
    width: '320px',
    zIndex: 1000001,
  },
  titleLineConfirmation: {
    width: '100%',
    borderBottom: '6px solid #ffdd3f',
    position: 'fixed',
    marginTop: '60px'
  },
  bodyConfiguration: {
    marginTop: '32px !important'
  },
  closeButtonContainerConfirmation: {
    textAlign: 'center',
    marginTop: '65px !important'
  }
});

export const Feedback = () => {
  // Actions
  const [postFeedback, dismissFeedback] = useActions([
    postFeedbackAction,
    dismissMessage
  ]);

  // State
  const [open, setOpen] = useState(undefined);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const currPage = useCurrPage();
  const { width } = useWindowDimensions();

  const overrideFeedbackPercent = () => {
    const rawValue = new URLSearchParams(window.location.search).get('feedbackPercent');
    if (!rawValue) {
      return undefined;
    }

    const value = parseInt(rawValue, 10);
    return isNaN(value) ? undefined : value;
  };

  const showFeedback = (message) => {
    // In case the message doesn't contain any text, don't show the feedback
    if (!message || !message.data || !message.data.message) {
      return false;
    }

    let queryPercent = overrideFeedbackPercent();
    let messagePercent = FEED_BACK_PCT;
    if (message && message.data && message.data.percent) {
      messagePercent = message.data.percent;
    }

    // Give priority to the query parameter and then to the percent of the feedback
    let displayPercent = queryPercent || messagePercent;
    return Math.random() <= displayPercent / 100;
  }

  const feedbackMessage = useSelector((state) => {
    let user = state.user || {};
    let messages = user.globalMessages || [];

    return messages
      .filter((msg) => msg.typeId === 3)
      .filter((msg) => filterByPage(msg, currPage))
      .filter((msg) => filterBySymbols(msg, currPage))
      .filter((msg) => filterBySizes(msg, width))
      .filter((msg) => !(user.dismissedMessageIds || []).includes(msg.id))[0];
  });

  const onClickConfirmation = () => {
    setOpenConfirmation(false);
  };

  if (openConfirmation) {
    return (<FeedbackConfirmationDialog onClick={onClickConfirmation}/>);
  }

  // Since we need the message to determine if we should display it for the first time,
  // we are checking if `open` is `undefined` here.
  if (feedbackMessage && open === undefined) {
    setOpen(showFeedback(feedbackMessage));
  }

  if (!open || !feedbackMessage) {
    return null;
  }

  const onClick = (value) => {
    if (value !== 'dismiss') {
      setOpenConfirmation(true);
    }

    dismissFeedback(feedbackMessage.id);
    // In case a new feedback message comes after the one that was dismissed without
    // refreshing the page, we would like to use again the same logic to determine
    // if we should display the feedback or not. For that reason, `open` is set to
    // `undefined`.
    setOpen(undefined);
    postFeedback(feedbackMessage.id, value);
  };

  return (
    <FeedbackDialog message={feedbackMessage.data.message} onClick={onClick} />
  );
};

export const FeedbackDialog = ({ message, onClick }) => {
  const classes = feedbackStyles();

  return (
    <div className={classes.mount}>
      <Paper elevation={5} className={classes.dialog}>
        <Stack direction={'column'} spacing={2}>
          <Grid
            container
            columns={2}
            alignItems="center"
            justifyContent="space-evenly"
          >
            <RateReviewIcon className={classes.dialogIcon} />
            <span className={classes.title}>
              <FormattedMessage id={'toast.feedback.title'} />
            </span>
          </Grid>
          <div>{message}</div>
          <div className={classes.optionContainer}>
            <Grid
              container
              spacing={2}
              columns={3}
              justifyContent="center"
              alignItems="center"
            >
              <Button onClick={() => onClick('negative')}>
                <SentimentVeryDissatisfiedIcon
                  className={`${classes.optionIcon} ${classes.unsatisfied}`}
                />
              </Button>
              <Button onClick={() => onClick('neutral')}>
                <SentimentSatisfiedIcon
                  className={`${classes.optionIcon} ${classes.ok}`}
                />
              </Button>
              <Button onClick={() => onClick('positive')}>
                <SentimentVerySatisfiedIcon
                  className={`${classes.optionIcon} ${classes.satisfied}`}
                />
              </Button>
            </Grid>
          </div>
          <div className={classes.closeButtonContainer}>
            <Button
              onClick={() => onClick('dismiss')}
              className={classes.closeButton}
            >
              <FormattedMessage id={'toast.feedback.button'} />
            </Button>
          </div>
        </Stack>
      </Paper>
    </div>
  );
};

export const FeedbackConfirmationDialog = ({ onClick }) => {
  const classes = feedbackStyles();

  // Set the dialog to close in 3 seconds
  setTimeout(() => onClick(), 3000);

  return (
    <>
      <div className={classes.overlayConfirmation} />
      <ClickAwayListener onClickAway={() => onClick()}>
        <div className={classes.mountConfirmation}>
          <div className={classes.titleLineConfirmation}/>
          <Paper elevation={5} className={classes.dialog}>
            <Stack direction={'column'} spacing={2}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-evenly"
              >
                <span className={classes.title}>
                  <FormattedMessage id={'toast.feedback.confirmation.title'} />
                </span>
              </Grid>
              <div className={classes.bodyConfiguration}>
                <FormattedMessage id={'toast.feedback.confirmation.body'} />
              </div>
              <div className={classes.closeButtonContainerConfirmation}>
                
                  <Button
                    onClick={() => onClick()}
                    className={classes.closeButton}
                  >
                    <FormattedMessage id={'toast.feedback.confirmation.button'} />
                  </Button>
              </div>
            </Stack>
          </Paper>
        </div>
      </ClickAwayListener>
    </>
  );
};