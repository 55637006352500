import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import useActions from '../../utils/hooks/useActions';
import { useSelector } from 'react-redux';
import { fetchAllBarsIfNeeded } from '../../actions/bars';
import { fetchSymbolIfNeeded } from '../../actions/symbol';
import { start, stop } from '../../actions/stream';
import { makeStyles } from '@mui/styles';

import ErrorBoundary from '../common/ErrorBoundary';
import { tooltipFormatter } from '../../utils/chartTooltip';
import { mapSyphonBarsToHighchartBars } from '../../utils/chart';

import {
  HighchartsStockChart,
  Chart,
  HighchartsProvider,
  XAxis,
  YAxis,
  CandlestickSeries,
  Navigator,
  RangeSelector,
  Tooltip,
  Debug,
  Series
} from 'react-jsx-highstock';

import Highcharts from 'highcharts/highstock';
import indicatorsAll from 'highcharts/indicators/indicators-all';
import indicators from 'highcharts/indicators/indicators';
import pivotPoints from 'highcharts/indicators/pivot-points';
import macd from 'highcharts/indicators/macd';
import ema from 'highcharts/indicators/ema';
import ichimoku from '../common/customIndicators/ichimoku';
import avgDeviation from '../common/customIndicators/avgDeviation';
import bollingerBands from 'highcharts/indicators/bollinger-bands';
import volumeByPrice from 'highcharts/indicators/volume-by-price';
import NoData from '../common/NoData';
import RealtimeChartIndicator from './RealtimeChartIndicator';
import { useInterval } from 'usehooks-ts';
import RealtimeMainChartIndicator from './RealtimeMainChartIndicator';
import RealtimeVolumeChart from './RealtimeVolumeChart';

// import 'highcharts/css/stocktools/gui.scss';
// import 'highcharts/css/annotations/popup.scss';

indicatorsAll(Highcharts);
indicators(Highcharts);
pivotPoints(Highcharts);
macd(Highcharts);
ema(Highcharts);
ichimoku(Highcharts);
avgDeviation(Highcharts);
bollingerBands(Highcharts);
volumeByPrice(Highcharts);

// This is used to copy the start and end settings (extremes) from the
// primary candlestick chart out to the other charts.  We use the provided
// `onAfterSetExtremes` so that the extremes will have propagated to the
// original axis and we can check for them (to avoid infinite recursion)
const handleOnAfterSetExtremes = (event) => {
  Highcharts.charts.forEach((chart) => {
    if (!chart) {
      return;
    }

    let axis = chart.xAxis[0];
    if (!axis) {
      return;
    }

    let extremes = axis.getExtremes();

    if (extremes.max !== event.max || extremes.min !== event.min) {
      // It looks better and smoother if we don't animate it
      axis.setExtremes(event.min, event.max, true, false);
    }
  });
};

// This is called from the "onMouseMove" and "onTouchMove"
// handlers of the container div, we fan this out to each
// chart so that it can highlight the correct bar and show
// the correct tooltip if applicable.
const handleMouseMove = (event) => {
  Highcharts.charts.forEach((chart) => {
    if (!chart) {
      return;
    }

    let normalizedEvent = chart.pointer.normalize(event);
    let point = chart.series[0]?.searchPoint(normalizedEvent, true);

    if (point && point.onMouseOver) {
      point.onMouseOver(normalizedEvent);
    }
  });
};

// 5-min syphon bars is loading if
//   bars is not yet defined
//   OR bars for symbol/size is not yet available
//   OR bars is still 'loading' for that symbol/size
const AreFiveMinuteBarsLoaded = (barSize, barResponse) => {
  if (barSize !== '5min') {
    // we don't care about other bar size
    return true;
  }
  if (!barResponse) {
    // we haven't received bar response from Syphon
    return false;
  }
  const result = barResponse.isLoading;
  return !result;
};

const isBarsLoading = (bars) => {
  return !bars || !!bars.isLoading;
};

const hasLoadedWithoutBarsData = (bars) => {
  if (isBarsLoading(bars)) {
    return false;
  }

  return !bars.data;
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& .highcharts-tooltip table th': {
      'padding-bottom': '10px',
      'border-bottom': '1px solid silver'
    }
  }
});

const SymbolChartRealTime = ({ symbol, barSize, indicators, additionalSymbolsData }) => {
  // Hooks
  const classes = useStyles();

  // Actions
  const [getBars] = useActions([fetchAllBarsIfNeeded], []);
  const [getSymbolMetadata] = useActions([fetchSymbolIfNeeded], []);
  const [startStream] = useActions([start], []);
  const [stopStream] = useActions([stop], []);

  // Effects
  useEffect(() => {
    // Load bars if needed
    getBars(symbol);

    // Load symbol metadata if needed
    getSymbolMetadata(symbol);
  }, [symbol, getBars, getSymbolMetadata]);

  useEffect(() => {
    // Only stream the intraday data if we're going to use it to update
    // the chart in real time, aka only if the barSize is `5min`

    // TODO do this only after we got bars from syphon
    // If not, chart is re-rendered every time new stream
    // message comes in and makes chart initialization slow/sluggish
    if (barSize === '5min') {
      startStream(`${symbol}/intraday`);
    }

    return () => {
      if (barSize === '5min') {
        stopStream(`${symbol}/intraday`);
      }
    };
  }, [symbol, barSize, startStream, stopStream]);

  // Selector
  const barResponse = useSelector(
    (state) => (state.bars || {})[`${symbol}/${barSize}`] || {}
  );

  // Intl
  const intl = useIntl();
  const localize = useCallback(
    (id, otherParams) => intl.formatMessage({ id: id }, otherParams),
    [intl]
  );

  // Set Chart Options
  Highcharts.setOptions({
    lang: {
      // Set Range Selector Zoom label
      rangeSelectorZoom: localize('symbol.details.chart.range.selector.zoom')
    }
  });

  const symbolMetadata = useSelector(
    (state) => ((state.symbols || {}).data || {})[symbol] || {}
  );

  const bars = (barResponse.data || {}).bars || [];

  // We can't use hooks (such as `useSelector`) in a conditional branch,
  // so we do it outside of the below if statement.
  const intradayBars = useSelector(
    (state) => ((state.stream || {}).channels || {})[`${symbol}/intraday`] || {}
  );

  // This logic is for updating the bars in real time.  We only do this when the
  // bar size is `5min`.
  // Start realtime intraday bars only after we have received 5-min bars from syphon
  if (barSize === '5min' && AreFiveMinuteBarsLoaded(barSize, barResponse)) {
    // The bars in the state are in an object keyed by timestamp, this gets them
    // into an array.
    const intradayBarArray = Object.values(intradayBars).filter((bar) => typeof bar === 'object');

    // Next, we reformat the bars from Faucet into the shape from Syphon.
    // This way all the bars will be in the same shape and can be formatted
    // alike later on.  Note that we are only mapping the values we're using -
    // OHLCV and timestamp.
    const mappedIntradayBars = intradayBarArray.map((bar) => {
      return {
        Timestamp: bar.timestamp,
        ClosePrice: bar.close,
        HighPrice: bar.high,
        LowPrice: bar.low,
        OpenPrice: bar.open,
        Volume: bar.volume?.total
      };
    });

    // Sort the new bars from Faucet just in case
    const sortedIntradayBars = mappedIntradayBars.sort((a, b) => {
      return new Date(a.Timestamp) - new Date(b.Timestamp);
    });

    for (let i = 0; i < sortedIntradayBars.length; i++) {
      let intradayBar = sortedIntradayBars[i];

      let index = bars.findIndex(
        (bar) => bar.Timestamp === intradayBar.Timestamp
      );

      // If we found a bar from Syphon with the same timestamp
      // as a bar from Faucet, replace it in-place.  Otherwise,
      // this is a new bar and we append it to the end of the array.
      if (index > -1) {
        bars[index] = intradayBar;
      } else {
        bars.push(intradayBar);
      }
    }
  }

  // mapSyphonBarsToHighchartBars also handles timestamp conversion.
  // Highchart already has {time} property but since we use tooltipFormatter,
  // that formatter expects timestamp to be in the local timezone
  const result = mapSyphonBarsToHighchartBars(bars, barSize);
  const mappedBars = result.bars;
  const mappedVolumes = result.volume;

  // Eventually we should swap these colors based on if the user has selected
  // monochrome candlestick mode, where the candles are black and white.
  const candleStickColor = '#0055cc';
  const candleStickUpColor = '#ff2800';

  // Generate a state change every 1 sec
  // When it changes, this will trigger update on the Indicators
  const [updateToggle, setUpdateToggle] = useState(false);
  useInterval(() => {
    setUpdateToggle(!updateToggle);
  }, 1000);

  // Memoized Indicators
  // Only re-renders the indicators when any of the following has updates
  // updateToggle (every 1sec)
  // barSize (user-driven)
  // symbol (user-driven)
  // intradayBars (when we get intraday from faucet)
  const throttledIndicators = useMemo(
    () => (
      <>
        {indicators.map((setting) => (
          <RealtimeChartIndicator
            key={setting.type}
            symbol={symbol}
            barSize={barSize}
            bars={mappedBars}
            volumeBars={mappedVolumes}
            additionalSymbolsData={additionalSymbolsData}
            type={setting.type}
            setting={setting}
          />
        ))}
      </>
    ),
    [barSize, symbol, indicators, mappedBars, mappedVolumes, additionalSymbolsData]
  );

  // These are the indicators that will be rendered
  // as part of the Main Chart
  const throttledMainChartIndicators = useMemo(
    () => (
      <>
        {indicators.map((setting) => (
          <RealtimeMainChartIndicator
            key={setting.type}
            symbol={symbol}
            barSize={barSize}
            bars={mappedBars}
            volumeBars={mappedVolumes}
            type={setting.type}
            setting={setting}
          />
        ))}
      </>
    ),
    [barSize, symbol, indicators, mappedBars, mappedVolumes]
  );

  const rangeSelectors = useMemo(
    () => [
      {
        size: '5min',
        count: 5,
        type: 'day',
        label: localize('symbol.details.chart.buttons.5day')
      },
      {
        size: '1hour',
        count: 1,
        type: 'month',
        label: localize('symbol.details.chart.buttons.1month')
      },
      {
        size: '1day',
        count: 6,
        type: 'month',
        label: localize('symbol.details.chart.buttons.6month')
      },
      {
        size: '1week',
        count: 1,
        type: 'year',
        label: localize('symbol.details.chart.buttons.1year')
      },
      {
        size: '1month',
        count: 10,
        type: 'year',
        label: localize('symbol.details.chart.buttons.10year')
      },
      {
        size: '1quarter',
        type: 'all',
        label: localize('symbol.details.chart.buttons.all')
      }
    ],
    [localize]
  );

  const getDefaultZoomIndex = useCallback(
    (bars) => {
      if (!isBarsLoading(bars)) {
        const index = rangeSelectors.findIndex((x) => x.size === barSize);
        return index > -1 ? index : 2;
      }
      return null;
    },
    [rangeSelectors, barSize]
  );

  const [zoomIndex, setZoomIndex] = useState(null);

  // Effects
  // Update zoom index only after we receive bars, as setting
  // zoom when there is no bar will just fall back to "all".
  useEffect(() => {
    setZoomIndex(getDefaultZoomIndex(barResponse));
  }, [barResponse, barSize, getDefaultZoomIndex]);

  const buildChart = useMemo(
    () => (
      <HighchartsProvider Highcharts={Highcharts}>
        {/* Primary Chart (Candlestick/) */}
        <HighchartsStockChart key={`${symbol}/${barSize}/primary`}>
          {/* Passing in the debug component will instruct the wrapper
          to place the highcharts object on `window.chart` for easy debugging. 
          https://github.com/whawker/react-jsx-highcharts/wiki/Debugging */}
          {process.env.NODE_ENV === 'development' && <Debug varName="realTimeChart"/>}
          <Chart animation={false} />
          <Tooltip
            useHTML={true}
            formatter={tooltipFormatter}
            outside={true}
            split={false}
            variables={{
              currentBarSize: barSize,
              realtime: true
            }}
            externalFunctions={{
              localize: localize
            }}
          />

          <XAxis onAfterSetExtremes={handleOnAfterSetExtremes}></XAxis>

          <YAxis>
            <CandlestickSeries
              id='bars'
              name={(symbolMetadata.data || {}).jpShortName}
              keys={['x', 'open', 'high', 'low', 'close']}
              data={mappedBars.map((bar) => {
                return {
                  x: bar[0],
                  open: bar[1],
                  high: bar[2],
                  low: bar[3],
                  close: bar[4],
                  y: bar[4]
                };
              })}
              color={candleStickColor}
              upColor={candleStickUpColor}
              showInNavigator={true}
              zIndex={100}
            />
            {/* In order to display the "Volume By Price" indicator, it's
            necessary to define the Volume series in the same chart. For that
            reason, the volume series was added here, but it's hidden in
            the browser. */}
            <Series
              id='volume'
              data={mappedVolumes.map((bar) => {
                return [bar[0], bar[1]];
              })}
              showInNavigator={false}
              visible={false}
            />

            {throttledMainChartIndicators}
          </YAxis>

          {/* Buttons and Date Entry */}
          <RangeSelector
            dropdown={'responsive'}
            verticalAlign={'top'}
            buttonPosition={{
              align: 'right',
              x: 0,
              y: 0
            }}
            selected={zoomIndex}
          >
            {rangeSelectors.map((x) => {
              return (
                <RangeSelector.Button
                  count={x.count}
                  type={x.type}
                  key={x.size}
                >
                  {x.label}
                </RangeSelector.Button>
              );
            })}
          </RangeSelector>

          {/* Navigation Scrollbar */}
          <Navigator>
            <Navigator.Series seriesId={'bars'} />
          </Navigator>
        </HighchartsStockChart>

        {/* Each vertically stacked axis is rendered as a different chart.
  They are all linked together by fanning out the setExtremes event.
  In this way, we don't need to fuss with Highchart's obscene method
  for setting the height of multiple axes (a percentage of the total
  height, which itself is somewhat referential to the chart width). */}
        {/* Volume Chart */}
        <RealtimeVolumeChart
          symbol={symbol}
          barSize={barSize}
          onAfterSetExtremes={handleOnAfterSetExtremes}
          mappedBars={mappedBars}
          mappedVolumes={mappedVolumes}
          indicators={indicators}
        />
        {throttledIndicators}
      </HighchartsProvider>
    ),
    [
      barSize,
      indicators,
      localize,
      mappedBars,
      mappedVolumes,
      rangeSelectors,
      symbol,
      symbolMetadata.data,
      throttledIndicators,
      throttledMainChartIndicators,
      zoomIndex
    ]
  );

  // Show NoData if there are no bars for the given symbol
  const chartToDisplay = hasLoadedWithoutBarsData(barResponse) ? (
    <NoData />
  ) : (
    buildChart
  );

  return (
    <div
      className={classes.root}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
    >
      <ErrorBoundary>{chartToDisplay}</ErrorBoundary>
    </div>
  );
};

export default SymbolChartRealTime;
