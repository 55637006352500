import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchSymbolIfNeeded } from '../../actions/symbols';
import Number from '../common/Number';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

class IndustryRankingRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.actions.getSymbol(this.props.symbol);
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const styles = {
      iconStyle: {
        color: '#BCBCBC',
        float: 'right',
        marginRight: '0',
        fontSize: '9px',
        fontWeight: 'bold'
      }
    };
    let symbolName = this.props.symbolData.name || '-';
    return (
      <Link to={'/hotlists/?sector=' + this.props.sectorId}>
        <div className={'industry-ranking-row'}>
          <div className={'industry-ranking-row-data'}>
            <span
              className="industry-ranking_value__rank"
              value-rank={this.props.rank}
            >
              {this.props.rank}
            </span>
            <span className="industry-ranking_value__symbol-name">
              {symbolName}
            </span>
            <span className="industry-ranking_value__value industry-ranking_value__value-primary">
              <Number
                className={'industry-ranking__net-change-value'}
                value={this.props.netChange * 100}
                hideIfZero={true}
                precision="2"
                suffix="%"
                signed={true}
              />
            </span>
          </div>
          <div className={'industry-ranking__arrow'}>
            <ArrowForwardIosIcon style={styles.iconStyle} aria-label="view" />
          </div>
        </div>
      </Link>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.user) {
    return {};
  }
  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };
  props.symbolData =
    (state.symbols.data[ownProps.symbol] || { data: {} }).data || {};

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getSymbol: bindActionCreators(fetchSymbolIfNeeded, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndustryRankingRow);
