import React from 'react';
import { Link } from 'react-router-dom';
import myScanIcon from '../../icons/Icon-my-scan.svg';

const MyScansButton = (props) => {
  return (
    <div
      className="actions"
      style={{
        textAlign: 'left',
        float: 'right'
      }}
    >
      <Link
        to={{
          pathname: `/scan`
        }}
        className={'my-scans-button'}
      >
        <div
          className="margin button btn-cmn scan result"
          style={{
            marginRight: '0px'
          }}
        >
          <img src={myScanIcon} className="my-scan-icon" alt="" />
          {props.buttonText}
        </div>
      </Link>
    </div>
  );
};

export default MyScansButton;
