import React from 'react';
import { injectIntl } from 'react-intl';

export class QuoteCardHighFlag extends React.PureComponent {
  render() {
    // It's possible for a symbol to both reach its maximum (or minimum) price
    // and record a new 52-week high (or low) on the same day. Following the
    // behavior of the desktop site, we display only the stop high/low flag in
    // this case.
    if (
      this.props.high > 0 &&
      this.props.max > 0 &&
      this.props.high >= this.props.max
    ) {
      return (
        <span className="quote-card-flag high">
          {this.props.intl.formatMessage({
            id: 'marketboard.row.flag.stophigh'
          })}
        </span>
      );
    }
    if (
      this.props.high > 0 &&
      this.props.record > 0 &&
      this.props.high > this.props.record
    ) {
      return (
        <span className="quote-card-flag high">
          {this.props.intl.formatMessage({
            id: 'marketboard.row.flag.newhigh'
          })}
        </span>
      );
    }
    return null;
  }
}
QuoteCardHighFlag = injectIntl(QuoteCardHighFlag);

export class QuoteCardLowFlag extends React.PureComponent {
  render() {
    // See notes on QuoteCardHighFlag.render()
    if (
      this.props.low > 0 &&
      this.props.min > 0 &&
      this.props.low <= this.props.min
    ) {
      return (
        <span className="quote-card-flag low">
          {this.props.intl.formatMessage({
            id: 'marketboard.row.flag.stoplow'
          })}
        </span>
      );
    }
    if (
      this.props.low > 0 &&
      this.props.record > 0 &&
      this.props.low < this.props.record
    ) {
      return (
        <span className="quote-card-flag low">
          {this.props.intl.formatMessage({
            id: 'marketboard.row.flag.newlow'
          })}
        </span>
      );
    }
    return null;
  }
}
QuoteCardLowFlag = injectIntl(QuoteCardLowFlag);
