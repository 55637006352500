import React from 'react';
import withStyles from '@mui/styles/withStyles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { yellow } from '@mui/material/colors';

const MonexSwitch = withStyles({
  switchBase: {
    // color: purple[300],
    '&$checked': {
      color: '#ffbb00'
    },
    '&$checked + $track': {
      backgroundColor: '#ffcc00',
      opacity: 0.4
    }
  },
  checked: {},
  track: {}
})(Switch);

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

class SingleToggle extends React.Component {
  constructor(props) {
    super(props);
    this.toggleFavorite = this.toggleFavorite.bind(this);
  }

  toggleFavorite() {
    this.props.toggleFavorite(this.props.type);
  }

  render() {
    let SwitchComponent = MonexSwitch;

    if (this.props.iosButton) {
      SwitchComponent = IOSSwitch;
    }

    const shouldDisplayFavorite =
      typeof this.props.toggleFavorite === 'function';
    const nonFavoriteIcon =
      shouldDisplayFavorite && !this.props.isFavorite ? (
        <StarOutlineRoundedIcon
          onClick={this.toggleFavorite}
          style={{
            float: 'left',
            paddingTop: 14,
            color: yellow['A700']
          }}
        ></StarOutlineRoundedIcon>
      ) : null;

    const favoriteIcon =
      shouldDisplayFavorite && this.props.isFavorite ? (
        <StarRoundedIcon
          onClick={this.toggleFavorite}
          style={{
            float: 'left',
            paddingTop: 14,
            color: yellow['A700']
          }}
        ></StarRoundedIcon>
      ) : null;

    let internals = (
      <div>
        {nonFavoriteIcon}
        {favoriteIcon}
        <span
          style={{
            display: 'inline-block',
            marginBottom: 10,
            paddingLeft: 5,
            paddingTop: 15
          }}
        >
          {this.props.title}
        </span>
        <div
          style={{
            display: 'inline-block',
            float: 'right',
            paddingRight: '0px',
            marginRight: -10,
            marginTop: 5
          }}
        >
          <FormGroup row>
            <FormControlLabel
              // Label is a required parameter in MUI v5,
              // but we don't want to display a label.
              label={''}
              control={
                <SwitchComponent
                  disableRipple
                  checked={this.props.checked}
                  onChange={this.props.onChange}
                />
              }
            />
          </FormGroup>
        </div>
      </div>
    );

    return this.props.skipList ? (
      internals
    ) : (
      <li className="settings-card">{internals}</li>
    );
  }
}
export default SingleToggle;
