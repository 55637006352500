import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { fetchHotlistIfNeeded } from '../../actions/hotlist';
import Hotlist from './Hotlist';
import SettingsDialog from '../common/SettingsDialog';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import withTracker from '../../utils/analytics';
import RefineIcon from '../../icons/Icon-Refine.svg';
import checkIcon from '../../icons/icon-check.svg';
import { withDesktopWidth } from '../../utils/hocs/withDesktopWidth';
import { DesktopHelpBar } from '../common/DesktopHelpbar';
import { setDocumentTitle } from '../../utils/html';

const defaultCategory = 'gainers';
const defaultHotlist = 'raterise';
const defaultExchange = 'ALL';
const defaultIndustry = 'ALL';
const regionalExchanges = 'Regionals';
const notAvailableIndustry = 'NotAvailable';

const hotlistSettingsMapper = [
  {
    category: 'gainers',
    subCategory: [
      'raterise',
      'bidaskprevcloseonedaygainers',
      'bidaskopengainers',
      'percentchangefivedaygainers',
      'percentchangethirtydaygainers',
      'percentvwapdeviationratehighest'
    ]
  },
  {
    category: 'losers',
    subCategory: [
      'ratefall',
      'bidaskprevcloseonedaylosers',
      'bidaskopenlosers',
      'percentchangefivedaylosers',
      'percentchangethirtydaylosers',
      'percentvwapdeviationratelowest'
    ]
  },
  {
    category: 'newpricestops',
    subCategory: ['new52weekhigh', 'new52weeklow', 'stophigh', 'stoplow']
  },
  {
    category: 'volume',
    subCategory: ['volume', 'turnover', 'volumesurge']
  },
  {
    category: 'marginbalance',
    subCategory: [
      'riseinsharessold',
      'fallinsharessold',
      'riseinsharesbought',
      'fallinsharesbought'
    ]
  }
];

const exchangeMapper = [
  'ALL',
  'TSE',
  'TSE_P',
  'TSE_S',
  'TSE_G',
  'NSE',
  'FSE',
  'SSE',
  'Regionals'
];

const exchangesToCombine = ['NSE', 'FSE', 'SSE'];

const industryMapper = [
  'ALL',
  '0050',
  '1050',
  '2050',
  '3050',
  '3100',
  '3150',
  '3200',
  '3250',
  '3300',
  '3350',
  '3400',
  '3450',
  '3500',
  '3550',
  '3600',
  '3650',
  '3700',
  '3750',
  '3800',
  '4050',
  '5050',
  '5100',
  '5150',
  '5200',
  '5250',
  '6050',
  '6100',
  '7050',
  '7100',
  '7150',
  '7200',
  '8050',
  '9050',
  'NotAvailable'
];

class MobileHotlistContainer extends Component {
  constructor(props) {
    super(props);

    let searchParams = new URLSearchParams(props.location.search);

    this.state = {
      open: false,
      wrap: true,
      currHotlist: defaultCategory, //e.g. 'gainers'
      currHotlistCategory: defaultHotlist, //e.g. 'raterise'
      currentExchange: searchParams.get('exchange') || defaultExchange,
      currentIndustry: searchParams.get('sector') || defaultIndustry,
      hideRankingCategory: false,
      hideRankingIndustry: false,
      hideRankingExchange: false,
      canSelectIndustires: true
    };

    this.setOpen = this.setOpen.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleToggleCategory = this.handleToggleCategory.bind(this);
    this.handleCategorySelect = this.handleCategorySelect.bind(this);
    this.handleToggleExchange = this.handleToggleExchange.bind(this);
    this.handleExchangeSelect = this.handleExchangeSelect.bind(this);
    this.handleToggleIndustry = this.handleToggleIndustry.bind(this);
    this.handleIndustrySelect = this.handleIndustrySelect.bind(this);
    this.buildIndustryListItem = this.buildIndustryListItem.bind(this);
    this.buildExchangeListItem = this.buildExchangeListItem.bind(this);
    this.buildExchangeCategory = this.buildExchangeCategory.bind(this);
    this.buildExchangeSubcategory = this.buildExchangeSubcategory.bind(this);
    this.setTitle = this.setTitle.bind(this);
  }

  componentWillMount() {}

  componentDidMount() {
    let category = this.getCurrentHotlistCategory();
    let exchange = this.getCurrentHotlistExchange();
    let sector = this.getCurrentHotlistSector();

    this.props.actions.getHotlist(category, exchange, sector);
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  getCurrentHotlistCategory() {
    return this.state.currHotlistCategory;
  }

  getCurrentHotlistExchange() {
    return this.state.currentExchange === defaultExchange
      ? undefined
      : this.state.currentExchange;
  }

  getCurrentHotlistSector() {
    return this.state.currentIndustry === defaultIndustry ||
      this.state.currentIndustry === notAvailableIndustry
      ? undefined
      : this.state.currentIndustry;
  }

  currentHotlist() {
    let category = this.getCurrentHotlistCategory();
    let exchange = this.getCurrentHotlistExchange();
    let sector = this.getCurrentHotlistSector();

    let hotlistKey =
      category +
      (!!exchange ? '/' + exchange : '') +
      (!!sector ? '/' + sector : '');

    return (this.props.hotlist[hotlistKey] || {}).data;
  }

  setOpen(openness) {
    this.setState({ open: openness });
  }

  handleClickOpen() {
    this.setOpen(true);
  }

  handleClose() {
    let category = this.getCurrentHotlistCategory();
    let exchange = this.getCurrentHotlistExchange();
    let sector = this.getCurrentHotlistSector();

    this.props.actions.getHotlist(category, exchange, sector);
    this.setOpen(false);
  }

  hasExchangeAndIndustryChoice(category, subCategory) {
    return (
      category === 'newpricestops' ||
      category === 'volume' ||
      category === 'marginbalance' ||
      (category === 'losers' && subCategory === 'ratefall') ||
      (category === 'gainers' && subCategory === 'raterise')
    );
  }

  selectDefaults(industriesAvailable) {
    if (industriesAvailable) {
      this.setState({
        canSelectIndustires: true,
        currentExchange: defaultExchange,
        currentIndustry: defaultIndustry
      });
    } else {
      this.setState({
        canSelectIndustires: false,
        currentExchange: defaultExchange,
        currentIndustry: notAvailableIndustry
      });
    }
  }

  handleCategorySelect(category, subCategory) {
    this.setState({
      currHotlist: category,
      currHotlistCategory: subCategory
    });

    let areAvailable = this.hasExchangeAndIndustryChoice(category, subCategory);
    this.selectDefaults(areAvailable);

    this.handleToggleCategory();
  }

  handleIndustrySelect(industry) {
    this.setState({ currentIndustry: industry });
    this.handleToggleIndustry();
  }

  handleExchangeSelect(exchange) {
    this.setState({ currentExchange: exchange });
    this.handleToggleExchange();
  }

  handleToggleCategory() {
    this.setState({ hideRankingCategory: !this.state.hideRankingCategory });
  }

  handleToggleIndustry() {
    this.setState({ hideRankingIndustry: !this.state.hideRankingIndustry });
  }

  handleToggleExchange() {
    this.setState({ hideRankingExchange: !this.state.hideRankingExchange });
  }

  buildExchangeListItem(exchange) {
    if (
      this.hasExchangeAndIndustryChoice(
        this.state.currHotlist,
        this.state.currHotlistCategory
      )
    ) {
      if (exchange === regionalExchanges) {
        return;
      }
    } else {
      if (exchangesToCombine.includes(exchange)) {
        return;
      }
    }

    let isSelected = this.state.currentExchange === exchange;
    let isLast = exchangeMapper[exchangeMapper.length - 1] === exchange;
    return (
      <ListItem button style={{ padding: '0px' }}>
        <div
          className={`hotlist_settings_item--container${
            isSelected ? ' selected' : ''
          }`}
        >
          <div
            className={`hotlist_settings_item${isLast ? ' last' : ''}`}
            onClick={() => this.handleExchangeSelect(exchange)}
          >
            {isSelected && <img src={checkIcon} alt="" />}
            <span className={`hotlist_settings_item--name`}>
              {this.localize(`scan.criteria.Exchange.${exchange}`)}
            </span>
          </div>
        </div>
      </ListItem>
    );
  }

  buildIndustryListItem(industry) {
    if (
      this.hasExchangeAndIndustryChoice(
        this.state.currHotlist,
        this.state.currHotlistCategory
      )
    ) {
      if (industry === notAvailableIndustry) {
        return;
      }
    } else {
      if (industry !== notAvailableIndustry) {
        return;
      }
    }

    let isSelected = this.state.currentIndustry === industry;
    let isLast = industryMapper[industryMapper.length - 1] === industry;
    return (
      <ListItem button style={{ padding: '0px' }}>
        <div
          className={`hotlist_settings_item--container${
            isSelected ? ' selected' : ''
          }`}
        >
          <div
            className={`hotlist_settings_item${isLast ? ' last' : ''}`}
            onClick={() => this.handleIndustrySelect(industry)}
          >
            {isSelected && <img src={checkIcon} alt="" />}
            <span className={`hotlist_settings_item--name`}>
              {this.localize(`scan.criteria.Sector.${industry}`)}
            </span>
          </div>
        </div>
      </ListItem>
    );
  }

  buildExchangeCategory(hotlistObj) {
    return (
      <div>
        <div className={'hotlist_settings_item--category'}>
          <span className="hotlist_settings_item--name">
            {this.localize(`hotlist.category.${hotlistObj.category}`)}
          </span>
        </div>
        {hotlistObj.subCategory.map((subcategory) =>
          this.buildExchangeSubcategory(hotlistObj, subcategory)
        )}
      </div>
    );
  }

  buildExchangeSubcategory(hotlistObj, subcategory) {
    let isSelected = this.state.currHotlistCategory === subcategory;
    let isLast =
      hotlistObj.subCategory[hotlistObj.subCategory.length - 1] === subcategory;
    return (
      <ListItem button style={{ padding: '0px' }}>
        <div
          className={`hotlist_settings_item--container${
            isSelected ? ' selected' : ''
          }`}
        >
          <div
            className={`hotlist_settings_item${isLast ? ' last' : ''}`}
            onClick={() =>
              this.handleCategorySelect(
                hotlistObj.category.toString(),
                subcategory.toString()
              )
            }
          >
            {isSelected && <img src={checkIcon} alt="" />}
            <span className={`hotlist_settings_item--name`}>
              {this.localize(`hotlist.sub.category.${subcategory}`)}
            </span>
          </div>
        </div>
      </ListItem>
    );
  }

  setTitle() {
    setDocumentTitle(this.localize('document.title.hotlists'));
  }

  render() {
    this.setTitle();

    const rightButton = (
      <IconButton onClick={this.handleClickOpen} size="large">
        <Link to="#">
          <img src={RefineIcon} className={'refine-icon'} alt="" />
        </Link>
      </IconButton>
    );

    let hotlistDisplay;

    if (this.currentHotlist()) {
      hotlistDisplay = <Hotlist hotlist={this.currentHotlist()} />;
    } else {
      hotlistDisplay = (
        <div className={'no-content empty'}>
          {this.localize('message.status.loading')}
        </div>
      );
    }

    return (
      <>
      {this.props.isDesktopWidth && (
        <div className="section-desktop-help-bar">
          <DesktopHelpBar
            route={'hotlists'}
          />
        </div>
      )}

      <div className="hotlist-container">
        <div className="section-header">{this.localize('hotlist.title')}</div>
        <div className="hotlist_header__container">
          <div className="hotlist_header__inner_container">
            <div className="hotlist_header__text_container">
              {this.localize('hotlist.category') +
                this.localize(
                  `hotlist.sub.category.${this.state.currHotlistCategory}`
                )}
              <div className="hotlist_header__subtext_container">
                <span className="hotlist_header__main_filter">{`${this.localize(
                  'scan.criteria.Exchange'
                )}：${this.localize(
                  `scan.criteria.Exchange.${this.state.currentExchange}`
                )}`}</span>
                <span className="hotlist_header__secondary_filter">{`${this.localize(
                  'scan.criteria.Sector'
                )}：${this.localize(
                  `scan.criteria.Sector.${this.state.currentIndustry}`
                )}`}</span>
              </div>
            </div>
            <div className="hotlist_header__filter_button">{rightButton}</div>
          </div>
        </div>
        {hotlistDisplay}
        <SettingsDialog
          isSmall={this.props.isDesktopWidth}
          handleClose={this.handleClose}
          open={this.state.open}
          title={this.localize('hotlist.settings.title')}
        >
          {this.buildHeaderListItem(
            this.localize('hotlist.settings.ranking.category'),
            this.localize(`hotlist.category.${this.state.currHotlist}`) +
              ' : ' +
              this.localize(
                `hotlist.sub.category.${this.state.currHotlistCategory}`
              ),
            this.handleToggleCategory,
            { marginTop: 38 }
          )}
          {this.state.hideRankingCategory && (
            <div>{hotlistSettingsMapper.map(this.buildExchangeCategory)}</div>
          )}
          {this.buildHeaderListItem(
            this.localize('scan.criteria.Exchange'),
            this.localize(
              `scan.criteria.Exchange.${this.state.currentExchange}`
            ),
            this.handleToggleExchange
          )}
          {this.state.hideRankingExchange && (
            <div>{exchangeMapper.map(this.buildExchangeListItem)}</div>
          )}
          {this.buildHeaderListItem(
            this.localize('scan.criteria.Sector'),
            this.localize(`scan.criteria.Sector.${this.state.currentIndustry}`),
            this.handleToggleIndustry
          )}
          {this.state.hideRankingIndustry && this.state.canSelectIndustires && (
            <div>{industryMapper.map(this.buildIndustryListItem)}</div>
          )}
        </SettingsDialog>
      </div>
      </>
    );
  }

  buildHeaderListItem(mainLabel, subLabel, onClickHandler, customStyle) {
    let style = customStyle || {};
    style.padding = 0;
    return (
      <ListItem button style={style}>
        <div className={'hotlist_settings_category'} onClick={onClickHandler}>
          <div className="hotlist_settings__row one">
            <span className="hotlist_settings_category--name">{mainLabel}</span>
          </div>
          <div className="hotlist_settings__row two">
            <span className="hotlist_settings_category--selected">
              {subLabel}
            </span>
          </div>
        </div>
      </ListItem>
    );
  }
}
MobileHotlistContainer = injectIntl(MobileHotlistContainer);

function mapStateToProps(state, ownProps) {
  if (!state.user) {
    return {};
  }

  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };

  // Grab user
  props.user = state.user;
  props.hotlist = state.hotlists;

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getHotlist: bindActionCreators(fetchHotlistIfNeeded, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTracker(withDesktopWidth(MobileHotlistContainer)));
