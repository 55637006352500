import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { initWebSocket, start, stop } from '../../actions/stream';
import { fetchSymbolIfNeeded } from '../../actions/symbols';
import IndustryRankingRow from './IndustryRankingRow';

let industryMap = [
  { name: 'JP:$0040-TS', code: '0050', netChange: 0 },
  { name: 'JP:$0041-TS', code: '1050', netChange: 0 },
  { name: 'JP:$0042-TS', code: '2050', netChange: 0 },
  { name: 'JP:$0043-TS', code: '3050', netChange: 0 },
  { name: 'JP:$0044-TS', code: '3100', netChange: 0 },
  { name: 'JP:$0045-TS', code: '3150', netChange: 0 },
  { name: 'JP:$0046-TS', code: '3200', netChange: 0 },
  { name: 'JP:$0047-TS', code: '3250', netChange: 0 },
  { name: 'JP:$0048-TS', code: '3300', netChange: 0 },
  { name: 'JP:$0049-TS', code: '3350', netChange: 0 },
  { name: 'JP:$004A-TS', code: '3400', netChange: 0 },
  { name: 'JP:$004B-TS', code: '3450', netChange: 0 },
  { name: 'JP:$004C-TS', code: '3500', netChange: 0 },
  { name: 'JP:$004D-TS', code: '3550', netChange: 0 },
  { name: 'JP:$004E-TS', code: '3600', netChange: 0 },
  { name: 'JP:$004F-TS', code: '3650', netChange: 0 },
  { name: 'JP:$0050-TS', code: '3700', netChange: 0 },
  { name: 'JP:$0051-TS', code: '3750', netChange: 0 },
  { name: 'JP:$0052-TS', code: '3800', netChange: 0 },
  { name: 'JP:$0053-TS', code: '4050', netChange: 0 },
  { name: 'JP:$0054-TS', code: '5050', netChange: 0 },
  { name: 'JP:$0055-TS', code: '5100', netChange: 0 },
  { name: 'JP:$0056-TS', code: '5150', netChange: 0 },
  { name: 'JP:$0057-TS', code: '5200', netChange: 0 },
  { name: 'JP:$0058-TS', code: '5250', netChange: 0 },
  { name: 'JP:$0059-TS', code: '6050', netChange: 0 },
  { name: 'JP:$005A-TS', code: '6100', netChange: 0 },
  { name: 'JP:$005B-TS', code: '7050', netChange: 0 },
  { name: 'JP:$005C-TS', code: '7100', netChange: 0 },
  { name: 'JP:$005D-TS', code: '7150', netChange: 0 },
  { name: 'JP:$005E-TS', code: '7200', netChange: 0 },
  { name: 'JP:$005F-TS', code: '8050', netChange: 0 },
  { name: 'JP:$0060-TS', code: '9050', netChange: 0 }
];

class IndustryRankings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false
    };
    this.toggleMore = this.toggleMore.bind(this);
  }

  getStreamId(symbol) {
    return `${symbol}/miniquote`;
  }

  componentDidMount() {
    for (var i = 0; i < industryMap.length; i++) {
      let symbol = industryMap[i].name;
      let streamId = this.getStreamId(symbol);
      this.props.actions.start(streamId);
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    for (var i = 0; i < industryMap.length; i++) {
      let symbol = industryMap[i].name;
      let streamId = this.getStreamId(symbol);
      this.props.actions.stop(streamId);
    }
  }

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  toggleMore(e) {
    this.setState({ showMore: !this.state.showMore });
  }

  createToggleButton() {
    let toggleKeyName = !this.state.showMore ? 'more' : 'close';
    return (
      <div className="market_summary_tse--toggle">
        <div className={'market_summary_tse--show'} onClick={this.toggleMore}>
          {this.props.intl.formatMessage({
            id: `button.label.${toggleKeyName}`
          })}
        </div>
        <div className={`triangle${!this.state.showMore ? '' : '-down'}`} />
      </div>
    );
  }

  render() {
    if (this.props.stream) {
      let channelKeys = Object.keys(this.props.stream);
      let symbolKeys = industryMap.map(s => this.getStreamId(s.name));

      let allStreamsOpened = symbolKeys.every(s => channelKeys.indexOf(s) > -1);

      if (allStreamsOpened) {
        for (var i = 0; i < industryMap.length; i++) {
          let symbol = industryMap[i].name;
          let miniquote = this.props.stream[this.getStreamId(symbol)];
          let netChange =
            (miniquote.close - miniquote.prevClose) / miniquote.prevClose;
          industryMap[i].netChange = netChange;
        }
      }
    }

    let quantityToShow = this.state.showMore ? industryMap.length : 5;

    return (
      <div className={'market_summary_industry_rankings--title_bar'}>
        <div className={'market_summary_symbol--title_bar'}>
          {this.localize('market.summary.industry.rankings.title')}
        </div>
        {industryMap
          .sort((a, b) => {
            return b.netChange - a.netChange;
          })
          .slice(0, quantityToShow)
          .map((i, index) => {
            // Index is 0-indexed
            return (
              <IndustryRankingRow
                symbol={i.name}
                key={i.name}
                netChange={i.netChange}
                rank={index + 1}
                sectorId={i.code}
              />
            );
          })}
        {this.createToggleButton()}
      </div>
    );
  }
}
IndustryRankings = injectIntl(IndustryRankings);

function mapStateToProps(state, ownProps) {
  if (!state.user) {
    return {};
  }
  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };

  if (!state.stream || !state.stream.channels) {
    return {};
  }
  props.stream = { ...(state.stream.channels || {}) };

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      init: bindActionCreators(initWebSocket, dispatch),
      start: bindActionCreators(start, dispatch),
      stop: bindActionCreators(stop, dispatch),
      getSymbol: bindActionCreators(fetchSymbolIfNeeded, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndustryRankings);
