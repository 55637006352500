import React from 'react';
import SymbolAddEditRow from './SymbolAddEditRow';
import { List } from '@mui/material';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import withDraggableContext from '../../utils/hocs/withDraggableContext';

const listTheme = createTheme({
  components: {
    // Name of the component
    MuiList: {
      styleOverrides: {
        root: {
          "& ul": {
            borderTop: "1px solid #00000026"
          },
        }
      },
    },
  },
});

const SymbolAddEditContainer = ({ currentTree, undo, marketBoardId, onDeleteClick, onUndoClick, onSortEnd }) => {
  return withDraggableContext(
    <ThemeProvider theme={listTheme}>
      <List id="marketboard-edit-symbols" disablePadding={true}>
        {currentTree.map((id, i) => {
          const showUndo = !currentTree[i].containsSymbol && undo[i];
          return (
            <SymbolAddEditRow
              id={id}
              key={currentTree[i].position + currentTree[i].name}
              data={currentTree[i]}
              marketboardId={marketBoardId}
              onDeleteClick={onDeleteClick}
              onUndoClick={onUndoClick}
              showUndo={showUndo}
            />
          );
        })}
      </List>
    </ThemeProvider>
  , currentTree, onSortEnd);
};

export default SymbolAddEditContainer;
