import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

class NotFoundView extends Component {
  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  render() {
    return (
      <div>
        <div class="nopage">
          <ul>
            <li>
              <h1>{this.localize('nopage.message')}</h1>
            </li>
            <li>
              <Link to="/market" class="btn-s">
                {this.localize('local.menu.marketsummary')}
              </Link>
            </li>
            <li>
              <Link to="/" class="btn-s">
                {this.localize('local.menu.marketboard')}
              </Link>
            </li>
            <li>
              <Link to="/news" class="btn-s">
                {this.localize('local.menu.news')}
              </Link>
            </li>
            <li>
              <Link to="/hotlists" class="btn-s">
                {this.localize('local.menu.rankings')}
              </Link>
            </li>
            <li>
              <Link to="/scan" class="btn-s">
                {this.localize('local.menu.scans')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default injectIntl(NotFoundView);
