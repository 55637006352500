function findLowValue(array, parameter) {
  return array.reduce(
    (min, p) => (p[parameter] < min ? p[parameter] : min),
    array[0][parameter]
  );
}

function findHighValue(array, parameter) {
  return array.reduce(
    (max, p) => (p[parameter] > max ? p[parameter] : max),
    array[0][parameter]
  );
}

export function calculateChange(bars) {
  return bars.map((bar, i, arr) => {
    let previousBar = arr[i + 1];
    bar.changeAmount = !!previousBar
      ? bar.ClosePrice - previousBar.ClosePrice
      : 0;
    return bar;
  });
}

export function calculateLows(bars) {
  return {
    OpenPrice: findLowValue(bars, 'OpenPrice'),
    LowPrice: findLowValue(bars, 'LowPrice'),
    HighPrice: findLowValue(bars, 'HighPrice'),
    ClosePrice: findLowValue(bars, 'ClosePrice'),
    changeAmount: findLowValue(bars, 'changeAmount'),
    Volume: findLowValue(bars, 'Volume'),
    AverageDailyVolume: findLowValue(bars, 'AverageDailyVolume')
  };
}

export function calculateHighs(bars) {
  return {
    OpenPrice: findHighValue(bars, 'OpenPrice'),
    LowPrice: findHighValue(bars, 'LowPrice'),
    HighPrice: findHighValue(bars, 'HighPrice'),
    ClosePrice: findHighValue(bars, 'ClosePrice'),
    changeAmount: findHighValue(bars, 'changeAmount'),
    Volume: findHighValue(bars, 'Volume'),
    AverageDailyVolume: findHighValue(bars, 'AverageDailyVolume')
  };
}
