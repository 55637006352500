import types from '../actions/_actionTypes';

function getInitialState() {
  var state = {
    system: {
      maintenance: false
    }
  };
  return state;
}

const system = (state = getInitialState(), action) => {
  var newState;

  switch (action.type) {
    case types.RECEIVE_SYSTEM_STATUS:
      let tx =
        (!!action &&
          !!action.data &&
          !!action.data.data &&
          !!action.data.data.system &&
          action.data.data.system.maintenance === true) ||
        false;
      newState = Object.assign({}, state, { maintenance: tx });
      return newState;
    case types.ERROR_REQUESTING_SYSTEM_STATUS:
      newState = Object.assign({}, state);
      return newState;
    default:
      return state;
  }
};

export default system;
