import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PageTitleBar } from '../common/PageTitleBar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchResult from './SearchResult';
import withTracker from '../../utils/analytics';
import { bindActionCreators } from 'redux';
import { fetchSymbolPatternIfNeeded } from '../../actions/symbols';
import { getSymbolSearchResult } from '../../utils/symbol';

const MAX_SYMBOL_SEARCH_RESULTS = 50;

class SearchResultContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ''
    };
  }

  componentDidMount() {
    const searchPattern = this.getSearchPattern();
    this.performSearch(searchPattern, MAX_SYMBOL_SEARCH_RESULTS);
    this.setState({
      search: searchPattern || ''
    });
  }

  performSearch(searchPattern) {
    this.props.actions.getSymbol(
      searchPattern,
      MAX_SYMBOL_SEARCH_RESULTS,
      true,
      true,
      true,
      true
    );
  }

  render() {
    const searchResult = this.getSearchResult();
    const searchPattern = this.getSearchPattern();
    if (searchPattern !== this.state.search) {
      this.setState({
        search: searchPattern
      });
      this.performSearch(searchPattern);
    }
    const backButton = (
      <IconButton onClick={this.onBackClick} size="large">
        <ArrowBackIcon />
      </IconButton>
    );

    return (
      <div>
        <PageTitleBar
          title={this.props.intl.formatMessage(
            {
              id: 'symbol.search.result'
            },
            {
              searchPattern: searchPattern,
              numberofresults: searchResult.symbols.length
            }
          )}
          leftButton={backButton}
        />
        <SearchResult
          onSelectedSymbol={this.handleSelectedSymbol}
          searchResult={searchResult}
          symbolData={this.props.symbols.data}
        />
      </div>
    );
  }

  onBackClick() {
    window.history.back();
  }

  getSearchResult() {
    return this.props.symbols.searches[this.state.search] || { symbols: [] };
  }

  getSearchPattern() {
    return getSymbolSearchResult(
      new URLSearchParams(this.props.location.search).get('pattern') || ''
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    symbols: state.symbols
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: {
      getSymbol: bindActionCreators(fetchSymbolPatternIfNeeded, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTracker(injectIntl(SearchResultContainer)));
