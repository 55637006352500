import React from 'react';
import TextField from '@mui/material/TextField';

class InputNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  getAllowedNumber(value) {
    if(!this.props.useDecimals) {
      value = value.replace(/\./g, '');
    }

    if (!value) {
      return value;
    }

    value = parseInt(value);
    if (this.props.min || this.props.min === 0) {
      if (value < this.props.min) {
        value = this.props.min;
      }
    }

    if (this.props.max || this.props.max === 0) {
      if (value > this.props.max) {
        value = this.props.max;
      }
    }
    return value;
  }

  onChange = event => {
    this.setState({
      value: this.getAllowedNumber(event.target.value)
    });
  };

  onBlur = event => {
    if (!this.props.onBlur) {
        return;
    }

    let value = this.getAllowedNumber(event.target.value);
    if (value === '') {
      return;
    }

    this.setState({
      value: value
    });
    this.props.onBlur(parseInt(value));
  };

  // This is required in order to update inputs with old values
  componentDidUpdate = prevProps => {
    if (prevProps.value === this.props.value) {
      return;
    }

    this.setState({
      value: this.props.value
    });
  };

  render() {
    let inputProps = {};
    if (this.props.min || this.props.min === 0) {
      inputProps.min = this.props.min;
    }
    if (this.props.max || this.props.max === 0) {
      inputProps.max = this.props.max;
    }

    return (
      <TextField
        className={this.props.className}
        value={this.state.value}
        onChange={this.onChange}
        onBlur={this.onBlur}
        InputProps={{ inputProps: inputProps }}
        type="number"
        variant="standard"
    />
    );
  }
}

export default InputNumber;
