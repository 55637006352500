import types from './_actionTypes';
import * as time from '../utils/time';
import fetchRetry from '../utils/fetchRetry';
import { handleErrors } from './../utils/common';

var debounceTime = 100;
var maxItems = 25;
var timer = null;
var stack = [];

function getSymbolPostUrl() {
  // prettier-ignore
  return `https://${process.env.REACT_APP_SYFT_HOSTNAME}/symbols?language=ja-JP`;
}

function getSymbolUrl(symbol) {
  // prettier-ignore
  return `https://${process.env.REACT_APP_SYFT_HOSTNAME}/symbols/${symbol}?language=ja-JP`;
}

function fetchSymbols() {
  return (dispatch, getState) => {
    let symbols = [...stack];

    stack.length = 0;
    clearTimeout(timer);
    timer = null;

    return fetchRetry(getSymbolPostUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },

      body: JSON.stringify(symbols)
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((response) => {
        response.data.symbols.forEach((symbolObject) => {
          let symbol = Object.keys(symbolObject)[0];
          let symbolData = Object.entries(symbolObject)[0][1];
          // after moving the receiveSymbolResult
          // logic into receiveSymbolMetaData (which
          // just set loading to false,etc) we can
          // reduce this to a single dispatch
          //dispatch(receiveSymbolResult(symbol));
          dispatch(receiveSymbolMetaData(symbol, symbolData));
        });
      })
      .catch((ex) => {
        symbols.forEach((symbol) => {
          dispatch(errorRequestingSymbol(symbol, ex, 'E0016'));
        });
      });
  };
}

function fetchSymbol(symbol) {
  return (dispatch, getState) => {
    return fetchRetry(getSymbolUrl(symbol))
      .then(handleErrors)
      .then((response) => response.json())
      .then((response) => {
        dispatch(receiveSymbolMetaData(symbol, response.data));
      })
      .catch((ex) => {
        dispatch(errorRequestingSymbol(symbol, ex, 'E0008'));
      });
  };
}

function shouldFetchSymbol(state, symbol) {
  if (!symbol) {
    return false;
  }

  const search = state.symbols.data[symbol];
  var shouldFetch = false;
  if (!search) {
    shouldFetch = true;
  } else if (search.error) {
    shouldFetch = true;
  } else if (search.isLoading) {
    shouldFetch = false;
  } else if (search.when && search.when < time.timestamp() - 300) {
    shouldFetch = true;
  } else {
    shouldFetch = search.didInvalidate;
  }
  return shouldFetch;
}

function errorRequestingSymbol(symbol, error, errorCode) {
  return {
    type: types.SYMBOL_ERROR_REQUESTING_METADATA,
    symbol: symbol,
    error: error,
    errorCode: errorCode
  };
}

function requestingSymbol(symbol) {
  return {
    type: types.REQUESTING_SYMBOL,
    symbol: symbol
  };
}

export function receiveFetchPostResult(dispatch, symbol) {
  if (symbol) {
    dispatch(receiveSymbolResult(symbol));
  }
}

export function delayFetchSymbols(symbol) {
  return function (dispatch) {
    if (timer === null) {
      timer = setTimeout(() => {
        dispatch(fetchSymbols());
      }, debounceTime);
    } else if (stack.length >= maxItems) {
      dispatch(fetchSymbols());
    }
    stack.push(symbol);
  };
}

export function receiveFetchResult(dispatch, symbol, response) {
  if (response.data) {
    dispatch(receiveSymbolResult(symbol));
    dispatch(receiveSymbolMetaData(symbol, response.data));
  }
}

export function fetchSymbolIfNeeded(symbol) {
  return (dispatch, getState) => {
    if (shouldFetchSymbol(getState(), symbol)) {
      dispatch(requestingSymbol(symbol));
      return dispatch(delayFetchSymbols(symbol));
    }
  };
}

export function fetchSymbolMetadataIfNeeded(symbol) {
  return (dispatch, getState) => {
    if (shouldFetchSymbol(getState(), symbol)) {
      dispatch(requestingSymbol(symbol));
      return dispatch(fetchSymbol(symbol));
    }
  };
}

export function receiveSymbolMetaData(symbol, symbolsinfo) {
  return {
    type: types.RECEIVE_SYMBOL_METADATA,
    symbol: symbol,
    data: symbolsinfo
  };
}

export function receiveSymbolResult(symbol) {
  return {
    type: types.RECEIVE_SYMBOL_RESULT,
    symbol: symbol
  };
}
