function getSmaSeries(periods, bars, fullBarData, localize) {
  let result = [];
  for (let i = 0; i < periods.length; i++) {
    let period = periods[i];
    let lineId = `sma${i}_${period}`;

    result.push({
      type: 'candlestick',
      id: lineId,
      visible: false,
      data: fullBarData.slice(-1 * (bars.length + period - 1)),
      yAxis: 0
    });
    result.push({
      type: 'sma',
      name: localize({ id: 'symbol.details.chart.tooltip.sma' }).replace(
        'XX',
        period
      ),
      yAxis: 0,
      linkedTo: lineId,
      visible: true,
      params: {
        index: 3,
        period: period
      },
      enableMouseTracking: false
    });
  }
  return result;
}

const calculateItemsSum = (bars, start, stop) => {
	let sum = 0;
  for (let j = start; j <= stop; j++) {
    sum += bars[j][4];
  }
  return sum;
};

const getSma = (bars, period) => {
	const result = [];
  for (let i = bars.length - 1; i > period; i--) {
    const sum = calculateItemsSum(bars, i - period + 1, i);
    let timestamp = bars[i][0];
    result.unshift([timestamp, sum / period]);
  }

  // Fill with null bars at the beginning
  for(let i = 0; i < period; i++) {
    result.unshift(null);
  }

  return result;
};

module.exports = {
  getSeries: getSmaSeries,
  getSma: getSma
};
