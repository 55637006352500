import React from 'react';

// Date displays a date, formatted as M/d (no leading zeroes)
// The time is expected to be given as an ISO 8601 formatted string in the
// `value` prop.  If the date cannot be parsed, an em dash is displayed.
export default class DateComponent extends React.PureComponent {
  render() {
    const date = new Date(this.props.value);
    if (!date || isNaN(date.getTime())) {
      return <span className="date empty">&mdash;</span>;
    }
    return (
      <span className="date">{date.getMonth() + 1 + '/' + date.getDate()}</span>
    );
  }
}
