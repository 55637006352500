import types from '../actions/_actionTypes';
import initialState from './initialState';

export default function newsData(state = initialState.news, action) {
  var news = {};
  switch (action.type) {
    case types.NEWS_ERROR_REQUESTING_ARTICLE:
      news = Object.assign({}, state);
      news.searches = Object.assign({}, state.searches);
      news.articles[action.articleId] = {
        isLoading: false,
        isError: true,
        didInvalidate: false
      };
      return news;
    case types.NEWS_ERROR_REQUESTING_TSE:
      news = Object.assign({}, state);
      news.tse = {
        isLoading: false,
        isError: true,
        didInvalidate: false
      };
      return news;
    case types.RECEIVE_NEWS_ARTICLE:
      news = Object.assign({}, state);
      news.articles[action.data.data.article.articleId] =
        action.data.data.article;
      return news;
    case types.RECEIVE_NEWS_TSE:
      news = Object.assign({}, state);
      news.tse = action.data.data.article;
      news.tse.isLoading = false;
      news.tse.isError = false;
      news.tse.didInvalidate = false;
      return news;
    case types.RECEIVE_SYMBOL_NEWS:
      news = Object.assign({}, state);
      news.isLoading = false;
      news.isError = false;
      news.headlines = action.data;
      return news;
    case types.RECEIVE_GLOBAL_NEWS:
      news = Object.assign({}, state);
      news.isLoading = false;
      news.isError = false;
      news.headlines = action.data;
      return news;
    case types.WILL_RECEIVE_NEWS:
      news = Object.assign({}, state);
      news.isLoading = true;
      return news;
    case types.NEWS_ERROR_REQUESTING_HEADLINES:
      news = Object.assign({}, state);
      news.isLoading = false;
      news.isError = true;
      return news;
    default:
      return state;
  }
}
