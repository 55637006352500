import React from 'react';
import { connect } from 'react-redux';

function addParameterIfRequired(url, parameterName, parameterValue) {
  return parameterValue !== undefined
    ? url += `&${parameterName}=${parameterValue}`
    : url;
}

function buildUrl(
  style,
  symbol,
  barSize,
  quantity,
  width,
  height,
  markHighLow,
  token,
  smaLength,
  useStrokeColors,
  strokeSize,
  useShadow
) {
  let url =
    `https://${process.env.REACT_APP_RADIANT_HOSTNAME}/chart` +
    `?style=${style}&symbol=${symbol}&barSize=${barSize}&quantity=${quantity}` +
    `&width=${width}&height=${height}&markHighLow=${markHighLow}`;

  url = addParameterIfRequired(url, 'smaLength', smaLength);
  url = addParameterIfRequired(url, 'useStrokeColors', useStrokeColors);
  url = addParameterIfRequired(url, 'strokeSize', strokeSize);
  url = addParameterIfRequired(url, 'useShadow', useShadow);
  
  url += `&token=${token}`;
  return url;
}

function RadiantChart({
  user,
  style = 'line',
  symbol,
  barSize = '1week',
  quantity = 52,
  width = 80,
  height = 20,
  markHighLow = true,
  smaLength,
  useStrokeColors,
  strokeSize,
  useShadow
}) {

  const url = buildUrl(
    style,
    symbol,
    barSize,
    quantity,
    width,
    height,
    markHighLow,
    user.token,
    smaLength,
    useStrokeColors,
    strokeSize,
    useShadow
  );
  const chart = <img width={width} height={height} src={url} />;

  return chart;
}

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps)(RadiantChart);
