import { createSelector } from 'reselect';

const areEqual = (a, b) => {
  // use `.when` property to compare if data has changed, if available.
  return (
    a.timestamp === b.timestamp && a.symbol === b.symbol && a.memo === b.memo
  );
};

const getMemoBySymbol = (state, symbol) => {
  const data = (state.memos || {})[symbol] || {};
  // need to do shallow copy as reselect expects immutable objects
  // and treats data as same if they are reference equal
  return Object.assign({}, data);
};

export const createMemoSelector = (symbol) =>
  createSelector(
    (state) => getMemoBySymbol(state, symbol),
    (memo) => memo,
    {
      memoizeOptions: {
        equalityCheck: areEqual,
        // maxSize is selector cache size
        maxSize: 20
      }
    }
  );
