import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { fullQuoteSelector, getTradeStatus } from '../../utils/quotes';
import { isIndustry, isSupplemental } from '../../utils/symbol';
import Exchange from '../common/Exchange';
import AddListIcon from '../../icons/Icon-List-Add.svg';
import AddedListIcon from '../../icons/Icon-List-Added.svg';
import AddListIconDisabled from '../../icons/Icon-List-Add-Disabled.svg';
import ClockIcon from '../../icons/Icon-Clock.svg';
import { marketboardFontFamily } from '../../utils/html';
import { fetchSymbolIfNeeded } from '../../actions/symbol';
import useActions from '../../utils/hooks/useActions';
import { useEffect } from 'react';
import { ReactComponent as IconHistory } from '../../icons/Icon-History.svg';

const useStyles = makeStyles({
  symbolCard: {
    height: '48px',
    background: '#FFFFFF',
    padding: '2px 10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) auto'
  },
  symbolDetailsArea: {
    margin: 'auto 0'
  },
  symbolDetailsHistoryArea: {
    background: `url(${ClockIcon}) 0px 10px no-repeat`,
    paddingLeft: '30px',
    display: 'inline-block',
    marginTop: '2px'
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap'
  },
  symbolName: {
    fontFamily: marketboardFontFamily,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#333333',
    marginRight: '1px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  symbolDetails: {
    display: 'block',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#666666'
  },
  symbolDetailItem: {
    marginRight: '5px'
  },
  orderButton: {
    padding: '0px 10px',
    height: '36px',
    background: '#A64E4A',
    borderRadius: '4px',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '12px',
    color: '#FFFFFF',
    display: 'inline-block',
    marginLeft: '10px',
    '& span': {
      lineHeight: '36px'
    },
    cursor: 'pointer'
  },
  detailsButton: {
    padding: '0px 8px',
    height: '32px',
    background: '#FFFFFF',
    border: '2px solid #927A22',
    borderRadius: '4px',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    color: '#927A22',
    display: 'inline-block',
    marginLeft: '10px',
    '& span': {
      lineHeight: '32px'
    },
    cursor: 'pointer'
  },
  addButton: {
    width: '32px',
    height: '32px',
    display: 'inline-block',
    marginLeft: '10px'
  },
  addButtonCanAdd: {
    background: `url(${AddListIcon}) 5px 5px no-repeat`,
    cursor: 'pointer'
  },
  addButtonAlreadyAdded: {
    background: `url(${AddedListIcon}) 5px 5px no-repeat`
  },
  addButtonDisabled: {
    background: `url(${AddListIconDisabled}) 5px 5px no-repeat`
  },
  clockIcon: {
    position: 'absolute',
    marginTop: '3px'
  },
  symbolRow: {
    marginLeft: '35px'
  }
});

export const MobileSymbolSearchCard = ({
  symbol,
  isFromHistory,
  isRecent,
  isAdded,
  isAddDisabled,
  isBuyDisabled,
  isDetailsDisabled,
  onOrderClick,
  onAddClick,
  onDetailsClick
}) => {
  // Actions
  const [getSymbol] = useActions([fetchSymbolIfNeeded], []);

  // Redux Store
  const quoteData = useSelector((state) =>
    fullQuoteSelector((state.stream || {}).channels || {}, symbol)
  );
  const symbolData = useSelector(
    (state) =>
      ((state.symbols.data || { data: {} } || {})[symbol] || {}).data || {}
  );

  // Intl
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });

  useEffect(() => {
    getSymbol(symbol);
  }, [symbol, getSymbol]);

  // Styles
  const styles = useStyles();

  const symbolName = symbolData.name;
  const addButtonStateClass = isAdded
    ? styles.addButtonAlreadyAdded
    : styles.addButtonCanAdd;
  const addButtonClass = [
    styles.addButton,
    isAddDisabled ? styles.addButtonDisabled : addButtonStateClass
  ].join(' ');

  const orderButton = (
    <div
      id={'symbol-search-card-order-button'}
      className={styles.orderButton}
      onClick={onOrderClick}
    >
      <span>{localize('symbol.search.order')}</span>
    </div>
  );
  const detailsButton = (
    <div
      id={'symbol-search-card-details-button'}
      className={styles.detailsButton}
      onClick={onDetailsClick}
    >
      <span>{localize('symbol.search.details')}</span>
    </div>
  );
  const addButton = (
    <div
      id={'symbol-search-card-add-button'}
      className={addButtonClass}
      onClick={() => !isAddDisabled && onAddClick(symbol)}
    >
      &nbsp;
    </div>
  );
  const actionButtons = (
    <div className={styles.actionButtons}>
      {!isBuyDisabled && orderButton}
      {!isDetailsDisabled && detailsButton}
      {addButton}
    </div>
  );

  const clockIcon = isFromHistory && symbolName
    ? (
      <div className={styles.clockIcon}>
        <IconHistory />
      </div>
    ) : null;

  return (
    <div id={'symbol-search-card'} className={styles.symbolCard}>
      <div
        className={
          isRecent ? styles.symbolDetailsHistoryArea : styles.symbolDetailsArea
        }
      >
        {clockIcon}
        <div className={isFromHistory ? styles.symbolRow : null}>
          <div className={styles.symbolName}>
            <span>{symbolName}</span>
          </div>
          <div style={{ clear: 'both' }} />
          <div className={styles.symbolDetails}>
            {isSupplemental(symbolData.assetClass) ||
            isIndustry(symbolData.assetClass) ? (
              ' '
            ) : (
              <span className={styles.symbolDetailItem}>{symbolData.root}</span>
            )}
            <span className={styles.symbolDetailItem}>
              <Exchange
                name={symbolData.exchangeName}
                section={symbolData.jpSection}
                assetClass={symbolData.assetClass}
              />
            </span>
            <span className={styles.tradeStatus}>{getTradeStatus(quoteData)}</span>
          </div>
        </div>
      </div>
      {actionButtons}
    </div>
  );
};

export default MobileSymbolSearchCard;
