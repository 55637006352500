import React, { Component } from 'react';
import QuoteTableBody from './QuoteTableBody';
import { connect } from 'react-redux';
import {
  postMarketboards,
  toggleEdit,
  deleteSymbol
} from '../../actions/marketboards';

class QuoteTable extends Component {
  onToggleAndSave() {
    //Logger.debug('T&S Quote Table');
    this.props.toggleEdit();
    this.props.saveMarketboard();
  }

  onDeleteClick(id, pos) {
    this.props.onDeleteClick(id, pos);
    this.props.saveMarketboard();
  }

  render() {
    return (
        <QuoteTableBody
            selectedView={this.props.selectedView}
            symbols={this.props.symbols}
            onDeleteClick={this.onDeleteClick.bind(this)}
            marketboardId={this.props.marketboardId}
            history={this.props.history}
        />
    );
  }
}

function mapStateToProps(state) {
  const { selectedView } = state.marketboard;
  const isEdit = state.marketboard.edit;
  return {
    selectedView,
    isEdit
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleEdit: () => dispatch(toggleEdit()),
    saveMarketboard: () => dispatch(postMarketboards()),
    onDeleteClick: (id, pos) => {
      dispatch(deleteSymbol(id, pos));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteTable);
