import { handleErrors } from './../utils/common';
import * as time from '../utils/time';
import types from './_actionTypes';
import { getOrderedSymbolSearch } from '../utils';
import { isJapaneseEquity, getSymbolRoot } from '../utils/symbol';
import fetchRetry from '../utils/fetchRetry';
import { receiveSymbolMetaData, receiveSymbolResult } from './symbol';

const DEFAULT_SYMBOL_PATTERN_FETCH_RESULTS = 20;

function getSearchUrl(
  pattern,
  top = DEFAULT_SYMBOL_PATTERN_FETCH_RESULTS,
  jpeq = true,
  useq = false,
  supplemental = false,
  industry = false
) {
  if (isJapaneseEquity(pattern)) {
    pattern = getSymbolRoot(pattern);
  }

  return `https://${
    process.env.REACT_APP_SYFT_HOSTNAME
  }/symbols/matches?pattern=${pattern}${getAssetType(
    jpeq,
    useq,
    supplemental,
    industry
  )}&language=ja-JP&top=${top}`;
}

function getAssetType(isJpeq, isUseq, isSupplemental, industry) {
  let returnString = '';
  if (isJpeq === false) {
    returnString = `${returnString}&jpeq=false`;
  }
  if (isUseq) {
    returnString = `${returnString}&useq=true`;
  }
  if (isSupplemental) {
    returnString = `${returnString}&supplemental=true`;
  }
  if (industry) {
    returnString = `${returnString}&industry=true`;
  }
  return returnString;
}

function isFuture(symbolId) {
  return symbolId === '@OSENK' || symbolId === '@SGXNK';
}

function receiveSearchPatternFetchResult(dispatch, pattern, response, top) {
  // In order to retrieve information for non japanese equities (JP:$MNK), it's
  // required to use the symbols endpoint (symbols/JP:$MNK) instead of using the
  // matches endpoint (symbols/matches). So, for non japanese equities it's required
  // to obtain the response directly from the `data` property instead of using the
  // `symbols` property, as this is the array that will have the data when using the
  // `matches` endpoint.
  if (response.data && response.data.id) {
    if (isFuture(response.data.id)) return;
    dispatch(receiveSymbolPatternResult(pattern, [pattern]));
    dispatch(receiveSymbolMetaData(pattern, response.data));
    return;
  }
  if (response.data && response.data.symbols) {
    let symbols = getOrderedSymbolSearch(
      response.data.symbols.filter((symbol) => !isFuture(symbol.id)),
      top
    ).map((symbol) => symbol.id);
    dispatch(receiveSymbolPatternResult(pattern, symbols));
    response.data.symbols
      .filter((symbol) => !isFuture(symbol.id))
      .map((symbol) => dispatch(receiveSymbolMetaData(symbol.id, symbol)));
  }
}

function receiveSymbolPatternResult(pattern, symbols) {
  return {
    type: types.RECEIVE_SYMBOL_PATTERN_RESULT,
    pattern: pattern,
    symbols: symbols
  };
}

function requestingSymbolPattern(pattern) {
  return {
    type: types.REQUESTING_SYMBOL_PATTERN,
    pattern
  };
}

function fetchSearchPattern(
  pattern,
  topSearchResult,
  jpeq,
  useq,
  supplemental,
  industry
) {
  return (dispatch, getState) => {
    // TODO : refactor this code, it's hacky
    let topSyftRequest = DEFAULT_SYMBOL_PATTERN_FETCH_RESULTS;
    if (
      topSearchResult &&
      topSearchResult > DEFAULT_SYMBOL_PATTERN_FETCH_RESULTS
    ) {
      topSyftRequest = topSearchResult;
    }
    return fetchRetry(
      getSearchUrl(pattern, topSyftRequest, jpeq, useq, supplemental, industry)
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then((response) => {
        receiveSearchPatternFetchResult(
          dispatch,
          pattern,
          response,
          topSearchResult
        );
      })
      .catch((ex) => {
        dispatch(errorRequestingSymbolPattern(pattern, ex));
      });
  };
}

function shouldFetchSymbolPattern(state, pattern, forceFetch = false) {
  const search = state.symbols.searches[pattern];
  var shouldFetch = false;
  if (!search) {
    shouldFetch = true;
  } else if (search.error) {
    shouldFetch = true;
  } else if (search.isLoading) {
    shouldFetch = false;
  } else if (search.when && search.when < time.timestamp() - 300) {
    shouldFetch = true;
  } else {
    shouldFetch = search.didInvalidate;
  }
  return shouldFetch || forceFetch;
}

export function errorRequestingSymbolPattern(pattern, error) {
  return {
    type: types.SYMBOL_ERROR_REQUESTING_PATTERN,
    pattern: pattern,
    error: error,
    errorCode: 'E0006'
  };
}

export function invalidateSymbolPattern(pattern) {
  return {
    type: types.INVALIDATE_SYMBOL_PATTERN,
    pattern
  };
}

export function fetchSymbolPatternIfNeeded(
  pattern,
  top,
  jpeq,
  useq,
  supplemental,
  industry
) {
  return (dispatch, getState) => {
    let forceFetch =
      typeof jpeq === 'boolean' ||
      typeof useq === 'boolean' ||
      typeof supplemental === 'boolean' ||
      typeof industry === 'boolean';
    if (shouldFetchSymbolPattern(getState(), pattern, forceFetch)) {
      dispatch(requestingSymbolPattern(pattern));
      return dispatch(
        fetchSearchPattern(pattern, top, jpeq, useq, supplemental, industry)
      );
    }
  };
}

export function receiveSearchFetchResult(dispatch, symbol, response) {
  if (response.data) {
    dispatch(receiveSymbolResult(symbol));
    dispatch(receiveSymbolMetaData(symbol, response.data));
  }
}
