import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { setDocumentTitle } from '../../utils/html';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { isMonexApp } from '../../utils/common';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import {
  getSelectedBoard,
  marketboardDeletableSelector
} from '../../selectors/marketboardSelectors';
import { PageTitleBar } from '../common/PageTitleBar';
import {
  deleteMarketboard,
  deleteSymbol,
  postMarketboards,
  replaceMarketboardSymbols,
  updateMarketboardNameIfNeeded,
  updateMarketboardSymbol
} from '../../actions/marketboards';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import SymbolAddEditContainer from './SymbolAddEditContainer';
import DeleteDialog from '../common/DeleteDialog';
import useActions from '../../utils/hooks/useActions';
import { MarketBoardTitleEdit } from './MarketBoardTitleEdit';
import {getMaxSymbolsPerBoard} from "../../utils/marketboards";
import Button from '@mui/material/Button';

const MAX_SYMBOLS_PER_BOARD = getMaxSymbolsPerBoard();

function indexOfPosition(symbols, position) {
  return _.findIndex(symbols, function (symbol) {
    return symbol.Position === position;
  });
}

function mergeWithEmpty(symbols) {
  return _.times(MAX_SYMBOLS_PER_BOARD, (position) => {
    var index = indexOfPosition(symbols, position);
    if (index > -1) {
      return {
        position: position,
        name: symbols[index].Symbol,
        containsSymbol: true
      };
    } else {
      return {
        position: position,
        name: '',
        containsSymbol: false
      };
    }
  });
}

function rowToSymbol(row, index) {
  return {
    Symbol: row.name || '',
    Position: index
  };
}

const marketboardFontFamily =
  '"Arial", "ヒラギノ角ゴシック ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", sans-serif';

const useStyles = makeStyles({
  marketBoardEditContainer: {
    clear: 'both'
  },
  input: {
    marginTop: '16px'
  },
  deleteLink: {
    float: 'right',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#927A22',
    padding: '20px',
    cursor: 'pointer'
  },
  disabled: {
    color: '#CCC'
  }
});

const dialogStyles = makeStyles({
  deleteContent: {
    width: '100%',
    marginTop: '20px',
    marginLeft: '0px',
    fontWeight: '900'
  },
  titleText: {
    paddingTop: '20px',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    alignItems: 'center',
    color: '#333333'
  },
  contentText: {
    width: '100%',
    marginTop: '5px'
  },
  deleteDialogCancel: {
    width: '120px',
    height: '40px',
    margin: '0px auto 10px 0px',
    borderRadius: '4px',
    background: 'white !important',
    border: '2px solid #927A22',
    boxShadow: 'none',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#927A22'
  },
  deleteDialogConfirm: {
    width: '120px',
    height: '40px',
    margin: '0px 10px 10px auto',
    borderRadius: '4px',
    background: 'white !important',
    border: '2px solid #927A22',
    boxShadow: 'none',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#927A22'
  },
  buttonDisabled: {
    color: '#666666',
    border: '2px solid #666666',
    background: 'white !important',
    opacity: '0.5'
  },
  checkBox: {
    '& .MuiSvgIcon-root': {
      fontSize: 22,
      color: '#616161'
    },
    'padding-bottom': '12px'
  },
  deleteDialog: {
    '& .MuiPaper-root': {
      alignItems: 'left',
      padding: '0px',
      background: '#FFFFFF',
      borderRadius: '8px'
    }
  }
});

export const MarketBoardEditContainer = ({ match, history }) => {
  const styles = useStyles();
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });

  // actions
  const [updateSymbol] = useActions([updateMarketboardSymbol]);
  const [deleteMarketboardById] = useActions([deleteMarketboard]);
  const [deleteMarketboardSymbol] = useActions([deleteSymbol]);
  const [replaceSymbols] = useActions([replaceMarketboardSymbols]);
  const [saveMarketboard] = useActions([postMarketboards]);
  const [updateMarketbordName] = useActions([updateMarketboardNameIfNeeded]);

  // states
  const [undo, setUndo] = useState(_.times(MAX_SYMBOLS_PER_BOARD, () => null));
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    document.body.setAttribute('data-page-name', 'marketboard-edit');
  }, []);

  // redux
  const selected = useSelector((state) =>
    getSelectedBoard(state, match.params.id)
  );
  const marketboardDeletable = useSelector((state) =>
    marketboardDeletableSelector(state)
  );
  const currentTree = mergeWithEmpty(selected.Symbols);
  const marketboardId = selected.Id;

  // functions & event handlers
  const onBackClick = () => {
    window.history.back();
  };
  const onTitleBlur = (val) => {
    updateMarketbordName(marketboardId, val);
    saveMarketboard();
  };
  const onMarketboardDeleteOpen = () => {
    setDeleteDialogOpen(true);
  };
  const onMarketboardDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };
  const onMarketboardDeleteConfirm = () => {
    deleteMarketboardById(marketboardId);
    history.push('/');
    saveMarketboard();
  };
  const onDeleteClick = (symbolPosition) => {
    const copyUndo = [...undo];
    copyUndo[symbolPosition] = currentTree.find(
      (symbol) => symbol.position === symbolPosition
    );
    setUndo(copyUndo);
    deleteMarketboardSymbol(marketboardId, symbolPosition);
    saveMarketboard();
  };
  const onUndoClick = (symbolPosition) => {
    const symbolData = undo[symbolPosition];
    if (symbolData) {
      const copyUndo = [...undo];
      copyUndo[symbolPosition] = null;
      setUndo(copyUndo);
      updateSymbol(marketboardId, symbolPosition, symbolData.name);
      saveMarketboard();
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    let copyUndo = [...undo];
    copyUndo = arrayMove(copyUndo, oldIndex, newIndex).map((symbol, i) => {
      if (symbol) {
        symbol.position = i;
      }
      return symbol;
    });
    setUndo(copyUndo);

    const newSymbols = arrayMove(Object.values(currentTree), oldIndex, newIndex)
      .map(rowToSymbol)
      .filter((x) => x.Symbol.length);

    replaceSymbols(marketboardId, newSymbols);
    saveMarketboard();
  };
  const setTitle = useCallback(
    (boardName) => {
      const title = intl.formatMessage(
        {
          id: 'document.title.marketboard'
        },
        {
          boardName: boardName
        }
      );
      setDocumentTitle(title);
    },
    [intl]
  );

  // UI Components

  const backButton = !isMonexApp() && (
    <IconButton onClick={onBackClick} size="large">
      <ArrowBackIcon />
    </IconButton>
  );

  useEffect(() => {
    setTitle(selected.Name);
  }, [setTitle, selected]);

  const pageTitleBar = (
    <PageTitleBar
      title={localize('marketboard.title.edit')}
      leftButton={backButton}
    />
  );

  const marketboardTitleEdit = (
    <MarketBoardTitleEdit board={selected} onBlur={onTitleBlur} />
  );

  const symbolAddEditContainer = (
    <SymbolAddEditContainer
      onSortEnd={onSortEnd}
      useDragHandle={true}
      lockAxis="y"
      helperClass="drag-item"
      currentTree={currentTree}
      undo={undo}
      marketBoardId={marketboardId}
      onDeleteClick={onDeleteClick}
      onUndoClick={onUndoClick}
    />
  );

  const deleteDialog = (
    <DeleteDialog
      titleText={localize('marketboard.dialog.delete.mb.title')}
      contentText={localize('marketboard.dialog.delete.mb.message')}
      cancelText={localize('button.label.cancel')}
      confirmText={localize('button.label.delete')}
      openDialog={deleteDialogOpen}
      onCancel={onMarketboardDeleteCancel}
      onDelete={onMarketboardDeleteConfirm}
      confirmViaCheckbox={true}
      deleteContent={selected.Name}
      classes={dialogStyles()}
      swapButtons={true}
    />
  );

  let deleteButton = (
    <Button className={[ styles.deleteLink, marketboardDeletable ? '' : styles.disabled].join(' ')}>
      <span
        onClick={() => {
          if (marketboardDeletable) onMarketboardDeleteOpen();
        }}
      >
        {localize('dialog.delete.keyword')}
      </span>
    </Button>
  );

  return (
    <div
      id={'marketboard-edit-container'}
      className={styles.marketBoardEditContainer}
    >
      {!isMonexApp() && pageTitleBar}
      {deleteButton}
      {marketboardTitleEdit}
      {symbolAddEditContainer}
      {deleteDialog}
    </div>
  );
};
