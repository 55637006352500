import React from 'react';
import PropTypes from 'prop-types';

// Number displays a single numeric value. The value (given in the `value`
// prop) is formatted with commas every three digits.
//
// The precision may be controlled by the `precision` prop, which may be an
// integer from zero to ten. By default, values are displayed with no decimal
// places.
//
// If the `hideIfZero` prop is `true`, an em dash is rendered instead of the
// value if the value is exactly zero.
//
// A prefix (such as a currency sign) or a suffix (such as a percentage sign)
// can be specified in the `prefix` and `suffix` props, respectively. Neither
// the prefix nor the suffix is displayed if the value is hidden.
//
// Negative values are always formatted with a leading MINUS sign. Positive
// values may be formatted with a leading PLUS sign if the `signed` prop is
// `true`. (`signed` defaults to `false`.)
class Number extends React.PureComponent {
  static defaultProps = {
    signed: false
  };
  render() {
    const value = parseFloat(this.props.value);

    // Allow users of the Number component add their own classes to the final result
    // if passed 'color' it will assign the default up/down colors
    let additionalClasses;
    if (this.props.className) {
      additionalClasses = ' ' + this.props.className;
    } else {
      // if we don't set it to something, it will be 'undefined'
      additionalClasses = '';
    }

    if (!isFinite(value) || (value === 0 && this.props.hideIfZero)) {
      return (
        <span className={'number empty' + additionalClasses}>&mdash;</span>
      );
    }
    let precision = parseInt(this.props.precision, 10);
    if (!isFinite(precision) || precision < 0 || precision > 10) {
      precision = 0;
    }
    // Split the number into the two parts to the left and right of the decimal
    // point. If the number formats as "0", parts will contain only one
    // element.
    const parts = value.toFixed(precision).split('.');
    // Split the first part (guaranteed to exist in the parts array) into
    // individual digits. Working backward from the third-to-last digit, insert
    // commas until we get to the beginning of the string.
    const negative = value < 0;
    const digits = parts[0].slice(negative ? 1 : 0).split('');
    for (let i = digits.length - 3; i > 0; i -= 3) {
      digits.splice(i, 0, ',');
    }
    if (negative) {
      // Using a proper minus sign (U+2212) rather than a hyphen makes negative
      // numbers more obvious.
      digits.unshift('−');
    }

    if (this.props.signed && value > 0) {
      digits.unshift('+');
    }
    // If parts has only one element, precision must be zero, so we don't need
    // to worry about reinserting the decimal point. Otherwise we join the
    // individual digits to the left of the decimal point back together and
    // prepend them to a decimal points and all digits to the right of that.
    const valueClass = value < 0 ? 'negative' : value > 0 ? 'positive' : 'zero';
    return (
      <span className={'number ' + valueClass + additionalClasses}>
        {(this.props.prefix || '') +
          digits.join('') +
          (parts.length > 1 ? '.' + parts[1] : '') +
          (this.props.suffix || '')}
      </span>
    );
  }
}
Number.propTypes = {
  signed: PropTypes.bool,
  hideIfZero: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  value: PropTypes.number,
  precision: PropTypes.any
};

export default Number;
