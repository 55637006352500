import types from './_actionTypes';
import Logger from '../utils/logger';
import { redirectToLoginIfRequired } from '../utils/common';
import { promiseRetry } from '../utils/promiseRetry';
import { getMarketSdk } from '../utils/sdk';

const DEFAULT_NEWS_FETCH_RESULTS = 250;

function willRequestNews() {
  return {
    type: types.WILL_RECEIVE_NEWS
  };
}

function initializeSdk(user) {
  if (!user || !user.id || user.tokenIsValid !== true || !user.token) {
    Logger.error({
      errorCode: 'E0030',
      message: 'Invalid Token when initializing the SDK'
    });
    return null;
  }

  return getMarketSdk(user.token);
}

function convertArticle(article) {
  Object.keys(article).forEach((key) => {
    if (key === 'headline') {
      article['title'] = article[key];
    }
  });

  return article;
}

function convertArticles(articles) {
  return articles.map((article) => {
    return convertArticle(article);
  });
}

export function fetchHeadlines(symbol, top = DEFAULT_NEWS_FETCH_RESULTS) {
  return (dispatch, getState) => {
    const sdk = initializeSdk(getState().user);
    if (!sdk) {
      return false;
    }

    let fetchHeadlineFn = !!symbol
      ? async () => sdk.news.getHeadlinesBySymbol(symbol, top)
      : async () => sdk.news.getHeadlines(top);

    return promiseRetry(fetchHeadlineFn)
      .then(dispatch(willRequestNews()))
      .then((response) => convertArticles(response))
      .then((headlines) => {
        dispatch(
          receiveNews(symbol, {
            data: {
              articles: headlines,
              total: headlines.length
            },
            ok: true
          })
        );
      })
      .catch((ex) => {
        redirectToLoginIfRequired(ex);
        dispatch(errorRequestingHeadlines(symbol, ex));
      });
  };
}

function receiveNews(symbol, response) {
  if (!!symbol) {
    return {
      type: types.RECEIVE_SYMBOL_NEWS,
      symbol: symbol,
      data: response
    };
  } else {
    return {
      type: types.RECEIVE_GLOBAL_NEWS,
      data: response
    };
  }
}

function receiveArticle(response) {
  return {
    type: types.RECEIVE_NEWS_ARTICLE,
    data: response
  };
}

function receiveTSE(response) {
  return {
    type: types.RECEIVE_NEWS_TSE,
    data: response
  };
}

export function errorRequestingArticle(articleId, error) {
  return {
    type: types.NEWS_ERROR_REQUESTING_ARTICLE,
    articleId: articleId,
    error: error,
    errorCode: 'E0028'
  };
}

export function errorRequestingHeadlines(symbol, error) {
  return {
    type: types.NEWS_ERROR_REQUESTING_HEADLINES,
    symbol: symbol,
    error: error,
    errorCode: 'E0012'
  };
}

export function errorRequestingTSENews(error) {
  return {
    type: types.NEWS_ERROR_REQUESTING_TSE,
    error: error,
    errorCode: 'E0029'
  };
}

export function fetchArticle(articleId) {
  return (dispatch, getState) => {
    const sdk = initializeSdk(getState().user);
    if (!sdk) {
      return false;
    }

    return promiseRetry(async () => sdk.news.getArticleById(articleId))
      .then((response) => convertArticle(response))
      .then((article) => {
        dispatch(
          receiveArticle({
            data: {
              article: article,
              total: 1
            },
            ok: true
          })
        );
      })
      .catch((ex) => {
        redirectToLoginIfRequired(ex);
        dispatch(errorRequestingArticle(articleId, ex));
      });
  };
}

export function fetchTSENews() {
  return (dispatch, getState) => {
    const sdk = initializeSdk(getState().user);
    if (!sdk) {
      return false;
    }

    return promiseRetry(async () => sdk.news.getLatestTSEArticle())
      .then((response) => convertArticle(response))
      .then((article) => {
        dispatch(
          receiveTSE({
            data: {
              article: article,
              total: 1
            },
            ok: true
          })
        );
      })
      .catch((ex) => {
        redirectToLoginIfRequired(ex);
        dispatch(errorRequestingTSENews(ex));
      });
  };
}
