// Monex 11 Url Builder functions

import { symbolExchange, symbolRoot } from '../formatter';
import { isJapaneseEquity } from '../symbol';
import Logger from '../logger';

const formatSymbol = (symbol) => symbol.trim().padEnd(5, '0');

const orderUrlFormat = (dm, attrSrcKey, orderType, symbolCode) =>
  `https://${dm}.monex.co.jp/pc/servlet/ITS/stock/${orderType}?site=insight&attrSrcKey=${attrSrcKey}&dscrCD=${symbolCode}`;

// Buy Example
// https://mxp2.monex.co.jp/pc/servlet/ITS/stock/StkBuyOrder?site=insight&attrSrcKey=b2ff9228a3235b1fca&dscrCD=72030&marketCDOrder=1&orderNari=1

// Sell Example
// https://mxp2.monex.co.jp/pc/servlet/ITS/stock/StkHavingList?site=insight&attrSrcKey=b2ff9228a3235b1fca&dscrCD=72030

export function getSometaroMonex11CashOrderUrl(
  dm,
  userKey,
  symbolId,
  action = 'buy',
  validity = null,
  quantity = null,
  price = null
) {
  if (!isJapaneseEquity(symbolId)) {
    return '';
  }
  const symbolCode = formatSymbol(symbolRoot(symbolId));
  const exchangeId = symbolExchange(symbolId);
  const orderType = action === 'buy' ? 'StkBuyOrder' : 'StkHavingList';

  let extendedQueryParams = '';

  if (action === 'buy') {
    if (!exchangeId) {
      Logger.error(
        `getSometaroMonex11CashOrderUrl: Cannot generate url as exchange for symbol [${symbolId}] not found`
      );
      return '';
    }

    // limitOrder should be 1 when price does not exist
    const limitOrder = price ? '0' : '1';
    const priceQueryParam = price ? `&orderPrc=${price}` : '';
    const quantityQueryParam = quantity ? `&orderNominal=${quantity}` : '';

    extendedQueryParams = `&marketCDOrder=${exchangeId}&orderNari=${limitOrder}${priceQueryParam}${quantityQueryParam}`;
  }

  const baseUrl = orderUrlFormat(dm, userKey, orderType, symbolCode);
  return `${baseUrl}${extendedQueryParams}`;
}

// New Margin Example
// https://mxp2.monex.co.jp/pc/servlet/ITS/stock/MrgNewOrder?site=insight&attrSrcKey=b2ff9228a3235b1fca&dscrCD=72030&marketCDOrder=1

export function getSometaroMonex11DefaultMarginOrderUrl(
  dm,
  userKey,
  symbolId,
  action = 'buy'
) {
  if (!isJapaneseEquity(symbolId)) {
    return '';
  }

  const exchangeId = symbolExchange(symbolId);
  if (!exchangeId) {
    Logger.error(
      `getSometaroMonex11DefaultMarginOrderUrl: Cannot generate url as exchange for symbol [${symbolId}] not found`
    );
    return '';
  }

  const toBuy = action === 'buy';
  const symbolCode = formatSymbol(symbolRoot(symbolId));
  const orderActionCode = action === 'buy' ? '23' : '21';
  const orderType = toBuy ? 'MrgNewOrder' : 'MrgRefundList';

  const baseUrl = orderUrlFormat(dm, userKey, orderType, symbolCode);
  return baseUrl + (toBuy ? `&marketCDOrder=${exchangeId}` : '');
}

// Refund Margin Example
// https://mxp2.monex.co.jp/pc/servlet/ITS/stock/MrgRefundList?site=insight&attrSrcKey=b2ff9228a3235b1fca&dscrCD=72030

export function getSometaroMonex11DefaultMarginRefundUrl(
  dm,
  userKey,
  symbolId,
  action = 'sell'
) {
  return getSometaroMonex11DefaultMarginOrderUrl(dm, userKey, symbolId, action);
}

// One Lot Order Example
// https://mxp2.monex.co.jp/pc/servlet/ITS/stock/StkHakabuBuyOrder?site=insight&attrSrcKey=b2ff9228a3235b1fca&dscrCD=72030

export function getSometaroMonex11SingleShareOrderUrl(dm, userKey, symbolId) {
  if (!isJapaneseEquity(symbolId)) {
    return '';
  }

  const symbolCode = formatSymbol(symbolRoot(symbolId));
  return orderUrlFormat(dm, userKey, 'StkHakabuBuyOrder', symbolCode);
}
