const _ = require('lodash');

function getSeries(periods, bars, fullBarData, localize) {
  let result = [];
  result.push({
    type: 'candlestick',
    id: 'ichimoku',
    visible: false,
    data: fullBarData.slice(-1 * (bars.length + 78)),
    yAxis: 0
  });
  result.push({
    type: 'ikh',
    id: 'ikh',
    yAxis: 0,
    linkedTo: 'ichimoku',
    visible: true,
    params: {
      // Here, we pass in the timestamp of our first bar.
      // If we do not do that, then Highcharts will render
      // all of the Ichimoku cloud data that shows before the
      // current chart period as well, obfuscating the view
      // and giving a disparate chart view from Sometaro
      earliestTimestamp: bars.length > 0 ? bars[0][0] : 0,
      latestTimestamp:
        bars.length > 0 ? bars[bars.length - 1][0] : Number.MAX_SAFE_INTEGER,
      barsLength: bars.length
    },
    tenkanLine: {
      styles: {
        lineColor: 'tomato'
      }
    },
    kijunLine: {
      styles: {
        lineColor: 'darkred'
      }
    },
    chikouLine: {
      styles: {
        lineColor: 'lightgreen'
      }
    },
    senkouSpanA: {
      styles: {
        lineColor: 'green'
      }
    },
    senkouSpanB: {
      styles: {
        lineColor: 'red'
      }
    },
    senkouSpan: {
      styles: {
        overColor: 'rgba(0, 255, 0, 0.3)',
        underColor: 'rgba(255, 0, 0, 0.3)'
      }
    },
    enableMouseTracking: false
  });
  return result;
}

const maxHigh = (arr) => {
  return arr.reduce(function (max, res) {
    return Math.max(max, res[1]);
  }, -Infinity);
};

const minLow = (arr) => {
  return arr.reduce(function (min, res) {
    return Math.min(min, res[2]);
  }, Infinity);
};

const highlowLevel = (arr) => {
  return {
    high: maxHigh(arr),
    low: minLow(arr)
  };
};

const calculateIchimokuIndicator = (series, params) => {
  if (
    !params ||
    !params.period ||
    !params.periodTenkan ||
    !params.periodSenkouSpanB ||
    !params.barsLength ||
    !params.latestTimestamp
  ) {
    return;
  }

  const period = params.period;
  const periodTenkan = params.periodTenkan;
  const periodSenkouSpanB = params.periodSenkouSpanB;

  // Ikh requires close value
  if (
    !series ||
    !series.xData ||
    !_.isArray(series.xData) ||
    series.xData.length <= period ||
    !series.yData ||
    !_.isArray(series.yData)
  ) {
    return;
  }

  const arr = series.yData
    .map((x) => (_.isArray(x) ? x.length : 0))
    .filter((x) => x < 4);
  if (arr.length > 0) {
    return;
  }

  // this is used to handle the skipped final bar
  const filteredXData = series.xData.filter((x) => x <= params.latestTimestamp);
  const filteredXDataOffset = series.xData.length - filteredXData.length;

  const seriesStartIndex =
    series.xData.length - params.barsLength - filteredXDataOffset;
  const seriesEndIndex = series.xData.length - 1 - filteredXDataOffset;

  // use the original implementation as a reference
  // we override the `closestPointRange` from the minimum difference between points in xData 
  // to the difference between the first two points
  // const closestPointRange = getPointRangeMinValueByXData(series.xData);
  const closestPointRange = series.xData[1] - series.xData[0];

  const calculateTS = (
    yData,
    seriesStartIndex,
    i,
    periodTenkan,
    offset = 0
  ) => {
    const tsOffset = 1;
    const tsTargetIndex =
      seriesStartIndex + i - periodTenkan + tsOffset - offset;
    if (tsTargetIndex < 0) {
      return NaN;
    }
    const slicedTSY = yData.slice(
      tsTargetIndex,
      seriesStartIndex + i + tsOffset - offset
    );
    const pointTS = highlowLevel(slicedTSY);
    const TS = (pointTS.high + pointTS.low) / 2;

    return TS;
  };

  const calculateKS = (yData, seriesStartIndex, i, period, offset = 0) => {
    const ksOffset = 1;
    const ksTargetIndex = seriesStartIndex + i - period + ksOffset - offset;
    if (ksTargetIndex < 0) {
      return NaN;
    }
    const slicedKSY = yData.slice(
      ksTargetIndex,
      seriesStartIndex + i + ksOffset - offset
    );
    const pointKS = highlowLevel(slicedKSY);
    const KS = (pointKS.high + pointKS.low) / 2;

    return KS;
  };

  const calculateSSB = (
    yData,
    seriesStartIndex,
    i,
    periodSenkouSpanB,
    offset
  ) => {
    const ssbOffset = 1;
    const ssbTargetIndex =
      seriesStartIndex + i - periodSenkouSpanB + ssbOffset - offset;
    if (ssbTargetIndex < 0) {
      return NaN;
    }
    const slicedSSBY = yData.slice(
      ssbTargetIndex,
      seriesStartIndex + i + ssbOffset - offset
    );
    const pointSSB = highlowLevel(slicedSSBY);
    const SSB = (pointSSB.high + pointSSB.low) / 2;

    return SSB;
  };

  const xData = [];
  const IKH = [];

  const TSIndex = 0;
  const KSIndex = 1;
  const CSIndex = 2;
  const SSAIndex = 3;
  const SSBIndex = 4;

  for (let i = 0; i < params.barsLength; i++) {
    const ikh = new Array(5).fill(undefined);

    const TS = calculateTS(series.yData, seriesStartIndex, i, periodTenkan);
    ikh[TSIndex] = isNaN(TS) ? undefined : TS;

    const KS = calculateKS(series.yData, seriesStartIndex, i, period);
    ikh[KSIndex] = isNaN(KS) ? undefined : KS;

    const offsetTS = calculateTS(
      series.yData,
      seriesStartIndex,
      i,
      periodTenkan,
      period - 1
    );
    const offsetKS = calculateKS(
      series.yData,
      seriesStartIndex,
      i,
      period,
      period - 1
    );
    const SSA = (offsetTS + offsetKS) / 2;
    ikh[SSAIndex] = isNaN(SSA) ? undefined : SSA;

    const SSB = calculateSSB(
      series.yData,
      seriesStartIndex,
      i,
      periodSenkouSpanB,
      period - 1
    );
    ikh[SSBIndex] = isNaN(SSB) ? undefined : SSB;

    const csTargetIndex = seriesStartIndex + period + i - 1;
    if (csTargetIndex <= seriesEndIndex) {
      const CS = series.yData[csTargetIndex][3];
      ikh[CSIndex] = isNaN(CS) ? undefined : CS;
    }

    // the condition is used to handle the skipped final bar
    if (series.xData[seriesStartIndex + i] <= params.latestTimestamp) {
      IKH.push(ikh);
      xData.push(series.xData[seriesStartIndex + i]);
    }
  }

  const endXData = series.xData[seriesEndIndex];

  // sometimes we skip the final 5-minute bar
  // use the `+ filteredXDataOffset` if we want to have the same number of points as Sometaro
  // in the extended Senkou Span 
  // for (let i = 1; i < period + filteredXDataOffset; i++) {
  for (let i = 1; i < period; i++) {
    const ikh = new Array(5).fill(undefined);

    const offsetTS = calculateTS(
      series.yData,
      seriesEndIndex,
      i,
      periodTenkan,
      period - 1
    );
    const offsetKS = calculateKS(
      series.yData,
      seriesEndIndex,
      i,
      period,
      period - 1
    );
    const SSA = (offsetTS + offsetKS) / 2;
    ikh[SSAIndex] = isNaN(SSA) ? undefined : SSA;

    const SSB = calculateSSB(
      series.yData,
      seriesEndIndex,
      i,
      periodSenkouSpanB,
      period - 1
    );
    ikh[SSBIndex] = isNaN(SSB) ? undefined : SSB;

    // the condition is used to handle the empty spaces in the extended Senkou Span in Popsicle
    if (ikh[SSAIndex] || ikh[SSBIndex]) {
      IKH.push(ikh);
      xData.push(endXData + i * closestPointRange);
    }
  }

  return {
    values: IKH,
    xData: xData,
    yData: IKH
  };
};

const getPointRangeMinValueByXData = (xData) => {
  const arr = xData
    .map((x, i) => {
      if (i === 0) {
        return undefined;
      }

      return x - xData[i - 1];
    })
    .filter((x) => x !== undefined);

  return _.min(arr);
};

module.exports = {
  calculateIchimokuIndicator: calculateIchimokuIndicator,
  getPointRangeMinValueByXData: getPointRangeMinValueByXData,
  getSeries: getSeries
};
