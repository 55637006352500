import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';

import { fetchSymbolPatternIfNeeded } from '../../actions/symbols';
import useActions from '../../utils/hooks/useActions';
import { DEFAULT_MAX_SYMBOL_SEARCH_RESULTS } from '../../utils';

import SymbolSearchResults, {
  GetMenuPlacement as GetSearchResultMenuPlacement
} from './SymbolSearchResults';
import SearchInput from './SearchInput';
import SearchSelector, {
  GetMenuPlacement as GetSearchSelectorMenuPlacement
} from './SearchSelector';
import { useSymbolSearchFlags } from '../../utils/hooks/useSymbolSearchFlags';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MenuPlacements } from '../../utils/menuPlacement';

const SEARCH_DEBOUNCE = 200;

const defaultComponentWidth = 200;
const getComponentWidth = (props) =>
  ((props || {}).width || defaultComponentWidth) + 'px';

const useStyles = makeStyles({
  defaultRoot: {
    position: 'relative',
    width: getComponentWidth
  },
  styledRoot: {
    '& *': {
      'box-sizing': 'border-box',
      '-webkit-box-sizing': 'border-box',
      'font-size': '14px'
    },
    '& input': {
      margin: 0,
      'font-size': '100%',
      '-webkit-appearance': 'none',
      outline: 'none',
      '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)'
    },
    '& table': {
      'border-spacing': 0,
      'font-size': '100%'
    }
  },

  searchSelectorContainer: {
    'z-index': 100000,
    position: 'absolute'
  },
  searchResultsContainer: {
    'z-index': 90000,
    position: 'absolute'
  },
  leftAlign: {
    left: '0px'
  },
  rightAlign: {
    right: '0px'
  },
  searchResultTopMenuPlacement: {
    bottom: '28px'
  },
  searchResultBottomMenuPlacement: {},
  searchSelectorTopMenuPlacement: {
    bottom: '28px'
  },
  searchSelectorBottomMenuPlacement: {},
  disabled: {
    cursor: 'default'
  }
});

const stopEvent = (e) => {
  if (e.stopPropagation) {
    e.stopPropagation();
  } else if (e.preventDefault) {
    e.preventDefault();
  } else {
    e.cancelBubble = true;
    e.returnValue = false;
  }
};

const SymbolSearch = ({
  onSelectedSymbol,
  searchHorizontalAlignment,
  selectorHorizontalAlignment,
  disabled,
  searchText,
  InputComponentOverride,
  searchInputRef,
  isPlusIcon,
  showActionButtons,
  ...props
}) => {
  // Refs
  // We use this ref to so that user of this component
  // can set focus on the search "input" field of this component
  const nullRef = useRef(null);
  searchInputRef = searchInputRef || nullRef;

  // Actions
  const [getSymbol] = useActions([fetchSymbolPatternIfNeeded], []);

  // States
  const [searchPattern, setSearchPattern] = useState('');
  const [selected, setSelected] = useState(-1);
  const [searchResult, setSearchResult] = useState([]);

  const [includeJpeq, includeUseq, includeSupplementals, includeIndustry] =
    useSymbolSearchFlags();

  const [displaySearchSelector, setDisplaySearchSelector] = useState(false);

  const [blurTimeout, setBlurTimeout] = useState(null);

  // Store
  const symbols = useSelector((state) => state.symbols || {});

  // Callbacks
  const search = useCallback(
    (searchPattern) => {
      if (!searchPattern) {
        return;
      }

      getSymbol(
        searchPattern,
        DEFAULT_MAX_SYMBOL_SEARCH_RESULTS,
        includeJpeq,
        includeUseq,
        includeSupplementals,
        includeIndustry
      );
    },
    [getSymbol, includeIndustry, includeJpeq, includeSupplementals, includeUseq]
  );

  const debouncedSearch = useMemo(() => debounce(search, SEARCH_DEBOUNCE), [search]);

  // Effects
  // execute search when pattern has changed
  useEffect(() => debouncedSearch(searchPattern), [debouncedSearch, searchPattern]);

  // update search result when we got search results
  useEffect(() => {
    const result = symbols.searches[searchPattern] || {
      symbols: [],
      isLoading: true
    };
    // save search result
    setSearchResult(result);
    // select the 1st item from the result
    setSelected(0);

    // This might be counter intuitive, but we actually don't want to
    // update results every time searchPattern is updated and only update
    // when we get the search results. We only use searchPattern to search the 
    // results based on the search input from user. Because of this, we 
    // don't include searchPattern in the dependency array.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbols]);

  useEffect(() => {
    // cleanup when this component is unmounted
    // this is to stop/clear the timeout
    return () => {
      if (blurTimeout) {
        clearTimeout(blurTimeout);
      }
    };
  });

  useEffect(() => {
    if (searchInputRef.current) {
      if (disabled) {
        searchInputRef.current.blur();
      }
    }
  }, [disabled, searchInputRef]);

  const reset = useCallback(() => {
    setSearchPattern('');
    setSearchResult({ symbols: [] });
  }, []);

  const handleSelectedSymbol = useCallback((symbolName) => {
    if (symbolName) {
      reset();
      onSelectedSymbol(symbolName);
    }
  }, [onSelectedSymbol, reset]);

  const showSearchSelector = useCallback((e) => {
    setDisplaySearchSelector(!displaySearchSelector);
    stopEvent(e);
  }, [displaySearchSelector]);

  const onBlur = useCallback(() => {
    // delay handling in case
    // Click() was made that caused
    // onBlur to occur
    setBlurTimeout(setTimeout(() => reset(), 500));
  }, [reset]);

  const onKeyDown = useCallback((e) => {
    const code = e.which || e.keyCode || 0;
    const tempSelected = Math.min(
      selected + 1,
      searchResult.symbols.length - 1
    );
    switch (code) {
      case 27 /* ESC */:
        reset();
        break;
      case 38 /* UP */:
        setSelected(Math.max(selected - 1, 0));
        break;
      case 40 /* DOWN */:
        setSelected(tempSelected);
        break;
      case 13 /* ENTER */:
        handleSelectedSymbol(searchResult.symbols[selected]);
        break;
      default:
        return;
    }
    stopEvent(e);
  }, [handleSelectedSymbol, reset, searchResult, selected]);

  const onChange = useCallback((e) => {
    setSearchResult({ symbols: [] });
    setSearchPattern(e.target.value);
  }, []);

  const onResultItemClick = useCallback((symbolName) => {
    handleSelectedSymbol(symbolName);
  }, [handleSelectedSymbol]);

  const classes = useStyles(props);

  let searchInput;
  if (InputComponentOverride !== undefined) {
    searchInput = (
      <InputComponentOverride
        inputRef={searchInputRef}
        value={searchPattern}
        onBlur={() => onBlur()}
        onKeyDown={(e) => onKeyDown(e)}
        onChange={(e) => onChange(e)}
        showSearchSelector={(e) => !disabled && showSearchSelector(e)}
        disabled={disabled}
        InputProps={{
          // I don't know if this is the right place to put this logic, we're
          // injecting "styling" AKA an end adornment into the provided input override.
          // That being said, this needs access to local state in this component for their
          // behavior.  If anyone has a better idea, feel free to refactor this whole override.
          endAdornment: (
            <InputAdornment position="end">
              {searchPattern.length <= 0 ? (
                // Only display the dropdown selector if there is no search pattern
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setDisplaySearchSelector(!displaySearchSelector);
                    }}
                    edge="end"
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              ) : (
                // Display an X if there is any input in the field,
                // clicking the X icon will clear the field
                <IconButton onClick={() => setSearchPattern('')} edge="end">
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
      />
    );
  } else {
    searchInput = (
      <SearchInput
        searchInputRef={searchInputRef}
        searchPattern={searchPattern}
        onBlur={() => onBlur()}
        onKeyDown={(e) => onKeyDown(e)}
        onChange={(e) => onChange(e)}
        showSearchSelector={(e) => !disabled && showSearchSelector(e)}
        disabled={disabled}
        searchText={searchText}
        isPlusIcon={isPlusIcon}
        {...props}
      />
    );
  }

  const searchInputHeight = 28;
  const searchContainerRef = useRef(null);
  const searchSelectorMenuPlacement = GetSearchSelectorMenuPlacement({
    element: searchContainerRef.current,
    offsetTop: searchInputHeight
  });
  const searchResultItemCount = ((searchResult || {}).symbols || []).length;
  const searchResultMenuPlacement = GetSearchResultMenuPlacement({
    element: searchContainerRef.current,
    resultItemCount: searchResultItemCount,
    offsetTop: searchInputHeight
  });

  return (
    <div
      ref={searchContainerRef}
      className={[
        classes.defaultRoot,
        // Only apply the root styling if the code did not provide a custom input,
        // if they did then they are responsible for styling themselves
        InputComponentOverride === undefined ? classes.styledRoot : classes.null
      ].join(' ')}
    >
      {searchInput}
      <div
        className={[
          classes.searchSelectorContainer,
          selectorHorizontalAlignment === 'left'
            ? classes.leftAlign
            : classes.rightAlign,
          searchSelectorMenuPlacement === MenuPlacements.top
            ? classes.searchSelectorTopMenuPlacement
            : classes.searchSelectorBottomMenuPlacement
        ].join(' ')}
      >
        <SearchSelector
          displaySearchSelector={displaySearchSelector}
          setSearchSelector={setDisplaySearchSelector}
          disabled={disabled}
        />
      </div>
      <div
        className={[
          classes.searchResultsContainer,
          searchHorizontalAlignment === 'left'
            ? classes.leftAlign
            : classes.rightAlign,
          searchResultMenuPlacement === MenuPlacements.top
            ? classes.searchResultTopMenuPlacement
            : classes.searchResultBottomMenuPlacement
        ].join(' ')}
      >
        {searchPattern !== '' && searchResult.isLoading === false ? (
          <SymbolSearchResults
            selectedIndex={selected}
            results={searchResult.symbols}
            symbolsMetadata={symbols.data || {}}
            onResultItemClick={onResultItemClick}
            showActionButtons={showActionButtons}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SymbolSearch;
