const http = require('http');

export default function pushToLoggly(logData, token, tags) {
  return new Promise((resolve, reject) => {
    try {
      const eventsToPush = logData.map(JSON.stringify).join('\n');

      const options = {
        hostname: 'logs-01.loggly.com',
        path: `/bulk/${token}/tag/${encodeURIComponent(tags.join(','))}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Length': new Buffer(eventsToPush).length
        }
      };

      const req = http.request(options, res => {
        if (res.statusCode !== 200) {
          reject(`Loggly returned status code: ${res.statusCode}`);
          return;
        }

        let data = '';
        res.on('data', chunk => {
          data += chunk;
        });

        res.on('end', () => {
          try {
            const result = JSON.parse(data);
            if (result.response === 'ok') {
              resolve('All events sent to Loggly');
            } else {
              reject(`Loggly post not ok: ${result.response}`);
            }
          } catch (err) {
            reject(`Error parsing post response from Loggly: ${err}`);
          }
        });
      });

      req.on('error', err => {
        reject(`Loggly post error: ${err.toString()}`);
      });

      req.write(eventsToPush);
      req.end();
    } catch (ex) {
      reject(`Error posting to Loggly: ${ex}`);
    }
  });
}
