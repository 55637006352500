import React, { useEffect, useMemo } from 'react';
import Number from '../common/Number';
import { useIntl } from 'react-intl';
import { QuoteCardHighFlag, QuoteCardLowFlag } from '../marketBoard/QuoteCard';
import * as _ from 'lodash';
import DataPanel from './DataPanel';
import CssFlash from '../../views/common/CssFlash';
import { getSymbolClose, isJpeq } from '../../utils/symbol';
import { start, stop } from '../../actions/stream';
import { useSelector } from 'react-redux';
import useActions from '../../utils/hooks/useActions';
import { fetchFundamentals as fetchFundamentalsAction } from '../../actions/market';
import { selectChannel } from '../../selectors/channelSelectors';

function vwap(turnover, volume) {
  if (!!turnover && !!volume) {
    return turnover / volume;
  }

  return 0;
}

function marketCap(sharesOutstanding, close) {
  if (!!sharesOutstanding && !!close) {
    return sharesOutstanding * close;
  }

  return 0;
}

const getNegotiatedMarginKeys = (fundamentals) => {
  const negotiatedMarginAllowedLong = _.get(
    fundamentals,
    'negotiatedMarginAllowedLong.value'
  );
  const negotiatedMarginAllowedShort = _.get(
    fundamentals,
    'negotiatedMarginAllowedShort.value'
  );
  const keys = [];
  if (negotiatedMarginAllowedLong === 1) {
    keys.push('negotiated.margin.allowed.long');
  }
  if (negotiatedMarginAllowedShort === 1) {
    keys.push('negotiated.margin.allowed.short');
  }
  if (!negotiatedMarginAllowedLong && !negotiatedMarginAllowedShort)
    keys.push('symbol.margin.status.null');

  return keys;
};

const getFundamentalValue = (fundamentals, fundamental) => {
  return _.get(fundamentals, [fundamental, 'value']);
};

export const FundamentalFlag = ({ show, keys }) => {
  const intl = useIntl();
  const localize = (key) => {
    return intl.formatMessage({ id: key });
  };

  if (!show || !keys || keys.length === 0) {
    return null;
  }

  const text = keys.map(localize).join('・');

  return (
    <div className={'symbol-fundamentals-margin-container-value'}>
      <span>{text}</span>
    </div>
  );
};

const SymbolDetailAttributeSection = ({ symbol }) => {
  const intl = useIntl();
  const localize = (key) => {
    return intl.formatMessage({ id: key });
  };

  // Quote
  const [startStream] = useActions([start], []);
  const [stopStream] = useActions([stop], []);
  const channel = useMemo(() => `${symbol}/quote`, [symbol]);
  const quote = useSelector((state) => selectChannel(state, channel));

  useEffect(() => {
    startStream(`${symbol}/quote`);

    return () => {
      stopStream(`${symbol}/quote`);
    };
  }, [symbol, startStream, stopStream]);

  // Metadata
  const metadata = useSelector(
    (state) => state.symbols.data || { data: {} } || {}
  );
  const symbolMetadata = (metadata[symbol] || {}).data || {};
  const assetClass = symbolMetadata.assetClass;

  const precision = symbolMetadata.precision || 0;
  const close = getSymbolClose(assetClass, quote);
  const isSymbolEquity = isJpeq(assetClass);

  // Fundamentals
  const [fetchFundamentals] = useActions([fetchFundamentalsAction], []);
  useEffect(() => {
    fetchFundamentals(symbol);
  }, [symbol, fetchFundamentals]);
  const allFundamentals = useSelector(
    (state) => (state.market || {}).fundamentals || {}
  );
  const fundamentals =
    ((allFundamentals[symbol] || {}).data || {}).fundamentals || {};

  return (
    <div className={'symbol-fundamentals-section'}>
      <div className={'symbol-fundamentals-margin-container'}>
        <FundamentalFlag
          show={getFundamentalValue(fundamentals, 'marginTradingType')}
          keys={[
            'symbol.margin.status.' +
              _.get(fundamentals, 'marginTradingType.value', 0)
          ]}
        />
        <FundamentalFlag
          show={
            getFundamentalValue(fundamentals, 'negotiatedMarginAllowedLong') ||
            getFundamentalValue(fundamentals, 'negotiatedMarginAllowedShort')
          }
          keys={getNegotiatedMarginKeys(fundamentals)}
        />
        <FundamentalFlag
          show={getFundamentalValue(
            fundamentals,
            'negotiatedMarginAllowedShortTermMargin'
          )}
          keys={['symbol.margin.negotiated.margin.allowed.short.term.margin']}
        />
        <FundamentalFlag
          show={getFundamentalValue(
            fundamentals,
            'negotiatedMarginAllowedOneDayLong'
          )}
          keys={['symbol.margin.negotiated.margin.allowed.one.day.long']}
        />
        <FundamentalFlag
          show={getFundamentalValue(
            fundamentals,
            'negotiatedMarginAllowedOneDayShort'
          )}
          keys={['symbol.margin.negotiated.margin.allowed.one.day.short']}
        />
        <FundamentalFlag
          show={getFundamentalValue(
            fundamentals,
            'negotiatedMarginAllowedSpecialShortSell'
          )}
          keys={['symbol.margin.negotiated.margin.allowed.special.short.sell']}
        />
      </div>
      <div className={'symbol-stats-container'}>
        <DataPanel
          label={localize('symbol.details.price.open')}
          value={
            <span className={'symbol-details-flash-wrapper'}>
              <CssFlash value={quote.open}>
                <Number
                  value={quote.open}
                  precision={precision}
                  hideIfZero={true}
                />
              </CssFlash>
            </span>
          }
          timestamp={quote.openTime}
          show={true}
        />
        <DataPanel
          label={localize('symbol.details.price.high')}
          value={
            <span className={'symbol-details-flash-wrapper'}>
              <CssFlash value={quote.high}>
                <Number
                  value={quote.high}
                  precision={precision}
                  hideIfZero={true}
                />
              </CssFlash>
            </span>
          }
          flag={
            <QuoteCardHighFlag
              high={quote.high}
              max={quote.maxPrice}
              record={quote.high52Week}
            />
          }
          timestamp={quote.highTime}
          show={true}
        />
        <DataPanel
          label={localize('symbol.details.price.low')}
          value={
            <span className={'symbol-details-flash-wrapper'}>
              <CssFlash value={quote.low}>
                <Number
                  value={quote.low}
                  precision={precision}
                  hideIfZero={true}
                />
              </CssFlash>
            </span>
          }
          flag={
            <QuoteCardLowFlag
              low={quote.low}
              min={quote.minPrice}
              record={quote.low52Week}
            />
          }
          timestamp={quote.lowTime}
          show={true}
        />
        <DataPanel
          label={localize('symbol.details.price.high.52week')}
          value={
            <span className={'symbol-details-flash-wrapper'}>
              <CssFlash value={quote.high52Week}>
                <Number
                  value={quote.high52Week}
                  precision={precision}
                  hideIfZero={true}
                />
              </CssFlash>
            </span>
          }
          date={quote.high52WeekTime}
          show={true}
        />
        <DataPanel
          label={localize('symbol.details.price.low.52week')}
          value={
            <span className={'symbol-details-flash-wrapper'}>
              <CssFlash value={quote.low52Week}>
                <Number
                  value={quote.low52Week}
                  precision={precision}
                  hideIfZero={true}
                />
              </CssFlash>
            </span>
          }
          date={quote.low52WeekTime}
          show={true}
        />
        <DataPanel
          label={localize('symbol.details.price.close')}
          value={
            <Number
              value={quote.prevClose}
              precision={precision}
              hideIfZero={true}
            />
          }
          show={true}
        />
        <DataPanel
          label={localize('symbol.details.volume')}
          value={
            <Number
              value={quote.volume}
              precision={0}
              hideIfZero={true}
            />
          }
          show={isSymbolEquity}
        />
        <DataPanel
          label={localize('symbol.details.shares.outstanding')}
          value={
            <Number
              value={getFundamentalValue(fundamentals, 'sharesOutstanding')}
              hideIfZero={true}
            />
          }
          show={isSymbolEquity}
        />
        <DataPanel
          label={localize('symbol.details.market.cap')}
          value={
            <Number
              value={marketCap(
                getFundamentalValue(fundamentals, 'sharesOutstanding'),
                close
              )}
              hideIfZero={true}
            />
          }
          show={isSymbolEquity}
        />
        <DataPanel
          label={localize('symbol.details.vwap')}
          value={
            <Number
              value={vwap(quote.turnover, quote.volume)}
              precision={precision}
              hideIfZero={true}
            />
          }
          show={isSymbolEquity}
        />
        <DataPanel
          label={localize('symbol.details.trading.value')}
          value={
            <Number
              value={quote.turnover}
              precision={precision}
              hideIfZero={true}
            />
          }
          show={isSymbolEquity}
        />
        <DataPanel
          label={localize('symbol.details.lotsize')}
          value={
            <Number
              value={_.get(symbolMetadata, 'lotSize', 0)}
              precision={0}
              hideIfZero={true}
              suffix={localize('symbol.details.lotsize.units')}
            />
          }
          show={isSymbolEquity}
        />
      </div>
    </div>
  );
};

export default SymbolDetailAttributeSection;
