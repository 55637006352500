import { useState, useEffect } from 'react';
import useWindowDimensions from './useWindowDimensions';

export const MIN_DESKTOP_WIDTH = 1024;

function isDesktopWidth(width) {
  return width >= MIN_DESKTOP_WIDTH;
}

export function useDesktopWidth() {
  const { width } = useWindowDimensions();
  const [getIsDesktopWidth, setIsDesktopWidth] = useState(isDesktopWidth(width));

  useEffect(() => {
    setIsDesktopWidth(isDesktopWidth(width));
  }, [width]);

  return getIsDesktopWidth;
}
