import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DialogModal from '../common/DialogModal';
import DialogNavigationPosition from '../common/DialogNavigationPosition';
import Fade from '@mui/material/Fade';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ReactComponent as DropdownIcon } from '../../icons/Icon-Dropdown.svg';
import SearchIcon from '../../icons/Icon-Search.svg';
import { marketboardFontFamily } from '../../utils/html';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import { ReactComponent as ListAddIcon } from '../../icons/Icon-List-Add-Gold.svg';
import { isPositionBoard, saveLastMarketboard } from '../../utils/marketboards';
import {
  addMarketboard,
  deleteMarketboard,
  setSelected,
  postMarketboards,
  moveMarketboard
} from '../../actions/marketboards';
import useActions from '../../utils/hooks/useActions';
import DeleteDialog from '../common/DeleteDialog';
import Button from '@mui/material/Button';
import {
  getNextMarketboardId,
  getNextMarketboardName
} from '../../selectors/marketboardSelectors';
import SortableBoardItem from '../common/SortableBoardItem';
import withDraggableContext from '../../utils/hocs/withDraggableContext';

const useStyles = makeStyles({
  dropdown: {
    float: 'left',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#333333',
    cursor: 'pointer'
  },
  dropdownIcon: {
    marginLeft: '9px'
  },
  dialogParent: {
    position: 'fixed',
    borderRadius: '8px',
    width: 'calc(95%)',
    height: '80%',
    top: '60px'
  },
  dialog: {
    margin: 0,
    padding: 0
  },
  expanded: {
    position: 'absolute',
    zIndex: 2,
    background: '#FFFFFF',
    top: '150px'
  },
  menuHeader: {
    paddingTop: '10px',
    paddingBottom: '5px'
  },
  menuHeaderSearch: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#333333',
    marginLeft: '16px',
    padding: '5px 5px 5px 0px',
    '& input': {
      border: 'none'
    }
  },
  menuHeaderSearchIcon: {
    position: 'relative',
    top: '3px'
  },
  menuHeaderInputBox: {
    width: '150px',
    height: '12px',
    outline: 'none'
  },
  menuHeaderEditFont: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#927A22',
    float: 'right',
    padding: '10px 16px 5px 0px',
    cursor: 'pointer'
  },
  boardListHeading: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.15)'
  },
  boardListHeadingFont: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#666666',
    padding: '10px 0px 5px 10px'
  },
  boardListItemSpacer: {
    width: '100%',
    height: '15px'
  },
  boardListItem: {
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#333333',
    padding: '16px 0px 16px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
    '& a': {
      textDecoration: 'none',
      color: '#333333'
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'pointer'
  },
  addButton: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    background: 'rgba(240, 230, 201, 0.2)',
    boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.15)',
    height: '52px',
    fontFamily: marketboardFontFamily,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#333333',
    marginTop: '15px'
  },
  dialogContentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  userBoardList: {
    flex: '1',
    overflow: 'auto'
  },
  sortableHelper: {
    zIndex: '10000'
  },
  addButtonDisabled: {
    background: 'rgba(240, 230, 201, 0.5)',
    color: '#8d8d8d',
    cursor: 'default',
    opacity: '0.5'
  }
});

const MAX_BOARDS = process.env.REACT_APP_MAX_MARKETBOARDS;

const FadeTransition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

export const MobileMarketBoardDropdownSelect = ({ marketboard }) => {
  const styles = useStyles();
  const intl = useIntl();
  const localize = (id) => intl.formatMessage({ id: id });
  const isMounted = useRef(false);
  const [expanded, setExpanded] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [boardToDelete, setBoardToDelete] = useState({});

  // Actions
  const [addMarketboardAction] = useActions([addMarketboard]);
  const [deleteMarketboardAction] = useActions([deleteMarketboard]);
  const [postMarketboardsAction] = useActions([postMarketboards]);
  const [setSelectedMarketboardAction] = useActions([setSelected]);
  const [moveMarketboardAction] = useActions([moveMarketboard]);

  // Redux Store
  const userBoards = useSelector(
    (state) => (state.marketboard || {}).user || []
  );
  const cashBoards = useSelector(
    (state) => (state.marketboard || {}).cash || []
  );
  const marginBoards = useSelector(
    (state) => (state.marketboard || {}).margin || []
  );

  const nextMarketboardId = useSelector((state) => getNextMarketboardId(state));

  const nextMarketboardName = useSelector((state) =>
    getNextMarketboardName(state)
  );

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  if (!marketboard) return null;

  const navigate = (url) => {
    window.location.hash = url;
    setExpanded(false);
  };

  const onAddClick = () => {
    addMarketboardAction({
      Id: nextMarketboardId,
      Name: nextMarketboardName,
      Symbols: []
    });
    setSelectedMarketboardAction(nextMarketboardId);
    saveLastMarketboard(nextMarketboardId);
    postMarketboardsAction();
    navigate('#/marketboard/' + nextMarketboardId);
    setSelectedMarketboardAction(nextMarketboardId);
    window.location.hash = '#/marketboard/edit/' + nextMarketboardId;
  };

  const onSelectMarketboard = (marketboardId) => {
    setSelectedMarketboardAction(marketboardId);
    saveLastMarketboard(marketboardId);
    navigate('#/marketboard/' + marketboardId);
  };

  const onMarketboardMoved = ({ oldIndex, newIndex }) => {
    moveMarketboardAction(oldIndex, newIndex);
    postMarketboardsAction();
  };

  const onMarketboardDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const onMarketboardDeleteConfirm = () => {
    setDeleteDialogOpen(false);
    deleteMarketboardAction(boardToDelete.Id);
    postMarketboardsAction();
    if (marketboard.Id === boardToDelete.Id) {
      let remainingBoards = userBoards.filter(
        (board) => board.Id !== boardToDelete.Id
      );
      remainingBoards.length >= 1 &&
        navigate('#/marketboard/' + remainingBoards[0].Id);
    }
  };

  const onDeleteClick = (marketboard) => {
    setBoardToDelete(marketboard);
    setDeleteDialogOpen(true);
  };

  const deleteDialog = (
    <DeleteDialog
      titleText={localize('marketboard.dialog.delete.mb.title')}
      contentText={localize('marketboard.dialog.delete.mb.message')}
      cancelText={localize('button.label.cancel')}
      confirmText={localize('button.label.delete')}
      openDialog={deleteDialogOpen}
      onCancel={onMarketboardDeleteCancel}
      onDelete={onMarketboardDeleteConfirm}
      confirmViaCheckbox={true}
      deleteContent={boardToDelete.Name}
    />
  );

  const getFilteredBoards = (boards, filter) => {
    return boards.filter((board) =>
      board.Name.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const createBoardList = (boardName, boards, filter = '') => {
    return (
      <>
        {boardName && (
          <div
            className={[
              styles.boardListHeading,
              styles.boardListHeadingFont
            ].join(' ')}
          >
            {boardName}
          </div>
        )}
        {getFilteredBoards(boards, filter).map((board) => (
          <div
            id={`marketboard-dropdown-board-item`}
            key={`board.${board.Name}.${board.Id}`}
            className={styles.boardListItem}
            onClick={() => {
              setSelectedMarketboardAction(board.Id);
              saveLastMarketboard(board.Id);
              navigate('#/marketboard/' + board.Id);
            }}
          >
            {board.Name}
          </div>
        ))}
      </>
    );
  };

  const ExpandedDropdownHeader = (
    <div
      id={'marketboard-dropdown-expanded-header'}
      className={styles.menuHeader}
    >
      <span className={styles.menuHeaderSearch}>
        <img
          className={styles.menuHeaderSearchIcon}
          src={SearchIcon}
          alt={'Board Search'}
        />
        <input
          id={'boardSearch'}
          name={'boardSearch'}
          className={styles.menuHeaderInputBox}
          placeholder={localize('marketboard.title.search')}
          onChange={(event) => setFilterText(event.target.value)}
          value={filterText}
          maxLength={20}
        />
      </span>
    </div>
  );

  const cashBoardsList = createBoardList(
    localize('marketboard.type.cash'),
    cashBoards,
    filterText
  );
  const marginBoardsList = createBoardList(
    localize('marketboard.type.margin'),
    marginBoards,
    filterText
  );

  const SortableBoardList = ({ className, boards, onDelete, onSelect, canDelete, searchTerm, onSortEnd }) => {
    return withDraggableContext(
      <List className={className || 'marketboard-list'}>
        {boards.map((board, index) => (
          <SortableBoardItem
            id={board}
            key={board.Id}
            index={index}
            board={board}
            onSelect={onSelect}
            onDelete={onDelete}
            canDelete={canDelete}
            editable={true}
            searchTerm={searchTerm}
          />
        ))}
      </List>
    , boards, onSortEnd);
  };
  SortableBoardList.muiName = 'List';

  const AddButton = ({ canAdd, onAdd }) => (
    <Button
      className={canAdd ? styles.addButton : styles.addButtonDisabled}
      onClick={canAdd ? onAdd : null}
    >
      <div className={['add-board-icon', !canAdd ? 'disabled' : ''].join(' ')}>
        <ListItemIcon>
          <ListAddIcon key="add-button-icon" />
        </ListItemIcon>
        <span>{localize('marketboard.add.title.dropdown')}</span>
      </div>
    </Button>
  );

  const ExpandedDropdown = (
    <DialogModal
      id={'marketboard-dropdown-expanded'}
      fullScreen={false}
      open={expanded}
      handleClose={() => setExpanded(false)}
      navigationPosition={DialogNavigationPosition.Top}
      navigation={ExpandedDropdownHeader}
      transition={FadeTransition}
      dialogClassName={styles.dialogParent}
      dialogContentClassName={styles.dialog}
    >
      <div className={styles.dialogContentContainer}>
        <div className={styles.boardListItemSpacer} />
        <span className={styles.boardListHeadingFont}>
          {localize('marketboard.type.user')}
        </span>
        <div className={styles.userBoardList}>
          <SortableBoardList
            helperClass={styles.sortableHelper}
            boards={getFilteredBoards(userBoards, filterText)}
            lockAxis="y"
            onSelect={onSelectMarketboard}
            onDelete={onDeleteClick}
            canDelete={userBoards.length > 1}
            useDragHandle={true}
            onSortEnd={onMarketboardMoved}
            searchTerm={filterText}
          />
          {cashBoardsList}
          <div className={styles.boardListItemSpacer} />
          {marginBoardsList}
        </div>
        <AddButton onAdd={onAddClick} canAdd={userBoards.length < MAX_BOARDS} />
      </div>
    </DialogModal>
  );

  return (
    <>
      <div
        id={'marketboard-dropdown'}
        className={styles.dropdown}
        onClick={() => setExpanded(true)}
      >
        <span className={styles.name}>{marketboard.Name}</span>
        <DropdownIcon className={styles.dropdownIcon} />
      </div>
      {/* The below is important as there is a zIndex issue when the dialog closes */}
      {expanded && ExpandedDropdown}
      {deleteDialog}
    </>
  );
};
