import types from './_actionTypes';

function getFeedbackUrl(userId) {
  // prettier-ignore
  return `https://${process.env.REACT_APP_INSIGHT_API_DOMAIN}/user/${userId}/usage`;
}

function errorPostingFeedback(id, value, error) {
  return {
    type: types.ERROR_POSTING_FEEDBACK,
    id: id,
    value: value,
    error: error,
    errorCode: 'E0011'
  };
}

export function postFeedback(id, value) {
  return (dispatch, getState) => {
    let user = getState().user;
    if (user.id && user.tokenIsValid === true && user.token) {
      let url = getFeedbackUrl(user.id);
      let postData = {
        method: 'post',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          authorization: user.token
        },
        body: JSON.stringify({
          action: 'feedback',
          data: {
            id: id,
            value: value
          }
        })
      };
      return fetch(url, postData)
        .then((response) => {
          if (!response.ok) {
            dispatch(errorPostingFeedback(id, value, response.body));
          }
        })
        .catch((err) => {
          dispatch(errorPostingFeedback(id, value, err));
        });
    } else {
      dispatch(
        errorPostingFeedback(id, value, {
          message: `user invalid in feedback post: ${JSON.stringify(user)}`
        })
      );
      return Promise.resolve();
    }
  };
}
