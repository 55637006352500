import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '../common/Button';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  confirm: {
    backgroundColor: '#2eb975',
    '&:hover': {
      backgroundColor: '#62cb98'
    },
    color: 'white'
  },
  deleteDialogCancel: {
    color: '#000000',
    backgroundColor: 'white !important'
  }
});

const MemoDialog = ({
  title,
  confirmLabel,
  cancelLabel,
  open,
  boardName,
  onConfirm,
  onCancel,
  contentLabel
}) => {
  const [value, setValue] = useState(boardName);
  const intl = useIntl();
  const classes = useStyles();

  function handleKeyDown(e) {
    switch (e.keyCode) {
      // ENTER
      case 13:
        onConfirm(value);
        break;
      // ESC
      case 27:
        onCancel();
        break;
    }
  }

  // By default, show the TextField and only show the message if
  // we explicitely set a value for `contentLabel` parameter.
  const dialogContent = contentLabel ?
    (intl.formatMessage({ id: contentLabel })) : (
    <TextField
      fullWidth
      autoFocus
      defaultValue={value}
      inputProps={{ maxLength: 20 }}
      onChange={(event) => setValue(event.target.value)}
      onKeyDown={(e) => handleKeyDown(e)}
    ></TextField>
  );

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '70%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>{intl.formatMessage({ id: title })}</DialogTitle>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <DialogActions>
        <Button id="delete-dialog-cancel" className={classes.deleteDialogCancel} onClick={onCancel}>
          {intl.formatMessage({ id: cancelLabel })}
        </Button>
        <Button className={classes.confirm} onClick={() => onConfirm(value)}>
          {intl.formatMessage({ id: confirmLabel })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MemoDialog;
