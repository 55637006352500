import types from '../actions/_actionTypes';
import initialState from './initialState';
import * as time from '../utils/time';

export default function symbolMetadata(state = initialState.symbols, action) {
  var symbols = {};
  switch (action.type) {
    case types.REQUESTING_SYMBOL:
      symbols = Object.assign({}, state);
      symbols.data = Object.assign({}, state.data);
      symbols.data[action.symbol] = {
        data: action.data,
        isLoading: true,
        didInvalidate: false,
        error: false,
        when: time.timestamp()
      };
      return symbols;
    case types.REQUESTING_SYMBOL_PATTERN:
      symbols = Object.assign({}, state);
      symbols.searches = Object.assign({}, state.searches);
      symbols.searches[action.pattern] = {
        symbols: [],
        isLoading: true,
        didInvalidate: false,
        error: false,
        when: time.timestamp()
      };
      return symbols;
    case types.INVALIDATE_SYMBOL_PATTERN:
      symbols = Object.assign({}, state);
      symbols.searches = Object.assign({}, state.searches);
      symbols.searches[action.pattern] = {
        symbols: [],
        isLoading: false,
        didInvalidate: true,
        error: false,
        when: time.timestamp()
      };
      return symbols;
    case types.RECEIVE_SYMBOL_PATTERN_RESULT:
      symbols = Object.assign({}, state);
      symbols.searches = Object.assign({}, state.searches);
      symbols.searches[action.pattern] = {
        symbols: action.symbols,
        isLoading: false,
        didInvalidate: false,
        error: false,
        when: time.timestamp()
      };
      return symbols;
    case types.RECEIVE_SYMBOL_RESULT:
      symbols = Object.assign({}, state);
      symbols.data = Object.assign({}, state.data);
      symbols.data[action.symbol] = {
        symbol: action.symbol,
        isLoading: false,
        didInvalidate: false,
        error: false,
        when: time.timestamp()
      };
      return symbols;
    case types.RECEIVE_SYMBOL_METADATA:
      symbols = Object.assign({}, state);
      symbols.data = Object.assign({}, state.data);
      symbols.data[action.symbol] = {
        data: action.data,

        // We are also going to add these here
        // to allow us to combine the  RECEIVE_SYMBOL_RESULT
        // and RECEIVE_SYMBOL_METADATA events into 1. This will
        // help us reduce the number of events being processed
        symbol: action.symbol,
        isLoading: false,
        didInvalidate: false,
        error: false,
        when: time.timestamp()
      };
      return symbols;
    case types.SYMBOL_ERROR_REQUESTING_METADATA:
      symbols = Object.assign({}, state);
      symbols.data = Object.assign({}, state.data);
      symbols.data[action.symbol] = {
        isLoading: false,
        didInvalidate: false,
        error: true,
        when: time.timestamp()
      };
      return symbols;
    case types.SYMBOL_ERROR_REQUESTING_PATTERN:
      symbols = Object.assign({}, state);
      symbols.searches = Object.assign({}, state.searches);
      symbols.searches[action.pattern] = {
        symbols: [],
        isLoading: false,
        didInvalidate: false,
        error: true,
        when: time.timestamp()
      };
      return symbols;
    case types.RECEIVE_SYMBOL_NEWS:
      symbols = Object.assign({}, state);
      symbols.data = Object.assign({}, state.data);
      symbols.data[action.symbol] = Object.assign(
        {},
        symbols.data[action.symbol]
      );
      symbols.data[action.symbol].headlines = action.data;
      return symbols;
    case types.NEWS_ERROR_REQUESTING_HEADLINES:
      symbols = Object.assign({}, state);
      symbols.data = Object.assign({}, state.data);
      symbols.data[action.symbol] = Object.assign(
        {},
        symbols.data[action.symbol]
      );
      symbols.data[action.symbol].headlines = Object.assign(
        {},
        {
          isLoading: false,
          didInvalidate: false,
          isError: true,
          when: time.timestamp()
        }
      );
      return symbols;

    default:
      return state;
  }
}
