import { useEffect } from "react";
import { withRouter } from 'react-router';

const ScrollToTop = (props) => {
  // Workaround to get location with React-Router V4
  const { pathname } = (props || {}).location;
  
  /*
    Note: Use the below to get location for React-Router V5 and above
    
    import { useLocation } from 'react-router-dom'
   */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default withRouter(ScrollToTop);