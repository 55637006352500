import React from 'react';
import SpeedDial from '../common/customMaterial/SpeedDial';
import SpeedDialIcon from '../common/customMaterial/SpeedDialIcon';
import SpeedDialAction from '../common/customMaterial/SpeedDialAction';
import { trackNavigation } from '../../utils/analytics';
import { postUserTrade } from '../../utils/userMetrics';

// Much of this code based on examples from https://material-ui.com/lab/speed-dial/
class TradeSpeedDial extends React.Component {
  state = {
    direction: 'up',
    open: false,
    hidden: false,
  };

  handleClick = () => {
    this.setState((state) => ({
      open: !state.open,
    }));
  };

  handleDirectionChange = (event, value) => {
    this.setState({
      direction: value,
    });
  };

  handleHiddenChange = (event, hidden) => {
    this.setState((state) => ({
      hidden,
      // hidden implies !open
      open: hidden ? false : state.open,
    }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  redir = (url) => {
    window.location.href = url;
  };

  render() {
    if (!this.props.show) {
      return null;
    }

    const { direction, hidden, open } = this.state;

    return (
      <SpeedDial
        ariaLabel="SpeedDial"
        className="trade-speed-dial"
        hidden={hidden}
        variant="extended"
        icon={<SpeedDialIcon />}
        label={this.props.label}
        onBlur={this.handleClose}
        onClick={this.handleClick}
        onClose={this.handleClose}
        onFocus={this.handleOpen}
        onMouseEnter={this.handleOpen}
        onMouseLeave={this.handleClose}
        open={open}
        direction={direction}
      >
        {this.props.actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={<div>{action.name}</div>}
            tooltipTitle={action.name}
            onClick={async () => {
              if (action.navigationType) {
                trackNavigation(action.navigationType);
              }

              if (action.postUserTrade) {
                let data = {
                  navigationType: action.navigationType,
                  origin: 'SymbolDetails',
                  symbol: this.props.symbolId,
                  redirectUrl: action.targetUrl,
                  encryptedId: this.props.encryptedId,
                };

                // Since the redirect can be faster than the browser sending the metric to loggly,
                // we will need to wait for it. However, sending the metric should take less than
                // a second to send.
                await Promise.race([
                  postUserTrade(data),
                  new Promise((resolve) => setTimeout(resolve, 400)),
                ]);
              }

              this.redir(action.targetUrl);
            }}
            variant="extended"
            size="large"
            disableTooltip={true}
            className={'trade-speed-dial-button ' + action.className}
          />
        ))}
      </SpeedDial>
    );
  }
}
export default TradeSpeedDial;
