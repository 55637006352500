import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ChartPeriod from './ChartPeriod';
import SingleToggle from './SingleToggle';

class ChartAvgDeviation extends React.Component {
  constructor(props) {
    super(props);
    this.addPeriod = this.addPeriod.bind(this);
  }

  addPeriod(type) {
    this.props.addPeriod(type);
  }

  localize(i18nKey, otherParams) {
    return this.props.intl.formatMessage({ id: i18nKey }, otherParams);
  }

  getPeriods(type) {
    let sma =
      _.find(this.props.indicators, indicator => indicator.type === type) || {};
    return sma.params || [];
  }

  toggleIndicator = event => {
    let value = event.target.checked;
    if (value) {
      this.props.addIndicator(this.props.type);
    } else {
      this.props.deleteIndicator(this.props.type);
    }
  };

  render() {
    let periods = this.props.isIndicatorActive(this.props.type) ? (
      <div className="settings-chart-indicators-container">
        <div className="settings-chart-indicators-properties">
          {this.getPeriods(this.props.type).map((period, i) => {
            return (
              <ChartPeriod
                {...this.props}
                key={i}
                id={i}
                period={period}
                setPeriod={this.props.setPeriod}
                deletePeriod={this.props.deletePeriod}
              />
            );
          })}
        </div>
        <div className="settings-chart-ave-dev">
          <div className="settings-chart-ave-dev-add">
            {this.localize('settings.chart.indicators.add.period')}
          </div>
          <div className="settings-chart-indicators-sma-add">
            <IconButton
              className="add-button"
              onClick={() => this.addPeriod(this.props.type)}
              size="large">
              <AddIcon />
            </IconButton>
          </div>
        </div>
      </div>
    ) : null;
    return (
      <div className="">
        <div className="settings-card-contents">
          <SingleToggle
            title={this.localize(
              'settings.chart.indicators.' + this.props.type + '.title'
            )}
            checked={this.getPeriods(this.props.type).length > 0}
            onChange={this.toggleIndicator}
            skipList={true}
            toggleFavorite={this.props.toggleFavorite}
            type={this.props.type}
            isFavorite={this.props.isFavorite}
          />
        </div>
        {periods}
      </div>
    );
  }
}

export default injectIntl(ChartAvgDeviation);
