import React from 'react';
import SymbolDetailAttributeSection from './SymbolDetailAttributeSection';
import Footer from './Footer';
import SymbolChartContainer from './SymbolChartContainer';
import SymbolNewsSection from './SymbolNewsSection';
import MarginInformationSection from './MarginInformationSection';
import SessionDetailsSection from './SessionDetailsSection';
import { SymbolMatrixContainer } from '../matrix/SymbolMatrixContainer';
import { isJpeq, isUseq } from '../../utils/symbol';
import useSymbolMetadata from '../../utils/hooks/useSymbolMetadata';
import { useSelector } from 'react-redux';
import { userSelector } from '../../selectors/userSelectors';

const SymbolSummary = ({ symbol }) => {
  const symbolMetadata = useSymbolMetadata(symbol);
  const isSymbolEquity = isJpeq(symbolMetadata.assetClass);
  const isSymbolUseq = isUseq(symbolMetadata.assetClass);
  const user = useSelector(userSelector);

  const symbolMatrix = !isSymbolEquity ? null : (
    <SymbolMatrixContainer symbol={symbol} enabled={true} />
  );

  const symbolNews = !isSymbolEquity ? null : (
    <SymbolNewsSection symbol={symbol} />
  );

  const marginInformation = isSymbolEquity ? (
    <MarginInformationSection symbol={symbol} />
  ) : null;

  const sessionDetail = isSymbolEquity ? (
    <SessionDetailsSection symbol={symbol} />
  ) : null;

  return (
    <div>
      <div className="container symbol-details-container">
        <SymbolChartContainer
          symbol={symbol}
        />
        {symbolMatrix}
        <div className="symbol-margin-fundamentals-information-section">
          <SymbolDetailAttributeSection symbol={symbol} />
          {sessionDetail}
          {marginInformation}
        </div>
        {symbolNews}
        <Footer
          user={user}
          mainClassName={'symbol-external-links-section'}
          symbol={symbol}
          isSymbolEquity={isSymbolEquity}
          isSymbolUseq={isSymbolUseq}
        />
      </div>
    </div>
  );
};

export default SymbolSummary;