import React, { ReactComponent } from 'react';
import { injectIntl } from 'react-intl';
import { TextField } from '@mui/material';
import DateTime from '../common/DateTime';
import noteIcon from '../../icons/Icon-memo.svg';

const MEMO_MAX_LENGTH = 200;

/**
 * Note component
 * @param {boolean} open Flag that indicates if memo field is opened
 * @param {memo} memo Memo text to be displayed
 * @param {date} timestamp Timestamp when memo was last updated
 * @param {function} onChange Callback when memo text has changed
 * @param {function} onClick Callback when memo field is clicked
 * @param {function} onKeyPress Callback when a key is pressed on the memo field
 */
class Note extends React.PureComponent {
  getPlaceholder(props) {
    return props.intl.formatMessage({
      id: 'symbol.note.body.placeholder'
    });
  }

  render() {
    let inputPropsStyles = {
      fontSize: 12
    };
    if (this.props.open) {
      inputPropsStyles.height = 130;
    }

    let headerRight = !this.props.timestamp ? (
      <span className="symbol-note-header-right empty">
        <span className="symbol-note-header-right">
          {this.props.intl.formatMessage({
            id: 'symbol.note.header.timestamp.empty'
          })}
        </span>
      </span>
    ) : (
      <span className="symbol-note-header-right value">
        <span className="text">
          <DateTime
            value={new Date(this.props.timestamp)}
            hasYear={true}
            prefix={this.props.intl.formatMessage({
              id: 'symbol.note.header.timestamp.note'
            })}
          />
        </span>
      </span>
    );

    return (
      <div className="symbol-note">
        <div className="symbol-note-header">
          <span className="symbol-note-header-left">
            <img src={noteIcon} className="symbol-note-icon" alt="" />
            {this.props.intl.formatMessage({
              id: 'symbol.note.header.note'
            })}
          </span>
          {headerRight}
        </div>
        <div className="symbol-note-body" onClick={this.props.onClick}>
          <TextField
            placeholder={this.getPlaceholder(this.props)}
            onChange={this.props.onChange}
            fullWidth={true}
            multiline={true}
            rows={this.props.open ? 7 : null}
            maxRows={7}
            value={this.props.memo || ''}
            inputProps={{ maxLength: MEMO_MAX_LENGTH, style: inputPropsStyles }}
            onKeyPress={this.props.onKeyPress}
            onBlur={this.props.onBlur}
            InputLabelProps={{ style: { fontSize: 12 } }}
            variant="standard"
          />
        </div>
        <div className="symbol-note-header">
          <span className="symbol-note-header-right value">
            <span className="text">
              {(this.props.memo || {}).length || 0}/{MEMO_MAX_LENGTH}
            </span>
          </span>
        </div>
      </div>
    );
  }
}
Note = injectIntl(Note);

export default Note;
