export function simpleMovingAverage(values, length) {
  let averages = [];
  let queue = [];
  let sum = 0;
  if (length <= 0) {
    throw new Error('Argument out of range: length');
  }
  values.forEach(value => {
    if (value !== null) {
      queue.push(value);
      sum += value;
    }
    if (queue.length < length) {
      averages.push(null);
    } else {
      averages.push(sum / length);
      sum -= queue.shift();
    }
  });
  return averages;
}

// This function returns a new array with the fixed value
// using the same timestamps from the bars. This function
// is useful since we need the fixed lines to have the same
// timestamps from the bars in order to be displayed
// properly.
export function getFixedLinesData(bars, value) {
  let result = [];
  bars.forEach(bar => {
    result.push([bar[0], value]);
  });

  return result;
}

export function getRelativeStrengthIndexes(bars, length) {
  let lastValue = 0,
    change = 0,
    totalGain = 0,
    averageGain = 0,
    currentGain = 0,
    totalLoss = 0,
    averageLoss = 0,
    currentLoss = 0,
    rs = 0,
    result = [];

  for (let i = 0; i < bars.length; i++) {
    let value = bars[i];
    if (i > 0) {
      change = value - lastValue;
    }

    lastValue = value;
    if (i < length - 1) {
      totalGain += change > 0 ? change : 0;
      totalLoss -= change < 0 ? change : 0;
      result.push(null);
      continue;
    }

    if (i === length - 1) {
      averageGain = totalGain / (i + 1);
      averageLoss = totalLoss / (i + 1);
    } else {
      currentGain = change > 0 ? change : 0;
      currentLoss = change < 0 ? change : 0;
      averageGain = (averageGain * (length - 1) + currentGain) / length;
      averageLoss = (averageLoss * (length - 1) - currentLoss) / length;
    }

    rs = averageGain / (averageLoss === 0 ? Number.MAX_VALUE : averageLoss);
    let index = averageLoss === 0 ? 100 : 100 - 100 / (1 + rs);
    result.push(index);
  }

  return result;
}

// For FiveMinute bars we need to filter out the EOD bars but
// we still need them for calculations. So, this function
// helps to get an array with the same timestamps as the source
// bars but using the new calculated values for the indicator.
export function getFilteredBarValuesWithSameTimestamps(
  bars,
  fullBars,
  values,
  keepNonChartBars
) {
  let result = [];

  // First we locate the index of the first timestamp of the source bar
  // in the fullBars array.
  let index = 0;
  for (let i = 0; i < fullBars.length; i++) {
    if (fullBars[i][0] === bars[0][0]) {
      index = i;
      break;
    }
  }

  // Now, we trim everything before that since we don't need it
  let filteredBars = fullBars.slice(index);
  let filteredValues = values.slice(index);

  // Since we now have the bars that begin with the same timestamp as the source bars
  // we just need to filter out the EOD elements in case there's any
  for (let i = 0; i < filteredBars.length; i++) {
    // The isChartBar flag will be on the last element of elem array.
    // We have two possible types of array:
    // Bar array: [timestamp, open, high, low, close, isChartBar]
    // Volume array: [timestamp, volume, isChartBar]
    let length = filteredBars[i].length;

    // If the last element is not true, then it's an EOD element
    let isChartBar = filteredBars[i][length - 1];
    if (!keepNonChartBars && !isChartBar) {
      continue;
    }

    let elem = [filteredBars[i][0], filteredValues[i]];

    // If this flag is set to true, we save the isChartBar since we are going
    // to need it later to remove unused bars.
    if (keepNonChartBars) {
      elem.push(isChartBar);
    }
    result.push(elem);
  }

  return result;
}
