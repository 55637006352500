import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';

import analyticsMiddleware from './analyticsMiddleware';
import { actionLoggerMiddleware } from './actionLoggerMiddleware';
import { faucet } from './faucet';

let middleware = [
  thunkMiddleware.withExtraArgument(faucet),
  analyticsMiddleware,
  actionLoggerMiddleware
];
if (process.env.REACT_APP_ENABLE_LOGGER_MIDDLEWARE === 'true') {
  let loggerMiddleware = createLogger();
  middleware = [...middleware, loggerMiddleware];
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );
}
