import React, { useEffect } from 'react';
import _ from 'lodash';
import WarningIcon from '@mui/icons-material/Warning';
import { useSelector } from 'react-redux';
import { isJpeq } from '../../utils/symbol';
import useActions from '../../utils/hooks/useActions';
import { fetchFundamentals as fetchFundamentalsAction } from '../../actions/market';
import useSymbolMetadata from '../../utils/hooks/useSymbolMetadata';

const getFormatedSymbol = (symbolRoot) => {
  if (symbolRoot.length === 4) {
    symbolRoot += '0';
  }

  return symbolRoot;
}

const buildRestrictionUrl = (dm, symbolRoot, attrSrcKey) => {
  return `https://${dm}.monex.co.jp/pc/servlet/ITS/mrgrestriction/StkDescInfoSearchResult?searchMode=searchBydscrCd&dscrCd=${getFormatedSymbol(
    symbolRoot
  )}&attrSrcKey=${attrSrcKey}`;
}

const RestrictionIcon = () => (
  <div style={{ width: 44, height: 44 }}>
    <WarningIcon color="error" style={{ paddingBottom: 5 }} />
  </div>
);

const SymbolRestrictions = ({ className, symbol }) => {
  // Redux

  // User
  const user = useSelector((state) => state.user || {});
  
  // Metadata
  const symbolData = useSymbolMetadata(symbol);
  const assetClass = symbolData.assetClass;
  const isSymbolEquity = isJpeq(assetClass);

  // Fundamentals
  const [fetchFundamentals] = useActions([fetchFundamentalsAction], []);
  const hasRestrictionMargin = useSelector(
    (state) => {
      const allFundamentals = (state.market || {}).fundamentals || {};
      const symbolFundamentals = allFundamentals[symbol] || {};
      const fundamentals = (symbolFundamentals.data || {}).fundamentals || {};
      return _.get(fundamentals, 'tradingRestriction.value') === 1;
    }
  );

  useEffect(() => {
    fetchFundamentals(symbol);
  }, [symbol, fetchFundamentals]);
  
  if (!isSymbolEquity || !hasRestrictionMargin) {
    return null;
  }

  // NISA accounts do not get the restriction URL
  if (user.dm === 'trade') {
    return (
      <div style={{display: 'inline-block'}}>
        <RestrictionIcon />;
      </div>
    );
  }

  return (
    <div style={{display: 'inline-block'}}>
      <a
        className={className}
        href={buildRestrictionUrl(
          user.dm,
          symbolData.root,
          user.attrSrcKey
        )}
      >
        <RestrictionIcon />
      </a>
    </div>
  );
}

export default SymbolRestrictions;