import { Transition } from 'react-transition-group';
import React, { useEffect, useRef, useState } from 'react';

const defaultStyle = (duration, delay) => {
  return {
    transition: `background-color ${duration}ms ${delay}ms cubic-bezier(0,1,0.1,1)`,
    backgroundColor: 'rgba(0, 255, 255, 255)'
  };
};

const transitionStyles = {
  entering: { backgroundColor: 'rgba(0, 255, 255, 255)' },
  entered: { backgroundColor: 'rgba(0, 255, 255, 255)' },
  exiting: { backgroundColor: 'rgba(0, 255, 255, 0)' },
  exited: { backgroundColor: 'rgba(0, 255, 255, 0)' }
};

export const BackgroundFlash = ({
  inValue,
  duration = 400,
  delay = 0,
  children
}) => {
  const [value, setValue] = useState(null);
  const [changed, setChanged] = useState(false);
  const nodeRef = useRef(null);

  useEffect(() => {
    if (value !== inValue) {
      setChanged(true);
    }

    setTimeout(() => {
      setChanged(false);
    }, duration + delay);

    setValue(inValue);
  }, [delay, duration, inValue, value, setChanged, setValue]);

  return (
    <Transition nodeRef={nodeRef} in={changed} timeout={duration}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyle(duration, delay),
            ...transitionStyles[state]
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};
