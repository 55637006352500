import React from 'react';
import GA from 'react-ga';

// Loading Google Analytics when running jest tests can cause issues,
// so this has been updated to not load when running in test environments.
// The error that caused this was:
// TypeError: Cannot read property 'parentNode' of undefined
// Read more about this at https://github.com/react-ga/react-ga/issues/322
if (process.env.NODE_ENV !== 'test') {
  GA.initialize(process.env.REACT_APP_MOBILE_GOOGLE_ANALYTICS_APP_ID);
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    if (page && page !== 'undefined') {
      // here will will parse out the symbol if it is sent.
      // We still, however want to track the symbol search page
      let pageParts = page.split('/');
      if (pageParts[1] === 'symbol' && pageParts[2] !== 'search') {
        page = '/symbol';
      }

      GA.ga('set', 'page', page);
      GA.ga('send', 'pageview');
    }
  };

  function getPageFromProps(props) {
    let page =
      props.url ||
      props.path ||
      (props.location && props.location.pathname
        ? props.location.pathname
        : 'undefined');
    return page.replace(/\d+$/, '');
  }

  const GoogleTrackedComponent = class extends React.PureComponent {
    componentDidMount() {
      trackPage(getPageFromProps(this.props));
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = getPageFromProps(this.props);
      const nextPage = getPageFromProps(nextProps);

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return GoogleTrackedComponent;
};

export function event(category, action) {
  let event = {
    category: category,
    action: action
  };

  GA.event(event);
}

export function trackNavigation(navigationType) {
  let event = {
    category: 'navigation',
    action: navigationType
  };

  GA.event(event);
}

export default withTracker;
