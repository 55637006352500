import types from './_actionTypes';
import { getGalaxyMarketboards } from './marketboards';
import Logger from '../utils/logger';
import fetchRetry from '../utils/fetchRetry';
import { handleErrors } from '../utils/common';
import { fetchFeatureFlagsIfNeeded } from './featureFlags';

// eslint-disable-next-line
const messageLinkRegex = /\[([^\[\]]+)\]\(([^))]+)\)/;

function getRedirectUrl() {
  return `${process.env.REACT_APP_MONEX_REDIRECT_URL}`;
}

function getVerifyUrl() {
  return `https://${process.env.REACT_APP_INSIGHT_API_DOMAIN}/auth/verify`;
}

export function getMessagesMockParam() {
  const urlParams = new URLSearchParams(window.location.search);
  let globalMessageMockParam = '';
  if (urlParams.has('type')) {
    globalMessageMockParam = `?type=${urlParams.get('type')}`;
    for (const key of urlParams.keys()) {
      if (['size', 'page', 'dismissable', 'percent'].includes(key)) {
        globalMessageMockParam += `&${key}=${urlParams.get(key)}`;
      }
    }
  }
  return globalMessageMockParam;
}

function getMessagesUrl() {
  let messagesMockParam = getMessagesMockParam();
  return `https://${process.env.REACT_APP_QUAY_DOMAIN}/messages/active${messagesMockParam}`;
}

export function setToken(token) {
  return { type: types.SET_TOKEN, token: token };
}

export function setUser(userId) {
  return { type: types.SET_USER, userId: userId };
}

function getParameter(response, param) {
  if (response.data && response.data.payload && response.data.payload[param]) {
    return response.data.payload[param];
  } else {
    return '';
  }
}

function checkValid(response) {
  let valid = false;
  if (response.ok) {
    valid = true;
  }

  // anything we need to persist from the token, add here
  return {
    type: types.USER_VALIDATE_TOKEN,
    isValid: valid,
    attrSrcKey: getParameter(response, 'attrSrcKey'),
    dm: getParameter(response, 'dm'),
    encryptedId: getParameter(response, 'encryptedId'),
    rf: getParameter(response, 'rf'),
    rz: getParameter(response, 'rz'),
    hash: getParameter(response, 'hash'),
    ifisHash: getParameter(response, 'ifisHash'),
    internal: getParameter(response, 'internal')
  };
}

function getPositions() {
  return getGalaxyMarketboards();
}

export function validateToken() {
  return (dispatch, getState) => {
    let user = getState().user;
    if (!user.id || !user.token) {
      window.location.href = getRedirectUrl();
      return;
    }

    var req = {
      method: 'get',
      headers: { authorization: user.token }
    };

    return fetch(getVerifyUrl(), req)
      .then(handleErrors)
      .then((response) => response.json())
      .then((response) => dispatch(checkValid(response)))
      .then(() => dispatch(getPositions()))
      .then(() => dispatch(fetchGlobalMessages()))
      .then(() => {
        let cleanedUrl = cleanUrl(window.location.search, window.location.href);
        window.history.replaceState(null, '', cleanedUrl);
      })
      .then(() => dispatch(fetchFeatureFlagsIfNeeded()))
      .catch((err) => {
        dispatch(errorValidatingToken(err));
      });
  };

  function errorValidatingToken(error) {
    return {
      type: types.USER_ERROR_VALIDATING_TOKEN,
      error: error,
      errorCode: 'E0002'
    };
  }
}

function applyMessageKey(message) {
  // However unlikely this may be, it's possible that the message ID is
  // re-used to issue another global message. Therefore use both the ID and
  // the timestamp to compose the message key.
  if (!!message) {
    message.Key = `${message.id}-${message.timestamp}`;
  }
  return message;
}

// The message values here will be used to replace the placeholders of the message string.
// For example: `{symbol} was not found` -> symbol: 'JP:8698-TS'.
// The messageValues is a JSON object with the same placeholders as in the message
export function addLocalMessage(translationId, messageValues) {
  let message = {
    Id: 'local',
    TranslationId: translationId,
    LastActive: Date.now(),
    Key: `local-${translationId}`,
    Values: messageValues || {}
  };
  return {
    type: types.ADD_LOCAL_MESSAGE,
    message: message
  };
}

export function fetchGlobalMessages() {
  let user;
  return (dispatch, getState) => {
    user = getState().user;
    if (user.id && user.tokenIsValid === true && user.token) {
      let req = {
        method: 'get'
      };
      return fetchRetry(getMessagesUrl(), req)
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          if (response.ok && response.data && response.data.messages) {
            let data = filterPopsicleMessages(response.data.messages.data);
            dispatch(
              receiveMessages(applyMessageKeyOnList(parseMessageLinks(data)))
            );
          }
        })
        .catch((err) => {
          Logger.error({
            errorCode: 'E0007',
            error: err,
            message: 'Error fetching global messages'
          });
        });
    }
    return Promise.resolve();
  };

  function filterPopsicleMessages(messages) {
    let result = [];
    for (var i = 0; i < messages.length; i++) {
      var message = messages[i];
      var data = message.data || {};
      // When the message does not have the `component` property it means that
      // it is a global message. Otherwise, the name of the component is specified.
      // In case the component is specified, we only want to display the ones with
      // the component equal to `popsicle`.
      if (!data.component || data.component.toLowerCase() === 'popsicle') {
        result.push(message);
      }
    }

    return result;
  }

  function filterDismissedMessages(data) {
    return (data || []).filter(
      (message) => (user.dismissedMessageIds || []).indexOf(message.Key) === -1
    );
  }

  function parseMessageLink(message) {
    if (!!message) {
      let m;
      // Check for any and all link patterns in the message,
      // using a while loop to find them all
      while ((m = messageLinkRegex.exec(message.data.message))) {
        // Check it's not null for sanity
        if (m) {
          // m[0] is the full match, m[1] is the first
          // matching group (name), m[2] is the second (link).
          message.data.message = message.data.message.replace(
            m[0],
            `<a href="${m[2]}">${m[1]}</a>`
          );
        }
      }
    }
    return message;
  }

  function parseMessageLinks(data) {
    return (data || []).map(parseMessageLink);
  }

  function applyMessageKeyOnList(data) {
    return (data || []).map(applyMessageKey);
  }

  function receiveMessages(response) {
    return {
      type: types.RECEIVE_GLOBAL_MESSAGES,
      globalMessages: filterDismissedMessages(response)
    };
  }
}

export function dismissLocalMessage(translationId) {
  return {
    type: types.DISMISS_LOCAL_MESSAGE,
    Key: `local-${translationId}`
  };
}

export function dismissGlobalMessage(messageId) {
  return {
    type: types.DISMISS_GLOBAL_MESSAGE,
    messageId: messageId
  };
}

export function dismissMessage(Key) {
  return (dispatch, getState) => {
    return Promise.resolve().then(() => {
      if (!!Key && Key.indexOf('local-') === 0) {
        dispatch(dismissLocalMessage(Key.substr(6)));
      } else {
        dispatch(dismissGlobalMessage(Key));
      }
    });
  };
}

export function setChartStyle(value) {
  return {
    type: types.SET_CHART_STYLE,
    value: value
  };
}

export function setChartMode(value) {
  return {
    type: types.SET_CHART_MODE,
    value: value
  };
}

export function setDisplayTooltip(value) {
  return {
    type: types.SET_DISPLAY_TOOLTIP,
    value: value
  };
}

export function setChartPeriod(value) {
  return {
    type: types.SET_CHART_PERIOD,
    value: value
  };
}

export function setIndicatorParameters(indicatorType, value) {
  return {
    type: types.SET_INDICATOR_PARAMETERS,
    value: value,
    indicatorType: indicatorType
  };
}

export function setIndicators(value) {
  return {
    type: types.SET_INDICATORS,
    value: value
  };
}

export function setDisabledIndicators(value) {
  return {
    type: types.SET_DISABLED_INDICATORS,
    value: value
  };
}

export function setComparisonSymbols(value) {
  return {
    type: types.SET_COMPARISON_SYMBOLS,
    value: value
  };
}

export function setIncludeJpeq(value) {
  return {
    type: types.SET_INCLUDE_JPEQ,
    value: value
  };
}

export function setIncludeUseq(value) {
  return {
    type: types.SET_INCLUDE_USEQ,
    value: value
  };
}

export function setIncludeSupplementals(value) {
  return {
    type: types.SET_INCLUDE_SUPPLEMENTALS,
    value: value
  };
}

export function setIncludeIndustry(value) {
  return {
    type: types.SET_INCLUDE_INDUSTRY,
    value: value
  };
}

export function cleanUrl(search, href) {
  const urlParams = new URLSearchParams(search);
  // remove unwanted search params
  let cleanUrl = href
    .replace(`userId=${urlParams.get('userId')}`, '')
    .replace(`token=${urlParams.get('token')}`, '');

  // remove unnecessary characters (extra & and ?)
  return cleanUrl
    .replace(/(&)\1{1,}/, '&')
    .replace('&#', '#')
    .replace('?#', '#');
}
