import React from 'react';
import { injectIntl } from 'react-intl';

class ScanItemRangeDetail extends React.PureComponent {
  render() {
    return (
      <span>{this.getScanCriteria(this.props.id, this.props.values)}</span>
    );
  }

  getString(value, id) {
    if (value.length === 0) {
      return this.props.intl.formatMessage({ id: 'scan.criteria.no.settings' });
    }
    return this.props.intl.formatMessage({
      id: `scan.criteria.${id}.${value}`
    });
  }

  getStringWithComma(values, id) {
    if (values.length === 0) {
      return this.props.intl.formatMessage({ id: 'scan.criteria.no.settings' });
    }
    return values
      .map(e => {
        return this.props.intl.formatMessage({
          id: `scan.criteria.${id}.${e}`
        });
      })
      .join(this.props.intl.formatMessage({ id: 'common.comma' }));
  }

  getSMACrossover(values) {
    if (!values[1]) {
      return this.props.intl.formatMessage({ id: 'scan.criteria.anytime' });
    }
    return `${this.props.intl.formatMessage(
      {
        id: `scan.criteria.range.smacrossover`
      },
      {
        '0': values[1]
      }
    )}`;
  }

  getRangeString(unitKey, values) {
    let lowerBoundExists = typeof values[0] == 'number';
    let upperBoundExists = typeof values[1] == 'number';

    if (!lowerBoundExists && !upperBoundExists) {
      return this.props.intl.formatMessage({ id: 'scan.criteria.no.settings' });
    }
    let unit = this.props.intl
      .formatMessage({
        id: `scan.criteria.range.${unitKey}`
      })
      .trim();
    let tempString;
    // if only upper-bound is inputted
    if (lowerBoundExists && !upperBoundExists) {
      tempString = this.props.intl.formatMessage(
        {
          id: 'scan.criteria.range.upper'
        },
        {
          '0': values[0],
          u: unit
        }
      );
      // if only lower-bound is inputted
    } else if (!lowerBoundExists && upperBoundExists) {
      tempString = this.props.intl.formatMessage(
        {
          id: 'scan.criteria.range.lower'
        },
        {
          '1': values[1],
          u: unit
        }
      );
    } else {
      let formatMessageParams = { u: unit };
      for (let i = 0; i < values.length; i++) {
        formatMessageParams[i] = values[i];
      }
      tempString = this.props.intl.formatMessage(
        {
          id: 'scan.criteria.range'
        },
        formatMessageParams
      );
    }
    return tempString;
  }

  getScanCriteria(id, values) {
    switch (id) {
      case 'Exchange':
      case 'IchimokuCloud':
        return this.getString(values, id);
      case 'Sector':
      case 'MarginType':
      case 'SecurityType':
        return this.getStringWithComma(values, id);
      case 'SMACrossover_5_25':
      case 'SMACrossover_25_5':
        return this.getSMACrossover(values);
      case 'MarginTurnoverPeriod':
        return this.getRangeString('days', values);
      case 'AnalystCount':
      case 'Rating':
        return this.getRangeString('rating', values);
      case 'BPS':
      case 'DividendPerShare':
      case 'DividendPerShareForecast':
      case 'EarningsPerShare':
      case 'MarginLendingFee':
      case 'PredictedEarningsPerShare':
      case 'InvestmentAmount':
        return this.getRangeString('yen', values);
      case 'NetAssets':
      case 'PredictedNetProfit':
      case 'PredictedNetSales':
      case 'PredictedOrdinaryProfit':
      case 'TotalAssets':
      case 'MarketCap':
      case 'NetProfit':
      case 'NetSales':
      case 'OrdinaryProfit':
        return this.getRangeString('billion', values);
      case 'InventoryTurnoverRate':
      case 'MarginBuySellRatio':
      case 'PBR':
      case 'PredictedPriceEarningsRatio':
      case 'PredictedPriceSalesRatio':
      case 'PriceCashFlowRatio':
      case 'PriceEarningsRatio':
      case 'PriceSalesRatio':
      case 'ReceivablesTurnoverRate':
        return this.getRangeString('multiple', values);
      case 'TotalScore':
      case 'FiscalHealthScore':
      case 'GrowthScore':
      case 'MarketTrendScore':
      case 'SizeScore':
      case 'TechnicalScore':
      case 'ValueScore':
        return this.getRangeString('points', values);
      case 'ChangeInSharesBoughtOnMargin':
      case 'ChangeInSharesSoldOnMargin':
      case 'SharesBoughtOnMargin':
      case 'SharesSoldOnMargin':
      case 'LotSize':
        return this.getRangeString('shares', values);
      case 'PredictedOrdinaryProfitChange':
      case 'CapitalAdequacyRatio':
      case 'CurrentRatio':
      case 'DebtEquityRatio':
      case 'DividendOnEquity':
      case 'DividendPayoutRatio':
      case 'DividendYield':
      case 'DividendYieldForecast':
      case 'PredictedNetProfitChange':
      case 'PredictedNetSalesChange':
      case 'QuickRatio':
      case 'ReturnOnAssets':
      case 'ReturnOnEquity':
      case 'SMADeviation_130':
      case 'SMADeviation_25':
      case 'SMADeviation_5':
      case 'SMADeviation_65':
      case 'TargetPriceChange':
      case 'TargetPriceVsCurrentPrice':
      case 'NetProfitChange':
      case 'NetSalesChange':
      case 'OrdinaryProfitChange':
      case 'OrdinaryProfitToNetSalesRatio':
      case 'OrdinaryProfitVsPredictedProgress':
      case 'StochasticFast_9':
      case 'StochasticSlow_9':
      case 'RSI_14':
      case 'ATR_14':
      case 'MFI_14':
      case 'RCI_10_3':
      case 'CCI_14_9':
        return this.getRangeString('percent', values);
      default:
        return '-';
    }
  }
}

export default injectIntl(ScanItemRangeDetail);
