import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const NewScansAddCriteriaButton = props => {
  return (
    <div className="actions add-scan-criteria">
      <Link
        to="#"
        onClick={() => props.handleClickOpen()}
        className={'add-criteria-link'}
      >
        <div className="margin button btn-cmn scan result add-criteria">
          <div className={'add-criteria-label'}>
            <div className={'white-svg-icon add-criteria-icon'}>
              <AddIcon
                color={'inherit'}
                fontSize={'inherit'}
                // Even using !important in CSS will not make the
                // below styles stick, so I've put them here.
                // If someone else has better CSS, please go ahead.
                style={{ width: 20, height: 20, color: '#FFFFFF' }}
              />
            </div>
            <div className={'white-svg-label'}>
              &nbsp;
              {props.labelText}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default NewScansAddCriteriaButton;
