const { simpleMovingAverage } = require('./utils');

const configuration = [
  {
    tooltipCaption: 'prime',
    color: '#32afed',
    symbol: 'JP:$0000-TS',
    gainersSymbol: 'JP:$ADVP-TS',
    losersSymbol: 'JP:$DECLP-TS',
    zIndex: 4
  },
  {
    tooltipCaption: 'standard',
    color: '#ed7291',
    symbol: 'JP:$8802-TS',
    gainersSymbol: 'JP:$ADVS-TS',
    losersSymbol: 'JP:$DECLS-TS',
    zIndex: 3
  },
  {
    tooltipCaption: 'growth',
    color: '#008c46',
    symbol: 'JP:$8801-TS',
    gainersSymbol: 'JP:$ADVG-TS',
    losersSymbol: 'JP:$DECLG-TS',
    zIndex: 2
  }
];

// Create a mapping for each of the bar collections that maps from
// the bar timestamp to the bar itself.
function getBarsDictionary(bars) {
  let result = {};
  for (let i = 0; i < bars.length; i++) {
    let b = bars[i];
    result[b[0]] = b;
  }

  return result;
}

// This function returns bars where both gainers and losers have the
// same timestamp
function getPairedBars(gainersBars, losersBars) {
  // Create a mapping for each of the bar collections that maps from
  // the bar timestamp to the bar itself.
  let advanceLookup = getBarsDictionary(gainersBars);
  let declineLookup = getBarsDictionary(losersBars);

  let result = [];
  // We want to add a bar only if we have a gainers and losers bar with
  // the same timestamp
  let keys = Object.keys(advanceLookup);
  for (let i = 0; i < keys.length; i++) {
    let timestamp = keys[i];

    // Bar structure [timestamp, open, high, low, close, isChartBar]
    let gainersBar = advanceLookup[timestamp];
    let losersBar = declineLookup[timestamp];
    if (gainersBar && losersBar) {
      // New bar structure [timestamp, gainersClose, losersClose, isChartBar]
      result.push([gainersBar[0], gainersBar[4], losersBar[4], gainersBar[5]]);
    }
  }

  return result;
}

// It was noticed that the timestamps of the indexes used to calculate this
// indicator does not necessarily match with the timestamps of some Forex and
// Indexes when the bar size is larger than 5 minutes (eg: daily and
// monthly). However, we need the timestamp of the bars to match with each other
// in order to see which bars can be drawn on the chart.
// For that reason, in order to match those timestamps, we need to
// calculate the offset between these symbol timestamps and the
// indexes timestamps used to calculate this indicator. This is required
// For example, with monthly bars for USDJPY we have this difference:
// 1362063660000 ->	3/01/2013	00:01 (JP:$ADVE-TS, JP:$DECLE-TS, etc)
// 1362117540000 ->	3/01/2013	14:59 (USDJPY)
function getTimestampOffset(bars, pairedBars) {

  // Not enough bars to calculate the offset
  if (bars.length <= 1) {
    return 0;
  }

  // We first see if we need to calculate the offset or not by checking if
  // the bars are larger than 5 minutes. In this case, if the bars have a
  // difference of 5 minutes, there's no difference in the timestamps
  // between the bars.
  // 300000 = 5 * 60 * 1000
  if (bars[1][0] - bars[0][0] <= 300000) {
    return 0;
  }

  // However, if the bars are daily and monthly, it could be the case that the
  // timestamps won't match because of a difference in the hours and minutes.
  // So, we try to see how much time difference there is between them and return
  // that value.
  let barDate = new Date(bars[0][0]);
  let pairedBarDate = new Date(pairedBars[0][0]);
  let hoursDiff = barDate.getHours() - pairedBarDate.getHours();
  let minutesDiff = barDate.getMinutes() - pairedBarDate.getMinutes();
  if (hoursDiff === 0 && minutesDiff === 0) {
    return 0;
  }

  return hoursDiff * 60 * 60 * 1000 + minutesDiff * 60 * 1000;
}

// This function calculates the points where both gainers and losers
// have values
function getPoints(pairedBars, period, timestampOffset) {
  // Bar structure [timestamp, gainersClose, losersClose, isChartBar]
  let advanceBars = pairedBars.map((b) => b[1]);
  let advanceSma = simpleMovingAverage(advanceBars, period);

  let declineBars = pairedBars.map((b) => b[2]);
  let declineSma = simpleMovingAverage(declineBars, period);

  // We create a JSON object instead of an array to lookup faster bars with
  // a specific timestamp later.
  let result = {};
  for (let i = 0; i < pairedBars.length; i++) {
    let advanceBar = advanceSma[i];
    let declineBar = declineSma[i];
    // Ignore non numeric values, if decline is 0 (because of division
    // by 0), and if this is a EOD bar
    if (
      typeof advanceBar !== 'number' ||
      typeof declineBar !== 'number' ||
      declineBar === 0 ||
      !pairedBars[i][3]
    ) {
      continue;
    }

    let timestamp = pairedBars[i][0] + timestampOffset;
    let value = (100 * advanceBar) / declineBar;
    result[timestamp] = value;
  }

  return result;
}

// We need to create an array with all the timestamps of the indexes
// that overlap with source bars timestamps. The rest we ignore it.
function getBarsWithSameTimestamps(bars, values) {
  let result = [];
  let lastValue = null;

  // This function assumes that the timestamps of the bars are ordered.
  for (let i = 0; i < bars.length; i++) {
    let timestamp = bars[i][0];
    let pairedBarValue = values[timestamp];
    if (typeof pairedBarValue !== 'number') {
      // Since we could have gaps on the chart, we copy the last value that
      // was used in order to fill that gap in the chart.
      pairedBarValue = lastValue;
    }

    result.push([timestamp, pairedBarValue]);
    lastValue = pairedBarValue;
  }

  return result;
}

function getAdvDeclineRatios(
  bars,
  configurationElem,
  period,
  additionalSymbolsData
) {
  if (
    !additionalSymbolsData ||
    Object.keys(additionalSymbolsData).length === 0
  ) {
    return null;
  }

  let gainersBars = additionalSymbolsData[configurationElem.gainersSymbol] ?? [];
  let losersBars = additionalSymbolsData[configurationElem.losersSymbol] ?? [];
  if (gainersBars.length === 0 || losersBars.length === 0) {
    return null;
  }

  let pairedBars = getPairedBars(gainersBars, losersBars);
  let timestampOffset = getTimestampOffset(bars, pairedBars);
  let values = getPoints(pairedBars, period, timestampOffset);
  return getBarsWithSameTimestamps(bars, values);
}

function getAdvDeclineRatiosSeries(
  periods,
  bars,
  fullBarData,
  localize,
  fullVolume,
  additionalSymbolsData
) {
  if (!fullBarData || fullBarData.length === 0) {
    return [];
  }

  let result = [];
  let period = periods[0];

  configuration.forEach((configurationElem) => {
    result.push({
      name: localize({
        id:
          'symbol.details.chart.tooltip.advDeclineRatios.' +
          configurationElem.tooltipCaption
      }),
      id: 'advDeclineRatios-' + configurationElem.tooltipCaption,
      type: 'line',
      color: configurationElem.color,
      yAxis: 'advDeclineRatios',
      data: getAdvDeclineRatios(
        bars,
        configurationElem,
        period,
        additionalSymbolsData
      ),
      tooltip: { yDecimals: 2 },
      zIndex: configurationElem.zIndex
    });
  });

  return result;
}

module.exports = {
  getSeries: getAdvDeclineRatiosSeries,
  getAdvDeclineRatioSymbols: function () {
    let symbols = [];
    configuration.forEach((elem) => {
      symbols.push(elem.gainersSymbol);
      symbols.push(elem.losersSymbol);
    });

    return symbols;
  },
  // Added mainly for unit testing
  getBarsDictionary: getBarsDictionary,
  getPairedBars: getPairedBars,
  getTimestampOffset: getTimestampOffset,
  getPoints: getPoints,
  getBarsWithSameTimestamps: getBarsWithSameTimestamps,
  getAdvDeclineRatios: getAdvDeclineRatios
};
