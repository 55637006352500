import types from '../actions/_actionTypes';
import initialState from './initialState';

export default function stream(state = initialState.streams, action) {
  var messages = [];
  var channels = {};
  switch (action.type) {
    case types.RECEIVE_MESSAGE:
      channels = state.channels ? Object.assign({}, state.channels) : {};
      channels[action.channel] = action.snapshot;
      if (typeof(channels[action.channel]) === 'object') {
        channels[action.channel].when = new Date().getTime();
      }

      return Object.assign({}, state, {
        //messages: messages,
        channels: channels
      });

    case types.RECEIVE_STATUS:
      messages = state.messages.slice(-10).concat({
        text: action.text,
        when: action.when
      });
      return Object.assign({}, state, {
        messages: messages,
        status: {
          connected: action.data.connected,
          connecting: false
        }
      });

    case types.CONNECTING:
      messages = state.messages.concat({
        text: 'CONNECTING',
        when: action.when
      });
      return Object.assign({}, state, {
        messages: messages,
        status: {
          connected: false,
          connecting: true
        }
      });

    default:
      return state;
  }
}
