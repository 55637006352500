import { useState, useEffect } from 'react';
import _ from 'lodash';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const defaultDimensions = getWindowDimensions();

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(defaultDimensions);

  useEffect(() => {
    const handleResize = _.debounce(() => {
      setWindowDimensions(getWindowDimensions());
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
