import React, { useEffect, useState } from 'react';
import { fetchAllBarsIfNeeded } from '../../actions/bars';
import SymbolHistoricalListContainer from './historical/SymbolHistoricalListContainer';
import SymbolHistoricalListLegend from './historical/SymbolHistoricalListLegend';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useLocalize from '../../utils/hooks/useLocalize';
import useActions from '../../utils/hooks/useActions';
import { start, stop } from '../../actions/stream';
import { useSelector } from 'react-redux';
import useSymbolMetadata from '../../utils/hooks/useSymbolMetadata';
import { isJpeq } from '../../utils/symbol';
import { useCallback } from 'react';
import { selectChannel } from '../../selectors/channelSelectors';
import { useMemo } from 'react';

const SymbolHistorical = ({ symbol }) => {
  const [startStream] = useActions([start], []);
  const [stopStream] = useActions([stop], []);
  const [fetchBars] = useActions([fetchAllBarsIfNeeded], []);

  const localize = useLocalize();

  const [ barSize, setBarSize ] = useState('1day');

  const symbolBars = useSelector(
    (state) => (state.bars || {}) || {}
  );
  const symbolMetadata = useSymbolMetadata(symbol);
  const isSymbolEquity = isJpeq(symbolMetadata.assetClass);

  useEffect(() => {
    if (!symbol) {
      return;
    }

    startStream(`${symbol}/quote`);
    fetchBars(symbol);

    return () => {
      stopStream(`${symbol}/quote`);
    };
  }, [symbol, fetchBars, startStream, stopStream]);

  const channel = useMemo(() => `${symbol}/quote`, [symbol]);
  const quote = useSelector((state) => selectChannel(state, channel));

  const changeBarSize = useCallback((event) => {
    let value = event.target.value;
    fetchBars(symbol, value);
    setBarSize(value);
  }, [symbol, fetchBars, setBarSize]);

  // Consider loading until we have all the data.
  let isLoading, isError, noData, bars;
  if (
    !symbol ||
    !barSize ||
    !symbolBars ||
    !symbolBars[`${symbol}/${barSize}`] ||
    !!symbolBars[`${symbol}/${barSize}`].isLoading
  ) {
    isLoading = true;
  } else if (symbolBars[`${symbol}/${barSize}`].isError) {
    isLoading = false;
    isError = true;
  } else {
    isLoading = false;
    isError = false;

    // If the bars were fetched but we have no data, then set the noData flag
    // to true, so the UI can be updated accordingly.
    if (!symbolBars[`${symbol}/${barSize}`].data) {
      noData = true;
    } else {
      // Get a new object
      bars = JSON.parse(
        JSON.stringify(symbolBars[`${symbol}/${barSize}`].data.bars)
      );

      // Bars come oldest->newest, we want the opposite
      bars = bars.reverse();
    }
  }

  return (
    <div className={'symbol-details-historical-container'}>
      <div className={'symbol-historial-selectors'}>
        <span>{localize('symbol.details.historical.time.select')}</span>
        <span className="symbol-historial-period-span">
          <Select
            labelId="settings-chart-style-select"
            id="settings-chart-style-select"
            value={barSize || '1day'}
            onChange={changeBarSize}
            className="symbol-historial-period-select"
            variant="standard"
          >
            <MenuItem value="5min">
              {localize('symbol.details.historical.bar.size.5min')}
            </MenuItem>
            <MenuItem value="1hour">
              {localize('symbol.details.historical.bar.size.1hour')}
            </MenuItem>
            <MenuItem value="1day">
              {localize('symbol.details.historical.bar.size.day')}
            </MenuItem>
            <MenuItem value="1week">
              {localize('symbol.details.historical.bar.size.week')}
            </MenuItem>
            <MenuItem value="1month">
              {localize('symbol.details.historical.bar.size.month')}
            </MenuItem>
            <MenuItem value="1quarter">
              {localize('symbol.details.historical.bar.size.quarter')}
            </MenuItem>
          </Select>
        </span>
      </div>
      <SymbolHistoricalListLegend localize={localize} />
      <SymbolHistoricalListContainer
        barSize={barSize}
        bars={bars}
        isLoading={isLoading}
        isError={isError}
        noData={noData}
        symbolId={symbol}
        precision={symbolMetadata.precision}
        isSymbolEquity={isSymbolEquity}
        previousVolume={quote.previousVolume}
      />
    </div>
  );
};

export default SymbolHistorical;