import _, { isEqual } from 'lodash';
import { createSelector } from 'reselect';

export const ticksSelector = (channels, symbol) => {
  return channels[`${symbol}/tick`] || {};
};

const isChannelDataEqual = (a, b) => {
  // use `.when` property to compare if data has changed, if available.
  if (a.when !== undefined && b.when !== undefined) {
    // Check if `when` property is equal
    // If `when` are equal, do deep equality check.
    const equal = a.when === b.when && isEqual(a, b);
    return equal;
  }
  // otherwise, use selector default equality check
  return a === b;
};

const getChannelByName = (state, name) => {
  const data = ((state.stream || {}).channels || {})[name] || {};
  // need to do shallow copy as reselect expects immutable objects
  // and treats data as same if they are reference equal
  return Object.assign({}, data);
};

export const selectChannel = createSelector(
  getChannelByName,
  (channel) => channel,
  {
    memoizeOptions: {
      equalityCheck: isChannelDataEqual,
      // maxSize is selector cache size
      maxSize: 20
    }
  }
);

export const createChannelSelector = (name) =>
  createSelector(
    (state) => getChannelByName(state, name),
    (channel) => channel,
    {
      memoizeOptions: {
        equalityCheck: isChannelDataEqual,
        // maxSize is selector cache size
        maxSize: 20
      }
    }
  );