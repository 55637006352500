const isWithinViewPort = (left, top, width, height) => {
  const viewPortWidth = window.visualViewport.width;
  const viewPortHeight = window.visualViewport.height;

  return (
    left >= 0 &&
    left + width <= viewPortWidth &&
    top >= 0 &&
    top + height <= viewPortHeight
  );
};

export const MenuPlacements = {
  top: 'top',
  bottom: 'bottom'
};

export const getMenuPlacement = (element, requiredHeight) => {
  // Menu Placement
  // bottom: (default) shows the results below the search input field
  // top:    shows the results above the search input field
  let menuPlacement = MenuPlacements.bottom;

  if (!element) {
    return menuPlacement;
  }

  // element rect
  const { y, height } = element.getBoundingClientRect();
  requiredHeight = requiredHeight != null ? requiredHeight : height;

  // Check if menu can be placed at the top
  if (isWithinViewPort(0, y - requiredHeight, 0, requiredHeight)) {
    menuPlacement = MenuPlacements.top;

    // Now, we try if menu items can be placed at the bottom.
    // If menu can be rendered at both top and bottom, we default
    // to bottom.
    if (isWithinViewPort(0, y, 0, requiredHeight)) {
      // Menu items can be placed at the bottom
      menuPlacement = MenuPlacements.bottom;
    }
  }

  return menuPlacement;
};
