import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { getToolUrl } from '../../utils/formatter';
import { Link, withRouter } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import SymbolSearch from '../subHeader/SymbolSearch';
import { useCurrPage } from '../../utils/hooks/useCurrentPage';

const useStyles = makeStyles({
  topSpace: {
    height: '34px',
    width: '100%'
  },
  container: {
    backgroundColor: '#F7F3E7',
    zIndex: 9,
    marginTop: -34,
    width: '100%'
  },
  linksContainer: {
    display: 'flex',
    gap: 10,
    width: 980,
    margin: '0 auto',
    height: 34
  },
  headerLink: {
    paddingTop: 8,
    paddingLeft: 4,
    paddingRight: 6,
    borderRadius: 3,
    minWidth: 60,
    color: 'black !important',
    fontSize: 14,
    textAlign: 'center',
    flexShrink: 0 // Do not allow the icon containers to shrink
  },
  selected: {
    fontWeight: 'bold'
  },
  search: {
    paddingTop: 4,
    marginLeft: 35
  }
});

const HeaderLink = ({ selected, label, href }) => {
  const classes = useStyles();
  const intl = useIntl();

  // If we are already on the page for this button, don't render as a Link
  if (selected) {
    return (
      <div className={`${classes.headerLink} ${classes.selected}`}>
        <div className={`${classes.selected}`}>
          {intl.formatMessage({ id: label })}
        </div>
      </div>
    );
  }

  return (
    <Link className={classes.headerLink} to={href}>
      <div>{intl.formatMessage({ id: label })}</div>
    </Link>
  );
};

function SubHeader({ history, dm, attrSrcKey }) {
  // Hooks
  const currPage = useCurrPage();
  const classes = useStyles();
  const intl = useIntl();
  const localize = useCallback((id) => {
    return intl.formatMessage({ id: id });
  }, []);

  return (
    <>
      <div className={classes.topSpace} />
      <div className={classes.container}>
        <div className={classes.linksContainer}>
          {/* Market Summary */}
          <HeaderLink
            label={'local.menu.marketsummary'}
            href="/market"
            selected={currPage === 'market_summary'}
          />
          {/* Marketboard */}
          <HeaderLink
            label={'local.menu.marketboard'}
            href="/"
            selected={currPage === 'marketboard'}
          />
          {/* Global News */}
          <HeaderLink
            label={'global.subheader.news'}
            href="/news"
            selected={currPage === 'news'}
          />
          {/* Hotlists */}
          <HeaderLink
            label={'local.menu.rankings'}
            href="/hotlists"
            selected={currPage === 'hotlists'}
          />
          {/* Scanner */}
          <HeaderLink
            label={'local.menu.scans'}
            href="/scan"
            selected={currPage === 'scans'}
          />
          {/* Monex Tools (External, just use `a`) */}
          <a className={classes.headerLink} href={getToolUrl(dm, attrSrcKey)}>
            <div className={classes.iconLabel}>
              {localize('local.menu.tools')}
            </div>
          </a>
          {/* Monex Mail Service (External, just use `a`) */}
          <a
            className={classes.headerLink}
            href={'https://info.monex.co.jp/invest/mail-service.html'}
          >
            <div className={classes.iconLabel}>
              {localize('local.menu.mail.service')}
            </div>
          </a>
          <div className={classes.search}>
            <SymbolSearch
              onSelectedSymbol={(symbolName) =>
                history.push(`/symbol/${symbolName}`)
              }
              searchHorizontalAlignment="right"
              selectorHorizontalAlignment="left"
              searchText={localize('label.symbol.code.or.name.search')}
              showActionButtons={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(SubHeader);
