import { useCallback } from 'react';
import { useIntl } from 'react-intl';

const useLocalize = () => {
  const intl = useIntl();

  const localize = useCallback(
    (key, params) => {
      return intl.formatMessage(
        {
          id: key
        },
        params
      );
    },
    [intl]
  );

  return localize;
};

export default useLocalize;
