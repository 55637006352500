import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  getOrderedMarketboards,
  getSelectedBoardOrDefault
} from '../../selectors/marketboardSelectors';
import {
  setSelectedTab,
  postMarketboards,
  updateMarketboardNameIfNeeded,
  addMarketboard,
  deleteMarketboard,
  deleteSymbol,
  setSelected
} from '../../actions/marketboards';
import MarketBoard from './MarketBoard';
import { ContextAwareProMarketBoard } from './ContextAwareProMarketBoard';
import { ProMarketBoardHeader } from './ProMarketBoardHeader';
import withTracker from '../../utils/analytics';
import MemoDialog from './MemoDialog';
import {
  getNextMarketboardId,
  getNextMarketboardName
} from '../../selectors/marketboardSelectors';
import { injectIntl } from 'react-intl';
import DeleteDialog from '../common/DeleteDialog';
import { isMonexApp } from '../../utils/common';
import { withDesktopWidth } from '../../utils/hocs/withDesktopWidth';
import { fetchUserMemosIfNeeded } from '../../actions/memos';
import { setDocumentTitle } from '../../utils/html';
import { MobilePageTitleBar } from './MobilePageTitleBar';
import { postMetric } from '../../utils/userMetrics';
import { saveLastMarketboard } from '../../utils/marketboards';

class MarketBoardsContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      addDialogOpen: false,
      deleteBoardDialogOpen: false,
      deleteSymbolDialogOpen: false,
      renameDialogOpen: false,
      // Used to delete a symbol from a board
      symbolPosition: null,
      fetchedMemos: false,
      selectedSymbol: null
    };

    this.setTitle = this.setTitle.bind(this);

    this.handleAddBoardDialogOpen = this.handleAddBoardDialogOpen.bind(this);
    this.handleDeleteBoardDialogOpen =
      this.handleDeleteBoardDialogOpen.bind(this);
    this.handleDeleteSymbolDialogOpen =
      this.handleDeleteSymbolDialogOpen.bind(this);
    this.handleRenameBoardDialogOpen =
      this.handleRenameBoardDialogOpen.bind(this);

    this.handleMarketboardAdd = this.handleMarketboardAdd.bind(this);
    this.handleMarketboardDelete = this.handleMarketboardDelete.bind(this);
    this.handleMarketboardRename = this.handleMarketboardRename.bind(this);
    this.handleDeleteSymbol = this.handleDeleteSymbol.bind(this);
    this.handleSelectedSymbolChanged =
      this.handleSelectedSymbolChanged.bind(this);
  }

  fetchMemosForCurrentSymbols() {
    if (!this.props || !this.props.selected || !this.props.selected.Symbols) {
      return;
    }

    const symbols = this.props.selected.Symbols.map((s) => s.Symbol);
    this.props.actions.fetchUserMemos(symbols);
  }

  componentDidMount() {
    document.body.setAttribute('data-page-name', 'marketboard');

    // When opening the browser while viewing directly a marketboard (instead of the
    // marketboard list page), the boards might not be loaded yet. For that reason,
    // we check here if they are loaded or not. In case we do have the data for the
    // board, load the memos here.
    if (this.props.selected && this.props.selected.Symbols) {
      this.setState({ fetchedMemos: true });
      this.fetchMemosForCurrentSymbols();
    }
  }

  getMarketboardId(props) {
    if (!props || !props.selected || !props.selected.Id) {
      return null;
    }

    return props.selected.Id;
  }

  componentDidUpdate(prevProps) {
    // If when the page was loaded the boards were not ready yet, we will keep checking
    // until we got data. When we do get data for the boards, if we still haven't fetched
    // the memos yet, fetch them.
    if (
      !this.state.fetchedMemos &&
      prevProps.selected !== this.props.selected &&
      this.props.selected.Symbols
    ) {
      this.setState({ fetchedMemos: true });
      this.fetchMemosForCurrentSymbols();
      // Adding this return here because executing the below logic at this point is unnecessary.
      return;
    }

    if (
      this.getMarketboardId(prevProps) !== this.getMarketboardId(this.props)
    ) {
      this.fetchMemosForCurrentSymbols();
    }
  }

  isPositionBoard(boardId) {
    return boardId.startsWith('cash') || boardId.startsWith('margin');
  }

  getTabIndexFromBoard(board) {
    return board.startsWith('margin') ? 2 : board.startsWith('cash') ? 1 : 0;
  }

  getTabValueFromBoard(board) {
    if (board.startsWith('margin')) {
      return '?tab=margin';
    }

    if (board.startsWith('cash')) {
      return '?tab=cash';
    }

    return '';
  }

  handleAddBoardDialogOpen(isOpen) {
    this.setState({ addDialogOpen: isOpen });
  }

  handleDeleteBoardDialogOpen(isOpen) {
    this.setState({ deleteBoardDialogOpen: isOpen });
  }

  handleDeleteSymbolDialogOpen(isOpen, position = null) {
    this.setState({ deleteSymbolDialogOpen: isOpen, symbolPosition: position });
  }

  handleRenameBoardDialogOpen(isOpen) {
    this.setState({ renameDialogOpen: isOpen });
  }

  handleMarketboardAdd(boardName) {
    this.handleAddBoardDialogOpen(false);
    if (!boardName) {
      return;
    }

    this.props.actions.addMarketboard({
      Id: this.props.nextId,
      Name: boardName,
      Symbols: []
    });
    this.props.actions.onSaveMarketboard();
    this.props.actions.setSelectedMarketboard(this.props.nextId);
    saveLastMarketboard(this.props.nextId);
    // Navigate to the marketboard with the given id.
    window.location.hash = '#/marketboard/' + this.props.nextId;
    postMetric({
      metricType: 'MarketboardPro',
      metricName: 'MarketboardAdded'
    });
  }

  handleMarketboardDelete() {
    // Obtain next available marketboard
    let nextAvailableBoards = this.props.boards.filter(
      (board) => board.Id !== this.props.selected.Id
    );
    let nextAvailableBoard = nextAvailableBoards[0] || {};
    let nextAvailableBoardId = nextAvailableBoard.Id || null;

    // Do not continue if there is only 1 marketboard available or cannot get next board
    if (this.props.boards.length === 1 || nextAvailableBoardId === null) {
      return;
    }

    // Delete selected marketboard
    this.handleDeleteBoardDialogOpen(false);
    this.props.actions.deleteMarketboard(this.props.selected.Id);
    this.props.actions.onSaveMarketboard();

    // Navigate to next available marketboard
    window.location.hash = '#/marketboard/' + nextAvailableBoardId;
    postMetric({
      metricType: 'MarketboardPro',
      metricName: 'MarketboardDeleted'
    });
  }

  handleMarketboardRename(newValue) {
    this.handleRenameBoardDialogOpen(false);
    if (newValue && this.props.selected.Name !== newValue) {
      this.props.actions.onUpdateMarketboardName(
        this.props.selected.Id,
        newValue
      );
      this.props.actions.onSaveMarketboard();
      postMetric({
        metricType: 'MarketboardPro',
        metricName: 'MarketboardRenamed'
      });
    }
  }

  handleDeleteSymbol() {
    this.handleDeleteSymbolDialogOpen(false);
    this.props.actions.deleteSymbol(
      this.props.selected.Id,
      this.state.symbolPosition
    );
    this.props.actions.onSaveMarketboard();
  }

  setTitle(boardName) {
    const title = this.props.intl.formatMessage(
      {
        id: 'document.title.marketboard'
      },
      {
        boardName: boardName
      }
    );
    setDocumentTitle(title);
  }

  handleSelectedSymbolChanged(symbol) {
    this.setState({ selectedSymbol: symbol });
  }

  render() {
    if (!this.props.boards) {
      return (
        <div className="no-content empty">
          <FormattedMessage id="message.no.user.boards.found" />
        </div>
      );
    }
    if (this.props.isLoading) {
      return (
        <div className="no-content loading">
          <FormattedMessage id="message.status.loading" />
        </div>
      );
    }

    // This code is here to check if the current (selected) tab is not set,
    // to set it in the redux store so we know what tab to go back to.  In
    // addition, if the tab is not the tab we expect (such as after refreshing
    // while looking at a cash or margin position board) to set it to the tab we expect.

    // TODO: Block to be removed when we remove tabbing.
    // The below isMonexApp check is temporary to avoid causing errors when checking tabs that do not exist
    if (!isMonexApp()) {
      if (
        !this.props.currentTab ||
        this.props.currentTab !==
          this.getTabIndexFromBoard(this.props.selected.Id || '')
      ) {
        this.props.actions.setSelectedTab(
          this.getTabIndexFromBoard(this.props.selected.Id || '')
        );
      }
    }

    const mobileMarketboardHeader = (
      <MobilePageTitleBar marketboard={this.props.selected} />
    );
    const mobileMarketboard = (
      <MarketBoard
        key={this.props.selected.Id}
        board={this.props.selected}
        marketboardId={this.props.selected.Id}
        history={this.props.history}
      />
    );
    const proMarketboard = (
      <ContextAwareProMarketBoard
        key={this.props.selected.Id}
        board={this.props.selected}
        marketboardId={this.props.selected.Id}
        handleDeleteSymbolDialogOpen={this.handleDeleteSymbolDialogOpen}
        handleSelectedSymbolChanged={this.handleSelectedSymbolChanged}
      />
    );
    const proMarketboardHeader = (
      <ProMarketBoardHeader
        key={'ProMarketBoardHeader' + this.props.selected.Id}
        symbol={this.state.selectedSymbol}
        board={this.props.selected}
        boards={this.props.boards}
        handleAddBoardDialogOpen={this.handleAddBoardDialogOpen}
        handleDeleteBoardDialogOpen={this.handleDeleteBoardDialogOpen}
        handleRenameBoardDialogOpen={this.handleRenameBoardDialogOpen}
      />
    );

    this.setTitle(this.props.selected.Name);

    // If Pro Marketboard is enabled, we should show the
    // Marketboard View Toggle as well as the dropdown
    // selector for selecting the active board.
    return (
      <div>
        {this.props.isDesktopWidth ? (
          <>
            <MemoDialog
              key={this.props.nextId + 'add'}
              title={'marketboard.add.title'}
              confirmLabel={'marketboard.add.confirm'}
              cancelLabel={'button.label.cancel'}
              open={this.state.addDialogOpen}
              boardName={this.props.nextName}
              onConfirm={this.handleMarketboardAdd}
              onCancel={() => this.handleAddBoardDialogOpen(false)}
            />
            <DeleteDialog
              key={this.props.selected.Id + 'delete'}
              titleText={this.props.intl.formatMessage({
                id: 'marketboard.dialog.delete.mb.title'
              })}
              contentText={this.props.intl.formatMessage({
                id: 'marketboard.dialog.delete.mb.message'
              })}
              cancelText={this.props.intl.formatMessage({
                id: 'button.label.cancel'
              })}
              confirmText={this.props.intl.formatMessage({
                id: 'button.label.delete'
              })}
              openDialog={this.state.deleteBoardDialogOpen}
              onCancel={() => this.handleDeleteBoardDialogOpen(false)}
              onDelete={this.handleMarketboardDelete}
              confirmViaCheckbox={true}
              deleteContent={this.props.selected.Name}
            />
            <MemoDialog
              key={this.props.selected.Name}
              title={'marketboard.rename.title'}
              confirmLabel={'marketboard.rename.confirm'}
              cancelLabel={'button.label.cancel'}
              open={this.state.renameDialogOpen}
              boardName={this.props.selected.Name}
              onConfirm={this.handleMarketboardRename}
              onCancel={() => this.handleRenameBoardDialogOpen(false)}
            />
            <MemoDialog
              key={this.props.selected.Id + 'deleteSymbol'}
              title={'marketboard.symbol.delete.title'}
              confirmLabel={'marketboard.symbol.delete.confirm'}
              cancelLabel={'button.label.cancel'}
              open={this.state.deleteSymbolDialogOpen}
              onConfirm={this.handleDeleteSymbol}
              onCancel={() => this.handleDeleteSymbolDialogOpen(false)}
              contentLabel={'marketboard.symbol.delete.message'}
            />
            {proMarketboardHeader}
            {proMarketboard}
          </>
        ) : (
          // If we are not an internal user or we are an internal user
          // but not in desktop mode, we should show the PageTitleBar.
          <>
            {mobileMarketboardHeader}
            {mobileMarketboard}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const boards = (getOrderedMarketboards(state, props) || { boards: [] })
    .boards;
  const isLoading = state.marketboard ? state.marketboard.isLoading : false;
  const currentBoard = state.marketboard ? state.marketboard.selectedTab : null;

  return {
    isLoading,
    boards,
    currentBoard,
    selected: getSelectedBoardOrDefault(state, props.match.params.id),
    nextId: getNextMarketboardId(state),
    nextName: getNextMarketboardName(state),
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setSelectedTab: (index) => dispatch(setSelectedTab(index)),
      onSaveMarketboard: () => dispatch(postMarketboards()),
      onUpdateMarketboardName: (marketboardId, name) => {
        dispatch(updateMarketboardNameIfNeeded(marketboardId, name));
      },
      addMarketboard: (marketboard) => {
        dispatch(addMarketboard(marketboard));
      },
      deleteMarketboard: (marketboard) => {
        dispatch(deleteMarketboard(marketboard));
      },
      deleteSymbol: (boardId, position) => {
        dispatch(deleteSymbol(boardId, position));
      },
      fetchUserMemos: (symbols) => dispatch(fetchUserMemosIfNeeded(symbols)),
      setSelectedMarketboard: (marketboardId) => dispatch(setSelected(marketboardId))
    }
  };
}

MarketBoardsContainer.propTypes = {
  boards: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const MarketboardProEnabledMarketboardsContainer = withDesktopWidth(
  MarketBoardsContainer
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTracker(injectIntl(MarketboardProEnabledMarketboardsContainer)));
