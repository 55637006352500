import { useSelector } from 'react-redux';

const useSymbolMetadata = (symbol) => {
  const metadata = useSelector(
    (state) => state.symbols.data || { data: {} } || {}
  );
  
  return (metadata[symbol] || {}).data || {};
}

export default useSymbolMetadata;