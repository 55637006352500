import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Series } from 'react-jsx-highcharts';

import Sma from './Sma';
import { getSeries as ichimoku } from '../../utils/indicators/ichimoku';
import { getSeries as bb } from '../../utils/indicators/bb';
import { getSeries as volByPrice } from '../../utils/indicators/volByPrice';
import { getSeries as parabolicSar } from '../../utils/indicators/parabolicStopAndReverse';
import { getSeries as movAvgEnvelopes } from '../../utils/indicators/movAvgEnvelopes';

const Indicator = ({ getSeries, bars, setting, volumeBars }) => {
  const intl = useIntl();

  const series = useMemo(() => {
    const indicatorLocalize = (data, params) =>
      intl.formatMessage(data, params);

    return getSeries(
      setting.params,
      bars,
      bars,
      indicatorLocalize,
      volumeBars
    );
  }, [getSeries, setting.params, bars, intl, volumeBars]);

  if (!series) {
    return <></>;
  }

  return (
    <>
      {series.map((props) => (
        <Series
          key={props.id}
          showInNavigator={false}
          animation={false}
          {...props}
        />
      ))}
    </>
  );
};

const RealtimeMainChartIndicator = (props) => {
  const components = {
    sma: [Sma, null],
    ichimoku: [Indicator, ichimoku],
    bb: [Indicator, bb],
    volByPrice: [Indicator, volByPrice],
    parabolicSar: [Indicator, parabolicSar],
    movAvgEnvelopes:[Indicator, movAvgEnvelopes],
  };

  const indicatorData = components[props.type];
  if (!indicatorData) {
    return null;
  }

  const Component = indicatorData[0];
  return <Component getSeries={indicatorData[1]} {...props} />;
};

export default RealtimeMainChartIndicator;
