import React, { useEffect } from 'react';
import FundamentalAnalysisSection from './FundamentalAnalysisSection';
import DividendInformationSection from './DividendInformationSection';
import AnalystConsensusSection from './AnalystConsensusSection';
import TechnicalAnalysisSection from './TechnicalAnalysisSection';
import ScoreAnalysisSection from './ScoreAnalysisSection';
import { getAnalysisFundamentals } from '../../utils/fundamentals';
import useActions from '../../utils/hooks/useActions';
import { fetchFundamentals as fetchFundamentalsAction } from '../../actions/market';
import useFundamentals from '../../utils/hooks/useFundamentals';
import useSymbolMetadata from '../../utils/hooks/useSymbolMetadata';
import { useSelector } from 'react-redux';

const SymbolIndicators = ({ symbol }) => {
  const user = useSelector((state) => state.user || {});
  const symbolData = useSymbolMetadata(symbol);
  const fundamentals = useFundamentals(symbol);
  const [fetchFundamentals] = useActions([fetchFundamentalsAction], []);

  useEffect(() => {
    fetchFundamentals(symbol);
  }, [symbol, fetchFundamentals]);

  let analysisFundamentals = getAnalysisFundamentals(fundamentals);
  return (
    <div className='symbol-details-indicators-fundamental-analysis-container'>
      <FundamentalAnalysisSection {...analysisFundamentals} />
      <DividendInformationSection {...analysisFundamentals} />
      <AnalystConsensusSection fundamentals={fundamentals} />
      <TechnicalAnalysisSection
        token={user.token}
        symbolId={symbolData.id}
        key={symbolData.id}
      />
      <ScoreAnalysisSection fundamentals={fundamentals} />
    </div>
  );
};

export default SymbolIndicators;
