import types from './actions/_actionTypes';
// import { publish } from './utils/metrics';
import { event } from './utils/analytics';
//import Logger from './utils/logger';

function handleAction(user, state, action) {
  switch (action.type) {
    case types.REQUESTING_MARKETBOARDS:
    case types.FILTER_MARKETBOARDS:
    case types.SET_SELECTED:
    case types.SET_SELECTED_TAB:
    case types.ERROR_FETCHING_MARKETBOARD:
    case types.ERROR_POSTING_FEEDBACK:
    case types.ERROR_POSTING_MARKETBOARD:
    case types.TOGGLE_EDIT:
    case types.DELETE_SYMBOL:
    case types.REPLACE_MARKETBOARD_SYMBOLS:
    case types.UPDATE_MARKETBOARD_NAME:
    case types.UPDATE_MARKETBOARD_SYMBOL:
    case types.MARKETBOARD_ADD:
    case types.MARKETBOARD_DELETE:
    case types.MOVE_MARKETBOARD:
    case types.USER_ERROR_VALIDATING_TOKEN:
    case types.SYMBOL_ERROR_REQUESTING_PATTERN:
      //publishEvent(user, state, action);
      event('actions', action.type);
      break;
    default:
  }
}

// function publishEvent(user, state, action) {
//   Logger.debug({
//     errorCode: 'D0002',
//     error: 'events reducer publishing',
//     user: user,
//     action: action.type,
//     state: state
//   });
//   publish(action.type, { userId: user });
// }

const analyticsMiddleware = (store) => (next) => (action) => {
  var state = store.getState();

  if (state.user && state.user.id) {
    handleAction(state.user.id, state, action);
  }

  next(action);
};

export default analyticsMiddleware;
