import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import ChartPeriod from './ChartPeriod';
import SingleToggle from './SingleToggle';

const LOCALIZED_TEXT_CLASS = 'settings-chart-indicators-rci-period';

class ChartRci extends React.Component {
  constructor(props) {
    super(props);
    this.addPeriod = this.addPeriod.bind(this);
  }

  addPeriod(type) {
    this.props.addPeriod(type);
  }

  localize(i18nKey, otherParams) {
    return this.props.intl.formatMessage({ id: i18nKey }, otherParams);
  }

  getPeriods(type) {
    let indicator =
      _.find(this.props.indicators, indicator => indicator.type === type) || {};
    return indicator.params || [];
  }

  toggleRci = event => {
    let value = event.target.checked;
    if (value) {
      this.props.addIndicator(this.props.type);
    } else {
      this.props.deleteIndicator(this.props.type);
    }
  };

  displayInputPeriods = isActive => {
    if (isActive === true) {
      return (
        <div className="settings-chart-indicators-container">
          <div className="settings-chart-indicators-properties">
            <ChartPeriod
              {...this.props}
              key={0}
              id={0}
              localizedText="settings.chart.indicators.rci.period"
              localizedTextClass={LOCALIZED_TEXT_CLASS}
              period={this.getPeriods(this.props.type)[0]}
              setPeriod={this.props.setPeriod}
              deletePeriod={null}
            />
            <ChartPeriod
              {...this.props}
              key={1}
              id={1}
              localizedText="settings.chart.indicators.rci.avgPeriod"
              localizedTextClass={LOCALIZED_TEXT_CLASS}
              period={this.getPeriods(this.props.type)[1]}
              setPeriod={this.props.setPeriod}
              deletePeriod={null}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    let periods = this.displayInputPeriods(
      this.props.isIndicatorActive(this.props.type)
    );
    return (
      <div className="">
        <div className="settings-card-contents">
          <SingleToggle
            title={this.localize(
              'settings.chart.indicators.' + this.props.type + '.title'
            )}
            checked={this.getPeriods(this.props.type).length > 0}
            onChange={this.toggleRci}
            skipList={true}
            toggleFavorite={this.props.toggleFavorite}
            type={this.props.type}
            isFavorite={this.props.isFavorite}
          />
        </div>
        {periods}
      </div>
    );
  }
}

export default injectIntl(ChartRci);
