import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { initWebSocket, start, stop } from '../../actions/stream';
import { fetchSymbolIfNeeded } from '../../actions/symbols';
import { injectIntl } from 'react-intl';
import Exchange from '../common/Exchange';
import Number from '../common/Number';
import * as _ from 'lodash';

class HotlistItem extends React.PureComponent {
  componentDidMount() {
    this.props.actions.getSymbol(this.props.value.symbol);
  }

  componentDidUpdate() {}

  localize(i18nKey) {
    return this.props.intl.formatMessage({
      id: i18nKey
    });
  }

  componentMap = {
    NetChangePct: {
      component: Number,
      props: {
        signed: true,
        precision: 2,
        suffix: '%',
        hideIfZero: true,
        multiplier: 1
      }
    },
    Close: {
      component: Number,
      props: {
        signed: true,
        hideIfZero: true,
        multiplier: 1
      }
    },
    Volume: {
      component: Number,
      props: {
        signed: false,
        hideIfZero: true,
        multiplier: 1
      }
    },
    Turnover: {
      component: Number,
      props: {
        signed: false,
        hideIfZero: true,
        multiplier: 1
      }
    },
    NetChange: {
      component: Number,
      props: {
        signed: true,
        hideIfZero: true,
        multiplier: 1
      }
    },
    VolumeChangePct: {
      component: Number,
      props: {
        signed: true,
        precision: 2,
        suffix: '%',
        hideIfZero: true,
        multiplier: 1
      }
    },
    ChangeInSharesSoldOnMargin: {
      component: Number,
      props: {
        signed: true,
        hideIfZero: true,
        multiplier: 1
      }
    },
    ChangeInSharesBoughtOnMargin: {
      component: Number,
      props: {
        signed: true,
        hideIfZero: true,
        multiplier: 1
      }
    },
    BidAskRatio: {
      component: Number,
      props: {
        signed: true,
        precision: 2,
        suffix: '%',
        hideIfZero: true,
        multiplier: 100
      }
    },
    OpenPriceRatio: {
      component: Number,
      props: {
        signed: true,
        precision: 2,
        suffix: '%',
        hideIfZero: true,
        multiplier: 100
      }
    },
    '5DaysRiseRate': {
      component: Number,
      props: {
        signed: true,
        precision: 2,
        suffix: '%',
        hideIfZero: true,
        multiplier: 100
      }
    },
    '30DaysRiseRate': {
      component: Number,
      props: {
        signed: true,
        precision: 2,
        suffix: '%',
        hideIfZero: true,
        multiplier: 100
      }
    },
    '5DaysFallRate': {
      component: Number,
      props: {
        signed: true,
        precision: 2,
        suffix: '%',
        hideIfZero: true,
        multiplier: 100
      }
    },
    '30DaysFallRate': {
      component: Number,
      props: {
        signed: true,
        precision: 2,
        suffix: '%',
        hideIfZero: true,
        multiplier: 100
      }
    },
    VwapDeviationRate: {
      component: Number,
      props: {
        signed: true,
        precision: 2,
        suffix: '%',
        hideIfZero: true,
        multiplier: 100
      }
    }
  };

  getValuesFromMap(level) {
    return this.props.value.values[
      this.props.columns.findIndex(
        x => x.id === this.props.hotlistValueMap[this.props.hotlistName][level]
      )
    ];
  }

  getPropsFromMap(level) {
    return this.componentMap[
      this.props.hotlistValueMap[this.props.hotlistName][level]
    ].props;
  }

  getComponentFromMap(level) {
    return this.componentMap[
      this.props.hotlistValueMap[this.props.hotlistName][level]
    ].component;
  }

  buildComponentFromMap(level) {
    if (this.componentExistsInMap(level)) {
      let Component = this.getComponentFromMap(level);
      let componentProps = this.getPropsFromMap(level);
      let componentValue = this.getValuesFromMap(level);

      return (
        <Component
          value={componentValue * componentProps.multiplier}
          {...componentProps}
          className={`${
            this.props.hotlistValueMap[this.props.hotlistName][level]
          }
          ${componentProps.signed ? '' : ' unsigned'}`}
        />
      );
    } else {
      return '';
    }
  }
  componentExistsInMap(level) {
    return (
      this.props.hotlistValueMap[this.props.hotlistName][level] &&
      this.props.columns.findIndex(
        x => x.id === this.props.hotlistValueMap[this.props.hotlistName][level]
      )
    );
  }

  render() {
    if (this.props.value) {
      return (
        <Link to={'/symbol/' + this.props.value.symbol}>
          <div className={'hotlist_values ' + this.props.hotlistName}>
            <div className="hotlist_values__row one">
              {this.props.ranked && (
                <span
                  className="hotlist_value__rank"
                  value-rank={this.props.value.rank}
                >
                  {this.props.value.rank}
                </span>
              )}
              <span className="hotlist_value__symbol-name">
                {this.props.symbolData.jpShortName}
              </span>
              <span className="hotlist_value__value hotlist_value__value-primary">
                {this.buildComponentFromMap('primary')}
              </span>
              <span className="hotlist_value__value hotlist_value__value-tertiary">
                {this.buildComponentFromMap('tertiary')}
              </span>
              <span className="hotlist_value__value hotlist_value__value-quaternary">
                {this.buildComponentFromMap('quaternary')}
              </span>
              <span className="hotlist_value__value hotlist_value__value-quinary">
                {this.buildComponentFromMap('quinary')}
              </span>
              <span className="hotlist_value__value hotlist_value__value-senary">
                {this.buildComponentFromMap('senary')}
              </span>
            </div>
            <div className="hotlist_values__row two">
              <span className="hotlist_value__symbol-root">
                {this.props.symbolData.root}
              </span>
              <span className="hotlist_value__symbol-excahnge">
                {
                  <Exchange
                    name={this.props.symbolData.exchangeName}
                    section={this.props.symbolData.jpSection}
                  />
                }
              </span>
              <span className="hotlist_value__symbol-industry">
                {this.localize(
                  'symbol.industry.' +
                    _.get(this.props, 'symbolData.jpIndustry', 9999)
                )}
              </span>
              <span className="hotlist_value__value hotlist_value__value-secondary">
                {this.buildComponentFromMap('secondary')}
              </span>
            </div>
          </div>
        </Link>
      );
    } else {
      return <div />;
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.user) {
    return {};
  }

  // Use spread (...) to guarantee we have a new object.
  let props = {
    ...{}
  };

  props.symbolData =
    (state.symbols.data[ownProps.value.symbol] || { data: {} }).data || {};

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      init: bindActionCreators(initWebSocket, dispatch),
      start: bindActionCreators(start, dispatch),
      stop: bindActionCreators(stop, dispatch),
      getSymbol: bindActionCreators(fetchSymbolIfNeeded, dispatch)
    }
  };
}

HotlistItem = injectIntl(HotlistItem);

export default connect(mapStateToProps, mapDispatchToProps)(HotlistItem);
