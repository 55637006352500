import Logger from './logger';
import { handleErrors } from './common';

const defaultTimeoutMilliseconds = 3000;
const defaultRetries = 3;
const defaultDelay = 500;

async function fetchTimeout(url, options, timeout) {
  let tx = setTimeout(() => {
    return 'error';
  }, timeout);

  let result = await Promise.race([
    fetch(url, options),
    new Promise(function (resolve) {
      setTimeout(resolve.bind(null, 'timeout'), timeout);
    })
  ]);

  clearTimeout(tx);
  if (result !== 'timeout') {
    return result;
  }

  const msg = `E0014: Error fetching ${url} with ${timeout / 1000}s timeout`;
  Logger.error(msg);
  throw new Error(msg);
}

export default function fetchRetry(
  url,
  fetchOptions = {},
  delay = defaultDelay,
  retries = defaultRetries,
  timeout = defaultTimeoutMilliseconds
) {
  const maxRetries = retries;
  return new Promise((resolve, reject) => {
    function success(response) {
      resolve(response);
    }
    function failure(error) {
      retries--;
      if (retries) {
        setTimeout(fetchUrl, delay);
      } else {
        Logger.error(`E0013: Error fetching ${url} with ${maxRetries} retries`);
        reject(error);
      }
    }
    function fetchUrl() {
      return fetchTimeout(url, fetchOptions, timeout)
        .then(success)
        .catch(failure);
    }
    fetchUrl();
  });
}

export function fetchRetryWithAuth(
  req,
  getUrl,
  successDispatch,
  errorDispatch,
  errorCode, // deprecated
  successCallback, // optional
  failCallback // optional
) {
  if (!req || !getUrl || !successDispatch || !errorDispatch) {
    Logger.error({
      errorCode: 'E0023',
      message: 'Insufficient Parameters to fetch retry'
    });
    return false;
  }

  return (dispatch, getState) => {
    var user = getState().user || {};
    if (!user.id || user.tokenIsValid !== true || !user.token) {
      Logger.error({
        errorCode: 'E0031',
        message: `Invalid Token in fetch retry - user: (${JSON.stringify(
          user
        )})`
      });
      failCallback && failCallback();
      return false;
    }

    if (!req.headers) {
      req.headers = {};
    }
    req.headers.authorization = user.token;
    let timeout = req.timeout || defaultTimeoutMilliseconds;
    return fetchRetry(
      getUrl(user.id),
      req,
      defaultDelay,
      defaultRetries,
      timeout
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then((response) => dispatch(successDispatch(response)))
      .then(() => successCallback && successCallback())
      .catch((ex) => {
        dispatch(errorDispatch(ex));
        failCallback && failCallback();
      });
  };
}
