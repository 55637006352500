import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Number from '../common/Number';
import { getSymbolClose } from '../../utils/symbol';

import CssFlash from '../common/CssFlash';
import useActions from '../../utils/hooks/useActions';
import { start, stop } from '../../actions/stream';
import { calcNetChgPct } from '../../utils/maths';
import SymbolRestrictions from '../common/SymbolRestrictions';
import { QuoteCardExRightsFlag } from '../common/QuoteCardExRightsFlag';
import { selectChannel } from '../../selectors/channelSelectors';

const MobileSymbolDetailHeader = ({ symbol }) => {
  // Redux
  const channel = useMemo(() => `${symbol}/quote`, [symbol]);
  const quote = useSelector((state) => selectChannel(state, channel));
  const [startStream] = useActions([start], []);
  const [stopStream] = useActions([stop], []);
  const metadata = useSelector(
    (state) => (state.symbols.data || { data: {} } || {})[symbol] || {}
  );
  const symbolData = metadata.data || {};

  useEffect(() => {
    if (!symbol) {
      return;
    }

    startStream(`${symbol}/quote`);

    return () => {
      stopStream(`${symbol}/quote`);
    };
  }, [symbol, startStream, stopStream]);

  if (!symbol) {
    return null;
  }

  const precision = quote.precision;
  const assetClass = symbolData.assetClass;
  const close = getSymbolClose(assetClass, quote); 
  const netChange = calcNetChgPct(close, quote.prevClose);

  return (
    <div className={'symbol-detail-price-data'}>
      <span className={'symbol-details-last-price'}>
        <CssFlash value={close}>
          <Number
            value={close}
            precision={precision}
            hideIfZero={true}
          />
        </CssFlash>
      </span>
      <div className={'symbol-detail-net-change'}>
        <Number
          className={'symbol-detail-net-change-value'}
          value={close - quote.prevClose}
          precision={precision}
          signed={true}
        />{' '}
        <Number
          className={'symbol-detail-net-change-value'}
          value={netChange}
          precision="2"
          prefix="("
          suffix="%)"
          signed={true}
        />
      </div>
      <div className="quote-card-exrights-container">
        <QuoteCardExRightsFlag
          status={quote.exRightsStatus}
          className={'quote-card-flag ex-rights left symboloverview'}
        />
      </div>
      <SymbolRestrictions
        className={'symbol-detail-restrictions'}
        symbol={symbol}
      />
    </div>
  );
};

export default MobileSymbolDetailHeader;
