import { getExchangeResource } from './exchange';

const japaneseEquitySymbolPattern =
  /^JP:([1-9][0-9A-Y]\d[0-9A-Y][0-9A-Y]?)-(TS|NG|FK|SP)$/;
const internationalSymbolPattern = /^([A-Z]{2}:)([^-]+)(-[A-Z]{2})?$/;
const SUPPLEMENTAL_CLASSES = ['bond', 'index', 'forex', 'commodity', 'future'];
const JPEQ_CLASS = 'jpeq';
const USEQ_CLASS = 'useq';
const INDUSTRY_CLASS = 'industry';

export function isJapaneseEquity(symbolId) {
  return japaneseEquitySymbolPattern.test(symbolId);
}

export function isJpeq(assetClass) {
  return assetClass === JPEQ_CLASS;
}

export function isSupplemental(assetClass) {
  return SUPPLEMENTAL_CLASSES.includes(assetClass);
}

export function isIndustry(assetClass) {
  return assetClass === INDUSTRY_CLASS;
}

export function isUseq(assetClass) {
  return assetClass === USEQ_CLASS;
}

export function getSymbolRoot(pattern) {
  if (japaneseEquitySymbolPattern.test(pattern)) {
    return pattern.slice(0, -3).slice(3);
  } else {
    return pattern;
  }
}

export function getInternationalSymbolRoot(pattern) {
  if (internationalSymbolPattern.test(pattern)) {
    const matches = pattern.match(internationalSymbolPattern);
    return matches[2] || pattern;
  }
  return pattern;
}

export function getSymbolExchange(pattern) {
  if (japaneseEquitySymbolPattern.test(pattern)) {
    return pattern.slice(-2);
  } else {
    return pattern;
  }
}

function decodeEntities(encodedString) {
  var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  var translate = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>'
  };
  return encodedString
    .replace(translate_re, function (match, entity) {
      return translate[entity];
    })
    .replace(/&#(\d+);/gi, function (match, numStr) {
      var num = parseInt(numStr, 10);
      return String.fromCharCode(num);
    });
}

export function getSymbolSearchResult(pattern) {
  return decodeEntities(decodeURIComponent(pattern));
}

export const showCodeAndExchange = (metadata) => {
  return (
    metadata === undefined ||
    metadata === null ||
    metadata.assetClass === undefined ||
    metadata.assetClass === 'jpeq' ||
    metadata.assetClass === 'useq'
  );
};

const getUseqExchange = (exchange) => {
  switch (exchange) {
    case 'ARCA':
    case 134:
      return 'ARX'; // ARCA
    case 'Cboe':
    case 'CBOE':
    case 117:
      return 'BAT'; // CBOE
    case 'NASDAQ':
    case 122:
    case 123:
      return 'NDQ'; // NASDAQ
    case 'NYSE':
    case 124:
      return 'NYS'; // NYSE
    case 'NYSE American':
    case 'NYSE AMERICAN':
    case 'NYSE Market':
    case 'NYSE MARKET':
    case 104:
      return 'AMX'; // NYSE Market
  }
};

/** Gets the exchange resource ID given a symbol name.
 * Useful for delisted symbols and only functional for JPEQ */
export const getExchangeResourceIdFromSymbolName = (symbol) => {
  if (japaneseEquitySymbolPattern.test(symbol)) {
    const exchange = symbol.slice(-2);
    switch (exchange) {
      case 'TS':
        return getExchangeResource('TSE');
      case 'NG':
        return getExchangeResource('NSE');
      case 'FK':
        return getExchangeResource('FSE');
      case 'SP':
        return getExchangeResource('SSE');
      default:
        return 'common.empty';
    }
  } else {
    return 'common.empty';
  }
}

export const getExchangeResourceId = (metadata) => {
  if (metadata.assetClass === 'useq') {
    return 'exchange.' + getUseqExchange(metadata.exchange);
  }

  let name = metadata.exchangeName;
  if (!name) {
    return 'common.empty';
  }

  const section = metadata.jpSection;
  return getExchangeResource(name, section);
};

export const getSymbolAssetTypeResourceId = (assetClass) => {
  if (!assetClass) {
    return 'common.empty';
  }

  return 'symbol.assetType.' + assetClass;
};

export const getSymbolClose = (assetClass, quote) => {
  if (!isUseq(assetClass)) {
    return quote.close;
  }

  return quote.last;
};
