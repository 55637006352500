import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useIntl } from 'react-intl';
import ErrorBoundary from '../common/ErrorBoundary';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {
  getIndicatorsSeries,
  getAdditionalYAxis
} from '../../utils/indicators';
import { numberFormatter } from '../../utils/chart';
import { tooltipFormatter } from '../../utils/chartTooltip';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import useLocalize from '../../utils/hooks/useLocalize';
require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/indicators/ema')(Highcharts);
require('../common/customIndicators/ichimoku')(Highcharts);
require('../common/customIndicators/avgDeviation')(Highcharts);
require('highcharts/indicators/bollinger-bands')(Highcharts);
require('highcharts/indicators/volume-by-price')(Highcharts);

export const comparisonSymbolsSelector = (state) =>
  (state.user.settings.chart || {}).comparisonSymbols || {};
export const symbolsDataSelector = (state) => state.symbols.data || {};
export const additionalSymbolsBarsSelector = (state, props) =>
  props.additionalSymbolsData;

export const getSymbolsData = createSelector(
  [
    comparisonSymbolsSelector,
    additionalSymbolsBarsSelector,
    symbolsDataSelector
  ],
  (comparisonSymbols, additionalSymbolsBars, symbolsData) => {
    // only load metadata if there's comparison symbols
    // as only comparison chart needs metadata
    if (Object.keys(comparisonSymbols).length === 0) {
      return {};
    }

    const symbols = Object.keys(additionalSymbolsBars);
    let filteredSymbolsData = {};
    symbols.forEach((s) => (filteredSymbolsData[s] = symbolsData[s] || {}));
    return filteredSymbolsData;
  }
);

const xCrosshairLabelFormat = {
  '5min': '{value:%Y-%m-%d %H:%M}',
  '1hour': '{value:%Y-%m-%d %H:%M}',
  '1day': '{value:%Y-%m-%d}',
  '1week': '{value:%Y-%m-%d}',
  '1month': '{value:%Y-%m}',
  '1quarter': '{value:%Y-%m}',
  '1year': '{value:%Y}'
};

const SymbolChart = ({
  displayTooltip,
  isLoading,
  additionalSymbolsData,
  indicators,
  fullVolume,
  chartStyle,
  name,
  bars,
  volume,
  fullBars,
  barSize
}) => {
  const intl = useIntl();
  const localize = useLocalize();

  const { width: windowWidth } = useWindowDimensions();
  const chart = useRef();

  const metadata = useSelector(
    (state) => state.symbols.data || { data: {} } || {}
  );

  const user = useSelector((state) => state.user || {});
  const settings = (user.settings || {}).chart || {};
  const chartMode = settings.chartMode || 'single';

  useEffect(() => {
    // Any localization for Highcharts can be done here
    Highcharts.setOptions({
      lang: {
        loading: localize('symbol.details.chart.loading')
      }
    });
  }, [localize]);

  useEffect(() => {
    if (chart.current) {
      if (isLoading) {
        chart.current.chart.showLoading();
      } else {
        chart.current.chart.hideLoading();
      }
    }
  }, [isLoading, chart]);

  const isComparisonMode = useCallback(() => {
    return chartMode === 'comparison';
  }, [chartMode]);

  const generateComparisonSymbolsSeries = useCallback(
    (bars, volume, rawBars, comparisonSymbolsBars) => {
      const symbols = Object.keys(additionalSymbolsData);
      const comparisonSymbolsMetadata = {};
      symbols.forEach((s) => (comparisonSymbolsMetadata[s] = metadata[s]));

      // series for comparison symbols
      const series = getIndicatorsSeries(
        indicators || [],
        bars || [],
        rawBars || [],
        intl.formatMessage,
        fullVolume,
        comparisonSymbolsBars,
        comparisonSymbolsMetadata
      );

      // volume chart
      series.push({
        type: 'column',
        id: 'volume',
        name: localize('symbol.details.volume'),
        data: volume,
        yAxis: 1,
        enableMouseTracking: false
      });

      return series;
    },
    [additionalSymbolsData, metadata, indicators, intl, fullVolume, localize]
  );

  const buildSeriesData = useCallback(
    (bars, volume, rawBars, additionalSymbolsData) => {
      let type = chartStyle;
      if (type === 'candlestickBlackWhite') {
        type = 'candlestick';
      }

      if (
        !isLoading &&
        bars?.length > 0 &&
        volume?.length > 0 &&
        rawBars?.length > 0
      ) {
        if (isComparisonMode()) {
          // return a separate list of series
          // if we have comparison symbols to show
          // series 1 - main
          // series 2 - symbol X
          // series N - symbol N
          // volume series
          return generateComparisonSymbolsSeries(
            bars,
            volume,
            rawBars,
            additionalSymbolsData
          );
        }

        let series = [
          {
            type: type,
            name: name,
            id: 'bars',
            data: bars,
            yAxis: 0,
            zIndex: 2
          },
          {
            type: 'column',
            id: 'volume',
            name: localize('symbol.details.volume'),
            data: volume,
            yAxis: 1,
            enableMouseTracking: false
          }
        ];

        // Currently the bars have the following format: [timestamp, open, high, low, close, isChartBar].
        // Now, in Sometaro, we are using the `close` to draw the lines and area instead of the `open`.
        // For that reason, we are using the `useOhlcData` flag here for those two types of chart, so
        // we use the `close` instead of the `open`.
        if (chartStyle === 'area' || chartStyle === 'line') {
          series[0].useOhlcData = true;
        }

        let indicatorSeries = getIndicatorsSeries(
          indicators || [],
          bars || [],
          rawBars || [],
          intl.formatMessage,
          fullVolume,
          additionalSymbolsData
        );

        return series.concat(indicatorSeries);
      }
    },
    [
      chartStyle,
      fullVolume,
      generateComparisonSymbolsSeries,
      indicators,
      intl,
      isComparisonMode,
      isLoading,
      localize,
      name
    ]
  );

  const plotOptions = useMemo(() => {
    let candleStickColor = '#0055cc';
    let candleStickUpColor = '#ff2800';
    if (chartStyle === 'candlestickBlackWhite') {
      candleStickColor = '#000000';
      candleStickUpColor = '#FFFFFF';
    }

    return {
      area: {
        color: '#0055cc',
        fillOpacity: 0.3,
        lineWidth: 1.5,
        shadow: !1,
        states: {
          inactive: {
            opacity: 1
          }
        },
        threshold: null
      },
      candlestick: {
        color: candleStickColor,
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          },
          inactive: {
            opacity: 1
          }
        },
        upColor: candleStickUpColor
      },
      column: {
        groupPadding: 0,
        color: '#008c46',
        states: {
          inactive: {
            opacity: 1
          }
        }
      },
      scatter: {
        color: '#d9027c',
        states: {
          inactive: {
            opacity: 1
          }
        },
        tooltip: {
          followPointer: !0
        }
      },
      line: {
        color: '#0055cc',
        lineWidth: 1.5,
        states: {
          inactive: {
            opacity: 1
          }
        },
        tooltip: {
          followPointer: !0
        }
      },
      ohlc: {
        color: '#0055cc',
        lineWidth: 1.5,
        states: {
          inactive: {
            opacity: 1
          }
        }
      },
      spline: {
        color: '#0055cc',
        lineWidth: 1.5,
        states: {
          inactive: {
            opacity: 1
          }
        },
        tooltip: {
          followPointer: !0
        }
      },
      series: {
        animation: !1,
        dataGrouping: {
          enabled: !1
        },
        marker: {
          enabled: false,
          radius: 2,
          states: {
            hover: {
              radius: 2
            }
          },
          symbol: 'circle'
        },
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            lineWidth: 1.5
          }
        },
        turboThreshold: 1
      }
    };
  }, [chartStyle]);

  const chartHeight = useMemo(() => {
    let height;

    switch (true) {
      case windowWidth < 500:
        height = 200;
        break;
      case windowWidth >= 1400:
        height = 312;
        break;
      case windowWidth > 800:
        height = 312;
        break;
      case windowWidth > 600:
        height = 215;
        break;
      default:
        height = 250;
        break;
    }
    return height;
  }, [windowWidth]);

  const configCrosshair = useCallback((customizeFunc) => {
    const color = '#808080'; // grey color
    // gold color '#e0b400';

    const config = {
      color: color,
      width: 0.25,
      dashstyle: 'shortdash',
      label: {
        enabled: true,
        backgroundColor: color,
        padding: 4
      }
    };

    return customizeFunc ? customizeFunc(config) : config;
  }, []);

  const buildYAxis = useCallback(() => {
    const indicatorAxis = getAdditionalYAxis(indicators || []);
    // we can specify the primary height by width here
    const height = chartHeight;

    const base = [
      {
        height: height,
        lineWidth: 0,
        crosshair: configCrosshair((config) => {
          config.snap = false;
          config.label.format = '{value:.2f}';
          return config;
        }),
        title: {
          text: null
        }
      },
      {
        height: 96,
        offset: 0,
        lineWidth: 0,
        labels: {
          formatter: numberFormatter()
        },
        title: {
          text: null
        }
      }
    ];

    if (chart.current) {
      chart.current.chart.reflow();
    }

    return base.concat(indicatorAxis).reduce((a, c, i) => {
      if (i > 0) {
        let h = a[i - 1].height || 0;
        let t = a[i - 1].top || 0;
        c.top = h + t;
      }
      return a.concat(c);
    }, []);
  }, [chartHeight, configCrosshair, indicators]);

  const resizeChartIfNecessary = useCallback((e) => {
    const chart = e.target;

    const totalHeight =
      chart.axes.reduce((a, c, i) => {
        if (i > 0) {
          return a + c.height;
        } else return 0;
      }, 0) + 36; //this is to offset the xAxis labels overlapping the chart

    if (chart?.options?.chart?.height !== totalHeight) {
      chart.setSize(null, totalHeight, false);
    }
  }, []);

  const highchartsOptions = useMemo(() => {
    return {
      chart: {
        animation: false,
        events: {
          redraw: resizeChartIfNecessary,
          render: resizeChartIfNecessary
        },
        pinchType: 'none',
        spacingTop: 0,
        spacingLeft: 4,
        spacingRight: 4,
        backgroundColor: '#FFFFFF',
        shadow: false
      },
      rangeSelector: {
        inputEnabled: false,
        buttons: [],
        labelStyle: {
          display: 'none'
        }
      },
      series: buildSeriesData(bars, volume, fullBars, additionalSymbolsData),
      tooltip: {
        enabled: displayTooltip,
        useHTML: true,
        hideDelay: 100,
        formatter: tooltipFormatter,
        outside: true,
        split: false,
        variables: {
          currentBarSize: barSize
        },
        externalFunctions: {
          localize: localize
        }
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        dateTimeLabelFormats: {
          day: '%m/%d',
          week: '%m/%d',
          month: '%y/%m'
        },
        lineWidth: 0,
        crosshair: configCrosshair((config) => {
          config.snap = true;

          if (barSize && barSize in xCrosshairLabelFormat) {
            config.label.format = xCrosshairLabelFormat[barSize];
          }

          return config;
        })
      },
      yAxis: buildYAxis(),
      plotOptions: plotOptions
    };
  }, [
    additionalSymbolsData,
    barSize,
    bars,
    buildSeriesData,
    buildYAxis,
    configCrosshair,
    displayTooltip,
    fullBars,
    localize,
    plotOptions,
    resizeChartIfNecessary,
    volume
  ]);

  // OK. I know this looks bad, and it is. But, we want to completely
  // Re-render the chart when any of the indicators change, or
  // when the bars change. This is due to an issue with the VBP
  // indicator in that it crashes whenever you try to re-render
  // it from a previously good state.
  const key = new Date().getTime();

  return (
    <ErrorBoundary>
      <HighchartsReact
        key={key}
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={highchartsOptions}
        ref={chart}
      />
    </ErrorBoundary>
  );
};

export default SymbolChart;
