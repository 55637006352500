import types from '../actions/_actionTypes';
import initialState from './initialState';
import * as time from '../utils/time';

const market = (state = initialState.market, action) => {
  var newState;

  switch (action.type) {
    case types.REQUESTING_UP_DOWN_RATIOS:
      newState = Object.assign({}, state);
      newState.upDownRatios.isLoading = true;
      newState.upDownRatios.isError = false;
      return newState;
    case types.RECEIVE_UP_DOWN_RATIOS:
      newState = Object.assign({}, state);
      newState.upDownRatios.isLoading = false;
      newState.upDownRatios.isError = false;
      newState.upDownRatios.data = action.data.data;
      return newState;
    case types.ERROR_REQUESTING_UP_DOWN_RATIOS:
      newState = Object.assign({}, state);
      newState.upDownRatios.isLoading = false;
      newState.upDownRatios.isError = true;
      return newState;
    case types.REQUESTING_FUNDAMENTALS:
      newState = Object.assign({}, state);
      newState.fundamentals[action.symbol] = {};
      newState.fundamentals[action.symbol].isLoading = true;
      newState.fundamentals[action.symbol].isError = false;
      newState.fundamentals[action.symbol].timestamp = time.timestamp();
      return newState;
    case types.RECEIVE_FUNDAMENTALS:
      newState = Object.assign({}, state);
      newState.fundamentals[action.symbol].isLoading = false;
      newState.fundamentals[action.symbol].isError = false;
      newState.fundamentals[action.symbol].data = action.data.data;
      newState.fundamentals[action.symbol].timestamp = time.timestamp();
      return newState;
    case types.ERROR_REQUESTING_FUNDAMENTALS:
      newState = Object.assign({}, state);
      newState.fundamentals[action.symbol].isLoading = false;
      newState.fundamentals[action.symbol].isError = true;
      return newState;
    default:
      return state;
  }
};

export default market;
