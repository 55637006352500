function createBlankMatrix() {
  return {
    market: {
      ask: 0,
      bid: 0
    },
    over: {
      ask: 0,
      bid: 0
    },
    rows: [],
    under: {
      ask: 0,
      bid: 0
    }
  };
}

function convertMatrixSnapshotToPriceMap(snapshot) {
  var priceMap = {};
  for (var key in snapshot) {
    var order = snapshot[key];
    if (!order) {
      continue;
    }
    priceMap[order.price] = priceMap[order.price] || {
      price: order.price,
      ask: 0,
      bid: 0
    };
    if (key[0] === 'A') {
      priceMap[order.price].ask += order.size;
      if (order.flag) {
        priceMap[order.price].askFlag = order.flag;
      }
    } else {
      priceMap[order.price].bid += order.size;
      if (order.flag) {
        priceMap[order.price].bidFlag = order.flag;
      }
    }
  }
  return priceMap;
}

function convertPriceMapToMatrix(priceMap) {
  var matrix = createBlankMatrix();

  for (var price in priceMap) {
    price = parseFloat(price);
    if (price >= 9999999999) {
      matrix.over.ask += priceMap[price].ask;
      matrix.over.bid += priceMap[price].bid;
    } else if (Number(price) === Number(0)) {
      matrix.market.ask += priceMap[price].ask;
      matrix.market.bid += priceMap[price].bid;
    } else if (price <= 0.0001) {
      matrix.under.ask += priceMap[price].ask;
      matrix.under.bid += priceMap[price].bid;
    } else {
      matrix.rows.push(priceMap[price]);
    }
  }

  matrix.rows.sort(function compareRows(a, b) {
    return b.price - a.price;
  });

  return matrix;
}

function trimMatrixToSize(matrix, rows) {
  matrix.lowestAskIndex = 0;
  matrix.highestBidIndex = 0;

  matrix.rows = matrix.rows.filter(function(el) {
    return el != null;
  });

  for (var r = 0; r < matrix.rows.length; r++) {
    if (!!matrix.rows[r] && matrix.rows[r].ask) {
      matrix.lowestAskIndex = r;
    }
    if (!!matrix.rows[r] && !matrix.highestBidIndex && matrix.rows[r].bid) {
      matrix.highestBidIndex = r;
    }
  }

  while (matrix.lowestAskIndex < rows - 1) {
    matrix.rows.unshift({});
    matrix.lowestAskIndex += 1;
    matrix.highestBidIndex += 1;
  }
  while (matrix.lowestAskIndex >= rows) {
    var over = matrix.rows.shift();
    matrix.over.ask += over.ask;
    matrix.over.bid += over.bid;
    matrix.lowestAskIndex -= 1;
    matrix.highestBidIndex -= 1;
  }

  while (matrix.rows.length < rows * 2) {
    matrix.rows.push({});
  }
  while (matrix.rows.length > rows * 2) {
    var under = matrix.rows.pop();
    matrix.under.ask += under.ask;
    matrix.under.bid += under.bid;
  }
}

function copyMatrix(source, dest) {
  source.rows = source.rows.filter(function(el) {
    return el != null;
  });

  dest.market.askChanged = source.market.ask !== dest.market.ask;
  dest.market.ask = source.market.ask;

  dest.market.bidChanged = source.market.bid !== dest.market.bid;
  dest.market.bid = source.market.bid;
  dest.over.askChanged = source.over.ask !== dest.over.ask;
  dest.over.ask = source.over.ask;
  dest.over.bidChanged = source.over.bid !== dest.over.bid;
  dest.over.bid = source.over.bid;
  dest.under.askChanged = source.under.ask !== dest.under.ask;
  dest.under.ask = source.under.ask;
  dest.under.bidChanged = source.under.bid !== dest.under.bid;
  dest.under.bid = source.under.bid;

  for (var r = 0; r < source.rows.length; r++) {
    if (r >= dest.rows.length) {
      dest.rows.push(source.rows[r]);
    } else {
      dest.rows[r].askChanged = source.rows[r].ask !== dest.rows[r].ask;
      dest.rows[r].ask = source.rows[r].ask;
      dest.rows[r].askFlag = source.rows[r].askFlag;
      dest.rows[r].bidChanged = source.rows[r].bid !== dest.rows[r].bid;
      dest.rows[r].bid = source.rows[r].bid;
      dest.rows[r].bidFlag = source.rows[r].bidFlag;
      dest.rows[r].price = source.rows[r].price;
    }
  }

  while (dest.rows.length > source.rows.length) {
    dest.rows.unshift();
  }
  dest.lowestAskIndex = source.lowestAskIndex;
  dest.highestBidIndex = source.highestBidIndex;

  return dest;
}

function unmarkChangedValues(matrix) {
  var changed = false;
  if (matrix.market.askChanged) {
    matrix.market.askChanged = false;
    changed = true;
  }
  if (matrix.market.bidChanged) {
    matrix.market.bidChanged = false;
    changed = true;
  }
  if (matrix.over.askChanged) {
    matrix.over.askChanged = false;
    changed = true;
  }
  if (matrix.over.bidChanged) {
    matrix.over.bidChanged = false;
    changed = true;
  }
  if (matrix.under.askChanged) {
    matrix.under.askChanged = false;
    changed = true;
  }
  if (matrix.under.bidChanged) {
    matrix.under.bidChanged = false;
    changed = true;
  }
  for (var r = 0; r < matrix.rows.length; r++) {
    if (matrix.rows[r].askChanged) {
      matrix.rows[r].askChanged = false;
      changed = true;
    }
    if (matrix.rows[r].bidChanged) {
      matrix.rows[r].bidChanged = false;
      changed = true;
    }
  }
  return changed;
}

module.exports = {
  createBlank: createBlankMatrix,
  fromSnapshot: function createMatrixFromSnapshot(snapshot) {
    var priceMap = convertMatrixSnapshotToPriceMap(snapshot);
    return convertPriceMapToMatrix(priceMap);
  },
  trim: trimMatrixToSize,
  copy: copyMatrix,
  unmarkChanged: unmarkChangedValues
};
