import React from 'react';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Edit from '@mui/icons-material/EditOutlined';

const EditableScanTitle = props => {
  if (!props.isEditable) {
    return <div className="scan-name-label">{props.scanName}</div>;
  }
  return (
    <TextField
      autoComplete="off"
      type="text"
      value={props.scanName}
      className="scan-name-field"
      style={{ marginLeft: '5px' }}
      onChange={event => {
        props.handleInputChange(event.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Edit className={'icon-soft'} />
          </InputAdornment>
        )
      }}
      variant="standard"
    />
  );
};

export default EditableScanTitle;
