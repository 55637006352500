import React from 'react';
import QuoteTable from './QuoteTable';
import { MobileMarketboardHeader } from './MobileMarketboardHeader';
import { injectIntl } from 'react-intl';
import { setDocumentTitle } from '../../utils/html';

class MarketBoard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.setTitle = this.setTitle.bind(this);
  }

  setTitle(boardName) {
    const title = this.props.intl.formatMessage(
      {
        id: 'document.title.marketboard'
      },
      {
        boardName: boardName
      }
    );
    setDocumentTitle(title);
  }

  render() {
    this.setTitle(this.props.board.Name);
    return (
      <div>
        <MobileMarketboardHeader marketboardId={this.props.marketboardId} />
        <QuoteTable
          symbols={this.props.board.Symbols}
          marketboardId={this.props.marketboardId}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default injectIntl(MarketBoard);
