import React, { useEffect, useState } from 'react';
import NewsContainer from '../news/NewsContainer';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
const DEFAULT_NEWS_AMOUNT = 250;
const MAX_NEWS_QUANTITY = 30;
const MIN_NEWS_QUANTITY = 5;

const getSymbolNewsQuantity = (width) => {
  if (width <= 375) {
    return MIN_NEWS_QUANTITY;
  } else if (width <= 812) {
    return 10;
  } else if (width >= 1201) {
    return Math.round(Math.min(width * (8 / 100) - 74, MAX_NEWS_QUANTITY));
  } else { // between 812 and 1201
    return Math.round(width * (1 / 200) + 10);
  }
}

export default function SymbolNewsSection({ symbol }) {
  const { width } = useWindowDimensions();
  const [symbolNewsQuantity, setSymbolNewsQuantity] = useState(MIN_NEWS_QUANTITY);

  useEffect(() => {
    setSymbolNewsQuantity(getSymbolNewsQuantity(width));
  }, [width, setSymbolNewsQuantity])

  return (
    <div className={'symbol-news-section section'}>
      <div className="symbol-headlines">
        <NewsContainer
          displaySearch={false}
          hideShowMore={true}
          amountNewsToShow={symbolNewsQuantity}
          newsAmount={DEFAULT_NEWS_AMOUNT}
          newsIncrement={symbolNewsQuantity}
          key="symbol-headlines__headline-list"
          symbol={symbol}
          isWidget={true}
          isDisableRelatedSymbols={true}
        />
      </div>
    </div>
  );
}
