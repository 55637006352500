import types from '../actions/_actionTypes';
import initialState from './initialState';
import { getHotlistStateId } from '../actions/hotlist';
import * as time from '../utils/time';

function getStateId(action) {
  const hotlistName = action.hotlistName;
  const exchange = action.exchange;
  const sector = action.sector;
  return getHotlistStateId(hotlistName, exchange, sector);
}

const hotlist = (state = initialState.hotlists, action) => {
  let newState;
  let stateId = getStateId(action);

  switch (action.type) {
    case types.REQUESTING_HOTLIST:
      newState = Object.assign({}, state);
      newState[stateId] = {
        data: null,
        isLoading: true,
        didInvalidate: false,
        isError: false,
        when: time.timestamp()
      };
      return newState;
    case types.RECEIVE_HOTLIST:
      newState = Object.assign({}, state);
      newState[stateId] = {
        data: action.data.data,
        isLoading: false,
        didInvalidate: false,
        isError: false,
        when: time.timestamp()
      };
      return newState;
    case types.INVALIDATE_HOTLIST:
      newState = Object.assign({}, state);
      newState[stateId] = {
        data: null,
        isLoading: false,
        didInvalidate: true,
        isError: false,
        when: time.timestamp()
      };
      return newState;
    case types.ERROR_REQUESTING_HOTLIST:
      newState = Object.assign({}, state);
      newState[stateId] = {
        data: null,
        isLoading: false,
        didInvalidate: false,
        isError: true,
        when: time.timestamp()
      };
      return newState;
    default:
      return state;
  }
};

export default hotlist;
